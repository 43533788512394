import React, { useEffect, useState } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCommon } from '../../shared/hooks/useCommon';
import { useFuelApi } from '../../services/api/hooks/useFuelApi';
import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import DetailsWrapper from '../../shared/components/DetailsWrapper';
import { NavItemOnClick } from '../../shared/components/nav/components/NavItemOnClick';
import ErrorIconText from '../../shared/components/icon-components/ErrorIconText';
import { selectConfiguration } from '../application/applicationSlice';

interface EsFuelMapProps {
  canViewMap: boolean;
  linkText?: string;
  isSub?: boolean;
  isNavItem?: boolean;
  buttonStyle?: React.CSSProperties;
}
const EsFuelMap = (props: EsFuelMapProps) => {
  const {
    canViewMap, linkText = 'Fuel Discount Map', isSub = false, isNavItem = true, buttonStyle,
  } = props;

  const [esFuelMapUrl, setEsFuelMapUrl] = useState<string>();
  const [isTokenError, setIsTokenError] = useState<boolean>(false);
  const {
    config, isLoading, setIsLoading, isOpen, setIsOpen,
  } = useCommon();
  const { getEsFuelMapSsoToken } = useFuelApi();
  const { handleError } = useAsyncErrorHandler();
  const esFuelDiscountMapUrl = useSelector(selectConfiguration)?.esFuelDiscountMapUrl;

  const loadToken = async (reactFuelMapUrl: string) => {
    try {
      setIsLoading(true);
      const token = await getEsFuelMapSsoToken();
      setEsFuelMapUrl(`${reactFuelMapUrl}/#/magic?code=${token}`);
    } catch (err) {
      setIsTokenError(true);
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (esFuelDiscountMapUrl) {
      if (canViewMap) {
        loadToken(esFuelDiscountMapUrl);
      } else {
        setEsFuelMapUrl(config.REACT_APP_ES_FUEL_MAP_NO_FUEL);
      }
    }
  }, [canViewMap, esFuelDiscountMapUrl]);

  return (
    <>
      {esFuelDiscountMapUrl && (
        <>
          {isNavItem
            ? (
              <NavItemOnClick
                linkText={linkText}
                onClick={() => setIsOpen(true)}
                isSub={isSub}
              />
            )
            : <Button color="primary" variant="text" onClick={() => setIsOpen(true)} sx={{ padding: '0px', ...buttonStyle }}>{linkText}</Button>}
          <DetailsWrapper
            title={isLoading ? <LinearProgress /> : linkText}
            onClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
            maxDialogContentWidth={2400}
            // titlebackgroundcolor={userProfile?.isEmployee ? theme.palette.common.black : getMemberLegalCaseStatusColor(caseDetails?.caseStatus)}
            isAppBarSticky
            includeContentTopBottomPadding={false}
            paperStyle={{
              padding: '0px !important',
            }}
            contentPaddingContainerStyle={{
              padding: '0px !important',
            }}
          >
            {isTokenError && (
              <Box sx={{
                margin: '32px',
              }}
              >
                <ErrorIconText text="There was a problem accessing the 'Discount Fuel Map'. Please try again later." />
              </Box>
            )}
            {esFuelMapUrl && !isTokenError && (
              <div style={{
                flex: '1 1  auto',
                flexDirection: 'column',
                display: 'flex',
                minHeight: '0px',
                overflow: 'hidden',
              }}
              >
                <iframe
                  title="Fuel Discount"
                  loading="lazy"
                  src={esFuelMapUrl}
                  allow="geolocation; clipboard-read; clipboard-write"
                  style={{
                    flex: '1 1 auto', border: 0, minHeight: 'calc(100vh - 64px)',
                  }}
                />

              </div>
            )}
          </DetailsWrapper>
        </>
      )}
    </>
  );
};

export default EsFuelMap;
