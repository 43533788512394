import { ValidationCouponResult } from '../../../shared/models/coupon/ValidationCouponResult';
import { initAxiosInstance } from './utils';

export const useCouponApi = () => {
  const portalClient = initAxiosInstance();

  const validateFleetFuelCoupon = async (
    fleetId: string,
    couponCode: string,
    price: number,
  ) => {
    const { data } = await portalClient.post<ValidationCouponResult>(`coupon/fleet/${fleetId}/fuel/validate/${couponCode}`, { price });
    return data;
  };

  const validateUserFuelCoupon = async (
    userId: string,
    couponCode: string,
    price: number,
  ) => {
    const { data } = await portalClient.post<ValidationCouponResult>(`coupon/user/${userId}/fuel/validate/${couponCode}`, { price });
    return data;
  };

  return {
    validateFleetFuelCoupon,
    validateUserFuelCoupon,
  };
};
