import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';
import { RootState } from '../../app/store';
import { Profile } from '../../shared/models/user/Profile';
import { createBlockingThunk } from '../../services/thunk';
import { loadPermissionCache } from './authUtil';
import { getFullName, getInitials } from './hooks/useProfile';
import { userPermissionNames, UserPermissions } from './permissionNames';
import { useUserApi } from '../../services/api/hooks/useUserApi';

export interface AzureAccount {
  email: string;
  fullAccount: AccountInfo;
}

export interface AuthState {
  idToken?: string;
  azureAccount?: AzureAccount;
  portalProfile?: Profile;
}

const accountInfoToAzureAccount = (accountInfo: AccountInfo) => ({
  fullAccount: accountInfo,
  email: get('idTokenClaims.emails[0]', accountInfo),
});

export enum PortalApiActions {
  ProfileRetrieved = 'auth/profileRetrieved',
  LoginSuccess = 'auth/loginSuccess',
  AccessTokenRetrieved = 'auth/azureAccountRetrieved',
  LogoutSuccess = 'auth/logoutSucess',
}
export const logoutSuccess = createAction(PortalApiActions.LogoutSuccess);

export const accessTokenRetrieved = createAction<AccountInfo>(
  PortalApiActions.AccessTokenRetrieved,
);
export const loginSuccess = createAction<AuthenticationResult>(
  PortalApiActions.LoginSuccess,
);

export const profileRetrieved = createAction<Profile>(
  PortalApiActions.ProfileRetrieved,
);

export const getUserProfileThunk = (bypassStateCheck: boolean = false) => createBlockingThunk<Profile>(
  async (dispatch, getState) => {
    const { getLoggedInUserProfile } = useUserApi();
    const profileState = getState().auth.portalProfile;
    if (profileState && !bypassStateCheck) {
      return profileState;
    }
    const profile = await getLoggedInUserProfile();
    const { azureAccount } = getState().auth;

    profile.cachePermissions = loadPermissionCache(
      userPermissionNames,
      profile.permissions,
      undefined,
    );

    if (azureAccount?.fullAccount) {
      const { fullAccount } = azureAccount;
      const idTokenClaims: any = fullAccount.idTokenClaims
        ? fullAccount.idTokenClaims
        : {};

      profile.isMultiFleetUser = (profile?.fleets && profile?.fleets.length > 1);

      profile.azureUser = {
        id: fullAccount.localAccountId,
        firstName: idTokenClaims.given_name,
        lastName: idTokenClaims.family_name,
        // NOTE: bugfix below is a quick bandaid to resolve a last minute QA bug (when an individual membership updates their own email address... logic below will allow the UI to update accordingly)
        email: profile.isEmployee ? fullAccount.username : profile.portalUser?.email || '-',
        domainUserId: '',
      };

      profile.initials = getInitials(profile.azureUser);
      profile.fullName = getFullName(profile.azureUser);

      const { permissions } = profile.cachePermissions;
      // userPermissions is being set solely for easier access to the named permissions
      profile.userPermissions = {
        canAddEditScheduledLoads: permissions[UserPermissions.canAddEditScheduledLoads],
        canViewAllFleets: permissions[UserPermissions.canViewAllFleets],
        canEditOwnPermissions: permissions[UserPermissions.canEditOwnPermissions],
        canViewPortalAdministratorRoute: profile.isEmployee && permissions[UserPermissions.canViewPortalAdministratorRoute],
        canEditEmployeePermissions: permissions[UserPermissions.canEditEmployeePermissions],
        canAddEmployee: permissions[UserPermissions.canAddEmployee],
        canAddFleet: permissions[UserPermissions.canAddFleet],
        canAddFuelAdjustment: permissions[UserPermissions.canAddFuelAdjustment],
        canEditFuelLoadLimits: permissions[UserPermissions.canEditFuelLoadLimits],
        canEditFleetGeneralInfo: permissions[UserPermissions.canEditFleetGeneralInfo],
        canViewAllIndividualMemberships: permissions[UserPermissions.canViewAllIndividualMemberships],
        canManageIndividualFuel: permissions[UserPermissions.canManageIndividualFuel],
        canManageIndividualLegal: permissions[UserPermissions.canManageIndividualLegal],
        canManageIndividualPortalUser: permissions[UserPermissions.canManageIndividualPortalUser],
        canApproveNameChangeRequests: permissions[UserPermissions.canApproveNameChangeRequests],
        canAccessIndividualDashboard: permissions[UserPermissions.canAccessIndividualDashboard],
        canManageFuelSponsorRegistrationLastCreationDate: permissions[UserPermissions.canManageFuelSponsorRegistrationLastCreationDate],
      };
    }
    dispatch(profileRetrieved(profile));
    return profile;
  },
);

const authReducer = createReducer<AuthState>({}, (builder) => {
  builder
    .addCase(loginSuccess, (state, action) => {
      const { payload } = action;
      if (!payload.account) {
        // throw error possibly?
        return;
      }
      state.azureAccount = {
        fullAccount: payload.account,
        email: get('idTokenClaims.emails[0]', payload),
      };
    })
    .addCase(accessTokenRetrieved, (state, action) => {
      if (!state.azureAccount) {
        state.azureAccount = accountInfoToAzureAccount(action.payload);
      }
    })
    .addCase(logoutSuccess, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = {};
    })
    .addCase(profileRetrieved, (state, action) => {
      state.portalProfile = action.payload;
    });
});

export const selectAccount = (state: RootState) => state.auth.azureAccount;
export const selectPortalProfile = (state: RootState) => state.auth.portalProfile;

export default authReducer;
