import React from 'react';
import { Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type BadgeColor = 'success' | 'error' | 'warning' | 'info' | 'outline';

export interface TvcBadgeProps {
  children?: React.ReactNode;
  badgeContent?: number | string;
  badgeColor?: BadgeColor;
  variant?: 'standard' | 'dot';
  invisible?: boolean;
}

const TvcBadge = (props: TvcBadgeProps) => {
  const {
    children,
    badgeContent,
    badgeColor = 'success',
    variant = 'standard',
    invisible = false,
  } = props;
  const theme = useTheme();
  const styles = {
    badgeError: {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
    badgeSuccess: {
      backgroundColor: `${theme.palette.success.main} !important`,
    },
    badgeWarning: {
      backgroundColor: `${theme.palette.warning.main} !important`,
    },
    badgeInfo: {
      backgroundColor: `${theme.palette.info.main} !important`,
    },
    badgeOutlined: {
      backgroundColor: `${theme.palette.grey[200]} !important`,
      color: `${theme.palette.grey[800]} !important`,
      border: `${theme.palette.grey[800]} solid 1px !important`,
    },
  };

  const getBadgeColor = (bColor: BadgeColor) => {
    switch (bColor) {
      case 'success':
        return styles.badgeSuccess;
      case 'error':
        return styles.badgeError;
      case 'warning':
        return styles.badgeWarning;
      case 'info':
        return styles.badgeInfo;
      case 'outline':
        return styles.badgeOutlined;
      default:
        return styles.badgeSuccess;
    }
  };

  return (
    <Badge
      badgeContent={badgeContent}
      color="primary"
      variant={variant}
      showZero={false}
      max={99}
      invisible={invisible}
      sx={{
        '& .MuiBadge-colorPrimary': getBadgeColor(badgeColor),
      }}
    >
      {children}
    </Badge>
  );
};

export default TvcBadge;
