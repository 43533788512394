import React, { useDeferredValue, useEffect, useState } from 'react';
import { PagingProps } from '../models/other/PagingProps';
import { getValueOrReplacementValue } from '../utilities/miscHelpers';

export const usePagination = (props: PagingProps) => {
  const {
    activePageColor,
    resetPagingDependencies,
    jsxItems,
    isLoading,
    defaultPageSize,
  } = props;
  let { entityName, pageSizes } = props;
  entityName = getValueOrReplacementValue(entityName, 'item');

  const [page, setPage] = useState(1);
  const [pagingNeeded, setPagingNeeded] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize ?? 25);
  pageSizes = pageSizes ?? [10, 25, 50];
  const pagingControlsVisibility: 'hidden' | 'unset' = jsxItems.length <= pageSize ? 'hidden' : 'unset';
  const isEmptyResults: boolean = jsxItems.length < 1;
  const defferedPage = useDeferredValue(page);

  useEffect(() => {
    setPagingNeeded(jsxItems.length >= pageSize);
  }, [jsxItems, pageSize]);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const getPagedEntities = (entities: JSX.Element[]) => (pageSize > 0
    ? entities.slice((defferedPage - 1) * pageSize, defferedPage * pageSize)
    : entities);

  return {
    entityName,
    activePageColor,
    resetPagingDependencies,
    jsxItems,
    isLoading,
    pageSizes,
    defaultPageSize,
    pagingControlsVisibility,
    isEmptyResults,
    changePage,
    handlePageSizeChange,
    getPagedEntities,
    setPage,
    pageSize,
    page: defferedPage,
    pagingNeeded,
  };
};
