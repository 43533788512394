import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import IconText, { IconTextProps } from './IconText';
import { theme } from '../../../appTheme';

export interface ErrorIconTextProps extends Omit<IconTextProps, 'icon'> {
  iconSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const ErrorIconText = (props: ErrorIconTextProps) => {
  const { iconSize = 'medium', ...rest } = props;
  return (
    <IconText
      {...rest}
      icon={<ErrorIcon htmlColor={theme.palette.error.main} fontSize={iconSize} />}
    />
  );
};

export default ErrorIconText;
