import { Backdrop, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const LoadingScreen = ({ isLoading }: { isLoading: boolean }) => {
  const theme = useTheme();
  const styles = {
    loadingBackdrop: {
      zIndex: 2000,
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.common.white,
    },
  };
  return (
    <Backdrop
      open={isLoading}
      data-testid="loading-overlay"
      sx={styles.loadingBackdrop}
    >
      <CircularProgress sx={{ color: 'white' }} />
    </Backdrop>
  );
};

export default LoadingScreen;
