import { Tooltip } from '@mui/material';
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { theme } from '../../../appTheme';

const PendingLoadLastLoadErrorIcon = () => (
  <Tooltip
    title="There were one or more errors when attempting to process this pending load"
    aria-label="last load error notification"
  >
    <FiberManualRecordIcon sx={{ color: theme.palette.error.light, marginLeft: '8px' }} />
  </Tooltip>
);

export default PendingLoadLastLoadErrorIcon;
