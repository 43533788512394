import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useBreakPoints } from '../hooks/useBreakPoints';
import { getValueOrReplacementValue } from '../utilities/miscHelpers';

export interface SubRowProps {
  title: string;
  value?: string | number | React.ReactNode | null;
  titleMinWidth?: number;
  valueColor?: string;
  alignTitle?: string;
}

export const SubRow = (props: SubRowProps) => {
  const {
    title, value, titleMinWidth = 84, valueColor = 'initial', alignTitle = 'center',
  } = props;
  const theme = useTheme();
  const { isXsDown } = useBreakPoints();

  const styles = {
    typography: {
      fontSize: 'inherit !important',
    },
  };

  return (
    <Box sx={{ display: 'flex', alignItems: alignTitle }}>
      <Box sx={{
        fontSize: isXsDown ? '0.65em' : '0.75em', color: value ? theme.palette.grey[600] : theme.palette.grey[400], minWidth: `${titleMinWidth}px`, paddingTop: '6px',
      }}
      >
        {title}
        :
        {' '}
      </Box>
      {/* calc width is needed for Typography ellipsis truncation logic to work (parent needs a explicit width) */}
      <Box sx={{
        fontSize: isXsDown ? '0.65em' : '0.75em', marginLeft: '6px', color: value ? valueColor : theme.palette.grey[400], paddingTop: '6px', width: `max(100% - ${titleMinWidth + 10}px)`,
      }}
      >
        <Typography noWrap sx={styles.typography}>
          {getValueOrReplacementValue(value)}
        </Typography>
      </Box>
    </Box>
  );
};
