import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { createNonBlockingThunk } from '../../services/thunk';
import { Message } from '../../shared/models/message/Message';
import { useMessageApi } from '../../services/api/hooks/useMessageApi';

export interface MessageState {
  individualMessages: Message[];
  individualMessagesAreLoading: boolean;
  fleetMessages: Message[];
  fleetMessagesAreLoading: boolean;
}

const initialState: MessageState = {
  individualMessages: [],
  individualMessagesAreLoading: false,
  fleetMessages: [],
  fleetMessagesAreLoading: false,
};

export const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setIndividualMessages(state, action: PayloadAction<Message[]>) {
      state.individualMessages = action.payload;
    },
    setIndividualMessagesAreLoading(state, action: PayloadAction<boolean>) {
      state.individualMessagesAreLoading = action.payload;
    },
    setFleetMessages(state, action: PayloadAction<Message[]>) {
      state.fleetMessages = action.payload;
    },
    setFleetlMessagesAreLoading(state, action: PayloadAction<boolean>) {
      state.fleetMessagesAreLoading = action.payload;
    },
  },
});

export const {
  setIndividualMessages,
  setIndividualMessagesAreLoading,
  setFleetMessages,
  setFleetlMessagesAreLoading,
} = messageSlice.actions;

export const fetchIndividualMessagesThunk = (userId: string) => createNonBlockingThunk<Message[]>(async (dispatch) => {
  const { getIndividualMessages } = useMessageApi();
  dispatch(setIndividualMessagesAreLoading(true));
  const messages = await getIndividualMessages(userId);
  dispatch(setIndividualMessages(messages));
  dispatch(setIndividualMessagesAreLoading(false));
  return messages;
});

export const fetchFleetMessagesThunk = (fleetId: string) => createNonBlockingThunk<Message[]>(async (dispatch) => {
  const { getFleetMessages } = useMessageApi();
  dispatch(setFleetlMessagesAreLoading(true));
  const messages = await getFleetMessages(fleetId);
  dispatch(setFleetMessages(messages));
  dispatch(setFleetlMessagesAreLoading(false));
  return messages;
});

export const selectIndividualProductMessages = (state: RootState) => state.messages.individualMessages.filter((m) => m.productId !== undefined);
export const selectIndividualMessagesAreLoading = (state: RootState) => state.messages.individualMessagesAreLoading;

export const selectFleetProductMessages = (state: RootState) => state.messages.fleetMessages.filter((m) => m.productId !== undefined);
export const selectFleetMessagesAreLoading = (state: RootState) => state.messages.fleetMessagesAreLoading;

export default messageSlice.reducer;
