import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import VerifyCodeForm from './VerifyCodeForm';
import { selectMigrationBusy, verifyCode } from './migrationSlice';
import MigrationContentHeader from './MigrationContentHeader';
import { useAppDispatch } from '../../app/store';
import { useTvcPortalApiValidationErrors } from '../../shared/hooks/useTvcPortalApiValidationErrors';
import CheckJunkBoxIconText from '../../shared/components/icon-components/CheckJunkBoxIconText';

interface VerificationCodeProps {
  email: string;
  resendCode: (email: string, isResend?: boolean) => Promise<void>
}
const VerificationCode = (props: VerificationCodeProps) => {
  const { email, resendCode } = props;
  const { handleError } = useAsyncErrorHandler();
  const dispatch = useAppDispatch();
  const {
    apiValidationErrors,
    hasApiValidationErrors,
    setApiValidationErrors,
  } = useTvcPortalApiValidationErrors();

  const reSendVerificationCode = async (emailValue: string) => {
    setApiValidationErrors([]);
    await resendCode(emailValue, true);
  };
  const isBusy = useSelector(selectMigrationBusy);

  return (
    <>
      <Grid item xs={12}>
        <MigrationContentHeader headerText="Verification Code" />
      </Grid>
      <Grid item xs={12}>
        Verification code sent to
        {' '}
        {email}
        . This code will expire in 5 minutes.
      </Grid>
      <Grid item xs={12}>
        <CheckJunkBoxIconText />
      </Grid>
      <Grid item xs={12}>
        <VerifyCodeForm
          email={email}
          resendCode={reSendVerificationCode}
          validationErrors={apiValidationErrors}
          isBusy={isBusy}
          onFormSubmit={async (formData) => {
            const { code } = formData;

            try {
              if (!email) {
                throw new Error('There is a problem reading the value for email address');
              }
              setApiValidationErrors([]);
              await dispatch(verifyCode(email, code));
            } catch (err) {
              if (!hasApiValidationErrors({ errorResponse: err })) {
                handleError(err);
              }
            }
          }}
        />
      </Grid>
    </>
  );
};

export default VerificationCode;
