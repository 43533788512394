import React from 'react';
import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
  Tooltip,
  Zoom,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface ClearableTextFieldProps extends StandardTextFieldProps {
  showAdornment?: boolean;
  onAdornmentClick?: () => void;
  preventDefaultOnEnter?: boolean;
  maxLength?: number;
}

const ClearableTextField = (props: ClearableTextFieldProps) => {
  const {
    showAdornment = true,
    onAdornmentClick,
    type = 'text',
    fullWidth = true,
    disabled = false,
    preventDefaultOnEnter = true,
    onKeyDown,
    maxLength,
    ...rest
  } = props;
  return (
    <TextField
      {...rest}
      type={type}
      onKeyDown={preventDefaultOnEnter ? (e) => {
        if (e.key.toLowerCase() === 'enter') {
          e.preventDefault();
        }
      } : onKeyDown}
      fullWidth={fullWidth}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Clear" aria-label="clear text">
              <Zoom in={showAdornment}>
                <div>
                  <IconButton
                    onClick={onAdornmentClick}
                    sx={{ marginRight: '-14px' }}
                    disabled={disabled}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </div>
              </Zoom>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ClearableTextField;
