import React from 'react';
import {
  ButtonProps, Button, CircularProgress, Backdrop,
  Portal,
} from '@mui/material';

interface BusySubmitButtonProps extends ButtonProps {
  actionPending: boolean;
  containerStyles?: React.CSSProperties;
  applyInvisibleBackdrop?: boolean;
}
const BusySubmitButton = (props: BusySubmitButtonProps) => {
  const styles = {
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-12px',
      marginLeft: '-12px',
    },
    invisOverlay: {
      zIndex: '9000',
    },
  };
  const {
    actionPending,
    children,
    disabled,
    containerStyles,
    applyInvisibleBackdrop = true,
    style,
    ...rest
  } = props;

  return (
    <>
      <div style={{ position: 'relative', ...containerStyles }}>
        <Button sx={style} {...rest} disabled={actionPending || disabled}>
          {children}
        </Button>
        {actionPending && (
          <CircularProgress size={24} sx={styles.buttonProgress} />
        )}
      </div>
      {actionPending && (
        <>
          {applyInvisibleBackdrop && (
            <Portal>
              <Backdrop
                open={actionPending}
                sx={styles.invisOverlay}
                invisible
              />
            </Portal>
          )}
        </>
      )}
    </>
  );
};

export default BusySubmitButton;
