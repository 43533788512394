import {
  Badge,
  Box,
  Fade,
  IconButton,
  Slide,
  Tooltip,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface FilterSearchWrapperProps extends React.HTMLAttributes<any> {
  children?: React.ReactNode;
  optionsAreSelected?: boolean;
  clearFiltersFunc?: () => void;
  activeColor?: string;
  visibilityCallback?: (isVisible: boolean) => void;
  disabled?: boolean;
  showWhenNotDisabled?: boolean;
  filterButtonBoxStyle?: React.CSSProperties;
  filterContentBoxStyle?: React.CSSProperties;
  filtersChildrenBoxStyle?: React.CSSProperties;
}

const FilterSearchWrapper = (props: FilterSearchWrapperProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const {
    children,
    optionsAreSelected = false,
    clearFiltersFunc,
    filtersChildrenBoxStyle,
    activeColor = 'black',
    visibilityCallback,
    disabled,
    showWhenNotDisabled = false,
    filterButtonBoxStyle,
    filterContentBoxStyle,
  } = props;

  useEffect(() => {
    if (visibilityCallback) {
      visibilityCallback(isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    if (disabled) {
      setIsVisible(false);
    } else if (showWhenNotDisabled) {
      setIsVisible(true);
    }
  }, [disabled, showWhenNotDisabled]);

  return (
    <Box display="flex" flex={1}>
      <Box sx={{ minHeight: '80px', ...filterButtonBoxStyle }} display="flex">
        <Tooltip
          title={`${isVisible ? 'Hide' : 'Show'} additional filter options`}
          aria-label="Filter options"
        >
          <IconButton
            disabled={disabled}
            aria-label="toggle filter options"
            sx={{ alignSelf: 'center' }}
            onClick={() => setIsVisible(!isVisible)}
            size="large"
          >
            <Badge
              color="primary"
              variant="dot"
              invisible={!optionsAreSelected}
            >
              <FilterListIcon
                sx={{ color: optionsAreSelected ? activeColor : 'unset' }}
              />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        sx={{ overflow: 'hidden' }}
      >
        <Slide
          direction="right"
          in={isVisible}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 500, exit: 200 }}
        >
          <Box display="flex" flex={1} sx={{ ...filterContentBoxStyle }}>
            <Fade
              in={optionsAreSelected}
              timeout={{ enter: 200, exit: 100 }}
              style={{ transitionDelay: '200ms' }}
            >
              <Box display="flex">
                {clearFiltersFunc && (
                  <Tooltip
                    title="Clear filter options"
                    aria-label="clear filter options"
                  >
                    <IconButton
                      sx={{ alignSelf: 'center' }}
                      aria-label="clear filter options"
                      onClick={() => clearFiltersFunc()}
                      size="large"
                    >
                      <HighlightOffIcon color="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Fade>
            <Box
              display="flex"
              sx={{ ...filtersChildrenBoxStyle }}
              alignItems="center"
              flex={1}
              flexWrap="wrap"
            >
              {children}
            </Box>
          </Box>
        </Slide>
      </Box>
    </Box>
  );
};

export default FilterSearchWrapper;
