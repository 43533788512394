import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Step,
  Stepper,
  Alert, AlertTitle,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { find, get, take } from 'lodash/fp';
import {
  FormProvider, useForm,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import { theme as appTheme } from '../../appTheme';
import { fetchMemberOrgs } from '../matrix/matrixSlice';
import { FleetLimitValidKeys } from '../../shared/types/fuel/FleetLimitValidKeys';
import { LoadLimits } from '../../shared/types/fuel/LoadLimits';
import { PortalApiAddFleetAndLimits } from '../../shared/models/fleet/PortalApiAddFleetAndLimits';
import DetailsWrapper from '../../shared/components/DetailsWrapper';
import CustomAutocompleteInput from '../../shared/components/formFields/CustomAutocompleteInput';
import CustomTextInput from '../../shared/components/formFields/CustomTextInput';
import FormLayout from '../../shared/components/FormLayout';
import ModalCollectionSearch from '../../shared/components/ModalCollectionSearch';
import PinnedSubHeader from '../../shared/components/PinnedSubHeader';
import Summary from '../../shared/components/confirm-summary/Summary';
import SummarySection from '../../shared/components/confirm-summary/SummarySection';
import { fetchCarriers, selectCarriers } from '../fuel/efsSlice';
import ConfirmLabelValue from '../../shared/components/confirm-summary/ConfirmLabelValue';
import { useLoadLimits } from './hooks/useLoadLimits';
import MinLoadLimitInput from '../../shared/components/load-limits/MinLoadLimitInput';
import MaxLoadLimitInput from '../../shared/components/load-limits/MaxLoadLimitInput';
import LimitPeriodLimitInput from '../../shared/components/load-limits/LimitPeriodLimitInput';
import ClearableTextField from '../../shared/components/ClearableTextField';
import { useSearchText } from '../../shared/hooks/useSearchText';
import { useMatrixProducts } from './hooks/useMatrixProducts';
import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import FormSubmissionFeedback from '../../shared/components/FormSubmissionFeedback';
import { createNewFleetThunk } from './fleetSlice';
import LimitPeriodAmountLimitInputEdit from '../../shared/components/load-limits/LimitPeriodAmountLimitInputEdit';
import AllowanceDisplay from '../../shared/components/load-limits/AllowanceDisplay';
import UsingDefault from '../../shared/components/load-limits/UsingDefault';
import { useProfile } from '../auth/hooks/useProfile';
import { FleetUserPermissions } from '../auth/permissionNames';
import ScrollableContent from '../../shared/components/ScrollableContent';
import { useTvcPortalApiValidationErrors } from '../../shared/hooks/useTvcPortalApiValidationErrors';
import ApiValidationErrors from '../../shared/components/ApiValidationErrors';
import { StyledStepLabel } from '../../shared/components/step-wizard/StyledStepLabel';
import AddFleetUserModal from '../user/AddFleetUserModal';
import { useFuelApi } from '../../services/api/hooks/useFuelApi';
import {
  useMatrixApi,
} from '../../services/api/hooks/useMatrixApi';
import { PortalApiMemberOrganization } from '../../shared/models/matrix/PortalApiMemberOrganization';
import { PermissionCache } from '../../shared/models/permissions/PermissionCache';
import { Multiplier } from '../../shared/models/fuel/Multiplier';
import { EfsCarrier } from '../../shared/models/fuel/EfsCarrier';
import { portalApiFleetToFleet } from '../../shared/utilities/fleetHelpers';
import SectionHeader from '../../shared/components/SectionHeader';
import SectionRow from '../../shared/components/confirm-summary/SectionRow';
import FleetTemplates from './FleetTemplates';
import { ProductPromotions } from '../../shared/models/fleet/ProductPromotions';
import { getValueOrReplacementValue } from '../../shared/utilities/miscHelpers';

interface AddAFleetModalProps {
  open: boolean;
  onClose: () => void;
  userFleetPermissions?: PermissionCache<FleetUserPermissions>;
}

interface AddAFleetStep {
  id: number;
  label: string;
}

interface AddaFleetStepContent {
  id: number;
  content: JSX.Element;
}

export interface FleetAdditionalInformation extends ProductPromotions {
  efsCarrier: EfsCarrier;
  dotNumber: string;
  displayName: string;
}

interface ActionButtonsProps {
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  primaryButtonTitle: string;
  onPrimaryClick?: () => void;
  secondaryButtonTitle: string;
  onSecondaryClick: () => void;
  isBusy?: boolean;
  showBackButton?: boolean;
}
export const ActionButtons = (props: ActionButtonsProps) => {
  const {
    onSecondaryClick,
    onPrimaryClick,
    secondaryButtonTitle,
    primaryButtonTitle,
    primaryDisabled = false,
    secondaryDisabled,
    isBusy = false,
    showBackButton = true,
  } = props;
  const theme = useTheme();
  const styles = {
    navigationButtons: {
      display: 'flex',
      marginTop: '25px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
  };
  return (
    <Box sx={styles.navigationButtons}>
      <Box sx={{ flex: 1 }}>
        {showBackButton && (
          <Button
            sx={{ width: '6em' }}
            variant="contained"
            onClick={onSecondaryClick}
            disabled={secondaryDisabled || false}
          >
            {secondaryButtonTitle}
          </Button>
        )}
      </Box>
      <BusySubmitButton
        color="primary"
        variant="contained"
        style={{ width: '6em' }}
        type="submit"
        onClick={onPrimaryClick}
        disabled={primaryDisabled}
        actionPending={isBusy}
      >
        {primaryButtonTitle}
      </BusySubmitButton>
    </Box>
  );
};

const AddAFleetModal = (props: AddAFleetModalProps) => {
  const { userProfile } = useProfile();
  const theme = useTheme();
  const styles = {
    stepperRoot: {
      backgroundColor: 'unset',
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
      marginBottom: '16px',
    },
    active: {
      color: `${theme.palette.secondary.main} !important`,
      fontStyle: 'normal !important',
    },
    completed: {
      color: `${theme.palette.primary.main} !important`,
    },
    searchField: {
      width: '35em',
      [theme.breakpoints.down('lg')]: {
        width: '25em',
      },
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
      },
    },
    fleetTypeCheckboxLabel: {
      fontSize: '18px',
    },
  };
  const { open, onClose, userFleetPermissions } = props;
  const {
    getProductDisplay, getProductPromotionDisplay, fetchFleetProducts,
  } = useMatrixProducts(true);
  const [isFleetAdded, setIsFleetAdded] = useState<boolean>(false);
  const [hideDisabledOrgs, setHideDisabledOrgs] = useState<boolean>(false);
  const [userCanEditLoadLimits, setUserCanEditLoadLimits] = useState<boolean>(false);
  const [isFleetCreationBusy, setIsFleetCreationBusy] = useState<boolean>(false);
  const [addLoginOpen, setAddLoginOpen] = useState(false);
  const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
  const [newFleetId, setNewFleetId] = useState<string>();
  const [addFleetError, setAddFleetError] = useState<string>();
  const [isFetchingCardcount, setIsFetchingCardcount] = useState<boolean>(false);
  const [isFleetFuel, setIsFleetFuel] = useState<boolean>(false);
  const { getMatrixOrgNotes } = useMatrixApi();
  const { getCarrierCardCount } = useFuelApi();
  const { handleError } = useAsyncErrorHandler();
  const {
    apiValidationErrors,
    hasApiValidationErrors,
    setApiValidationErrors,
  } = useTvcPortalApiValidationErrors();

  const [completedSteps, setCompletedSteps] = useState<AddAFleetStep[]>([]);
  const [validFleetSteps, setValidFleetSteps] = useState<FleetStep[]>([
    {
      id: 1,
      label: 'Link Member Org',
    },
    {
      id: 2,
      label: 'Fleet Type',
    },
    {
      id: 3,
      label: 'Additional Info',
    },
    {
      id: 4,
      label: 'Load Limits',
    },
    {
      id: 5,
      label: 'Confirm',
    },
    {
      id: 6,
      label: 'Complete',
    },
  ]);

  const [currentStep, setCurrentStep] = useState<AddAFleetStep>(
    validFleetSteps[0],
  );

  interface FleetStep {
    id: number;
    label: string;
  }

  useEffect(() => {
    if (userProfile) {
      const canEditLoadLimits = Boolean(userProfile?.userPermissions?.canEditFuelLoadLimits);
      const availableFleetSteps = [...validFleetSteps];

      setUserCanEditLoadLimits(canEditLoadLimits);

      if (!canEditLoadLimits) {
        // don't show Load limits step if said user does not have permission
        setValidFleetSteps(availableFleetSteps.filter((fs) => fs.id !== 4));
      } else {
        setValidFleetSteps(availableFleetSteps);
      }
    }
  }, [userProfile]);

  const handleNext = () => {
    const newCompletedSteps = [...completedSteps, currentStep];

    setCompletedSteps(newCompletedSteps);
    setCurrentStep(validFleetSteps[newCompletedSteps.length]);
  };

  const {
    getNewFleetDefaultLimits,
    newFleetDefaultLimits,
    newFleetDefaultMultiplier,
  } = useLoadLimits();

  const [additionalFleetInfo, setAdditionalFleetInfo] = useState<FleetAdditionalInformation>();
  const [loadLimits, setLoadLimits] = useState<LoadLimits>();
  const [loadMultiplier, setLoadMultiplier] = useState<Multiplier | undefined>();
  const dispatch = useAppDispatch();
  const { searchText, setSearchText, searchTextHasValue } = useSearchText();

  const memberOrgs = useSelector((state: RootState) => state.matrix.memberOrgs
    .filter(
      (org) => org.name
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase())
        || org.id.toString().includes(searchText),
    ))
    .filter(
      (org) => (hideDisabledOrgs ? org.productCount !== undefined && org.productCount > 0 : true),
    ) || [];

  const memberOrgsAreLoading = useSelector(
    (state: RootState) => state.matrix.memberOrgsAreLoading,
  );

  const efsCarriers = useSelector(selectCarriers);

  useEffect(() => {
    const initializeModalData = async () => {
      dispatch(fetchMemberOrgs);
      dispatch(fetchCarriers);
      await getNewFleetDefaultLimits('2E5527AE-561E-4C4A-8D63-0B262FD0112E'); // Hard coding for right now, someday in the future will can ask for a parent
    };

    initializeModalData();
  }, [dispatch]);

  const [selectedMemberOrg, setSelectedMemberOrg] = useState<PortalApiMemberOrganization | null>(null);

  useEffect(() => {
    if (selectedMemberOrg?.id) {
      fetchFleetProducts(selectedMemberOrg.id);
    }
  }, [selectedMemberOrg]);

  const [orgNotes, setOrgNotes] = useState<string[]>([]);

  useEffect(() => {
    if (selectedMemberOrg) {
      (async () => {
        try {
          const response = await getMatrixOrgNotes(selectedMemberOrg.id);
          setOrgNotes(response.orgNotes || []);
        } catch (err: any) {
          handleError(err);
        }
      })();
    }
  }, [selectedMemberOrg]);

  const resetStepsInput = () => {
    setSelectedMemberOrg(null);
    setLoadLimits({ useMultiplier: newFleetDefaultLimits?.useMultiplier });
    setLoadMultiplier(newFleetDefaultMultiplier);
    setAdditionalFleetInfo(undefined);
    setIsFleetFuel(false);
  };

  const startOver = () => {
    setApiValidationErrors([]);
    resetStepsInput();
    setCompletedSteps([]);
    setCurrentStep(validFleetSteps[0]);
  };

  useEffect(() => {
    startOver();
  }, [open, validFleetSteps]);

  const handleHideDisabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideDisabledOrgs(event.target.checked);
  };

  const jsxItems = (memberOrgList: PortalApiMemberOrganization[]) => memberOrgList.map((org) => {
    const isSelected = selectedMemberOrg?.id === org.id;
    const isOrgWithNoProduct = org.productCount !== undefined && org.productCount < 1;

    return (
      <div
        key={`member-org-${org.id}`}
        role="listitem"
        onClick={() => {
          if (isOrgWithNoProduct) { return; }
          if (selectedMemberOrg && selectedMemberOrg.id !== org.id) {
            resetStepsInput();
          }
          setSelectedMemberOrg(org);
        }}
        onKeyPress={(e) => {
          if (isOrgWithNoProduct) { return; }
          if (e.key === 'Enter') {
            if (selectedMemberOrg && selectedMemberOrg.id !== org.id) {
              resetStepsInput();
            }
            setSelectedMemberOrg(org);
          }
        }}
      >
        <ListItem key={org.id} button selected={isSelected} disabled={isOrgWithNoProduct}>
          <ListItemText
            primary={org.name.trim() === '' ? '-' : org.name}
            secondary={org.id}
          />
          <ListItemSecondaryAction>
            <Radio checked={isSelected} disabled={isOrgWithNoProduct} />
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  });

  const handleBack = () => {
    const newCompletedSteps = take(completedSteps.length - 1, completedSteps);
    if (!completedSteps.length) {
      onClose();
    }
    setCompletedSteps(newCompletedSteps);
    setCurrentStep(validFleetSteps[newCompletedSteps.length]);
  };

  const onSubmit = (data: FleetAdditionalInformation) => {
    setAdditionalFleetInfo(data);
  };

  interface FleetAdditionalInformationProps {
    efsCarriers: EfsCarrier[];
    // billingDays: BillingDay[];
  }
  const FleetAdditionalInformation = (
    faiProps: FleetAdditionalInformationProps,
  ) => {
    const { efsCarriers: carriers } = faiProps;
    const additionalInfoForm = useForm<FleetAdditionalInformation>({
      mode: 'onChange',
      defaultValues: additionalFleetInfo,
    });

    const {
      watch, formState, handleSubmit,
    } = additionalInfoForm;

    const prodPromoWatch = watch('productPromotions') || [];

    return (
      <FormProvider {...additionalInfoForm}>
        <form>
          <Grid container xs={12} spacing={4} sx={{ paddingTop: '16px' }}>
            <Grid item xs={12} lg={6}>
              <CustomTextInput
                fullWidth
                defaultValue={selectedMemberOrg?.rootMemberDotNumber || ''}
                style={{ flex: 1, display: 'flex' }}
                label="DOT Number"
                name="dotNumber"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomTextInput
                style={{ flex: 1, display: 'flex' }}
                fullWidth
                label="Portal Display Name"
                name="displayName"
                defaultValue={selectedMemberOrg?.name.trim() || ''}
                rules={{
                  required: 'Display Name required',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ visibility: isFleetFuel ? 'visible' : 'hidden' }}>
                <CustomAutocompleteInput<EfsCarrier>
                  itemDisplay={(carrier: EfsCarrier) => {
                    if (!carrier.name.includes(carrier.id.toString())) {
                      return `${carrier.name} - ${carrier.id}`;
                    }
                    return carrier.name;
                  }}
                  itemValue={(v) => v}
                  label="EFS Carrier"
                  defaultValue={isFleetFuel && additionalFleetInfo?.efsCarrier ? find(
                    (carrier) => carrier.id === additionalFleetInfo?.efsCarrier.id,
                    carriers,
                  ) : undefined}
                  items={carriers}
                  required={isFleetFuel}
                  name="efsCarrier"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader headerText="Member Product Configuration(s)" />
            </Grid>

            <Grid item xs={12}>
              <FleetTemplates />
            </Grid>

            <Grid item xs={12}>
              <SectionHeader headerText={`Matrix Org Notes (${orgNotes.length})`} />
              {orgNotes.length && (
                <ScrollableContent
                  style={{ marginTop: '10px' }}
                  maxHeight={140}
                  showBottomOverlay={orgNotes.length > 0}
                  showGradient={false}
                >
                  <List>
                    {orgNotes.map((note, i) => (
                      <ListItem key={i}>
                        <ListItemText>
                          {note}
                        </ListItemText>
                      </ListItem>
                    ))}

                  </List>
                </ScrollableContent>
              )}
            </Grid>
          </Grid>

          <ActionButtons
            primaryButtonTitle="Next"
            secondaryButtonTitle="Back"
            primaryDisabled={!formState.isValid || prodPromoWatch.length < 1}
            isBusy={isFetchingCardcount}
            onPrimaryClick={async () => {
              if (loadMultiplier && userCanEditLoadLimits && isFleetFuel) {
                try {
                  setIsFetchingCardcount(true);
                  const carrierId = additionalInfoForm.getValues('efsCarrier').id;
                  const cardCount = await getCarrierCardCount(carrierId);
                  const newMultiplier: Multiplier = {
                    ...loadMultiplier,
                    multiplier: cardCount.active,
                  };
                  setLoadMultiplier(newMultiplier);
                  setIsFetchingCardcount(false);
                } catch (err) {
                  handleError(err);
                }
              }
              handleSubmit(onSubmit)();
              handleNext();
            }}
            onSecondaryClick={handleBack}
          />
        </form>
      </FormProvider>
    );
  };

  const getConfirmLimitLabel = (
    propName: FleetLimitValidKeys,
    suffix: string = '',
    prefix: string = '$',
  ) => {
    const custom = get(propName, loadLimits);
    const fleetDefault = get(propName, newFleetDefaultLimits);
    const isCustom = Boolean(custom);
    const isFleetDefault = Boolean(fleetDefault);

    return isCustom || isFleetDefault ? (
      <NumericFormat
        displayType="text"
        thousandSeparator
        prefix={prefix}
        suffix={suffix}
        value={custom || fleetDefault}
      />
    ) : (
      'Unlimited'
    );
  };

  const FleetTypeSelection = () => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsFleetFuel(event.target.checked);
    };

    return (
      <>
        <form>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isFleetFuel}
                  onChange={handleChange}
                />
              )}
              label="Will this fleet use fuel?"
              sx={{
                '& .MuiFormControlLabel-label': styles.fleetTypeCheckboxLabel,
              }}
            />
          </div>
          <ActionButtons
            primaryButtonTitle="Next"
            secondaryButtonTitle="Back"
            onPrimaryClick={() => {
              handleNext();
            }}
            onSecondaryClick={handleBack}
          />
        </form>
      </>
    );
  };

  const FleetLoadLimits = () => {
    const form = useForm<LoadLimits>({
      mode: 'all',
      defaultValues: loadLimits,
    });

    const { formState, handleSubmit } = form;

    const onFormSubmit = (data: LoadLimits) => {
      setLoadLimits(data);
    };

    return (
      <FormProvider {...form}>
        <form>

          {isFleetFuel ? (
            <Grid container xs={12} spacing={4}>
              <Grid item xs={12} lg={6}>
                <MinLoadLimitInput
                  defaultLimits={newFleetDefaultLimits}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MaxLoadLimitInput
                  defaultLimits={newFleetDefaultLimits}
                />
              </Grid>
              <Grid item xs={12} lg={6} />
              <Grid item xs={12} lg={6}>
                <LimitPeriodLimitInput
                  defaultLimits={newFleetDefaultLimits}
                />
              </Grid>
              <Grid item xs={12} lg={6} />
              <Grid item xs={12} lg={6}>
                <LimitPeriodAmountLimitInputEdit
                  // Not sure what to pass to limits
                  limits={loadLimits}
                  defaultLimits={newFleetDefaultLimits}
                  defaultMultiplier={loadMultiplier}
                  canEditLoadLimits
                  entityName={additionalFleetInfo?.displayName}
                  labelStyle={{ fontSize: '16px' }}
                />
              </Grid>
            </Grid>
          ) : (
            <div style={{
              textAlign: 'center', padding: '10px', fontStyle: 'italic', fontSize: '16px',
            }}
            >
              Fleet will not be using fuel. Please continue.
            </div>
          )}

          <ActionButtons
            primaryButtonTitle="Next"
            secondaryButtonTitle="Back"
            primaryDisabled={!formState.isValid}
            onPrimaryClick={() => {
              handleSubmit(onFormSubmit)();
              handleNext();
            }}
            onSecondaryClick={handleBack}
          />
        </form>
      </FormProvider>
    );
  };

  const stepContentList: AddaFleetStepContent[] = [
    {
      id: 1,
      content: (
        <>
          <ModalCollectionSearch
            searchBox={(
              <ClearableTextField
                id="search-member-orgs"
                showAdornment={searchTextHasValue}
                onAdornmentClick={() => setSearchText('')}
                value={searchText}
                onChange={(e) => setSearchText(e.currentTarget.value)}
                sx={styles.searchField}
                label="Search for a TVC organization to link"
                variant="standard"
              />
            )}
            pinnedSubheader={(
              <Box>
                <Box>
                  <PinnedSubHeader
                    selectedLabel="Selected Member Org"
                    selectionExists={selectedMemberOrg !== null}
                    chipLabel={`${selectedMemberOrg?.name || selectedMemberOrg?.id}`}
                    onDelete={resetStepsInput}
                  />
                </Box>
                <Box sx={{ marginLeft: '2px' }}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={hideDisabledOrgs}
                        onChange={handleHideDisabledChange}
                        name="showOrgsWithNoProducts"
                        color="primary"
                      />
                    )}
                    label="Hide member orgs that do not have products"
                  />
                  <Box sx={{ fontStyle: 'italic' }}>Member orgs that do not have products are disabled</Box>
                </Box>
              </Box>
            )}
            jsxItems={jsxItems(memberOrgs)}
            pageSizes={[10, 25]}
            defaultPageSize={10}
            resetPagingDependencies={[searchText, hideDisabledOrgs]}
            isLoading={memberOrgsAreLoading}
            entityName="member organization"
          />
          <ActionButtons
            primaryButtonTitle="Next"
            onPrimaryClick={() => {
              if (!selectedMemberOrg) {
                return;
              }

              handleNext();
            }}
            onSecondaryClick={handleBack}
            secondaryButtonTitle="Back"
            primaryDisabled={!selectedMemberOrg}
          />
        </>
      ),
    },
    {
      id: 2,
      content: (
        <FleetTypeSelection />
      ),
    },
    {
      id: 3,
      content: <FleetAdditionalInformation efsCarriers={efsCarriers} />,
    },
    {
      id: 4,
      content: <FleetLoadLimits />,
    },
    {
      id: 5,
      content: (
        <Summary>
          <SummarySection title="Linked Member Organization">
            <ConfirmLabelValue
              label="Portal Display Name"
              value={additionalFleetInfo?.displayName}
            />
            <ConfirmLabelValue
              label="TVC Organization ID"
              value={selectedMemberOrg?.id}
            />
          </SummarySection>

          <SummarySection title="Additional Info">
            <SectionRow>
              <ConfirmLabelValue
                label="DOT #"
                value={getValueOrReplacementValue(additionalFleetInfo?.dotNumber)}
              />
              {isFleetFuel && additionalFleetInfo?.efsCarrier && (
                <ConfirmLabelValue
                  label="EFS Carrier ID"
                  value={additionalFleetInfo?.efsCarrier.id}
                />
              )}
              <ConfirmLabelValue
                label="Display Name"
                value={additionalFleetInfo?.displayName}
              />
            </SectionRow>
            <SectionRow>
              <ConfirmLabelValue
                label="Member Product Configuration(s)"
                value={(
                  <Box sx={{ marginTop: '4px' }}>
                    {additionalFleetInfo?.productPromotions.map((pp, index) => (
                      <Box key={`promotion-${pp.id}`} sx={{ marginBottom: index + 1 !== additionalFleetInfo.productPromotions.length ? '24px' : {} }}>
                        {getProductDisplay(pp.productId)}
                        {' '}
                        <span style={{ fontSize: '0.85em' }}>
                          (
                          {getProductPromotionDisplay(pp.productId, pp.promotionId)}
                          )
                        </span>
                        <Box sx={{ fontSize: '0.85em' }}>
                          <span style={{ fontStyle: 'italic' }}>Display Name:</span>
                          {' '}
                          <span style={{ fontWeight: 'bold', opacity: 0.85 }}>{pp.friendlyName || getProductDisplay(pp.productId)}</span>
                        </Box>
                      </Box>
                    ))}
                  </Box>

                )}
              />

            </SectionRow>
          </SummarySection>

          {userCanEditLoadLimits && isFleetFuel && (
            <SummarySection title="Load Limits">
              <ConfirmLabelValue
                label={(
                  <>
                    Load Minimum Amount
                    {' '}
                    {!loadLimits?.loadMinimumAmount ? (
                      <UsingDefault style={{ marginTop: '0px', marginBottom: '0px' }} />
                    ) : (
                      ''
                    )}
                  </>
                )}
                value={getConfirmLimitLabel('loadMinimumAmount')}
              />
              <ConfirmLabelValue
                label={(
                  <>
                    Maximum Load Amount
                    {' '}
                    {!loadLimits?.loadMaximumAmount ? (
                      <UsingDefault style={{ marginTop: '0px', marginBottom: '0px' }} />
                    ) : (
                      ''
                    )}
                  </>
                )}
                value={getConfirmLimitLabel('loadMaximumAmount')}
              />
              <AllowanceDisplay
                limits={loadLimits}
                defaultLimits={newFleetDefaultLimits}
                defaultMultiplier={loadMultiplier}
                canEditLoadLimits={userCanEditLoadLimits}
                isConfirm
              />
            </SummarySection>
          )}
          <ActionButtons
            isBusy={isFleetCreationBusy}
            primaryButtonTitle="Submit"
            onPrimaryClick={async () => {
              // Need error feedback for these returns
              if (!selectedMemberOrg) {
                return;
              }

              if (!additionalFleetInfo) {
                return;
              }

              if (!loadLimits) {
                return;
              }

              let newFleet: PortalApiAddFleetAndLimits = {
                fleet: {
                  name: additionalFleetInfo.displayName,
                  memberId: selectedMemberOrg.rootMemberId,
                  carrierId: isFleetFuel ? additionalFleetInfo.efsCarrier.id : undefined,
                  organizationId: selectedMemberOrg.id,
                  dotNumber: additionalFleetInfo.dotNumber,
                  isActive: true,
                  productPromotions: additionalFleetInfo.productPromotions,
                },
              };

              if (userCanEditLoadLimits && isFleetFuel) {
                newFleet = {
                  ...newFleet,
                  limit: {
                    loadMinimumAmount: loadLimits.loadMinimumAmount,
                    loadMaximumAmount: loadLimits.loadMaximumAmount,
                    limitType: 2,
                    flatLimitDays: loadLimits.flatLimitDays,
                    flatLimitAmount: loadLimits.flatLimitAmount,
                    useMultiplier: loadLimits.useMultiplier,
                  },
                };
              }

              try {
                setIsFleetAdded(false);
                setIsFleetCreationBusy(true);

                const retFleet = await dispatch(
                  createNewFleetThunk(
                    newFleet,
                    portalApiFleetToFleet,
                    userFleetPermissions,
                  ),
                );
                setIsFleetAdded(true);
                setNewFleetId(retFleet.id);
                setFormIsSubmitted(true);
              } catch (e) {
                const err = e as Error;
                if (!hasApiValidationErrors({ errorResponse: err })) {
                  setAddFleetError(err.message);
                } else {
                  setAddFleetError('There were validation errors (see below)');
                }

                handleError(err, '', false);
              } finally {
                setIsFleetCreationBusy(false);
                handleNext();
              }
            }}
            onSecondaryClick={handleBack}
            secondaryButtonTitle="Back"
            primaryDisabled={!selectedMemberOrg}
          />
        </Summary>
      ),
    },
    {
      id: 6,
      content: (
        <FormSubmissionFeedback
          show
          alertTitle={
            isFleetAdded
              ? `Successfully Added Fleet "${additionalFleetInfo?.displayName}"`
              : 'There was an error during the fleet creation process'
          }
          alertSeverity={isFleetAdded ? 'success' : 'warning'}
          headerText=""
        >
          <Box display="flex" flexDirection="column" flex={1}>
            <Alert
              severity={isFleetAdded ? 'success' : 'error'}
              sx={{ marginBottom: '10px' }}
              variant="standard"
            >
              <AlertTitle sx={{ fontWeight: 'bold' }}>
                Created fleet
              </AlertTitle>
              {isFleetAdded
                ? `This fleet does not have a login. ${newFleetId ? 'Would you like to create a login now?' : ''}`
                : `Error adding "${additionalFleetInfo?.displayName}" - ${addFleetError}`}

              {newFleetId && (
                <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                  <Button variant="contained" color="primary" onClick={() => setAddLoginOpen(true)}>
                    Create Login
                  </Button>

                </div>
              )}
            </Alert>

            <AddFleetUserModal
              open={addLoginOpen}
              onClose={() => setAddLoginOpen(false)}
              userTypeSelection="login"
              modalTitle={`Add Fleet Login for ${additionalFleetInfo?.displayName}`}
              hideUserTypeSelection
              canEditUserPermissions
              fleetIdOverride={newFleetId}
            />
            <ApiValidationErrors
              apiValidationErrors={apiValidationErrors}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '30px',
              }}
            >
              {isFleetAdded && (
                <Link
                  to={`/fleets/${newFleetId}/account`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button color="primary" sx={{ fontWeight: 'bold' }}>
                    Go to "
                    {`${additionalFleetInfo?.displayName}`}
                    " Account
                  </Button>
                </Link>
              )}

              <Button
                color="primary"
                sx={{ fontWeight: 'bold' }}
                onClick={() => {
                  startOver();
                }}
              >
                Add another fleet
              </Button>
            </div>
          </Box>
        </FormSubmissionFeedback>
      ),
    },
  ];

  const getContent = (currentStepId: number): JSX.Element => {
    const currentContent = stepContentList.find((s) => s.id === currentStepId);

    if (currentContent) {
      return currentContent.content;
    }

    return <></>;
  };

  return (
    <DetailsWrapper
      title="Add a Fleet"
      onClose={onClose}
      open={open}
      titlebackgroundcolor={appTheme.palette.secondary.main}
      maxLargeScreenDialogWidth={1300}
      confirmCloseProps={{
        showConfirmCloseModal: !formIsSubmitted
          ? completedSteps.length > 0
          : false,
        confirmTitle: "Close 'Add a Fleet' Dialog?",
        confirmContent:
          "You may have unsaved changes. Are you sure you want to close the 'Add a Fleet' dialog? ",
      }}
      isAppBarSticky
    >
      <Stepper
        alternativeLabel
        sx={styles.stepperRoot}
      >
        {validFleetSteps.map((step) => (
          <Step
            key={step.label}
            active={currentStep.id === step.id}
            completed={completedSteps.includes(step)}
          >
            <StyledStepLabel
              StepIconProps={{
                sx: {
                  '&.Mui-completed': styles.completed,
                  '&.Mui-active': styles.active,
                },
              }}
            >
              {step.label}
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>

      <FormLayout>{currentStep && getContent(currentStep.id)}</FormLayout>
    </DetailsWrapper>
  );
};

export default AddAFleetModal;
