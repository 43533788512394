import { Box, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import CustomTextInput from '../../shared/components/formFields/CustomTextInput';
import { isInvalidEmailString } from '../../shared/utilities/formValidators';
import CustomSelectInput from '../../shared/components/formFields/CustomSelectInput';
import { enumToArray } from '../../shared/utilities/miscHelpers';
import CustomNumberInput from '../../shared/components/formFields/CustomNumberInput';
import CustomTextMaskInput from '../../shared/components/formFields/CustomTextMaskInput';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import StandardError from '../../shared/components/StandardError';
import { selectMigrationBusy, selectMigrationError } from './migrationSlice';
import ApiValidationErrors from '../../shared/components/ApiValidationErrors';

export enum MemberLoginType {
  Username,
  'Member ID',
  'Email Address',
  'Phone Number',
}

export interface MembersLoginFormData {
  loginType: string,
  password: string;
  userName?: string;
  phoneNumber?: string;
  email?: string;
  memberId?: number;
}

interface MatrixLoginFormProps {
  onFormSubmit: (data: MembersLoginFormData) => void;
  validationErrors?: string[];
}

const MatrixLoginForm = (props: MatrixLoginFormProps) => {
  const { onFormSubmit, validationErrors = [] } = props;

  const migrationError = useSelector(selectMigrationError);
  const isBusy = useSelector(selectMigrationBusy);

  const loginForm = useForm<MembersLoginFormData>({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      loginType: 'Email Address',
      userName: '',
      phoneNumber: '',

      password: '',
    },
  });

  const {
    handleSubmit, watch, formState: { isValid },
  } = loginForm;
  const watchLoginType = watch('loginType');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>

          <FormProvider {...loginForm}>
            <form
              style={{ flexDirection: 'column', display: 'flex' }}
              onSubmit={handleSubmit(async (submittedForm) => {
                onFormSubmit(submittedForm);
              })}
            >

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4} xl={4}>
                  <CustomSelectInput<string>
                    name="loginType"
                    items={enumToArray(MemberLoginType)}
                    itemValue={(v) => v}
                    itemDisplay={(v: string) => v}
                    itemKey={(v) => `loginType-${v}`}
                    label="Credential Type"
                    defaultValue="Email Address"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={8} xl={8}>
                  {/* Email Address or Username  */}
                  {(watchLoginType === 'Email Address') && (
                  <>
                    <CustomTextInput
                      name="email"
                      fullWidth
                      label={`Enter ${watchLoginType}`}
                      maxLength={100}
                      rules={{
                        required: `${watchLoginType} is required`,
                        validate: {
                          isValidEmail: (v: string | undefined) => ((v && watchLoginType === 'Email Address')
                            ? isInvalidEmailString(v) ? 'Invalid Email Address' : true
                            : true),
                        },
                      }}
                    />
                  </>
                  )}
                  {(watchLoginType === 'Username') && (
                  <>
                    <CustomTextInput
                      name="userName"
                      fullWidth
                      label={`Enter ${watchLoginType}`}
                      maxLength={100}
                      rules={{
                        required: `${watchLoginType} is required`,
                      }}
                    />
                  </>
                  )}
                  {/* Member ID  */}
                  {(watchLoginType === 'Member ID') && (
                  <CustomNumberInput
                    name="memberId"
                    fullWidth
                    rules={{
                      required: 'Member ID is required',
                    }}
                    label={`Enter ${watchLoginType}`}
                    style={{ flex: 1 }}
                    decimalScale={0}
                    allowNegative={false}
                    validateOnChange
                  />
                  )}
                  {/* Phone Number  */}
                  {(watchLoginType === 'Phone Number') && (
                  <CustomTextMaskInput
                    name="phoneNumber"
                    rules={{
                      required: 'Phone Number is required',
                      validate: {
                        isComplete: (v) => {
                          // eslint-disable-next-line
                          const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                          return pattern.test(v) ? true : 'Please fill out Phone Number';
                        },
                      },
                    }}
                    fullWidth
                    label={`Enter ${watchLoginType}`}
                    maxLength={50}
                    mask="(999) 999-9999"
                  />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    name="password"
                    rules={{
                      required: 'Password is required',
                    }}
                    fullWidth
                    type="password"
                    label="Password"
                    maxLength={100}
                  />
                </Grid>
                <Grid item xs={12}>
                  {migrationError && <StandardError errorTitle={migrationError} style={{ marginBottom: '18px' }} errorDescription="" />}
                  <Box sx={{ marginBottom: '18px' }}>
                    <ApiValidationErrors apiValidationErrors={validationErrors} variant="filled" />
                    <BusySubmitButton
                      disabled={!isValid}
                      fullWidth
                      color="primary"
                      variant="contained"
                      type="submit"
                      actionPending={isBusy}
                    >
                      Login
                    </BusySubmitButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>

        </Grid>
      </Grid>
    </>
  );
};

export default MatrixLoginForm;
