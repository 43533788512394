import { initAxiosInstance } from './utils';

export const useCommonApi = () => {
  const portalClient = initAxiosInstance();

  // Incoming link is provided by the callee
  const getBlobViaLink = async (link: string) => {
    const { data } = await portalClient.get<Blob>(link, {
      responseType: 'blob',
    });

    return data;
  };

  return {
    getBlobViaLink,
  };
};
