import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLoadLimits } from '../../../features/fleet/hooks/useLoadLimits';
import { currencyFormat } from '../../utilities/currencyFormat';
import { CommonLimitInputProps } from './interfaces/CommonLimitInputProps';
import LimitInput from './LimitInput';
import { getValueOrReplacementValue } from '../../utilities/miscHelpers';

const MinLoadLimitInput = (props: CommonLimitInputProps) => {
  const name = 'loadMinimumAmount';
  const {
    defaultLimits,
    showAdornment = true,
    adornmentIsDisabled = false,
    ...rest
  } = props;
  const { maxContract, loadMinimumAmountValidate } = useLoadLimits();
  const form = useFormContext();
  const { watch, trigger } = form;
  const watchLoadMinimumAmount = watch(name);

  useEffect(() => {
    if (watchLoadMinimumAmount) {
      trigger(name);
    }
  }, [watchLoadMinimumAmount]);

  return (
    <LimitInput
      label={`Minimum Load Amount - Default is ${
        getValueOrReplacementValue(currencyFormat(defaultLimits?.loadMinimumAmount), 'Unlimited')
      }`}
      {...rest}
      allowNegative={false}
      thousandSeparator
      showAdornment={showAdornment}
      adornmentIsDisabled={adornmentIsDisabled}
      rules={{
        ...maxContract('Minimum Load Amount'),
        validate: {
          loadMinimumAmountValidate: () => loadMinimumAmountValidate(form),
          useDefault: () => (watchLoadMinimumAmount === defaultLimits?.loadMinimumAmount
            ? `${currencyFormat(
              defaultLimits?.loadMinimumAmount,
            )} is the default value. Please click "USE DEFAULT"`
            : true),
        },
      }}
      name={name}
    />
  );
};

export default MinLoadLimitInput;
