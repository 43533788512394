import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import { parseEnv } from './config';
import SystemDownMessage from './SystemDownMessage';
import './index.css';
import { signInAuthProvider } from './services/authService';
import * as serviceWorker from './serviceWorker';

const init = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  try {
    parseEnv();

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <MsalProvider instance={signInAuthProvider}>
            <App />
          </MsalProvider>
        </Provider>
      </React.StrictMode>,
    );
  } catch (err) {
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <SystemDownMessage />
        </Provider>
      </React.StrictMode>,
    );
  }
};

init();

serviceWorker.unregister();
