import {
  Box,
  List, ListItem, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import HelpDialog from '../../shared/components/HelpDialog';
import { useBreakPoints } from '../../shared/hooks/useBreakPoints';

const AddAUserHelp = () => {
  const { isXsDown } = useBreakPoints();
  const theme = useTheme();
  return (
    <HelpDialog tooltipTitle="Click for help about adding a user" style={{ left: '6px', top: isXsDown ? '-12px' : '-16px' }}>
      <Typography variant="h5" gutterBottom>
        Add a User
      </Typography>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="USER TYPE"
          />
        </ListItem>
      </List>
      <Box sx={{ marginLeft: '36px', marginTop: '-20px' }}>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Login"
              secondary="Select this option if the user you are adding only requires a login."
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Membership *"
              secondary="Select this option if the user you are adding only requires a membership."
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Both *"
              secondary="Select this option if the user you are adding requires both a login and a membership."
            />
          </ListItem>
        </List>
        <Box sx={{
          fontStyle: 'italic', marginTop: '8px', marginLeft: '24px', marginBottom: '18px', color: theme.palette.text.secondary,
        }}
        >
          * Additional fees will appear on your invoice when selecting this option
        </Box>
      </Box>
    </HelpDialog>
  );
};

export default AddAUserHelp;
