export enum TvcCaseCoverageTypes {
  COVERED = 'Covered',
  TWENTYFIVEPERCENT = '25%',
  CSA = 'CSA',
}

export enum TvcCaseStatusTypes {
  DRAFT = 'Draft',
  NEW = 'New',
  OPEN = 'Open',
  PAID = 'Paid',
  PROCESSED = 'Processed',
  CLOSED = 'Closed',
  DELETED = 'Deleted',
}

export type TvcCaseStatus = TvcCaseStatusTypes.DRAFT | TvcCaseStatusTypes.NEW | TvcCaseStatusTypes.OPEN | TvcCaseStatusTypes.PAID | TvcCaseStatusTypes.PROCESSED | TvcCaseStatusTypes.CLOSED | TvcCaseStatusTypes.DELETED;

export enum CaseStatusTypes {
  DRAFT = 'Draft',
  OPEN = 'Open',
  ASSIGNED = 'Assigned',
  CLOSED = 'Closed',
}

export type CaseStatus = CaseStatusTypes.OPEN | CaseStatusTypes.ASSIGNED | CaseStatusTypes.CLOSED | CaseStatusTypes.DRAFT;

export enum CourtDateTypes {
  ARRAIGNMENT = 'Arraignment',
  NEGOTIATION = 'Negotiation',
  PRETRIAL = 'Pre-Trial',
  RESPONSEDATE = 'Response Date',
  TRIAL = 'Trial',
}

export type CourtDateType = CourtDateTypes.ARRAIGNMENT | CourtDateTypes.NEGOTIATION | CourtDateTypes.PRETRIAL | CourtDateTypes.RESPONSEDATE | CourtDateTypes.TRIAL;

export enum CaseCoverageTypes {
  COVERED = 'Covered',
  QUARTER_COVERED = '25%',
  CSA = 'CSA',
}

export type CaseCoverage = CaseCoverageTypes.COVERED | CaseCoverageTypes.QUARTER_COVERED | CaseCoverageTypes.CSA;

export enum CaseVehicleTypes {
  PERSONAL = 'Personal',
  COMMERCIAL = 'Commercial',
}
export type CaseVehicleType = CaseVehicleTypes.PERSONAL | CaseVehicleTypes.COMMERCIAL;

// LegalCaseSummaryModel
export interface CaseSummary {
  caseId: number,
  memberLastName: string,
  memberFirstName: string,
  status: TvcCaseStatus,
  caseStatus: CaseStatus,
  violationDate?: string,
  coverageType: string,
  crossRefId?: string;
  parentLegalCaseId?: number;
  parentCoverageType?: string;
}

export interface CaseEvent {
  id: number;
  caseId: number;
  startDate?: string;
  name: string;
  appearanceRequired?: boolean;
}

interface TrafficTicket {
  ticketNumber: string;
  ticketType: string;
  isOther?: boolean;
  violations: string[];
}

interface LegalCaseEvents {
  past: CaseEvent[];
  future: CaseEvent[];
}
// FleetLegalCase
// "attorney":{
//   "id":8249,
//   "firstName":"G. Rudy ",
//   "lastName":"Hiersche Jr.",
//   "address1":"105 N. Hudson, Ste. 300",
//   "address2":"",
//   "city":"Oklahoma City",
//   "state":"OK",
//   "zip":"73102",
//   "phone":"800-555-8249",
//   "mobile":"888-555-8249",
//   "fax":"405-235-3142",
//   "email":"tvcdevenvironment@prodriver.com"
// }

interface AttorneyInfo {
  id: number;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  email?: string;
  lawFirm?: string;
}

// ChildLegalCaseModel
interface ChildLegalCase {
  caseId: number;
  coverageType: string;
}

// LegalCaseModel
export interface CaseDetails extends CaseSummary {
  organizationName: string;
  organizationId: number;
  memberId: number;
  caseOpenDate?: Date;
  coverageType: CaseCoverage;
  vehicleType: CaseVehicleType;
  isExaminationReportReceived: boolean;
  courtName: string;
  courtAddress1: string;
  courtAddress2: string;
  courtCity: string;
  courtCounty: string;
  courtState: string;
  courtZip: string;
  tickets: TrafficTicket[];
  legalCaseEvents: LegalCaseEvents;
  attorney?: AttorneyInfo;
  lawType?: string;
  draftNotes?: string;
  crossRefId?: string;
  childLegalCases: ChildLegalCase[];
  closeReason: string;
}

interface CsaInfo {
  dotNumber: number;
  phyCountry: string;
  phyState: string;
  phyCity: string;
  phyStreet: string;
  phyZipcode: string;

  totalPowerUnits: number; // Number of Vehicles
  totalDrivers: number; // Number of Drivers
  driverInspections: number; // Number of Inspections
  legalName?: string;
  driverOosRate: number; // Driver OOS %
  driverOosRateNationalAverage: string; // Driver Naional OOS %
  hazmatOosRate: number; // Hazmat OOS %
  hazmatOosRateNationalAverage: string; // Hazmat Naional OOS %

  vehicleOosRate: number; // Vehicle OOS %
  vehicleOosRateNationalAverage: string; // Vehicle Naional OOS %

  authorizedForProperty: string; // Property Yes/No
  authorizedForPassenger: string; // Passenger Yes/No
  authorizedForHouseholdGoods: string; // Household Yes/No
  authorizedForBroker: string; // Broker Yes/No
  // join both below for Property MX#/MX#
  prefix: string; // MC#
  docketNumber?: number; // MX#
}

export interface CsaInfoResponse {
  validationErrors: string[];
  csaInformation: CsaInfo[];
}
