import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useBreakPoints = () => {
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isLgDown = useMediaQuery(theme.breakpoints.down('xl'));
  const isXlDown = useMediaQuery(theme.breakpoints.down('xl'));

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    isXsDown,
    isSmDown,
    isMdDown,
    isLgDown,
    isXlDown,
    isLgUp,
  };
};
