import React from 'react';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import { FilterOption } from '../../../shared/components/search-filter/FilterOption';
import MultiSelectFilter from '../../../shared/components/search-filter/MultiSelectFilter';
import { StatusFilterProps } from '../../../shared/components/search-filter/StatusFilterProps';

export const fleetUsages: FilterOption<Fleet>[] = [
  {
    displayName: 'Fuel',
    key: 'fuel-fleet',
    condition: (f: Fleet) => Boolean(f.carrierId),
  },
  {
    displayName: 'Legal',
    key: 'legal-fleet',
    condition: (f: Fleet) => !f.carrierId,
  },
];

const FleetUsageFilter = (props: StatusFilterProps) => {
  const { value, handleSelection } = props;
  return (
    <MultiSelectFilter<Fleet>
      inputLabel="Usage"
      labelId="filter-fleet-usage-label"
      selectId="filter-fleet-usage-select"
      value={value}
      handleSelection={handleSelection}
      options={fleetUsages}
    />
  );
};

export default FleetUsageFilter;
