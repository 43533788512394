import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { AppThunk, RootState } from '../../app/store';
import { UpdateLoginInfo } from '../../shared/models/user/UpdateLoginInfo';
import { BillingHistoryModel } from '../../shared/models/other/BillingHistoryModel';
import { PortalApiUpdateFleetAndLimits } from '../../shared/models/fleet/PortalApiUpdateFleetAndLimits';
import { PortalApiAddFleetAndLimits } from '../../shared/models/fleet/PortalApiAddFleetAndLimits';
import { PortalApiLoadLimit } from '../../shared/models/fuel/PortalApiLoadLimit';
import { FleetBalance } from '../../shared/models/fuel/FleetBalance';
import { FleetPortalFuelFees } from '../../shared/models/fuel/FleetPortalFuelFees';
import {
  createBlockingThunk,
  createNonBlockingThunk,
} from '../../services/thunk';
import { getUserProfileThunk } from '../auth/authReducer';
import { FleetUserPermissions } from '../auth/permissionNames';
import { toTimeZone } from '../../shared/utilities/dateHelpers';
import { removeMemberOrg } from '../matrix/matrixSlice';
import { removeCarrier } from '../fuel/efsSlice';
import { toggleFleetUserLoginInfo } from '../user/userSlice';
import {
  useFleetApi,
} from '../../services/api/hooks/useFleetApi';
import { Fleet } from '../../shared/models/fleet/Fleet';
import { PortalApiFleet } from '../../shared/models/fleet/PortalApiFleet';
import { useFuelApi } from '../../services/api/hooks/useFuelApi';
import { useMatrixApi } from '../../services/api/hooks/useMatrixApi';
import { MatrixProduct } from '../../shared/types/matrix/MatrixProduct';
import { PermissionCache } from '../../shared/models/permissions/PermissionCache';
import { PortalApiPermission } from '../../shared/models/permissions/PortalApiPermission';
import { LimitLevel } from '../../shared/enums/fuel/LimitLevel';
import { FleetNotificationEmailContactModel } from '../../shared/models/fleet/FleetNotificationEmailContactModel';
import { FleetProductTemplate } from '../../shared/models/fleet/FleetProductTemplate';
import { addAlert } from '../application/applicationSlice';
import { NextAvailableFuelLoadModel } from '../../shared/models/fuel/NextAvailableFuelLoadModel';

const getFleetIndex = (fleetArray: Fleet[], fleetId: string) => fleetArray.findIndex((f) => f.id === fleetId);

export interface FleetState {
  fleets: Fleet[];
  matrixProducts: MatrixProduct[];
  loadFees: FleetPortalFuelFees[];
  isLoadingLoadFees: boolean;
  fuelNotifications: FleetNotificationEmailContactModel[];
  legallNotifications: FleetNotificationEmailContactModel[];
  isLoadingFuelNotifications: boolean;
  isLoadingLegalNotifications: boolean;
  billingHistory: BillingHistoryModel[];
  isLoadingBillingHistory: boolean;
  isLoaddingMatrixProducts: boolean;
  currentFleetNextAvailFundingInfo?: NextAvailableFuelLoadModel;
}

export const initialFleetState: FleetState = {
  fleets: [],
  matrixProducts: [],
  loadFees: [],
  isLoadingLoadFees: false,
  fuelNotifications: [],
  isLoadingFuelNotifications: false,
  billingHistory: [],
  isLoadingBillingHistory: false,
  isLoaddingMatrixProducts: false,
  legallNotifications: [],
  isLoadingLegalNotifications: false,
};

export const fleetSlice = createSlice({
  name: 'fleets',
  initialState: initialFleetState,
  reducers: {
    setFleets(state, action: PayloadAction<Fleet[]>) {
      state.fleets = action.payload;
    },
    setFleetMatrixProducts(state, action: PayloadAction<MatrixProduct[]>) {
      state.matrixProducts = action.payload;
    },
    addFleet(state, action: PayloadAction<Fleet>) {
      state.fleets.push(action.payload);
    },
    updateFleetInfo(state, action: PayloadAction<Fleet>) {
      const { id: fleetId } = action.payload;
      const fleetIndex = getFleetIndex(state.fleets, fleetId);
      if (fleetIndex !== -1) {
        const fleet = state.fleets[fleetIndex];
        const updatedFleet = omit(action.payload, 'cachePermissions');
        state.fleets[fleetIndex] = {
          ...fleet,
          ...updatedFleet,
        };
      }
    },
    updateFleetFuelContractInfo(state, action: PayloadAction<FleetBalance>) {
      const { fleetId, ...fleetBalance } = action.payload;
      const fleetIndex = getFleetIndex(state.fleets, fleetId);

      if (fleetIndex !== -1) {
        const fleet = state.fleets[fleetIndex];
        state.fleets[fleetIndex] = {
          ...fleet,
          contractBalance: fleetBalance.efsBalance,
          contractBalanceAsOf: toTimeZone(fleetBalance.efsBalanceAsOf),
        };
      }
    },
    sortFleets(state) {
      state.fleets = state.fleets.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    fleetLoadFeesRetrieved(state, action: PayloadAction<FleetPortalFuelFees>) {
      const fleetLoadFeesIndex = state.loadFees.findIndex(
        (fpl) => fpl.fleetId === action.payload.fleetId,
      );

      if (fleetLoadFeesIndex === -1) {
        state.loadFees.push(action.payload);
      } else {
        state.loadFees[fleetLoadFeesIndex] = action.payload;
      }
    },
    setIsLoadingLoadFees(state, action: PayloadAction<boolean>) {
      state.isLoadingLoadFees = action.payload;
    },
    fleetFuelNotificaitonsRetrieved(state, action: PayloadAction<FleetNotificationEmailContactModel[]>) {
      state.fuelNotifications = action.payload;
    },
    setIsLoadingFuelNotifications(state, action: PayloadAction<boolean>) {
      state.isLoadingFuelNotifications = action.payload;
    },
    fleetLegalNotificaitonsRetrieved(state, action: PayloadAction<FleetNotificationEmailContactModel[]>) {
      state.legallNotifications = action.payload;
    },
    setIsLoadingLegalNotifications(state, action: PayloadAction<boolean>) {
      state.isLoadingLegalNotifications = action.payload;
    },
    setBillingHistory(state, action: PayloadAction<BillingHistoryModel[]>) {
      state.billingHistory = action.payload;
    },
    setIsLoadingBillingHistory(state, action: PayloadAction<boolean>) {
      state.isLoadingBillingHistory = action.payload;
    },
    setIsLoadingFleetMatrixProducts(state, action: PayloadAction<boolean>) {
      state.isLoaddingMatrixProducts = action.payload;
    },
    updateFleetProductPromotions(state, action: PayloadAction<{ fleetId: string, productPromotions: FleetProductTemplate[] }>) {
      const { fleetId, productPromotions } = action.payload;
      const fleetIndex = getFleetIndex(state.fleets, fleetId);
      if (fleetIndex !== -1) {
        const fleet = state.fleets[fleetIndex];
        state.fleets[fleetIndex] = {
          ...fleet,
          productPromotions: [...fleet.productPromotions, ...productPromotions],
        };
      }
    },
    setNextAvailFundingInfo(state, action: PayloadAction<NextAvailableFuelLoadModel | undefined>) {
      state.currentFleetNextAvailFundingInfo = action.payload;
    },
  },
});

export const {
  setFleets,
  setFleetMatrixProducts,
  addFleet,
  updateFleetInfo,
  sortFleets,
  fleetLoadFeesRetrieved,
  setIsLoadingLoadFees,
  updateFleetFuelContractInfo,
  fleetFuelNotificaitonsRetrieved,
  setIsLoadingFuelNotifications,
  fleetLegalNotificaitonsRetrieved,
  setIsLoadingLegalNotifications,
  setBillingHistory,
  setIsLoadingBillingHistory,
  setIsLoadingFleetMatrixProducts,
  updateFleetProductPromotions,
  setNextAvailFundingInfo,
} = fleetSlice.actions;

// FLEETS
export const selectFleets = (state: RootState) => state.fleets.fleets;
export const selectFleetById = (fleetId: string | undefined) => (state: RootState) => (fleetId
  ? state.fleets.fleets.find((fleet) => fleet.id === fleetId)
  : undefined);

export const selectFleetBillingHistory = (state: RootState) => state.fleets.billingHistory;
export const selectIsLoadingBillingHistory = (state: RootState) => state.fleets.isLoadingBillingHistory;

export const fetchFleets = (includeInactive: boolean = false) => createBlockingThunk<Fleet[]>(
  async (dispatch, getState) => {
    const { getFleets } = useFleetApi();
    dispatch(setFleets([]));
    // const stateFleets = getState().fleets.fleets;
    // if (stateFleets.length) {
    //   return stateFleets;
    // }
    const profile = getState().auth.portalProfile || (await dispatch(getUserProfileThunk()));
    const fleets = await getFleets(profile.permissions, includeInactive);
    dispatch(setFleets(fleets));

    return fleets;
  },
);

export const addFleetMemberProductThunk = (fleetId: string, productPromotions: FleetProductTemplate[]) => createNonBlockingThunk(async (dispatch) => {
  const { postAddMemberSubscriptionProduct } = useFleetApi();

  const addedProductPromotions = await postAddMemberSubscriptionProduct(fleetId, productPromotions);
  dispatch(updateFleetProductPromotions({
    fleetId,
    productPromotions: addedProductPromotions,
  }));
  dispatch(
    addAlert({
      message: 'Member product configuration(s) updated successfully',
      severity: 'success',
    }),
  );
});

export const fetchFleetBillingHistory = (fleetId: string) => createNonBlockingThunk(async (dispatch) => {
  const { getFleetBillingHistory } = useFleetApi();
  dispatch(setBillingHistory([]));
  dispatch(setIsLoadingBillingHistory(true));

  try {
    const billingHistoryResponse = await getFleetBillingHistory(fleetId);
    const billingHistory = billingHistoryResponse.map((bh) => ({
      ...bh,
      paymentDate: bh.paymentDate ? new Date(bh.paymentDate) : undefined,
    }));
    dispatch(setBillingHistory(billingHistory));
    dispatch(setIsLoadingBillingHistory(false));
  } catch (err) {
    dispatch(setIsLoadingBillingHistory(false));
    throw err;
  }
});

export const updateFleetLoginThunk = (fleetId: string, userId: string, loginInfo: UpdateLoginInfo) => createBlockingThunk(async (dispatch) => {
  const { postUpdateFleetLoginInfo } = useFleetApi();
  await postUpdateFleetLoginInfo(fleetId, userId, loginInfo);
  dispatch(toggleFleetUserLoginInfo({ fleetId, userId, newLoginInfo: loginInfo }));
});

export const createNewFleetThunk = (
  newFleet: PortalApiAddFleetAndLimits,
  portalApiFleetToFleetFunc: (
    portalApiFleet: PortalApiFleet,
    permissions: PortalApiPermission[] | null,
    scopePermissions: PortalApiPermission[] | undefined
  ) => Fleet,
  userFleetPermissions: PermissionCache<FleetUserPermissions> | undefined,
): AppThunk<Promise<Fleet>> => createNonBlockingThunk<Fleet>(async (dispatch) => {
  const { postAddNewFleet } = useFleetApi();
  const retFleet = await postAddNewFleet(newFleet);

  const reduxFleet: Fleet = {
    ...portalApiFleetToFleetFunc(retFleet, null, undefined),
    // since a given TVC employee currently has the same Fleet* permissions across all fleets, we can get away with this (for now)
    // However, if we ever introduce the concept of having separate permissions for a TVC employee within a given fleet... we will need to re-vist the
    // permissions being set below

    cachePermissions: userFleetPermissions || undefined,
  };

  dispatch(addFleet(reduxFleet));
  dispatch(sortFleets());
  dispatch(removeMemberOrg(retFleet.organizationId || 0));
  dispatch(removeCarrier(retFleet.carrierId || 0));

  return reduxFleet;
});

export const updateFleetThunk = (
  fleet: PortalApiUpdateFleetAndLimits,
  portalApiFleetToFleetFunc: (
    portalApiFleet: PortalApiFleet,
    permissions: PortalApiPermission[] | null,
    scopePermissions: PortalApiPermission[] | undefined
  ) => Fleet,
): AppThunk<Promise<Fleet>> => createNonBlockingThunk<Fleet>(async (dispatch) => {
  const { postUpdateFleet } = useFleetApi();
  const retFleet = await postUpdateFleet(fleet);
  const reduxFleet = portalApiFleetToFleetFunc(retFleet, null, undefined);
  dispatch(updateFleetInfo(reduxFleet));

  return reduxFleet;
});

// MATRIX PRODUCTS
export const selectFleetMatrixProducts = (state: RootState) => state.fleets.matrixProducts;
export const selectIsLoadingFleetMatrixProducts = (state: RootState) => state.fleets.isLoaddingMatrixProducts;

export const selectMatrixProductById = (productId: number) => (state: RootState) => state.fleets.matrixProducts.find(
  (matrixProduct) => matrixProduct.id === productId,
);

export const fetchFleetMatrixProducts = (orgId: number) => createNonBlockingThunk<MatrixProduct[]>(
  async (dispatch) => {
    const { getOrganizationProducts } = useMatrixApi();
    setIsLoadingFleetMatrixProducts(true);
    const matrixProducts = await getOrganizationProducts(orgId);
    setIsLoadingFleetMatrixProducts(false);
    dispatch(setFleetMatrixProducts(matrixProducts));

    return matrixProducts;
  },
);

export const retrieveContractForFleetThunk = (fleetId: string): AppThunk<Promise<PortalApiLoadLimit>> => async (dispatch, getState) => {
  const fleetIndex = getFleetIndex(getState().fleets.fleets, fleetId);
  const { getFleetLoadLimits } = useFuelApi();
  if (fleetIndex !== -1) {
    const fleet = getState().fleets.fleets[fleetIndex];

    if (fleet?.contractLimit) return fleet.contractLimit;
    // dispatch(setIsLoadingPendingLoads(true));
    const contractLimits = await getFleetLoadLimits(
      fleet.id,
      LimitLevel.applied,
    );
    // TODO: <Y> Figure out how the changing of the inherited limits will update the affected limits
    return contractLimits;
  }
  const contractLimits = await getFleetLoadLimits(
    fleetId,
    LimitLevel.applied,
  );
  return contractLimits;
};

// Fleet loadFees
export const retrieveLoadFeesForFleetThunk = (fleetId: string): AppThunk<Promise<FleetPortalFuelFees>> => async (dispatch, getState) => {
  const stateFleetLoadFees = getState().fleets.loadFees.find(
    (fpl) => fpl.fleetId === fleetId,
  );

  const { getFleetLoadFees } = useFuelApi();

  if (stateFleetLoadFees) {
    return stateFleetLoadFees;
  }
  dispatch(setIsLoadingLoadFees(true));
  try {
    const fleetLoadFees: FleetPortalFuelFees = await getFleetLoadFees(fleetId);
    dispatch(setIsLoadingLoadFees(false));
    dispatch(fleetLoadFeesRetrieved(fleetLoadFees));
    return fleetLoadFees;
  } catch (err) {
    dispatch(setIsLoadingLoadFees(false));
    throw err;
  }
};

export const selectFleetLoadFees = (fleetId: string) => (state: RootState) => state.fleets.loadFees.find((fpl) => fpl.fleetId === fleetId);
export const selectIsLoadingFleetLoadFees = (state: RootState) => state.fleets.isLoadingLoadFees;

// Fleet Next Avail Funding Info
export const fetchFleetNextAvailFundingInfo = (fleetId: string) => createBlockingThunk<NextAvailableFuelLoadModel>(
  async (dispatch) => {
    const { getFleetNextAvailableFundingDate } = useFleetApi();
    dispatch(setNextAvailFundingInfo(undefined));
    const nextAvailInfo = await getFleetNextAvailableFundingDate(fleetId);
    dispatch(setNextAvailFundingInfo(nextAvailInfo));

    return nextAvailInfo;
  },
);

export const selectCurrentFleetNextAvailFundingInfo = (state: RootState) => state.fleets.currentFleetNextAvailFundingInfo;
export const retrieveFuelNotificationsForFleetThunk = (fleetId: string): AppThunk<Promise<FleetNotificationEmailContactModel[]>> => async (dispatch) => {
  try {
    dispatch(fleetFuelNotificaitonsRetrieved([]));

    const { getFleetFuelNotifications } = useFleetApi();

    dispatch(setIsLoadingFuelNotifications(true));
    const fleetFuelNotificaitons: FleetNotificationEmailContactModel[] = await getFleetFuelNotifications(fleetId);
    dispatch(setIsLoadingFuelNotifications(false));

    dispatch(fleetFuelNotificaitonsRetrieved(fleetFuelNotificaitons));

    return fleetFuelNotificaitons;
  } catch (err) {
    dispatch(setIsLoadingFuelNotifications(false));
    throw err;
  }
};

export const selectFleetFuelNotifications = () => (state: RootState) => state.fleets.fuelNotifications;
export const selectIsLoadingFuelNotifications = (state: RootState) => state.fleets.isLoadingFuelNotifications;

export const retrieveLegalNotificationsForFleetThunk = (fleetId: string): AppThunk<Promise<FleetNotificationEmailContactModel[]>> => async (dispatch) => {
  try {
    dispatch(fleetLegalNotificaitonsRetrieved([]));

    const { getFleetLegalNotifications } = useFleetApi();

    dispatch(setIsLoadingLegalNotifications(true));
    const fleetLegalNotificaitons: FleetNotificationEmailContactModel[] = await getFleetLegalNotifications(fleetId);
    dispatch(setIsLoadingLegalNotifications(false));

    dispatch(fleetLegalNotificaitonsRetrieved(fleetLegalNotificaitons));

    return fleetLegalNotificaitons;
  } catch (err) {
    dispatch(setIsLoadingLegalNotifications(false));
    throw err;
  }
};

export const selectFleetLegalNotifications = () => (state: RootState) => state.fleets.legallNotifications;
export const selectIsLoadingLegalNotifications = (state: RootState) => state.fleets.isLoadingLegalNotifications;

export default fleetSlice.reducer;
