import { LinearProgress } from '@mui/material';
import React from 'react';
import { theme } from '../../appTheme';
import DetailsWrapper from '../../shared/components/DetailsWrapper';
import { currencyFormat } from '../../shared/utilities/currencyFormat';

export const negativePositiveFormatting = (isPositive: boolean, value: any) => {
  let color = isPositive ? theme.palette.success.main : theme.palette.error.main;
  if (value === 'N/A') {
    color = theme.palette.text.primary;
  }
  return (
    <span
      style={{
        color,
      }}
    >
      {value}
    </span>
  );
};
export const getCreditDebitFormatting = (
  isCredit: boolean,
  value: number | undefined,
): React.ReactNode => {
  const formattedValue = value
    ? currencyFormat(isCredit ? value : value * -1, 2)
    : '-';

  return negativePositiveFormatting(isCredit, formattedValue);
};

export const getCountryText = (countryChar: string) => {
  switch (countryChar.toUpperCase()) {
    case 'U':
      return 'US';
    case 'C':
      return 'CA';
    default:
      return 'Unknown';
  }
};

export const getHandEnteredText = (efsKey: string) => {
  if (efsKey === 'N') return 'No';
  if (efsKey === 'Y') return 'Yes';

  return 'Unknown';
};
// TODO: There are several modals that use the same properties... refactor them so they all use the same wrapper component and/or interface
interface FuelActivityDetailsProps {
  detailsType?: string;
  open?: boolean;
  children?: React.ReactNode;
  isBusy?: boolean;
  onClose: () => void;
}

const FuelActivityDetails = (props: FuelActivityDetailsProps) => {
  const {
    open = false,
    isBusy = false,
    children,
    onClose,
    detailsType = 'Transaction',
  } = props;

  return (
    <DetailsWrapper
      title={isBusy ? <LinearProgress /> : `${detailsType} Details`}
      open={open}
      onClose={onClose}
      isAppBarSticky
    >
      {!isBusy && children}
    </DetailsWrapper>
  );
};

export default FuelActivityDetails;
