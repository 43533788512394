import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { floor, round } from 'lodash';
import {
  flow, get, map, sum,
} from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { useProfile } from '../../../features/auth/hooks/useProfile';
import PaymentMethodIcon from '../../../features/paymentMethod/components/PaymentMethodIcon';
import { PaymentSubheader } from '../../../features/paymentMethod/components/PaymentMethodSubHeader';
import { PaymentMethod } from '../../models/payment-method/PaymentMethod';
import { PortalApiLoadLimit } from '../../models/fuel/PortalApiLoadLimit';
import { FuelFees } from '../../models/fuel/FuelFees';
import { currencyFormat } from '../../utilities/currencyFormat';
import {
  getOrdinalDisplay, isWeekendDay, longDaysOfWeek, shortDaysOfWeek,
} from '../../utilities/dateHelpers';
import ApiValidationErrors from '../ApiValidationErrors';
import BusySubmitButton from '../BusySubmitButton';
import CustomCheckboxInput from '../formFields/CustomCheckboxInput';
import CustomNumberInput from '../formFields/CustomNumberInput';
import CustomSelectInput from '../formFields/CustomSelectInput';
import CustomTextInput from '../formFields/CustomTextInput';
import LabelValue from '../LabelValue';
import ProcessSteps, { resolveHasStepError } from '../ProcessSteps';
import StandardError from '../StandardError';
import { useBreakPoints } from '../../hooks/useBreakPoints';
import { selectConfiguration } from '../../../features/application/applicationSlice';
import InfoIconText from '../icon-components/InfoIconText';
import { ProcessingFee } from '../../models/fuel/ProcessingFee';
import { TriggeredFundModel } from '../../models/fuel/TriggeredFundModel';
import { ProcessStep } from '../../models/other/ProcessStep';
import { getPaymentMethodDisabledState } from '../../utilities/paymentMethodHelpers';
import { DiscountStatus } from '../../enums/coupon/DiscountStatus';
import { useCoupons } from '../../hooks/useCoupons';
import { ValidationCouponResult } from '../../models/coupon/ValidationCouponResult';
import { useAsyncErrorHandler } from '../../hooks/useTvcAppInsights';
import CustomClearableTextField from '../formFields/CustomClearableTextField';
import FundingLimitAvailabilityDisplay from '../fuel/FundingLimitAvailabilityDisplay';
import { NextAvailableFuelLoadModel } from '../../models/fuel/NextAvailableFuelLoadModel';
import { getValueOrReplacementValue } from '../../utilities/miscHelpers';
import { usePaymentMethods } from '../../../features/paymentMethod/hooks/usePaymentMethods';

export interface LoadFundsForm {
  paymentMethodId: number;
  processingPeriodId: number;
  fundsToAdd: number;
  loadFee: number;
  expediteFee: number;
  applyFeeAdjustment: boolean;
  feeAdjustmentAmount?: number;
  feeAdjustmentReason?: string;
  total: number;
  daysOfWeek: number[];
  scheduledLoadId?: string;
  isFirstFifteenth: boolean;
  firstFifteenth: number[];
  couponCode: string;
  couponUsageId?: string;
}

export interface LoadFuelFundsData {
  paymentMethodId: number;
  processingPeriod: ProcessingFee;
  feeAdjustmentAmount?: number;
  feeAdjustmentReason?: string;
  fundsToAdd: number;
  daysOfWeek: number[];
  isFirstFifteenth: boolean;
  firstFifteenth: number[];
  scheduledLoadId?: string;
  couponUsageId?: string;
}

export interface Coupon {
  consumerId?: string | null;
  isFleet: boolean;
}

interface LoadFundsBaseFormProps {
  loadFees: FuelFees; // Load fees (loadFeeMinimum, loadFeePercentage, and available expediteFees)
  loadLimits: PortalApiLoadLimit; // Load limits (loadMinimumAmount, loadMaximumAmount, limitType, flatLimitDays, flatLimitAmount, limitNodes, multiplier, useMultiplier)
  paymentMethodsFilter?: (pm: PaymentMethod) => boolean; // filter for payment methods if desired
  formIsSubmitted?: boolean; // Form is submitted flag
  formDataIsProcessing?: boolean; // Form data is processing flag
  isScheduledLoad?: boolean; // Should this form be used for a scheduled load or not
  scheduledLoad?: Partial<TriggeredFundModel>; // Scheduled load data (can be undefined if this is a new scheduled load)
  onApplyAdjustmentFeesLessThanZero?: (value: boolean | undefined) => void; // Callback for when the total fees are less than zero if desired
  showEstimatedAvailabilityDate?: boolean; // Show estimated availability date flag
  isSmartCardRegistration?: boolean; // Is this form being used for smart card registration (deprecated)
  coupon?: Coupon; // Coupon data
  onCouponValidated?: (discount: ValidationCouponResult | null) => void; // Callback for when a coupon is validated
  allowCoupon?: boolean; // Allow coupon flag
  nextAvailableFundingInfo?: NextAvailableFuelLoadModel; // Next available funding info
}

interface LoadFundsFormProps extends LoadFundsBaseFormProps {
  apiValidationErrors?: string[]; // API validation errors
  onLoadFundsSubmit: (loadFuelFundsData: LoadFuelFundsData) => Promise<void>; // Submit handler
  onCancel?: () => void; // Cancel handler
  postSubmitContent?: React.ReactNode; // Content to display after form submission
  onDirtyStateChanged?: (isDirty: boolean) => void; // Dirty state change handler (used to notify the parent component when the form is dirty if provided)
  steps?: ProcessStep[]; // Steps to display in the process steps component
  submitButtonText?: string; // Submit button text
}

export const LoadFundsBaseForm = (props: LoadFundsBaseFormProps) => {
  const theme = useTheme();
  const styles = {
    labelValueContainer: {
      minWidth: '280px',
      marginRight: '0px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '10px',
      },
      '& .MuiInputBase-input': {
        textAlign: 'right',
        fontSize: 'unset',
      },
    },
    gridItemOverviewItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    overviewContent: {
      [theme.breakpoints.down('md')]: {
        marginTop: '20px',
      },
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const {
    loadFees,
    loadLimits,
    formIsSubmitted = false,
    formDataIsProcessing = false,
    isScheduledLoad = false,
    scheduledLoad,
    paymentMethodsFilter,
    onApplyAdjustmentFeesLessThanZero,
    showEstimatedAvailabilityDate = true,
    isSmartCardRegistration = false,
    coupon,
    onCouponValidated,
    allowCoupon = true,
    nextAvailableFundingInfo,
  } = props;

  // get reference to the needed form methods and state using the useFormContext hook4
  // useFormContext hook allows us to access the form context from the parent form (the parent form is responsible for providing the context via the FormProvider component)
  const {
    watch, // method used to watch the value of a given field
    setValue, // method used to set the value of a given field
    clearErrors, // method used to clear errors for a given field or fields
    reset, // method used to reset the value of a given field or fields to it's default value
    trigger, // method used to trigger validation for a given field or fields
    formState, // object containing the current form state
  } = useFormContext();

  const { userProfile } = useProfile(); // get the user profile from the useProfile hook
  const { isSmDown } = useBreakPoints(); // get the isSmDown breakpoint from the useBreakPoints hook

  const {
    validationCouponResult, // coupon validation result
    couponIsValid, // coupon is valid flag
    clearCoupon, // method to clear the coupon
    validateCoupon, // method to validate a coupon
  } = useCoupons(); // custom hook for providing coupon functionality

  // set up local state for the LoadFundsBaseForm component
  const [pennyAdjustment, setPennyAdjustment] = useState<number | undefined>(); // local state for any penny adjustments that might need to be made due to rounding
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(); // local state for the selected payment method
  const [addFundsInputIsDisabled, setAddFundsInputIsDisabled] = useState(false); // local state for the add funds input disabled state
  const [availibilityDate, setAvailibilityDate] = useState<string | undefined>('-'); // local state for the availability date
  const [isTotalFeesLessThanZero, setIsTotalFeesLessThanZero] = useState<boolean>(); // local state for if the total fees are less than zero (primarily used during fee adjustment)
  const [checkingCoupon, setCheckingCoupon] = useState<boolean>(false); // local state for checking coupon flag
  const [couponValidated, setCouponValidated] = useState<boolean>(false); // local state for coupon validated flag
  const [isLoadFeesDisabled, setIsLoadFeesDisabled] = useState<boolean>(false);
  const [feeAdjustmentErrorMessage, setfeeAdjustmentErrorMessage] = useState<string>('(expedite fee)'); // local state for the fee adjustment error message

  const { handleError } = useAsyncErrorHandler(); // get the handleError method from the useAsyncErrorHandler hook (used to handle async errors)
  const { paymentMethods } = usePaymentMethods(); // get the payment methods from the usePaymentMethods hook
  const configuration = useSelector(selectConfiguration); // get the configuration from the application state

  // determine the minimum load amount based on the smart card registration flag and the load limits
  const minLoadAmount = (isSmartCardRegistration && loadLimits.minimumLoadAmountForRegistration) ? loadLimits.minimumLoadAmountForRegistration : loadLimits.loadMinimumAmount;

  // useEffect hook to call the onApplyAdjustmentFeesLessThanZero prop (if provided) when the form state becomes dirty and isTotalFeesLessThanZero changes.
  // isTotalFeesLessThanZero is used to determine if the total fees are less than zero (primarily used during fee adjustment)
  useEffect(() => {
    if (formState.isDirty && onApplyAdjustmentFeesLessThanZero) {
      onApplyAdjustmentFeesLessThanZero(isTotalFeesLessThanZero);
    }
  }, [isTotalFeesLessThanZero, formState.isDirty]);

  // function definition for getting the payment method type based on the payment method id
  const getPaymentMethodType = (paymentMethodId?: number) => {
    if (paymentMethodId === undefined) {
      return undefined;
    }
    const paymentMethod = paymentMethods.byId[paymentMethodId];
    return paymentMethod?.paymentMethodType; // BankAccount = 1,  CreditCard = 2,  Check = 3
  };

  // determine the maximum load amount based on the load limits and the c,application onfiguration
  const maxLoadAmount = loadLimits.loadMaximumAmount > configuration.maximumLoadLimit ? configuration.maximumLoadLimit : loadLimits.loadMaximumAmount;

  // setup watch variables for those fields that we want to watch for changes
  // these variables will be used to determine if we need to trigger validation or other actions based on changes to these fields
  // * the watch method will instantly update the value of the variable when the given field changes
  const watchPaymentMethodId = watch('paymentMethodId');
  const watchProcessingPeriod = watch('processingPeriodId');
  const watchTotal = watch('total');
  const watchFundsToAdd = watch('fundsToAdd');
  const watchLoadFee = watch('loadFee');
  const watchExpediteFee = watch('expediteFee');
  const watchFeeAdjustmentAmount = watch('feeAdjustmentAmount');
  const watchApplyFeeAdjustment = watch('applyFeeAdjustment');
  const watchIsFirstFifteenth = watch('isFirstFifteenth');
  const watchCouponCode = watch('couponCode');

  const textAlignRight: React.CSSProperties = { textAlign: 'right' };

  const unFilteredPaymentMethods = paymentMethods.all.map((id) => paymentMethods.byId[id]); // get a reference to all payment methods (unfiltered) in array format
  const filteredPaymentMethods = paymentMethodsFilter ? unFilteredPaymentMethods.filter(paymentMethodsFilter) : unFilteredPaymentMethods; // filter the payment methods if a filter function was provided
  const shouldDisplayCouponLine = validationCouponResult && (validationCouponResult.price !== watchFundsToAdd || validationCouponResult.bonus === 0); // determine if the coupon line should be displayed

  // filter the load fees to only include those that are relevant to the selected payment method
  const relevantLoadFees = loadFees.expediteFees.filter(
    (fee) => fee.paymentMethodType
      === getPaymentMethodType(watchPaymentMethodId),
  );

  // useEffect for resetting the form when formIsSubmitted is false
  useEffect(() => {
    if (!formIsSubmitted) {
      setAvailibilityDate('-');
      reset();
    }
  }, [formIsSubmitted]);

  // funtion definition for resetting the coupon
  const resetCoupon = () => {
    setValue('couponCode', '');
    setValue('couponUsageId', '');
    clearCoupon();
  };

  // useEffect for resetting the coupon and triggering validation for the 'fundsToAdd' and 'couponCode' form fields when watchFundsToAdd changes
  useEffect(() => {
    resetCoupon();
    trigger(['fundsToAdd', 'couponCode']);
  }, [watchFundsToAdd]);

  // useEffect for triggering validation for the 'fundsToAdd' and 'couponCode' form fields when watchCouponCode changes
  useEffect(() => {
    trigger(['fundsToAdd', 'couponCode']);
  }, [watchCouponCode]);

  // useEffect for triggering all form validation when watchIsFirstFifteenth changes
  useEffect(() => {
    trigger();
  }, [watchIsFirstFifteenth]);

  // useEffect for resetting the fee adjustment state when watchApplyFeeAdjustment does not have a value. Always trigger all form validation when watchApplyFeeAdjustment changes (regardless of whether or not it has a value)
  useEffect(() => {
    if (!watchApplyFeeAdjustment) {
      setValue('feeAdjustmentReason', '');
      setValue('feeAdjustmentAmount', 0);
      setTimeout(() => clearErrors(['feeAdjustmentAmount', 'feeAdjustmentReason']), 0);
      setIsTotalFeesLessThanZero(false);
    }
    trigger();
  }, [watchApplyFeeAdjustment]);

  // useEffect to trigger validation for feeAdjustmentAmount field anytime isTotalFeesLessThanZero, watchFeeAdjustmentAmount, or watchApplyFeeAdjustment changes
  useEffect(() => {
    if (watchApplyFeeAdjustment) {
      trigger('feeAdjustmentAmount');
    }
  }, [isTotalFeesLessThanZero, watchFeeAdjustmentAmount, watchApplyFeeAdjustment]);

  useEffect(() => {
    if (loadFees) {
      setIsLoadFeesDisabled(loadFees.loadFeeMinimum === 0 && loadFees.loadFeePercentage === 0);
    }
  }, [loadFees]);

  useEffect(() => {
    setfeeAdjustmentErrorMessage(isLoadFeesDisabled ? '(expedite fee)' : '(expedite fee + load fee)');
  }, [isLoadFeesDisabled]);

  // function definition for getting the corrected funds to add amount
  const correctedFundsToAdd = () => (watchCouponCode && validationCouponResult && validationCouponResult.status === DiscountStatus.Valid ? validationCouponResult.price : watchFundsToAdd || 0);

  // function definition for calculating the expedite fee
  const calculateExpediteFee = () => {
    // invalid state check
    if (!selectedPaymentMethod) {
      return 0;
    }

    // invalid state check
    if (!watchFundsToAdd) {
      return 0;
    }

    // get the processing period based on the selected processing period id
    const processingPeriod = loadFees.expediteFees.find(
      (value) => value.id === watchProcessingPeriod,
    );

    // invalid state check (sanity check)
    if (!watchFundsToAdd) {
      return 0;
    }

    // get the adjusted or actual amount based on the penny adjustment or the corrected funds to add
    const adjustedOrActualAmount = pennyAdjustment || correctedFundsToAdd();

    // calculate the expedite fee
    const expediteFee = !processingPeriod
      ? 0
      : adjustedOrActualAmount * (processingPeriod.percentage / 100);

    // return the expedite fee rounded to 2 decimal places
    return round(expediteFee, 2);
  };

  // function definition for calculating load fee
  const calculateLoadFee = () => {
    // invalid state check
    if (!selectedPaymentMethod) {
      return 0;
    }

    // get the processing period based on the selected processing period id
    const processingPeriod = loadFees.expediteFees.find(
      (period) => period.id === watchProcessingPeriod,
    );

    // invalid state check
    if (!processingPeriod) {
      return 0;
    }

    // invalid state check
    if (!watchFundsToAdd) {
      return 0;
    }

    // invalid state check
    if (!loadFees) {
      return 0;
    }

    // get the adjusted amount based on the penny adjustment or the current value funds to add
    const adjustedOrActualAmount = pennyAdjustment || watchFundsToAdd;

    // calculate the current fee amount
    const currentFeeAmount = adjustedOrActualAmount * (loadFees.loadFeePercentage / 100);

    // calculate the load fee based on whether the currentFeeAmount is less than the loadFeeMinimum (always take the larger amount)
    const loadFee = currentFeeAmount < loadFees.loadFeeMinimum
      ? loadFees.loadFeeMinimum
      : currentFeeAmount;

    return round(loadFee, 2); // return loadFee rounded to 2 decimal places
  };

  // function definition for calculating the funds to add for a check payment method
  const calculateFundsToAddForCheck = () => {
    // get the processing period based on the selected processing period id
    const processingPeriod = loadFees.expediteFees.find(
      (period) => period.id === watchProcessingPeriod,
    );

    // invalid state check
    if (!processingPeriod) {
      return 0;
    }

    // get the percentage from the processing period
    const { percentage } = processingPeriod;

    // invalid state check
    if (!selectedPaymentMethod) {
      return 0;
    }

    // invalid state check
    if (!loadFees) {
      return 0;
    }

    const { limit } = selectedPaymentMethod; // get the limit from the selected payment method
    const feeAdjustment = watchFeeAdjustmentAmount || 0; // get the fee adjustment amount
    const staticLoadFee = (limit - loadFees.loadFeeMinimum - feeAdjustment) / (1 + percentage / 100); // calculate the static load fee
    const calculatedLoadFee = (limit - feeAdjustment) / (1 + (loadFees.loadFeePercentage / 100) + (percentage / 100)); // calculate the calculated load fee
    const loadAmount = round(Math.min(staticLoadFee, calculatedLoadFee), 2); // get the load amount (minimum of the static load fee and the calculated load fee)
    const expediteFee = round(loadAmount * (percentage / 100), 2); // calculate the expedite fee and round to 2 decimal places
    const loadFee = round(Math.max(loadAmount * (loadFees.loadFeePercentage / 100), loadFees.loadFeeMinimum), 2); // calculate the load fee and round to 2 decimal places
    const adjustedPennyAmount = round(loadAmount + limit - loadFee - expediteFee - feeAdjustment - loadAmount, 2); // calculate the adjusted penny amount and round to 2 decimal places
    setPennyAdjustment(loadAmount); // set the penny adjustment to the load amount

    return adjustedPennyAmount;
  };

  // useEffect for resetting the appropriate form fields when watchPaymentMethodId changes and updating the selected payment method.
  // Trigger validation after the form fields are reset and the selected payment method is updated
  useEffect(() => {
    if (watchPaymentMethodId) {
      if (!scheduledLoad?.id) {
        // preserve existing values for scheduled loads that are being edited
        // -- let the form validation do it's job
        setValue('processingPeriodId', undefined);
        setValue('fundsToAdd', 0);
        setValue('loadFee', 0);
        setValue('expediteFee', 0);
        setValue('couponCode', undefined);
      }

      setValue('feeAdjustmentAmount', 0);
      setValue('feeAdjustmentReason', '');
      setValue('applyFeeAdjustment', false);
      setValue('couponCode', '');
      setPennyAdjustment(0);
      setAvailibilityDate('-');
      setTimeout(() => clearErrors([
        'processingPeriodId',
        'feeAdjustmentAmount',
        'feeAdjustmentReason',
        'fundsToAdd',
        'couponCode',
      ]), 0);

      setSelectedPaymentMethod(
        paymentMethods.byId[watchPaymentMethodId],
      );

      setTimeout(() => {
        trigger();
      }, 1);
    }
  }, [watchPaymentMethodId]);

  // useEffect for setting the add funds input disabled state when the selectedPaymentMethod, watchProcessingPeriod, watchApplyFeeAdjustment, or watchFeeAdjustmentAmount changes
  useEffect(() => {
    if (!selectedPaymentMethod) {
      return; // do nothing if selectedPaymentMethod is not set
    }

    // if selectedPaymentMethod is a check, disable the funds to add input and calculate the funds to add for a check
    if (selectedPaymentMethod.isCheck) {
      setAddFundsInputIsDisabled(true); // disable the funds to add input because the funds to add for a check is calculated (not input by user)
      const fundsToAdd = calculateFundsToAddForCheck();

      setValue('fundsToAdd', fundsToAdd); // set the funds to add form field value to the value of fundsToAdd
    } else {
      setAddFundsInputIsDisabled(false); // enable the funds to add input because the selectedPaymentMethod is not a check and a user can input the funds to add
    }
  }, [selectedPaymentMethod, watchProcessingPeriod, watchApplyFeeAdjustment, watchFeeAdjustmentAmount]);

  // useEffect for calculating the load fee and expedite fee when watchProcessingPeriod or watchFundsToAdd changes
  useEffect(() => {
    if (!watchFundsToAdd) {
      return; // invalid state
    }

    setValue('loadFee', calculateLoadFee()); // set the load fee form field value to the calculated load fee
    setValue('expediteFee', calculateExpediteFee()); // set the expedite fee form field value to the calculated expedite fee

    const availDate = loadFees.expediteFees.find((period) => period.id === watchProcessingPeriod)?.availabilityDateDisplay; // get the availability date based on the selected processing period id

    setAvailibilityDate(getValueOrReplacementValue(availDate)); // set the availability date to the availability date display value
  }, [watchProcessingPeriod, watchFundsToAdd, validationCouponResult]);

  const floorWithPrecision = (precision: number) => (num: number) => floor(num, precision); // function definition for flooring a number with a given precision

  // function definition for calculating the total
  const calculateTotal = () => floor(sum([
    watchExpediteFee || 0,
    watchLoadFee || 0,
    watchFeeAdjustmentAmount || 0,
    correctedFundsToAdd()]), 2);

  // function definition for calculating the total fees
  const calculateTotalFees = () => flow(
    map(floorWithPrecision(2)),
    sum,
  )([
    watchExpediteFee || 0,
    watchLoadFee || 0,
    watchFeeAdjustmentAmount || 0,
  ]);

  // useEffect for setting the total form field value when watchExpediteFee, watchLoadFee, watchFeeAdjustmentAmount, watchFundsToAdd, validationCouponResult, or watchCouponCode changes

  useEffect(() => {
    setValue('total', calculateTotal());
  }, [
    watchExpediteFee,
    watchLoadFee,
    watchFeeAdjustmentAmount,
    watchFundsToAdd,
    validationCouponResult,
    watchCouponCode,
  ]);

  // function definition for checking the total fees value
  const checkTotalFeesValue = () => {
    if (!watchFeeAdjustmentAmount) {
      return; // invalid state
    }

    // set the isTotalFeesLessThanZero state based on the total fees value
    if (watchApplyFeeAdjustment && calculateTotalFees() < 0) {
      setIsTotalFeesLessThanZero(true);
    } else if (watchApplyFeeAdjustment) {
      setIsTotalFeesLessThanZero(false);
    }
  };

  // useEffect for checking the total fees value and triggering the feeAdjustmentAmount validation when watchTotal changes
  useEffect(() => {
    checkTotalFeesValue();
    trigger('feeAdjustmentAmount');
  }, [watchTotal]);

  // useEffect for checking the total fees value and triggering the feeAdjustmentReason validation when watchFeeAdjustmentAmount changes
  useEffect(() => {
    checkTotalFeesValue();
    trigger('feeAdjustmentReason');
  }, [watchFeeAdjustmentAmount]);

  // function definition for checking if the coupon code is invalid
  const isInValidCouponLength = () => !watchCouponCode || watchCouponCode.length < 3;

  // function definition for validating a user coupon
  const validateUserCoupon = async () => {
    if (!(coupon && coupon.consumerId)) {
      return true; // invalid state
    }

    setCheckingCoupon(true);
    let couponValid = false; // setup the couponValid flag

    if (!coupon.consumerId || isInValidCouponLength()) {
      // reset coupon state
      clearCoupon();
      setValue('couponUsageId', '');
      couponValid = true;
      if (onCouponValidated) {
        onCouponValidated(null); // if there was a value provided for the onCouponValidated prop, call it with null
      }
    } else {
      clearCoupon(); // clear coupon so we can validate (make sure we don't have any lingering coupon state)
      try {
        const discount = await validateCoupon(coupon, watchCouponCode, watchFundsToAdd); // validate the coupon
        if (discount && discount.status === DiscountStatus.Valid) { // if it's valid
          setValue('couponUsageId', discount.couponUsageId); // set the couponUsageId form field value to the couponUsageId from the discount
          couponValid = true;
        } else {
          setValue('couponUsageId', ''); // set the couponUsageId form field value to undefined when the discount is not valid
          couponValid = false;
        }
        if (onCouponValidated) {
          onCouponValidated(discount); // if there was a value provided for the onCouponValidated prop, call it with null
        }
      } catch (err) {
        handleError(err);
        clearCoupon();
        setCheckingCoupon(false);
      }
    }

    // set the couponValidated state to the couponValid value and finalize the coupon validation
    setCouponValidated(couponValid);
    setCheckingCoupon(false);
    setTimeout(() => {
      trigger();
    }, 1);

    return couponValid;
  };

  // useEffect for resetting the coupon when watchCouponCode changes and does not have a value
  useEffect(() => {
    if (!watchCouponCode) {
      resetCoupon();
    }
  }, [watchCouponCode]);

  // function definition for getting the coupon display
  const getCouponDisplay = () => {
    if (!validationCouponResult || validationCouponResult.status !== DiscountStatus.Valid) {
      return null; // clear the coupon display if the coupon is not valid
    }

    if (validationCouponResult.price !== watchFundsToAdd) {
      return validationCouponResult.price - watchFundsToAdd; // return the difference between the coupon price and the funds to add
    }

    return 'Activated'; // if we get this far... just return 'Activated' for the coupon display because the coupon is valid but there is no actual discount (fringe case scenario for indicating a coupon is valid)
  };

  const isFundingLimitMet = Boolean(nextAvailableFundingInfo?.nextFundingDate); // determine if the funding limit is met based on the next available funding date
  const lockDownFunding = userProfile?.isEmployee ? false : isFundingLimitMet; // determine if funding is locked down based on the funding limit being met and whether or not the user is an employee
  const isSmartCardRegistrationOrIsScheduledLoad = isSmartCardRegistration || isScheduledLoad; // determine whether or not this form is for smart card registration/scheduled load
  const enforceNextAvailableValidation = userProfile?.isEmployee ? false : !isSmartCardRegistrationOrIsScheduledLoad && !isFundingLimitMet; // if user is not an employee, enforce the next available validation

  // RENDER
  return (
    <>
      <Grid item xs={12} md={6} sx={{ paddingRight: '0px' }}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <FundingLimitAvailabilityDisplay show={!isSmartCardRegistrationOrIsScheduledLoad} nextAvailableFundingInfo={nextAvailableFundingInfo} availableFundingTestID="load-fuel-funds-available-funding-display" />
          </Grid>
          <Grid item xs={12}>
            {filteredPaymentMethods.length === 0 || loadFees.expediteFees.length === 0 || lockDownFunding
              ? (
                // Don't render the form
                // -- If there are no payment methods, no processing periods... show the appropriate error message.
                // -- In the case where lockDownFunding is true... we don't render anything at all. But, we don't want to show the load funds form at all.
                <>
                  {filteredPaymentMethods.length === 0 && (
                    <StandardError
                      errorTitle="No payment methods found"
                      errorDescription=""
                      style={{ alignItems: 'center' }}
                      styleTitle={{ marginTop: 0, marginBottom: 0 }}
                    />
                  )}
                  {loadFees.expediteFees.length === 0 && (
                    <StandardError
                      errorTitle="No processing periods found"
                      errorDescription=""
                      style={{ alignItems: 'center' }}
                      styleTitle={{ marginTop: 0, marginBottom: 0 }}
                    />
                  )}
                </>
              )
              : (
                // Render the form elements (non error state or funding is not locked down)
                <CustomSelectInput<PaymentMethod>
                  disabled={
                    formDataIsProcessing || !filteredPaymentMethods.length
                  }
                  items={filteredPaymentMethods}
                  itemDisplay={(pm: PaymentMethod) => {
                    const { name } = pm;

                    return (
                      <div style={{ minHeight: '112px', display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                          <PaymentMethodIcon paymentMethod={pm} isDisabled={getPaymentMethodDisabledState(pm, userProfile?.isEmployee, false) || formDataIsProcessing} />
                        </ListItemIcon>
                        <ListItemText
                          primary={name}
                          secondary={
                            <PaymentSubheader paymentMethod={pm} />
                          }
                        />
                      </div>
                    );
                  }}
                  itemDisabled={(pm: PaymentMethod) => getPaymentMethodDisabledState(pm, userProfile?.isEmployee, false)}
                  itemKey={get('id')}
                  rules={{
                    required: 'Payment Method required',
                    validate: {
                      scheduledLoadPreviousPaymentMethodCheck: (value) => {
                        const pm = filteredPaymentMethods.find((p) => p.id === value);
                        const isInvalid = isScheduledLoad && (!pm || (pm && !pm.isAvailable));

                        return (isInvalid
                          ? 'Previous configured payment method is now invalid. Please choose another'
                          : true);
                      },
                    },
                  }}
                  itemValue={get('id')}
                  data-testid="payment-method-select"
                  label="Payment Method"
                  name="paymentMethodId"
                  fullWidth
                  selectStyle={{ minHeight: '124px' }}
                />
              )}
          </Grid>
          <Grid item xs={12}>
            <CustomSelectInput<ProcessingFee>
              defaultValue=""
              disabled={formDataIsProcessing}
              items={relevantLoadFees}
              itemDisplay={get('display')}
              data-testid="processing-period-select"
              rules={{
                required: 'Processing Period required',
                validate: {
                  scheduledLoadPreviousProcessingPeriodIdPaymentMethodCheck: (value) => {
                    const isInvalid = isScheduledLoad && !relevantLoadFees.find((fee) => fee.id === value);

                    if (isInvalid) {
                      setValue('processingPeriodId', undefined);
                      setValue('loadFee', 0);
                      setValue('expediteFee', 0);
                    }
                    return isInvalid ? 'Previous configured expedite fee is now invalid. Please choose another' : true;
                  },
                },
              }}
              fullWidth
              itemKey={get('id')}
              itemValue={get('id')}
              label="Processing Period (business days)"
              name="processingPeriodId"
              style={{
                visibility: watchPaymentMethodId
                  ? 'visible'
                  : 'hidden',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomNumberInput
              disabled={
                addFundsInputIsDisabled || formDataIsProcessing
              }
              name="fundsToAdd"
              fullWidth
              label="Funds to Add"
              style={{
                visibility: watchProcessingPeriod
                  ? 'visible'
                  : 'hidden',
              }}
              // allowNegative={selectedPaymentMethod && selectedPaymentMethod.isCheck}
              allowNegative={false}
              data-testid="funds-to-add-input"
              thousandSeparator
              prefix="$"
              decimalScale={2}
              rules={{
                required: 'Funds to Add is required',
                validate: {
                  loadLimit: (loadValue: number) => (loadValue >= minLoadAmount && loadValue <= maxLoadAmount ? true : `Amount must be between ${currencyFormat(minLoadAmount)} and ${currencyFormat(maxLoadAmount)}`),
                  notZero: (value) => (value === 0
                    ? 'Funds to Add must not be zero'
                    : true),
                  exceedsCheckAmount: (value) => ((selectedPaymentMethod && selectedPaymentMethod.isCheck && value && value > selectedPaymentMethod.limit) ? 'Funds to Add must not exceed check amount' : true),
                  checkNextAvail: (value: number) => (enforceNextAvailableValidation && nextAvailableFundingInfo?.availableToFund
                    ? value > nextAvailableFundingInfo.availableToFund ? `Current available funding is ${currencyFormat(nextAvailableFundingInfo.availableToFund)}.` : true
                    : true),
                },
              }}
              validateOnChange
            />
          </Grid>

          {/* rendering logic for displaying the coupon section  */}
          {coupon && !isScheduledLoad && allowCoupon && (
            <Grid item xs={12} sx={{ display: watchFundsToAdd ? 'flex' : 'none', alignItems: 'center' }}>
              <CustomClearableTextField
                data-testid="promo-code-input"
                disabled={formDataIsProcessing}
                name="couponCode"
                fullWidth
                label="Promo Code"
                showAdornment={!!watchCouponCode}
                onKeyDown={(e) => {
                  if (e.key.toLowerCase() === 'enter') {
                    e.preventDefault();
                    if (!isInValidCouponLength()) {
                      validateUserCoupon();
                    }
                  }
                }}
                onAdornmentClick={() => {
                  resetCoupon();
                }}
                style={{
                  display:
                    watchFundsToAdd
                      ? 'flex'
                      : 'none',
                  flex: 1,
                  marginRight: '16px',
                }}
                rules={{
                  validate: {
                    couponValid: () => {
                      if (couponIsValid || (!validationCouponResult && watchCouponCode === '')) {
                        return true;
                      }
                      return getValueOrReplacementValue(validationCouponResult?.statusDescription, 'Invalid Promo Code');
                    },
                  },
                }}
              />
              <BusySubmitButton
                data-testid="submit-coupon-btn"
                color="primary"
                variant="contained"
                disabled={checkingCoupon || formDataIsProcessing || !watchCouponCode}
                actionPending={checkingCoupon}
                applyInvisibleBackdrop={false}
                onClick={() => {
                  if (!isInValidCouponLength()) {
                    validateUserCoupon();
                  }
                }}
                size="small"
              >
                Apply
              </BusySubmitButton>
            </Grid>
          )}

          {/* rendering logic for displaying the fee adjustment section  */}
          {userProfile?.userPermissions?.canAddFuelAdjustment && isScheduledLoad
            && (
              <Grid
                item
                container
                xs={12}
                style={{
                  visibility:
                    watchFundsToAdd
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <InfoIconText
                  text="Fee adjustments cannot be applied to scheduled loads"
                />
              </Grid>
            )}

          {/* only display this section if the form is being used for a scheduled load */}
          {isScheduledLoad && (
            <>
              <Grid
                item
                container
                xs={12}
                style={{
                  display:
                    watchFundsToAdd
                      ? 'flex'
                      : 'none',
                }}
              >
                <CustomCheckboxInput
                  name="isFirstFifteenth"
                  label="Use Monthly Occurrence Criteria"
                  isVisible
                  color="primary"
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{
                  display:
                    !watchIsFirstFifteenth
                      ? 'flex'
                      : 'none',
                }}
              >
                <CustomSelectInput<number>
                  items={[0, 1, 2, 3, 4, 5, 6]}
                  itemDisplay={(dayOfWeek: number) => (
                    <span style={{ color: !configuration.areWeekendsAvailableForScheduledLoad && isWeekendDay(dayOfWeek) ? theme.palette.grey[400] : 'initial' }}>
                      {longDaysOfWeek[dayOfWeek]}
                    </span>
                  )}
                  renderValue={(renderValues) => (
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      {(renderValues as number[]).sort((a, b) => a - b).map((day) => (
                        <span
                          key={`day-of-week-${day}`}
                          style={{
                            display: 'inline-block',
                            marginRight: '12px',
                            color: !configuration.areWeekendsAvailableForScheduledLoad && isWeekendDay(day)
                              ? theme.palette.error.main
                              : formDataIsProcessing
                                ? theme.palette.grey[400]
                                : 'initial',
                          }}
                        >
                          {isSmDown ? shortDaysOfWeek[day] : longDaysOfWeek[day]}
                        </span>
                      ))}
                    </div>
                  )}
                  data-testid="day-of-week-schedule-select"
                  rules={{
                    validate: {
                      required: (value: number[]) => (!watchIsFirstFifteenth && !value.length ? 'Weekly Occurrence Criteria is required' : true),
                      checkForNonNumbers: (value?: number[]) => {
                        const condition = (v: number) => Number.isNaN(v);
                        return (!watchIsFirstFifteenth && value && value.some(condition) ? `Illegal values detected: ${value.filter(condition).toString()} ` : true);
                      },
                      checkWeekendsAllowed: (value?: number[]) => (!watchIsFirstFifteenth && !configuration.areWeekendsAvailableForScheduledLoad ? value && value.some(isWeekendDay) ? 'Weekend days are not allowed' : true : true),
                    },
                  }}
                  fullWidth
                  itemKey={(item) => item}
                  itemValue={(item) => item}
                  label="Weekly Occurrence Criteria *"
                  name="daysOfWeek"
                  style={{
                    visibility: watchFundsToAdd
                      ? 'visible'
                      : 'hidden',
                  }}
                  multiple
                  disabled={formDataIsProcessing}
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                sx={{
                  display:
                    watchIsFirstFifteenth
                      ? 'flex'
                      : 'none',
                }}
              >
                <CustomSelectInput<number>
                  items={[1, 15]}
                  itemDisplay={(day: number) => (
                    getOrdinalDisplay(day)
                  )}
                  renderValue={(renderValues) => (
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      {(renderValues as number[]).sort((a, b) => a - b).map((day) => (
                        <span
                          key={`fofs-${day}`}
                          style={{
                            display: 'inline-block',
                            marginRight: '12px',
                            color: formDataIsProcessing
                              ? theme.palette.grey[400]
                              : 'initial',
                          }}
                        >
                          {getOrdinalDisplay(day)}
                        </span>
                      ))}
                    </div>
                  )}
                  data-testid="first-or-fifteenth-schedule-select"
                  rules={{
                    validate: {
                      required: (value: number[]) => (watchIsFirstFifteenth && !value.length ? 'Monthly Occurrence Criteria is required' : true),
                      checkForNonNumbers: (value?: number[]) => {
                        const condition = (v: number) => Number.isNaN(v);
                        return (watchIsFirstFifteenth && value && value.some(condition) ? `Illegal values detected: ${value.filter(condition).toString()} ` : true);
                      },
                    },
                  }}
                  fullWidth
                  itemKey={(item) => item}
                  itemValue={(item) => item}
                  label="Monthly Occurrence Criteria *"
                  name="firstFifteenth"
                  style={{
                    visibility: watchFundsToAdd
                      ? 'visible'
                      : 'hidden',
                  }}
                  multiple
                  disabled={formDataIsProcessing}
                />
              </Grid>
            </>

          )}

          {/* only display this section if the given user has the permission to add a fuel adjustment and the form is not being used for a scheduled load */}
          {userProfile?.userPermissions?.canAddFuelAdjustment && !isScheduledLoad && (
            <>
              <Grid
                item
                container
                xs={12}
                sx={{
                  visibility:
                    userProfile?.userPermissions
                      ?.canAddFuelAdjustment && watchFundsToAdd
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <CustomCheckboxInput
                  name="applyFeeAdjustment"
                  label="Apply fee adjustment?"
                  isVisible
                  color="primary"
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                sx={{
                  display: watchApplyFeeAdjustment
                    ? 'block'
                    : 'none',
                }}
              >
                <CustomNumberInput
                  disabled={formDataIsProcessing}
                  name="feeAdjustmentAmount"
                  fullWidth
                  label="Fee Adjustment"
                  style={{ flex: '1' }}
                  data-testid="fee-adjustment-input"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  rules={{
                    validate: {
                      notZero: (value) => (watchApplyFeeAdjustment
                        && (value === 0 || !value)
                        ? 'Fee Adjustment must not be zero'
                        : true),
                      totalFeesNotLessThanZero: () => (watchApplyFeeAdjustment
                        && isTotalFeesLessThanZero
                        ? `Fee adjustment cannot cause the calculated fees to be less than 0. Calculated fees are currently: ${currencyFormat(
                          (watchExpediteFee || 0) + (watchLoadFee || 0),
                          2,
                        )
                        } ${feeAdjustmentErrorMessage}`
                        : true),
                      maxFeeAdjustment: (value) => (value > 10 ? `Fee adjustment cannot be more than ${currencyFormat(10, 2)}` : true),
                    },
                  }}
                />
                <CustomTextInput
                  disabled={formDataIsProcessing}
                  name="feeAdjustmentReason"
                  fullWidth
                  label="Adjustment Reason"
                  style={{ flex: '1' }}
                  data-testid="fee-adjustment-reason-input"
                  rules={{
                    validate: {
                      require: (value: string) => (watchApplyFeeAdjustment
                        && watchFeeAdjustmentAmount
                        && (watchFeeAdjustmentAmount > 0
                          || watchFeeAdjustmentAmount < 0)
                        && !value?.length
                        ? 'Adjustment Reason is required'
                        : true),
                    },
                  }}
                />
              </Grid>
            </>
          )}
          {/* only display this section if the form is being used for a scheduled load */}
          {scheduledLoad?.id
            && (
              <Grid item xs={12}>
                <CustomTextInput
                  disabled
                  name="scheduledLoadId"
                  fullWidth
                  label="Scheduled Load ID"
                  style={{ flex: '1' }}
                />
              </Grid>
            )}
        </Grid>

      </Grid>

      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid
          container
          xs={12}
          spacing={2}
          sx={styles.overviewContent}
        >
          {/* only display this section if the form is is not being used for a scheduled load and showEstimatedAvailabilityDate is true  */}
          {!isScheduledLoad && showEstimatedAvailabilityDate && (
            <Grid
              item
              xs={12}
              sx={styles.gridItemOverviewItem}
            >
              <LabelValue
                label="Estimated Availability Date"
                boxStyles={styles.labelValueContainer}
                labelStyle={textAlignRight}
                valueStyle={textAlignRight}
                value={<span data-testid="est-availability-date-display">{availibilityDate}</span>}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={styles.gridItemOverviewItem}
          >
            <LabelValue
              label={`${validationCouponResult?.bonus ? 'Original ' : ''}Funds to Add`}
              boxStyles={styles.labelValueContainer}
              labelStyle={textAlignRight}
              valueStyle={textAlignRight}
              value={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <NumericFormat
                    prefix="$"
                    thousandSeparator
                    data-testid="funds-to-add-display"
                    fixedDecimalScale
                    decimalScale={2}
                    displayType="text"
                    value={watchFundsToAdd}
                    style={{ height: '28px' }}
                  />
                </div>
              )}
            />
          </Grid>

          {/* only show this section if the discount status is valid and the bonus is greater than 0 */}
          {validationCouponResult?.status === DiscountStatus.Valid && validationCouponResult?.bonus
            ? (
              <Grid
                item
                container
                xs={12}
                sx={styles.gridItemOverviewItem}
              >
                <LabelValue
                  label={<span style={{ color: theme.palette.success.main }}>Promo Bonus</span>}
                  boxStyles={styles.labelValueContainer}
                  labelStyle={textAlignRight}
                  valueStyle={textAlignRight}
                  value={(
                    <div style={{ display: 'flex', justifyContent: 'flex-end', color: theme.palette.success.main }}>
                      +
                      {' '}
                      <NumericFormat
                        prefix="$"
                        thousandSeparator
                        data-testid="promo-bonus-display"
                        fixedDecimalScale
                        decimalScale={2}
                        displayType="text"
                        value={validationCouponResult.bonus}
                        style={{ height: '28px' }}
                      />
                    </div>
                  )}
                />
              </Grid>
            ) : undefined}

          {/* only show this section if the validationCouponResult bonus is greater than 0 */}
          {validationCouponResult?.bonus
            ? (
              <Grid
                item
                container
                xs={12}
                sx={styles.gridItemOverviewItem}
              >
                <LabelValue
                  label="Total Funds to Add"
                  boxStyles={styles.labelValueContainer}
                  labelStyle={textAlignRight}
                  valueStyle={textAlignRight}
                  value={(
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <NumericFormat
                        prefix="$"
                        thousandSeparator
                        data-testid="funds-to-load-display"
                        fixedDecimalScale
                        decimalScale={2}
                        displayType="text"
                        value={(watchFundsToAdd + validationCouponResult.bonus)}
                        style={{ height: '28px', fontWeight: 'bold' }}
                      />
                    </div>
                  )}
                />
              </Grid>
            ) : undefined}

          <Grid
            item
            xs={12}
            sx={styles.gridItemOverviewItem}
          >
            <LabelValue
              label="Expedite Fee"
              boxStyles={styles.labelValueContainer}
              labelStyle={textAlignRight}
              valueStyle={textAlignRight}
              value={(
                <NumericFormat
                  prefix="$"
                  thousandSeparator
                  data-testid="expedite-fee-display"
                  fixedDecimalScale
                  decimalScale={2}
                  displayType="text"
                  value={getValueOrReplacementValue(watchExpediteFee)}
                  style={{ height: '28px', display: 'block' }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={[styles.gridItemOverviewItem, isLoadFeesDisabled ? { display: 'none' } : {}]}
          >
            <LabelValue
              label="Load Fee"
              boxStyles={styles.labelValueContainer}
              labelStyle={textAlignRight}
              valueStyle={textAlignRight}
              value={(
                <NumericFormat
                  prefix="$"
                  thousandSeparator
                  data-testid="load-fee-display"
                  fixedDecimalScale
                  decimalScale={2}
                  displayType="text"
                  value={getValueOrReplacementValue(watchLoadFee)}
                  style={{ height: '28px', display: 'block' }}
                />
              )}
            />
          </Grid>

          {/* only show this section if the validationCouponResult status is valid and shouldDisplayCouponLine is true */}
          {validationCouponResult?.status === DiscountStatus.Valid && shouldDisplayCouponLine && (
            <Grid
              item
              xs={12}
              sx={styles.gridItemOverviewItem}
            >
              <LabelValue
                label={<span style={{ color: theme.palette.success.main }}>Promo</span>}
                boxStyles={styles.labelValueContainer}
                labelStyle={textAlignRight}
                valueStyle={textAlignRight}
                value={(
                  getCouponDisplay() === 'Activated'
                    ? (
                      <span
                        style={{
                          borderRadius: '4px',
                          display: 'inline-block',
                          paddingRight: '4px',
                          paddingLeft: '4px',
                          backgroundColor: theme.palette.success.main,
                          color: theme.palette.common.white,
                        }}
                        data-testid="activated-coupon-display"
                      >
                        Activated
                      </span>
                    )
                    : (
                      <NumericFormat
                        prefix="$"
                        thousandSeparator
                        data-testid="coupon-discount-display"
                        fixedDecimalScale
                        decimalScale={2}
                        displayType="text"
                        value={getValueOrReplacementValue(getCouponDisplay())}
                        style={{ height: '28px', display: 'block', color: theme.palette.success.main }}
                      />
                    )
                )}
              />
            </Grid>
          )}

          {/* only display this section if the given user has the permission to add a fuel adjustment */}
          {userProfile?.userPermissions?.canAddFuelAdjustment && (
            <Grid
              item
              xs={12}
              sx={{
                ...styles.gridItemOverviewItem,
                display: watchApplyFeeAdjustment ? 'flex' : 'none',
              }}
            >
              <LabelValue
                label="Fee Adjustment"
                boxStyles={styles.labelValueContainer}
                labelStyle={textAlignRight}
                valueStyle={textAlignRight}
                value={(
                  <NumericFormat
                    displayType="text"
                    value={getValueOrReplacementValue(watchFeeAdjustmentAmount)}
                    style={{ height: '28px', display: 'block' }}
                    prefix="$"
                    decimalScale={2}
                    thousandSeparator
                    data-testid="fee-adjustment-display"
                    fixedDecimalScale
                  />
                )}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={styles.gridItemOverviewItem}
          >
            <LabelValue
              emphasize
              label="Total Charge"
              boxStyles={styles.labelValueContainer}
              labelStyle={textAlignRight}
              value={(
                <CustomNumberInput
                  name="total"
                  prefix="$"
                  decimalScale={2}
                  data-testid="total-display"
                  style={{ flex: '1' }}
                  thousandSeparator
                  displayOnly
                  placeholder="-"
                  label=""
                  fixedDecimalScale
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={couponValidated} autoHideDuration={6000} onClose={() => setCouponValidated(false)}>
        <Alert onClose={() => setCouponValidated(false)} severity="success">
          Promo Code Accepted
        </Alert>
      </Snackbar>
    </>
  );
};

// The LoadFuelFundsForm component is a form that allows the user to load funds to their contract or smart card. This form is used to create load fund requests or to create/edit scheduled loads.
const LoadFuelFundsForm = (props: LoadFundsFormProps) => {
  const {
    loadFees,
    loadLimits,
    onLoadFundsSubmit,
    apiValidationErrors = [],
    postSubmitContent,
    formIsSubmitted = false,
    formDataIsProcessing = false,
    onCancel,
    onDirtyStateChanged,
    steps = [],
    isScheduledLoad = false, // Indicates whether this form is for a scheduled load or not
    scheduledLoad, // If isScheduledLoad is true but this prop DOES NOT have a vlue, this form is for creating a new scheduled load. If isScheduledLoad is true and this prop DOES have a vlue, this form is for editing an existing scheduled load.
    submitButtonText = 'Add Funds',
    paymentMethodsFilter,
    coupon,
    allowCoupon,
    nextAvailableFundingInfo,
  } = props;

  const scheduledLoadHasFeeAdjustment = Boolean(scheduledLoad?.feeAdjustmentAmount);

  // The useForm hook from react-hook-form is used to manage form state.
  // The form fields are initialized with default values based on the scheduledLoad prop if present.
  // Otherise, the form fields are initialized with undefined values to allow the user to input new values for a new scheduled load or load funds request.
  const form = useForm<Partial<LoadFundsForm>>({
    mode: 'all',
    defaultValues: {
      paymentMethodId: scheduledLoad?.accountId || undefined,
      processingPeriodId: scheduledLoad?.feeId || undefined,
      feeAdjustmentAmount: scheduledLoad?.feeAdjustmentAmount || undefined,
      feeAdjustmentReason: scheduledLoad?.feeAdjustmentReason || undefined,
      applyFeeAdjustment: scheduledLoadHasFeeAdjustment,
      daysOfWeek: [],
      scheduledLoadId: scheduledLoad?.id,
      isFirstFifteenth: scheduledLoad?.isFirstFifteenth || false,
      firstFifteenth: [],
    },
  });

  // extract the needed form methods and properties from the react hook form object
  const {
    handleSubmit, watch, formState,
  } = form;

  // get access to the Material UI theme object
  const theme = useTheme();

  const formContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };

  const styles = {
    labelValueContainer: {
      minWidth: '280px',
      marginRight: '0px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '10px',
      },
      '& .MuiInputBase-input': {
        textAlign: 'right',
        fontSize: 'unset',
      },
    },
    gridItemOverviewItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    overviewContent: {
      [theme.breakpoints.down('md')]: {
        marginTop: '20px',
      },
    },
    formContainer: formContainerStyles,
  };

  // If an onDirtyStateChanged prop value was provided, this useEffect hook is used to call the onDirtyStateChanged callback whenever the form's dirty state changes.
  // The dirty state is a boolean that indicates whether the user has interacted with any of the form's inputs.
  useEffect(() => {
    if (onDirtyStateChanged) {
      onDirtyStateChanged(form.formState.isDirty);
    }
  }, [form.formState.isDirty]);

  const [isTotalFeesLessThanZero, setIsTotalFeesLessThanZero] = useState<boolean>();
  const watchApplyFeeAdjustment = watch('applyFeeAdjustment');

  // The handleLoadSubmit function is defined to handle form submissions. This function prepares the data to be submitted (transforming LoadFundsForm to LoadFuelFundsData) and calls the onLoadFundsSubmit callback with this data.
  const handleLoadSubmit = async (loadData: LoadFundsForm) => {
    const processingPeriod = loadFees.expediteFees.find(
      (period) => period.id === loadData.processingPeriodId,
    );

    if (!processingPeriod) {
      return;
    }

    if (!loadFees) {
      return;
    }

    if (watchApplyFeeAdjustment && isTotalFeesLessThanZero) {
      return;
    }

    let loadToSubmit: LoadFuelFundsData = {
      paymentMethodId: loadData.paymentMethodId,
      processingPeriod,
      fundsToAdd: loadData.fundsToAdd,
      daysOfWeek: loadData.daysOfWeek ?? [],
      scheduledLoadId: loadData.scheduledLoadId,
      isFirstFifteenth: loadData.isFirstFifteenth,
      firstFifteenth: loadData.firstFifteenth ?? [],
      couponUsageId: Boolean(loadData.couponUsageId) ? loadData.couponUsageId : undefined,
    };

    if (watchApplyFeeAdjustment && !isScheduledLoad) {
      loadToSubmit = {
        ...loadToSubmit,
        feeAdjustmentAmount: loadData.feeAdjustmentAmount,
        feeAdjustmentReason: loadData.feeAdjustmentReason,
      };
    }
    onLoadFundsSubmit(loadToSubmit);
  };

  // If this is a scheduled load... Initialize said scheduled load edit
  useEffect(() => {
    if (isScheduledLoad && scheduledLoad?.accountId) {
      setTimeout(() => {
        form.setValue('paymentMethodId', scheduledLoad.accountId);

        if (scheduledLoad?.feeId) {
          setTimeout(() => {
            form.setValue('processingPeriodId', scheduledLoad.feeId);

            if (scheduledLoad?.loadAmount) {
              setTimeout(() => {
                form.setValue('fundsToAdd', scheduledLoad?.loadAmount);

                if (scheduledLoad?.trigger) {
                  const schedulePieces = scheduledLoad.trigger.criteria.split(' ');
                  const daysOfWeek = schedulePieces[(schedulePieces.length - 1)].split(',').map((dayStr) => parseInt(dayStr, 10));
                  const firstFifteenth = schedulePieces[(schedulePieces.length - 3)].split(',').map((dayStr) => parseInt(dayStr, 10));
                  if (scheduledLoad?.isFirstFifteenth) {
                    form.setValue('firstFifteenth', firstFifteenth);
                  } else {
                    form.setValue('daysOfWeek', daysOfWeek);
                  }
                }

                if (scheduledLoadHasFeeAdjustment) {
                  form.setValue('applyFeeAdjustment', true);
                  form.setValue('feeAdjustmentAmount', scheduledLoad?.feeAdjustmentAmount);
                  form.setValue('feeAdjustmentReason', scheduledLoad?.feeAdjustmentReason);
                }

                form.trigger();
              }, 1);
            }
          }, 1);
        }
      }, 1);
    }
  }, []);

  // schedueled load concerns
  const hasScheduledLoadLoadfailure = scheduledLoad?.lastLoadFailure && scheduledLoad.lastLoadFailure.length > 0; // were there any errors on the last load attempt?
  const hasScheduledLoadValidationError = scheduledLoad?.validationErrors && scheduledLoad.validationErrors.length > 0; // does this scheduled load have any existing validation errors?

  // The component's render method returns a form that includes various input fields and buttons. The form uses the handleSubmit function from react-hook-form to handle form submissions.
  return (
    <>
      {!formIsSubmitted && (
        <Fade
          in
          style={{ transitionDelay: '200ms' }}
          timeout={600}
          unmountOnExit
          mountOnEnter
        >
          <Box display="flex" flex={1} flexDirection="column">
            <FormProvider {...form}>
              <form
                style={styles.formContainer}
                onSubmit={handleSubmit(handleLoadSubmit)}
              >
                <Grid container xs={12} spacing={2}>

                  {/* display any scheduled load failures if the exist  */}
                  {scheduledLoad?.lastLoadFailure && hasScheduledLoadLoadfailure
                    && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">The following errors were encountered the last time this scheduled load was triggered/processed</Typography>
                        <ApiValidationErrors apiValidationErrors={scheduledLoad.lastLoadFailure} variant="filled" />
                      </Grid>
                    )}

                  {/* display any scheduled load validation errors if the exist  */}
                  {scheduledLoad?.validationErrors && hasScheduledLoadValidationError
                    && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">The following validation errors exist for this scheduled load</Typography>
                        <ApiValidationErrors severity="warning" apiValidationErrors={scheduledLoad.validationErrors} variant="filled" />
                      </Grid>
                    )}

                  {/* Display a divider if hasScheduledLoadLoadfailure or hasScheduledLoadValidationError is true  */}
                  {(hasScheduledLoadLoadfailure || hasScheduledLoadValidationError) && <Grid item xs={12}><Divider sx={{ marginTop: '16px', marginBottom: '28px' }} /></Grid>}

                  {/* LoadFundsBaseForm is responsible for rendering the form fields and executing all the dynamic pre-submit logic for loading funds or configuring a scheduled load
                  The LoadFundsBaseForm is a separate component that is defined in the same file as this component.
                  */}
                  <LoadFundsBaseForm
                    loadFees={loadFees}
                    loadLimits={loadLimits}
                    coupon={coupon}
                    paymentMethodsFilter={paymentMethodsFilter}
                    formIsSubmitted={formIsSubmitted}
                    formDataIsProcessing={formDataIsProcessing}
                    isScheduledLoad={isScheduledLoad}
                    scheduledLoad={scheduledLoad}
                    onApplyAdjustmentFeesLessThanZero={setIsTotalFeesLessThanZero}
                    allowCoupon={allowCoupon}
                    nextAvailableFundingInfo={nextAvailableFundingInfo}
                  />

                  <Grid item xs={6}>
                    <Button
                      disabled={formDataIsProcessing}
                      onClick={() => {
                        if (onCancel) {
                          onCancel();
                        }
                      }}
                      variant="contained"
                      sx={{ marginBottom: '19.5px', marginTop: '16px', marginLeft: '0px' }}
                      data-testid="load-fuel-funds-cancel-button"
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item xs={6} sx={{ paddingRight: '0px' }}>
                    {/* Display any validation errors (this component will not be displayed if the apiValidationErrors array length is 0).
                    The apiValidationErrors array is updated from the API response */}
                    <ApiValidationErrors
                      apiValidationErrors={apiValidationErrors}
                    />
                    {/* Display the process steps component (this component will not be displayed if the steps array length is 0).
                    The steps array contains the steps that the api request went through (as well as whether or not said step was successful or not).
                    The steps array is updated from the API response */}
                    <ProcessSteps steps={steps} />

                    {/* Display the submit button. The button is disabled if the form is invalid, the form is processing, the form is not dirty, the total fees are less than zero, or if there are any steps with errors.
                    This component will also display a busy state if the formDataIsProcessing prop is true.
                    */}
                    <BusySubmitButton
                      data-testid="load-fuel-add-funds-button"
                      color="primary"
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={
                        !formState.isValid
                        || formDataIsProcessing
                        || !formState.isDirty
                        || (watchApplyFeeAdjustment && isTotalFeesLessThanZero)
                        || steps.some((s) => resolveHasStepError(s))
                      }
                      actionPending={formDataIsProcessing}
                    >
                      {submitButtonText}
                    </BusySubmitButton>

                    {/* general note to the user that the process may take a while */}
                    <Typography
                      variant="caption"
                      display="block"
                      align="center"
                      color="textSecondary"
                      sx={{
                        marginTop: '10px',
                        visibility: formDataIsProcessing ? 'visible' : 'hidden',
                      }}
                    >
                      Please do not close or refresh your browser. This process
                      may take several minutes.
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </Fade>
      )}

      {/* After the form is submitted, display the postSubmitContent prop. */}
      {formIsSubmitted && (
        <Fade in style={{ transitionDelay: '100ms' }} timeout={600}>
          <div>{postSubmitContent}</div>
        </Fade>
      )}
    </>
  );
};

export default LoadFuelFundsForm;
