import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

export interface LabelValueProps {
  label: React.ReactNode;
  value: React.ReactNode;
  emphasize?: boolean;
  children?: React.ReactNode;
  boxStyles?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  applyBoxStyles?: boolean;
}

const LabelValue = (props: LabelValueProps) => {
  const theme = useTheme();
  const styles = {
    boxContainer: {
      minWidth: '185px',
      marginRight: '80px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '10px',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
      },
    },
    label: {
      ...theme.typography.summaryLabel,
    },
    value: {
      ...theme.typography.summaryValue,
    },
    labelEmphasis: {
      ...theme.typography.summaryLabel,
      fontSize: '1.3em',
    },
    valueEmphasis: {
      ...theme.typography.summaryValue,
      fontSize: '2.3em',
    },
  };
  const {
    label,
    value,
    emphasize,
    children,
    boxStyles = styles.boxContainer,
    labelStyle,
    valueStyle,
    containerStyle,
    applyBoxStyles = true,
  } = props;
  const typographyLabelClass = emphasize
    ? styles.labelEmphasis
    : styles.label;
  const typographyValueClass = emphasize
    ? styles.valueEmphasis
    : styles.value;
  const boxStyle = applyBoxStyles ? boxStyles : {};
  return (
    <Box
      sx={{
        ...boxStyle,
        ...containerStyle,
      }}
    >
      <Typography sx={{ ...typographyLabelClass, ...labelStyle }}>
        {label}
      </Typography>
      <Typography sx={{ ...typographyValueClass, ...valueStyle }}>
        {value}
      </Typography>
      {children}
    </Box>
  );
};

export default LabelValue;
