import {
  AppBar, Box, Hidden, Toolbar,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import React from 'react';
import logo from '../../../assets/logo-tvc-pro-driver-web_small.png';
import MobileDrawer from './MobileDrawer';
import ProfileAvatar from './ProfileAvatar';

interface MenuBarProps {
  renderNav?: boolean;
}

const MenuBar = (props: MenuBarProps) => {
  const { renderNav = true } = props;
  const styles = {
    appBar: {
      backgroundColor: '#fff',
    },
    toolBar: {
      minHeight: '4rem',
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      height: '40px',
      display: 'flex',
      margin: '0 2rem 0 1rem',
      cursor: 'pointer',
    },
    profileAvatar: {
      marginRight: '10px',
    },
  };
  const history = useHistory();
  return (
    <>
      <AppBar position="sticky" sx={styles.appBar}>
        <Toolbar sx={styles.toolBar}>
          <Box flex={1} display="flex" alignItems="center" flexDirection="row">
            <img
              alt="TVC logo"
              src={logo}
              style={styles.logo}
              onClick={() => {
                history.push('/');
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  history.push('/');
                }
              }}
            />
          </Box>
          <Hidden lgDown>
            <div style={styles.profileAvatar}>
              <ProfileAvatar />
            </div>
          </Hidden>

          <Hidden lgUp>
            <MobileDrawer renderNav={renderNav} />
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MenuBar;
