import { theme } from '../../appTheme';
import {
  CaseDetails, CaseStatus, CaseStatusTypes, TvcCaseStatus, TvcCaseStatusTypes,
} from '../components/legal/legalTypes';
import { getValueOrReplacementValue } from './miscHelpers';

const legalCaseStatusColors = {
  [CaseStatusTypes.DRAFT]: theme.palette.warning.dark,
  [CaseStatusTypes.OPEN]: theme.palette.success.main,
  [CaseStatusTypes.ASSIGNED]: theme.palette.info.light,
  [CaseStatusTypes.CLOSED]: theme.palette.grey[800],
};

const tvcLegalCaseStatusColors = {
  [TvcCaseStatusTypes.DRAFT]: theme.palette.warning.dark,
  [TvcCaseStatusTypes.NEW]: theme.palette.info.dark,
  [TvcCaseStatusTypes.OPEN]: theme.palette.success.light,
  [TvcCaseStatusTypes.PAID]: theme.palette.success.dark,
  [TvcCaseStatusTypes.PROCESSED]: theme.palette.grey[500],
  [TvcCaseStatusTypes.CLOSED]: theme.palette.grey[900],
  [TvcCaseStatusTypes.DELETED]: theme.palette.error.light,
};

export const mapCaseDetails = (
  data: CaseDetails,
): CaseDetails => ({
  ...data,
  caseOpenDate: data.caseOpenDate
    ? new Date(data.caseOpenDate)
    : undefined,
});

export const getMemberLegalCaseStatusColor = (status: CaseStatus | CaseStatusTypes | undefined) => (status ? legalCaseStatusColors[status] || theme.palette.grey[500] : theme.palette.grey[500]);

export const getEmployeeLegalCaseStatusColor = (status: TvcCaseStatus | TvcCaseStatusTypes | undefined) => (status ? tvcLegalCaseStatusColors[status] || theme.palette.grey[500] : theme.palette.grey[500]);

export const getCaseStatusText = (status: CaseStatus | undefined) => (status === CaseStatusTypes.ASSIGNED ? 'Discounted' : getValueOrReplacementValue(status, 'Unknow'));

export const caseCoveredSharedText = 'TVC will pay the attorney fees';
export const case25percentSharedText = 'TVC will refer the member to an attorney who has agreed to give the member a free consultation and a discount of 25% off the attorney\'s regular fee';
export const defaultLegalCaseYearsBack = 2;
export const defaultLegalCaseYearsBackMax = 3;

export const draftLegalCaseStatusText = 'Your legal case has been submitted to the legal department for finalization. A notification will be sent to you should TVC need any further information to process your legal case. There is no need to contact us. Cases are processed within 24-48 hours and you will be notified once completed.';
