import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLoadLimits } from '../../../features/fleet/hooks/useLoadLimits';
import { currencyFormat } from '../../utilities/currencyFormat';
import { CommonLimitInputProps } from './interfaces/CommonLimitInputProps';
import LimitInput from './LimitInput';
import { getValueOrReplacementValue } from '../../utilities/miscHelpers';

const MaxLoadLimitInput = (props: CommonLimitInputProps) => {
  const name = 'loadMaximumAmount';
  const {
    defaultLimits,
    showAdornment = true,
    adornmentIsDisabled = false,
    ...rest
  } = props;
  const { maxContract, loadMaximumAmountValidate } = useLoadLimits();
  const form = useFormContext();
  const { watch, trigger } = form;
  const watchLoadMaximumAmount = watch(name);

  useEffect(() => {
    if (watchLoadMaximumAmount) {
      trigger(name);
    }
  }, [watchLoadMaximumAmount]);

  return (
    <LimitInput
      label={`Maximum Load Amount - Default is ${
        getValueOrReplacementValue(currencyFormat(defaultLimits?.loadMaximumAmount), 'Unlimited')
      }`}
      {...rest}
      allowNegative={false}
      thousandSeparator
      showAdornment={showAdornment}
      adornmentIsDisabled={adornmentIsDisabled}
      rules={{
        ...maxContract('Maximum Load Amount'),
        validate: {
          loadMaximumAmountValidate: () => loadMaximumAmountValidate(form, defaultLimits),
          useDefault: () => (watchLoadMaximumAmount === defaultLimits?.loadMaximumAmount
            ? `${currencyFormat(
              defaultLimits?.loadMaximumAmount,
            )} is the default value. Please click "USE DEFAULT"`
            : true),
        },
      }}
      name={name}
    />
  );
};

export default MaxLoadLimitInput;
