import React from 'react';
import { theme } from '../../../appTheme';
import { TriggeredFundModel } from '../../models/fuel/TriggeredFundModel';
import { getOrdinalDisplay, shortDaysOfWeek } from '../../utilities/dateHelpers';

const formatTriggerCriteria = (load: TriggeredFundModel) => {
  const schedulePieces = load.trigger.criteria.split(' ');
  const arrayIndexOffset = load.isFirstFifteenth ? 3 : 1;
  const cronPieces = schedulePieces[(schedulePieces.length - arrayIndexOffset)]
    .split(',')
    .map((dayStr) => parseInt(dayStr, 10))
    .sort()
    .map((dayNum) => (load.isFirstFifteenth ? getOrdinalDisplay(dayNum) : shortDaysOfWeek[dayNum]));
  return cronPieces.join(', ');
};

interface ScheduledLoadCriteriaDisplayProps {
  load: TriggeredFundModel;
}

const ScheduledLoadCriteriaDisplay = (props: ScheduledLoadCriteriaDisplayProps) => {
  const { load } = props;
  const preface = load.isFirstFifteenth ? 'Monthly' : 'Weekly';
  const criteriaDisplay = formatTriggerCriteria(load);

  return (
    <>
      <span style={{ color: theme.palette.grey[600] }}>
        {preface}
        :
        {' '}
      </span>
      <span>{criteriaDisplay}</span>
    </>
  );
};

export default ScheduledLoadCriteriaDisplay;
