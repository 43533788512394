import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import HelpDialog from '../HelpDialog';

interface CrossRefereceHelpProps {
  style?: React.CSSProperties;
}

const CrossRefereceHelp = (props: CrossRefereceHelpProps) => {
  const { style } = props;

  return (
    <HelpDialog tooltipTitle="Click for help information about cross reference id" style={style}>
      <Typography variant="h5" gutterBottom>
        Cross Reference ID
      </Typography>

      <List>
        <ListItem sx={{ alignItems: 'flex-start' }}>
          <ListItemText
            sx={{ alignItems: 'flex-start' }}
            secondary={(
              <Box>
                <Box>
                  Cross Reference ID can be used to provide your own unique identifier for a TVC Portal user.
                  Providing a Cross Reference ID will allow you to use this value when filtering users, legal cases, or associated fuel cards.
                </Box>
                <Box sx={{ marginTop: '16px' }}>
                  An example value for Cross Reference ID would be your fleet's internal employee ID for said user.
                </Box>
              </Box>
            )}
          />
        </ListItem>
      </List>
    </HelpDialog>
  );
};

export default CrossRefereceHelp;
