import React from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import CustomNumberInput from '../../shared/components/formFields/CustomNumberInput';
import ApiValidationErrors from '../../shared/components/ApiValidationErrors';
import { selectIsReSending } from './migrationSlice';
import WarningIconText from '../../shared/components/icon-components/WarningIconText';

interface VerifyCodeFormData {
  code: number;
}

interface VerifyCodeFormProps {
  onFormSubmit: (data: VerifyCodeFormData) => void;
  isBusy?: boolean;
  validationErrors?: string[];
  resendCode: (email: string) => Promise<void>;
  email: string;
}
const VerifyCodeForm = (props: VerifyCodeFormProps) => {
  const {
    onFormSubmit, isBusy, validationErrors = [], resendCode, email,
  } = props;

  const isReSending = useSelector(selectIsReSending);

  const verifyCodeForm = useForm<VerifyCodeFormData>({
    mode: 'all',
  });

  const {
    handleSubmit, formState: { isValid }, reset, trigger,
  } = verifyCodeForm;

  return (
    <FormProvider {...verifyCodeForm}>
      <form
        style={{ flexDirection: 'column', display: 'flex' }}
        onSubmit={handleSubmit(async (submittedForm) => {
          onFormSubmit(submittedForm);
        })}
      >

        <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
          <Grid item xs={12}>
            <CustomNumberInput
              name="code"
              fullWidth
              label="Code"
              style={{ flex: 1 }}
              decimalScale={0}
              allowNegative={false}
              validateOnChange
              rules={{
                required: 'Code is required',
                validate: (v: number) => (v.toString().length === 6 ? true : 'Code should be exactly 6 digits'),
              }}
            />
          </Grid>

          {validationErrors.length > 0
            && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ApiValidationErrors apiValidationErrors={validationErrors} variant="filled" />
              </Grid>
            )}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <WarningIconText text="Do not close or refresh this browser window. After you receive your verification code, return to this browser form and enter it in the input field above." />
          </Grid>
          <Grid item xs={12}>
            <BusySubmitButton
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              disabled={!isValid}
              actionPending={Boolean(isBusy)}
              containerStyles={{ display: 'flex', justifyContent: 'center' }}
            >
              Verify Code
            </BusySubmitButton>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <BusySubmitButton
              variant="text"
              color="primary"
              type="button"
              disabled={(isValid && validationErrors.length === 0)}
              actionPending={isReSending}
              containerStyles={{ display: 'flex', justifyContent: 'center' }}
              onClick={async () => {
                await resendCode(email);
                reset();
                trigger('code');
              }}
            >
              Resend Verification Code
            </BusySubmitButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default VerifyCodeForm;
