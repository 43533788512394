import {
  Configuration,
  EventType,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { get } from 'lodash/fp';
import { store } from '../app/store';
import { parseEnv } from '../config';
import { PortalApiActions } from '../features/auth/authReducer';
import { logErrorWithApplicaitonInsights } from '../shared/hooks/useTvcAppInsights';

const appConfig = parseEnv();
/*
  // Browser check variables
  // If you support IE, our recommendation is that you sign-in using Redirect APIs
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  const msedge = ua.indexOf("Edge/");
  const firefox = ua.indexOf("Firefox");
  const isIE = msie > 0 || msie11 > 0;
  const isEdge = msedge > 0;
  const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
*/

const newSignInConfig: Configuration = {
  auth: {
    clientId: appConfig.REACT_APP_MSAL_CLIENT_ID,
    authority: appConfig.REACT_APP_MSAL_CLIENT_AUTHORITY,
    redirectUri: window.location.origin,
    knownAuthorities: [appConfig.REACT_APP_MSAL_CLIENT_AUTHORITY],
  },
  cache: {
    cacheLocation: 'localStorage',
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/authConfig.js
    // storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
};

export const signInAuthProvider = new PublicClientApplication(newSignInConfig);

signInAuthProvider.addEventCallback((message) => {
  if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    store.dispatch({
      type: PortalApiActions.AccessTokenRetrieved,
      payload: get('account', message.payload),
    });
  }
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    store.dispatch({
      type: PortalApiActions.LoginSuccess,
      payload: message.payload,
    });
  }
  if (message.eventType === EventType.LOGOUT_SUCCESS) {
    store.dispatch({
      type: PortalApiActions.LogoutSuccess,
      payload: message.payload,
    });
  }
});

export const getAccessToken = async () => {
  const accounts = signInAuthProvider.getAllAccounts();
  const [account] = accounts;

  // log if there is more than one account returned from signInAuthProvider.getAllAccounts();
  if (accounts.length > 1) {
    const multipleAccounts = accounts.map((acct) => ({
      ...acct,
      idTokenClaims: null,
    }));

    logErrorWithApplicaitonInsights(new Error('Multiple accounts`'), {
      userHasMultipleAccounts: true,
      multipleAccounts,
    });
  }

  let retAccessToken: string = '';

  const silentRequest: SilentRequest = {
    account,
    scopes: appConfig.REACT_APP_MSAL_SCOPES,
    forceRefresh: false,
  };

  try {
    const { accessToken } = await signInAuthProvider.acquireTokenSilent(
      silentRequest,
    );
    retAccessToken = accessToken;
  } catch (ex) {
    logErrorWithApplicaitonInsights(ex);
    const redirectRedirect: RedirectRequest = {
      scopes: appConfig.REACT_APP_MSAL_SCOPES,
    };
    await signInAuthProvider.loginRedirect(redirectRedirect);
  }

  return retAccessToken;
};
