import React from 'react';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import { FilterOption } from '../../../shared/components/search-filter/FilterOption';
import MultiSelectFilter from '../../../shared/components/search-filter/MultiSelectFilter';
import { StatusFilterProps } from '../../../shared/components/search-filter/StatusFilterProps';

export const fleetInvalidConditions: FilterOption<Fleet>[] = [
  {
    displayName: 'Invalid Carrier',
    key: 'invalid-carrier-fleet',
    condition: (f: Fleet) => Boolean(f.carrierId) && !f.isValidCarrier,
  },
  {
    displayName: 'No Active Fuel Cards',
    key: 'no-active-fuel-cards',
    condition: (f: Fleet) => Boolean(f.carrierId) && f.cardCount && f.cardCount.active === 0,
  },
  {
    displayName: 'Missing Root Member',
    key: 'missing-root-member-fleet',
    condition: (f: Fleet) => Boolean(!f.rootMemberId),
  },
  {
    displayName: 'Missing Org',
    key: 'missing-org-fleet',
    condition: (f: Fleet) => Boolean(!f.organizationId),
  },
];

const FleetInvalidConditionsFilter = (props: StatusFilterProps) => {
  const { value, handleSelection } = props;
  return (
    <MultiSelectFilter<Fleet>
      inputLabel="Invalid Conditions"
      labelId="filter-fleet-label"
      selectId="filter-fleet-select"
      value={value}
      handleSelection={handleSelection}
      options={fleetInvalidConditions}
    />
  );
};

export default FleetInvalidConditionsFilter;
