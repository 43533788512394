import { useSelector } from 'react-redux';
import { selectRootHelmetTitle } from '../../features/application/applicationSlice';

export const useHelmetTitle = () => {
  const rootTitle = useSelector(selectRootHelmetTitle);

  return {
    rootTitle,
  };
};
