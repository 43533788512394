import { Card } from '@mui/material';
import React, { useState } from 'react';

interface CardWrapperProps {
  children: React.ReactNode;
  cardStyles?: React.CSSProperties;
  userCanClickCard?: boolean;
  paperHoverElevation?: number;
  paperRestingElevation?: number;
}

const GridCardWrapper = (props: CardWrapperProps) => {
  const {
    cardStyles, children, userCanClickCard = true, paperHoverElevation = 10, paperRestingElevation = 2,
  } = props;
  const cardWrapperStyles = {
    root: {
      '& .MuiCardHeader-content': {
        alignSelf: 'start',
      },
    },
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Card
        elevation={userCanClickCard ? (isHovered ? paperHoverElevation : paperRestingElevation) : 0}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          ...cardWrapperStyles.root,
          ...cardStyles,
        }}
      >
        {children}
      </Card>
    </>
  );
};

export default GridCardWrapper;
