import React from 'react';
import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SectionHeader from './SectionHeader';

interface ListContactProps {
  headerText?: string;
  children?: React.ReactNode;
}

const ListContact = (props: ListContactProps) => {
  const defaultText = 'Contact Info';
  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
      borderRadius: '4px',
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.background.paper,
    },
  };

  const {
    headerText = defaultText,
    children,
  } = props;

  return (
    <>
      <SectionHeader headerText={headerText} />
      <List sx={styles.root}>
        { children }
      </List>
    </>
  );
};

export default ListContact;
