import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Variant } from '@mui/material/styles/createTypography';

interface SectionHeaderProps {
  headerText: React.ReactNode;
  typographyStyle?: React.CSSProperties;
  typographyVariant?: Variant;
  helpDialog?: React.ReactNode;
  supplementalText?: React.ReactNode;
  supplementalTextStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const SectionHeader = (props: SectionHeaderProps) => {
  const {
    headerText, typographyStyle, helpDialog, supplementalText, supplementalTextStyle, containerStyle, style, typographyVariant = 'h6',
  } = props;
  const theme = useTheme();
  const styles = {
    sectionHeader: {
      flexBasis: '100%',
      marginBottom: '8px',
      marginTop: '18px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px',
        marginLeft: '0px',
      },
    },
  };

  return (
    <Box sx={{
      display: 'flex', flex: '1', flexBasis: '100%', ...style,
    }}
    >
      <Box
        sx={{
          ...styles.sectionHeader,
          ...containerStyle,
          position: helpDialog ? 'relative' : {},
        }}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <Typography
              variant={typographyVariant}
              sx={{ ...typographyStyle }}
            >
              {headerText}
            </Typography>
          </Box>
          {supplementalText && (
            <Box sx={{ ...supplementalTextStyle }}>
              {supplementalText}
            </Box>
          )}
        </Box>
        {helpDialog}
      </Box>
    </Box>
  );
};

export default SectionHeader;
