import { FleetUserProfile } from '../models/fleet/FleetUserProfile';
import { PortalApiFleetUserProfile } from '../models/fleet/PortalApiFleetUserProfile';
import { PortalApiPermission } from '../models/permissions/PortalApiPermission';
import { RestrictAccess } from '../models/other/RestrictAccess';
import { FleetUserDetailModel } from '../models/user/FleetUserDetailModel';
import { PortalApiProfile } from '../models/user/PortalApiProfile';
import { PortalApiUser } from '../models/user/PortalApiUser';
import { PortalUserBody } from '../models/user/PortalUserBody';
import { Profile } from '../models/user/Profile';
import { UserInformation } from '../models/user/UserInformation';
import { portalApiFleetToFleet } from './fleetHelpers';
import { parseEnv } from '../../config';
import { PortalApiPermissionFull } from '../models/permissions/PortalApiPermissionFull';
import { getLastFourText } from './fuelHelpers';

const config = parseEnv();

export const isFleetSuperAdministrator = (permissions: PortalApiPermissionFull[] | null | undefined) => permissions?.some((p) => p.id.toUpperCase() === config.REACT_APP_FLEET_SUPER_ADMINISTRATOR_GUID);

export const getFreezeActivityReason = (freeze: boolean, user: PortalApiUser) => {
  if (freeze) {
    if (!user.hasValidMembership) {
      return 'Invalid or expired membership';
    }
    if (user.products.length === 0) {
      return 'There are no active products associated with this membership';
    }
  }

  return '';
};

export const mapRestrictAccess = (user: PortalApiUser): RestrictAccess => {
  const freezeActivity = !user.hasValidMembership || user.products?.length === 0;

  return {
    freezeActivity,
    freezeActivityReason: getFreezeActivityReason(freezeActivity, user),
  };
};

export const portalApiFleetUserProfileToFleetUserProfile = (
  portalApiFleetUserProfile: PortalApiFleetUserProfile,
  permissions: PortalApiPermission[] | null,
): FleetUserProfile => ({
  ...portalApiFleetToFleet(
    portalApiFleetUserProfile,
    permissions,
    portalApiFleetUserProfile.permissions
      ? portalApiFleetUserProfile.permissions
      : undefined,
  ),
  loggedInUserIsFleetSuperAdmin: isFleetSuperAdministrator(portalApiFleetUserProfile.permissions) ?? false,
  permissions: portalApiFleetUserProfile.permissions
    ? portalApiFleetUserProfile.permissions
    : undefined,
});

export const convertFleetUserToUserInformation = (userDetail: PortalApiUser, permissionIds?: string[]): UserInformation => ({
  userId: userDetail.userId,
  firstName: userDetail.firstName,
  lastName: userDetail.lastName,
  permissionIds,
  memberId: userDetail.memberId,
  account: userDetail.email ? { email: userDetail.email, createLogin: false } : undefined,
});

export const convertUserInfoToPortalUserBody = (user: UserInformation): PortalUserBody => ({
  userId: user.userId,
  memberId: user.memberId,
  firstName: user.firstName,
  lastName: user.lastName,
  createLogin: user.account?.createLogin,
  email: user.account?.email,
  permissionIds: user.permissionIds,
  alternateId: user.matrixMembership?.alternateId,
  driversLicenseNumber: user.matrixMembership?.driversLicenseNumber,
  address1: user.matrixMembership?.address1,
  address2: user.matrixMembership?.address2,
  city: user.matrixMembership?.city,
  state: user.matrixMembership?.state,
  country: user.matrixMembership?.country,
  zipCode: user.matrixMembership?.zipCode,
  phoneCell: user.matrixMembership?.phoneCell,
  crossRefId: user.matrixMembership?.crossRefId,
  fleetTemplateId: user.matrixMembership?.fleetTemplateId,
  startDate: user.matrixMembership?.startDate,
});

export const convertProfiletoUserInfo = (userDetail: Profile): UserInformation => ({
  userId: userDetail.id,
  firstName: userDetail.firstName,
  lastName: userDetail.lastName,
  permissionIds: userDetail.permissions.map((p) => p.id), // ???
  memberId: userDetail.memberId,
});

export const mapPortalApiUser = (user: PortalApiUser): PortalApiUser => ({
  ...user,
  hasLogin: Boolean(user.azureB2CId),
  hasMembership: Boolean(user.memberId),
  hasFuelCard: Boolean(user.fuelCardCount),
  hasActiveFuelCard: Boolean(user.fuelCardActiveCount),
  hasLegalCase: Boolean(user.legalCaseCount),
  hasActiveLegalCase: Boolean(user.activeLegalCaseCount),
  restrictAccess: mapRestrictAccess(user),
  productHasFuel: user.products && user.products.some((p) => p.hasFuel),
  // Mikey this is for later
  // productHasFuel: user.products && user.products.some((p) => p.benefits.some((b) => b.metaName === 'Fuel' && !b.isFullyDisabled)),
  // showFuelMessaging: user.products === undefined || user.products === null || !user.products.some((p) => p.benefits.some((b) => b.metaName === 'Fuel' && b.isFullyDisabled)),
  contractBalanceAsOf: user.contractBalanceAsOf ? new Date(user.contractBalanceAsOf) : null,
});

export const mapPortalApiFleetUser = (fleetUser: FleetUserDetailModel) => ({
  ...fleetUser,
  ...mapPortalApiUser(fleetUser),
});

export const portalApiProfileToProfle = (
  portalApiProfile: PortalApiProfile,
): Profile => ({
  ...portalApiProfile,
  portalUser: portalApiProfile.portalUser ? {
    ...portalApiProfile.portalUser,
    ...mapPortalApiUser(portalApiProfile.portalUser),
  } : undefined,
  fleets: portalApiProfile.fleets
    ? portalApiProfile.fleets.map((f) => portalApiFleetUserProfileToFleetUserProfile(
      f,
      portalApiProfile.permissions,
    ))
    : undefined,
  smartcards: portalApiProfile.smartcards?.map((sc) => ({
    ...sc,
    lastFour: sc.lastFour ? getLastFourText(sc.lastFour) : '-',
    cardBalance: sc.cardBalance ? {
      ...sc.cardBalance,
      efsBalanceAsOf: new Date(sc.cardBalance.efsBalanceAsOf),
    } : null,
  })),
});
