import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface ScrollableContentProps {
  children?: React.ReactNode;
  maxHeight?: number | 'unset';
  showBottomOverlay?: boolean;
  additionalBottomPadding?: number;
  showGradient?: boolean;
  style?: React.CSSProperties;
  scrollingBoxStyle?: React.CSSProperties;
  gradientFadeColor?: string;
}

const ScrollableContent = (props: ScrollableContentProps) => {
  const theme = useTheme();
  const {
    children,
    maxHeight = 'unset',
    showBottomOverlay = false,
    additionalBottomPadding = 50,
    showGradient = true,
    style,
    scrollingBoxStyle,
    gradientFadeColor = theme.palette.background.default,
  } = props;
  const styles = {
    impliedScollingAvailable: {
      position: 'absolute',
      height: '36px',
      left: '0px',
      bottom: '0px',
      width: 'calc(100% + 4px)',
      marginLeft: '-4px',
      backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), ${gradientFadeColor})`,
    },
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          ...style,
          maxHeight: maxHeight === 'unset' ? maxHeight : `${maxHeight}px`,
          overflow: showBottomOverlay ? 'auto' : 'unset',
          paddingBottom: showBottomOverlay ? `${additionalBottomPadding}px` : 'unset',
        }}
      >
        {children}
      </Box>
      {(showBottomOverlay && showGradient) && (
        <Box sx={{
          ...styles.impliedScollingAvailable,
          ...scrollingBoxStyle,
        }}
        />
      )}
    </Box>
  );
};

export default ScrollableContent;
