import React from 'react';
import {
  Box, Grid, Paper, Zoom,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { theme as appTheme } from '../../appTheme';
import ContentPadding from '../../shared/components/ContentPadding';
import MigrationLogo from './MigrationLogo';
import { selectAccountCreated, selectMigrationResponse, selectPortalEmail } from './migrationSlice';
import MigrationContentHeader from './MigrationContentHeader';
import ConfirmLabelValue from '../../shared/components/confirm-summary/ConfirmLabelValue';

const MigrationHeader = () => {
  const loginResponse = useSelector(selectMigrationResponse);
  const accountCreated = useSelector(selectAccountCreated);
  const portalEmail = useSelector(selectPortalEmail);
  const fullName = `${loginResponse?.firstName} ${loginResponse?.lastName}`;

  return (
    <Grid item xs={12} sx={{ marginBottom: '18px' }}>
      <Paper elevation={2}>
        <ContentPadding>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MigrationLogo headerText="Portal Account Migration" />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ paddingLeft: '14px' }}>
                <MigrationContentHeader headerText={accountCreated ? 'New TVC Pro-Driver Account Created' : 'Credentials Verified'} isSuccess />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                <ConfirmLabelValue
                  label="Member ID"
                  value={loginResponse?.id}
                />

                <ConfirmLabelValue
                  label="Name"
                  value={fullName}
                />
                <ConfirmLabelValue
                  label={(accountCreated && portalEmail) ? 'New Portal Email' : 'Email'}
                  value={(
                    <>
                      {accountCreated
                        ? (
                          <Zoom in mountOnEnter unmountOnExit timeout={850}>
                            <Box sx={{ color: appTheme.palette.success.main }}>
                              {portalEmail || loginResponse?.email}
                            </Box>
                          </Zoom>

                        )
                        : (
                          <Box sx={{ color: (!loginResponse?.isEmailUnique || !loginResponse?.emailIsValid) ? appTheme.palette.error.main : appTheme.palette.success.main }}>
                            {loginResponse?.emailIsValid ? loginResponse?.email : '<invalid email>'}
                          </Box>
                        )}
                    </>
                  )}
                />

              </Box>
            </Grid>
          </Grid>
        </ContentPadding>

      </Paper>
    </Grid>
  );
};

export default MigrationHeader;
