import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { LegalClosingLetter } from '../../shared/models/legal/LegalClosingLetter';
import { UpcomingEventsModel } from '../../shared/models/legal/UpcomingEventsModel';
import { LegalCount } from '../../shared/models/legal/LegalCount';
import {
  createBlockingThunk,
  createNonBlockingThunk,
} from '../../services/thunk';
import { CaseDetails, CaseStatusTypes, CaseSummary } from '../../shared/components/legal/legalTypes';
import { useLegalApi } from '../../services/api/hooks/useLegalApi';
import { addAlert } from '../application/applicationSlice';
import { LegalDocument } from '../../shared/models/legal/LegalDocument';
import { useCaseApi } from '../../services/api/hooks/useCaseApi';
import { useFleetApi } from '../../services/api/hooks/useFleetApi';
import { draftLegalCaseStatusText } from '../../shared/utilities/legalHelpers';
import { CaseInformation } from '../../shared/models/legal/CaseInformation';
import { FileCategory } from '../../shared/models/file-upload/FileCategory';
import { VehicleType } from '../../shared/models/legal/VehicleType';
import { ViolationType } from '../../shared/models/legal/ViolationType';
import { LawType } from '../../shared/models/legal/LawType';
import { StateProvince } from '../../shared/models/other/StateProvince';

interface CaseDetailsById {
  [key: number]: CaseDetails;
}

interface CaseSummariesById {
  [key: number]: CaseSummary;
}

export interface NormalizedCaseDetails {
  byId: CaseDetailsById,
  all: number[]
}

export interface NormalizedCaseSummaries {
  byId: CaseSummariesById,
  all: number[]
}

export interface LegalState {
  legalCaseCount: LegalCount,
  caseSummaries: NormalizedCaseSummaries,
  caseDetails: NormalizedCaseDetails,
  caseClosingLetter: LegalClosingLetter,
  legalStateLoading: boolean,
  legalDetailsLoading: boolean,
  leglCaseClosingLetterLoading: boolean,
  upcomingEvents?: UpcomingEventsModel,
  upcomingEventsLoading: boolean;
  submitLegalCaseBusy: boolean;
  legalDraftDocuments: LegalDocument[],
  legalDraftDocumentsLoading: boolean;
  legalDocumentCategories: FileCategory[];
  vehicleTypes: VehicleType[];
  violationTypes: ViolationType[];
  lawTypes: LawType[];
  stateProvinces: StateProvince[];
}

const initialState: LegalState = {
  legalCaseCount: {
    draft: 0,
    open: 0,
    assigned: 0,
    closed: 0,
  },
  caseDetails: {
    byId: {},
    all: [],
  },
  caseSummaries: {
    byId: {},
    all: [],
  },
  caseClosingLetter: {
    rawText: '',
    linkToPdf: '',
  },
  legalStateLoading: false,
  legalDetailsLoading: false,
  leglCaseClosingLetterLoading: false,
  upcomingEvents: undefined,
  upcomingEventsLoading: false,
  submitLegalCaseBusy: false,
  legalDraftDocuments: [],
  legalDraftDocumentsLoading: false,
  legalDocumentCategories: [],
  vehicleTypes: [],
  violationTypes: [],
  lawTypes: [],
  stateProvinces: [],
};

export const legalSlice = createSlice({
  name: 'legal',
  initialState,
  reducers: {
    setCaseDetails(state, action: PayloadAction<CaseDetails[]>) {
      const ids = action.payload.map((detail) => detail.caseId);
      const detailsById: CaseDetailsById = {};
      action.payload.forEach((detail) => {
        detailsById[detail.caseId] = detail;
      });
      state.caseDetails = {
        byId: detailsById,
        all: ids,
      };
    },
    setCaseSummaries(state, action: PayloadAction<CaseSummary[]>) {
      const ids = action.payload.map((summary) => summary.caseId);
      const summariesById: CaseSummariesById = {};
      action.payload.forEach((summary) => {
        summariesById[summary.caseId] = summary;
      });
      state.caseSummaries = {
        byId: summariesById,
        all: ids,
      };
    },
    setClosingLetter(state, action: PayloadAction<LegalClosingLetter>) {
      state.caseClosingLetter = action.payload;
    },
    setCaseCount(state, action: PayloadAction<LegalCount>) {
      state.legalCaseCount = action.payload;
    },
    setUpcomingEvents(state, action: PayloadAction<UpcomingEventsModel | undefined>) {
      state.upcomingEvents = action.payload;
    },
    updateCaseDetail(state, action: PayloadAction<CaseDetails>) {
      if (!state.caseDetails.byId[action.payload.caseId]) {
        state.caseDetails.all.push(action.payload.caseId);
        state.caseDetails.byId[action.payload.caseId] = action.payload;
      } else {
        const caseDetailToUpdate = state.caseDetails.byId[action.payload.caseId];
        if (caseDetailToUpdate) {
          state.caseDetails.byId[action.payload.caseId] = {
            ...caseDetailToUpdate,
            ...action.payload,
          };
        }
      }
    },
    updateCaseSummary(state, action: PayloadAction<CaseSummary>) {
      const caseSummaryToUpdate = state.caseSummaries.byId[action.payload.caseId];
      if (caseSummaryToUpdate) {
        state.caseSummaries.byId[action.payload.caseId] = {
          ...caseSummaryToUpdate,
          ...action.payload,
        };
      }
    },
    setLegalStateLoading(state, action: PayloadAction<boolean>) {
      state.legalStateLoading = action.payload;
    },
    setLegalDetailsLoading(state, action: PayloadAction<boolean>) {
      state.legalDetailsLoading = action.payload;
    },
    setClosingLetterLoading(state, action: PayloadAction<boolean>) {
      state.leglCaseClosingLetterLoading = action.payload;
    },
    setUpcomingEventsLoading(state, action: PayloadAction<boolean>) {
      state.upcomingEventsLoading = action.payload;
    },
    setSubmitLegalCaseBusy(state, action: PayloadAction<boolean>) {
      state.submitLegalCaseBusy = action.payload;
    },
    setLegalDraftDocuments(state, action: PayloadAction<LegalDocument[]>) {
      state.legalDraftDocuments = action.payload;
    },
    setLegalDraftDocumentsLoading(state, action: PayloadAction<boolean>) {
      state.legalDraftDocumentsLoading = action.payload;
    },
    setLegalDocumentCategories(state, action: PayloadAction<FileCategory[]>) {
      state.legalDocumentCategories = action.payload;
    },
    setVehicleTypes: (state, action: PayloadAction<VehicleType[]>) => {
      state.vehicleTypes = action.payload;
    },
    setViolationTypes: (state, action: PayloadAction<ViolationType[]>) => {
      state.violationTypes = action.payload;
    },
    setLawTypes: (state, action: PayloadAction<LawType[]>) => {
      state.lawTypes = action.payload;
    },
    setStateProvinces: (state, action: PayloadAction<StateProvince[]>) => {
      state.stateProvinces = action.payload;
    },
  },
});

export const {
  setCaseDetails,
  setCaseSummaries,
  updateCaseDetail,
  updateCaseSummary,
  setLegalStateLoading,
  setLegalDetailsLoading,
  setCaseCount,
  setClosingLetter,
  setClosingLetterLoading,
  setUpcomingEvents,
  setUpcomingEventsLoading,
  setSubmitLegalCaseBusy,
  setLegalDraftDocuments,
  setLegalDraftDocumentsLoading,
  setLegalDocumentCategories,
  setVehicleTypes,
  setViolationTypes,
  setLawTypes,
  setStateProvinces,
} = legalSlice.actions;

// FLEET
export const fetchCaseSummariesByFleet = (fleetId: string, startDate?: Date, endDate?: Date) => createBlockingThunk(async (dispatch) => {
  dispatch(setLegalStateLoading(true));
  dispatch(setCaseSummaries([]));
  const { getCaseSummariesByFleet } = useLegalApi();
  try {
    const caseSummaries = await getCaseSummariesByFleet(fleetId, startDate, endDate);
    dispatch(setCaseSummaries(caseSummaries));
    dispatch(setCaseCount({
      draft: caseSummaries.filter((cs) => cs.caseStatus === CaseStatusTypes.DRAFT).length,
      open: caseSummaries.filter((cs) => cs.caseStatus === CaseStatusTypes.OPEN).length,
      assigned: caseSummaries.filter((cs) => cs.caseStatus === CaseStatusTypes.ASSIGNED).length,
      closed: caseSummaries.filter((cs) => cs.caseStatus === CaseStatusTypes.CLOSED).length,
    }));
    dispatch(setLegalStateLoading(false));

    return caseSummaries;
  } catch (err) {
    dispatch(setLegalStateLoading(false));
    throw err;
  }
});

export const fetchCaseSummariesByFleetUser = (fleetId: string, userId: string, startDate?: Date, endDate?: Date) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setLegalStateLoading(true));
  dispatch(setCaseSummaries([]));
  const { getCaseSummariesByFleetUser } = useLegalApi();
  try {
    const caseSummaries = await getCaseSummariesByFleetUser(fleetId, userId, startDate, endDate);
    dispatch(setCaseSummaries(caseSummaries));
    dispatch(setLegalStateLoading(false));
    return caseSummaries;
  } catch (err) {
    dispatch(setLegalStateLoading(false));
    throw err;
  }
});

export const fetchCaseSummariesByUser = (userId: string, startDate?: Date, endDate?: Date) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setLegalStateLoading(true));
  dispatch(setCaseSummaries([]));
  const { getCaseSummariesByUser } = useLegalApi();
  try {
    const caseSummaries = await getCaseSummariesByUser(userId, startDate, endDate);
    dispatch(setCaseSummaries(caseSummaries));
    dispatch(setLegalStateLoading(false));
    return caseSummaries;
  } catch (err) {
    dispatch(setLegalStateLoading(false));
    throw err;
  }
});

export const fetchFleetCaseDetails = (fleetId: string, caseId: number) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setLegalDetailsLoading(true));
  const { getFleetCaseDetails } = useLegalApi();
  try {
    const caseDetails = await getFleetCaseDetails(fleetId, caseId);
    dispatch(updateCaseDetail(caseDetails));
    dispatch(setLegalDetailsLoading(false));
    return caseDetails;
  } catch (err) {
    dispatch(setLegalStateLoading(false));
    throw err;
  }
});

export const fetchFleetLegalClosingLetter = (fleetId: string, caseId: number) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setClosingLetterLoading(true));
  dispatch(setClosingLetter({ rawText: '', linkToPdf: '' }));
  const { getFleetLegalClosingLetter } = useLegalApi();
  try {
    const closingLetter = await getFleetLegalClosingLetter(fleetId, caseId);
    dispatch(setClosingLetter(closingLetter));
    dispatch(setClosingLetterLoading(false));
    return closingLetter;
  } catch (err) {
    dispatch(setClosingLetterLoading(false));
    throw err;
  }
});

export const fetchIndividualCaseDetails = (caseId: number) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setLegalDetailsLoading(true));
  const { getIndividualCaseDetails } = useLegalApi();
  try {
    const caseDetails = await getIndividualCaseDetails(caseId);
    dispatch(updateCaseDetail(caseDetails));
    dispatch(setLegalDetailsLoading(false));
    return caseDetails;
  } catch (err) {
    dispatch(setLegalStateLoading(false));
    throw err;
  }
});

export const fetchIndividualLegalClosingLetter = (caseId: number) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setClosingLetterLoading(true));
  dispatch(setClosingLetter({ rawText: '', linkToPdf: '' }));
  const { getIndividualLegalClosingLetter } = useLegalApi();
  try {
    const closingLetter = await getIndividualLegalClosingLetter(caseId);
    dispatch(setClosingLetter(closingLetter));
    dispatch(setClosingLetterLoading(false));
    return closingLetter;
  } catch (err) {
    dispatch(setClosingLetterLoading(false));
    throw err;
  }
});

export const fetchUpcomingEventsByFleet = (fleetId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setUpcomingEventsLoading(true));
  dispatch(setUpcomingEvents(undefined));
  const { getUpcomingEventsByFleet } = useLegalApi();
  try {
    const upcomingEvents = await getUpcomingEventsByFleet(fleetId);
    dispatch(setUpcomingEvents(upcomingEvents));
    dispatch(setUpcomingEventsLoading(false));
    return upcomingEvents;
  } catch (err) {
    dispatch(setUpcomingEvents(undefined));
    dispatch(setUpcomingEventsLoading(false));
    throw err;
  }
});

export const fetchUpcomingEventsByUserId = (userId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setUpcomingEventsLoading(true));
  dispatch(setUpcomingEvents(undefined));
  const { getUpcomingEventsByUserId } = useLegalApi();
  try {
    const upcomingEvents = await getUpcomingEventsByUserId(userId);
    dispatch(setUpcomingEvents(upcomingEvents));
    dispatch(setUpcomingEventsLoading(false));
    return upcomingEvents;
  } catch (err) {
    dispatch(setUpcomingEvents(undefined));
    dispatch(setUpcomingEventsLoading(false));
    throw err;
  }
});

export const submitFleetDraftLegalCase = (fleetId: string, caseInfo: CaseInformation, userId?: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setSubmitLegalCaseBusy(true));

  const { postSubmitFleetMembershipCase } = useCaseApi();
  try {
    const caseId = await postSubmitFleetMembershipCase({
      fleetId,
      ...caseInfo,
    });

    if (userId) {
      // re-fetch legal cases for an individual fleet User
      await dispatch(fetchCaseSummariesByFleetUser(fleetId, userId));
    } else {
      // re-fetch all the legal cases for the fleet
      await dispatch(fetchCaseSummariesByFleet(fleetId));
    }

    dispatch(setSubmitLegalCaseBusy(false));
    dispatch(addAlert({
      message: `TVC Case ID: ${caseId}. ${draftLegalCaseStatusText}`,
      severity: 'success',
      autoHideDuration: 8000,
    }));
  } catch (err) {
    dispatch(setSubmitLegalCaseBusy(false));
    throw err;
  }
});

export const submitIndividualMembershipDraftLegalCase = (caseInfo: CaseInformation, userId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setSubmitLegalCaseBusy(true));

  const { postSubmitIndividualMembershipCase } = useCaseApi();
  try {
    const caseId = await postSubmitIndividualMembershipCase(caseInfo);
    // re-fetch individual legal cases
    await dispatch(fetchCaseSummariesByUser(userId));
    dispatch(setSubmitLegalCaseBusy(false));
    dispatch(addAlert({
      message: `TVC Case ID: ${caseId}. ${draftLegalCaseStatusText}`,
      severity: 'success',
      autoHideDuration: 8000,
    }));
  } catch (err) {
    dispatch(setSubmitLegalCaseBusy(false));
    throw err;
  }
});

export const fetchLegalDraftDocuments = (caseId: number, fleetId?: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setLegalDraftDocumentsLoading(true));
  dispatch(setLegalDraftDocuments([]));
  const { getDraftCaseDocument } = useLegalApi();
  const { getFleetDraftCaseDocument } = useFleetApi();
  try {
    let draftDocs: LegalDocument[] = [];

    if (fleetId) {
      draftDocs = await getFleetDraftCaseDocument(fleetId, caseId);
    } else {
      draftDocs = await getDraftCaseDocument(caseId);
    }

    dispatch(setLegalDraftDocuments(draftDocs));
    dispatch(setLegalDraftDocumentsLoading(false));
    return draftDocs;
  } catch (err) {
    dispatch(setLegalDraftDocumentsLoading(false));
    throw err;
  }
});

export const fetchLegalDocumentCategories = () => createBlockingThunk(async (dispatch, getState) => {
  const { legalDocumentCategories } = getState().legal;
  const { getDocumentCategories } = useLegalApi();

  if (legalDocumentCategories.length > 0) {
    return;
  }
  dispatch(setLegalDocumentCategories([]));
  const cats = await getDocumentCategories();
  dispatch(setLegalDocumentCategories(cats));
});

export const exportFleetLegalCases = (fleetId: string, caseIds: number[]) => createBlockingThunk(async () => {
  const { postExportFleetLegalCases } = useLegalApi();
  const exportData: Blob = await postExportFleetLegalCases(fleetId, caseIds);

  return exportData;
});

export const exportIndividualLegalCases = (caseIds: number[]) => createBlockingThunk(async () => {
  const { postExportIndividualLegalCases } = useLegalApi();
  const exportData: Blob = await postExportIndividualLegalCases(caseIds);

  return exportData;
});

export const fetchVehicleTypes = () => createBlockingThunk(async (dispatch, getState) => {
  const { vehicleTypes } = getState().legal;

  if (vehicleTypes.length > 0) {
    return;
  }

  const { getVehicleTypes } = useCaseApi();

  dispatch(setVehicleTypes([]));
  const veTypes = await getVehicleTypes();
  dispatch(setVehicleTypes(veTypes));
});

export const fetchViolationTypes = () => createBlockingThunk(async (dispatch, getState) => {
  const { violationTypes } = getState().legal;
  const { getViolationTypes } = useCaseApi();

  if (violationTypes.length > 0) {
    return;
  }

  setViolationTypes([]);
  const viTypes = await getViolationTypes();
  dispatch(setViolationTypes(viTypes));
});

export const fetchLawTypes = () => createBlockingThunk(async (dispatch, getState) => {
  const { lawTypes } = getState().legal;
  const { getLawTypes } = useCaseApi();

  if (lawTypes.length > 0) {
    return;
  }

  setLawTypes([]);
  // dispatch(setLawTypesLoading(true));
  const lwTypes = await getLawTypes();
  dispatch(setLawTypes(lwTypes));
});

// SELECTORS
export const selectLegal = (state: RootState) => state.legal;
export const selectCaseCount = (state: RootState) => state.legal.legalCaseCount;
export const selectClosingLetter = (state: RootState) => state.legal.caseClosingLetter;
export const selectUpcomingEvents = (state: RootState) => state.legal.upcomingEvents;
export const selectLegalStateLoading = (state: RootState) => state.legal.legalStateLoading;
export const selectLegalDetailsLoading = (state: RootState) => state.legal.legalDetailsLoading;
export const selectClosingLetterLoading = (state: RootState) => state.legal.leglCaseClosingLetterLoading;
export const selectUpcomingEventsLoading = (state: RootState) => state.legal.upcomingEventsLoading;
export const selectsubmitLegalCaseBusy = (state: RootState) => state.legal.submitLegalCaseBusy;
export const selectDraftDocuments = (state: RootState) => state.legal.legalDraftDocuments;
export const selectDraftDocumentsLoading = (state: RootState) => state.legal.legalDraftDocumentsLoading;
export const selectLegalDocumentCategories = (state: RootState) => state.legal.legalDocumentCategories;
export const selectVehicleTypes = (state: RootState) => state.legal.vehicleTypes;
export const selectViolationTypes = (state: RootState) => state.legal.violationTypes;
export const selectLawTypes = (state: RootState) => state.legal.lawTypes;

export default legalSlice.reducer;
