import React, { useEffect, useState } from 'react';
import {
  Box,
  createTheme,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  Paper,
  Slide,
  adaptV4Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import BackgroundImage from '../../assets/tvc-prodriver-background.jpg';
import ContentPadding from '../../shared/components/ContentPadding';
import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import MatrixLoginForm from './MatrixLoginForm';
import { slideTimeout } from '../../shared/transitions/timeouts';
import MatrixLoginSuccess from './MatrixLoginSuccess';
import MigrationLogo from './MigrationLogo';
import { useAppDispatch } from '../../app/store';
import { selectCodeVerified, selectMigrationResponse, submitMatrixLogin } from './migrationSlice';
import MigrationHeader from './MigrationHeader';
import { useTvcPortalApiValidationErrors } from '../../shared/hooks/useTvcPortalApiValidationErrors';
import CreateNewPortalAccount from './CreateNewPortalAccount';

const MigrateMemberToPortal = () => {
  const theme = useTheme();
  const styles = {
    dialog: {
      position: 'absolute',
      top: '90px',
      [theme.breakpoints.down('lg')]: {
        top: '50px',
      },
      [theme.breakpoints.down('md')]: {
        top: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        top: '10px',
      },
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '60px',
      [theme.breakpoints.down('xl')]: {
        marginTop: '30px',
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '85%',
        margin: 'auto',
        maxWidth: '1300px',
      },
    },
    gradient: {
      minHeight: '100vh',
      background: 'linear-gradient(0deg, rgba(0, 92, 157, 0.23012955182072825) 6%,rgba(255, 255, 255, 0) 100%)',
    },
  };

  const migrationTheme = createTheme(adaptV4Theme({
    ...theme,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundImage: `url(${BackgroundImage})`,
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          },
        },
      },
    },
  }));
  const { handleError } = useAsyncErrorHandler();
  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const [open, setOpen] = useState<boolean>(true);

  const history = useHistory();

  const {
    apiValidationErrors,
    hasApiValidationErrors,
    setApiValidationErrors,
  } = useTvcPortalApiValidationErrors();

  const handleClose = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown' | 'confirmed') => {
    if (reason && reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  };

  const loginResponse = useSelector(selectMigrationResponse);
  const emailCodeVerified = useSelector(selectCodeVerified);

  useEffect(() => {
    if (loginResponse) {
      handleClose(undefined, 'confirmed');
      history.push(`${path}/confirm`);
    }
  }, [loginResponse]);

  useEffect(() => {
    if (emailCodeVerified) {
      history.push(`${path}/create-new-account`);
    }
  }, [emailCodeVerified]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={migrationTheme}>
          <CssBaseline />

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            sx={{
              '& .MuiDialog-paper': styles.dialog,
            }}
            // onBackdropClick={handleBackdropClick}
            disableEscapeKeyDown
          >
            <DialogContent>
              <Box sx={{ marginBottom: '18px' }}>
                <MigrationLogo headerText="Portal Account Migration" />
              </Box>
              <DialogContentText>
                To begin the migration of your members.prodriver.com account to our new portal, please login using your existing members.prodriver.com credentials.
              </DialogContentText>
              <Box sx={{ marginTop: '38px' }}>
                <MatrixLoginForm
                  validationErrors={apiValidationErrors}
                  onFormSubmit={async (matrixLoginFormData) => {
                    try {
                      setApiValidationErrors([]);
                      await dispatch(submitMatrixLogin(matrixLoginFormData));
                    } catch (err) {
                      if (!hasApiValidationErrors({ errorResponse: err })) {
                        handleError(err);
                      }
                    }
                  }}
                />
              </Box>

            </DialogContent>
          </Dialog>

          {!open && loginResponse && (
            <Box sx={styles.gradient}>
              <Box sx={styles.container}>
                <Box sx={styles.contentContainer}>
                  <ContentPadding>
                    <Slide direction="up" in mountOnEnter timeout={slideTimeout}>
                      <Grid container spacing={2}>
                        {/* HEADER  */}
                        <MigrationHeader />
                        {/* CONTENT  */}
                        <Grid item xs={12}>
                          <Paper elevation={2}>
                            <ContentPadding>
                              <Switch>
                                <Route
                                  path={`${path}/confirm`}
                                  exact
                                  render={() => <MatrixLoginSuccess />}
                                />
                                <Route
                                  path={`${path}/create-new-account`}
                                  exact
                                  component={CreateNewPortalAccount}
                                />
                              </Switch>

                            </ContentPadding>
                          </Paper>
                        </Grid>

                      </Grid>
                    </Slide>
                  </ContentPadding>

                </Box>

              </Box>
            </Box>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default MigrateMemberToPortal;
