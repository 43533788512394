import React from 'react';
import {
  Avatar,
  Card,
  CardHeader,
  Fade,
  IconButton,
  styled,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PaymentMethod } from '../../../shared/models/payment-method/PaymentMethod';
import PaymentMethodIcon from './PaymentMethodIcon';
import { PaymentSubheader } from './PaymentMethodSubHeader';
import { PaymentMethodType } from '../../../shared/enums/payment-method/PaymentMethodType';

interface PaymentMethodCardProps {
  index?: number;
  paymentMethod: PaymentMethod;
  transitionDelayModifier?: number;
  onDeleteClick: () => void;
  userCanDeletePaymentMethod?: boolean;
  paperElevation?: number;
}

const StyledCardHeader = styled(CardHeader)({
  '& .MuiCardHeader-action': {
    alignSelf: 'unset',
  },
  flexBasis: '100%',
});

export const paymentMethodText = (pmType: PaymentMethodType) => {
  switch (pmType) {
    case PaymentMethodType.BankAccount:
      return 'Bank Account';
    case PaymentMethodType.CreditCard:
      return 'Credit Card';
    case PaymentMethodType.Check:
      return 'Check';
    default:
      return 'Unknown';
  }
};

const getPaymentMethodText = (paymentMethod: PaymentMethod) => paymentMethodText(paymentMethod.paymentMethodType);

const PaymentMethodCard = (props: PaymentMethodCardProps) => {
  const {
    index,
    paymentMethod,
    transitionDelayModifier = 0,
    onDeleteClick,
    userCanDeletePaymentMethod = false,
    paperElevation = 2,
  } = props;

  const getFormattedTitle = (pm: PaymentMethod) => {
    const { nameOnAccount, name } = pm;

    return (
      <>
        {name}
        {' '}
        {nameOnAccount ? (
          <span style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.54)' }}>
            (
            {nameOnAccount}
            )
          </span>
        ) : ''}
      </>
    );
  };

  return (
    <Fade
      in
      style={{
        transitionDelay: index ? `${transitionDelayModifier * index}ms` : '0ms',
      }}
      timeout={600}
    >
      <div>
        <Card elevation={paperElevation} sx={{ minHeight: '112px', display: 'flex' }}>
          <StyledCardHeader
            style={{
              minHeight: '92px',
            }}
            avatar={(
              <Avatar
                aria-label={getPaymentMethodText(paymentMethod)}
                sx={{
                  color: '#bdbdbd',
                  backgroundColor: 'transparent',
                }}
              >
                <PaymentMethodIcon paymentMethod={paymentMethod} />
              </Avatar>
          )}
            action={(
              <>
                {!paymentMethod.isCheck && userCanDeletePaymentMethod && (
                <Tooltip
                  title="Delete payment method"
                  aria-label="delete payment method"
                >
                  <span>
                    <IconButton
                      aria-label="delete payment method"
                      onClick={
                        userCanDeletePaymentMethod ? onDeleteClick : undefined
                      }
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                )}
              </>
          )}
            title={getFormattedTitle(paymentMethod)}
            subheader={<PaymentSubheader paymentMethod={paymentMethod} />}
          />
        </Card>
      </div>
    </Fade>
  );
};

export default PaymentMethodCard;
