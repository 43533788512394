import { Box } from '@mui/material';
import React from 'react';

interface SectionRowProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}
const SectionRow = (props: SectionRowProps) => {
  const { children, style } = props;
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexBasis="100%"
      alignItems="center"
      sx={style}
    >
      {children}
    </Box>
  );
};

export default SectionRow;
