import { NamedPermission } from '../../shared/types/permissions/NamedPermission';

export enum UserPermissions {
  canEditOwnPermissions,
  canViewAllFleets,
  canViewPortalAdministratorRoute,
  canEditEmployeePermissions,
  canAddEmployee,
  canAddFleet,
  canAddFuelAdjustment,
  canEditFuelLoadLimits,
  canEditFleetGeneralInfo,
  canAddEditScheduledLoads,
  canViewAllIndividualMemberships,
  canManageIndividualFuel,
  canManageIndividualLegal,
  canManageIndividualPortalUser,
  canApproveNameChangeRequests,
  canAccessIndividualDashboard,
  canManageFuelSponsorRegistrationLastCreationDate,
}

export const userPermissionNames: NamedPermission<UserPermissions>[] = [
  {
    name: UserPermissions.canViewAllFleets,
    action: '*',
    entity: '*',
    scope: 'Fleet*',
    userPerms: [{
      action: 'Edit-Load-Limits',
      entity: 'FuelLoadLimit',
      scope: 'Portal',
    }, {
      action: 'Edit-General-Info',
      entity: 'Fleet',
      scope: 'Portal',
    }, {
      action: 'Add-Edit-Scheduled-Load',
      entity: 'Fuel',
      scope: 'Portal',
    }],
  },
  {
    name: UserPermissions.canEditOwnPermissions,
    action: 'Edit',
    entity: 'SelfPermissions',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canViewPortalAdministratorRoute,
    action: '*',
    entity: 'User',
    scope: 'Portal',
    userPerms: [{
      action: 'Add-Edit-Scheduled-Load',
      entity: 'Fuel',
      scope: 'Portal',
    }],
  },
  {
    name: UserPermissions.canApproveNameChangeRequests,
    action: 'Approve-Name-Change-Requests', // Name = Approve Name Change Requests, Description = Can approve name change requests
    entity: 'User*',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canEditEmployeePermissions,
    action: 'Edit-Employee',
    entity: 'User*',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canAddEmployee,
    action: 'Add-Employee',
    entity: 'User*',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canAddFleet,
    action: 'Add-Fleet',
    entity: 'Fleet',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canAddFuelAdjustment,
    action: 'Add-Fuel-Adjustment',
    entity: 'Fuel',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canEditFuelLoadLimits,
    action: 'Edit-Load-Limits',
    entity: 'FuelLoadLimit',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canEditFleetGeneralInfo,
    action: 'Edit-General-Info',
    entity: 'Fleet',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canAddEditScheduledLoads,
    action: 'Add-Edit-Scheduled-Load',
    entity: 'Fuel',
    scope: 'Portal',
  },
  {
    name: UserPermissions.canViewAllIndividualMemberships,
    action: '*',
    entity: '*',
    scope: 'Member*',
  },
  {
    name: UserPermissions.canManageIndividualLegal,
    action: 'Manage-Legal',
    entity: 'Legal',
    scope: 'Member*',
  },
  {
    name: UserPermissions.canManageIndividualFuel,
    action: 'Manage-Fuel',
    entity: 'Fuel',
    scope: 'Member*',
  },
  {
    name: UserPermissions.canManageIndividualPortalUser,
    action: 'Manage-User',
    entity: 'User*',
    scope: 'Member*',
  },
  {
    name: UserPermissions.canAccessIndividualDashboard,
    action: 'View-Dashboard',
    entity: 'Dashboard',
    scope: 'Member*',
  },
  {
    name: UserPermissions.canManageFuelSponsorRegistrationLastCreationDate,
    action: 'Manage-Fuel-Sponsor-Registration-Last-CreationDate',
    entity: 'FuelSponsorRegistration',
    scope: 'Portal',
  },
];

export enum FleetUserPermissions {
  canViewFleetRoute,
  canViewFleetAccount,
  canAddFleetPaymentMethod,
  canDeleteFleetPaymentMethod,
  canViewFleetFuelContractBalance,
  canLoadFleetFuelContract,
  canViewFleetFuelActivity,
  canAddFleetUser,
  canViewFleetUsers,
  canEditFleetUserPermissions,
  canEditFleetUser,
  canViewFleetFuelCards,
  canAssignFleetFuelCards,
  canViewFleetFuelRoute,
  canManageFleetFuelNotifications,
  canManageFleetLegalNotifications,
  canViewFleetLegalRoute,
  canViewFleetLegalCases,
  canAddFleetLegalCases,
  canAccessFleetDashboard,
}

export const fleetUserPermissionNames: NamedPermission<FleetUserPermissions>[] = [
  {
    name: FleetUserPermissions.canViewFleetRoute,
    action: '*',
    entity: '*',
    userPerms: [{
      action: 'Edit-Load-Limits',
      entity: 'FuelLoadLimit',
      scope: 'Portal',
    }, {
      action: 'Edit-General-Info',
      entity: 'Fleet',
      scope: 'Portal',
    }],
  },
  {
    name: FleetUserPermissions.canViewFleetAccount,
    action: '*',
    entity: 'Fleet',
    userPerms: [{
      action: 'Edit-General-Info',
      entity: 'Fleet',
      scope: 'Portal',
    }],
  },
  {
    name: FleetUserPermissions.canAddFleetPaymentMethod,
    action: 'Add-PaymentMethod',
    entity: 'Fleet',
  },
  {
    name: FleetUserPermissions.canDeleteFleetPaymentMethod,
    action: 'Delete-PaymentMethod',
    entity: 'Fleet',
  },
  {
    name: FleetUserPermissions.canViewFleetFuelContractBalance,
    action: 'View-Contract-Balance',
    entity: 'Fuel',
  },
  {
    name: FleetUserPermissions.canLoadFleetFuelContract,
    action: 'Load-Funds-Contract',
    entity: 'Fuel',
  },
  {
    name: FleetUserPermissions.canViewFleetFuelActivity,
    action: 'View-Fuel-Activity',
    entity: 'Fuel',
  },
  {
    name: FleetUserPermissions.canAddFleetUser,
    action: 'Add-Fleet-User',
    entity: 'User*',
  },
  {
    name: FleetUserPermissions.canViewFleetUsers,
    action: '*',
    entity: 'User*',
  },
  {
    name: FleetUserPermissions.canEditFleetUserPermissions,
    action: 'Edit-Fleet-User-Permissions',
    entity: 'User*',
  },
  {
    name: FleetUserPermissions.canEditFleetUser,
    action: 'Edit-Fleet-User',
    entity: 'User*',
  },
  {
    name: FleetUserPermissions.canViewFleetFuelCards,
    action: 'View-Fuel-Cards',
    entity: 'Fuel',
  },
  {
    name: FleetUserPermissions.canAssignFleetFuelCards,
    action: 'Assign-Fuel-Card',
    entity: 'Fuel',
  },
  {
    name: FleetUserPermissions.canViewFleetFuelRoute,
    action: '*',
    entity: 'Fuel',
    userPerms: [{
      action: 'Edit-Load-Limits',
      entity: 'FuelLoadLimit',
      scope: 'Portal',
    }],
  },
  {
    name: FleetUserPermissions.canManageFleetFuelNotifications,
    action: 'Manage-Notifications',
    entity: 'Fuel',
  },
  {
    name: FleetUserPermissions.canViewFleetLegalRoute,
    action: '*',
    entity: 'Legal',
  },
  {
    name: FleetUserPermissions.canViewFleetLegalCases,
    action: 'View',
    entity: 'Legal',
  },
  {
    name: FleetUserPermissions.canAddFleetLegalCases,
    action: 'Add-Legal-Cases',
    entity: 'Legal',
  },
  {
    name: FleetUserPermissions.canManageFleetLegalNotifications,
    action: 'Manage-Notifications',
    entity: 'Legal',
  },
  {
    name: FleetUserPermissions.canAccessFleetDashboard,
    action: 'View-Dashboard',
    entity: 'Dashboard',
  },
];
