import { AppThunk } from '../app/store';
import {
  addAlert,
  decrementBlockingRequests,
  incrementBlockingRequests,
} from '../features/application/applicationSlice';
import {
  getAzureFullAccount,
  getErrorInfo,
  logErrorWithApplicaitonInsights,
} from '../shared/hooks/useTvcAppInsights';

const createThunk = <Return, ParType>(
  blocking: boolean,
  thunk: AppThunk<Promise<Return>, ParType | undefined>,
  extraArgument: ParType | undefined = undefined,
): AppThunk<Promise<Return>, ParType> => async (dispatch, getState) => {
    let result: any = {};
    try {
      if (blocking) {
        dispatch(incrementBlockingRequests());
      }
      result = await thunk(dispatch, getState, extraArgument);

      return result;
    } catch (e) {
      const error = e as Error;
      const accountInfo = getAzureFullAccount(getState().auth.azureAccount);
      const errorInfo = getErrorInfo(error, accountInfo);

      logErrorWithApplicaitonInsights(error, errorInfo);

      dispatch(
        addAlert({
          message: error.message,
          severity: 'error',
          autoHideDuration: 10000,
        }),
      );

      throw error;
    } finally {
      if (blocking) {
        dispatch(decrementBlockingRequests());
      }
    }
  };

export const createBlockingThunk = <Return, ParType = unknown>(
  thunk: AppThunk<Promise<Return>, ParType | undefined>,
  extraArgument: ParType | undefined = undefined,
): AppThunk<Promise<Return>, ParType> => createThunk(true, thunk, extraArgument);

export const createNonBlockingThunk = <Return, ParType = unknown>(
  thunk: AppThunk<Promise<Return>, ParType | undefined>,
) => createThunk(false, thunk);
