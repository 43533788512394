import React from 'react';
import {
  Box,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import {
  format, isAfter, isBefore, isValid,
} from 'date-fns';

export interface CustomDateInputProps {
  name?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  id?: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  additionalValidation?: any;
  shouldDisableDate?: (day: Date) => boolean
}

const CustomDateInput = (props: CustomDateInputProps) => {
  const {
    label = 'Date',
    id,
    disabled = false,
    name = 'date',
    required,
    minDate,
    maxDate,
    additionalValidation,
    shouldDisableDate,
  } = props;

  const { control } = useFormContext();

  const defaultInputId = id || `${name}-control`;

  return (
    // NOTE: Hardcoded style adjustment to account for helpertext being removed from DOM when no error is present. Otherwise, the inputs jump
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '80px',
      }}
    >
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? `${label} required` : false,
          validate: {
            isValid: (d: Date) => {
              // Don't enforce if d is empty and date is not required
              if (!d && !required) {
                return true;
              }

              return isValid(d) ? true : `${label} is invalid`;
            },
            isAfterMaxDate: (d: Date) => {
              if (d && maxDate && isValid(d) && isValid(maxDate)) {
                if (isAfter(d, maxDate)) {
                  return `${label} must be before ${format(maxDate, 'MM/dd/yyyy')}`;
                }
              }
              return true;
            },
            isBeforeMinDate: (d) => {
              if (d && minDate && isValid(d) && isValid(minDate)) {
                if (isBefore(d, minDate)) {
                  return `${label} must be after ${format(minDate, 'MM/dd/yyyy')}`;
                }
              }
              return true;
            },
            ...additionalValidation,
          },
        }}
        render={({ fieldState, field: { ref, ...rest } }) => (
          <DatePicker
            shouldDisableDate={shouldDisableDate}
            disabled={disabled}
            label={`${label}${required ? ' *' : ''}`}
            format="MM/dd/yyyy"
            inputRef={ref}
            maxDate={maxDate || undefined}
            slotProps={{
              textField: {
                id: defaultInputId,
                error: Boolean(fieldState.error),
                helperText: fieldState.error ? fieldState.error.message : null,
              },
            }}
            {...rest}
          />
        )}
      />
    </Box>
  );
};

export default CustomDateInput;
