import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Fleet } from '../../shared/models/fleet/Fleet';
import { SubRow } from '../../shared/components/SubRow';

interface FleetCardItemDisplayProps {
  fleet: Fleet;
}

const FleetCardItemDisplay = (props: FleetCardItemDisplayProps) => {
  const { fleet } = props;
  const theme = useTheme();
  const minWidth = 68;

  return (
    <Box sx={{ fontSize: '1.4em', marginTop: '8px' }}>
      <SubRow title="Org ID" value={fleet.organizationId} titleMinWidth={minWidth} />
      <SubRow title="Root ID" value={fleet.rootMemberId} titleMinWidth={minWidth} />
      <SubRow title="Carrier ID" value={fleet.carrierId} titleMinWidth={minWidth} valueColor={!fleet.isValidCarrier ? theme.palette.error.main : undefined} />
    </Box>

  );
};

export default FleetCardItemDisplay;
