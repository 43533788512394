/* eslint-disable no-console */
import { any } from 'lodash/fp';
import { Profile } from '../../shared/models/user/Profile';

import { parseEnv } from '../../config';
import { NamedPermission } from '../../shared/types/permissions/NamedPermission';
import { PermissionCache } from '../../shared/models/permissions/PermissionCache';
import { PermissionCacheLookup } from '../../shared/types/permissions/PermissionCacheLookup';
import { PortalApiPermission } from '../../shared/models/permissions/PortalApiPermission';

const config = parseEnv();

const debug: boolean = Boolean(config.REACT_APP_LOG_PERMISSION_CHECKING);

const getNames = (name: string | undefined) => {
  const isNameNormal: boolean = name !== undefined && !name.endsWith('*');
  const normalName: string | undefined = isNameNormal ? name : undefined;
  const lookupName: string | undefined = normalName !== undefined
    ? normalName
    : name === undefined || name.length === 1
      ? undefined
      : name.substr(0, name.length - 1);
  const wildName: string | undefined = isNameNormal ? `${name}*` : name;
  return { normalName, wildName, lookupName };
};

export const computeAllow = (
  action: string,
  entity: string,
  scopeName: string,
  permissions: PortalApiPermission[],
  scopePermissions: PortalApiPermission[] | undefined,
) => {
  const {
    normalName: normalScopeName,
    wildName: wildScopeName,
    lookupName,
  } = getNames(scopeName);
  let effectivePermissions: PortalApiPermission[] = [
    ...permissions.filter(
      (p) => p.scope === '*'
        || p.scope === normalScopeName
        || p.scope === wildScopeName,
    ),
    ...(scopePermissions
      ? scopePermissions.filter(
        (p) => p.scope === normalScopeName || p.scope === wildScopeName,
      )
      : []),
  ];
  if (debug) console.log(`asking for ${action} ${entity} ${scopeName}`);
  if (debug) {
    console.log(
      `   scope ${lookupName} permissions ${JSON.stringify(scopePermissions)}`,
    );
  }
  if (debug) {
    console.log(
      `   effective permissions ${JSON.stringify(effectivePermissions)}`,
    );
  }
  if (debug) console.log(`   checking scope ${normalScopeName} ${wildScopeName}`);
  if (effectivePermissions.length === 0) return false;
  const { normalName: normalEntityName, wildName: wildEntityName } = getNames(entity);
  if (debug) console.log(`   checking entity ${normalEntityName} ${wildEntityName} against effective permissions ${JSON.stringify(effectivePermissions)}`);
  effectivePermissions = effectivePermissions.filter(
    (p) => p.entity === '*'
      || wildEntityName === '*'
      || p.entity === normalEntityName
      || p.entity === wildEntityName,
  );
  if (effectivePermissions.length === 0) return false;
  const { normalName: normalActionName, wildName: wildActionName } = getNames(action);
  if (debug) console.log(`   checking action ${normalActionName} ${wildActionName} against effective permissions ${JSON.stringify(effectivePermissions)}`);
  effectivePermissions = effectivePermissions.filter(
    (p) => p.action === '*'
      || wildActionName === '*'
      || p.action === normalActionName
      || p.action === wildActionName,
  );
  if (effectivePermissions.length === 0) return false;
  if (debug) console.log(`   granting ${JSON.stringify(effectivePermissions)}`);
  return true;
};

export const isTvcSuperAdmin = (profile: Profile) => any<PortalApiPermission>(
  (permission) => permission.action === '*'
    && permission.entity === '*'
    && permission.scope === '*',
)(profile.permissions) && profile.isEmployee;

export const loadPermissionCache = <T extends string | symbol | number>(
  namePermissions: NamedPermission<T>[],
  permissions: PortalApiPermission[] | null,
  scopePermissions: PortalApiPermission[] | undefined,
): PermissionCache<T> => {
  const permissionCache: PermissionCacheLookup<T> = {} as PermissionCacheLookup<T>;
  namePermissions.forEach((namePermission) => {
    // 'Fleet' could be done better but works for now
    const perm: boolean = permissions
      ? computeAllow(
        namePermission.action,
        namePermission.entity,
        namePermission.scope ? namePermission.scope : 'Fleet',
        permissions,
        scopePermissions,
      ) || (namePermission.userPerms !== undefined && namePermission.userPerms.some((up) => computeAllow(up.action, up.entity, up.scope, permissions, scopePermissions)))
      : false;
    permissionCache[namePermission.name] = perm;
  });
  return { permissions: permissionCache };
};
