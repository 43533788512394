import { useSelector } from 'react-redux';
import { selectSelectedProfile } from '../../user/userSlice';

export const useSelectedProfilePortalUser = () => {
  const selectSelectedProfilePortalUser = useSelector(selectSelectedProfile())?.portalUser;

  return {
    selectSelectedProfilePortalUser,
  };
};
