import React from 'react';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import { FilterOption } from '../../../shared/components/search-filter/FilterOption';
import MultiSelectFilter from '../../../shared/components/search-filter/MultiSelectFilter';
import { StatusFilterProps } from '../../../shared/components/search-filter/StatusFilterProps';
import { UserMembershipIcon } from '../../../shared/components/UserIcons';

export const userMembershipStatuses: FilterOption<PortalApiUser>[] = [

  {
    displayName: 'Active',
    key: 'active-membership',
    icon: <UserMembershipIcon
      hasMembership
      membershipIsValid
    />,
    condition: (user: PortalApiUser) => user.hasMembership && user.hasValidMembership,
  },
  {
    displayName: 'Inactive',
    key: 'inactive-membership',
    icon: <UserMembershipIcon
      hasMembership
      membershipIsValid={false}
    />,
    condition: (user: PortalApiUser) => user.hasMembership && !user.hasValidMembership,
  },
  {
    displayName: 'Future Start Date',
    key: 'future-start-date',
    icon: <UserMembershipIcon
      hasMembership={false}
      membershipIsValid={false}
      startDate="<start date>"
    />,
    condition: (user: PortalApiUser) => user.hasMembership && !user.hasValidMembership && Boolean(user.startDate),
  },
  {
    displayName: 'No Membership',
    key: 'no-membership',
    icon: <UserMembershipIcon
      hasMembership={false}
      membershipIsValid={false}
    />,
    condition: (user: PortalApiUser) => !user.hasMembership && !user.hasValidMembership,
  },
];

const MembershipStatusFilter = (props: StatusFilterProps) => {
  const { value, handleSelection, disabled } = props;
  return (
    <MultiSelectFilter<PortalApiUser>
      disabled={disabled}
      inputLabel="Membership Status"
      labelId="filter-membership-label"
      selectId="filter-membership-select"
      value={value}
      handleSelection={handleSelection}
      options={userMembershipStatuses}
    />
  );
};

export default MembershipStatusFilter;
