import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/store';
import { theme as appTheme } from '../../appTheme';
import { PortalApiMatrixUser } from '../../shared/models/matrix/PortalApiMatrixUser';
import { UserInformation } from '../../shared/models/user/UserInformation';
import DetailsWrapper from '../../shared/components/DetailsWrapper';

import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import { useTvcPortalApiValidationErrors } from '../../shared/hooks/useTvcPortalApiValidationErrors';
import { FleetUserPermissions } from '../auth/permissionNames';

import { selectFleetById } from '../fleet/fleetSlice';
import { UserLoginAccountIcon, UserMembershipIcon } from '../../shared/components/UserIcons';
import AddAUser, { ExistingUser } from './AddAUser';
import {
  addFleetUser,
  retrieveFleetPermissions, selectFleetPermissions, selectFleetUsersByFleetId,
} from './userSlice';
import { useMatrixApi } from '../../services/api/hooks/useMatrixApi';
import { PortalApiPermissionFull } from '../../shared/models/permissions/PortalApiPermissionFull';
import { convertUserInfoToPortalUserBody } from '../../shared/utilities/userHelpers';
import { useFleetApi } from '../../services/api/hooks/useFleetApi';
import { useCommon } from '../../shared/hooks/useCommon';

interface AddFleetUserModalProps {
  onClose: () => void;
  open: boolean;
  hideUserTypeSelection?: boolean;
  modalTitle?: string;
  user?: UserInformation;
  userTypeSelection?: 'login' | 'membership' | 'both',
  canEditUserPermissions?: boolean;
  fleetIdOverride?: string;
  postAddUserFunc?: () => void;
  showHelp?: boolean;
}

const AddFleetUserModal = (props: AddFleetUserModalProps) => {
  const {
    open,
    onClose,
    hideUserTypeSelection = false,
    modalTitle = 'Add a User',
    user,
    userTypeSelection = 'login',
    fleetIdOverride,
    postAddUserFunc,
    showHelp,
  } = props;
  const { getFleetMatrixUsers } = useFleetApi();
  const { getMemberDetails } = useMatrixApi();
  const { config } = useCommon();
  const { handleError } = useAsyncErrorHandler();
  const [allowClose, setAllowClose] = useState(true);
  const { fleetId } = useParams<{
    fleetId: string;
  }>();

  const [unassignedMemberhips, setUnassignedMemberhips] = useState<PortalApiMatrixUser[]>();
  const [existingUsers, setExistingUsers] = useState<ExistingUser[]>([]);

  const fleet = useSelector(selectFleetById(fleetIdOverride || fleetId));
  const fleetUsers = useSelector(selectFleetUsersByFleetId(fleetIdOverride || fleetId));
  const fleetHasUsers = fleetUsers.length > 0;

  const {
    hasApiValidationErrors,
  } = useTvcPortalApiValidationErrors();

  const fetchFleetMMatrixUsers = async (fId: string) => {
    if (fId && fleet?.cachePermissions?.permissions[
      FleetUserPermissions.canAddFleetUser
    ]) {
      await getFleetMatrixUsers(fleet.id).then(setUnassignedMemberhips);
    }
  };

  useEffect(() => {
    if (fleet?.id) {
      fetchFleetMMatrixUsers(fleet.id);
    }
  }, [fleet]);

  const handleClose = () => {
    if (!allowClose) {
      return;
    }

    onClose();
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(retrieveFleetPermissions());
  }, []);

  const getRootMemberDetails = async () => {
    const ret = {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      phoneCell: '',
      dlNumber: '',
      memberId: undefined,
      alternateId: '',
    };

    if (fleet?.rootMemberId) {
      try {
        const rootMemberDetails = fleet ? await getMemberDetails(fleet.rootMemberId) : undefined;
        return {
          ...ret,
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
          email: user?.account?.email,
          address: rootMemberDetails?.address ?? '',
          city: rootMemberDetails?.city ?? '',
          state: rootMemberDetails?.state ? rootMemberDetails.state.toUpperCase() : '',
          country: rootMemberDetails?.country,
          zipCode: rootMemberDetails?.postalCode ?? '',
          phoneCell: rootMemberDetails?.phoneCell ?? '',
        };
      } catch (err) {
        handleError(err);
      }
    }

    return ret;
  };

  return (
    <DetailsWrapper
      title={modalTitle}
      onClose={handleClose}
      open={open}
      titlebackgroundcolor={appTheme.palette.primary.dark}
      maxLargeScreenDialogWidth={1300}
      isAppBarSticky
    >
      <AddAUser
        hideSpecialPermissionsCheckbox={!fleetHasUsers}
        showHelp={showHelp}
        lockPermissions={!fleetHasUsers}
        lockPermissionsReason={!fleetHasUsers ? 'Because this fleet does not have a user login, the permissions for the user you are adding must be "Fleet Super Administrator"' : undefined}
        hideUserTypeSelection={hideUserTypeSelection}
        userTypeSelection={userTypeSelection}
        user={user}
        getDefaultContactValue={getRootMemberDetails}
        forceCustomContactValue={!(fleet?.rootMemberId)}
        userCanEditPermissions={fleet?.cachePermissions?.permissions[
          FleetUserPermissions.canEditFleetUserPermissions
        ]}
        getDefaultPermissions={(allPermissions: PortalApiPermissionFull[]) => allPermissions.map((p) => ({
          ...p,
          isSelected: !fleetHasUsers ? p.id.toUpperCase() === config.REACT_APP_FLEET_SUPER_ADMINISTRATOR_GUID : p.isFleetDefault,
          isDependee: false,
          isPrerequisiteBlock: false,
        })).filter((p) => p.isSelected)}
        matrixUsers={unassignedMemberhips || []}
        allPermissionsSelector={selectFleetPermissions}
        onFormSubmitted={async (addUser: UserInformation, isFleetDefaultContactInfo: boolean) => {
          setAllowClose(false);
          const request = {
            fleetId: fleetIdOverride || fleetId,
            isFleetDefaultContactInfo,
            ...convertUserInfoToPortalUserBody(addUser),
          };

          try {
            await dispatch(addFleetUser(request, fleet?.name));
            await fetchFleetMMatrixUsers(fleetIdOverride || fleetId);

            if (postAddUserFunc) {
              postAddUserFunc();
            }
            handleClose();
          } catch (err: any) {
            if (!hasApiValidationErrors({ errorResponse: err })) {
              handleError(err);
            }
          } finally {
            setAllowClose(true);
          }
        }}
        onLastNameChanged={(lastName: string) => {
          if (lastName.length > 2) {
            const users = fleetUsers.filter((fu) => fu.lastName.toLowerCase().includes(lastName.toLocaleLowerCase()));
            setExistingUsers(users.length > 0 ? users.map((u) => ({
              fullName: `${u.firstName} ${u.lastName}`,
              loginIcon: <UserLoginAccountIcon hasLoginAccount={u.hasLogin} loginAccountIsActive={!u.isDisabled} />,
              membershipIcon: <UserMembershipIcon hasMembership={u.hasMembership} membershipIsValid={u.hasValidMembership} applySpacing startDate={u.startDate} />,
            })) : []);
          } else {
            setExistingUsers([]);
          }
        }}
        existingUsers={existingUsers}
        productPromotions={fleet?.productPromotions}
      />

    </DetailsWrapper>
  );
};

export default AddFleetUserModal;
