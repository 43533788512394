import React from 'react';

interface ShowHideProps {
  children: React.ReactNode;
  show: boolean | undefined;
}

const ShowHide = (props: ShowHideProps) => {
  const { children, show = false } = props;

  return show ? <>{children}</> : null;
};

export default ShowHide;
