import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface FormSubmissionFeedbackButtonsProps {
  children: React.ReactNode;
  boxStyle?: React.CSSProperties;
}

const FormSubmissionFeedbackButtons = (
  props: FormSubmissionFeedbackButtonsProps,
) => {
  const { children, boxStyle } = props;

  const theme = useTheme();
  const styles = {
    formFeedbackButtons: {
      display: 'flex',
      flex: '1',
      justifyContent: 'space-evenly',
      [theme.breakpoints.down('lg')]: {
        marginTop: '20px',
        flexBasis: '100%',
      },
    },
  };

  return (
    <Box sx={{
      ...styles.formFeedbackButtons,
      ...boxStyle,
    }}
    >
      {children}
    </Box>
  );
};

export default FormSubmissionFeedbackButtons;
