import { useState } from 'react';
import { ProcessStep } from '../models/other/ProcessStep';

export const useTvcPortalApiStepsResponse = () => {
  const [apiStepsResponse, setApiStepsResponse] = useState<ProcessStep[]>([]);

  const hasApiStepsResponse = (obj: any): boolean => {
    if (!obj.hasOwnProperty('response')) {
      return false;
    }

    if (!obj.response) { return false; }

    const { data } = obj.response;

    if (data === null || !data.hasOwnProperty('steps')) {
      return false;
    }

    if (data.steps && data.steps.length > 0) {
      setApiStepsResponse(data.steps);
      return true;
    }

    return false;
  };

  return {
    hasApiStepsResponse,
    apiStepsResponse,
    setApiStepsResponse,
  };
};
