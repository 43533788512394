import React from 'react';
import { Box, Typography } from '@mui/material';

interface StandardHeaderProps {
  icon?: React.ReactNode;
  headerText?: React.ReactNode;
}

const StandardHeader = (props: StandardHeaderProps) => {
  const { headerText, icon } = props;

  return (
    <Box display="flex" alignItems="center" sx={{ marginBottom: '8px' }}>
      {icon && (
        <Box display="flex" alignItems="center" sx={{ marginRight: '10px' }}>
          {' '}
          {icon}
        </Box>
      )}
      {headerText && (
        <Typography variant="h6" sx={{ flex: 1, fontSize: '1.10rem' }}>
          {headerText}
        </Typography>
      )}
    </Box>
  );
};

export default StandardHeader;
