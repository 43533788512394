import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@mui/material';
import { get } from 'lodash/fp';
import React from 'react';
import {
  Controller,
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';
import InputMask from 'react-input-mask';

interface CustomTextInputProps {
  name: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  mask: string;
  defaultValue?: string;
  disabled?: boolean;
  style?: Object;
  id?: string;
  type?: string;
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  showAdornment?: boolean;
  autoComplete?: boolean;
  maxLength?: number;
}

const CustomTextMaskInput = (props: CustomTextInputProps) => {
  const {
    mask,
    style,
    label,
    id,
    disabled,
    type,
    name,
    rules,
    defaultValue,
    fullWidth = false,
    showAdornment,
    endAdornment,
    autoComplete = true,
    maxLength,
  } = props;
  const { control } = useFormContext();
  const { fieldState } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || '',
  });
  const styles = {
    helperTextRoot: {
      marginLeft: '0px !important',
    },
    inputLabelRoot: {
      left: '-14px',
    },
  };
  const isFieldInvalid = fieldState.invalid;
  const defaultInputId = id || `${name}-masked-textbox`;

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel
        error={isFieldInvalid}
        htmlFor={defaultInputId}
        sx={styles.inputLabelRoot}
      >
        {label}
        {rules?.required ? ' *' : ''}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputMask mask={mask} value={value} onChange={onChange} maskPlaceholder={mask} disabled={disabled}>
            <Input
              autoComplete={autoComplete ? undefined : 'off'}
              id={defaultInputId}
              required={Boolean(rules?.required)}
              sx={style}
              error={isFieldInvalid}
              type={type}
              disableUnderline={type === 'hidden'}
              endAdornment={endAdornment && showAdornment ? endAdornment : null}
              inputProps={{
                maxLength,
              }}
            />
          </InputMask>
        )}
      />
      <FormHelperText
        error={isFieldInvalid}
        sx={styles.helperTextRoot}
      >
        {isFieldInvalid ? get(['error', 'message'], fieldState) : ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomTextMaskInput;
