import { Alert } from '@mui/material';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import React, { ErrorInfo } from 'react';
import {
  initiate,
  logErrorWithApplicaitonInsights,
} from '../../hooks/useTvcAppInsights';
import MenuBar from '../nav/MenuBar';

// TODO: <Y> consider publishing source maps to insights for during CI pipelines. https://docs.microsoft.com/en-us/azure/azure-monitor/app/source-map-support
type TvcAppInsightsErrorBoundaryProps = {
  children: React.ReactNode;
};

type State = {
  reactPlugin: ReactPlugin;
  hasError: boolean;
  error: Error;
};

class TvcAppInsightsErrorBoundary extends React.Component<
TvcAppInsightsErrorBoundaryProps,
State
> {
  constructor(props: TvcAppInsightsErrorBoundaryProps) {
    super(props);
    const reactPlugin = initiate();
    this.state = {
      hasError: false,
      reactPlugin,
      error: { name: '', message: '' },
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error });

    logErrorWithApplicaitonInsights(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <>
          <MenuBar renderNav={false} />
          <Alert severity="error" variant="filled" sx={{ marginTop: '20px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{error.message}</span>
              {' '}
              <div>
                We apologize for the inconvenience. Our dev team has been
                notified.
              </div>
            </div>
          </Alert>
        </>
      );
    }

    return children;
  }
}

export default TvcAppInsightsErrorBoundary;
