import React from 'react';
import LabelValue, { LabelValueProps } from '../LabelValue';

const ConfirmLabelValue = (props: LabelValueProps) => {
  const {
    containerStyle, label, value, ...rest
  } = props;
  return (
    <LabelValue
      {...rest}
      containerStyle={{ padding: '16px', paddingRight: '0px', ...containerStyle }}
      label={label}
      value={value}
    />
  );
};

export default ConfirmLabelValue;
