import React from 'react';
import {
  Avatar, Box, Link, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';

interface ListContactItemProps {
  avatarAltText?: string;
  avatar?: string;
  fallbackInitials?: string;
  contactName?: string;
  email?: string;
  phone?: string,
  showAvatar?: boolean;
  itemKey?: string;
  sms?: string;
  jobTitle?: string;
}

const ListContactItem = (props: ListContactItemProps) => {
  const defaultText = 'Contact Info';
  const theme = useTheme();
  const styles = {
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    icon: {
      fontSize: '1rem',
      marginRight: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    commonPadding: {
      paddingLeft: theme.spacing(2),
    },
    commonNoPadding: {
      paddingLeft: theme.spacing(0),
    },
    contactBox: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(0.25),
    },
    link: {
      fontSize: '1.2em',
    },
  };

  const {
    avatarAltText = defaultText,
    avatar,
    fallbackInitials = 'CI',
    contactName = defaultText,
    email,
    phone,
    showAvatar = true,
    itemKey,
    sms,
    jobTitle,
  } = props;
  const avatarPadding = showAvatar ? styles.commonPadding : styles.commonNoPadding;
  return (
    <ListItem key={itemKey}>
      {showAvatar && (
        <ListItemIcon>
          <Avatar
            alt={avatarAltText}
            src={avatar}
            sx={styles.large}
          >
            {fallbackInitials}
          </Avatar>
        </ListItemIcon>
      )}
      <ListItemText
        primary={(
          <Box sx={{
            ...avatarPadding,
            color: theme.palette.text.secondary,
            fontSize: '1.1rem',
          }}
          >
            {contactName}
          </Box>
        )}
        secondary={(
          <Box sx={avatarPadding}>

            {jobTitle && (
              <Box sx={styles.contactBox}>
                {jobTitle}
              </Box>
            )}

            {email && (
              <Box sx={styles.contactBox}>
                <EmailIcon sx={styles.icon} />
                <Link href={`mailto:${email}`} sx={styles.link}>{email}</Link>
              </Box>
            )}

            {phone && (
              <Box sx={styles.contactBox}>
                <PhoneIcon sx={styles.icon} />
                <Link href={`tel:${phone}`} sx={styles.link}>{phone}</Link>
              </Box>
            )}

            {sms && (
              <Box sx={styles.contactBox}>
                <SmsIcon sx={styles.icon} />
                <Link href={`sms:${sms}`} sx={styles.link}>{sms}</Link>
              </Box>
            )}
          </Box>
        )}
      />
    </ListItem>
  );
};

export default ListContactItem;
