import { SystemNotificationModel } from '../../../shared/models/application/SystemNotificationModel';
import { AppConfiguration } from '../../../shared/models/configuration/AppConfiguration';
import { initAxiosInstance } from './utils';

export const useApplicationApi = () => {
  const portalClient = initAxiosInstance();

  const getSystemNotifications = async () => {
    const { data } = await portalClient.get<SystemNotificationModel[]>(
      'portal/systemNotifications', { params: { path: 'system-path', siteId: 'Portal' } },
    );
    return data;
  };

  const postAcknowledgeSystemNotifications = async (id: number) => {
    const { data } = await portalClient.post(
      `portal/systemNotifications/acknowledge/${id}`,
    );
    return data;
  };

  const getConfiguration = async () => {
    const { data } = await portalClient.get<AppConfiguration>('/portal/configuration');

    return data;
  };

  return {
    getSystemNotifications,
    postAcknowledgeSystemNotifications,
    getConfiguration,
  };
};
