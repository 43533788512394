import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { SearchIndividualMemberships } from '../../shared/models/matrix/SearchIndividualMemberships';
import ContentPadding from '../../shared/components/ContentPadding';
import DetailsWrapper from '../../shared/components/DetailsWrapper';
import DefineMembershipsSearchCriteria from './DefineMembershipsSearchCriteria';

interface DefineMembershipsSearchCriteriaModalProps {
  onClose: () => void;
  open: boolean;
  onSearchCriteriaDefined: (data: SearchIndividualMemberships) => Promise<void>;
  useStore?: boolean;
}

const DefineMembershipsSearchCriteriaModal = (props: DefineMembershipsSearchCriteriaModalProps) => {
  const {
    open, onClose, onSearchCriteriaDefined, useStore = true,
  } = props;
  const theme = useTheme();

  const handleClose = () => {
    onClose();
  };

  return (
    <DetailsWrapper
      title="Define Non-Fleet Membership Search Criteria"
      onClose={handleClose}
      open={open}
      titlebackgroundcolor={theme.palette.grey[800]}
      maxLargeScreenDialogWidth={1300}
      isAppBarSticky
    >
      <Paper elevation={0} sx={{ paddingBottom: '24px', paddingTop: '24px' }}>
        <ContentPadding>
          <DefineMembershipsSearchCriteria onSearchCriteriaDefined={onSearchCriteriaDefined} postSubmitFunc={handleClose} useStore={useStore} />
        </ContentPadding>
      </Paper>
    </DetailsWrapper>
  );
};

export default DefineMembershipsSearchCriteriaModal;
