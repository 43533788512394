import React from 'react';
import {
  IconButton,
  InputAdornment,
  Tooltip,
  Zoom,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomTextInput, { CustomTextInputProps } from './CustomTextInput';

interface CustomClearableTextFieldProps extends CustomTextInputProps {
  showAdornment?: boolean;
  onAdornmentClick?: () => void;
  name: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const CustomClearableTextField = (props: CustomClearableTextFieldProps) => {
  const {
    showAdornment = true,
    onAdornmentClick,
    type = 'text',
    fullWidth = true,
    disabled = false,
    name,
    onKeyDown,
    ...rest
  } = props;
  return (
    <CustomTextInput
      {...rest}
      onKeyDown={onKeyDown}
      type={type}
      name={name}
      fullWidth={fullWidth}
      disabled={disabled}
      showAdornment={showAdornment}
      endAdornment={(
        <InputAdornment position="end">
          <Tooltip title="Clear" aria-label="clear text">
            <Zoom in={showAdornment}>
              <IconButton
                onClick={onAdornmentClick}
                sx={{ marginRight: '-14px' }}
                disabled={disabled}
                size="large"
              >
                <HighlightOffIcon />
              </IconButton>
            </Zoom>
          </Tooltip>
        </InputAdornment>
      )}
    />
  );
};

export default CustomClearableTextField;
