import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import { useAppDispatch } from '../../app/store';
import { addAlert } from '../../features/application/applicationSlice';
import BusySubmitButton from './BusySubmitButton';

interface ConfirmDialogProps {
  open: boolean;
  content: React.ReactNode;
  title: React.ReactNode;
  action: () => any;
  onActionSuccess: () => void;
  onActionFail?: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
  okButtonText?: string;
  cancelButtonText?: string;
  failMessage?: string;
}

const ConfirmDialog = ({
  onCancel,
  content,
  action,
  open,
  onActionSuccess,
  onActionFail,
  title,
  children,
  okButtonText = 'OK',
  cancelButtonText = 'CANCEL',
  failMessage = 'An error occurred',
}: ConfirmDialogProps) => {
  const [actionPending, setActionPending] = useState(false);

  const dispatch = useAppDispatch();

  const handleConfirmClick = async () => {
    setActionPending(true);
    try {
      await Promise.resolve(action());
      onActionSuccess();
    } catch (err) {
      if (onActionFail) {
        onActionFail();
        return;
      }

      dispatch(
        addAlert({
          message: failMessage,
          severity: 'error',
        }),
      );
    } finally {
      setActionPending(false);
    }
  };

  const handleCancelClick = () => {
    if (actionPending) {
      return;
    }

    onCancel();
  };

  return (
    <Dialog disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick} color="primary">
          {cancelButtonText}
        </Button>
        <BusySubmitButton
          onClick={handleConfirmClick}
          color="primary"
          actionPending={actionPending}
        >
          {okButtonText}
        </BusySubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
