import React from 'react';
import EsFuelMap from '../../../features/fuel/EsFuelMap';

interface HowToFindFuelingDiscountsProps {
  style?: React.CSSProperties;
  buttonText?: string;
}
const HowToFindFuelingDiscounts = (props: HowToFindFuelingDiscountsProps) => {
  const { style, buttonText } = props;

  return (
    <>
      <EsFuelMap canViewMap isNavItem={false} buttonStyle={style} linkText={buttonText} />
    </>
  );
};

export default HowToFindFuelingDiscounts;
