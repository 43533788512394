import {
  CardActions, CardHeader, Fade, Grid,
} from '@mui/material';
import React, { Key } from 'react';
import { theme } from '../../../appTheme';
import baseGridCardStyle from '../../styles/baseGridCardStyle';
import { fadeTimeout } from '../../transitions/timeouts';
import GridCardWrapper from '../GridCardWrapper';

interface SearchGridItemProps {
  itemKey: Key | null | undefined;
  title: React.ReactNode;
  userCanClickItem?: boolean;
  cardClicked?: () => void;
  gridClicked?: () => void;
  userCanViewCardActions?: boolean;
  cardActionChildren?: React.ReactNode;
  cardHeaderSubHeader?: React.ReactNode;
  borderLeftColor?: string;
  minHeight?: number;
  cardStyles?: React.CSSProperties;
  cardActionStyles?: React.CSSProperties;
  cardHeaderAvatar?: React.ReactNode;
  paperHoverElevation?: number;
  paperRestingElevation?: number;
}

const SearchGridItem = (props: SearchGridItemProps) => {
  const {
    itemKey,
    title,
    userCanClickItem,
    cardClicked,
    gridClicked,
    userCanViewCardActions,
    cardActionChildren,
    cardHeaderSubHeader,
    borderLeftColor = theme.palette.common.black,
    minHeight = 125,
    cardStyles,
    cardActionStyles,
    cardHeaderAvatar,
    paperHoverElevation,
    paperRestingElevation,
  } = props;
  const headerStyles = userCanClickItem ? baseGridCardStyle.cardHeader : baseGridCardStyle.cardHeaderNoClick;
  const actionsStyles = gridClicked ? baseGridCardStyle.cardHeader : undefined;
  return (
    <Fade in exit timeout={fadeTimeout}>
      <Grid key={`grid-item-${itemKey}`} item xs={12} sm={6} md={6} lg={3} onClick={() => (gridClicked ? gridClicked() : undefined)}>
        <GridCardWrapper
          cardStyles={{
            borderLeft: `5px solid ${borderLeftColor}`,
            minHeight: `${minHeight}px`,
            ...cardStyles,
          }}
          userCanClickCard={userCanClickItem}
          paperHoverElevation={paperHoverElevation}
          paperRestingElevation={paperRestingElevation}
        >
          <CardHeader
            titleTypographyProps={{ noWrap: true }}
            subheaderTypographyProps={{ noWrap: true }}
            title={title}
            subheader={cardHeaderSubHeader}
            onClick={userCanClickItem && cardClicked ? cardClicked : undefined}
            sx={{
              ...headerStyles,
              '& .MuiCardHeader-title': baseGridCardStyle.cardTitle,
              '& .MuiCardHeader-subheader': baseGridCardStyle.cardSubTitle,
              // '& .MuiCardHeader-content': baseGridCardStyle.forceEllipsis, // As far as I can tell, this is not defined and might be vestigial?
            }}
            avatar={cardHeaderAvatar}
          />
          {userCanViewCardActions && cardActionChildren && (
            <CardActions
              sx={{
                justifyContent: 'space-between',
                ...actionsStyles,
                ...cardActionStyles,
              }}
            >
              {cardActionChildren}
            </CardActions>
          )}
        </GridCardWrapper>
      </Grid>
    </Fade>
  );
};

export default SearchGridItem;
