import React from 'react';
import { Button } from '@mui/material';
import UsingDefault from './UsingDefault';

export interface LimitAdornmentProps {
  disabled?: boolean;
  isDefault: boolean;
  onAdornmentClick: () => void;
}

const LimitAdornment = (props: LimitAdornmentProps) => {
  const { isDefault, onAdornmentClick, disabled = false } = props;
  const styles = {
    common: {
      marginBottom: '6px',
      marginTop: '6px',
      padding: '1px 8px',
      minWidth: '120px',
      fontSize: '10px',
      textAlign: 'center',
    },
  };

  return !isDefault ? (
    <Button
      onClick={onAdornmentClick}
      color="primary"
      variant="outlined"
      size="small"
      sx={styles.common}
      disabled={disabled}
    >
      Use Default
    </Button>
  ) : (
    <UsingDefault />
  );
};

export default LimitAdornment;
