import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@mui/material';
import { get } from 'lodash/fp';
import React from 'react';
import {
  RefCallBack,
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';

export interface CustomTextInputProps {
  name: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  style?: Object;
  id?: string;
  type?: string;
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  showAdornment?: boolean;
  autoComplete?: boolean;
  maxLength?: number;
  ref?: RefCallBack;
  multiline?: boolean;
  maxRows?: number;
  key?: React.Key;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const CustomTextInput = (props: CustomTextInputProps) => {
  const {
    style,
    label,
    id,
    disabled,
    type,
    name,
    rules,
    defaultValue,
    fullWidth = false,
    showAdornment,
    endAdornment,
    autoComplete = true,
    maxLength,
    ref,
    multiline = false,
    maxRows,
    key,
    onKeyDown,
    ...rest
  } = props;
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || '',
  });
  const styles = {
    helperTextRoot: {
      marginLeft: '0px !important',
    },
    inputLabelRoot: {
      left: '-14px',
    },
  };
  const isFieldInvalid = fieldState.invalid;

  const defaultInputId = id || `${name}-textbox`;

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel
        error={isFieldInvalid}
        htmlFor={defaultInputId}
        sx={styles.inputLabelRoot}
        disabled={disabled}
      >
        {label}
        {rules?.required ? ' *' : ''}
      </InputLabel>
      <Input
        key={key}
        multiline={multiline}
        maxRows={maxRows}
        autoComplete={autoComplete ? undefined : 'off'}
        id={defaultInputId}
        {...field}
        {...rest}
        required={Boolean(rules?.required)}
        sx={style}
        disabled={disabled}
        error={isFieldInvalid}
        type={type}
        disableUnderline={type === 'hidden'}
        endAdornment={endAdornment && showAdornment ? endAdornment : null}
        inputProps={{
          maxLength,
        }}
        onKeyDown={onKeyDown}
      />
      <FormHelperText
        error={isFieldInvalid}
        sx={styles.helperTextRoot}
      >
        {isFieldInvalid ? get(['error', 'message'], fieldState) : ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomTextInput;
