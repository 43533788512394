import { useState } from 'react';

interface ApiValidationErrorsProps {
  errorResponse: any;
}

export const useTvcPortalApiValidationErrors = () => {
  const [apiValidationErrors, setApiValidationErrors] = useState<string[]>([]);

  const hasApiValidationErrors = (props: ApiValidationErrorsProps): boolean => {
    const { errorResponse } = props;

    if (!errorResponse.hasOwnProperty('response') || !errorResponse.response) {
      return false;
    }

    const { status, data } = errorResponse.response;

    if (status === 422) {
      const { validationErrors } = data;
      setApiValidationErrors(validationErrors);
      return true;
    }

    return false;
  };

  return {
    hasApiValidationErrors,
    apiValidationErrors,
    setApiValidationErrors,
  };
};
