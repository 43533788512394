import { getOr } from 'lodash/fp';
import { initAxiosInstance } from './utils';
import { PortalApiPermissionFull } from '../../../shared/models/permissions/PortalApiPermissionFull';

export const usePermissionsApi = () => {
  const portalClient = initAxiosInstance();

  const getPermissions = (): Promise<PortalApiPermissionFull[]> => portalClient.get('/permission/all').then(getOr([], 'data'));

  const getFleetPermissions = (): Promise<PortalApiPermissionFull[]> => portalClient.get('/permission/fleet/all').then(getOr([], 'data'));

  return {
    getPermissions,
    getFleetPermissions,
  };
};
