import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
} from '@mui/material';
import { getOr } from 'lodash/fp';
import React from 'react';
import {
  RefCallBack, RegisterOptions, useController, useFormContext,
} from 'react-hook-form';

interface CustomSelectProps<ItemType> {
  label: string;
  style?: SxProps;
  selectStyle?: React.CSSProperties;
  multiple?: boolean;
  name: string;
  dataLoading?: boolean;
  customErrorMessage?: string;
  items: ItemType[];
  defaultValue?: string | number;
  itemKey: (item: ItemType) => string | number;
  itemValue: (item: ItemType) => string | number;
  itemDisplay: (item: ItemType) => React.ReactNode;
  itemDisabled?: (item: ItemType) => boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  renderValue?: (value: SelectProps['value']) => React.ReactNode;
  ref?: RefCallBack;
  key?: React.Key;
}

function CustomSelectInput<ItemType>(props: CustomSelectProps<ItemType>) {
  const {
    name,
    itemValue,
    defaultValue,
    style,
    items,
    label,
    dataLoading,
    itemKey,
    customErrorMessage,
    itemDisplay,
    selectStyle,
    disabled,
    itemDisabled = () => false,
    fullWidth = false,
    rules,
    renderValue,
    ref,
    key,
    ...rest
  } = props;
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const styles = {
    root: {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'center',
    },
    selectMenu: {
      maxWidth: 'calc(100vw - 60px)',
    },
    inputLabelRoot: {
      left: '-14px',
    },
    helperTextRoot: {
      marginLeft: '0px !important',
    },
  };
  const generateLabelId = () => `${name}-label`;
  const generateSelectId = () => `${name}-select`;

  const isFieldInvalid = fieldState.invalid;

  return (
    <FormControl sx={{ ...style, width: '100%' }} error={isFieldInvalid}>
      <InputLabel
        disabled={disabled}
        id={generateLabelId()}
        sx={styles.inputLabelRoot}
      >
        {label}
        {rules?.required ? ' *' : ''}
      </InputLabel>
      <Select
        renderValue={renderValue}
        key={key}
        fullWidth={fullWidth}
        disabled={disabled}
        labelId={generateLabelId()}
        {...rest}
        {...field}
        id={generateSelectId()}
        sx={{
          ...selectStyle,
          '& .MuiSelect-select': {
            ...styles.root,
            ...styles.selectMenu,
          },
        }}
        required={Boolean(rules?.required)}
        ref={ref}
        variant="standard"
      >
        {dataLoading ? (
          <MenuItem>
            <CircularProgress />
          </MenuItem>
        ) : (
          items.map((item) => (
            <MenuItem
              key={itemKey(item)}
              value={itemValue(item)}
              disabled={itemDisabled(item)}
            >
              {itemDisplay(item)}
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText sx={styles.helperTextRoot}>
        {!isFieldInvalid ? ' ' : getOr(' ', ['error', 'message'], fieldState)}
      </FormHelperText>
    </FormControl>
  );
}

export default CustomSelectInput;
