import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from '../../app/store';
import { sumBy } from 'lodash/fp';
import {
  fetchFleetNextAvailFundingInfo,
  retrieveLoadFeesForFleetThunk,
  selectCurrentFleetNextAvailFundingInfo,
  selectFleetLoadFees,
  selectFleets,
  selectIsLoadingFleetLoadFees,
  updateFleetFuelContractInfo,
} from './fleetSlice';
import { PortalApiLoadLimit } from '../../shared/models/fuel/PortalApiLoadLimit';
import { ContractFuelLoadResponse } from '../../shared/models/fuel/ContractFuelLoadResponse';
import { LoadFuelFundsData } from '../../shared/components/forms/LoadFuelFunds';
import FleetPendingLoads from './fuel/FleetPendlingLoads';
import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import { useLoadLimits } from './hooks/useLoadLimits';
import { retrieveFleetPaymentMethods } from '../paymentMethod/paymentMethodSlice';
import { FleetUserPermissions } from '../auth/permissionNames';
import {
  retrieveFleetTransactionHistory, retrievePendingLoadsForFleetThunk, selectPendingLoads, selectPendingLoadsLoading,
} from '../fuel/efsSlice';
import { Fleet } from '../../shared/models/fleet/Fleet';
import { FuelHistoryType } from '../../shared/enums/fuel/FuelHistoryType';
import { useFleetApi } from '../../services/api/hooks/useFleetApi';
import { useCommon } from '../../shared/hooks/useCommon';
import { FuelFees } from '../../shared/models/fuel/FuelFees';
import { LoadFundsModal } from '../../shared/components/fuel/LoadFundsModal';
import ContractBalanceHelp from '../fuel/ContractBalanceHelp';

import { usePaymentMethods } from '../paymentMethod/hooks/usePaymentMethods';

interface LoadContractModalProps {
  onClose: () => void;
  fleet: Fleet;
  open: boolean;
  canViewPendingLoads?: boolean;
}

const LoadFleetContractModal = (props: LoadContractModalProps) => {
  const {
    fleet, open, onClose, canViewPendingLoads = false,
  } = props;

  const { handleError } = useAsyncErrorHandler();
  const { loadAFleetContract } = useFleetApi();
  const { appliedLimits, retrieveFleetAppliedLimits } = useLoadLimits();
  const dispatch = useAppDispatch();
  const { config } = useCommon();
  const { getPaymentMethodIsCheck } = usePaymentMethods();

  const fleets = useSelector(selectFleets);
  const isLoadingFleetLoadFees = useSelector(selectIsLoadingFleetLoadFees);
  const fleetLoadFees = useSelector(selectFleetLoadFees(fleet.id));
  const nextAvailableFundingInfo = useSelector(selectCurrentFleetNextAvailFundingInfo);

  if (!fleets.length) {
    onClose();
  }

  const dispatchFetchFleetNextAvailFundingInfo = async (fid: string) => {
    await dispatch(fetchFleetNextAvailFundingInfo(fid));
  };

  const synchDataAfterLoad = async (data: ContractFuelLoadResponse, loadData: LoadFuelFundsData) => {
    dispatch(
      updateFleetFuelContractInfo({
        fleetId: fleet.id,
        ...data,
      }),
    );

    if (canViewPendingLoads && loadData.processingPeriod.days > 0) {
      dispatch(retrievePendingLoadsForFleetThunk(fleet.id));
    }

    if (fleet.cachePermissions?.permissions[
      FleetUserPermissions.canViewFleetFuelActivity
    ] && loadData.processingPeriod.days === 0) {
      await dispatch(retrieveFleetTransactionHistory(fleet.id,
        undefined,
        undefined,
        FuelHistoryType.All,
        undefined,
        undefined,
        false));
    }

    dispatchFetchFleetNextAvailFundingInfo(fleet.id);

    if (getPaymentMethodIsCheck(loadData.paymentMethodId)) {
      dispatch(retrieveFleetPaymentMethods(fleet.id));
    }
  };

  const loadFleetContractApiCall = (loadData: LoadFuelFundsData): Promise<AxiosResponse<ContractFuelLoadResponse, any>> => {
    if (!fleetLoadFees) {
      throw new Error('Load fees are unavailable');
    }

    return loadAFleetContract({
      fleetId: fleet.id,
      paymentMethodId: loadData.paymentMethodId,
      processingFee: loadData.processingPeriod,
      fundsToAdd: loadData.fundsToAdd,
      loadFeeMinimum: fleetLoadFees.loadFeeMinimum,
      loadFeePercentage: fleetLoadFees.loadFeePercentage,
      feeAdjustmentAmount: loadData.feeAdjustmentAmount,
      feeAdjustmentReason: loadData.feeAdjustmentReason,
      couponUsageId: loadData.couponUsageId,
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(retrieveFleetPaymentMethods(fleet.id));
    }
  }, [open]);

  useEffect(() => {
    const dispatchFleetLoadFees = async (fid: string) => {
      await dispatch(retrieveLoadFeesForFleetThunk(fid));
    };

    try {
      dispatchFleetLoadFees(fleet.id);
      retrieveFleetAppliedLimits(fleet.id);
      dispatchFetchFleetNextAvailFundingInfo(fleet.id);
    } catch (err) {
      handleError(err);
    }
  }, [fleet.id]);

  const pendingLoads = useSelector(selectPendingLoads);
  const isPendingLoadsLoading = useSelector(selectPendingLoadsLoading);

  return (
    <LoadFundsModal<ContractFuelLoadResponse>
      canViewPendingLoads={canViewPendingLoads}
      canViewBalance={fleet?.cachePermissions?.permissions[FleetUserPermissions.canViewFleetFuelContractBalance]}
      balance={fleet.contractBalance}
      balanceAsOf={fleet.contractBalanceAsOf}
      loadFees={fleetLoadFees as FuelFees}
      loadLimits={appliedLimits || ({} as PortalApiLoadLimit)}
      coupon={{ consumerId: fleet.id, isFleet: true }}
      allowCoupon={Boolean(config.REACT_APP_TVC_ALLOW_FLEET_COUPONS)}
      pendingLoadsTabContent={<FleetPendingLoads fleet={fleet} />}
      nextAvailFundingInfo={nextAvailableFundingInfo}
      loadContractFunc={loadFleetContractApiCall}
      postLoadFundsFunc={synchDataAfterLoad}
      dialogProps={{
        title: `${fleet.name} | Load Funds`,
        dataTestId: 'loadFleetContractDialog',
        onClose,
        open,
      }}
      balanceLabel="Contract Balance"
      balanceHelpComponent={<ContractBalanceHelp isFleet />}
      isLoadingLoadFees={isLoadingFleetLoadFees}

      pendingLoadsBalance={sumBy((load) => load.amount, pendingLoads)}
      pendingLoadsLength={pendingLoads.length}
      isLoadingPendingLoads={isPendingLoadsLoading}
    />
  );
};

export default LoadFleetContractModal;
