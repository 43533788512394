import { TransitionProps } from '@mui/material/transitions/transition';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';

interface ContentPaddingProps extends TransitionProps {
  children?: React.ReactNode;
  includeTopBottomPadding?: boolean;
  containerStyle?: React.CSSProperties;
}

const ContentPadding = (props: ContentPaddingProps) => {
  const {
    children, containerStyle, includeTopBottomPadding = false, style, ...rest
  } = props;
  const theme = useTheme();
  const styles = {
    commonPadding: {
      paddingLeft: '18px',
      paddingRight: '18px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '2px',
        paddingRight: '2px',
      },
    },
    topBottomPadding: {
      paddingTop: '25px',
      paddingBottom: '25px',
    },
  };
  const yPadding = includeTopBottomPadding ? styles.topBottomPadding : {};
  return (
    <Box
      sx={{
        ...styles.commonPadding,
        ...containerStyle,
        ...yPadding,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ContentPadding;
