import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import EmailAddressForm from './EmailAddressForm';
import {
  selectCodeSent, selectMigrationBusy, selectMigrationResponse, selectPortalEmail, sendVerificationCodeEmail,
} from './migrationSlice';
import MigrationContentHeader from './MigrationContentHeader';
import { useAppDispatch } from '../../app/store';
import VerificationCode from './VerificationCode';

import IconText from '../../shared/components/icon-components/IconText';

const emailAvailableText = 'The email address we have on file for your membership is available for use in the portal';

const MigrationErrorCondition = () => {
  const theme = useTheme();
  return (
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <IconText
        icon={<ErrorIcon htmlColor={theme.palette.error.main} />}
        text={`${emailAvailableText}. However, there is a problem with the email we have on file. Contact 1-800-288-2889 for help.`}
        textStyle={{ color: theme.palette.error.main }}
      />
    </Grid>
  );
};
const MatrixLoginSuccess = () => {
  const { handleError } = useAsyncErrorHandler();
  const dispatch = useAppDispatch();

  const loginResponse = useSelector(selectMigrationResponse);
  const codeSent = useSelector(selectCodeSent);
  const isBusy = useSelector(selectMigrationBusy);
  const portalEmail = useSelector(selectPortalEmail);

  const sendVerificationEmail = async (email: string, isResend: boolean = false) => {
    try {
      await dispatch(sendVerificationCodeEmail(email, isResend));
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <>

            {(loginResponse?.emailIsValid && loginResponse?.isEmailUnique && !loginResponse?.sentCode && loginResponse?.email !== null)

              ? (
                <Grid container spacing={2}>
                  <MigrationErrorCondition />
                </Grid>
              )

              : (
                <>
                  {loginResponse?.isEmailUnique && loginResponse?.sentCode
                    && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <MigrationContentHeader headerText="Email Address Available" isSuccess />
                        </Grid>
                        <>
                          {loginResponse.email ? (
                            <>
                              <Grid item xs={12}>
                                {emailAvailableText}
                                . A verification code was sent to
                                {' '}
                                {loginResponse?.email}
                                .
                              </Grid>
                              {/* Prompt to verify code sent to email address on file  */}
                              <VerificationCode resendCode={sendVerificationEmail} email={loginResponse.email} />
                            </>
                          )
                            : (
                              <MigrationErrorCondition />
                            )}
                        </>
                      </Grid>
                    )}

                  {/* Email on file is not unique in Azure B2C or their email is invalid - They must provide a new email  */}
                  {(!loginResponse?.isEmailUnique || (!loginResponse?.emailIsValid)) && (
                    <Grid container spacing={2}>

                      {!codeSent && (
                        <>
                          <Grid item xs={12}>
                            <MigrationContentHeader headerText={(!loginResponse?.emailIsValid) ? 'Invalid Email on File' : 'Email Address Unavailable'} isError />

                            {!loginResponse?.isEmailUnique && (
                              <p>
                                Unfortunately, the email address we have on file for your membership is already being used in the portal.
                              </p>
                            )}

                            {!loginResponse?.emailIsValid && (
                              <p>
                                Unfortunately, the email address we have on file for your membership is invalid.
                              </p>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            Please provide a valid email address to which you have access. This email address will be used to login to our new portal.
                          </Grid>
                          <Grid item xs={12}>
                            <EmailAddressForm
                              isBusy={isBusy}
                              onFormSubmit={async (formData) => {
                                const { email } = formData;
                                await sendVerificationEmail(email);
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      {/* Prompt to verify code sent to new email address  */}
                      {codeSent && portalEmail && (
                        <VerificationCode resendCode={sendVerificationEmail} email={portalEmail} />
                      )}
                    </Grid>
                  )}

                </>
              )}

          </>
        </Grid>
      </Grid>
    </>
  );
};

export default MatrixLoginSuccess;
