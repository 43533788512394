import React from 'react';
import TvcSkeleton, { SkeletonEntityTypes } from './TvcSkeleton';

interface SkeletonOrContentProps {
  children: JSX.Element;
  skeletonEntityType?: SkeletonEntityTypes;
  showSkeleton: boolean;
}

const SkeletonOrContent = (props: SkeletonOrContentProps) => {
  const { children, skeletonEntityType = 'input', showSkeleton } = props;

  if (showSkeleton) {
    return <TvcSkeleton entityType={skeletonEntityType} />;
  }

  return children;
};

export default SkeletonOrContent;
