import {
  Box,
  Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { TvcAccordionProps } from '../models/other/TvcAccordionProps';
import ApiValidationErrors from './ApiValidationErrors';
import DetailsAccordion from './DetailsAccordion';

interface ProfilePermissionsProps extends TvcAccordionProps {
  apiValidationErrors: string[];
  permissionsChildren?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  lockExpanded?: boolean;
  hideExpandIcon?: boolean;
}

const ProfilePermissions = (props: ProfilePermissionsProps) => {
  const theme = useTheme<Theme>();
  const {
    summaryChildrenIcon, detailsChildrenOverride, detailsStyle = {
      backgroundColor: theme.palette.detailWrapperBgColor.main,
    }, apiValidationErrors, permissionsChildren, containerStyle,
    lockExpanded = false,
    hideExpandIcon = false,
  } = props;

  return (
    <Box sx={{ ...containerStyle }}>
      <DetailsAccordion
        hideExpandIcon={hideExpandIcon}
        lockExpanded={lockExpanded}
        summaryChildren={(
          <Box display="flex" alignItems="Center">
            {summaryChildrenIcon}
            <Box sx={{ marginLeft: '8px' }}>Permissions</Box>
          </Box>
        )}
        detailsChildren={(
          <>
            {detailsChildrenOverride || (
              <>
                {permissionsChildren && (
                  <Box display="flex" flex={1} flexDirection="column">
                    <Box
                      display="flex"
                      sx={{
                        padding: '8px',
                        marginTop: '20px',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ApiValidationErrors
                        apiValidationErrors={apiValidationErrors}
                      />

                      {permissionsChildren}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )}
        detailsStyle={detailsStyle}
      />
    </Box>
  );
};

export default ProfilePermissions;
