import React from 'react';
import { JoinOperator } from '../enums/other/JoinOperator';
import CustomSelectInput from './formFields/CustomSelectInput';

export type AppAlertSeverity = 'error' | 'info' | 'warning' | 'success';

interface JoinOperatorSelectProps {
  disabled?: boolean;
  name: string;
  label?: string;
}

const JoinOperatorSelect = (props: JoinOperatorSelectProps) => {
  const {
    disabled, name, label,
  } = props;

  return (
    <CustomSelectInput<string>
      name={name}
      disabled={disabled}
      items={Object.keys(JoinOperator).filter((v) => Number.isNaN(Number(v)))}
      itemValue={(v) => JoinOperator[v as keyof typeof JoinOperator]}
      itemDisplay={(v: string) => v}
      itemKey={(v) => `${name}-${v}`}
      label={label || ''}
    />
  );
};

export default JoinOperatorSelect;
