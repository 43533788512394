import { Alert } from '@mui/material';
import React from 'react';

interface ApiValidationErrorsProps {
  apiValidationErrors: string[];
  style?: React.CSSProperties;
  severity?: 'success' | 'info' | 'warning' | 'error';
  variant?: 'standard' | 'filled' | 'outlined'
}

const ApiValidationErrors = (props: ApiValidationErrorsProps) => {
  const {
    apiValidationErrors, style, severity = 'error', variant = 'standard',
  } = props;
  const styles = {
    apiErrorRoot: {
      marginBottom: '10px',
    },
    apiErrorIcon: {
      alignItems: 'center',
    },
  };

  return (
    <div style={style}>
      {apiValidationErrors.map((apiValidationError: string, index) => (
        <Alert
          key={`validation-error-${index}`}
          variant={variant}
          severity={severity}
          sx={{
            ...styles.apiErrorRoot,
            '& .MuiAlert-icon': {
              ...styles.apiErrorIcon,
            },
          }}
        >
          {apiValidationError}
        </Alert>
      ))}
    </div>
  );
};

export default ApiValidationErrors;
