import { InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import debounceAsync from 'awesome-debounce-promise';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormContext } from 'react-hook-form';
import { isValidEmail } from '../../utilities/formValidators';
import { useUnAuthenticatedPortalApi } from '../../../services/api/hooks/useUnAuthenticatedPortalApi';
import { useAsyncErrorHandler } from '../../hooks/useTvcAppInsights';
import CustomTextInput from './CustomTextInput';
import { useUserApi } from '../../../services/api/hooks/useUserApi';

interface EmailTextInputProps {
  label?: string;
  requiredText?: string;
  debounceInterval?: number;
  authenticatedCheck?: boolean;
  fieldName?: string;
  isCheckingEmailCallback?: (value: boolean) => void;
  disabled?: boolean;
  isBusy?: boolean;
  enforceValidation?: boolean;
}
const EmailTextInput = (props: EmailTextInputProps) => {
  const {
    label = 'Email Address', requiredText = `${label} required`, debounceInterval = 225, authenticatedCheck = true, fieldName = 'email', isCheckingEmailCallback, disabled = false, isBusy = false, enforceValidation = true,
  } = props;
  const styles = {
    increaseSpeed: {
      animationDuration: '550ms',
    },
  };
  const { checkIfEmailAlreadyExistsUnauth } = useUnAuthenticatedPortalApi();
  const { getCheckIfEmailAlreadyExists } = useUserApi();
  const { handleError } = useAsyncErrorHandler();
  const [isCheckingEmail, setIsCheckingEmail] = useState<boolean>(false);

  const { trigger } = useFormContext();

  useEffect(() => {
    if (isCheckingEmailCallback) {
      isCheckingEmailCallback(isCheckingEmail);
    }
  }, [isCheckingEmail]);

  useEffect(() => {
    trigger(fieldName);
  }, []);

  return (
    <CustomTextInput
      fullWidth
      label={label}
      name={fieldName}
      disabled={disabled}
      rules={{
        required: enforceValidation ? requiredText : false,
        validate: enforceValidation ? {
          format: isValidEmail(),
          emailAvailable: debounceAsync(async (emailFormValue) => {
            if (isBusy || isCheckingEmail) {
              return true;
            }
            try {
              setIsCheckingEmail(true);
              const emailExists = authenticatedCheck ? await getCheckIfEmailAlreadyExists(emailFormValue) : await checkIfEmailAlreadyExistsUnauth(emailFormValue);
              return emailExists ? `${emailFormValue} is already in use` : true;
            } catch (err: any) {
              handleError(err);
            } finally {
              setIsCheckingEmail(false);
            }
            return false;
          }, debounceInterval, { onlyResolvesLast: false }),

        } : undefined,
      }}
      showAdornment={isCheckingEmail}
      endAdornment={(
        <InputAdornment position="end">
          <CircularProgress size={20} value={1} sx={styles.increaseSpeed} />
        </InputAdornment>
      )}
    />
  );
};

export default EmailTextInput;
