import { currencyFormat, formatNumber } from './currencyFormat';
import { formatTimezone } from './dateHelpers';

const StringIsNumber = (value: any) => Number.isNaN(Number(value)) === false;

export const stringIsPositiveInteger = (stringValue: any) => stringValue >>> 0 === parseFloat(stringValue); // eslint-disable-line no-bitwise

export const enumToArray = (enumme: any) => Object.keys(enumme)
  .filter(StringIsNumber)
  .map((key) => enumme[key]);

export const generateRandomString = (length = 8, charset = 'abcdefghijklmnopqrstuvwxyz0123456789') => {
  const result = [];

  for (let i = 0; i < length; i++) {
    result.push(charset.charAt(Math.floor(Math.random() * charset.length)));
  }

  return result.join('');
};

export const syncFileRead = (fileBlob: File | Blob) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => {
    resolve(reader.result as string);
  };

  reader.readAsDataURL(fileBlob);
  reader.onerror = reject;
});

export const getFilteredFileNameString = (isFiltered: boolean) => (isFiltered ? '-filtered' : '');
// NOTE: this was the old syncFileRead... keeping it here for reference if needed
// const syncFileRead = (fileBlob: Blob) => new Promise<string>((resolve) => {
//   const reader = new FileReader();
//   reader.readAsDataURL(fileBlob);
//   reader.onloadend = () => {
//     resolve(reader.result?.toString() || '');
//   };
// });

export const getValueOrReplacementValue = <T>(value: T, replacementValue: string = '-') => value || replacementValue;

export const getCurrencyFormattedValueOrReplacementValue = (value: number | undefined, replacementValue: string = '-') => (value ? currencyFormat(value, 2) : replacementValue);

export const getFormattedNumberOrReplacementValue = (statValue: number | undefined, replacementValue: string = '-', fractionDigits: number = 1) => (statValue ? formatNumber(statValue, fractionDigits) : replacementValue);

export const getFormattedDateOrReplacementValue = (value: Date | undefined, replacementValue: string = '-') => (value ? formatTimezone(value) : replacementValue);

export const getSuffixedValueOrReplacementValue = (value: number | undefined, suffix: string, replacementValue: string = '-') => (value ? `${formatNumber(value, 2)} ${suffix}` : replacementValue);
