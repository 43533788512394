import { TextField, Autocomplete } from '@mui/material';

import { get } from 'lodash/fp';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface CustomAutocompleteProps<ItemType> {
  label: string;
  required?: boolean;
  style?: Object;
  name: string;
  customErrorMessage?: string;
  items: ItemType[];
  defaultValue?: ItemType;
  itemDisplay: (item: ItemType) => string;
  itemDisabled?: (item: ItemType) => boolean;
  itemKey?: (item: ItemType) => string | number;
  itemValue: (item: ItemType) => ItemType | string | number | undefined;
  initialValue?: ItemType;
  callFieldOnChange?: boolean;
  fullWidth?: boolean;
}

function CustomAutocompleteInput<ItemType>(
  props: CustomAutocompleteProps<ItemType>,
) {
  const {
    label,
    required,
    style,
    name,
    customErrorMessage,
    items,
    defaultValue,
    itemDisplay,
    itemValue,
    itemDisabled,
    initialValue,
    callFieldOnChange = false,
    fullWidth,
  } = props;

  const { setValue, trigger } = useFormContext();
  const { field, fieldState } = useController({
    name,
    defaultValue,
    rules: {
      required: required && (customErrorMessage || `${label} required`),
    },
  });

  const generateAutocompleteId = () => `${name}-label`;

  const labelText = `${label}${required ? ' *' : ''}`;
  return (
    <Autocomplete<ItemType>
      {...field}
      id={generateAutocompleteId()}
      onChange={(_, val) => {
        setValue(name, val ? itemValue(val) : val);
        if (callFieldOnChange) {
          field.onChange(val);
        }

        trigger(name);
      }}
      sx={style}
      options={items}
      getOptionLabel={itemDisplay}
      getOptionDisabled={itemDisabled}
      defaultValue={initialValue}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          error={fieldState.invalid}
          helperText={get(['error', 'message'], fieldState)}
          label={labelText}
        />
      )}
    />
  );
}

export default CustomAutocompleteInput;
