import {
  Grid, Typography, Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

import React from 'react';

export interface Skeletons {
  paymentMethodCards: JSX.Element;
}

export type SkeletonEntityTypes = 'default' | 'payment methods' | 'modal collection' | 'upcoming dates' | 'user permissions' | 'input' | 'entity collection' | 'balance' | 'pending loads' | 'scheduled loads' | 'scheduled loads admin' | 'timeline' | 'accordion-icon' | 'accordion-default' | 'accordion-collapsed' | 'dashboard-card';

export interface SkeletonsProps {
  entityType: SkeletonEntityTypes;
}

const TvcSkeleton = ({ entityType = 'default' }: SkeletonsProps) => {
  const theme = useTheme();
  const styles = {
    paymentMethodCard: {
      padding: '16px',
      height: '72px',
      width: '100%',
      borderRadius: `${theme.shape.borderRadius}px`,
    },
    modalCollection: {
      height: '48px',
      width: '100%',
      marginBottom: '1px',
    },
    upcomingDatesCollection: {
      height: '92.0312px',
      width: '100%',
      marginBottom: '1px',
    },
    userPermisson: {
      height: '50px',
      marginBottom: '2px',
    },
    entityCollection: {
      height: '187px',
      padding: '8px',
      borderRadius: '4px',
    },
    text: {
      height: '46.6px',
    },
    balanceLabel: {
      height: '20px',
      width: '185px',
      marginBottom: '8px',
    },
    balanceValue: {
      height: '51px',
      width: '185px',
    },
    pendingLoads: {
      height: '109.778px',
    },
    scheduledLoads: {
      height: '72.0156px',
      marginBottom: '2px',
    },
    default: {
      height: '79px',
      marginBottom: '4px',
      marginTop: '4px',
      width: '100%',
    },
    accordionDefault: {
      height: '66px',
      padding: '8px',
      width: '100%',
    },
    accordionCollapsed: {
      height: '50px',
      padding: '8px',
      width: '100%',
    },
  };

  if (entityType === 'default') {
    return (
      <>
        <Skeleton
          variant="rectangular"
          sx={styles.default}
        />
      </>
    );
  }

  if (entityType === 'accordion-default') {
    return (
      <>
        <Skeleton
          variant="rectangular"
          sx={styles.accordionDefault}
        />
      </>
    );
  }

  if (entityType === 'accordion-collapsed') {
    return (
      <>
        <Skeleton
          variant="rectangular"
          sx={styles.accordionCollapsed}
        />
      </>
    );
  }

  if (entityType === 'payment methods') {
    return (
      <>
        <Skeleton
          variant="rectangular"
          sx={{ ...styles.paymentMethodCard, marginBottom: '4px' }}
        />
        <Skeleton variant="rectangular" sx={styles.paymentMethodCard} />
      </>
    );
  }

  if (entityType === 'modal collection') {
    return (
      <div style={{ marginTop: '94px' }}>
        <Skeleton variant="rectangular" sx={styles.modalCollection} />
        <Skeleton variant="rectangular" sx={styles.modalCollection} />
        <Skeleton variant="rectangular" sx={styles.modalCollection} />
        <Skeleton variant="rectangular" sx={styles.modalCollection} />
        <Skeleton variant="rectangular" sx={styles.modalCollection} />
      </div>
    );
  }

  if (entityType === 'upcoming dates') {
    return (
      <div style={{ marginTop: '12px' }}>
        <Skeleton variant="rectangular" sx={styles.upcomingDatesCollection} />
        <Skeleton variant="rectangular" sx={styles.upcomingDatesCollection} />
        <Skeleton variant="rectangular" sx={styles.upcomingDatesCollection} />
      </div>
    );
  }
  if (entityType === 'input') {
    return <Skeleton variant="text" sx={styles.text} />;
  }

  if (entityType === 'user permissions') {
    return (
      <>
        <Skeleton variant="rectangular" sx={styles.userPermisson} />
        <Skeleton variant="rectangular" sx={styles.userPermisson} />
      </>
    );
  }

  if (entityType === 'entity collection') {
    return (
      <Grid container xs={12} spacing={2} sx={{ margin: '0px', marginTop: '165px' }}>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" sx={styles.entityCollection} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" sx={styles.entityCollection} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" sx={styles.entityCollection} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" sx={styles.entityCollection} />
        </Grid>
      </Grid>
    );
  }

  if (entityType === 'balance') {
    return (
      <div>
        <Skeleton variant="rectangular" sx={styles.balanceLabel} />
        <Skeleton variant="rectangular" sx={styles.balanceValue} />
      </div>
    );
  }

  if (entityType === 'pending loads') {
    return (
      <div>
        <Skeleton variant="rectangular" sx={styles.pendingLoads} />
        <Skeleton variant="rectangular" sx={styles.pendingLoads} />
      </div>
    );
  }

  if (entityType === 'scheduled loads') {
    return (
      <div>
        <Skeleton variant="rectangular" sx={styles.scheduledLoads} />
        <Skeleton variant="rectangular" sx={styles.scheduledLoads} />
      </div>
    );
  }

  if (entityType === 'scheduled loads admin') {
    return (
      <div style={{ marginTop: '1em' }}>
        <Skeleton variant="rectangular" sx={styles.scheduledLoads} />
        <Skeleton variant="rectangular" sx={styles.scheduledLoads} />
      </div>
    );
  }

  if (entityType === 'timeline') {
    return (
      <Skeleton>
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography color="textSecondary">---</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography>---</Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Skeleton>
    );
  }

  if (entityType === 'accordion-icon') {
    return <Skeleton variant="circular" width={24} height={24} />;
  }

  if (entityType === 'dashboard-card') {
    return <Skeleton variant="rectangular" width="100%" height={356.375} />;
  }

  return <></>;
};

export default TvcSkeleton;
