import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentPadding from './ContentPadding';
import ConfirmDialog from './ConfirmDialog';

interface ConfirmCloseProps {
  showConfirmCloseModal: boolean;
  forceConfirmModal?: boolean;
  onConfirmCancel?: () => void;
  confirmTitle?: string;
  confirmContent?: string;
}
interface DetailsWrapperProps {
  titlebackgroundcolor?: string;
  title?: React.ReactNode;
  open?: boolean;
  preventKeyboardNavigation?: boolean;
  onClose?: () => void;
  hideHoritizontalScrollbar?: boolean;
  children?: React.ReactNode;
  maxLargeScreenDialogWidth?: number;
  maxDialogContentWidth?: number;
  isAppBarSticky?: boolean;
  onRefAcquired?: (ref: HTMLElement | undefined) => void;
  disableClose?: boolean;
  confirmCloseProps?: ConfirmCloseProps;
  contentPaddingContainerStyle?: React.CSSProperties;
  includeContentTopBottomPadding?: boolean;
  paperStyle?: React.CSSProperties;
  closeButtonTestID?: string;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props}><div>{props.children}</div></Slide>,
);

const DetailsWrapper = (props: DetailsWrapperProps) => {
  const {
    maxDialogContentWidth,
    maxLargeScreenDialogWidth,
    hideHoritizontalScrollbar = false,
    preventKeyboardNavigation = false,
    isAppBarSticky = false,
    onRefAcquired,
    disableClose,
    confirmCloseProps,
    titlebackgroundcolor,
    children,
    title,
    onClose,
    open,
    contentPaddingContainerStyle = { width: '100%' },
    includeContentTopBottomPadding = true,
    paperStyle,
    closeButtonTestID = 'close-dialog-button',
    ...additionalProps
  } = props;

  const theme = useTheme();
  const styles = {
    title: {
      marginLeft: '2px',
      display: 'inline-block',
    },
    // appBar: {
    //   position: "relative",
    // },
    detailContentContainer: {
      display: 'flex',
      flex: '1',
      alignItems: 'start',
      backgroundColor: theme.palette.detailWrapperBgColor ? theme.palette.detailWrapperBgColor.main : 'whitesmoke',
      [theme.breakpoints.only('xs')]: {
        padding: '8px',
      },
    },
    baseContainer: {
      padding: '2em',
      flex: '1',
      [theme.breakpoints.down('sm')]: {
        padding: '1em',
      },
    },
    dialogRoot: {
      [theme.breakpoints.up('lg')]: {
        width: '85%',
        margin: 'auto',
        maxWidth: maxLargeScreenDialogWidth ?? '1860px',
      },
      '& div[role="presentation"]': {
        height: '100%',
      },
    },
    dialogRootContent: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      maxWidth: maxDialogContentWidth ? `${maxDialogContentWidth}px` : '1800px',
      [theme.breakpoints.only('xs')]: {
        '& .MuiGrid-container': {
          maxWidth: 'unset !important',
        },
        '& form': {
          padding: '8px !important',
        },
      },
    },
  };
  const history = useHistory();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [localOpen, setLocalOpen] = useState(true);

  const dialogRef: React.MutableRefObject<HTMLElement | undefined> = useRef();

  useEffect(() => {
    if (confirmCloseProps?.forceConfirmModal) {
      setConfirmOpen(true);
    }
  }, [confirmCloseProps?.forceConfirmModal]);

  useEffect(() => {
    if (onRefAcquired) {
      onRefAcquired(dialogRef.current);
    }
  }, [dialogRef.current]);

  const goBackwards = () => {
    setLocalOpen(false);
    setTimeout(() => history.goBack(), 10);
  };

  const closeHandler = onClose ?? goBackwards;

  const handleClose = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (preventKeyboardNavigation) {
      return;
    }
    if (reason !== 'backdropClick') {
      if (confirmCloseProps?.showConfirmCloseModal) {
        setConfirmOpen(true);
      } else {
        closeHandler();
      }
    }
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown={preventKeyboardNavigation}
        sx={{
          ...styles.dialogRoot,
          '& .MuiDialog-paper': {
            overflowX: hideHoritizontalScrollbar ? 'hidden' : 'initial',
          },
        }}
        {...additionalProps}
        fullScreen
        open={open ?? localOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          ref={(node: HTMLElement) => {
            dialogRef.current = node;
          }}
          style={{
            backgroundColor: titlebackgroundcolor ?? theme.palette.primary.main,
          }}
          position={isAppBarSticky ? 'sticky' : 'relative'}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              disabled={disableClose}
              size="large"
              data-testid={closeButtonTestID}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={styles.title}
              noWrap
              style={{ width: '100%' }}
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper square sx={{ ...styles.detailContentContainer, ...paperStyle }}>
          <ContentPadding
            style={{ display: 'flex', flex: '1' }}
            includeTopBottomPadding={includeContentTopBottomPadding}
            containerStyle={contentPaddingContainerStyle}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                // overflowX: "hidden",
              }}
            >
              <Box sx={styles.dialogRootContent}>
                {children}
              </Box>
            </Box>
          </ContentPadding>
        </Paper>
      </Dialog>

      <ConfirmDialog
        open={confirmOpen}
        action={() => {
          setConfirmOpen(false);
          closeHandler();
        }}
        content={confirmCloseProps?.confirmContent}
        onActionSuccess={() => {
          setConfirmOpen(false);
        }}
        onCancel={() => {
          setConfirmOpen(false);
          if (confirmCloseProps?.onConfirmCancel) {
            confirmCloseProps.onConfirmCancel();
          }
        }}
        title={confirmCloseProps?.confirmTitle}
      />
    </>
  );
};

export default DetailsWrapper;
