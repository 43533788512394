import {
  Box, Fade, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  Alert,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import EntityCollectionHeader from '../../../shared/components/EntityCollectionHeader';
import EntityCollectionPage from '../../../shared/components/EntityCollectionPage';
import { useSearchStyles } from '../../../shared/styles/searchStyles';
import { selectIndividualSearchCriteria } from '../../application/applicationSlice';
import { useSearchText } from '../../../shared/hooks/useSearchText';
import ClearableTextField from '../../../shared/components/ClearableTextField';
import FilterSearchWrapper from '../../../shared/components/FilterSearchWrapper';
import { useFilterStyles } from '../../user/search/useFilterStyles';
import {
  addLoginToIndividualPortalUser,
  retrieveIndividualMembershipUsers, selectIndividualUsers, selectIndividualUsersSearchCount, selectIsUpdatingIndividualUser,
} from '../../user/userSlice';
import PortalUserGridItem from './PortalUserGridItem';
import { useEntitySelectedModals } from '../../../shared/hooks/useEntitySelectedModals';
import { useAppDispatch } from '../../../app/store';
import { useAsyncErrorHandler } from '../../../shared/hooks/useTvcAppInsights';
import DefineMembershipsSearchCriteriaModal from '../DefineMembershipsSearchCriteriaModal';
import LoginStatusFilter from '../../user/search/LoginStatusFilter';
import MembershipStatusFilter from '../../user/search/MembershipStatusFilter';
import { useUserFiltering } from '../../user/hooks/useUserFiltering';
// import FuelCardStatusFilter from '../../user/search/FuelCardStatusFilter';
// import UserLegalStatusFilter from '../../user/search/UserLegalStatusFilter';
import InitialResultSetButton from '../../../shared/components/InitialResultSetButton';
import MatrixMembershipGridItem from './MatrixMembershipGridItem';
import AddIndividualPortalLoginModal from '../AddIndividualPortalLoginModal';
import { useTvcPortalApiValidationErrors } from '../../../shared/hooks/useTvcPortalApiValidationErrors';
import { fadeTimeout } from '../../../shared/transitions/timeouts';
import { useBreakPoints } from '../../../shared/hooks/useBreakPoints';
import IndividualMembershipInitialResultSetHelp from './IndividualMembershipInitialResultSetHelp';
import FilterPortalUserStatusHelp from './FilterPortalUserStatusHelp';
// import { useProfile } from '../../auth/hooks/useProfile';
import { SearchIndividualMemberships } from '../../../shared/models/matrix/SearchIndividualMemberships';
import { PortalApiMembershipSearchUser } from '../../../shared/models/matrix/PortalApiMembershipSearchUser';
import { MemberDisplayProperties } from '../../../shared/models/matrix/MemberDisplayProperties';
import HelmetTitle from '../../../shared/components/HelmetTitle';

interface PortalUserStatusOptionTextProps {
  text: string;
}
const PortalUserStatusOptionText = (props: PortalUserStatusOptionTextProps) => {
  const { text } = props;

  return (
    <span>
      Members
      {' '}
      <span style={{ fontWeight: 'bold' }}>{text}</span>
      {' '}
      a portal user
    </span>
  );
};

const IndividualMembershipSearch = () => {
  // const { userProfile } = useProfile();

  const [filterOptionsAreVisible, setFilterOptionsAreVisible] = useState(false);
  const [addPortalLoginModalOpen, setAddPortalLoginModalOpen] = useState(false);
  const [resultsHavePortalUsers, setResultsHavePortalUsers] = useState(true);
  const [error, setError] = useState<string>('');
  const [portalUserStatus, setPortalUserStatus] = useState<string>('both');
  const [selectedMember, setSelectedMember] = useState<MemberDisplayProperties | undefined>(undefined);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [defineInititalResultSetOpen, setDefineInititalResultSetOpen] = useState(false);
  const enablePortalUserFilters = portalUserStatus !== 'user';

  const commonSearchStyles = useSearchStyles();
  const { styles } = useFilterStyles({ filterOptionsAreVisible });

  const { handleError } = useAsyncErrorHandler();
  const dispatch = useAppDispatch();
  const { searchText, setSearchText, searchTextHasValue } = useSearchText();
  const { isXsDown } = useBreakPoints();
  const {
    selectedUserLoginTypes,
    selectedUserMembershipTypes,
    selectedUserFuelCardTypes,
    selectedUserLegalStatuses,
    handleUserLoginTypeSelected,
    handleUserMembershipTypeSelected,
    // handleUserFuelCardTypeSelected,
    // handleUserUserLegalCaseStatusSelected,
    clearSearchFilters,
    // fuelCardStatusFilter,
    loginStatusFilter,
    membershipStatusFilter,
    // userLegalStatusFilter,
    filterOptionsAreSelected,
  } = useUserFiltering();
  const {
    setSelectedEntity,
    setEntityDetailsModalOpen,
  } = useEntitySelectedModals<PortalApiUser | undefined>();
  const {
    apiValidationErrors,
    hasApiValidationErrors,
    setApiValidationErrors,
  } = useTvcPortalApiValidationErrors();

  const handleClose = () => {
    setApiValidationErrors([]);
    setAddPortalLoginModalOpen(false);
    setTimeout(() => {
      setSelectedMember(undefined);
    }, 200);
  };

  const searchCriteria = useSelector(selectIndividualSearchCriteria);
  const individualUsersReturn = useSelector(selectIndividualUsers()) || { count: 0, members: [] };
  const searchCount = useSelector(selectIndividualUsersSearchCount);
  const isUpdatingUser = useSelector(selectIsUpdatingIndividualUser);

  const valuesIncludes = (propertyValues: (string | undefined)[], filterString: string) => propertyValues
    .filter((v) => v !== undefined && v !== null && (v !== undefined && v.trim() !== ''))
    .map((v) => v?.toLowerCase())
    .join(' ')
    .includes(filterString.trim().toLowerCase());

  const individualUsers = individualUsersReturn
    .filter((msu) => {
      switch (portalUserStatus) {
        case 'no-user':
          return msu.user === undefined;
        case 'user':
          return msu.user !== undefined;
        default:
          return true;
      }
    })
    .filter((membershipSearchUser) => {
      const { member, user } = membershipSearchUser;
      return valuesIncludes([
        member?.firstName,
        member?.lastName,
        member?.id.toString(),
        member?.email,
        user?.firstName,
        user?.lastName,
        user?.email,
        user?.memberId?.toString(),
      ], searchText);
    })
    .filter((msu) => (msu.user ? loginStatusFilter(msu.user) : true))
    .filter((msu) => (msu.user ? membershipStatusFilter(msu.user) : true));
  // .filter((msu) => (msu.user ? fuelCardStatusFilter(msu.user) : true))
  // .filter((msu) => (msu.user ? userLegalStatusFilter(msu.user) : true));

  const handlePortalUserStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPortalUserStatus((event.target as HTMLInputElement).value);
  };

  const handleMemberWithPortalUserAccountClicked = (i: PortalApiUser) => {
    setSelectedEntity(i);
    setEntityDetailsModalOpen(true);
  };

  const handleAddPortalLoginClicked = (member: MemberDisplayProperties) => {
    setAddPortalLoginModalOpen(true);
    setSelectedMember(member);
  };

  useEffect(() => {
    if (searchCount >= 500) {
      setRecordCount(500);
    } else {
      setRecordCount(searchCount);
    }
  }, [searchCount]);

  useEffect(() => {
    setResultsHavePortalUsers(individualUsersReturn.some((iur) => iur.user));
  }, [individualUsersReturn]);

  useEffect(() => {
    if (!resultsHavePortalUsers) {
      setPortalUserStatus('no-user');
    } else {
      setPortalUserStatus('both');
    }
  }, [resultsHavePortalUsers]);

  // Not using list view switch logic for this screen... the filter logic is causing too much browser lag when toggling
  const toggleJsxItems = (individualsList: PortalApiMembershipSearchUser[]) => (individualsList.map((individual) => (
    individual.user
      ? (
        <PortalUserGridItem
          key={`individual-user-${individual.member.id}`}
          item={individual.user}
          member={individual.member}
          onItemClickedPassItem={handleMemberWithPortalUserAccountClicked}
        />
      )
      : (
        <MatrixMembershipGridItem
          key={`individual-user-${individual.member.id}`}
          member={individual.member}
          onAddPortalLogin={handleAddPortalLoginClicked}
        />
      )
  )));

  return (
    <>
      <HelmetTitle subTitle="Non-Fleet Memberships Search" />
      <Fade in>
        <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
          {searchCount >= 500 && (
            <Alert variant="standard" severity="warning" sx={{ margin: '18px' }}>Your search criteria generated too many results. Only the first 500 were returned.</Alert>
          )}

          <Box sx={{ marginTop: '24px' }}>
            <InitialResultSetButton
              showBadge={Boolean(searchCriteria)}
              recordCount={recordCount}
              onClick={() => setDefineInititalResultSetOpen(true)}
              helpComponent={<IndividualMembershipInitialResultSetHelp />}
              buttonTitle="Search"
            />
          </Box>
          <FormControl component="fieldset" sx={{ marginLeft: '18px', marginTop: '28px' }} disabled={!resultsHavePortalUsers}>

            <Fade in={Boolean(searchCriteria)} timeout={fadeTimeout}>
              <Box>
                <Box display="flex" alignItems="center">
                  <FormLabel component="legend" sx={{ marginBottom: '3px', marginRight: '8px' }}>Filter Portal User Status</FormLabel>
                  <FilterPortalUserStatusHelp />
                </Box>

                <RadioGroup aria-label="account type filter" name="acount-type-filter" value={portalUserStatus} onChange={handlePortalUserStatusChange} sx={{ flexDirection: isXsDown ? 'column' : 'row' }}>
                  <FormControlLabel
                    value="no-user"
                    control={<Radio />}
                    label={<PortalUserStatusOptionText text="without" />}
                  />
                  <FormControlLabel
                    disabled={!resultsHavePortalUsers}
                    value="user"
                    control={<Radio />}
                    label={<PortalUserStatusOptionText text="with" />}
                  />
                  <FormControlLabel disabled={!resultsHavePortalUsers} value="both" control={<Radio />} label="Both" />
                </RadioGroup>
              </Box>
            </Fade>

          </FormControl>

          <Fade in={Boolean(searchCriteria)} timeout={fadeTimeout}>
            <Box>
              <EntityCollectionPage
                showViewToggle={false}
                entityName="non-fleet membership user"
                entityCollecitonHeader={(
                  <EntityCollectionHeader
                    containerStyle={{ marginTop: '0px' }}
                    centerBlock={(
                      <Box display="flex" alignItems="center" flex={1} position="relative">
                        <form style={{ ...styles.searchForm, marginTop: '10px' }}>
                          <Box sx={styles.textFieldBox} flexWrap="wrap">
                            <ClearableTextField
                              sx={{ ...commonSearchStyles.searchTextField, alignSelf: 'center' }}
                              showAdornment={searchTextHasValue}
                              onAdornmentClick={() => setSearchText('')}
                              value={searchText}
                              onChange={(e) => setSearchText(e.currentTarget.value)}
                              label="Filter non-fleet memberships"
                              variant="standard"
                            />
                          </Box>
                          <Box sx={styles.filterContainer}>
                            <FilterSearchWrapper
                              optionsAreSelected={filterOptionsAreSelected}
                              visibilityCallback={(isvisible: boolean) => {
                                setFilterOptionsAreVisible(isvisible);
                              }}
                              clearFiltersFunc={() => {
                                clearSearchFilters();
                              }}
                              disabled={enablePortalUserFilters}
                              showWhenNotDisabled
                            >
                              <LoginStatusFilter
                                handleSelection={handleUserLoginTypeSelected}
                                value={selectedUserLoginTypes}
                                disabled={enablePortalUserFilters}
                              />
                              <MembershipStatusFilter
                                handleSelection={handleUserMembershipTypeSelected}
                                value={selectedUserMembershipTypes}
                                disabled={enablePortalUserFilters}
                              />

                              {/* <FuelCardStatusFilter
                                handleSelection={handleUserFuelCardTypeSelected}
                                value={selectedUserFuelCardTypes}
                                disabled={enablePortalUserFilters}
                              />

                              {userProfile?.userPermissions?.canManageIndividualLegal && (
                                <UserLegalStatusFilter
                                  handleSelection={handleUserUserLegalCaseStatusSelected}
                                  value={selectedUserLegalStatuses}
                                  disabled={enablePortalUserFilters}
                                />
                              )} */}

                            </FilterSearchWrapper>
                          </Box>
                        </form>
                      </Box>
                    )}
                  />
                )}
                resetPagingDependencies={[searchText, selectedUserMembershipTypes, selectedUserLoginTypes, selectedUserFuelCardTypes, selectedUserLegalStatuses, portalUserStatus]}
                jsxItems={toggleJsxItems(individualUsers)}
                activePageColor="secondary"
              />
            </Box>
          </Fade>
        </Box>
      </Fade>

      <DefineMembershipsSearchCriteriaModal
        open={defineInititalResultSetOpen}
        onClose={() => setDefineInititalResultSetOpen(false)}
        onSearchCriteriaDefined={async (criteria: SearchIndividualMemberships) => {
          try {
            await dispatch(retrieveIndividualMembershipUsers(criteria));
          } catch (err) {
            handleError(err);
          }
        }}
      />

      {selectedMember && (
        <AddIndividualPortalLoginModal
          isBusy={isUpdatingUser}
          apiValidationErrors={apiValidationErrors}
          open={addPortalLoginModalOpen}
          onClose={() => {
            handleClose();
          }}
          onFormSubmit={async (submittedForm) => {
            try {
              setError('');
              setApiValidationErrors([]);
              await dispatch(addLoginToIndividualPortalUser({
                ...submittedForm,
                createLogin: true,
                permissionIds: [],
                userId: undefined,
                memberId: selectedMember.id,
              }, true));
              handleClose();
            } catch (err) {
              if (!hasApiValidationErrors({ errorResponse: err })) {
                setError('There was a problem during the add portal login process');
              }
              handleError(err, '', false);
            }
          }}
          apiError={error}
          defaultFormData={{
            firstName: selectedMember.firstName || '',
            lastName: selectedMember.lastName || '',
            email: selectedMember.email || '',
            memberId: selectedMember.id,
          }}
        />
      )}
    </>
  );
};

export default IndividualMembershipSearch;
