import React from 'react';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import { FilterOption } from '../../../shared/components/search-filter/FilterOption';
import MultiSelectFilter from '../../../shared/components/search-filter/MultiSelectFilter';
import { StatusFilterProps } from '../../../shared/components/search-filter/StatusFilterProps';
import { UserLegalIcon } from '../../../shared/components/UserIcons';

export const userLegalStatuses: FilterOption<PortalApiUser>[] = [

  {
    displayName: 'Active',
    key: 'active-user-legal-case',
    icon: <UserLegalIcon
      hasLegalCases
      hasActiveLegalCases
    />,
    condition: (user: PortalApiUser) => user.hasLegalCase && user.hasActiveLegalCase,
  },
  {
    displayName: 'Inactive',
    key: 'inactive-user-legal-case',
    icon: <UserLegalIcon
      hasLegalCases
      hasActiveLegalCases={false}
    />,
    condition: (user: PortalApiUser) => user.hasLegalCase && !user.hasActiveLegalCase,
  },
  {
    displayName: 'No Legal Cases',
    key: 'no-user-legal-cases',
    icon: <UserLegalIcon
      hasLegalCases={false}
      hasActiveLegalCases={false}
    />,
    condition: (user: PortalApiUser) => !user.hasLegalCase && !user.hasActiveLegalCase,
  },

];

const UserLegalStatusFilter = (props: StatusFilterProps) => {
  const { value, handleSelection, disabled } = props;
  return (
    <MultiSelectFilter<PortalApiUser>
      disabled={disabled}
      inputLabel="Legal Case Status"
      labelId="filter-user-legal-case-label"
      selectId="filter-user-legal-case-select"
      value={value}
      handleSelection={handleSelection}
      options={userLegalStatuses}
    />
  );
};

export default UserLegalStatusFilter;
