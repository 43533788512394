import React from 'react';
import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface NoResultsProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}
const NoResults = (props: NoResultsProps) => {
  const { children = 'No results', style } = props;
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flex: '1',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '40px',
        marginBottom: '10px',
        backgroundColor: theme.palette.grey[100],
        ...style,
      }}
    >
      <Box
        display="inline-flex"
        sx={{
          fontStyle: 'italic',
          color: theme.palette.grey[700],
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default NoResults;
