import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface EntityCollectionHeaderProps {
  centerBlock?: React.ReactNode;
  paging?: React.ReactNode;
  rightBlock?: React.ReactNode;
  useDefaultLayout?: boolean;
  customLayout?: React.ReactNode;
  containerStyle?: React.CSSProperties;
}

const EntityCollectionHeader = (props: EntityCollectionHeaderProps) => {
  const {
    useDefaultLayout, centerBlock, rightBlock, paging, customLayout, containerStyle,
  } = props;
  const theme = useTheme();
  const styles = {
    container: {
      marginTop: '2em',
      marginBottom: '1.5em',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
    paging: {
      display: 'flex',
      flexBasis: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '15px',
      [theme.breakpoints.down('sm')]: {
        order: 3,
      },
    },
    centerBlock: {
      paddingRight: '12px',
      [theme.breakpoints.down('sm')]: {
        order: '2',
        flexBasis: '100%',
        paddingLeft: '0px',
      },
    },
    rightBlock: {
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
        justifyContent: 'center',
        marginBottom: '10px',
      },
    },
  };
  const isDefaultLayout = useDefaultLayout === undefined ? true : useDefaultLayout;

  return (
    <>
      {isDefaultLayout ? (
        <Box
          display="flex"
          sx={{
            ...styles.container,
            ...containerStyle,
          }}
        >
          <Box flex="1" sx={styles.centerBlock}>
            {centerBlock}
          </Box>

          {rightBlock && (
            <Box sx={styles.rightBlock} display="flex">
              {rightBlock}
            </Box>
          )}

          {paging && <Box sx={styles.paging}>{paging}</Box>}
        </Box>
      ) : (
        <Box display="flex" sx={styles.container}>
          {customLayout}
        </Box>
      )}
    </>
  );
};

export default EntityCollectionHeader;
