import {
  Box, Paper, Typography, Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { PortalApiFuelLoadHistoryDetail } from '../../shared/models/fuel/PortalApiFuelLoadHistoryDetail';
import { useProfile } from '../auth/hooks/useProfile';
import { formatTimezone } from '../../shared/utilities/dateHelpers';
import Summary from '../../shared/components/confirm-summary/Summary';
import ApiValidationErrors from '../../shared/components/ApiValidationErrors';
import SummarySection from '../../shared/components/confirm-summary/SummarySection';
import SectionRow from '../../shared/components/confirm-summary/SectionRow';
import ConfirmLabelValue from '../../shared/components/confirm-summary/ConfirmLabelValue';
import { currencyFormat } from '../../shared/utilities/currencyFormat';
import { getCreditDebitFormatting } from './FuelActivityDetails';
import SubConfirmmLabelValue from '../../shared/components/confirm-summary/SubConfirmLabelValue';
import ScheduledLoadCriteriaDisplay from '../../shared/components/scheduled-loads/ScheduledLoadCriteriaDisplay';
import LabelValueHelp from '../../shared/components/help/LabelValueHelp';
import RunAsHelp from '../../shared/components/fuel/RunAsHelp';
import ScheduledLoadHistoryDisplay from '../../shared/components/scheduled-loads/ScheduledLoadHistoryDisplay';
import ProcessStepsArray from '../../shared/components/ProcessStepsArray';
import { TriggeredFundModel } from '../../shared/models/fuel/TriggeredFundModel';
import { getValueOrReplacementValue } from '../../shared/utilities/miscHelpers';

interface FuelLoadDetailsProps {
  details: PortalApiFuelLoadHistoryDetail;
  isPending?: boolean;
  scheduledLoadDetails?: TriggeredFundModel;
  isSmartCard?: boolean;
}

const FuelLoadDetails = (props: FuelLoadDetailsProps) => {
  const {
    details, isPending, scheduledLoadDetails, isSmartCard = false,
  } = props;
  const {
    feeAdjustmentAmount,
    feeAdjustmentReason,
    loadDate,
    loadAmount,
    contractId,
    carrierId,
    estimatedFundsAvailability,
    expediteFeeDays,
    expediteFeePercentage,
    createdDate,
    loadFeeAmount,
    totalFees,
    expediteFeeAmount,
    totalAmount,
    createdByFirstName,
    createdByLastName,
    type,
    isCheck,
    paymentType,
    lastFour,
    stepsWithDateTimes,
    sourceType,
    couponValidationResult,
  } = details;
  const theme = useTheme();
  const styles = {
    loadIssues: {
      marginLeft: '18px',
      marginRight: '18px',
      marginBottom: '6px',
    },
  };
  const isManualEfsLoad = type && type.toLowerCase() === 'efs load';
  const isScheduledLoad = Boolean(scheduledLoadDetails);

  const { userProfile } = useProfile();

  const getLoadedDateString = (isSchedLoad: boolean) => {
    let date: string | undefined;

    if (isSchedLoad) {
      date = scheduledLoadDetails?.estimatedFundsAvailability;
    } else {
      date = isPending ? estimatedFundsAvailability : loadDate;
    }

    if (!date) {
      return '-';
    }
    return date;
  };

  const getSummarySectionTitle = (loadType: string) => (loadType.toLocaleLowerCase() === 'load' ? '' : loadType);
  return (
    <Summary>
      <>
        {scheduledLoadDetails?.lastLoadFailure && scheduledLoadDetails.lastLoadFailure.length > 0
          && (
            <Box sx={styles.loadIssues}>
              <Typography variant="subtitle1" color="textSecondary">The following errors were encountered the last time this scheduled load was triggered/processed</Typography>
              <ApiValidationErrors apiValidationErrors={scheduledLoadDetails.lastLoadFailure} variant="filled" />
            </Box>
          )}

        {scheduledLoadDetails?.validationErrors && scheduledLoadDetails.validationErrors.length > 0
          && (
            <Box sx={styles.loadIssues}>
              <Typography variant="subtitle1" color="textSecondary">The following validation errors exist for this scheduled load</Typography>
              <ApiValidationErrors severity="warning" apiValidationErrors={scheduledLoadDetails.validationErrors} variant="filled" />
            </Box>
          )}

        <SummarySection title={`${getSummarySectionTitle(type)} Load Info`}>
          <Paper sx={{ flex: '1' }}>

            {isPending && type.toLowerCase() === 'drafted' && (
              <SectionRow>
                <Alert variant="standard" severity="warning" sx={{ width: '100%' }}>Drafted pending loads may not be available by Estimated Load Date</Alert>
              </SectionRow>
            )}

            {userProfile?.isEmployee && isManualEfsLoad && (
              <SectionRow>
                <Alert variant="standard" severity="warning" sx={{ width: '100%' }}>This load was done manually via the EFS website</Alert>
              </SectionRow>
            )}

            {scheduledLoadDetails && (
              <SectionRow>
                <ConfirmLabelValue
                  label="Scheduled Load ID"
                  value={scheduledLoadDetails.id}
                />
              </SectionRow>
            )}

            {userProfile?.isEmployee && (
              <SectionRow>
                <ConfirmLabelValue
                  label={`${isPending
                    ? isCheck
                      ? 'Check Amount' : `Amount to be Charged to ${paymentType}`
                    : isManualEfsLoad ? 'Amount loaded manually via EFS website'
                      : 'Amount Charged to Payment Method'} (only visible to TVC employees)`}
                  value={currencyFormat(totalAmount, 2)}
                />
              </SectionRow>
            )}

            <SectionRow>
              <ConfirmLabelValue
                label={
                  isPending
                    ? isScheduledLoad ? 'Amount to Load' : 'Pending Amount to Load'
                    : 'Amount Loaded'
                }
                value={getCreditDebitFormatting(loadAmount > 0, loadAmount)}
              />
              {couponValidationResult?.couponUsageId && (
                <>
                  {couponValidationResult.discount > 0 && (
                    <ConfirmLabelValue
                      label="Promo Discount"
                      value={getCreditDebitFormatting(couponValidationResult.discount < 0, couponValidationResult.discount)}
                    />
                  )}

                  {couponValidationResult.bonus !== undefined && couponValidationResult.bonus > 0 && (
                    <>
                      <ConfirmLabelValue
                        label="Promo Bonus Load Amount"
                        value={getCreditDebitFormatting(couponValidationResult.bonus > 0, couponValidationResult.bonus)}
                      />
                      <ConfirmLabelValue
                        label="Original Load Amount"
                        value={getCreditDebitFormatting(couponValidationResult.price > 0, couponValidationResult.price)}
                      />
                    </>
                  )}

                  {couponValidationResult.bonus !== undefined && couponValidationResult.bonus === 0 && couponValidationResult.discount === null && (
                    <>
                      <ConfirmLabelValue
                        label="Promotion"
                        value={(
                          <span
                            style={{
                              borderRadius: '4px',
                              display: 'inline-block',
                              paddingRight: '4px',
                              paddingLeft: '4px',
                              backgroundColor: theme.palette.success.main,
                              color: theme.palette.common.white,
                            }}
                            data-testid="activated-coupon-display"
                          >
                            Activated
                          </span>
                        )}
                      />
                    </>
                  )}

                </>
              )}

              <SubConfirmmLabelValue
                label={isScheduledLoad ? 'Date Scheduled Load Was Created' : 'Date Load Was Submitted'}
                value={getValueOrReplacementValue(createdDate)}
              />

              {scheduledLoadDetails && (
                <SubConfirmmLabelValue
                  label="Occurrence Criteria"
                  value={<ScheduledLoadCriteriaDisplay load={scheduledLoadDetails} />}
                />
              )}

              {scheduledLoadDetails && (
                <SubConfirmmLabelValue
                  label="Next Occurrence"
                  value={getValueOrReplacementValue(scheduledLoadDetails.nextOccurrence)}
                />
              )}

              <SubConfirmmLabelValue
                label={
                  isPending
                    ? isScheduledLoad ? 'Next Estimated Availability Date' : 'Estimated Availability Date'
                    : 'Date Loaded'
                }
                value={getLoadedDateString(isScheduledLoad)}
              />
              {sourceType && (
                <SubConfirmmLabelValue
                  label="Origin"
                  value={sourceType}
                />
              )}
              <SubConfirmmLabelValue
                label="Type"
                value={type}
              />

              {!isSmartCard && <SubConfirmmLabelValue label="Carrier ID" value={carrierId} />}

              {!isSmartCard && <SubConfirmmLabelValue label="Contract ID" value={contractId} />}

              <SubConfirmmLabelValue label="Payment Method Type" value={isCheck ? 'Check' : paymentType} />
              {!isCheck && <SubConfirmmLabelValue label="Last Four" value={lastFour} />}
            </SectionRow>

            {!isManualEfsLoad && (
              <SectionRow>
                <SubConfirmmLabelValue
                  label="Expedite Days"
                  value={`${expediteFeeDays} day(s)`}
                />
              </SectionRow>
            )}

            <SectionRow>
              <SubConfirmmLabelValue label={isScheduledLoad ? 'Created By' : 'Submitted By'} value={`${createdByFirstName} ${createdByLastName}`} />
              {scheduledLoadDetails && scheduledLoadDetails.modifiedDate
                && (
                  <>
                    <SubConfirmmLabelValue label="Last Modified By" value={`${scheduledLoadDetails.userInfo?.modifiedBy?.firstName} ${scheduledLoadDetails.userInfo?.modifiedBy?.lastName}`} />
                    <SubConfirmmLabelValue
                      label="Modified Date"
                      value={
                        scheduledLoadDetails.modifiedDate
                          ? formatTimezone(
                            scheduledLoadDetails.modifiedDate,
                            "MM/dd/yyyy h:mm aaaaa'm' zzz",
                          )
                          : '-'
                      }
                    />
                  </>
                )}

              {userProfile?.isEmployee && scheduledLoadDetails?.modifiedDate && (
                <SubConfirmmLabelValue
                  label={(
                    <LabelValueHelp label="Will Run As" tooltipTitle="Click for help about Will Run As" positionLeft={84}>
                      <RunAsHelp runAsName={`${scheduledLoadDetails?.userInfo?.runAs?.firstName} ${scheduledLoadDetails?.userInfo?.runAs?.lastName}`} />
                    </LabelValueHelp>
                  )}
                  value={`${scheduledLoadDetails?.userInfo?.runAs?.firstName} ${scheduledLoadDetails?.userInfo?.runAs?.lastName}`}
                />
              )}
            </SectionRow>

          </Paper>
        </SummarySection>
      </>
      {userProfile?.isEmployee && !isManualEfsLoad && (
        <SummarySection title="Fee Info (only visible to TVC employees)">
          <Paper sx={{ flex: '1' }}>
            <SectionRow>
              <ConfirmLabelValue
                label="Total Fees"
                value={currencyFormat(totalFees, 2)}
              />
              <SubConfirmmLabelValue
                label="Load Fee"
                value={currencyFormat(loadFeeAmount, 2)}
              />
              <SubConfirmmLabelValue
                label="Expedite Fee"
                value={currencyFormat(expediteFeeAmount, 2)}
              />
              <SubConfirmmLabelValue
                label="Expedite Fee Percentage"
                value={`${expediteFeePercentage}%`}
              />
              {feeAdjustmentAmount && (
                <>
                  <SubConfirmmLabelValue
                    label="Load Fee Adjustment"
                    value={currencyFormat(feeAdjustmentAmount, 2)}
                  />
                  <SubConfirmmLabelValue
                    label="Load Fee Adjustment Reason"
                    value={getValueOrReplacementValue(feeAdjustmentReason)}
                  />
                </>
              )}
            </SectionRow>
          </Paper>
        </SummarySection>
      )}

      {scheduledLoadDetails?.loadStepDetails && (
        <SummarySection title={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HistoryIcon sx={{ marginRight: '12px' }} />
            {' '}
            Occurrence History (Last 30 Days)
          </div>
        )}
        >
          <div style={{ marginLeft: '32px', width: '100%' }}><ScheduledLoadHistoryDisplay history={scheduledLoadDetails.loadStepDetails} /></div>
        </SummarySection>
      )}

      {stepsWithDateTimes && stepsWithDateTimes.length > 0 && (
        <SummarySection
          title={`Step Log${stepsWithDateTimes.length > 1 ? 's' : ''}${stepsWithDateTimes.length > 1 ? ` (${stepsWithDateTimes.length})` : ''}`}
          childrenContainerStyle={{ flexDirection: 'column' }}
        >
          <ProcessStepsArray stepArray={stepsWithDateTimes} />
        </SummarySection>
      )}
    </Summary>
  );
};

export default FuelLoadDetails;
