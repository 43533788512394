import {
  List, ListItem, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import HelpDialog from './HelpDialog';

const UserPermissionsHelp = () => (
  <HelpDialog tooltipTitle="Click for help about user permissions">
    <Typography variant="h5" gutterBottom>
      User Permissions
    </Typography>
    <List>
      <ListItem sx={{ alignItems: 'flex-start' }}>
        <ListItemText
          style={{ alignItems: 'flex-start' }}
          primary='"This user needs special permissions" checkbox'
          secondary={(
            <div>
              <div style={{ marginTop: '18px', marginBottom: '18px' }}>
                Check this box if the given user requires special permissions within the portal. Special permissions give a user the ability to create/alter items or access areas of the portal that are not available to users without special permissions.
              </div>
              <div>
                Users without special permissions will have access to their own items upon logging into the portal.
              </div>
            </div>
          )}
        />
      </ListItem>
    </List>

  </HelpDialog>
);

export default UserPermissionsHelp;
