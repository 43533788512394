import {
  Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface DetailsAccordionProps {
  summaryChildren?: React.ReactNode;
  detailsChildren?: React.ReactNode;
  onChange?: (isOpen: boolean) => void;
  ariaSummary?: string;
  detailsStyle?: React.CSSProperties;
  lockExpanded?: boolean;
  hideExpandIcon?: boolean;
  hasError?: boolean;
  hasSuccess?: boolean;
}

const DetailsAccordion = (props: DetailsAccordionProps) => {
  const theme = useTheme();
  const {
    summaryChildren,
    detailsChildren,
    onChange,
    ariaSummary = '',
    detailsStyle = {
      backgroundColor: theme.palette.background.default,
    },
    lockExpanded = false,
    hideExpandIcon = false,
    hasError = false,
    hasSuccess = false,
  } = props;

  const styles = {
    rootOutline: {
      border: '1px solid rgba(0, 0, 0, .125)',
      cursor: hideExpandIcon ? 'default !important' : '',
      boxShadow: theme.shadows[1],
    },
    root: {
      '&:before': {
        backgroundColor: 'unset !important',
      },
      backgroundColor: hasError ? 'rgb(253, 236, 234) !important' : hasSuccess ? '#DFF5DE' : theme.palette.common.white,
    },
    expandIcon: {
      display: hideExpandIcon ? 'none !important' : '',
    },
    detailsRoot: {
      [theme.breakpoints.only('xs')]: {
        padding: '4px !important',
      },
    },
  };

  return (
    <Accordion
      square
      elevation={0}
      onChange={(e, isOpen) => {
        if (onChange) {
          onChange(isOpen);
        }
      }}
      sx={{
        ...styles.root,
        flex: '1',
      }}
      expanded={lockExpanded ? true : undefined}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={ariaSummary}
        sx={{
          ...styles.rootOutline,
          '& .MuiAccordionSummary-expandIconWrapper': styles.expandIcon,
        }}
      >
        {summaryChildren}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          ...styles.detailsRoot,
          ...detailsStyle,
        }}
      >
        {detailsChildren}
      </AccordionDetails>
    </Accordion>
  );
};

export default DetailsAccordion;
