import { subYears } from 'date-fns';
import { CaseDetails, CaseSummary } from '../../../shared/components/legal/legalTypes';
import { LegalClosingLetter } from '../../../shared/models/legal/LegalClosingLetter';
import { LegalDocument } from '../../../shared/models/legal/LegalDocument';
import { UpcomingEventsModel } from '../../../shared/models/legal/UpcomingEventsModel';
import { getCentralNowDate } from '../../../shared/utilities/dateHelpers';
import { defaultLegalCaseYearsBack, mapCaseDetails } from '../../../shared/utilities/legalHelpers';
import { initAxiosInstance } from './utils';
import { CategoryAttachment } from '../../../shared/models/file-upload/CategoryAttachment';
import { FileCategory } from '../../../shared/models/file-upload/FileCategory';

export const useLegalApi = () => {
  const portalClient = initAxiosInstance();

  // API: GetLegalCases
  const getCaseSummariesByFleet = async (fleetId: string, startDate?: Date,
    endDate?: Date) => {
    const { data } = await portalClient.get<CaseSummary[]>(`/legal/fleet/${fleetId}`, {
      params: {
        startDate: startDate || subYears(getCentralNowDate(), defaultLegalCaseYearsBack),
        endDate,
      },
    });
    return data;
  };

  // API: GetFleetUpcomingCases
  const getUpcomingEventsByFleet = async (fleetId: string) => {
    const { data } = await portalClient.get<UpcomingEventsModel>(`/legal/fleet/${fleetId}/upcomingCases`);
    return data;
  };

  // API: GetFleetUserLegalCases
  const getCaseSummariesByFleetUser = async (fleetId: string, userId: string, startDate?: Date,
    endDate?: Date) => {
    const { data } = await portalClient.get<CaseSummary[]>(`/legal/fleet/${fleetId}/user/${userId}`, {
      params: {
        startDate: startDate || subYears(getCentralNowDate(), defaultLegalCaseYearsBack),
        endDate,
      },
    });
    return data;
  };

  // API: GetUserLegalCases
  const getCaseSummariesByUser = async (userId: string, startDate?: Date,
    endDate?: Date) => {
    const { data } = await portalClient.get<CaseSummary[]>(`/legal/user/${userId}`, {
      params: {
        startDate: startDate || subYears(getCentralNowDate(), defaultLegalCaseYearsBack),
        endDate,
      },
    });
    return data;
  };

  // API: GetLegalCase
  const getFleetCaseDetails = async (fleetId: string, caseId: number): Promise<CaseDetails> => {
    const { data } = await portalClient.get<CaseDetails>(`/legal/fleet/${fleetId}/case/${caseId}`);
    return mapCaseDetails(data);
  };

  // API: GetLegalCase
  const getIndividualCaseDetails = async (caseId: number): Promise<CaseDetails> => {
    const { data } = await portalClient.get<CaseDetails>(`/legal/case/${caseId}`);
    return mapCaseDetails(data);
  };

  // API: GetFleetLegalClosingLetter
  const getFleetLegalClosingLetter = async (fleetId: string, caseId: number) => {
    const { data } = await portalClient.get<LegalClosingLetter>(`/legal/fleet/${fleetId}/legalclosingletter/${caseId}`);

    return data;
  };

  // API: GetUserLegalClosingLetter
  const getIndividualLegalClosingLetter = async (caseId: number) => {
    const { data } = await portalClient.get<LegalClosingLetter>(`/legal/legalclosingletter/${caseId}`);
    return data;
  };

  // API: GetUserUpcomingCases
  const getUpcomingEventsByUserId = async (userId: string) => {
    const { data } = await portalClient.get<UpcomingEventsModel>(`/legal/user/${userId}/upcomingCases`);
    return data;
  };

  // API: GetLegalDraftDocument
  const getDraftCaseDocument = async (caseId: number) => {
    const { data } = await portalClient.get<LegalDocument[]>(`/legal/legalDraftDocument/${caseId}`);
    return data;
  };

  const postIndividualCaseFiles = async (
    caseId: number,
    attachments: CategoryAttachment[],
  ) => {
    const { data } = await portalClient.post(`/legal/case/${caseId}`, attachments);
    return data;
  };

  const postFleetCaseFiles = async (
    caseId: number,
    attachments: CategoryAttachment[],
    fleetId: string,
  ) => {
    const { data } = await portalClient.post(`/legal/case/${caseId}/fleet/${fleetId}`, attachments);
    return data;
  };

  const getFleetCaseFiles = async (fleetId: string, caseId: number) => {
    const { data } = await portalClient.get<Blob>(
      `/legal/fleet/${fleetId}/downloadCaseFiles/${caseId}`,
      {
        responseType: 'blob',
      },
    );

    return data;
  };

  const getIndividualCaseFiles = async (caseId: number) => {
    const { data } = await portalClient.get<Blob>(
      `/legal/downloadCaseFiles/${caseId}`,
      {
        responseType: 'blob',
      },
    );

    return data;
  };

  // API: DownloadFleetLegalCaseFiles
  const postExportFleetLegalCases = async (
    fleetId: string,
    caseIds: number[],
  ): Promise<Blob> => {
    const { data } = await portalClient.post<Blob>(`/legal/fleet/${fleetId}/export`, caseIds, {
      responseType: 'blob',
    });
    return data;
  };

  // API: DownloadUserLegalCaseFiles
  const postExportIndividualLegalCases = async (
    caseIds: number[],
  ): Promise<Blob> => {
    const { data } = await portalClient.post<Blob>('/legal/export', caseIds, {
      responseType: 'blob',
    });
    return data;
  };

  // API: GetDocumentCategories
  const getDocumentCategories = async () => {
    const { data } = await portalClient.get<FileCategory[]>('/legal/documentCategories');
    return data;
  };

  return {
    getCaseSummariesByFleet,
    getUpcomingEventsByFleet,
    getCaseSummariesByFleetUser,
    getCaseSummariesByUser,
    getFleetCaseDetails,
    getIndividualCaseDetails,
    getFleetLegalClosingLetter,
    getIndividualLegalClosingLetter,
    getUpcomingEventsByUserId,
    getDraftCaseDocument,
    postIndividualCaseFiles,
    postFleetCaseFiles,
    getFleetCaseFiles,
    getIndividualCaseFiles,
    postExportFleetLegalCases,
    postExportIndividualLegalCases,
    getDocumentCategories,
  };
};
