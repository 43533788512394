import {
  Action, combineReducers, configureStore, ThunkAction,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import applicationSliceReducer from '../features/application/applicationSlice';
import authReducer from '../features/auth/authReducer';
import fleetReducer from '../features/fleet/fleetSlice';
import fuelCardsReducer from '../features/fuelCard/fuelCardSlice';
import usersReducer from '../features/user/userSlice';
import matrixReducer from '../features/matrix/matrixSlice';
import efsReducer from '../features/fuel/efsSlice';
import legalReducer from '../features/legal/legalSlice';
import paymentMethodsReducer from '../features/paymentMethod/paymentMethodSlice';
import migrationReducer from '../features/member-migration/migrationSlice';
import messagesReducer from '../features/messages/messageSlice';

export const rootReducer = combineReducers({
  application: applicationSliceReducer,
  fleets: fleetReducer,
  legal: legalReducer,
  paymentMethods: paymentMethodsReducer,
  auth: authReducer,
  users: usersReducer,
  fuelCards: fuelCardsReducer,
  matrix: matrixReducer,
  efs: efsReducer,
  migration: migrationReducer,
  messages: messagesReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export type AppThunk<ReturnType = void, ParType = unknown> = ThunkAction<ReturnType, RootState, ParType, Action<string>>;
