import React from 'react';
import HelpDialog from '../HelpDialog';

interface LabelValueHelpProps {
  children?: React.ReactNode;
  tooltipTitle?: string;
  positionLeft?: number;
  positionTop?: number;
  label: React.ReactNode;
  style?: React.CSSProperties;
}
const LabelValueHelp = (props: LabelValueHelpProps) => {
  const {
    children, tooltipTitle = 'Click for help', positionTop = -2, positionLeft = 100, label, style,
  } = props;

  return (
    <div style={{ position: 'relative' }}>
      {label}
      {children && (
        <HelpDialog tooltipTitle={tooltipTitle} style={{ top: `${positionTop}px`, left: `${positionLeft}px`, ...style }} removeDialogPadding>
          {children}
        </HelpDialog>
      )}
    </div>
  );
};

export default LabelValueHelp;
