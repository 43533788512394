import { TextField } from '@mui/material';
import { get, omit } from 'lodash/fp';
import React from 'react';

import {
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { DisplayTextField } from '../DisplayTextField';

export interface CustomNumberInputProps {
  name: string;
  validateOnChange?: boolean;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  defaultValue?: number;
  disabled?: boolean;
  thousandSeparator?: boolean;
  decimalScale?: number;
  style?: React.CSSProperties;
  placeholder?: string;
  displayOnly?: boolean;
  adornment?: React.ReactNode;
  adornmentPosition?: 'start' | 'end';
  showAdornment?: boolean;
  prefix?: string;
  suffix?: string;
  fixedDecimalScale?: boolean;
  fullWidth?: boolean;
  allowNegative?: boolean;
  maxLength?: number;
}

const CustomNumberInput = (props: CustomNumberInputProps) => {
  const {
    style,
    label = '',
    validateOnChange,
    thousandSeparator,
    name,
    disabled,
    defaultValue,
    rules,
    decimalScale,
    placeholder,
    displayOnly,
    prefix,
    suffix,
    fixedDecimalScale,
    adornment,
    showAdornment = Boolean(adornment),
    adornmentPosition = 'end',
    fullWidth = false,
    allowNegative = true,
    maxLength,
    ...additionalProps
  } = props;
  const {
    control, setValue, trigger, watch,
  } = useFormContext<Record<string, number | undefined | null>>();
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const watchValue = watch(name) || '';

  const isFieldInvalid = fieldState.error;
  const fieldWithoutOnChange = omit(['onChange'], field);

  return (
    <NumericFormat
      {...fieldWithoutOnChange}
      onValueChange={({ floatValue }) => {
        if (floatValue === field.value) {
          return;
        }
        field.onChange(floatValue);
        setValue(name, floatValue);
        if (validateOnChange) {
          trigger(name);
        }
      }}
      value={watchValue}
      required={Boolean(rules?.required)}
      type="text"
      placeholder={placeholder}
      fixedDecimalScale={fixedDecimalScale}
      {...additionalProps}
      prefix={prefix}
      label={label}
      disabled={displayOnly ? true : disabled}
      error={!!isFieldInvalid}
      decimalScale={decimalScale}
      sx={{ ...style, width: fullWidth ? '100%' : {} }}
      thousandSeparator={thousandSeparator}
      InputProps={
        showAdornment
          ? {
            ...(adornmentPosition === 'start' && {
              startAdornment: adornment,
            }),
            ...(adornmentPosition === 'end' && { endAdornment: adornment }),
          }
          : {}
      }
      inputProps={{ maxLength }}
      helperText={isFieldInvalid ? get(['error', 'message'], fieldState) : ' '}
      customInput={displayOnly ? DisplayTextField : TextField}
      suffix={suffix}
      allowNegative={allowNegative}
    />
  );
};

export default CustomNumberInput;
