import React from 'react';

import StandardError from '../StandardError';

const FuelLoadError = () => (
  <StandardError
    style={{ width: '100%' }}
    errorTitle="Error Encountered"
    errorDescription="There was an unexpected error. Please contact support before making another attempt to load funds."
  />
);

export default FuelLoadError;
