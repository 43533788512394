import {
  Box, List, ListItem, ListItemText,
} from '@mui/material';

import React from 'react';

interface InitialResultsHelpListItemProps {
  children?: React.ReactNode;
  initialResultSecondaryText?: React.ReactNode;
  headerText?: string;
}
const InitialResultsHelpListItem = (props: InitialResultsHelpListItemProps) => {
  const { children, initialResultSecondaryText, headerText = 'INITIAL RESULT SET' } = props;

  const styles = {
    secondary: {
      marginTop: '18px',
    },
  };

  return (
    <>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={headerText.toUpperCase()}
            secondary={initialResultSecondaryText}
            sx={{
              '& .MuiListItemText-secondary': styles.secondary,
            }}
          />
        </ListItem>
      </List>
      { children && (
      <Box sx={{ marginLeft: '56px', marginTop: '-20px' }}>
        {children}
      </Box>
      )}
    </>
  );
};

export default InitialResultsHelpListItem;
