/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from 'axios';
import { parseEnv } from '../../../config';
import { MembersLoginFormData } from '../../../features/member-migration/MatrixLoginForm';
import { MatrixLoginResponse } from '../../../shared/types/matrix/migration/MatrixLoginResponse';
import { EmailVerificationCodeResponse } from '../../../shared/types/matrix/migration/EmailVerificationCodeResponse';
import { PortalUserCreationBody } from '../../../shared/types/matrix/migration/PortalUserCreationBody';
import { UnauthenticatedMembershipBillingModel } from '../../../shared/models/matrix/UnauthenticatedMembershipBillingModel';
import { UnauthenticatedPayNow } from '../../../shared/models/matrix/UnauthenticatedPayNow';

const initUnAuthenticatedAxiosInstance = () => {
  const config = parseEnv();

  const portalClient = axios.create({
    baseURL: config.REACT_APP_PORTAL_API_BASE_URL,
    responseType: 'json',
  });

  return portalClient;
};
export const useUnAuthenticatedPortalApi = () => {
  const portalClient = initUnAuthenticatedAxiosInstance();

  const checkIfEmailAlreadyExistsUnauth = async (email: string) => {
    const { data: isAvailable } = await portalClient.get<boolean>(
      '/unauthenticated/emailExists',
      {
        params: {
          email,
        },
      },
    );

    return isAvailable;
  };

  const sendEmailConfirmationCode = async (email: string) => {
    await portalClient.get<void>(
      `/unauthenticated/emailVerification/generate/${email}`,
    );
  };

  const verifyEmailCode = async (email: string, code: number) => {
    const { data } = await portalClient.get<EmailVerificationCodeResponse>(
      `/unauthenticated/emailVerification/verify/${email}/${code}`,
    );

    return data;
  };

  const attemptMatrixLogin = async (
    loginData: MembersLoginFormData,
  ) => {
    const { data } = await portalClient.post<MatrixLoginResponse>('unauthenticated/matrix/login', {
      ...loginData,
    });

    return data;
  };

  const createNewPortalAccount = async (
    createRequest: PortalUserCreationBody,
  ) => {
    await portalClient.post('unauthenticated/createPortalUser', {
      ...createRequest,
    });
  };

  // API: GetMembershipBilling
  const getUnAuthenticatedMembershipBilling = async (token: string) => {
    const { data } = await portalClient.get<UnauthenticatedMembershipBillingModel[]>(
      `/unauthenticated/membership/billing/${token}`,
    );

    return data;
  };

  // API: PayNow
  const postUnauthenticatedPayNow = async (unauthenticatedPayNow: UnauthenticatedPayNow) => {
    const { data } = await portalClient.post<UnauthenticatedMembershipBillingModel[]>(
      '/unauthenticated/membership/billing/payNow',
      {
        unauthenticatedPayNow,
      },
    );

    return data;
  };

  return {
    checkIfEmailAlreadyExistsUnauth,
    attemptMatrixLogin,
    sendEmailConfirmationCode,
    verifyEmailCode,
    createNewPortalAccount,
    getUnAuthenticatedMembershipBilling,
    postUnauthenticatedPayNow,
  };
};
