import {
  Box,
  List, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';

interface AdditionalFilterHelpListItemProps {
  children?: React.ReactNode;
}
const AdditionalFilteringHelpListItem = (props: AdditionalFilterHelpListItemProps) => {
  const { children } = props;
  return (
    <>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="ADDITIONAL FILTERING"
          />
        </ListItem>
        <ListItem sx={{ alignItems: 'flex-start' }}>
          <ListItemAvatar>
            <FilterListIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: '5px' }} />
          </ListItemAvatar>
          <ListItemText
            sx={{ alignItems: 'flex-start' }}
            primary="Click this icon to bring up additional filtering options"
            secondary="Select one or more of the options available in each drop-down list"
          />
        </ListItem>
      </List>
      { children && (
        <Box sx={{ marginLeft: '56px' }}>
          {children}
        </Box>
      )}

    </>
  );
};

export default AdditionalFilteringHelpListItem;
