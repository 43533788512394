import React from 'react';
import {
  Box, Fade, Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme } from '@mui/material/styles';
import {
  createNewTvcPortalAaccount,
  selectAccountCreated,
  selectCodeVerificationResponse, selectMigrationBusy, selectMigrationError, selectMigrationResponse, selectPortalEmail,
} from './migrationSlice';
import MigrationContentHeader from './MigrationContentHeader';
import { useAppDispatch } from '../../app/store';
import { fadeTimeout } from '../../shared/transitions/timeouts';
import ApiValidationErrors from '../../shared/components/ApiValidationErrors';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import { useTvcPortalApiValidationErrors } from '../../shared/hooks/useTvcPortalApiValidationErrors';
import IconText from '../../shared/components/icon-components/IconText';
import CheckJunkBoxIconText from '../../shared/components/icon-components/CheckJunkBoxIconText';

const CreateNewPortalAccount = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const loginResponse = useSelector(selectMigrationResponse);
  const codeVerificationResponse = useSelector(selectCodeVerificationResponse);
  const isBusy = useSelector(selectMigrationBusy);
  const portalEmail = useSelector(selectPortalEmail);
  const accountCreated = useSelector(selectAccountCreated);
  const migationError = useSelector(selectMigrationError);

  const {
    apiValidationErrors,
    hasApiValidationErrors,
    setApiValidationErrors,
  } = useTvcPortalApiValidationErrors();

  return (
    <>
      {!accountCreated

        ? (
          <Fade in style={{ transitionDelay: '200ms' }} timeout={fadeTimeout}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MigrationContentHeader headerText="Code Verified Successfully" isSuccess showIcon={false} />
                    </Grid>
                    <Grid item xs={12}>
                      <p>

                        The code has been verified. After clicking the button below, your new TVC Pro-Driver Portal account will be created. An email will be sent to
                        {' '}
                        <span style={{ fontWeight: 'bold', color: theme.palette.grey[700] }}>{portalEmail}</span>
                        {' '}
                        with instructions on how to access the new TVC Pro-Driver Portal.
                      </p>
                    </Grid>
                    {loginResponse && codeVerificationResponse && (
                      <>
                        {migationError && (
                          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <IconText
                              icon={<ErrorIcon htmlColor={theme.palette.error.main} />}
                              text={migationError}
                              textStyle={{ color: theme.palette.error.main }}
                            />
                          </Grid>
                        )}

                        {apiValidationErrors.length > 0
                          && (
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                              <ApiValidationErrors apiValidationErrors={apiValidationErrors} variant="filled" />
                            </Grid>
                          )}

                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ marginBottom: '18px' }}>
                            <BusySubmitButton
                              variant="contained"
                              size="large"
                              color="primary"
                              type="button"
                              onClick={async () => {
                                try {
                                  setApiValidationErrors([]);
                                  await dispatch(createNewTvcPortalAaccount({
                                    firstName: loginResponse.firstName,
                                    lastName: loginResponse.lastName,
                                    emailVerificationId: codeVerificationResponse.id,
                                    memberIdEncrypted: loginResponse.idEncrypted,
                                  }));
                                } catch (err) {
                                  hasApiValidationErrors({ errorResponse: err });
                                }
                              }}
                              disabled={isBusy || Boolean(migationError)}
                              actionPending={Boolean(isBusy)}
                            >
                              Create TVC Pro-Driver Portal Account
                            </BusySubmitButton>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Fade>
        )

        : (
          <Fade in style={{ transitionDelay: '200ms' }} timeout={fadeTimeout}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MigrationContentHeader headerText="Welcome to the New TVC Pro-Driver Portal!" isSuccess showIcon={false} typographyVariant="h5" />
              </Grid>
              <Grid item xs={12}>
                <p>
                  An email was sent to
                  {' '}
                  <span style={{ color: theme.palette.success.main, fontWeight: 'bold' }}>
                    {portalEmail}
                  </span>
                  {' '}
                  containing
                  {' '}
                  a
                  {' '}
                  temporary password and a link to our new portal.
                  <br />
                  <br />
                  <CheckJunkBoxIconText />
                </p>
              </Grid>
            </Grid>
          </Fade>
        )}
    </>
  );
};

export default CreateNewPortalAccount;
