import { Tooltip } from '@mui/material';
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FilterOption } from '../../../shared/components/search-filter/FilterOption';
import MultiSelectFilter from '../../../shared/components/search-filter/MultiSelectFilter';
import { StatusFilterProps } from '../../../shared/components/search-filter/StatusFilterProps';
import { theme } from '../../../appTheme';
import { Fleet } from '../../../shared/models/fleet/Fleet';

export const fleetActiveStatuses: FilterOption<Fleet>[] = [
  {
    displayName: 'Active',
    key: 'active-fleet',
    icon: <Tooltip title="Active" aria-label="filter case active"><FiberManualRecordIcon sx={{ fill: theme.palette.secondary.main }} /></Tooltip>,
    condition: (f: Fleet) => f.isActive,
  },
  {
    displayName: 'Inactive',
    key: 'inactive-fleet',
    icon: <Tooltip title="Inactive" aria-label="filter case inactive"><FiberManualRecordIcon sx={{ fill: theme.palette.action.disabled }} /></Tooltip>,
    condition: (f: Fleet) => !f.isActive,
  },
];

const FleetActiveFilter = (props: StatusFilterProps) => {
  const {
    value, handleSelection, disabled = false, style,
  } = props;
  return (
    <MultiSelectFilter<Fleet>
      inputLabel="Active Status"
      labelId="filter-active-fleet-label"
      selectId="filter-active-fleet-select"
      value={value}
      handleSelection={handleSelection}
      options={fleetActiveStatuses}
      disabled={disabled}
      style={style}
    />
  );
};

export default FleetActiveFilter;
