import React from 'react';
import { Box, Typography } from '@mui/material';
import Logo from '../../assets/logo-tvc-pro-driver-web_small.png';

interface MigrationLogoProps {
  headerText?: React.ReactNode;
}
const MigrationLogo = (props: MigrationLogoProps) => {
  const { headerText } = props;
  const styles = {
    logo: {
      height: '40px',
      display: 'inline-block',
    },
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '16px' }}>
      <Box flex={1} display="flex" alignItems="center">
        <img
          alt="TVC logo"
          src={Logo}
          style={styles.logo}
        />
        <Box sx={{ paddingLeft: '18px' }}>
          <Typography variant="h5">{ headerText }</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MigrationLogo;
