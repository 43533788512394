import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PortalApiFuelLoadHistoryDetail } from '../../../shared/models/fuel/PortalApiFuelLoadHistoryDetail';
import { useAppDispatch } from '../../../app/store';
import { useAsyncErrorHandler } from '../../../shared/hooks/useTvcAppInsights';
import { retrievePendingLoadsForFleetThunk, selectPendingLoads, selectPendingLoadsLoading } from '../../fuel/efsSlice';
import PendingLoads from '../../../shared/components/fuel/PendingLoads';
import FuelActivityDetails from '../../fuel/FuelActivityDetails';
import FuelLoadDetails from '../../fuel/FuelLoadDetails';
import { useFuelApi } from '../../../services/api/hooks/useFuelApi';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import { HistoryMatrixId } from '../../../shared/models/fuel/HistoryMatrixId';
import { useCommon } from '../../../shared/hooks/useCommon';

interface FleetPendingLoadsProps {
  fleet: Fleet;
  usePaperTableContainer?: boolean;
  maxScrollContainerHeight?: number | 'unset';
  showBottomOverlay?: boolean;
  hideHeader?: boolean;
  testId?: string;
}

const FleetPendingLoads = (props: FleetPendingLoadsProps) => {
  const {
    fleet,
    usePaperTableContainer = false,
    maxScrollContainerHeight = 'unset',
    showBottomOverlay,
    testId,
  } = props;

  const dispatch = useAppDispatch();
  const { config } = useCommon();

  const [isActivityDetailsOpen, setIsActivityDetailsOpen] = useState(false);
  const [activityDetailsAreLoading, setActivityDetailsAreLoading] = useState(false);
  const [loadDetails, setLoadDetails] = useState<PortalApiFuelLoadHistoryDetail>();
  const { handleError } = useAsyncErrorHandler();
  const { postFuelLoadHistoryDetails } = useFuelApi();

  const isLoadingPendingLoads = useSelector(selectPendingLoadsLoading);
  const pendingLoads = useSelector(selectPendingLoads);

  useEffect(() => {
    try {
      dispatch(retrievePendingLoadsForFleetThunk(fleet.id));
    } catch (err) {
      handleError(err);
    }
  }, []);

  const onPendingLoadClick = async (id: HistoryMatrixId) => {
    setIsActivityDetailsOpen(true);
    setActivityDetailsAreLoading(true);

    try {
      setLoadDetails(undefined);
      const details = await postFuelLoadHistoryDetails(id);
      setLoadDetails(details);
    } catch (error) {
      handleError(error);
    } finally {
      setActivityDetailsAreLoading(false);
    }
  };

  return (
    <>
      <PendingLoads
        usePaperTableContainer={usePaperTableContainer}
        pendingLoads={pendingLoads}
        isLoading={isLoadingPendingLoads}
        onPendingLoadClick={onPendingLoadClick}
        maxScrollContainerHeight={maxScrollContainerHeight}
        showBottomOverlay={showBottomOverlay}
        testId={testId}
        hideHeader
        showCouponUsed={config.REACT_APP_TVC_ALLOW_FLEET_COUPONS}
      />
      <FuelActivityDetails
        open={isActivityDetailsOpen}
        isBusy={activityDetailsAreLoading}
        onClose={() => setIsActivityDetailsOpen(false)}
        detailsType={loadDetails ? `${loadDetails.type} Load` : ''}
      >
        {loadDetails && <FuelLoadDetails details={loadDetails} isPending />}
      </FuelActivityDetails>
    </>
  );
};

export default FleetPendingLoads;
