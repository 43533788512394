import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useProfile } from '../../features/auth/hooks/useProfile';
import { useCommon } from '../hooks/useCommon';
import { useHelmetTitle } from '../hooks/useHelmetTitle';

interface HelmetTitleProps {
  subTitle?: string;
}

const getBarredPrefix = (v: string | undefined) => (v ? ` | ${v}` : '');

const HelmetTitle = (props: HelmetTitleProps) => {
  const { subTitle } = props;
  const { rootTitle } = useHelmetTitle();
  const { config } = useCommon();
  const { userProfile } = useProfile();

  return (
    <Helmet>
      <title>{`${config.REACT_APP_TVC_TITLE}${userProfile?.isEmployee ? ' (EMPLOYEE)' : ''}${getBarredPrefix(rootTitle)}${getBarredPrefix(subTitle)}`}</title>
    </Helmet>
  );
};

export default HelmetTitle;
