import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import { filterOptions } from '../../../shared/components/search-filter/filterOptions';
import { userFuelCardStatuses } from '../search/FuelCardStatusFilter';
import { userLoginStatuses } from '../search/LoginStatusFilter';
import { userMembershipStatuses } from '../search/MembershipStatusFilter';
import { userLegalStatuses } from '../search/UserLegalStatusFilter';

export const useUserFiltering = () => {
  const [selectedUserLoginTypes, setSelectedUserLoginTypes] = useState<string[]>([]);
  const [selectedUserMembershipTypes, setSelectedUserMembershipTypes] = useState<string[]>([]);
  const [selectedUserFuelCardTypes, setSelectedUserFuelCardTypes] = useState<string[]>([]);
  const [selectedUserLegalStatuses, setSelectedUserLegalStatuses] = useState<string[]>([]);

  const fuelCardStatusFilter = (profile: PortalApiUser) => filterOptions<PortalApiUser>(profile, userFuelCardStatuses, selectedUserFuelCardTypes);

  const loginStatusFilter = (profile: PortalApiUser) => filterOptions<PortalApiUser>(profile, userLoginStatuses, selectedUserLoginTypes);

  const membershipStatusFilter = (profile: PortalApiUser) => filterOptions<PortalApiUser>(profile, userMembershipStatuses, selectedUserMembershipTypes);

  const userLegalStatusFilter = (profile: PortalApiUser) => filterOptions<PortalApiUser>(profile, userLegalStatuses, selectedUserLegalStatuses);

  const clearSearchFilters = () => {
    setSelectedUserLoginTypes([]);
    setSelectedUserMembershipTypes([]);
    setSelectedUserFuelCardTypes([]);
    setSelectedUserLegalStatuses([]);
  };

  const handleUserLoginTypeSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setSelectedUserLoginTypes(value as string[]);
  };

  const handleUserMembershipTypeSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setSelectedUserMembershipTypes(value as string[]);
  };

  const handleUserFuelCardTypeSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setSelectedUserFuelCardTypes(value as string[]);
  };

  const handleUserUserLegalCaseStatusSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setSelectedUserLegalStatuses(value as string[]);
  };

  const filterOptionsAreSelected = selectedUserMembershipTypes.length > 0
    || selectedUserLoginTypes.length > 0
    || selectedUserFuelCardTypes.length > 0
    || selectedUserLegalStatuses.length > 0;

  return {
    selectedUserLoginTypes,
    selectedUserMembershipTypes,
    selectedUserFuelCardTypes,
    selectedUserLegalStatuses,
    handleUserLoginTypeSelected,
    handleUserMembershipTypeSelected,
    handleUserFuelCardTypeSelected,
    handleUserUserLegalCaseStatusSelected,
    clearSearchFilters,
    fuelCardStatusFilter,
    loginStatusFilter,
    membershipStatusFilter,
    userLegalStatusFilter,
    filterOptionsAreSelected,
  };
};
