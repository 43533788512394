import { Button, ButtonProps } from '@mui/material';
import React from 'react';

const EntityActionButton = (props: ButtonProps) => {
  const { children, style, ...rest } = props;

  return (
    <Button
      {...rest}
      size="small"
      variant="text"
      color="primary"
      sx={{
        fontWeight: 'bold',
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

export default EntityActionButton;
