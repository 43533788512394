import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AddPaymentMethod } from '../../shared/types/payment-method/AddPaymentMethod';
import { PaymentMethod } from '../../shared/models/payment-method/PaymentMethod';
import {
  createNonBlockingThunk,
} from '../../services/thunk';
import { useFleetApi } from '../../services/api/hooks/useFleetApi';
import { useUserApi } from '../../services/api/hooks/useUserApi';

interface PaymentMethodsById {
  [key: number]: PaymentMethod;
}

export interface NormalizedPaymentMethods {
  byId: PaymentMethodsById,
  all: number[]
}

export interface PaymentMethodState {
  paymentMethods: NormalizedPaymentMethods,
  paymentMethodsLoading: boolean,
}

const initialState: PaymentMethodState = {
  paymentMethods: {
    byId: {},
    all: [],
  },
  paymentMethodsLoading: false,
};

export const paymentMethodSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setPaymentMethods(state, action: PayloadAction<PaymentMethod[]>) {
      const ids = action.payload.map((pm) => pm.id);
      const pmsById: PaymentMethodsById = {};
      action.payload.forEach((pm) => {
        pmsById[pm.id] = pm;
      });
      state.paymentMethods = {
        byId: pmsById,
        all: ids,
      };
    },
    addPaymentMethod(state, action: PayloadAction<PaymentMethod>) {
      state.paymentMethods.all.push(action.payload.id);
      state.paymentMethods.byId[action.payload.id] = action.payload;
    },
    deletePaymentMethod(state, action: PayloadAction<PaymentMethod>) {
      state.paymentMethods.all = state.paymentMethods.all.filter((pmId: number) => pmId !== action.payload.id);
    },
    updatePaymentMethod(state, action: PayloadAction<PaymentMethod>) {
      const paymentMethodToUpdate = state.paymentMethods.byId[action.payload.id];
      if (paymentMethodToUpdate) {
        state.paymentMethods.byId[action.payload.id] = {
          ...paymentMethodToUpdate,
          ...action.payload,
        };
      }
    },
    setPaymentMethodsLoading(state, action: PayloadAction<boolean>) {
      state.paymentMethodsLoading = action.payload;
    },
  },
});

export const {
  setPaymentMethods,
  addPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  setPaymentMethodsLoading,
} = paymentMethodSlice.actions;

export const selectPaymentMethods = (state: RootState) => state.paymentMethods;

// FLEET
export const retrieveFleetPaymentMethods = (fleetId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setPaymentMethodsLoading(true));
  const { getFleetPaymentMethods } = useFleetApi();
  try {
    const paymentMethods = await getFleetPaymentMethods(fleetId);
    dispatch(setPaymentMethods(paymentMethods));
    dispatch(setPaymentMethodsLoading(false));
    return paymentMethods;
  } catch (err) {
    dispatch(setPaymentMethodsLoading(false));
    throw err;
  }
});

export const deleteFleetPaymentMethodThunk = (paymentMethod: PaymentMethod, fleetId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setPaymentMethodsLoading(true));
  const { deleteFleetPaymentMethod: delPayMethod } = useFleetApi();
  try {
    await delPayMethod(paymentMethod.id, fleetId);
    dispatch(deletePaymentMethod(paymentMethod));
    dispatch(setPaymentMethodsLoading(false));
  } catch (err) {
    dispatch(setPaymentMethodsLoading(false));
    throw err;
  }
});

export const addFleetPaymentMethodToFleetThunk = (paymentMethod: AddPaymentMethod, fleetId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setPaymentMethodsLoading(true));
  const { addPaymentMethodToFleet: addPayMethod } = useFleetApi();
  try {
    await addPayMethod(fleetId, paymentMethod);
    dispatch(retrieveFleetPaymentMethods(fleetId));
  } catch (err) {
    dispatch(setPaymentMethodsLoading(false));
    throw err;
  }
});

// INDIVIDUAL MEMBERSHIPS
export const fetchIndividualPaymentMethods = (userId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setPaymentMethodsLoading(true));
  const { getIndividualPaymentMethods } = useUserApi();
  try {
    const paymentMethods = await getIndividualPaymentMethods(userId);
    dispatch(setPaymentMethods(paymentMethods));
    dispatch(setPaymentMethodsLoading(false));
    return paymentMethods;
  } catch (err) {
    dispatch(setPaymentMethodsLoading(false));
    throw err;
  }
});

export const deleteIndividualMembershipPaymentMethodThunk = (paymentMethod: PaymentMethod, userId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setPaymentMethodsLoading(true));
  const { deleteIndividualMembershipPaymentMethod: delPayMethod } = useUserApi();
  try {
    await delPayMethod(paymentMethod.id, userId);
    dispatch(deletePaymentMethod(paymentMethod));
    dispatch(setPaymentMethodsLoading(false));
  } catch (err) {
    dispatch(setPaymentMethodsLoading(false));
    throw err;
  }
});

export const addIndividualMembershipPaymentMethodToFleetThunk = (paymentMethod: AddPaymentMethod, userId: string) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setPaymentMethodsLoading(true));
  const { addPaymentMethodToIndividualMembership: addPayMethod } = useUserApi();
  try {
    await addPayMethod(userId, paymentMethod);
    dispatch(fetchIndividualPaymentMethods(userId));
  } catch (err) {
    dispatch(setPaymentMethodsLoading(false));
    throw err;
  }
});

export default paymentMethodSlice.reducer;
