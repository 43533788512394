import { getOr } from 'lodash/fp';
import { AxiosResponse } from 'axios';
import { FuelCardFuelLoadResponse } from '../../../shared/models/fuel/FuelCardFuelLoadResponse';
import { LoadSmartCard } from '../../../shared/models/fuel/LoadSmartCard';
import { VoidedLoad } from '../../../shared/models/fuel/VoidedLoad';
import { NameChangeRequestBase } from '../../../shared/models/name-change/NameChangeRequestBase';
import { NameChangeReviewSuccessErrorsResponse } from '../../../shared/models/name-change/NameChangeReviewSuccessErrorsResponse';
import { PendingNameChangeRequest } from '../../../shared/models/name-change/PendingNameChangeRequest';
import { ReviewableNameChangeRequest } from '../../../shared/models/name-change/ReviewableNameChangeRequest';
import { UserNameChangeRequests } from '../../../shared/models/name-change/UserNameChangeRequests';
import { PaginatedCollection } from '../../../shared/models/other/PaginatedCollection';
import { PortalApiUpdatePermissions } from '../../../shared/models/permissions/PortalApiUpdatePermissions';
import { AddEmployee } from '../../../shared/models/user/AddEmployee';
import { AddIndvidualLoginResponse } from '../../../shared/models/user/AddIndvidualLoginResponse';
import { EmployeeDetails } from '../../../shared/models/user/EmployeeDetails';
import { PortalApiEmployee } from '../../../shared/models/user/PortalApiEmployee';
import { FleetUserDetailModel } from '../../../shared/models/user/FleetUserDetailModel';
import { PortalApiProfile } from '../../../shared/models/user/PortalApiProfile';
import { PortalUserBody } from '../../../shared/models/user/PortalUserBody';
import { Profile } from '../../../shared/models/user/Profile';
import { ResetUserPassword } from '../../../shared/models/user/ResetUserPassword';
import { SetUserStatus } from '../../../shared/models/user/SetUserStatus';
import { UpdateLoginInfo } from '../../../shared/models/user/UpdateLoginInfo';
import { UserSearchModel } from '../../../shared/models/user/UserSearchModel';
import { parsePendingLoad, parseVoidedLoad } from '../../../shared/utilities/fuelHelpers';
import { mapPortalApiFleetUser, portalApiProfileToProfle } from '../../../shared/utilities/userHelpers';
import { initAxiosInstance } from './utils';
import { PendingLoad } from '../../../shared/models/fuel/PendingLoad';
import { AddPaymentMethod } from '../../../shared/types/payment-method/AddPaymentMethod';
import { PortalApiPaymentMethod } from '../../../shared/models/payment-method/PortalApiPaymentMethod';
import {
  apiPaymentMethodToFrontendPaymentMethod, getAddPaymentMethodBody,
} from '../../../shared/utilities/paymentMethodHelpers';
import { PaymentMethod } from '../../../shared/models/payment-method/PaymentMethod';
import { SignIn } from '../../../shared/models/user/SignIn';
import { NextAvailableFuelLoadModel } from '../../../shared/models/fuel/NextAvailableFuelLoadModel';
import { CarrierSimpleInfo } from '../../../shared/models/other/CarrierSimpleInfo';
import { ContractFuelLoadResponse } from '../../../shared/models/fuel/ContractFuelLoadResponse';
import { LoadIndividualCarrierContract } from '../../../shared/models/fuel/LoadIndividualCarrierContract';

export const useUserApi = () => {
  const portalClient = initAxiosInstance();

  const loadSmartCard = (
    props: LoadSmartCard,
  ) => portalClient.post<FuelCardFuelLoadResponse>('user/card/load', {
    accountId: props.paymentMethodId,
    feeId: props.processingFee.id,
    loadFeePercentage: props.loadFeePercentage,
    loadFeeMinimum: props.loadFeeMinimum,
    expediteFeePercentage: props.processingFee.percentage,
    expediteFeeDays: props.processingFee.days,
    estimatedFundsAvailability: props.processingFee.availibilityDate,
    loadAmount: props.fundsToAdd,
    feeAdjustmentAmount: props.feeAdjustmentAmount,
    feeAdjustmentReason: props.feeAdjustmentReason,
    fuelCardId: props.fuelCardId,
    couponUsageId: props.couponUsageId,
  });

  const getPendingLoadsForIndividualFuelCard = async (fuelCardId: number) => {
    const { data: pendingLoads } = await portalClient.get<PendingLoad[]>(`user/card/${fuelCardId}/pendingLoads`);

    const parsedPendingLoads: PendingLoad[] = pendingLoads.map(parsePendingLoad);

    return parsedPendingLoads;
  };

  const addPaymentMethodToIndividualMembership = async (
    userId: string,
    payment: AddPaymentMethod,
  ) => {
    const { data } = await portalClient.post<any, AxiosResponse<PortalApiPaymentMethod>>('/user/paymentMethod', { userId, isActive: true, ...getAddPaymentMethodBody(payment) });

    return data;
  };

  const getIndividualPaymentMethods = async (
    userId: string,
  ): Promise<PaymentMethod[]> => {
    const { data: paymentMethods } = await portalClient.get<PortalApiPaymentMethod[]>('user/paymentmethod/all', {
      params: {
        userId,
        includeUnauthorized: true,
      },
    });

    return paymentMethods.map<PaymentMethod>(apiPaymentMethodToFrontendPaymentMethod);
  };

  // API: SetActiveUser
  const postSetUserActiveStatus = (userInfo: SetUserStatus) => portalClient.post<void>('/user/setActive', null, {
    params: {
      ...userInfo,
    },
  }).then((resp) => resp.data);

  // API: ResetPassword
  const postResetUserPassword = ({ userId }: ResetUserPassword) => portalClient.post<void>('/user/resetPassword', {
    forceChangePasswordNextSignIn: true,
    userId,
  }).then((resp) => resp.data);

  // API: GetCardVoidedLoads
  const getVoidedLoadsForUser = async (fuelCardId: number) => {
    const { data: voidedLoads } = await portalClient.get<VoidedLoad[]>(
      `user/card/${fuelCardId}/voidedLoads`,
    );

    const parsedVoidedLoads: VoidedLoad[] = voidedLoads.map(parseVoidedLoad);

    return parsedVoidedLoads;
  };

  // API: GetFleetUserDetails
  const getFleetUsers = async (fleetId: string) => {
    const { data: users } = await portalClient.get<FleetUserDetailModel[]>(
      `/user/details/fleet/${fleetId}`,
    );
    return users.map(mapPortalApiFleetUser);
  };

  // API: exportFleetUserDetails
  const exportFleetUsers = async (fleetId: string, fleetUserIds: string[]): Promise<Blob> => {
    const { data } = await portalClient.post<Blob>(`/user/details/fleet/${fleetId}/export`, { fleetUserIds }, { responseType: 'blob' });
    return data;
  };

  // API: GetAllUsers
  const postGetAllUsers = (userSearchModel: UserSearchModel) => portalClient.post<PaginatedCollection<PortalApiProfile>>('/user/all', userSearchModel);

  // wrapper around postGetAllUsers
  const getEmployees = async (): Promise<Profile[]> => {
    const { data: employees } = await postGetAllUsers({
      isEmployee: true,
    });

    return employees.items.map(portalApiProfileToProfle);
  };

  // wrapper around postGetAllUsers
  const getUserProfile = async (userId: string, fleetId?: string): Promise<Profile | undefined> => {
    const { data: users } = await postGetAllUsers({
      userId,
      fleetId,
    });

    return users.items.length === 1 ? portalApiProfileToProfle(users.items[0]) : undefined;
  };

  // API: GetProfile
  const getLoggedInUserProfile = async (): Promise<Profile> => {
    const { data: profile } = await portalClient.get<PortalApiProfile>(
      '/user/profile',
    );

    return portalApiProfileToProfle(profile);
  };

  // API: SearchEmployees
  const getSearchEmployees = (name: string): Promise<PortalApiEmployee[]> => portalClient.get<PortalApiEmployee[]>('/user/searchEmployees', {
    params: {
      name,
      checkAzureB2C: true,
    },
  })
    .then(getOr([], 'data'));

  // API: AddUpdatePortalUser
  const postAddEmployee = (info: AddEmployee) => portalClient
    .post('/user/addupdate', {
      ...info,
      isEmployee: true,
    })
    .then((resp) => resp.data);

  // API: UpdateUserPermissions
  const postUpdateUserPermissions = (info: PortalApiUpdatePermissions) => portalClient.post('/user/permissions', info).then((resp) => resp.data);

  // API: GetEmailExists
  const getCheckIfEmailAlreadyExists = async (email: string) => {
    const { data: isAvailable } = await portalClient.get<boolean>(
      '/user/emailExists',
      {
        params: {
          email,
        },
      },
    );

    return isAvailable;
  };

  // API: GetEmployeePhoto
  const getEmployeePhoto = async (email: string) => {
    const { data } = await portalClient.get<Blob>(
      `/user/photo/employee/${email}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  };

  // API GetEmployeeDetails
  const getEmployeeDetails = async (email: string) => {
    const { data } = await portalClient.get<EmployeeDetails>(`/user/employee/${email}/details`);
    return data;
  };

  // API: AddUpdatePortalUser
  const postAddIndividualMembershipLogin = (info: PortalUserBody) => portalClient.post<AddIndvidualLoginResponse>('/user/addupdate', {
    ...info,
    isEmployee: false,
  })
    .then((resp) => resp.data);

  // API: UpdateUserEmail
  const postUpdateIndividualLoginInfo = async (userId: string, loginInfo: UpdateLoginInfo) => {
    const { data } = await portalClient.post<any>('/user/email', {
      userId,
      ...loginInfo,
    });

    return data;
  };

  // API: GetNameChangeRequest
  const getIndividualNameChangeRequest = async (userId: string) => {
    const { data } = await portalClient.get<UserNameChangeRequests>(`/user/namechangerequest/${userId}`);

    return data;
  };

  // API: UpdatePortalUserFirstLastName
  const postAddIndividualNameChangeRequest = async (userId: string, nameChangeRequest: NameChangeRequestBase) => {
    await portalClient.post<any>('/user/nameChangeRequest', {
      userId,
      ...nameChangeRequest,
    });
  };

  // API: DeleteNameChangeRequest
  const deleteIndividualNameChangeRequest = (userId: string) => portalClient.delete(`/user/namechangerequest/${userId}`);

  // API: GetNameChangeRequests
  const getPendingNameChangeRequestsForTvcAdmin = async () => {
    const { data } = await portalClient.get<PendingNameChangeRequest[]>('/user/namechangerequests');

    return data;
  };

  // API: ApproveFleetUserFirstLastNameChangeRequest
  const postApproveIndividualNameChangeRequest = async (reviews: ReviewableNameChangeRequest[]) => {
    const { data } = await portalClient.post<NameChangeReviewSuccessErrorsResponse>(
      '/user/nameChangeRequest/approve', reviews,
    );

    return data;
  };

  // API: DeletePaymentMethod
  const deleteIndividualMembershipPaymentMethod = async (
    paymentMethodId: number,
    userId: string,
  ): Promise<void> => {
    await portalClient.delete('user/paymentmethod', {
      params: {
        userId,
        accountId: paymentMethodId,
      },
    });
  };

  const getSignIns = async (): Promise<SignIn[]> => {
    const { data } = await portalClient.get<SignIn[]>('/user/signIns');
    return data;
  };

  const getSignInsForUser = async (azureId: string): Promise<SignIn[]> => {
    const { data } = await portalClient.get<SignIn[]>(`/user/signIns/${azureId}`);
    return data;
  };

  // API: GetNextAvailableFundingDate
  const getSmartCardNextAvailableFundingDate = async (fuelCardId: number): Promise<NextAvailableFuelLoadModel> => {
    const { data } = await portalClient.get<NextAvailableFuelLoadModel>(`/user/card/${fuelCardId}/nextAvailableFundingDate`);
    return data;
  };

  const getUserProMilesSsoToken = async (userId: string) => {
    const { data } = await portalClient.get<any, AxiosResponse<string>>(
      `/user/ProMilesToken/${userId}`,
    );

    return data;
  };

  // API: GetCarrierInfoByName
  const postFmcsaSearchByName = async (searchText: string): Promise<CarrierSimpleInfo[]> => {
    const { data } = await portalClient.post<CarrierSimpleInfo[]>(
      '/user/carrierInfoByName',
      {
        searchText,
      },
    );

    return data;
  };

  // API: GetCarrierInfoByDotNumber
  const getFmcsaSearchByDot = async (dotNumber: number): Promise<CarrierSimpleInfo[]> => {
    const { data } = await portalClient.get<CarrierSimpleInfo[]>(
      `/user/carrierInfoByDotNumber?dotNumber=${dotNumber}`,
    );

    return data;
  };

  const postAddMemberDrivesFor = async (memberId: number, companies: CarrierSimpleInfo[]) => {
    const { data } = await portalClient.post(
      `/user/memberDrivesFor/${memberId}`,
      {
        companies,
      },
    );

    return data;
  };

  // API: DeleteMemberDrivesFor
  const deleteMemberDrivesFor = (memberId: number, dotNumber: number) => portalClient.delete(`/user/memberDrivesFor/${memberId}/${dotNumber}`);

  // BOCA STUFF
  const getPendingLoadsForIndividualCarrier = async (userId: string) => {
    const { data: pendingLoads } = await portalClient.get<PendingLoad[]>('user/contract/pendingLoads', {
      params: {
        userId,
      },
    });

    const parsedPendingLoads: PendingLoad[] = pendingLoads.map(parsePendingLoad);

    return parsedPendingLoads;
  };

  const getVoidedLoadsIndividualCarrier = async (userId: string) => {
    const { data: voidedLoads } = await portalClient.get<VoidedLoad[]>('user/contract/voidedLoads', {
      params: {
        userId,
      },
    });

    const parsedVoidedLoads: VoidedLoad[] = voidedLoads.map(parseVoidedLoad);

    return parsedVoidedLoads;
  };

  const getIndividualCarrierNextAvailableFundingDate = async (userId: string): Promise<NextAvailableFuelLoadModel> => {
    const { data } = await portalClient.get<NextAvailableFuelLoadModel>(`user/contract/${userId}/nextAvailableFundingDate`);
    return data;
  };

  const postIndividualCarrierContract = (
    props: LoadIndividualCarrierContract,
  ) => portalClient.post<ContractFuelLoadResponse>('user/contract/load', {
    userId: props.userId,
    accountId: props.paymentMethodId,
    feeId: props.processingFee.id,
    loadFeePercentage: props.loadFeePercentage,
    loadFeeMinimum: props.loadFeeMinimum,
    expediteFeePercentage: props.processingFee.percentage,
    expediteFeeDays: props.processingFee.days,
    estimatedFundsAvailability: props.processingFee.availibilityDate,
    loadAmount: props.fundsToAdd,
    feeAdjustmentAmount: props.feeAdjustmentAmount,
    feeAdjustmentReason: props.feeAdjustmentReason,
    couponUsageId: props.couponUsageId,
  });

  return {
    loadSmartCard,
    getPendingLoadsForIndividualFuelCard,
    addPaymentMethodToIndividualMembership,
    getIndividualPaymentMethods,
    postSetUserActiveStatus,
    postResetUserPassword,
    getVoidedLoadsForUser,
    getFleetUsers,
    exportFleetUsers,
    getEmployees,
    getUserProfile,
    getLoggedInUserProfile,
    getSearchEmployees,
    postAddEmployee,
    postUpdateUserPermissions,
    getCheckIfEmailAlreadyExists,
    getEmployeePhoto,
    getEmployeeDetails,
    postAddIndividualMembershipLogin,
    postUpdateIndividualLoginInfo,
    getIndividualNameChangeRequest,
    postAddIndividualNameChangeRequest,
    deleteIndividualNameChangeRequest,
    getPendingNameChangeRequestsForTvcAdmin,
    postApproveIndividualNameChangeRequest,
    deleteIndividualMembershipPaymentMethod,
    getSignIns,
    getSignInsForUser,
    getSmartCardNextAvailableFundingDate,
    getUserProMilesSsoToken,
    postFmcsaSearchByName,
    getFmcsaSearchByDot,
    postAddMemberDrivesFor,
    deleteMemberDrivesFor,

    // BOCA STUFF
    getPendingLoadsForIndividualCarrier,
    getVoidedLoadsIndividualCarrier,
    getIndividualCarrierNextAvailableFundingDate,
    postIndividualCarrierContract,
  };
};
