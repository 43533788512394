import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import {
  createNonBlockingThunk,
} from '../../services/thunk';
import {
  useUnAuthenticatedPortalApi,
} from '../../services/api/hooks/useUnAuthenticatedPortalApi';
import { PortalUserCreationBody } from '../../shared/types/matrix/migration/PortalUserCreationBody';
import { EmailVerificationCodeResponse } from '../../shared/types/matrix/migration/EmailVerificationCodeResponse';
import { MatrixLoginResponse } from '../../shared/types/matrix/migration/MatrixLoginResponse';
import { addAlert } from '../application/applicationSlice';
import { MembersLoginFormData } from './MatrixLoginForm';

export interface MigrationState {
  matrixLoginResponse?: MatrixLoginResponse;
  migrationProcessBusy: boolean;
  codeSent: boolean;
  codeVerified: boolean;
  portalEmail?: string;
  error?: string;
  codeVerificationResponse?: EmailVerificationCodeResponse;
  isReSendingCode: boolean;
  accountCreated: boolean;
}

const initialState: MigrationState = {
  migrationProcessBusy: false,
  codeSent: false,
  codeVerified: false,
  isReSendingCode: false,
  accountCreated: false,
  matrixLoginResponse: undefined,
};

export const migrationSlice = createSlice({
  name: 'migration',
  initialState,
  reducers: {
    setLoginResponse(state, action: PayloadAction<MatrixLoginResponse | undefined>) {
      state.matrixLoginResponse = action.payload;
    },
    setCodeVerificationResponse(state, action: PayloadAction<EmailVerificationCodeResponse | undefined>) {
      state.codeVerificationResponse = action.payload;
    },
    setMigrationBusy(state, action: PayloadAction<boolean>) {
      state.migrationProcessBusy = action.payload;
    },
    setCodeSent(state, action: PayloadAction<boolean>) {
      state.codeSent = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload;
    },
    setPortalEmail(state, action: PayloadAction<string>) {
      state.portalEmail = action.payload;
    },
    setIsResendingVerificationCode(state, action: PayloadAction<boolean>) {
      state.isReSendingCode = action.payload;
    },
    setAccountCreated(state, action: PayloadAction<boolean>) {
      state.accountCreated = action.payload;
    },
  },
});

export const {
  setLoginResponse,
  setMigrationBusy,
  setCodeSent,
  setError,
  setPortalEmail,
  setCodeVerificationResponse,
  setIsResendingVerificationCode,
  setAccountCreated,
} = migrationSlice.actions;

export const selectMigrationResponse = (state: RootState) => state.migration.matrixLoginResponse;
export const selectMigrationBusy = (state: RootState) => state.migration.migrationProcessBusy;
export const selectCodeSent = (state: RootState) => state.migration.codeSent;
export const selectCodeVerified = (state: RootState) => Boolean(state.migration.codeVerificationResponse);
export const selectMigrationError = (state: RootState) => state.migration.error;
export const selectPortalEmail = (state: RootState) => state.migration.portalEmail;
export const selectCodeVerificationResponse = (state: RootState) => state.migration.codeVerificationResponse;
export const selectIsReSending = (state: RootState) => state.migration.isReSendingCode;
export const selectAccountCreated = (state: RootState) => state.migration.accountCreated;

export const submitMatrixLogin = (matrixLoginData: MembersLoginFormData) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setMigrationBusy(true));
  dispatch(setLoginResponse(undefined));
  dispatch(setError(undefined));

  const { attemptMatrixLogin } = useUnAuthenticatedPortalApi();
  try {
    const response = await attemptMatrixLogin(matrixLoginData);

    if (response.success) {
      dispatch(setLoginResponse(response));
    } else {
      dispatch(setError('The credentials provided are not valid'));
    }

    dispatch(setMigrationBusy(false));
  } catch (err) {
    dispatch(setMigrationBusy(false));
    dispatch(setLoginResponse(undefined));
    throw err;
  }
});

export const sendVerificationCodeEmail = (email: string, isResend: boolean = false) => createNonBlockingThunk(async (dispatch) => {
  dispatch(isResend ? setIsResendingVerificationCode(true) : setMigrationBusy(true));

  if (!isResend) {
    dispatch(setCodeSent(false));
  }

  const { sendEmailConfirmationCode } = useUnAuthenticatedPortalApi();

  try {
    await sendEmailConfirmationCode(email);
    dispatch(setPortalEmail(email));
    dispatch(isResend ? setIsResendingVerificationCode(false) : setMigrationBusy(false));
    dispatch(setCodeSent(true));
    dispatch(addAlert({
      message: `Verification code was successfully ${isResend ? 're-' : ''}sent to ${email}`,
      severity: 'success',
      autoHideDuration: 8000,
    }));
  } catch (err) {
    dispatch(setPortalEmail(''));
    dispatch(isResend ? setIsResendingVerificationCode(false) : setMigrationBusy(false));
    if (!isResend) {
      dispatch(setCodeSent(false));
    }
    throw err;
  }
});

export const verifyCode = (email: string, code: number) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setMigrationBusy(true));
  dispatch(setCodeVerificationResponse(undefined));

  const { verifyEmailCode } = useUnAuthenticatedPortalApi();

  try {
    const response = await verifyEmailCode(email, code);

    dispatch(setPortalEmail(email));
    dispatch(setMigrationBusy(false));
    dispatch(setCodeVerificationResponse(response));
  } catch (err) {
    dispatch(setMigrationBusy(false));
    dispatch(setCodeVerificationResponse(undefined));
    throw err;
  }
});

export const createNewTvcPortalAaccount = (createRequest: PortalUserCreationBody) => createNonBlockingThunk(async (dispatch) => {
  dispatch(setMigrationBusy(true));
  dispatch(setAccountCreated(false));
  dispatch(setError(undefined));

  const { createNewPortalAccount } = useUnAuthenticatedPortalApi();
  try {
    await createNewPortalAccount(createRequest);

    dispatch(setAccountCreated(true));
    dispatch(setMigrationBusy(false));
  } catch (err) {
    dispatch(setMigrationBusy(false));

    // can't use useAsyncErrorHandler hook here... quick hack
    if (axios.isAxiosError(err)) {
      if (err.response?.status !== 422) {
        dispatch(setError('There was a problem creating your new TVC Pro-Driver portal account'));
      }
    }

    throw err;
  }
});

export default migrationSlice.reducer;
