import React from 'react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import saveAs from 'file-saver';
import { NameChangeReviewSuccessErrorsResponse } from '../../shared/models/name-change/NameChangeReviewSuccessErrorsResponse';
import { ReviewableNameChangeRequest } from '../../shared/models/name-change/ReviewableNameChangeRequest';
import { PendingNameChangeRequest } from '../../shared/models/name-change/PendingNameChangeRequest';
import { ConfigurationContact } from '../../shared/models/configuration/ConfigurationContact';
import { AppAlertSeverity } from '../../shared/components/AppAlert';
import { MembershipSearchTypeOptions } from '../individual-memberships/DefineMembershipsSearchCriteria';
import { SearchIndividualMemberships } from '../../shared/models/matrix/SearchIndividualMemberships';
import { useUserApi } from '../../services/api/hooks/useUserApi';
import { useMatrixApi } from '../../services/api/hooks/useMatrixApi';
import { useCommonApi } from '../../services/api/hooks/useCommonApi';
import { AppConfiguration } from '../../shared/models/configuration/AppConfiguration';
import { StateProvince } from '../../shared/models/other/StateProvince';
import { AppThunk, RootState } from '../../app/store';

export interface IndividualSearchCriteria {
  criteria: SearchIndividualMemberships;
  searchType: MembershipSearchTypeOptions;
}

interface AppAlert {
  message: React.ReactNode;
  autoHideDuration?: number;
  severity?: AppAlertSeverity;
}

export interface ApplicationState {
  blockingRequests: number;
  currentResultsView: string;
  alert?: AppAlert;
  appInsightsReactPlugin?: ReactPlugin;
  configuration: AppConfiguration;
  individualSearchCriteria?: IndividualSearchCriteria;
  includeInactiveFleets?: boolean;
  pendingNameChangeRequestsLoading?: boolean;
  pendingNameChangeRequests: PendingNameChangeRequest[];
  geographicCollectionsLoading?: boolean;
  stateProvinces: StateProvince[];
  rootHelmetTitle?: string;
  proMilesToken: string;
  esFuelDiscountMapUrl?: string;
}

export const initialApplicationState: ApplicationState = {
  blockingRequests: 0,
  currentResultsView: 'card',
  configuration: {
    maximumLoadLimit: 50000,
    areWeekendsAvailableForScheduledLoad: false,
    fuelCardSavingsAmountBlurb: '',
    contacts: {
      member: {
        contacts: [],
      },
      fleet: {
        contacts: [],
      },
    },
    fileUpLoadInfo: {
      legalDraft: {
        allowableFileTypes: ['application/pdf,image/*'],
        maxSize: 5000000, // 5 MB
        maxNumberOfFiles: 5,
        allowableFileTypesDisplay: ['PDF Files,Image files'],
      },
      legalTrafficTicket: {
        allowableFileTypes: ['image/*'],
        maxSize: 5000000, // 5 MB
        maxNumberOfFiles: 5,
        allowableFileTypesDisplay: ['Image files'],
      },
      paymentMethod: {
        allowableFileTypes: ['image/*'],
        maxSize: 5000000, // 5 MB
        maxNumberOfFiles: 1,
        allowableFileTypesDisplay: ['Image files'],
      },
    },
    maxDrivesForCompanies: 5,
  },
  includeInactiveFleets: false,
  pendingNameChangeRequests: [],
  rootHelmetTitle: '',
  stateProvinces: [],
  proMilesToken: '',
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState: initialApplicationState,
  reducers: {
    incrementBlockingRequests: (state) => {
      state.blockingRequests++;
    },
    decrementBlockingRequests: (state) => {
      state.blockingRequests--;
    },
    setCurrentResultsView: (state, action) => {
      state.currentResultsView = action.payload;
    },
    addAlert: (state, action: PayloadAction<AppAlert>) => {
      state.alert = action.payload;
    },
    setConfiguration: (state, action: PayloadAction<AppConfiguration>) => {
      state.configuration = action.payload;
    },
    setIndividualSearchCriteria: (state, action: PayloadAction<IndividualSearchCriteria>) => {
      state.individualSearchCriteria = action.payload;
    },
    setIncludeInactiveFleets: (state, action: PayloadAction<boolean>) => {
      state.includeInactiveFleets = action.payload;
    },
    setPendingNameChangeRequestsLoading: (state, action: PayloadAction<boolean>) => {
      state.pendingNameChangeRequestsLoading = action.payload;
    },
    setPendingNameChangeRequests: (state, action: PayloadAction<PendingNameChangeRequest[]>) => {
      state.pendingNameChangeRequests = action.payload;
    },
    setStateProvinces: (state, action: PayloadAction<StateProvince[]>) => {
      state.stateProvinces = action.payload;
    },
    setRootHelmetTitle: (state, action: PayloadAction<string>) => {
      state.rootHelmetTitle = action.payload;
    },
    setProMilesToken: (state, action: PayloadAction<string>) => {
      state.proMilesToken = action.payload;
    },
  },
});

export const {
  incrementBlockingRequests,
  decrementBlockingRequests,
  setCurrentResultsView,
  addAlert,
  setConfiguration,
  setIndividualSearchCriteria,
  setIncludeInactiveFleets,
  setPendingNameChangeRequestsLoading,
  setPendingNameChangeRequests,
  setStateProvinces,
  setRootHelmetTitle,
  setProMilesToken,
} = applicationSlice.actions;

// THUNKS
export const saveAsWithSuccessAlert = (data: Blob, fileName: string): AppThunk<Promise<void>> => async (dispatch) => {
  saveAs(data, fileName);
  dispatch(
    addAlert({
      message: fileName,
      severity: 'success',
    }),
  );
};

export const retrievePendingNameChangeRequestsThunk = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { getPendingNameChangeRequestsForTvcAdmin } = useUserApi();
    dispatch(setPendingNameChangeRequests([]));
    dispatch(setPendingNameChangeRequestsLoading(true));
    const pendingRequests = await getPendingNameChangeRequestsForTvcAdmin();
    dispatch(setPendingNameChangeRequestsLoading(false));
    dispatch(setPendingNameChangeRequests(pendingRequests));
  } catch (err) {
    setPendingNameChangeRequestsLoading(false);
    throw err;
  }
};

export const submitPendingNameChangeRequestReviewsThunk = (reviews: ReviewableNameChangeRequest[]): AppThunk<Promise<NameChangeReviewSuccessErrorsResponse>> => async (dispatch) => {
  try {
    const { postApproveIndividualNameChangeRequest } = useUserApi();
    dispatch(setPendingNameChangeRequestsLoading(true));
    const response = await postApproveIndividualNameChangeRequest(reviews);
    dispatch(retrievePendingNameChangeRequestsThunk());
    dispatch(setPendingNameChangeRequestsLoading(false));
    const { errors } = response;
    const hasErrors = errors.length > 0;
    dispatch(
      addAlert({
        message: hasErrors ? 'There were issues with one or more of the name change request reviews that were submitted (some may have succeeded)' : 'Name change request reviews were successfully submitted',
        severity: hasErrors ? 'warning' : 'success',
      }),
    );
    return response;
  } catch (err) {
    setPendingNameChangeRequestsLoading(false);
    throw err;
  }
};

export const fetchAndSaveBlob = (link: string, fileName: string): AppThunk<Promise<void>> => async (dispatch) => {
  const { getBlobViaLink } = useCommonApi();

  try {
    dispatch(incrementBlockingRequests());
    const blob: Blob = await getBlobViaLink(link);
    dispatch(saveAsWithSuccessAlert(blob, fileName));
    dispatch(decrementBlockingRequests());
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const fetchStatesAndProvinces = (): AppThunk<Promise<StateProvince[]>> => async (dispatch) => {
  const { getStatesAndProvinces } = useMatrixApi();

  dispatch(setStateProvinces([]));
  const stateProvince = await getStatesAndProvinces();
  dispatch(setStateProvinces(stateProvince));
  return stateProvince;
};

export const setRootRouteHelmetTitle = (title: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(setRootHelmetTitle(title));
};

export const retrieveProMilesThunk = (proMilesApi: () => Promise<string>): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(setProMilesToken(''));
  const token = await proMilesApi();
  dispatch(setProMilesToken(token));
};

// SELECTORS
export const showOverlay = (state: RootState) => state.application.blockingRequests > 0;
export const selectAppAlert = (state: RootState) => state.application.alert;
export const isListViewSelected = (state: RootState) => state.application.currentResultsView === 'list';
export const selectConfiguration = (state: RootState): AppConfiguration => state.application.configuration;
export const selectFleetGeneralContact = (state: RootState): ConfigurationContact | undefined => state.application.configuration.contacts?.fleet.contacts.find((c) => c.metaName === 'General');
export const selectIndividualSearchCriteria = (state: RootState): IndividualSearchCriteria | undefined => state.application.individualSearchCriteria;
export const selectIncludeInActiveFleets = (state: RootState) => state.application.includeInactiveFleets;
export const selectPendingNameChangeRequestsLoading = (state: RootState) => state.application.pendingNameChangeRequestsLoading;
export const selectPendingNameChangeRequests = (state: RootState) => state.application.pendingNameChangeRequests;
export const selectRootHelmetTitle = (state: RootState) => state.application.rootHelmetTitle;
export const selectProMilesToken = (state: RootState) => state.application.proMilesToken;

export const selectUsStates = (state: RootState) => state.application.stateProvinces?.filter((sp) => sp.country === 'USA');
export const selectCanadianProvinces = (state: RootState) => state.application.stateProvinces?.filter((sp) => sp.country === 'Canada');
export const selectUsStatesAndCanadianProvinces = (state: RootState) => state.application.stateProvinces;
export const selectCountries = (state: RootState) => {
  const uniqueCountries = new Set();
  state.application.stateProvinces.forEach((sp) => {
    uniqueCountries.add(sp.country);
  });
  // @ts-ignore
  const countries: string[] = Array.from(uniqueCountries);
  return countries;
};
export default applicationSlice.reducer;
