import React from 'react';
import { PaymentMethod } from '../../../shared/models/payment-method/PaymentMethod';
import { PaymentMethodType } from '../../../shared/enums/payment-method/PaymentMethodType';
import { currencyFormat } from '../../../shared/utilities/currencyFormat';
import { BankStatus } from './BankStatus';
import { CCStatus } from './CCStatus';

export const PaymentSubheader = ({
  paymentMethod,
}: {
  paymentMethod: PaymentMethod;
}) => {
  let accountNumberFormatted: JSX.Element = <></>;

  switch (paymentMethod.paymentMethodType) {
    case PaymentMethodType.CreditCard:
      accountNumberFormatted = (
        <div>
          <div>{paymentMethod.maskedAccountNumber}</div>
          <div>
            <CCStatus paymentMethod={paymentMethod} />
          </div>
        </div>
      );
      break;
    case PaymentMethodType.BankAccount:
      accountNumberFormatted = (
        <div>
          <div>{paymentMethod.maskedAccountNumber}</div>
          <div>
            <BankStatus paymentMethod={paymentMethod} />
          </div>
          <div>
            Business Account:
            {' '}
            {paymentMethod.isBusinessAccount ? 'Yes' : 'No'}
          </div>
        </div>
      );
      break;
    case PaymentMethodType.Check:
      accountNumberFormatted = <div>{currencyFormat(paymentMethod.limit, paymentMethod.limit - Math.floor(paymentMethod.limit) !== 0 ? 2 : undefined)}</div>;
      break;
    default:
    // code block
  }
  return accountNumberFormatted;
};
