import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import LabelValue from '../LabelValue';
import { LoadLimits } from '../../types/fuel/LoadLimits';
import { currencyFormat } from '../../utilities/currencyFormat';
import UsingDefault from './UsingDefault';
import SkeletonOrContent from '../SkeletonOrContent';
import { Multiplier } from '../../models/fuel/Multiplier';

interface AllowanceDisplayProps {
  limits?: LoadLimits;
  defaultLimits?: LoadLimits;
  defaultMultiplier?: Multiplier;
  canEditLoadLimits?: boolean;
  isConfirm?: boolean;
  isLoading?: boolean;
  containerStyle?: React.CSSProperties;
}
const AllowanceDisplay = (props: AllowanceDisplayProps) => {
  const {
    limits,
    defaultLimits,
    defaultMultiplier,
    isConfirm = false,
    canEditLoadLimits = false,
    isLoading = false,
    containerStyle,
  } = props;
  const [isLimitAmountDefault, setIsLimitAmountDefault] = useState<boolean>(false);
  const [isLimitDaysDefault, setIsLimitDaysDefault] = useState<boolean>(false);
  const [allowanceAmount, setAllowanceAmount] = useState<number>();

  useEffect(() => {
    setIsLimitAmountDefault(false);
    if (limits && defaultLimits && defaultMultiplier) {
      if (limits.useMultiplier) {
        if (!limits.flatLimitAmount) {
          setIsLimitAmountDefault(true);
          if (defaultMultiplier.multiplier !== undefined && defaultMultiplier.multiplicand !== undefined) {
            setAllowanceAmount(
              defaultMultiplier.multiplier * defaultMultiplier.multiplicand,
            );
          }
        } else if (defaultMultiplier.multiplier !== undefined) {
          setAllowanceAmount(
            defaultMultiplier.multiplier * limits.flatLimitAmount,
          );
        } else {
          setAllowanceAmount(limits.flatLimitAmount ?? 0);
        }
      } else {
        setAllowanceAmount(limits.flatLimitAmount ?? 0);
      }

      if (!limits.flatLimitDays) {
        setIsLimitDaysDefault(true);
      }
    }
  }, [
    limits,
    defaultLimits,
    defaultMultiplier,
  ]);

  return (
    <LabelValue
      applyBoxStyles={false}
      containerStyle={isConfirm ? { padding: '16px', paddingRight: '0px' } : undefined}
      label={(
        <div style={{ ...containerStyle }}>
          Allowance Amount
          {isLimitAmountDefault && isLimitDaysDefault && canEditLoadLimits ? (
            <UsingDefault style={{ marginTop: '0px', marginBottom: '0px' }} />
          ) : (
            ''
          )}
        </div>
      )}
      value={(
        <>
          <SkeletonOrContent showSkeleton={isLoading}>
            <Box>
              {currencyFormat(allowanceAmount)}
              {' '}
              over
              {' '}
              {limits?.flatLimitDays ?? defaultLimits?.flatLimitDays}
              {' '}
              calendar
              days
            </Box>
          </SkeletonOrContent>
        </>
      )}
    />
  );
};

export default AllowanceDisplay;
