import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  Menu,
  MenuItem,
  TextareaAutosize,
  Typography,
  Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { parseEnv } from '../../../config';
import { selectPortalProfile } from '../../../features/auth/authReducer';
import EmployeeAvatar from '../../../features/user/EmployeeAvatar';
import {
  getAccessToken,
  signInAuthProvider,
} from '../../../services/authService';
import { useBreakPoints } from '../../hooks/useBreakPoints';
import { getValueOrReplacementValue } from '../../utilities/miscHelpers';

const config = parseEnv();

const ProfileAvatar = () => {
  const theme = useTheme();
  const styles = {
    avatarColor: {
      color: 'white',
    },
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    devMenuButton: {
      marginLeft: '8px',
    },
  };
  const { isLgUp } = useBreakPoints();
  const [devMenuOpen, setDevMenuOpen] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [profileMenuAnchor, setProfileMenuAchor] = useState<HTMLElement>();
  const userProfile = useSelector(selectPortalProfile); // not using useProfile here... race condition with useProfile in Root.tsx
  const closeProfileMenu = () => setProfileMenuAchor(undefined);
  // const { getSignInsForUser } = useUserApi();

  const handleLogout = async () => {
    await signInAuthProvider.logoutRedirect();
    closeProfileMenu();
  };
  const cursorStyles = isLgUp ? { cursor: 'pointer' } : {};
  return (
    <div style={styles.avatarWrapper}>
      {userProfile ? (
        <>
          {userProfile.isEmployee ? (
            <EmployeeAvatar
              title={`${userProfile?.fullName}`}
              employee={userProfile}
              block={false}
              style={{ ...styles.avatarColor, ...cursorStyles }}
              onClick={(e) => setProfileMenuAchor(isLgUp ? e.currentTarget : undefined)}
            />
          ) : (
            <Avatar
              sx={{ ...styles.avatarColor, ...cursorStyles }}
              onClick={(e) => setProfileMenuAchor(isLgUp ? e.currentTarget : undefined)}
            >
              {userProfile?.initials}
            </Avatar>
          )}
        </>
      ) : (
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      )}

      <Hidden lgUp>
        <span style={{ color: theme.palette.grey[700] }}>
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={handleLogout}
          >
            Logout
          </Button>

          {config.NODE_ENV === 'development' && (
            <Button
              variant="text"
              color="inherit"
              size="small"
              sx={styles.devMenuButton}
              onClick={() => {
                setDevMenuOpen(true);
                closeProfileMenu();
              }}
            >
              Dev Menu
            </Button>
          )}
        </span>
      </Hidden>

      <Hidden lgDown>
        <Menu
          open={Boolean(profileMenuAnchor)}
          onBlur={closeProfileMenu}
          anchorEl={profileMenuAnchor}
        >
          {config.NODE_ENV === 'development' && (
            <MenuItem
              onClick={async () => {
                setDevMenuOpen(true);
                closeProfileMenu();
              }}
            >
              Dev Menu
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Hidden>

      {config.NODE_ENV === 'development' && (
        <Dialog
          maxWidth="lg"
          fullWidth
          onClose={() => setDevMenuOpen(false)}
          open={devMenuOpen}
        >
          <DialogTitle>Dev Menu</DialogTitle>
          <DialogContent>
            <Typography sx={{ marginBottom: '0.5em' }}>
              Portal Api Access Token
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                setAccessToken(await getAccessToken());
              }}
            >
              Get Access Token
            </Button>
            <TextareaAutosize
              minRows={5}
              style={{ width: '100%', margin: '2em 0' }}
              value={
                getValueOrReplacementValue(accessToken, 'Click to retrieve access token from azure...')
              }
            />
            <Typography sx={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
              User Permissions for
              {' '}
              {userProfile?.fullName}
            </Typography>
            {userProfile && (
              <pre>{JSON.stringify(userProfile.userPermissions, null, 2)}</pre>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default ProfileAvatar;
