import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import { useProfile } from '../../auth/hooks/useProfile';
import { selectLoggedInUserIsSelectedProfile } from '../../user/userSlice';
import SearchItemProps from '../../../shared/models/other/SearchItemProps';

interface PortalRouteTemplate {
  fallback: string;
  dashboard: string;
  account: string;
  fuel: string;
  legal: string;
}

export const individMembershipClickedRouteTemplates: PortalRouteTemplate = {
  fallback: '/individual-memberships',
  dashboard: '/individual-memberships/{0}/dashboard',
  account: '/individual-memberships/{0}/account',
  fuel: '/individual-memberships/{0}/fuel',
  legal: '/individual-memberships/{0}/legal',
};

export const useIndividualMembershipSearchItem = (props: SearchItemProps<PortalApiUser>) => {
  const { item } = props;

  const { onItemClickedPassItem } = props;
  const { userProfile } = useProfile();
  const userName = `${item.firstName} ${item.lastName}`;

  const history = useHistory();

  const loggedInUserIsSelectedProfile = useSelector(selectLoggedInUserIsSelectedProfile());

  const canAccessDashboard = userProfile?.userPermissions?.canAccessIndividualDashboard || loggedInUserIsSelectedProfile;
  const canAccessAccount = userProfile?.userPermissions?.canManageIndividualPortalUser || loggedInUserIsSelectedProfile;
  const canAccessFuel = userProfile?.userPermissions?.canManageIndividualFuel || loggedInUserIsSelectedProfile;
  const canAccessLegal = userProfile?.userPermissions?.canManageIndividualLegal || loggedInUserIsSelectedProfile;

  const getAppropriateIndividualMembershipClickedRoute = (
    individualMembershipClicked: PortalApiUser | undefined,
  ): string => {
    // order of precendence and available permissions

    // don't let dashboard ever be an "appropriate" clicked option
    if (
      !userProfile?.isEmployee && canAccessDashboard && individualMembershipClicked
    ) {
      return individMembershipClickedRouteTemplates.dashboard.replace(
        '{0}',
        individualMembershipClicked?.userId,
      );
    }

    if (
      canAccessAccount && individualMembershipClicked
    ) {
      return individMembershipClickedRouteTemplates.account.replace(
        '{0}',
        individualMembershipClicked?.userId,
      );
    }

    if (
      canAccessFuel && individualMembershipClicked
    ) {
      return individMembershipClickedRouteTemplates.fuel.replace(
        '{0}',
        individualMembershipClicked.userId,
      );
    }

    if (
      canAccessLegal && individualMembershipClicked
    ) {
      return individMembershipClickedRouteTemplates.legal.replace(
        '{0}',
        individualMembershipClicked.userId,
      );
    }

    return individMembershipClickedRouteTemplates.fallback;
  };

  const navigateToAppropriateIndividualMembershipClickedRoute = () => {
    history.push(getAppropriateIndividualMembershipClickedRoute(item));
  };

  return {
    navigateToAppropriateIndividualMembershipClickedRoute,
    onItemClickedPassItem,
    individualUser: item,
    userName,
  };
};
