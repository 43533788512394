import { Typography } from '@mui/material';
import React from 'react';
import InitialResultsHelpListItem from '../../../shared/components/help/InitialResultsHelpListItem';
import HelpDialog from '../../../shared/components/HelpDialog';

const IndividualMembershipInitialResultSetHelp = () => (
  <HelpDialog tooltipTitle="Click for help about non-fleet memberships search" style={{ top: '-30px', left: '-2px' }}>
    <Typography variant="h5" gutterBottom>
      Non-Fleet Memberships Search
    </Typography>
    <InitialResultsHelpListItem
      headerText="Search"
      initialResultSecondaryText="Define the query that will retrieve the initial result set for non-fleet memberships."
    />

  </HelpDialog>
);

export default IndividualMembershipInitialResultSetHelp;
