import React from 'react';
import { Box } from '@mui/material';
import { isValid as dateIsValid } from 'date-fns';
import LabelValue from './LabelValue';
import { currencyFormat } from '../utilities/currencyFormat';
import ShowHide from './ShowHide';
import TvcSkeleton from './TvcSkeleton';
import { formatTimezone } from '../utilities/dateHelpers';

export interface BalanceProps {
  balance?: number;
  fractionDigits?: number;
  label?: string;
  asOfDate?: Date;
  userCanViewBalance?: boolean;
  isLoading?: boolean;
  emphasize?: boolean;
  helpComponent?: React.ReactNode;
  balanceValueTestID?: string;
}

const Balance = (props: BalanceProps) => {
  const {
    balance,
    fractionDigits = 2,
    label = 'Fuel Balance',
    asOfDate,
    userCanViewBalance = false,
    isLoading = false,
    emphasize = true,
    helpComponent,
    balanceValueTestID,
  } = props;

  return (
    <ShowHide show={userCanViewBalance}>
      {isLoading ? (
        <TvcSkeleton entityType="balance" />
      ) : (
        <Box sx={{ position: 'relative' }}>
          <LabelValue
            emphasize={emphasize}
            label={(
              <span>
                {label}
                {' '}
                {asOfDate && dateIsValid(asOfDate) ? (
                  <span style={{ fontSize: '0.75em', fontStyle: 'italic' }}>
                    {`(as of ${formatTimezone(
                      asOfDate,
                      "MM/dd/yyyy hh:mm aaaaa'm' zzz",
                    )})`}
                  </span>
                )
                  : ''}
              </span>
            )}
            value={<span data-testid={balanceValueTestID}>{balance !== undefined ? currencyFormat(balance, fractionDigits) : ' - '}</span>}
          />
          {helpComponent}
        </Box>
      )}
    </ShowHide>
  );
};

export default Balance;
