import AppsIcon from '@mui/icons-material/Apps';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setCurrentResultsView } from '../../features/application/applicationSlice';

interface ToggleResultsViewButtonsProps {
  isDisabled?: boolean;
}
const ToggleResultsViewButtons = (props: ToggleResultsViewButtonsProps) => {
  let { isDisabled } = props;
  isDisabled = isDisabled === undefined ? false : isDisabled;
  const styles = {
    toggleButtonGroup: {
      marginRight: '8px',
    },
  };
  const dispatch = useDispatch();
  const setChangeView = (
    event: React.MouseEvent<HTMLElement>,
    resultsView: string | null,
  ) => {
    if (resultsView) {
      dispatch(setCurrentResultsView(resultsView));
    }
  };
  const selectedView = useSelector(
    (state: RootState) => state.application.currentResultsView,
  );
  return (
    <ToggleButtonGroup
      value={selectedView}
      exclusive
      onChange={setChangeView}
      aria-label="results view"
      sx={styles.toggleButtonGroup}
    >
      <ToggleButton
        value="card"
        aria-label="card view"
        size="small"
        disabled={isDisabled}
      >
        <AppsIcon />
      </ToggleButton>
      <ToggleButton
        value="list"
        aria-label="list view"
        size="small"
        disabled={isDisabled}
      >
        <FormatAlignJustifyIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleResultsViewButtons;
