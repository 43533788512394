import React from 'react';
import { theme } from '../../../appTheme';
import { PaymentMethod } from '../../../shared/models/payment-method/PaymentMethod';

export const BankStatus = ({
  paymentMethod,
}: {
  paymentMethod: PaymentMethod;
}) => {
  const { isAvailable, isAuthorized, validationError } = paymentMethod;

  return (isAvailable
    ? !isAuthorized ? <span style={{ color: theme.palette.warning.main }}>Awaiting approval. Processing may be delayed.</span> : <></>
    : (
      <span style={{ color: theme.palette.error.main }}>{validationError}</span>
    ));
};
