import { Theme, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Gavel } from '@mui/icons-material';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LocalGasStationTwoToneIcon from '@mui/icons-material/LocalGasStationTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import React, { FC } from 'react';
import { subYears } from 'date-fns';
import { PortalApiUser } from '../models/user/PortalApiUser';
import { theme as appTheme } from '../../appTheme';
import { formatTimezone, getCentralNowDate } from '../utilities/dateHelpers';
import { defaultLegalCaseYearsBack } from '../utilities/legalHelpers';

const activeUserIconColor = appTheme.palette.success.main;

const getIconFill = (theme: Theme, initialCondition: boolean, secondaryCondition: boolean): string => {
  let iconFill: string;

  if (initialCondition) {
    iconFill = secondaryCondition ? theme.palette.success.main : theme.palette.grey[800];
  } else {
    iconFill = theme.palette.action.disabled;
  }

  return iconFill;
};

const getToolTip = (
  initialCondition: boolean,
  secondaryCondition: boolean,
  entityName: string,
  secondaryTrueText: string,
  secondaryFalseText: string,
  initialFalseText: string,
): string => {
  let toolTip: string;

  if (initialCondition) {
    toolTip = `${entityName}: ${secondaryCondition ? secondaryTrueText : secondaryFalseText}`;
  } else {
    toolTip = initialFalseText;
  }

  return toolTip;
};

interface UserIconProps {
  applySpacing?: boolean;
}
interface UserMembershipIconProps extends UserIconProps {
  hasMembership: boolean;
  membershipIsValid: boolean;
  startDate?: string;
}

interface UserFuelCardIconProps extends UserIconProps {
  hasFuelCard: boolean;
  hasActiveFuelCard: boolean;
}

interface UserLoginIconProps extends UserIconProps {
  hasLoginAccount: boolean;
  loginAccountIsActive: boolean;
}

const legalDefaultYearsBackDate = formatTimezone(subYears(getCentralNowDate(), defaultLegalCaseYearsBack), 'MM/dd/yyyy');

const styles = {
  spacing: {
    marginRight: '10px',
  },
};

const UserLoginAccountIcon = (props: UserLoginIconProps) => {
  const { hasLoginAccount, loginAccountIsActive, applySpacing } = props;
  const theme = useTheme();
  const spacing = applySpacing ? styles.spacing : {};
  return (
    <Tooltip title={getToolTip(hasLoginAccount, loginAccountIsActive, 'Login Account', 'ACTIVE', 'DISABLED', 'No login account exists for this user')}>
      <AccountCircleTwoToneIcon
        sx={{
          ...spacing,
          fill: getIconFill(theme, hasLoginAccount, loginAccountIsActive),
        }}
      />
    </Tooltip>
  );
};

const UserMembershipIcon = (props: UserMembershipIconProps) => {
  const {
    hasMembership, membershipIsValid, applySpacing, startDate,
  } = props;
  const theme = useTheme();
  const hasFutureStartDate = Boolean(startDate);
  const spacing = applySpacing ? styles.spacing : {};
  return (
    <Tooltip title={hasFutureStartDate ? `This membership will become active on '${startDate}'` : getToolTip(hasMembership, membershipIsValid, 'Membership', 'ACTIVE', 'INACTIVE', 'No membership exists for this user')}>
      <LocalShippingTwoToneIcon
        sx={{
          ...spacing,
          fill: hasFutureStartDate ? theme.palette.info.main : getIconFill(theme, hasMembership, membershipIsValid),
        }}
      />
    </Tooltip>
  );
};

const UserFuelCardIcon = (props: UserFuelCardIconProps) => {
  const { hasFuelCard, hasActiveFuelCard, applySpacing } = props;
  const theme = useTheme();
  const spacing = applySpacing ? styles.spacing : {};
  return (
    <Tooltip title={getToolTip(hasFuelCard, hasActiveFuelCard, 'Fuel Card', 'ACTIVE', 'INACTIVE', 'There are no fuel cards associated to this user')}>
      <LocalGasStationTwoToneIcon
        sx={{
          ...spacing,
          fill: getIconFill(theme, hasFuelCard, hasActiveFuelCard),
        }}
      />
    </Tooltip>
  );
};

interface UserIconGroupProps extends UserIconProps {
  user: PortalApiUser;
  userCanViewLegal?: boolean;
}

interface UserLegalIconProps {
  numCases?: number;
  hasLegalCases: boolean;
  hasActiveLegalCases: boolean;
}

const UserLegalIcon: FC<UserLegalIconProps> = (props: UserLegalIconProps) => {
  const { hasLegalCases, hasActiveLegalCases } = props;
  const theme = useTheme();
  return (
    <Tooltip title={`${getToolTip(hasLegalCases, hasActiveLegalCases, 'Legal Cases', 'ACTIVE CASES', 'NO ACTIVE CASES', 'There are no legal cases associated to this user')} (cases with a create date greater than ${legalDefaultYearsBackDate})`}>
      <Gavel sx={{
        fill: getIconFill(theme, hasLegalCases, hasActiveLegalCases),
        width: '0.85em',
      }}
      />
    </Tooltip>
  );
};

const UserIconGroup = (props: UserIconGroupProps) => {
  // TODO: Re-visit apply spacing logic (for list views)
  const { user, applySpacing, userCanViewLegal } = props;

  return (
    <>
      <UserLoginAccountIcon
        applySpacing={applySpacing}
        hasLoginAccount={user.hasLogin}
        loginAccountIsActive={!user.isDisabled}
      />
      <UserMembershipIcon
        applySpacing={applySpacing}
        hasMembership={user.hasMembership}
        membershipIsValid={user.hasValidMembership}
        startDate={user.startDate}
      />
      <UserFuelCardIcon
        applySpacing={applySpacing}
        hasFuelCard={user.hasFuelCard}
        hasActiveFuelCard={user.hasActiveFuelCard}
      />

      {userCanViewLegal && (
        <UserLegalIcon
          hasLegalCases={user.hasLegalCase}
          hasActiveLegalCases={user.hasActiveLegalCase}
        />
      )}
    </>
  );
};

export {
  UserLoginAccountIcon,
  UserMembershipIcon,
  UserFuelCardIcon,
  UserLegalIcon,
  UserIconGroup,
  activeUserIconColor,
};
