import React from 'react';
import {
  Grid,
  Slide,
} from '@mui/material';

import { useSelector } from 'react-redux';
import ContentPadding from '../../../shared/components/ContentPadding';
import { slideTimeout } from '../../../shared/transitions/timeouts';
import { TvcSlideDirection } from '../../../shared/types/other/animation';
import { selectConfiguration } from '../../application/applicationSlice';
import ListContact from '../../../shared/components/ListContact';
import ListContactItem from '../../../shared/components/ListContactItem';
import HelmetTitle from '../../../shared/components/HelmetTitle';

interface IndividualContactUsProps {
  direction?: TvcSlideDirection;
}

const IndividualContactUs = (props: IndividualContactUsProps) => {
  const { direction = 'left' } = props;

  const memberContactUs = useSelector(selectConfiguration)?.contacts?.member?.contacts || [];

  return (
    <>
      <HelmetTitle subTitle="Contact Us" />
      <ContentPadding includeTopBottomPadding style={{ flex: '1' }}>
        <Slide direction={direction} in mountOnEnter timeout={slideTimeout}>
          <Grid container spacing={2} xs={12}>

            <Grid
              item
              xs={12}
            >
              {memberContactUs.length > 0
                && (
                  <ListContact
                    headerText="Contact Us"
                  >
                    {
                      memberContactUs.map((mc) => (
                        <ListContactItem
                          itemKey={`contact-${mc.id}`}
                          showAvatar={false}
                          contactName={mc.displayName}
                          email={mc.email}
                          phone={mc.phoneNumber}
                          sms={mc.text}
                        />
                      ))
                    }
                  </ListContact>
                )}

            </Grid>
          </Grid>
        </Slide>
      </ContentPadding>
    </>
  );
};

export default IndividualContactUs;
