import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles/withStyles';
import { SystemStyleObject } from '@mui/system';
import React from 'react';

interface SummarySectionProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  titleStyle?: CSSProperties;
  childrenContainerStyle?: CSSProperties;
  summarySectionStyle?: CSSProperties;
}

const SummarySection = (props: SummarySectionProps) => {
  const theme = useTheme();
  const styles = {
    summarySection: {
      margin: '0px 15px',
      padding: '0px 15x',
      borderLeft: '4px solid whitesmoke',
      marginBottom: '35px',
    },
    summarySectionTitle: {
      ...theme.typography.h5,
      color: theme.palette.primary.dark,
      marginBottom: '8px',
      marginLeft: '15px',
    },
    summaryWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
    },
  };
  const {
    children, title, titleStyle, childrenContainerStyle, summarySectionStyle,
  } = props;
  const sumStyles = summarySectionStyle || {};
  const childContStyles = childrenContainerStyle || {};
  return (
    <>
      {title && (
        <Typography sx={{ ...styles.summarySectionTitle, ...titleStyle }}>{title}</Typography>
      )}
      <Box sx={{ ...styles.summarySection, ...sumStyles }}>
        <Box sx={{ ...styles.summaryWrapper as SystemStyleObject, ...childContStyles }}>{children}</Box>
      </Box>
    </>
  );
};

export default SummarySection;
