import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DetailsAccordion from './DetailsAccordion';
import ProcessSteps, { resolveHasStepError } from './ProcessSteps';

import { ProcessStepArray } from '../models/other/ProcessStepArray';

interface ProcessStepsProps {
  stepArray: ProcessStepArray[];
}

const ProcessStepsArray = (props: ProcessStepsProps) => {
  const { stepArray } = props;
  const theme = useTheme();
  const retStepArray = stepArray.map((sa, index) => {
    const { dateTimeOfSteps, steps } = sa;

    return (
      <DetailsAccordion
        key={`step-${index}`}
        hasError={steps.some((s) => resolveHasStepError(s))}
        // hasSuccess={!steps.some((s) => s.hasError)}
        summaryChildren={(
          <>
            <Typography>{dateTimeOfSteps}</Typography>
          </>
        )}
        detailsChildren={(
          <ProcessSteps steps={steps} style={{ flexBasis: '100%', marginBottom: '12px' }} />
        )}
        detailsStyle={{
          backgroundColor: theme.palette.detailWrapperBgColor.main,
        }}
      />
    );
  });

  return <>{retStepArray}</>;
};

export default ProcessStepsArray;
