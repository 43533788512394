import {
  Box,
  List, ListItem, ListItemText,
} from '@mui/material';
import React from 'react';

interface FilteringHelpListItemProps {
  children?: React.ReactNode;
}
const FilteringHelpListItem = (props: FilteringHelpListItemProps) => {
  const { children } = props;
  return (
    <>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="FILTERING"
          />
        </ListItem>
      </List>
      { children && (
        <Box sx={{ marginLeft: '56px', marginTop: '-20px' }}>
          {children}
        </Box>
      )}
    </>
  );
};

export default FilteringHelpListItem;
