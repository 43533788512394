import { omit } from 'lodash';
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/store';
import { useFuelApi } from '../../../services/api/hooks/useFuelApi';

import { LoadLimits } from '../../../shared/types/fuel/LoadLimits';
import { PortalApiLoadLimit } from '../../../shared/models/fuel/PortalApiLoadLimit';
import { LimitLevel } from '../../../shared/enums/fuel/LimitLevel';
import { useAsyncErrorHandler } from '../../../shared/hooks/useTvcAppInsights';
import { Multiplier } from '../../../shared/models/fuel/Multiplier';
import { currencyFormat } from '../../../shared/utilities/currencyFormat';
import { selectConfiguration } from '../../application/applicationSlice';
import { retrieveContractForFleetThunk } from '../fleetSlice';
import { useFleetApi } from '../../../services/api/hooks/useFleetApi';

export const useLoadLimits = () => {
  const {
    getNewFleetDefaultLimit,
  } = useFleetApi();

  const {
    getFleetSystemDefaultLimits,
  } = useFleetApi();

  const { getUserLoadLimits, getFleetLimits } = useFuelApi();
  const [fleetLimits, setFleetLimits] = useState<LoadLimits>();
  const [fleetInheritedLimits, setFleetInheritedLimits] = useState<LoadLimits>();
  const [multiplierInherited, setMultiplierInherited] = useState<Multiplier>();
  const [fleetDefaultLimits, setFleetDefaultLimits] = useState<LoadLimits>();
  const [fleetDefaultMultiplier, setFleetDefaultMultiplier] = useState<Multiplier>();
  const [newFleetDefaultLimits, setNewFleetDefaultLimits] = useState<LoadLimits>();
  const [newFleetDefaultMultiplier, setNewFleetDefaultMultiplier] = useState<Multiplier>();
  const [appliedLimits, setAppliedLimits] = useState<PortalApiLoadLimit>();
  const [userLimits, setUserLimits] = useState<LoadLimits>();
  const { handleError } = useAsyncErrorHandler();
  const getNewFleetDefaultLimits = async (parentId: string) => {
    const data = await getNewFleetDefaultLimit(parentId);
    setNewFleetDefaultLimits(omit(data, ['multiplier', 'limitNodes']));
    setNewFleetDefaultMultiplier(data.multiplier);
  };
  const dispatch = useAppDispatch();

  const retrieveFleetAppliedLimits = async (fleetId: string) => {
    const data = await dispatch(retrieveContractForFleetThunk(fleetId));
    setAppliedLimits(data);
  };

  const retrieveIndividualLimits = async (userId: string) => {
    const data = await getUserLoadLimits(userId, LimitLevel.inherited);
    setAppliedLimits(data);
  };

  const retrieveFleetLimitDetails = async (fleetId: string) => {
    try {
      // TODO: <Y> Might consider making a new call that returns both
      const data = await getFleetLimits(fleetId);
      const defaultData = await getFleetSystemDefaultLimits(fleetId);

      setFleetDefaultLimits(omit(defaultData, ['multiplier', 'limitNodes']));
      setFleetDefaultMultiplier(defaultData.multiplier);

      const useMultiplier: boolean = Boolean(data.inheritedFleet.useMultiplier);
      setFleetLimits({
        ...data.fleet,
        useMultiplier,
      });

      setUserLimits(data.user);
      setFleetInheritedLimits(data.inheritedFleet);
      setMultiplierInherited(data.multiplier);
      return data;
    } catch (err) {
      handleError(err);
      return undefined;
    }
  };

  const limitAdornmentProps = (
    show: boolean,
    adornment: React.ReactNode,
    position: 'start' | 'end',
  ) => ({
    showAdornment: show,
    adornment,
    adornmentPosition: position,
  });

  const maxContract = (fieldDescription: string) => {
    const configuration = useSelector(selectConfiguration);

    return {
      max: {
        message: `${fieldDescription} must be less than ${currencyFormat(configuration.maximumLoadLimit)}`,
        value: configuration.maximumLoadLimit,
      },
    };
  };

  const nullableStringToNumber = (value?: number) => parseInt(value?.toString() || '', 10);

  const loadMinimumAmountValidate = (
    form: UseFormReturn<Partial<LoadLimits>>,
  ) => (nullableStringToNumber(form.getValues('loadMinimumAmount'))
    >= nullableStringToNumber(form.getValues('loadMaximumAmount'))
    ? 'Minimum Load Amount must be less than Maximum Load Amount'
    : true);

  const loadMaximumAmountValidate = (
    form: UseFormReturn<Partial<LoadLimits>>,
    defaultLimits?: LoadLimits,
  ) => {
    let max = nullableStringToNumber(form.getValues('loadMaximumAmount'));
    let min = nullableStringToNumber(form.getValues('loadMinimumAmount'));
    const minIsDefault = Number.isNaN(min);
    const maxIsDefault = Number.isNaN(max);

    min = minIsDefault
      ? nullableStringToNumber(defaultLimits?.loadMinimumAmount)
      : min;

    max = maxIsDefault
      ? nullableStringToNumber(defaultLimits?.loadMaximumAmount)
      : max;

    return max <= min
      ? `Maximum Load Amount must be greater than ${minIsDefault ? 'default' : ''
      } Minimum Load Amount${minIsDefault ? ` of ${currencyFormat(min)}` : ''
      }`
      : true;
  };

  return {
    // fleet
    getNewFleetDefaultLimits,
    retrieveFleetLimitDetails,
    fleetLimits,
    fleetInheritedLimits,
    fleetDefaultLimits,
    fleetDefaultMultiplier,
    newFleetDefaultLimits,
    newFleetDefaultMultiplier,
    retrieveFleetAppliedLimits,
    multiplierInherited,

    // user
    userLimits,
    retrieveIndividualAppliedLimits: retrieveIndividualLimits,

    // common
    appliedLimits,
    limitAdornmentProps,
    maxContract,
    loadMinimumAmountValidate,
    loadMaximumAmountValidate,
  };
};
