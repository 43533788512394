import { useState } from 'react';
import { useCouponApi } from '../../services/api/hooks/useCouponApi';
import { Coupon } from '../components/forms/LoadFuelFunds';
import { DiscountStatus } from '../enums/coupon/DiscountStatus';
import { ValidationCouponResult } from '../models/coupon/ValidationCouponResult';

export const useCoupons = () => {
  const { validateFleetFuelCoupon, validateUserFuelCoupon } = useCouponApi();
  const [validationCouponResult, setValidationCouponResult] = useState<ValidationCouponResult | undefined | null>();
  const [couponIsValid, setCouponIsValid] = useState<boolean>(true);

  const validateCoupon = async (coupon: Coupon, couponCode:string, fundsToAdd:number) => {
    if (!(coupon && coupon.consumerId)) {
      return null;
    }
    let discount = null;
    if (coupon.isFleet) {
      discount = await validateFleetFuelCoupon(coupon.consumerId, couponCode, fundsToAdd);
    } else {
      discount = await validateUserFuelCoupon(coupon.consumerId, couponCode, fundsToAdd);
    }
    setValidationCouponResult(discount);
    setCouponIsValid(discount.status === DiscountStatus.Valid);
    return discount;
  };

  const clearCoupon = () => {
    setValidationCouponResult(null);
    setCouponIsValid(true);
  };

  return {
    validationCouponResult,
    couponIsValid,
    validateCoupon,
    clearCoupon,
  };
};
