import React from 'react';
import {
  List, ListItem, ListItemText, Typography,
} from '@mui/material';
import { getValueOrReplacementValue } from '../../utilities/miscHelpers';

interface RunAsHelpProps {
  runAsName?: string;
}

const RunAsHelp = (props: RunAsHelpProps) => {
  const { runAsName } = props;

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ marginTop: '24px' }}>
        Scheduled Load "Will Run As"
      </Typography>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            secondary={`This scheduled load will use the load limits and permissions associated with "${getValueOrReplacementValue(runAsName, '<unknown>')}" when it is triggered/processed.`}
          />
        </ListItem>
      </List>
    </>
  );
};

export default RunAsHelp;
