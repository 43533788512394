import { utcToZonedTime, format } from 'date-fns-tz';
import { subMonths, subDays, subYears } from 'date-fns';
import { defaultFleetTransactionHistoryDaysBack, defaultIftaMonthsBack } from './fuelHelpers';
import { defaultLegalCaseYearsBackMax } from './legalHelpers';

export const centralTimeZone = 'America/Chicago';

export const getCentralNowDate = () => {
  const today = new Date(Date.now());
  return utcToZonedTime(today, centralTimeZone);
};

export const toTimeZone = (date: Date, tz: string = centralTimeZone) => utcToZonedTime(date, tz);

export const formatTimezone = (
  date: Date,
  dateFormat: string = 'MM/dd/yyyy zzz',
  timeZone: string = centralTimeZone,
) => format(date, dateFormat, {
  timeZone,
});

export const longDaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const shortDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface FirstFifteenthDisplay {
  day: number,
  display: string;
}

const nth = (d: number) => {
  const dString = String(d);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) return 'th';
  switch (last % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
};

export const getOrdinalDisplay = (day: number): string => `${day}${nth(day)}`;

export const getShortDay = (date: Date) => shortDaysOfWeek[date.getDay()];

export const isWeekendDay = (day: number) => day === 0 || day === 6;

const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

export const formatDateTOyyymmdd = (date: Date): string => [
  date.getFullYear(),
  padTo2Digits(date.getMonth() + 1),
  padTo2Digits(date.getDate()),
].join('-');

export const formatDateTOMMddyyyy = (startDate: Date) => formatTimezone(startDate, 'MM-dd-yyyy');

const getEndDateStringForFileName = (endDate: Date | null | undefined) => (endDate ? formatTimezone(endDate, 'MM-dd-yyyy') : formatTimezone(getCentralNowDate(), 'MM-dd-yyyy'));

const getYearsDateRangeStringForFileName = (startDate: Date | null | undefined, endDate: Date | null | undefined, numberYears: number): string => {
  const start = startDate ? formatDateTOMMddyyyy(startDate) : formatTimezone(subYears(getCentralNowDate(), numberYears), 'MM-dd-yyyy');
  const end = getEndDateStringForFileName(endDate);
  return `_${start}-to-${end}`;
};

const getMonthsDateRangeStringForFileName = (startDate: Date | null | undefined, endDate: Date | null | undefined, numberMonths: number): string => {
  const start = startDate ? formatDateTOMMddyyyy(startDate) : formatTimezone(subMonths(getCentralNowDate(), numberMonths), 'MM-dd-yyyy');
  const end = getEndDateStringForFileName(endDate);
  return `${start}-to-${end}`;
};

const getDaysDateRangeStringForFileName = (startDate: Date | null | undefined, endDate: Date | null | undefined, numberDays: number): string => {
  const start = startDate ? formatDateTOMMddyyyy(startDate) : formatTimezone(subDays(getCentralNowDate(), numberDays), 'MM-dd-yyyy');
  const end = getEndDateStringForFileName(endDate);
  return `${start}-to-${end}`;
};

export const getIftaDateRangeString = (startDate: Date | null | undefined, endDate: Date | null | undefined): string => getMonthsDateRangeStringForFileName(startDate, endDate, defaultIftaMonthsBack);

export const getTransactionDateRangeString = (startDate: Date | null | undefined, endDate: Date | null): string => getDaysDateRangeStringForFileName(startDate, endDate, defaultFleetTransactionHistoryDaysBack);

export const getLegalCasesDateRangeString = (startDate: Date | null | undefined, endDate: Date | null): string => getYearsDateRangeStringForFileName(startDate, endDate, defaultLegalCaseYearsBackMax);
