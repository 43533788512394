import {
  Fade, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText,
} from '@mui/material';
import React, { Key } from 'react';
import { theme } from '../../../appTheme';
import { useBreakPoints } from '../../hooks/useBreakPoints';
import baseGridCardStyle from '../../styles/baseGridCardStyle';
import { fadeTimeout } from '../../transitions/timeouts';
import ListItemDivider from '../ListItemDivider';

interface SearchListItemProps {
  itemKey: Key | null | undefined;
  userCanClickItem?: boolean;
  itemClicked?: () => void;
  borderColor?: string;
  primaryTitle?: React.ReactNode;
  secondaryTitle?: React.ReactNode;
  userCanViewCardActions?: boolean;
  listItemActionChildren?: React.ReactNode;
  hideLastDivider?: boolean;
  listItemStyles?: React.CSSProperties;
  listItemAvatar?: React.ReactElement;
}
const SearchListItem = (props: SearchListItemProps) => {
  const {
    itemKey,
    userCanClickItem,
    itemClicked,
    borderColor = theme.palette.common.black,
    primaryTitle,
    secondaryTitle,
    userCanViewCardActions,
    listItemActionChildren,
    hideLastDivider,
    listItemStyles,
    listItemAvatar,
  } = props;
  const styles = {
    ...baseGridCardStyle,
    noClick: {
      cursor: 'default',
    },
    basePrimaryText: {
      fontWeight: '600',
    },
    secondaryActionXs: {
      right: '4px',
    },
  };
  const { isXsDown } = useBreakPoints();
  const noClickStyles = userCanClickItem ? {} : styles.noClick;
  return (
    <>
      <Fade in timeout={fadeTimeout}>
        <ListItem
          key={`list-item-${itemKey}`}
          button
          disableRipple={!userCanClickItem}
          onClick={userCanClickItem && itemClicked ? itemClicked : undefined}
          sx={{
            borderLeft: `5px solid ${borderColor}`,
            ...listItemStyles,
            ...noClickStyles,
            '& .MuiListItem-secondaryAction': isXsDown ? styles.secondaryActionXs : {},
          }}
        >
          {listItemAvatar && (
            <ListItemAvatar>
              {listItemAvatar}
            </ListItemAvatar>
          )}
          <ListItemText
            primary={primaryTitle}
            secondary={secondaryTitle}
          />
          {userCanViewCardActions && (
            <ListItemSecondaryAction>
              {listItemActionChildren}
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </Fade>
      {!hideLastDivider && <ListItemDivider />}
    </>
  );
};

export default SearchListItem;
