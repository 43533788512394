import {
  flow,
  get,
  map,
  omit,
} from 'lodash/fp';
import { subMonths } from 'date-fns';
import { initAxiosInstance } from './utils';
import { mapPortalApiUser } from '../../../shared/utilities/userHelpers';
import { PortalApiMatrixProduct } from '../../../shared/models/matrix/PortalApiMatrixProduct';
import { MatrixProduct } from '../../../shared/types/matrix/MatrixProduct';
import { PortalApiMembershipSearchUser } from '../../../shared/models/matrix/PortalApiMembershipSearchUser';
import { PortalApiMembers } from '../../../shared/models/matrix/PortalApiMembers';
import { MemberDetails } from '../../../shared/models/matrix/MemberDetails';
import { SearchIndividualMemberships } from '../../../shared/models/matrix/SearchIndividualMemberships';
import { PortalApiMemberOrganization } from '../../../shared/models/matrix/PortalApiMemberOrganization';
import { MatrixOrgNotes } from '../../../shared/models/matrix/MatrixOrgNotes';
import { MembershipBillingModel } from '../../../shared/models/matrix/MembershipBillingModel';
import { PayNowModel } from '../../../shared/models/matrix/PayNowModel';
import { ProductPaymentMethod } from '../../../shared/models/matrix/ProductPaymentMethod';
import { RecurringPaymentInformation } from '../../../shared/models/matrix/RecurringPaymentInformation';
import { getCentralNowDate } from '../../../shared/utilities/dateHelpers';
import { PaginatedCollection } from '../../../shared/models/other/PaginatedCollection';
import { MemberPaymentModel } from '../../../shared/models/billing/MemberPaymentModel';
import { defaultMembershipPaymentHistoryMonthsBack } from '../../../shared/utilities/membershipHelpers';
import { MemberPaymentDetailModel, MemberPaymentDetailModelFrontend } from '../../../shared/models/billing/MemberPaymentDetailModel';
import { apiPaymentMethodToFrontendPaymentMethod } from '../../../shared/utilities/paymentMethodHelpers';
import { StateProvince } from '../../../shared/models/other/StateProvince';
import { MemberCarrierModel } from '../../../shared/models/matrix/MemberCarrierModel';
import { MemberFuelSponsorUpdateModel } from '../../../shared/models/matrix/MemberFuelSponsorUpdateModel';
import { FuelSponsorInfo } from '../../../shared/models/user/FuelSponsorInfo';

const mapPortalApMembershipUser = (membershipUser: PortalApiMembershipSearchUser) => ({
  member: membershipUser.member,
  user: membershipUser.user ? {
    ...mapPortalApiUser(membershipUser.user),
  } : undefined,
});

export const useMatrixApi = () => {
  const portalClient = initAxiosInstance();

  const getOrganizationProducts = (organizationId: number): Promise<MatrixProduct[]> => portalClient
    .get<PortalApiMatrixProduct[]>(`/matrix/products/fleet/organization/${organizationId}`)
    .then(flow(get('data'), map(omit(['isFleet']))));

  const getFleetProducts = (): Promise<MatrixProduct[]> => portalClient
    .get<PortalApiMatrixProduct[]>('/matrix/fleetproducts')
    .then(flow(get('data'), map(omit(['isFleet']))));

  const getMemberDetails = (memberId: number, fleetId?: string): Promise<MemberDetails> => portalClient.get<MemberDetails>(`/matrix/member/details/${memberId}`).then((resp) => {
    const details = resp.data;

    return {
      ...details,
      fleetId,
      dateCreated: details?.dateCreated ? new Date(details.dateCreated) : undefined,
    };
  });

  const postMemberDetails = async (memberDetails: MemberDetails): Promise<MemberDetails> => {
    const { data } = await portalClient.post('/matrix/member/details', {
      ...memberDetails,
    });

    return data;
  };

  const postSearchIndividualMembershipUsers = async (params: SearchIndividualMemberships): Promise<PortalApiMembers> => {
    const { data: users } = await portalClient.post<PortalApiMembers>(
      '/matrix/members', params,
    );
    return {
      count: users.count,
      members: users.members.map(mapPortalApMembershipUser),
    };
  };

  const getMemberOrganizations = async () => {
    const { data: memberOrgs } = await portalClient.get<PortalApiMemberOrganization[]>('/matrix/organizations');

    return memberOrgs;
  };

  const getMatrixOrgNotes = async (orgId: number) => {
    const { data } = await portalClient.get<MatrixOrgNotes>(`matrix/organization/notes/${orgId}`);

    return data;
  };

  const getStatesAndProvinces = async () => {
    const { data } = await portalClient.get<StateProvince[]>('matrix/statesProvinces');

    return data;
  };
  // API: GetMembershipBilling (this is actually outstanding membership dues that are unpaid... used exclusively in PayNow)
  const getPayNowMembershipBilling = async (memberId: number) => {
    const { data } = await portalClient.get<MembershipBillingModel[]>(`matrix/membership/${memberId}/billing`);

    return data;
  };

  const getMatrixCounties = async (stateAbbreviation: string) => {
    const { data } = await portalClient.get<string[]>(`matrix/${stateAbbreviation}/counties`);

    return data.filter((c) => c !== null).map((c) => c.toLowerCase());
  };

  // API: GetRecurringPayments
  const getRecurringPaymentMethod = async (memberId: number) => {
    const { data } = await portalClient.get<RecurringPaymentInformation>(`matrix/membership/${memberId}/billing/recurringPayments`);

    return data;
  };

  // API: PayNow
  const postPayNow = async (payNow: PayNowModel): Promise<void> => {
    await portalClient.post<void>(
      '/matrix/membership/billing/paynow',
      {
        ...payNow,
      },
    );
  };

  // API: UpdateProductPaymentMethod
  const postUpdateRecurringPaymentMethod = async (productPaymentMethod: ProductPaymentMethod): Promise<void> => {
    await portalClient.post<void>(
      '/matrix/membership/billing/update',
      {
        ...productPaymentMethod,
      },
    );
  };

  // API: GetMemberPayments
  const postFetchMemberPayments = async (
    memberId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<PaginatedCollection<MemberPaymentModel>> => {
    const { data } = await portalClient.post<PaginatedCollection<MemberPaymentModel>>('/matrix/membership/payments', {
      memberId,
      startDate: startDate || subMonths(getCentralNowDate(), defaultMembershipPaymentHistoryMonthsBack),
      endDate: endDate || getCentralNowDate(),
    });

    return data;
  };

  // API: GetMemberPaymentDetails
  const getMemberPaymentDetails = async (memberId: number, transactionId: number): Promise<MemberPaymentDetailModelFrontend> => {
    const { data } = await portalClient.get<MemberPaymentDetailModel>(`matrix/membership/${memberId}/payments/${transactionId}`);

    return {
      ...data,
      paymentMethod: apiPaymentMethodToFrontendPaymentMethod(data.accountPickModel),
    };
  };

  const postSetIndividualCarrierId = async (setCarrierInfo: { memberId: number, carrierId: number }): Promise<MemberCarrierModel> => {
    const { data } = await portalClient.post<MemberCarrierModel>(
      '/matrix/member/carrier',
      {
        ...setCarrierInfo,
      },
    );

    return data;
  };

  const deleteIndividualCarrierId = async (memberId: number): Promise<MemberCarrierModel> => {
    const { data } = await portalClient.delete<MemberCarrierModel>(`/matrix/member/carrier/${memberId}`);

    return data;
  };

  const postSetFuelSponsorInfo = async (fuelSponsorInfo: MemberFuelSponsorUpdateModel): Promise<FuelSponsorInfo> => {
    const { data } = await portalClient.post<FuelSponsorInfo>('/matrix/member/fuelSponsor', {
      ...fuelSponsorInfo,
    });

    return data;
  };

  const postUpdateFuelRegistrationLastCreationDate = async (memberId: number): Promise<FuelSponsorInfo> => {
    const { data } = await portalClient.post<FuelSponsorInfo>('/matrix/member/FuelRegistrationDate', {
      memberId,
    });

    return data;
  };

  return {
    getOrganizationProducts,
    getFleetProducts,
    getMemberDetails,
    postMemberDetails,
    postSearchIndividualMembershipUsers,
    getMemberOrganizations,
    getMatrixOrgNotes,
    getStatesAndProvinces,
    getMatrixCounties,
    getPayNowMembershipBilling,
    postPayNow,
    postUpdateRecurringPaymentMethod,
    getRecurringPaymentMethod,
    postFetchMemberPayments,
    getMemberPaymentDetails,
    postSetIndividualCarrierId,
    deleteIndividualCarrierId,
    postSetFuelSponsorInfo,
    postUpdateFuelRegistrationLastCreationDate,
  };
};
