import { styled, TextField } from '@mui/material';
import { theme } from '../../appTheme';

// export interface CustomNumberInputProps extends NumberFormatProps {}
export const DisplayTextField = styled(TextField)({
  '& .Mui-disabled': {
    color: `${theme.palette.common.black} !important`,
    '-webkit-text-fill-color': `${theme.palette.common.black} !important`,
    'text-fill-color': `${theme.palette.common.black} !important`,
    '-moz-text-fill-color': `${theme.palette.common.black} !important`,
    '-o-text-fill-color': `${theme.palette.common.black} !important`,
    '-ms-text-fill-color': `${theme.palette.common.black} !important`,
  },
  '& .MuiInputBase-root': {
    fontSize: 'inherit',
  },
  '& .MuiInput-underline.Mui-disabled:before': {
    borderBottomStyle: 'none',
  },
});
