import React from 'react';
import {
  Box, Fade, Typography,
} from '@mui/material';
import { currencyFormat } from '../../utilities/currencyFormat';
import LabelValue from '../LabelValue';
import { NextAvailableFuelLoadModel } from '../../models/fuel/NextAvailableFuelLoadModel';
import { fadeTimeout } from '../../transitions/timeouts';
import ErrorIconText from '../icon-components/ErrorIconText';
import { useProfile } from '../../../features/auth/hooks/useProfile';
import InfoIconText from '../icon-components/InfoIconText';

interface FundingLimitAvailabilityDisplayProps {
  nextAvailableFundingInfo?: NextAvailableFuelLoadModel;
  show?: boolean;
  availableFundingTestID?: string;
}
const FundingLimitAvailabilityDisplay = (props: FundingLimitAvailabilityDisplayProps) => {
  const { nextAvailableFundingInfo, show = true, availableFundingTestID } = props;
  const { userProfile } = useProfile();
  const isFundingLimitMet = Boolean(nextAvailableFundingInfo?.nextFundingDate);

  if (!show) {
    return <></>;
  }

  return (
    <>
      {
        isFundingLimitMet ? (
          <Fade in style={{ transitionDelay: '200ms' }} timeout={fadeTimeout}>
            <Box>
              <ErrorIconText text="Funding limit has been reached." />
              <Typography variant="body2" color="textSecondary" sx={{ marginLeft: '34px' }}>

                <Box sx={{ marginTop: '8px' }}>
                  {`Your next available funding date is ${nextAvailableFundingInfo?.nextFundingDate}.`}
                </Box>
                <Box>
                  {`${nextAvailableFundingInfo?.availableToFund ? currencyFormat(nextAvailableFundingInfo.availableToFund) : '<amount unknown>'} will be available for funding on this date.`}
                </Box>
                <Box sx={{ marginTop: '8px' }}>
                  Please call our fuel line to discuss funding options.
                </Box>

              </Typography>
              {userProfile?.isEmployee && <InfoIconText text="Funding is not restricted for TVC employees when funding limit has been reached" style={{ marginTop: '16' }} />}
            </Box>
          </Fade>
        )
          : (
            <Fade in style={{ transitionDelay: '200ms' }} timeout={fadeTimeout}>
              <Box>
                <LabelValue
                  label="Available Funding"
                  value={<span data-testid={availableFundingTestID}>{nextAvailableFundingInfo?.availableToFund ? currencyFormat(nextAvailableFundingInfo.availableToFund) : '<amount unknown>'}</span>}
                />
              </Box>
            </Fade>
          )
      }
    </>

  );
};

export default FundingLimitAvailabilityDisplay;
