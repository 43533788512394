import { loadPermissionCache } from '../../features/auth/authUtil';
import { fleetUserPermissionNames } from '../../features/auth/permissionNames';
import { Fleet } from '../models/fleet/Fleet';
import { PortalApiFleet } from '../models/fleet/PortalApiFleet';
import { PortalApiPermission } from '../models/permissions/PortalApiPermission';

export const portalApiFleetToFleet = (
  portalApiFleet: PortalApiFleet,
  permissions: PortalApiPermission[] | null,
  scopePermissions: PortalApiPermission[] | undefined = undefined,
): Fleet => ({
  ...portalApiFleet,
  cachePermissions: loadPermissionCache(
    fleetUserPermissionNames,
    permissions,
    scopePermissions,
  ),
  contractBalanceAsOf: portalApiFleet.contractBalanceAsOf
    ? new Date(portalApiFleet.contractBalanceAsOf)
    : undefined,
  hasActiveFuelCards: Boolean(portalApiFleet.carrierId) && (portalApiFleet.cardCount && portalApiFleet.cardCount.active > 0),
});
