import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Multiplier } from '../../models/fuel/Multiplier';
import { currencyFormat } from '../../utilities/currencyFormat';
import { CommonLimitInputProps } from './interfaces/CommonLimitInputProps';
import LimitInput from './LimitInput';
import { getValueOrReplacementValue } from '../../utilities/miscHelpers';

interface LimitPeriodAmountLimitInputProps extends CommonLimitInputProps {
  onAfterAdornmentClicked?: () => void;
  isDefaultValue?: (fieldValues: any[]) => boolean;
  otherFields?: string[];
  defaultMultiplier?: Multiplier;
}

const LimitPeriodAmountLimitInput = (
  props: LimitPeriodAmountLimitInputProps,
) => {
  const {
    defaultLimits,
    showAdornment = true,
    adornmentIsDisabled = false,
    onAfterAdornmentClicked,
    isDefaultValue,
    otherFields,
    defaultMultiplier,
    ...rest
  } = props;

  const { watch, clearErrors, trigger } = useFormContext();

  const watchUseMultiplier = watch('useMultiplier');
  const watchFlatLimitAmount = watch('flatLimitAmount');

  useEffect(() => {
    if (watchUseMultiplier) {
      clearErrors('flatLimitAmount');
    }
    trigger('flatLimitAmount');
  }, [watchUseMultiplier, watchFlatLimitAmount]);

  return (
    <LimitInput
      label={`Limit Period Amount Allowed - Default is ${getValueOrReplacementValue(currencyFormat(defaultLimits?.flatLimitDays), 'Unlimited')}`}
      {...rest}
      thousandSeparator
      showAdornment={showAdornment}
      adornmentIsDisabled={adornmentIsDisabled}
      onAfterAdornmentClicked={onAfterAdornmentClicked}
      isDefaultValue={isDefaultValue}
      otherFields={otherFields}
      rules={{
        required: watchUseMultiplier
          ? false
          : 'Custom flat limit amount is required',
        validate: {
          useDefault: () => (watchUseMultiplier
            && watchFlatLimitAmount === defaultMultiplier?.multiplicand
            ? `${currencyFormat(
              defaultMultiplier?.multiplicand,
            )} is the default multiplicand. Please click "USE DEFAULT"`
            : true),
        },
      }}
      allowNegative={false}
      name="flatLimitAmount"
    />
  );
};

export default LimitPeriodAmountLimitInput;
