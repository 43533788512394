import React from 'react';
import {
  Box, Fade, Typography, Alert, AlertTitle,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ProcessSteps from './ProcessSteps';
import { ProcessStep } from '../models/other/ProcessStep';

interface FormSubmissionFeedbackProps {
  headerText?: string;
  children?: React.ReactNode;
  show: boolean;
  alertTitle?: string;
  alertText?: string;
  alertSeverity?: 'success' | 'info' | 'warning' | 'error';
  showAlert?: boolean;
  id?: string;
  steps?: ProcessStep[],
}

const FormSubmissionFeedback = (props: FormSubmissionFeedbackProps) => {
  const {
    headerText = 'What would you like to do next?',
    children,
    show,
    alertText,
    alertTitle = 'Success',
    alertSeverity = 'success',
    showAlert = true,
    id,
    steps = [],
  } = props;
  const theme = useTheme();
  const styles = {
    formFeedbackContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '90%',
      margin: '0 auto',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    alert: {
      color: theme.palette.common.white,
    },
  };

  return (
    <Fade in={show} style={{ transitionDelay: '200ms' }} timeout={600}>
      <Box
        display="flex"
        flex={1}
        data-testid={id ? `form-feedback-${id}` : 'form-feedback'}
        flexDirection="column"
      >
        {showAlert && (
          <Alert
            severity={alertSeverity}
            variant="filled"
            sx={{
              ...styles.alert,
              marginBottom: headerText && steps.length < 1 ? '80px' : '20px',
            }}
          >
            <AlertTitle sx={styles.alert}>
              {alertTitle}
            </AlertTitle>
            {alertText}
          </Alert>
        )}
        <Box
          sx={{
            ...styles.formFeedbackContainer,
            justifyContent: headerText ? 'space-evenly' : 'stretch',
          }}
        >
          {steps.length > 0 && (
            <ProcessSteps steps={steps} style={{ flexBasis: '100%', marginBottom: '40px' }} />
          )}
          {headerText && <Typography variant="h6">{headerText}</Typography>}
          {children}
        </Box>
      </Box>
    </Fade>
  );
};

export default FormSubmissionFeedback;
