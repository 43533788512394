import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { useMatrixApi } from '../../services/api/hooks/useMatrixApi';
import { PortalApiMemberOrganization } from '../../shared/models/matrix/PortalApiMemberOrganization';
import { MemberDetails } from '../../shared/models/matrix/MemberDetails';
import { createNonBlockingThunk, createBlockingThunk } from '../../services/thunk';
import { ProductPaymentMethod } from '../../shared/models/matrix/ProductPaymentMethod';
import { addAlert } from '../application/applicationSlice';
import { RecurringPaymentInformation } from '../../shared/models/matrix/RecurringPaymentInformation';
import { PaginatedCollection } from '../../shared/models/other/PaginatedCollection';
import { MemberPaymentModel } from '../../shared/models/billing/MemberPaymentModel';
import { MemberFuelSponsorUpdateModel } from '../../shared/models/matrix/MemberFuelSponsorUpdateModel';
import { FuelSponsorInfo } from '../../shared/models/user/FuelSponsorInfo';
import { updateSelectedProfileFuelSponsorInfo } from '../user/userSlice';

export interface MatrixState {
  memberOrgs: PortalApiMemberOrganization[];
  memberOrgsAreLoading: boolean;
  membershipDetails?: MemberDetails | null;
  membershipDetailsAreLoading: boolean;
  membershipTransactionHistory?: PaginatedCollection<MemberPaymentModel> | undefined;
  membershipTransactionHistoryLoading: boolean;
  memberRecurringPaymentInformation?: RecurringPaymentInformation;
  memberRecurringPaymentInformationLoading: boolean;
  isSubmittingConfirmSalesPersonInfo: boolean;
  isSubmittingNewFuelRegistrationDate: boolean;
}

const initialState: MatrixState = {
  memberOrgs: [],
  memberOrgsAreLoading: false,
  membershipDetails: null,
  membershipDetailsAreLoading: false,
  membershipTransactionHistoryLoading: false,
  memberRecurringPaymentInformationLoading: false,
  isSubmittingConfirmSalesPersonInfo: false,
  isSubmittingNewFuelRegistrationDate: false,
};

export const matrixSlice = createSlice({
  name: 'matrix',
  initialState,
  reducers: {
    setMemberOrgs(state, action: PayloadAction<PortalApiMemberOrganization[]>) {
      state.memberOrgs = action.payload;
    },
    setMemberOrgsAreLoading(state, action: PayloadAction<boolean>) {
      state.memberOrgsAreLoading = action.payload;
    },
    setMembershipDetails(state, action: PayloadAction<MemberDetails | null>) {
      state.membershipDetails = action.payload;
    },
    setMembershipDetailsAreLoading(state, action: PayloadAction<boolean>) {
      state.membershipDetailsAreLoading = action.payload;
    },
    removeMemberOrg(state, action: PayloadAction<number>) {
      state.memberOrgs = state.memberOrgs.filter(
        (mo) => mo.id !== action.payload,
      );
    },
    setMembershipTransactionHistory(state, action: PayloadAction<PaginatedCollection<MemberPaymentModel> | undefined>) {
      state.membershipTransactionHistory = action.payload;
    },
    setMembershipTransactionHistoryIsLoading(state, action: PayloadAction<boolean>) {
      state.membershipTransactionHistoryLoading = action.payload;
    },
    setMemberRecurringPaymentInformation(state, action: PayloadAction<RecurringPaymentInformation | undefined>) {
      state.memberRecurringPaymentInformation = action.payload;
    },
    setMemberRecurringPaymentInformationLoading(state, action: PayloadAction<boolean>) {
      state.memberRecurringPaymentInformationLoading = action.payload;
    },
    setIsSubmittingConfirmSalesPersonInfo(state, action: PayloadAction<boolean>) {
      state.isSubmittingConfirmSalesPersonInfo = action.payload;
    },
    setIsSubmittingNewFuelRegistrationDate(state, action: PayloadAction<boolean>) {
      state.isSubmittingNewFuelRegistrationDate = action.payload;
    },
  },
});

export const {
  setMemberOrgs,
  setMemberOrgsAreLoading,
  removeMemberOrg,
  setMembershipDetails,
  setMembershipDetailsAreLoading,
  setMembershipTransactionHistory,
  setMembershipTransactionHistoryIsLoading,
  setMemberRecurringPaymentInformation,
  setMemberRecurringPaymentInformationLoading,
  setIsSubmittingConfirmSalesPersonInfo,
  setIsSubmittingNewFuelRegistrationDate,
} = matrixSlice.actions;

export const fetchMemberOrgs = createNonBlockingThunk<PortalApiMemberOrganization[]>(async (dispatch) => {
  const { getMemberOrganizations } = useMatrixApi();
  dispatch(setMemberOrgsAreLoading(true));
  const memberOrgs = await getMemberOrganizations();
  dispatch(setMemberOrgs(memberOrgs));
  dispatch(setMemberOrgsAreLoading(false));
  return memberOrgs;
});

export const fetchMembershipDetails = (memberId: number, fleetId?: string) => createNonBlockingThunk<MemberDetails>(async (dispatch) => {
  dispatch(setMembershipDetails(null));
  try {
    const { getMemberDetails } = useMatrixApi();
    dispatch(setMembershipDetailsAreLoading(true));
    const memberDetails = await getMemberDetails(memberId, fleetId);
    dispatch(setMembershipDetails(memberDetails));
    dispatch(setMembershipDetailsAreLoading(false));
    return memberDetails;
  } catch (err) {
    dispatch(setMembershipDetailsAreLoading(false));
    throw err;
  }
});

export const fetchMembershipTransactionHistory = (memberId: number) => createNonBlockingThunk<PaginatedCollection<MemberPaymentModel>>(async (dispatch) => {
  dispatch(setMembershipTransactionHistory(undefined));
  try {
    const { postFetchMemberPayments } = useMatrixApi();
    dispatch(setMembershipTransactionHistoryIsLoading(true));
    const transactionHistory = await postFetchMemberPayments(memberId);
    dispatch(setMembershipTransactionHistory(transactionHistory));
    dispatch(setMembershipTransactionHistoryIsLoading(false));
    return transactionHistory;
  } catch (err) {
    dispatch(setMembershipTransactionHistoryIsLoading(false));
    throw err;
  }
});

export const updateRecurringPaymentMethod = (paymentMethod: ProductPaymentMethod, postFunc?: () => Promise<void>) => createBlockingThunk<void>(async (dispatch) => {
  const { postUpdateRecurringPaymentMethod } = useMatrixApi();
  await postUpdateRecurringPaymentMethod(paymentMethod);

  if (postFunc) {
    await postFunc();
  }

  dispatch(addAlert({
    message: paymentMethod.paymentMethodId ? 'Your payment method for auto pay was successfully updated' : 'Auto pay has been disabled',
    severity: 'success',
    autoHideDuration: 8000,
  }));
});

export const fetchRecurringPaymentMethod = (memberId: number) => createBlockingThunk<RecurringPaymentInformation>(async (dispatch) => {
  const { getRecurringPaymentMethod } = useMatrixApi();
  dispatch(setMemberRecurringPaymentInformationLoading(true));

  try {
    const recurringPaymentMethod = await getRecurringPaymentMethod(memberId);
    dispatch(setMemberRecurringPaymentInformation(recurringPaymentMethod));
    dispatch(setMemberRecurringPaymentInformationLoading(false));
    return recurringPaymentMethod;
  } catch (err) {
    dispatch(setMemberRecurringPaymentInformation(undefined));
    dispatch(setMemberRecurringPaymentInformationLoading(false));
    throw err;
  }
});

export const submitConfirmFuelSalesPersonInfo = (confirmFuelSalesPersonInfo: MemberFuelSponsorUpdateModel) => createNonBlockingThunk<FuelSponsorInfo>(async (dispatch) => {
  const { postSetFuelSponsorInfo } = useMatrixApi();
  dispatch(setIsSubmittingConfirmSalesPersonInfo(true));

  try {
    const responseFuelSponsorInfo = await postSetFuelSponsorInfo(confirmFuelSalesPersonInfo);
    dispatch(updateSelectedProfileFuelSponsorInfo(responseFuelSponsorInfo));
    return responseFuelSponsorInfo;
  } catch (err) {
    dispatch(setMemberRecurringPaymentInformation(undefined));
    throw err;
  } finally {
    dispatch(setIsSubmittingConfirmSalesPersonInfo(false));
  }
});

export const submitSetFuelRegistrationDate = (memberId: number) => createNonBlockingThunk<FuelSponsorInfo>(async (dispatch) => {
  const { postUpdateFuelRegistrationLastCreationDate } = useMatrixApi();
  dispatch(setIsSubmittingNewFuelRegistrationDate(true));

  try {
    const responseFuelSponsorInfo = await postUpdateFuelRegistrationLastCreationDate(memberId);
    dispatch(updateSelectedProfileFuelSponsorInfo(responseFuelSponsorInfo));
    dispatch(
      addAlert({
        message: 'Fuel registration last creation date has been successfully updated.',
        severity: 'success',
      }),
    );

    return responseFuelSponsorInfo;
    // eslint-disable-next-line no-useless-catch
  } catch (err) {
    throw err;
  } finally {
    dispatch(setIsSubmittingNewFuelRegistrationDate(false));
  }
});

export const selectMemberOrgs = (state: RootState) => state.matrix.memberOrgs;
export const selectMemberOrgsAreLoading = (state: RootState) => state.matrix.memberOrgsAreLoading;

export const selectMembershipDetails = (state: RootState) => state.matrix.membershipDetails;
export const selectMembershipDetailsAreLoading = (state: RootState) => state.matrix.membershipDetailsAreLoading;
export const selectRecurringPaymentMethod = (state: RootState) => state.matrix.memberRecurringPaymentInformation;
export const selectRecurringPaymentMethodLoading = (state: RootState) => state.matrix.memberRecurringPaymentInformationLoading;

export const selectMembershipTransactionHistory = (state: RootState) => state.matrix.membershipTransactionHistory;
export const selectMembershipTransactionHistoryLoading = (state: RootState) => state.matrix.membershipTransactionHistoryLoading;

export const selectIsSubmittingConfirmSalesPersonInfo = (state: RootState) => state.matrix.isSubmittingConfirmSalesPersonInfo;
export const isSubmittingNewFuelRegistrationDate = (state: RootState) => state.matrix.isSubmittingNewFuelRegistrationDate;

export default matrixSlice.reducer;
