import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface SummaryProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const Summary = (props: SummaryProps) => {
  const theme = useTheme();
  const styles = {
    rootBox: {
      display: 'flex',
      padding: '15px 0px',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
  };
  const { children, style } = props;
  return (
    <Box sx={{ ...styles.rootBox, ...style }}>
      {children}
    </Box>
  );
};

export default Summary;
