import React from 'react';
import {
  Badge,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PageviewIcon from '@mui/icons-material/Pageview';

interface InitialResultSetButtonProps {
  onClick: () => void;
  recordCount?: number;
  showBadge?: boolean;
  buttonTitle?: string;
  helpComponent?: React.ReactNode;
}
const InitialResultSetButton = (props: InitialResultSetButtonProps) => {
  const {
    onClick,
    recordCount = 0,
    showBadge = false,
    buttonTitle = 'Initial Result Set',
    helpComponent,
  } = props;
  const theme = useTheme();
  return (
    <>
      <Box display="flex" sx={{ marginTop: '18px', marginLeft: '18px', position: 'relative' }} alignItems="center">
        <Tooltip
          title={`${showBadge ? 'Update' : 'Define'} search criteria for ${buttonTitle.toLowerCase()}`}
          aria-label={`${showBadge ? 'update' : 'define'} ${buttonTitle.toLowerCase()} criteria`}
        >
          <Badge color="primary" variant="dot" invisible={!showBadge}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: theme.palette.grey[700],
              }}
              onClick={onClick}
              startIcon={<PageviewIcon />}
            >
              { buttonTitle }
            </Button>
          </Badge>
        </Tooltip>
        <Box sx={{ marginLeft: '14px' }}>
          { showBadge && (
            <Typography variant="caption">
              {recordCount }
              {' '}
              records
            </Typography>
          )}
        </Box>
        { helpComponent }
      </Box>
    </>
  );
};

export default InitialResultSetButton;
