import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonLimitInputProps } from './interfaces/CommonLimitInputProps';
import LimitInput from './LimitInput';
import { getValueOrReplacementValue } from '../../utilities/miscHelpers';

const LimitPeriodLimitInput = (props: CommonLimitInputProps) => {
  const name = 'flatLimitDays';
  const {
    defaultLimits,
    showAdornment = true,
    adornmentIsDisabled = false,
    ...rest
  } = props;

  const { watch, trigger } = useFormContext();
  const watchLoadLimitDays = watch(name);
  useEffect(() => {
    if (watchLoadLimitDays) {
      trigger(name);
    }
  }, [watchLoadLimitDays]);

  return (
    <LimitInput
      label={`Limit Period (calendar days) - Default is ${
        getValueOrReplacementValue(defaultLimits?.flatLimitDays, 'Unlimited')
      } calendar days`}
      {...rest}
      thousandSeparator
      showAdornment={showAdornment}
      adornmentIsDisabled={adornmentIsDisabled}
      placeholder="Enter custom calendar days"
      rules={{
        validate: {
          useDefault: () => (watchLoadLimitDays === defaultLimits?.flatLimitDays
            ? `${watchLoadLimitDays} calendar days is the default value. Please click "USE DEFAULT"`
            : true),
        },
      }}
      name={name}
      prefix=""
      suffix=" calendar days"
      allowNegative={false}
    />
  );
};

export default LimitPeriodLimitInput;
