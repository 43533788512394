import { subDays, subMonths } from 'date-fns';
import { omit } from 'lodash/fp';
import { AxiosResponse } from 'axios';
import { getCentralNowDate } from '../../../shared/utilities/dateHelpers';
import { LimitDetails } from '../../../shared/models/fuel/LimitDetails';
import { PortalApiLoadLimit } from '../../../shared/models/fuel/PortalApiLoadLimit';
import { PortalApiLoadLimits } from '../../../shared/models/fuel/PortalApiLoadLimits';
import { PortalApiFuelTransactionHistoryDetail } from '../../../shared/models/fuel/PortalApiFuelTransactionHistoryDetail';
import { PortalApiFuelLoadHistoryDetail } from '../../../shared/models/fuel/PortalApiFuelLoadHistoryDetail';
import { FuelHistory } from '../../../shared/models/fuel/FuelHistory';
import { FuelHistoryType } from '../../../shared/enums/fuel/FuelHistoryType';
import { DefaultCardRegistrationInformationModel, VerifyAddress } from '../../../shared/models/fuel/CardRegistrationInformationModel';
import { PortalApiLimitType } from '../../../shared/enums/fuel/PortalApiLimitType';
import { LimitLevel } from '../../../shared/enums/fuel/LimitLevel';
import { CardCountModel } from '../../../shared/models/fuel/CardCountModel';
import { TriggeredFundModel } from '../../../shared/models/fuel/TriggeredFundModel';
import { PortalApiFuelLoadHistoryId } from '../../../shared/models/fuel/PortalApiFuelLoadHistoryId';
import { ScheduleLoadSubmit } from '../../../shared/models/fuel/ScheduleLoadSubmit';
import { PortalSmartCard } from '../../../shared/models/fuel/PortalSmartCard';
import { FuelCardRegistrationModel } from '../../../shared/models/fuel/FuelCardRegistrationModel';
import { RegisterSmartCard } from '../../../shared/models/fuel/RegisterSmartCard';
import { EfsTransactionSummaryPagedEntityModel } from '../../../shared/models/fuel/EfsTransactionSummaryPagedEntityModel';
import {
  defaultFleetTransactionHistoryDaysBack, defaultIftaMonthsBack, findNodeByLimitType, getTriggeredFundBaseModel, parseScheduledLoad, portalApiTransactionToTransaction,
} from '../../../shared/utilities/fuelHelpers';
import { PortalFuelFees } from '../../../shared/models/fuel/PortalFuelFees';
import { ProcessingFee } from '../../../shared/models/fuel/ProcessingFee';
import { UserPortalFuelFees } from '../../../shared/models/fuel/UserPortalFuelFees';
import { initAxiosInstance } from './utils';
import { TriggeredFundPauseModel } from '../../../shared/models/fuel/TriggeredFundPauseModel';
import { IftaParametersFormData } from '../../../shared/components/forms/IftaParametersForm';
import { Address } from '../../../shared/models/other/Address';
import { FuelCardMarketingData } from '../../../shared/models/fuel/FuelCardMarketingData';

export const useFuelApi = () => {
  const portalClient = initAxiosInstance();

  const getFleetLoadFees = async (fleetId: string) => {
    const { data } = await portalClient.get<PortalFuelFees>('fuel/fees/fleet', {
      params: {
        fleetId,
        highestPriority: true,
      },
    });

    const mappedData = data.expediteFees.map<ProcessingFee>((fee) => ({
      id: fee.id,
      // not sure why loadFeePercentage && loadFeeMinimum are being placed in each processing fee
      // ######
      // fleetLoadPercentage: data.loadFeePercentage,
      // fleetMinimumLoadFeeAmount: data.loadFeeMinimum,
      // ######
      paymentMethodType: fee.paymentMethodTypeId,
      display: `${fee.days} ${fee.days < 2 ? 'day' : 'days'} - ${fee.percentage}% expedite fee`,
      availabilityDateExpireDate: fee.availabilityExpireDateUtc,
      availibilityDate: fee.availabilityDate,
      availabilityDateDisplay: fee.availabilityDateDisplay || '',
      days: fee.days,
      percentage: fee.percentage,
    }));

    return {
      fleetId,
      loadFeePercentage: data.loadFeePercentage,
      loadFeeMinimum: data.loadFeeMinimum,
      expediteFees: mappedData,
    };
  };

  const getIndividualLoadFees = async (userId: string): Promise<UserPortalFuelFees> => {
    const { data } = await portalClient.get<PortalFuelFees>('fuel/fees/member', {
      params: {
        userId,
        highestPriority: true,
      },
    });

    const mappedData = data.expediteFees.map<ProcessingFee>((fee) => ({
      id: fee.id,
      // not sure why loadFeePercentage && loadFeeMinimum are being placed in each processing fee
      // ######
      // fleetLoadPercentage: data.loadFeePercentage,
      // fleetMinimumLoadFeeAmount: data.loadFeeMinimum,
      // ######
      paymentMethodType: fee.paymentMethodTypeId,
      display: `${fee.days} ${fee.days < 2 ? 'day' : 'days'} - ${fee.percentage}% expedite fee`,
      availabilityDateExpireDate: fee.availabilityExpireDateUtc,
      availibilityDate: fee.availabilityDate,
      availabilityDateDisplay: fee.availabilityDateDisplay || '',
      days: fee.days,
      percentage: fee.percentage,
    }));

    return {
      userId,
      loadFeePercentage: data.loadFeePercentage,
      loadFeeMinimum: data.loadFeeMinimum,
      expediteFees: mappedData,
    };
  };

  // API: GetCardRegistrationInformation
  const getSmartCardRegistrationInfo = async (userId: string) => {
    const { data } = await portalClient
      .get<DefaultCardRegistrationInformationModel>(`/fuel/card/registrationInformation/${userId}`);

    return data;
  };

  // API: ActivateAndSetPin
  const postActivateAndSetSmartCardPin = async (fuelCardId: number, pin: number) => {
    await portalClient.post<void>(`/fuel/card/${fuelCardId}/activateAndSetPin`, { pin });
  };

  // API: SetPin
  const postSetSmartCardPin = async (fuelCardId: number, pin: number) => {
    await portalClient.post<void>(`/fuel/card/${fuelCardId}/setPin`, { pin });
  };

  // API: RegisterAndLoadCard
  const postRegisterSmartCard = (
    props: RegisterSmartCard,
  ) => portalClient.post<FuelCardRegistrationModel>('fuel/card/register', {
    userId: props.userId,
    birthDate: props.birthDate,
    socialSecurityLast4: props.socialSecurityLast4,
    firstName: props.firstName,
    lastName: props.lastName,
    membershipAddress: props.membershipAddress,
    email: props.email,
    phone: props.phone,
    shippingAddress: props.shippingAddress,
    accountId: props.paymentMethodId,
    feeId: props.processingFee.id,
    loadFeePercentage: props.loadFeePercentage,
    loadFeeMinimum: props.loadFeeMinimum,
    expediteFeePercentage: props.processingFee.percentage,
    expediteFeeDays: props.processingFee.days,
    estimatedFundsAvailability: props.processingFee.availibilityDate,
    loadAmount: props.fundsToAdd,
    feeAdjustmentAmount: props.feeAdjustmentAmount,
    feeAdjustmentReason: props.feeAdjustmentReason,
    // enableSms: props.enableSms,
    sponsorChangeRequest: props.sponsorChangeRequest,
    sponsorChangeRequestType: props.sponsorChangeRequestType,
    couponUsageId: props.couponUsageId,
  });

  // API: UpdateTriggeredLoad
  // API: AddTriggeredLoad
  const postScheduleFleetLoad = (fleetId: string, loadFeePercentage: number, loadFeeMinimum: number, scheduledLoad: ScheduleLoadSubmit) => {
    const processingFee = scheduledLoad.processingPeriod;
    const apiUrl = scheduledLoad.id ? 'fuel/contract/fleet/updatetriggeredload' : 'fuel/contract/fleet/addtriggeredload';
    if (processingFee) {
      const loadData = {
        ...getTriggeredFundBaseModel(loadFeePercentage, loadFeeMinimum, scheduledLoad),
        fleetId,
        id: scheduledLoad.id,
      };

      // No need for the response body that is currently being returned because we are re-fetching scheduled loads manually after the post succeeds
      // TODO: API guys can remove the response body being returned from UpdateTriggeredLoad & AddTriggeredLoad
      return portalClient.post<void>(apiUrl, loadData);
    }
    throw new Error('Processing fee information is absent');
  };

  const postScheduleIndividualCarrierLoad = (userId: string, loadFeePercentage: number, loadFeeMinimum: number, scheduledLoad: ScheduleLoadSubmit) => {
    const processingFee = scheduledLoad.processingPeriod;
    const apiUrl = scheduledLoad.id ? 'fuel/contract/user/updatetriggeredload' : 'fuel/contract/user/addtriggeredload';
    if (processingFee) {
      const loadData = {
        ...getTriggeredFundBaseModel(loadFeePercentage, loadFeeMinimum, scheduledLoad),
        userId,
        id: scheduledLoad.id,
      };

      // No need for the response body that is currently being returned because we are re-fetching scheduled loads manually after the post succeeds
      // TODO: API guys can remove the response body being returned from UpdateTriggeredLoad & AddTriggeredLoad
      return portalClient.post<void>(apiUrl, loadData);
    }
    throw new Error('Processing fee information is absent');
  };
  // API: DeleteTriggeredLoad
  // TODO: API guys can remove the response body being returned from DeleteTriggeredLoad
  const deleteScheduledFleetLoad = (loadId: string) => portalClient.delete<void>(`fuel/contract/deletetriggeredload/${loadId}`);

  const getScheduledLoadsForFleet = async (fleetId: string) => {
    const { data: scheduledLoads } = await portalClient.get<TriggeredFundModel[]>(`fuel/contract/fleet/${fleetId}/triggeredLoads`);

    const parsedScheduledLoads: TriggeredFundModel[] = scheduledLoads.map(parseScheduledLoad);

    return parsedScheduledLoads;
  };
  // API: GetTriggeredLoad
  const getScheduledLoadDetails = async (loadId: string) => {
    const { data: loadDetails } = await portalClient.get<TriggeredFundModel>(`fuel/contract/triggeredload/${loadId}`);
    return parseScheduledLoad(loadDetails);
  };

  // API: GetFleetHistory
  const postFleetTransactionHistory = async (
    fleetId: string,
    startDate?: Date,
    endDate?: Date,
    type: FuelHistoryType = FuelHistoryType.All,
    fuelCardId?: number,
    userId?: string,
  ): Promise<FuelHistory> => {
    const { data } = await portalClient.post<EfsTransactionSummaryPagedEntityModel>('/fuel/history/fleet', {
      fleetId,
      startDate:
        startDate || subDays(getCentralNowDate(), defaultFleetTransactionHistoryDaysBack),
      endDate: endDate || getCentralNowDate(),
      fuelHistoryType: type,
      fuelCardId,
      userId,
    });
    return {
      transactions: data.items.map((h) => portalApiTransactionToTransaction(h)),
      // stats: data.stats,
      startDate: data.startDate,
      endDate: data.endDate,
      isDefaultDateRange: Boolean(!startDate && !endDate),
    };
  };

  const postFleetTransactionHistoryExport = async (
    fleetId: string,
    startDate?: Date,
    endDate?: Date,
    type: FuelHistoryType = FuelHistoryType.All,
    fuelCardId?: number,
    userId?: string,
  ) => {
    const { data } = await portalClient.post<Blob>('/fuel/history/fleet/export', {
      fleetId,
      startDate:
        startDate || subDays(getCentralNowDate(), defaultFleetTransactionHistoryDaysBack),
      endDate: endDate || getCentralNowDate(),
      fuelHistoryType: type,
      fuelCardId,
      userId,
    }, {
      responseType: 'blob',
    });
    return data;
  };

  const postFleetIftaExport = async (
    fleetId: string,
    iftaFormData: IftaParametersFormData,
    fuelCardId?: string,
    userId?: string,
  ): Promise<Blob> => {
    const {
      startDate, endDate, exportType, groupBy, reportRange,
    } = iftaFormData;
    const { data } = await portalClient.post<Blob>('/fuel/history/fleet/export/ifta', {
      fleetId,
      startDate:
        startDate || subMonths(getCentralNowDate(), defaultIftaMonthsBack),
      endDate: endDate || getCentralNowDate(),
      fuelCardId,
      userId,
      exportType,
      groupBy,
      dateRangeType: reportRange,
    }, {
      responseType: 'blob',
    });
    return data;
  };

  const postIndividualFuelTransactionHistory = async (
    userId: string,
    startDate?: Date,
    endDate?: Date,
    type: FuelHistoryType = FuelHistoryType.All,
    fuelCardId?: number, // NOTE: If this is not passed, the API will lookup the carrier id for the given userId that is passed and return transactions relative to said carrier id
  ): Promise<FuelHistory> => {
    const { data } = await portalClient.post<EfsTransactionSummaryPagedEntityModel>('/fuel/history/user', {
      userId,
      startDate:
        startDate || subDays(getCentralNowDate(), defaultFleetTransactionHistoryDaysBack),
      endDate: endDate || getCentralNowDate(),
      fuelHistoryType: type,
      fuelCardId,
    });

    return {
      transactions: data.items.map((h) => portalApiTransactionToTransaction(h)),
      // stats: data.stats,
      startDate: data.startDate,
      endDate: data.endDate,
      isDefaultDateRange: Boolean(!startDate && !endDate),
    };
  };

  const postIndividualFuelTransactionHistoryExport = async (
    userId: string,
    startDate?: Date,
    endDate?: Date,
    type: FuelHistoryType = FuelHistoryType.All,
    fuelCardId?: number, // NOTE: If this is not passed, the API will lookup the carrier id for the given userId that is passed and return transactions relative to said carrier id
  ) => {
    const { data } = await portalClient.post<Blob>('/fuel/history/user/export', {
      userId,
      startDate:
        startDate || subDays(getCentralNowDate(), defaultFleetTransactionHistoryDaysBack),
      endDate: endDate || getCentralNowDate(),
      fuelHistoryType: type,
      fuelCardId,
    }, {
      responseType: 'blob',
    });
    return data;
  };

  const postIndividualIftaExport = async (
    userId: string,
    iftaFormData: IftaParametersFormData,
    fuelCardId?: number, // NOTE: If this is not passed, the API will lookup the carrier id for the given userId that is passed and return transactions relative to said carrier id
  ) => {
    const {
      startDate, endDate, exportType, groupBy, reportRange,
    } = iftaFormData;
    const { data } = await portalClient.post<Blob>('/fuel/history/user/export/ifta', {
      userId,
      startDate:
        startDate || subMonths(getCentralNowDate(), defaultIftaMonthsBack),
      endDate: endDate || getCentralNowDate(),
      fuelCardId,
      exportType,
      groupBy,
      dateRangeType: reportRange,
    }, {
      responseType: 'blob',
    });
    return data;
  };

  // API: GetUserLimit
  const getUserLoadLimits = async (
    userId: string,
    limitLevel: LimitLevel = LimitLevel.applied,
  ): Promise<PortalApiLoadLimits> => {
    const { data: limits } = await portalClient.get<PortalApiLoadLimits>(`/fuel/limits/user/${userId}`, {
      params: {
        limitLevel,
      },
    });
    return limits;
  };

  // API: GetFleetLimits
  const getFleetLoadLimits = async (
    fleetId: string,
    limitLevel: LimitLevel = LimitLevel.applied,
  ): Promise<PortalApiLoadLimits> => {
    const { data: limits } = await portalClient.get<PortalApiLoadLimits>(
      `/fuel/limits/fleet/${fleetId}`,
      {
        params: {
          limitLevel,
        },
      },
    );
    return limits;
  };

  const getFleetLimits = async (fleetId: string): Promise<LimitDetails> => {
    const limits = await getFleetLoadLimits(fleetId, LimitLevel.inherited);
    const limitNodes = limits.limitNodes || undefined;

    const getByLimitType = findNodeByLimitType(limitNodes);

    return {
      fleet: getByLimitType(PortalApiLimitType.Fleet)!,
      user: getByLimitType(PortalApiLimitType.User),
      system: getByLimitType(PortalApiLimitType.System)!,
      inheritedFleet: omit(['multiplier', 'limitNodes'], limits),
      multiplier: limits.multiplier,
    };
  };

  // API: GetTransactionHistoryDetails
  const postFuelTransactionHistoryDetails = async (transactionId: number) => {
    const { data } = await portalClient.post<PortalApiFuelTransactionHistoryDetail>(
      `fuel/history/transactionDetails/${transactionId}`,
    );
    return {
      ...data,
      dateTime: data.dateTime
        ? new Date(data.dateTime)
        : undefined,
      pointOfSaleDate: data.pointOfSaleDate
        // ? toTimeZone(new Date(data.pointOfSaleDate), data.pointOfSaleTimeZone)
        ? new Date(data.pointOfSaleDate)
        : undefined,
    };
  };

  // API: GetLoadHistoryDetails
  const postFuelLoadHistoryDetails = async (id: PortalApiFuelLoadHistoryId) => {
    const { data } = await portalClient.post<PortalApiFuelLoadHistoryDetail>(
      'fuel/history/loadDetails',
      id,
    );
    return {
      ...data,
      loadDate: data.loadDate,
      createdDate: data.createdDate,
      estimatedFundsAvailability: data.estimatedFundsAvailability,
    };
  };

  // API: GetCarrierCardCount
  const getCarrierCardCount = async (carrierId: number) => {
    const { data } = await portalClient.get<CardCountModel>('/fuel/carrier/cardCount', {
      params: {
        carrierId,
      },
    });
    return data;
  };

  // API: GetUserLimit
  const getUserLimits = async (userId: string, limitLevel: LimitLevel = LimitLevel.applied) => {
    const { data } = await portalClient.get<PortalApiLoadLimit>(`/fuel/limits/user/${userId}`, {
      params: {
        limitLevel,
      },
    });

    return data;
  };

  // API: GetSmartCardsForUser
  const getSmartCardsByUserId = async (userId: string) => {
    const { data } = await portalClient.get<PortalSmartCard[]>(`/fuel/smartcard/${userId}`);

    return data;
  };

  // API: UpdateTriggeredLoad
  // TODO: if we ever allow fleets to do this on their own, we need more authorization checking
  const postPauseTriggeredLoad = async (pauseInfo: TriggeredFundPauseModel): Promise<void> => {
    await portalClient.post<any>('/fuel/contract/triggeredloadpause', { ...pauseInfo });
  };

  // TODO: if we ever allow fleets to do this on their own, we need more authorization checking
  // API: DeleteTriggeredLoadPauseInfo
  const deletePauseTriggeredLoad = async (scheduledLoadId: string): Promise<void> => {
    await portalClient.delete<void>(`fuel/contract/triggeredloadpause/${scheduledLoadId}`);
  };

  // API: UpdateTriggeredContractLoadPauseInfo
  const postPauseTriggeredLoadSmartCard = async (pauseInfo: TriggeredFundPauseModel): Promise<void> => {
    await portalClient.post<any>('/fuel/card/triggeredloadpause', { ...pauseInfo });
  };

  // API: DeleteTriggeredCardLoadPauseInfo
  const deletePauseTriggeredLoadSmartCard = async (scheduledLoadId: string): Promise<void> => {
    await portalClient.delete<void>(`fuel/card/triggeredloadpause/${scheduledLoadId}`);
  };

  // API: UpdateTriggeredCardLoad
  // API: AddTriggeredCardLoad
  const postScheduleSmartCardLoad = (fuelCardId: number, loadFeePercentage: number, loadFeeMinimum: number, scheduledLoad: ScheduleLoadSubmit) => {
    const processingFee = scheduledLoad.processingPeriod;
    const apiUrl = scheduledLoad.id ? 'fuel/card/updatetriggeredload' : 'fuel/card/addtriggeredload';
    if (processingFee) {
      const loadData = {
        ...getTriggeredFundBaseModel(loadFeePercentage, loadFeeMinimum, scheduledLoad),
        id: scheduledLoad.id,
        fuelCardId,
      };

      return portalClient.post<void>(apiUrl, loadData);
    }

    throw new Error('Processing fee information is absent');
  };

  // API: DeleteTriggeredCardLoad
  const deleteScheduledSmartCardLoad = (loadId: string) => portalClient.delete<void>(`fuel/card/deleteTriggeredLoad/${loadId}`);

  // API: GetTriggeredLoads
  const getScheduledLoadsForSmartCard = async (cardId: number) => {
    const { data: scheduledLoads } = await portalClient.get<TriggeredFundModel[]>(`fuel/card/triggeredLoads/${cardId}`);

    const parsedScheduledLoads: TriggeredFundModel[] = scheduledLoads.map(parseScheduledLoad);

    return parsedScheduledLoads;
  };

  const getScheduledLoadsForIndividualCarrier = async (userId: string) => {
    const { data: scheduledLoads } = await portalClient.get<TriggeredFundModel[]>(`fuel/contract/user/${userId}/triggeredLoads`);

    const parsedScheduledLoads: TriggeredFundModel[] = scheduledLoads.map(parseScheduledLoad);

    return parsedScheduledLoads;
  };

  // API: GetTriggeredCardLoad
  const getScheduledLoadSmartCardDetails = async (loadId: string) => {
    const { data: loadDetails } = await portalClient.get<TriggeredFundModel>(`fuel/card/triggeredLoad/${loadId}`);
    return parseScheduledLoad(loadDetails);
  };

  // API: CheckAddress
  const getAddressVerification = async (addressToVerify: Address) => {
    const { data } = await portalClient.post<VerifyAddress>('fuel/checkAddress', addressToVerify);
    return data;
  };

  // API: GetFuelCardMarketingData
  const getFuelCardMarketingData = async (userId: string) => {
    const { data } = await portalClient.get<FuelCardMarketingData>(`fuel/fuelCardMarketingData/${userId}`);
    return data;
  };

  const getEsFuelMapSsoToken = async () => {
    const { data } = await portalClient.get<any, AxiosResponse<string>>(
      '/fuel/EsFuelMap',
    );

    return data;
  };

  return {
    getFleetLoadFees,
    getIndividualLoadFees,
    getSmartCardRegistrationInfo,
    postActivateAndSetSmartCardPin,
    postSetSmartCardPin,
    postRegisterSmartCard,
    postScheduleFleetLoad,
    deleteScheduledFleetLoad,
    getScheduledLoadsForFleet,
    getScheduledLoadDetails,
    postFleetTransactionHistory,
    postFleetTransactionHistoryExport,
    postFleetIftaExport,
    postIndividualFuelTransactionHistory,
    postIndividualFuelTransactionHistoryExport,
    postIndividualIftaExport,
    getUserLoadLimits,
    getFleetLoadLimits,
    getFleetLimits,
    postFuelTransactionHistoryDetails,
    postFuelLoadHistoryDetails,
    getCarrierCardCount,
    getUserLimits,
    getSmartCardsByUserId,
    postPauseTriggeredLoad,
    deletePauseTriggeredLoad,
    postPauseTriggeredLoadSmartCard,
    deletePauseTriggeredLoadSmartCard,
    postScheduleSmartCardLoad,
    deleteScheduledSmartCardLoad,
    getScheduledLoadsForSmartCard,
    getScheduledLoadSmartCardDetails,
    getAddressVerification,
    getFuelCardMarketingData,
    getEsFuelMapSsoToken,
    postScheduleIndividualCarrierLoad,
    getScheduledLoadsForIndividualCarrier,
  };
};
