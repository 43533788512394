import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ErrorType } from '../enums/other/ErrorType';

import { ProcessStep } from '../models/other/ProcessStep';

export const resolveHasStepError = (stp: ProcessStep, otherTypes: ErrorType[] = []): boolean => {
  const isError = stp.errorType === ErrorType.Error;

  if (otherTypes.length > 0) {
    return otherTypes.includes(stp.errorType) || isError;
  }

  return isError;
};

interface ProcessStepsProps {
  steps: ProcessStep[],
  style?: React.CSSProperties;
}

const ProcessSteps = (props: ProcessStepsProps) => {
  const { steps, style } = props;
  const theme = useTheme();

  const resolveErrorStateIcon = (stp: ProcessStep): React.ReactNode => {
    switch (stp.errorType) {
      case ErrorType.Error:
        return <HighlightOffIcon color="error" />;
      case ErrorType.Warning:
        return <ErrorOutlineIcon sx={{ color: theme.palette.warning.main }} />;
      case ErrorType.NoError:
        return <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />;
      default:
        return <HelpOutlineIcon sx={{ color: theme.palette.action.disabled }} />;
    }
  };

  const resolveErrorStateColor = (stp: ProcessStep): string => {
    switch (stp.errorType) {
      case ErrorType.Error:
        return theme.palette.error.main;
      case ErrorType.Warning:
        return theme.palette.warning.main;
      case ErrorType.NoError:
        return theme.palette.success.main;
      default:
        return theme.palette.action.disabled;
    }
  };

  return (
    <List dense sx={{ ...style }}>
      {steps.map((ps, index) => (
        <ListItem key={`step-${index}`}>
          <ListItemIcon>
            {resolveErrorStateIcon(ps)}
          </ListItemIcon>
          <ListItemText
            sx={{ color: resolveErrorStateColor(ps) }}
            primary={ps.name}
            secondary={ps.status}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ProcessSteps;
