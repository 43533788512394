import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

interface ApiErrorProps {
  errorTitle?: React.ReactNode;
  errorDescription?: React.ReactNode;
  style?: React.CSSProperties;
  styleTitle?: React.CSSProperties;
}

const StandardError = (props: ApiErrorProps) => {
  const {
    errorTitle = 'An Error Occurred',
    errorDescription = 'An error occurred while performing this action. Please contact a system administrator.',
    style,
    styleTitle,
  } = props;

  return (
    <Alert variant="filled" severity="error" sx={{ ...style }}>
      <AlertTitle sx={{ ...styleTitle }}>{errorTitle}</AlertTitle>
      {errorDescription}
    </Alert>
  );
};

export default StandardError;
