import {
  Dialog, DialogContent, DialogTitle, Grow, Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { TransitionProps } from '@mui/material/transitions';
import { useBreakPoints } from '../hooks/useBreakPoints';

export const Transition = React.forwardRef((
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) => <Grow ref={ref} {...props}><div>{props.children}</div></Grow>);

interface HelpDialogProps {
  title?: string;
  tooltipTitle?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  removeDialogPadding?: boolean;
}

const HelpDialog = (props: HelpDialogProps) => {
  const {
    title, tooltipTitle = '', children, style, removeDialogPadding = false,
  } = props;
  const theme = useTheme();
  const styles = {
    container: {
      minHeight: '100vh',
    },
    contentRoot: {
      maxHeight: '90vh',
    },
  };
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const { isXsDown } = useBreakPoints();

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: isXsDown ? '-24px' : '-16px',
          left: isXsDown ? '4px' : '-26px',
          cursor: 'pointer',
          ...style,
        }}
      >
        <Tooltip
          title={tooltipTitle}
          aria-label={tooltipTitle}
          onClick={() => setHelpDialogOpen(true)}
        >
          <span style={{ color: theme.palette.info.dark, opacity: 0.75 }}><HelpOutlineOutlinedIcon color="inherit" sx={{ width: '0.9em', height: '0.9em' }} /></span>
        </Tooltip>
      </div>

      <Dialog
        scroll="paper"
        TransitionComponent={Transition}
        onClose={() => setHelpDialogOpen(false)}
        open={helpDialogOpen}
        sx={{
          '& .MuiDialog-container': styles.container,
        }}
      >
        <DialogTitle sx={{ padding: removeDialogPadding ? '0px' : {} }}>{title}</DialogTitle>
        <DialogContent
          sx={{
            ...styles.contentRoot,
          }}
        >
          {children}
        </DialogContent>
      </Dialog>

    </>
  );
};

export default HelpDialog;
