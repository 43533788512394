import React from 'react';
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import {
  withAITracking,
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { parseEnv } from './config';

const config = parseEnv();

const reactPlugin = new ReactPlugin();
const plugin = reactPlugin as unknown as ITelemetryPlugin;
const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxPerfTracking: true,
    isBrowserLinkTrackingEnabled: true,
    extensions: [plugin],
  },
});

appInsights.loadAppInsights();

interface AzureAppInsightsProps {
  children: (JSX.Element | undefined)[];
}
const AzureAppInsights = ({ children }: AzureAppInsightsProps) => (
  <AppInsightsContext.Provider value={reactPlugin}>
    {children}
  </AppInsightsContext.Provider>
);

export default withAITracking(reactPlugin, AzureAppInsights);
