import { Fade, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { fadeTimeout } from '../transitions/timeouts';

interface ListCollectionWrapperrProps {
  children?: React.ReactNode;
}

const ListCollectionWrapper = (props: ListCollectionWrapperrProps) => {
  const { children } = props;
  const theme = useTheme();
  const styles = {
    list: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      margin: '8px',
      borderRadius: theme.shape.borderRadius,
      '& li, & .MuiListItem-root': {
        backgroundColor: theme.palette.common.white,
        borderRadius: '5px',
        '&:hover': {
          backgroundColor: `${theme.palette.grey[100]} !important`,
        },
      },
    },
    listPadding: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  };

  return (
    <Fade in timeout={fadeTimeout}>
      <List sx={{
        ...styles.list,
        '&.MuiList-padding': styles.listPadding,
      }}
      >
        {children}
      </List>
    </Fade>
  );
};

export default ListCollectionWrapper;
