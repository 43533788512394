import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ITelemetryPlugin,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { omit } from 'lodash/fp';
import { useState } from 'react';
import { useAppDispatch } from '../../app/store';
import { parseEnv } from '../../config';
import { addAlert } from '../../features/application/applicationSlice';
import { Profile } from '../models/user/Profile';
import { AzureAccount, selectAccount } from '../../features/auth/authReducer';

const config = parseEnv();

let cachedInstance: ApplicationInsights;
let cachedReactPlugin: ReactPlugin;

export const initiate = () => {
  const newReactPlugin = new ReactPlugin();
  const newPlugin = newReactPlugin as unknown as ITelemetryPlugin;
  const newInstance = new ApplicationInsights({
    config: {
      connectionString: config.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
      extensions: [newPlugin],
      disableExceptionTracking: true,
    },
  });

  newInstance.loadAppInsights();
  cachedInstance = newInstance;

  cachedReactPlugin = newReactPlugin;
  return newReactPlugin;
};

export const logErrorWithApplicaitonInsights = (
  e: Error | unknown,
  errorInfo?: Object,
) => {
  const error = e as Error;
  const { message, name, stack } = error;
  const exceptionInfo = {
    exception: {
      message,
      name,
      stack,
    },
    severityLevel: SeverityLevel.Error,
    properties: {
      ...errorInfo,
    },
  };

  if (
    cachedReactPlugin
    && config.REACT_APP_APPLICATION_INSIGHTS_LOGGING_ENABLED
  ) {
    cachedReactPlugin.trackException({ ...exceptionInfo });
  }
};

export const getAzureFullAccount = (azureAccount: AzureAccount | undefined) => omit('idTokenClaims', azureAccount?.fullAccount);

export const getErrorInfo = (error: Error, extraProperties: any) => (axios.isAxiosError(error)
  ? {
    response: {
      ...error.response,
      config: {
        ...error.config,
        headers: omit('Authorization', error.config.headers),
      },
    },
    ...extraProperties,
  }
  : {
    ...extraProperties,
  });
/**
 * Wrapper to allow async errors to be caught by the error boundary if fatal is set to true.
 * Errors passed into the returned function will be logged to app insights
 * @returns never
 */
export const useAsyncErrorHandler = () => {
  const [err, setErr] = useState<Error>();
  const dispatch = useAppDispatch();
  const azureAccount = useSelector(selectAccount);

  if (err) {
    throw err;
  }

  const errorHandler = (
    error: Error,
    fatal: boolean,
    additonalAlertMessage: string = '',
    showAlertMessage: boolean = true,
  ) => {
    if (fatal) {
      setErr(error);
    }

    const accountInfo = getAzureFullAccount(azureAccount);

    const errorInfo = getErrorInfo(error, accountInfo);

    logErrorWithApplicaitonInsights(error, errorInfo);

    if (!fatal && showAlertMessage) {
      dispatch(
        addAlert({
          message: `${error.message}${additonalAlertMessage ? ` - ${additonalAlertMessage}` : ''}`,
          severity: 'error',
          autoHideDuration: 10000,
        }),
      );
    }

    throw error;
  };

  const handleError = (
    e: Error | unknown,
    additionalAlertMessage?: string,
    showAlertMessage: boolean = true,
  ) => {
    const error = e as Error;
    if (axios.isAxiosError(error)) {
      // eslint-disable-next-line no-param-reassign
      error.message = error.response?.data
        ? `${error.message}: ${error.response?.data}`
        : error.message;

      if (error.response?.status !== 422) {
        errorHandler(error, false, additionalAlertMessage, showAlertMessage);
      } else {
        // don't show toast for 422 validation errors
        errorHandler(error, false, additionalAlertMessage, false);
      }
    } else {
      errorHandler(error, false, additionalAlertMessage, showAlertMessage);
    }
  };
  const handleFatalError = (
    e: Error | unknown,
    additionalAlertMessage: string = '',
  ) => {
    const error = e as Error;
    errorHandler(error, true, additionalAlertMessage);
  };

  return {
    handleError,
    handleFatalError,
  };
};

export const useTvcAppInsights = () => {
  const [instance] = useState(cachedInstance);
  const [reactPlugin] = useState(cachedReactPlugin);
  const checkForInstance = () => {
    if (!instance) {
      throw new Error(
        'please call initiate before calling any other methods..',
      );
    }

    return instance;
  };

  const logError = (exception: Error) => {
    const inst = checkForInstance();

    inst.trackException({ exception });
  };

  const setLoggedInUser = (profile: Profile) => {
    const inst = checkForInstance();

    inst.setAuthenticatedUserContext(profile.id);
  };

  return { reactPlugin, logError, setLoggedInUser };
};
