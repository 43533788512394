import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  alpha,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { get } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { FormProvider, RegisterOptions, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import { RootState } from '../../app/store';
import { PortalApiMatrixUser } from '../../shared/models/matrix/PortalApiMatrixUser';
import { UserInformation } from '../../shared/models/user/UserInformation';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import ConfirmLabelValue from '../../shared/components/confirm-summary/ConfirmLabelValue';
import SectionRow from '../../shared/components/confirm-summary/SectionRow';
import Summary from '../../shared/components/confirm-summary/Summary';
import SummarySection from '../../shared/components/confirm-summary/SummarySection';

import CustomSelectInput from '../../shared/components/formFields/CustomSelectInput';
import CustomTextInput from '../../shared/components/formFields/CustomTextInput';
import FormWrapper from '../../shared/components/FormWrapper';
import NoResults from '../../shared/components/NoResults';
import ProfilePermissions from '../../shared/components/ProfilePermissions';
import TvcSkeleton from '../../shared/components/TvcSkeleton';
import UserPermissions, { SelectedPermission } from '../../shared/components/UserPermissions';
import { useAsyncErrorHandler } from '../../shared/hooks/useTvcAppInsights';
import { useProfile } from '../auth/hooks/useProfile';
import { activeUserIconColor, UserMembershipIcon } from '../../shared/components/UserIcons';

import AddAUserHelp from './AddAUserHelp';
import EmailTextInput from '../../shared/components/formFields/EmailTextInput';
import { PortalApiPermissionFull } from '../../shared/models/permissions/PortalApiPermissionFull';
import CrossRefereceHelp from '../../shared/components/user/CrossReferenceHelp';
import { FleetProductTemplate } from '../../shared/models/fleet/FleetProductTemplate';
import ErrorIconText from '../../shared/components/icon-components/ErrorIconText';
import CustomDateInput from '../../shared/components/formFields/CustomDateInput';
import { getCentralNowDate } from '../../shared/utilities/dateHelpers';
import HelpDialog from '../../shared/components/HelpDialog';
import { getValueOrReplacementValue } from '../../shared/utilities/miscHelpers';
import { CustomAddressForm } from '../../shared/components/forms/CustomAddressForm';

const defaultUserInformation: UserInformation = {
  userId: undefined,
  firstName: '',
  lastName: '',
};

export interface ExistingUser {
  fullName?: string;
  membershipIcon: React.ReactNode;
  loginIcon: React.ReactElement;
}
interface UserCreationOptions {
  loginNeeded: boolean;
  membershipNeeded: boolean;
}

interface UserCreationToggleButtonsProps {
  onChange: (value: UserCreationOptions) => void;
  style?: React.CSSProperties;
  initialSelection?: 'login' | 'membership' | 'both';
  showHelp?: boolean;
}

const UserTypeSelection = (props: UserCreationToggleButtonsProps) => {
  const {
    onChange,
    style,
    initialSelection = 'login',
    showHelp = false,
  } = props;

  const [value, setValue] = useState<string>(initialSelection);

  useEffect(() => {
    const isBoth = value.includes('both');
    onChange({ loginNeeded: (value.includes('login') || isBoth), membershipNeeded: (value.includes('membership') || isBoth) });
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div style={{ position: 'relative' }}>
      {showHelp && <AddAUserHelp />}
      <FormControl component="fieldset" sx={{ ...style }}>
        <FormLabel component="legend">User Type</FormLabel>
        <RadioGroup row aria-label="user type" name="user-type" value={value} onChange={handleChange}>
          <FormControlLabel value="login" control={<Radio color="primary" />} label="Login" />
          <FormControlLabel value="membership" control={<Radio color="primary" />} label="Membership" />
          <FormControlLabel value="both" control={<Radio color="primary" />} label="Both" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

interface AddAUserForm {
  startDate?: Date | null;
  firstName: string;
  lastName: string;
  email?: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phoneCell?: string;
  dlNumber: string;
  memberId?: number;
  alternateId: string;
  crossRefId?: string;
  fleetTemplateId: string;
}

const defaultUserCreationOptions: UserCreationOptions = {
  loginNeeded: true,
  membershipNeeded: false,
};

interface AddAUserProps {
  hideUserTypeSelection?: boolean;
  user?: UserInformation;
  userTypeSelection?: 'login' | 'membership' | 'both',
  userCanEditPermissions?: boolean;
  matrixUsers: PortalApiMatrixUser[];
  onFormSubmitted: (addUser: UserInformation, useFleetDefaultContactInfo: boolean) => Promise<void>;
  allPermissionsSelector?: (state: RootState) => PortalApiPermissionFull[];
  permissionFilter?: (p: PortalApiPermissionFull) => boolean;
  getDefaultPermissions: (allPermissions: PortalApiPermissionFull[]) => SelectedPermission[],
  lockPermissions?: boolean;
  lockPermissionsReason?: string;
  getDefaultContactValue?: () => Promise<Partial<AddAUserForm>>;
  forceCustomContactValue?: boolean;
  onLastNameChanged?: (lastName: string) => void;
  existingUsers?: ExistingUser[];
  showHelp?: boolean;
  hideSpecialPermissionsCheckbox?: boolean;
  productPromotions?: FleetProductTemplate[];
}

const AddAUser = (props: AddAUserProps) => {
  const {
    hideUserTypeSelection = false,
    user = defaultUserInformation,
    userTypeSelection = 'login',
    allPermissionsSelector,
    userCanEditPermissions = false,
    matrixUsers = [],
    onFormSubmitted,
    permissionFilter = (p) => p.categoryName === 'Fleet',
    getDefaultPermissions,
    lockPermissions = false,
    lockPermissionsReason,
    getDefaultContactValue = undefined,
    forceCustomContactValue = false,
    onLastNameChanged,
    existingUsers = [],
    showHelp = false,
    hideSpecialPermissionsCheckbox = false,
    productPromotions = [],
  } = props;

  const { userProfile } = useProfile();
  const isEmployee = Boolean(userProfile?.isEmployee);
  const [isCheckingEmail, setIsCheckingEmail] = useState<boolean>(false);
  const [requiresSpecialPermissions, setRequiresSpecialPermissions] = useState<boolean>(false);
  const [selectedMatrixMember, setSelectedMatrixMember] = useState<PortalApiMatrixUser>();
  const [useDefaultContact, setUseDefaultContact] = useState<string>('default');
  const [isDefaultContact, setIsDefaultContact] = useState<boolean>(true);
  const [isFetchingDefaultContact, setIsFetchingDefaultContact] = useState<boolean>(false);
  const [existingUsersModalOpen, setExistingUsersModalOpen] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<SelectedPermission[]>([]);
  const [enforceValidation, setEnforceValidation] = useState<boolean>(false);

  const [userOptions, setUserOptions] = useState<UserCreationOptions>(
    defaultUserCreationOptions,
  );

  const theme = useTheme();
  const styles = {
    selected: {
      color: `${activeUserIconColor} !important`,
      backgroundColor: `${alpha(activeUserIconColor, 0.08)} !important`,
    },
    commonWidth: {
      minWidth: `${194.5}px !important`,
    },
    selectOption: {
      textAlign: 'center',
      color: theme.palette.grey[700],
      fontStyle: 'italic',
      marginTop: '15px',
    },
    nestedGridItemAdjust: {
      paddingRight: '0px !important',
      paddingLeft: '13px !important',
    },
    nestedAssignExistingGridItemAdjust: {
      paddingRight: '4px !important',
    },
    submitButtonGridItemAdjust: {
      paddingRight: '6px !important',
      marginLeft: '6px !important',
      [theme.breakpoints.down('lg')]: {
        marginLeft: '0px !important',
      },
    },
  };
  const handleUserOptionSelection = (
    userSelectionOptions: UserCreationOptions,
  ) => {
    setUserOptions(userSelectionOptions);
  };
  const { handleError } = useAsyncErrorHandler();

  const form = useForm<AddAUserForm>({
    mode: 'onChange',
    defaultValues: {
      startDate: null,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.account?.email ?? '',
      address: '',
      city: '',
      state: 'select',
      country: 'USA',
      zipCode: '',
      phoneCell: '',
      dlNumber: '',
      memberId: undefined,
      alternateId: '',
      crossRefId: '',
      fleetTemplateId: '',
    },
  });

  const {
    formState, trigger, handleSubmit, watch, setValue,
  } = form;

  const watchEmail = watch('email');
  const watchMemberId = watch('memberId');
  const watchLastName = watch('lastName');

  const [assignToExistingMembership, setAssignToExistingMembership] = useState(false);

  useEffect(() => {
    setEnforceValidation(!assignToExistingMembership && userOptions.membershipNeeded && !isDefaultContact);
  }, [assignToExistingMembership, userOptions.membershipNeeded, isDefaultContact]);

  useEffect(
    () => () => {
      setUserOptions(defaultUserCreationOptions);
    },
    [],
  );

  useEffect(() => {
    if (onLastNameChanged) {
      onLastNameChanged(watchLastName);
    }
  }, [watchLastName]);

  useEffect(() => {
    if (forceCustomContactValue) {
      setUseDefaultContact('custom');
    }
  }, [forceCustomContactValue]);

  useEffect(() => {
    if (watchMemberId) {
      setSelectedMatrixMember(matrixUsers.find((m) => m.memberId === watchMemberId));
    } else {
      setSelectedMatrixMember(undefined);
    }
  }, [watchMemberId]);

  useEffect(() => {
    if (!assignToExistingMembership) {
      setSelectedMatrixMember(undefined);
      setValue('memberId', undefined);
    }
  }, [assignToExistingMembership]);

  useEffect(() => {
    if (!selectedMatrixMember) {
      setValue('firstName', user ? user.firstName : '');
      setValue('lastName', user ? user.lastName : '');
    }

    setTimeout(() => {
      trigger();
    }, 1);
  }, [selectedMatrixMember]);

  useEffect(
    () => {
      const defaultFormValue = async () => {
        if (!getDefaultContactValue) { return; }

        try {
          setIsFetchingDefaultContact(true);
          const defaultContactValue = await getDefaultContactValue();

          form.reset({
            ...defaultContactValue,
            startDate: null,
            firstName: form.getValues().firstName,
            lastName: form.getValues().lastName,
            email: form.getValues().email,
            dlNumber: form.getValues().dlNumber,
            alternateId: form.getValues().alternateId,
            crossRefId: form.getValues().crossRefId,
            fleetTemplateId: form.getValues().fleetTemplateId,
          });
        } catch (err: any) {
          handleError(err);
        } finally {
          setIsFetchingDefaultContact(false);
        }
      };
      if (isDefaultContact) {
        defaultFormValue();
      }
    },
    [isDefaultContact],
  );

  useEffect(() => {
    setTimeout(() => {
      trigger();
    }, 0);
  }, [
    userOptions.loginNeeded,
    userOptions.membershipNeeded,
    assignToExistingMembership,
    isDefaultContact,
    isFetchingDefaultContact,
  ]);

  const requiredIfTrue = (msg: string, value?: boolean): RegisterOptions => ({
    required: value ? msg : false,
  });

  const allPermissions = allPermissionsSelector ? useSelector(allPermissionsSelector) : [];

  useEffect(() => {
    setPermissions(getDefaultPermissions([...allPermissions]));
  }, [allPermissions]);

  const handleContactTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseDefaultContact(event.target.value);
  };

  useEffect(() => {
    if (useDefaultContact) {
      setIsDefaultContact(useDefaultContact.toLowerCase() === 'default');
    }
  }, [useDefaultContact]);

  const getMatrixUserDisplayItem = (item: PortalApiMatrixUser | undefined) => {
    if (!item) {
      return <span style={{ color: theme.palette.error.main }}>Select Existing Matrix Membership *</span>;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
        <UserMembershipIcon hasMembership membershipIsValid={item.hasValidMembership} startDate={item.startDate} />

        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
          {item.firstName}
          {' '}
          {item.lastName}
          {' '}
          (
          {item.memberId}
          )
        </span>
      </div>
    );
  };
  return (
    <>

      <>
        <UserTypeSelection
          onChange={handleUserOptionSelection}
          style={{
            display: hideUserTypeSelection ? 'none' : 'unset', marginBottom: '28px', marginLeft: '10px', marginTop: '20px',
          }}
          initialSelection={userTypeSelection}
          showHelp={showHelp}
        />

        {!userOptions.loginNeeded && !userOptions.membershipNeeded ? (
          <Typography sx={styles.selectOption}>
            Please select options above
          </Typography>
        ) : (
          <Fade in timeout={{ enter: 1000, exit: 200 }}>
            <div>
              {/* <FormWrapper error={error}> */}
              <FormWrapper>
                <FormProvider {...form}>
                  <form
                    onSubmit={handleSubmit(async (submittedForm) => {
                      const updatedUser: UserInformation = {
                        userId: user.userId,
                        firstName: submittedForm.firstName,
                        lastName: submittedForm.lastName,
                        permissionIds: userOptions.loginNeeded && requiresSpecialPermissions ? permissions.map((p) => p.id) : undefined,
                        matrixMembership: userOptions.membershipNeeded ? {
                          startDate: submittedForm.startDate,
                          alternateId: submittedForm.alternateId,
                          driversLicenseNumber: submittedForm.dlNumber,
                          address1: submittedForm.address,
                          city: submittedForm.city,
                          country: submittedForm.country,
                          state: submittedForm.state?.toUpperCase(),
                          zipCode: submittedForm.zipCode,
                          phoneCell: submittedForm.phoneCell,
                          crossRefId: submittedForm.crossRefId,
                          fleetTemplateId: submittedForm.fleetTemplateId,
                        } : undefined,
                        account: userOptions.loginNeeded ? { email: submittedForm.email ?? '', createLogin: true } : undefined,
                      };

                      try {
                        if (assignToExistingMembership) {
                          const existingMembership = matrixUsers.find((em) => em.memberId === submittedForm.memberId);
                          if (!existingMembership) {
                            throw new Error('Existing membership not found');
                          } else {
                            updatedUser.firstName = existingMembership.firstName;
                            updatedUser.lastName = existingMembership.lastName;
                          }
                        }
                      } catch (err) {
                        handleError(err);
                      }

                      if (user.memberId) {
                        updatedUser.memberId = user.memberId;
                      } else if (userOptions.membershipNeeded && !assignToExistingMembership) {
                        updatedUser.memberId = -1;
                      } else {
                        updatedUser.memberId = submittedForm.memberId;
                      }

                      await onFormSubmitted(updatedUser, isDefaultContact);
                    })}
                  >
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={12} sx={{ display: userOptions.membershipNeeded ? 'block' : 'none' }}>
                        {productPromotions.length < 1 && <ErrorIconText text="There are no subscription product templates available for this fleet" />}
                        <CustomSelectInput<FleetProductTemplate>
                          label="Subscription Product"
                          name="fleetTemplateId"
                          disabled={productPromotions.length < 1}
                          style={{ flex: '1', display: 'flex' }}
                          rules={{
                            required: userOptions.membershipNeeded ? 'Subscription Product required' : false,
                          }}
                          items={productPromotions}
                          itemValue={(p) => p.fleetTemplateId}
                          itemDisabled={(item) => !item.fleetTemplateId}
                          itemKey={(p) => `template-${p.fleetTemplateId}`}
                          itemDisplay={(item: FleetProductTemplate) => item.friendlyName || item.productName}
                          selectStyle={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} sx={{ visibility: assignToExistingMembership ? 'hidden' : 'visible' }}>
                        <CustomTextInput
                          disabled={Boolean(user.firstName) || assignToExistingMembership}
                          fullWidth
                          label="First Name"
                          name="firstName"
                          rules={{
                            required: assignToExistingMembership ? false : 'First Name required',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={userOptions.loginNeeded ? 4 : 8} sx={{ visibility: assignToExistingMembership ? 'hidden' : 'visible' }}>
                        <CustomTextInput
                          autoComplete={false}
                          disabled={Boolean(user.lastName) || assignToExistingMembership}
                          fullWidth
                          label="Last Name"
                          name="lastName"
                          rules={{
                            required: assignToExistingMembership ? false : 'Last Name required',
                          }}
                          showAdornment={(existingUsers.length > 0 && !(Boolean(user.lastName) || assignToExistingMembership))}
                          endAdornment={(
                            <InputAdornment position="end">
                              <Tooltip title="Existing Users" aria-label="existing users">
                                <IconButton
                                  aria-label="existing-users"
                                  edge="end"
                                  onClick={() => setExistingUsersModalOpen(true)}
                                  tabIndex={-1}
                                  size="large"
                                >
                                  <ErrorIcon sx={{ fill: theme.palette.warning.main }} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          )}
                        />

                        <Dialog
                          maxWidth="lg"
                          onClose={() => setExistingUsersModalOpen(false)}
                          open={existingUsersModalOpen}
                          scroll="paper"
                        >
                          <DialogTitle>Existing Users</DialogTitle>
                          <DialogContent>
                            <Typography sx={{ marginBottom: '28px' }}>
                              Before adding a new user, please verify that the user you are trying to add does not already exist.
                              <br />
                              <br />
                              If the user you are trying to add is listed below, navigate to that user and add a login/membership from the user details dialog.
                            </Typography>
                            <List>
                              {existingUsers.map((eu, i) => (
                                <ListItem key={`existing-user-${i}`}>
                                  <ListItemAvatar>
                                    <div style={{ marginRight: '4px' }}>
                                      {eu.loginIcon}
                                      {' '}
                                      {eu.membershipIcon}
                                    </div>
                                  </ListItemAvatar>
                                  <ListItemText primary={eu.fullName} />
                                </ListItem>
                              ))}
                            </List>
                          </DialogContent>
                        </Dialog>
                      </Grid>
                      <Grid item xs={12} md={4} sx={{ display: userOptions.loginNeeded ? 'flex' : 'none' }}>
                        <EmailTextInput
                          disabled={Boolean(user.account?.email)}
                          isCheckingEmailCallback={(v: boolean) => {
                            setIsCheckingEmail(v);
                          }}
                          enforceValidation={userOptions.loginNeeded}
                        />
                      </Grid>

                      {userOptions.membershipNeeded && (
                        <Fade in timeout={{ enter: 1000, exit: 200 }}>
                          <Grid item xs={12}>
                            <Grid container spacing={2} xs={12}>

                              {isEmployee && (
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  sx={
                                    styles.nestedAssignExistingGridItemAdjust
                                  }
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{ minHeight: '86px' }}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      sx={{ marginBottom: '8px' }}
                                    >
                                      <InputLabel
                                        sx={{ display: 'inline-block' }}
                                        disabled={matrixUsers.length < 1}
                                      >
                                        Assign to existing membership?
                                      </InputLabel>
                                      <Switch
                                        sx={{ display: 'flex', flex: '1' }}
                                        color="primary"
                                        disabled={matrixUsers.length < 1}
                                        onChange={(e, checked) => {
                                          setAssignToExistingMembership(checked);
                                        }}
                                      />
                                    </Box>
                                  </Box>

                                  {matrixUsers.length > 0 ? (
                                    <CustomSelectInput<PortalApiMatrixUser>
                                      style={{
                                        display: assignToExistingMembership
                                          ? 'flex'
                                          : ' none',
                                      }}
                                      label="Select Existing Matrix Membership"
                                      itemDisplay={(item) => (getMatrixUserDisplayItem(item))}
                                      rules={requiredIfTrue(
                                        'Select Existing Matrix Membership required',
                                        assignToExistingMembership,
                                      )}
                                      itemKey={get('memberId')}
                                      itemValue={get('memberId')}
                                      items={matrixUsers}
                                      name="memberId"
                                      renderValue={() => getMatrixUserDisplayItem(matrixUsers.find((m) => m.memberId === watchMemberId))}
                                    />
                                  ) : (

                                    <NoResults style={{ padding: '5px' }}>
                                      Unable to link to an existing membership because there were no available memberships found
                                    </NoResults>

                                  )}
                                </Grid>
                              )}

                              <Grid
                                item
                                xs={12}
                                md={8}
                                sx={{ paddingRight: '0px' }}
                              >

                                {/* EXISTING MEMBERSHIP INFO */}
                                <Grid
                                  container
                                  spacing={2}
                                  xs={12}
                                  sx={{ maxWidth: 'unset', display: assignToExistingMembership && selectedMatrixMember ? 'flex' : 'none', marginTop: '3px' }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.nestedGridItemAdjust}
                                  >

                                    <Summary>
                                      <SummarySection title={selectedMatrixMember?.memberId.toString() ?? '< Member Id Unavailable >'}>
                                        {selectedMatrixMember?.startDate && (
                                          <SectionRow>
                                            <ConfirmLabelValue
                                              label="Future Start Date"
                                              value={selectedMatrixMember?.startDate}
                                            />
                                          </SectionRow>
                                        )}
                                        <SectionRow>
                                          <ConfirmLabelValue
                                            label="First Name"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.firstName)}
                                          />
                                          <ConfirmLabelValue
                                            label="Last Name"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.lastName)}
                                          />
                                        </SectionRow>
                                        <SectionRow>
                                          <ConfirmLabelValue
                                            label="Alternate ID"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.alternateId)}
                                          />
                                          <ConfirmLabelValue
                                            label="Cross Reference ID"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.crossRefId)}
                                          />
                                          <ConfirmLabelValue
                                            label="DL Number"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.driverLicenseNumber)}
                                          />
                                        </SectionRow>
                                        <SectionRow>
                                          <ConfirmLabelValue
                                            containerStyle={{ marginRight: 'unset' }}
                                            label="Address"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.address1)}
                                          />
                                          <ConfirmLabelValue
                                            containerStyle={{ marginRight: 'unset' }}
                                            label="City"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.city)}
                                          />
                                          <ConfirmLabelValue
                                            containerStyle={{ marginRight: 'unset' }}
                                            label="State"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.state)}
                                          />
                                          <ConfirmLabelValue
                                            containerStyle={{ marginRight: 'unset' }}
                                            label="Zip"
                                            value={getValueOrReplacementValue(selectedMatrixMember?.zipCode)}
                                          />
                                        </SectionRow>
                                      </SummarySection>
                                    </Summary>

                                  </Grid>
                                </Grid>

                                {/* EXISTING NEW MEMBERSHIP INFO */}
                                <Grid
                                  container
                                  spacing={2}
                                  xs={12}
                                  sx={{ maxWidth: 'unset', display: assignToExistingMembership ? 'none' : 'flex' }}
                                >

                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.nestedGridItemAdjust}
                                  >
                                    <Box sx={{ position: 'relative' }}>

                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ position: 'relative' }}>
                                          <HelpDialog tooltipTitle="Click for more information about Future Start Date" style={{ left: '-1px', top: '-19px' }}>
                                            <Typography variant="h5" gutterBottom>
                                              Future Start Date
                                            </Typography>

                                            <List>
                                              <ListItem sx={{ alignItems: 'flex-start' }}>
                                                <ListItemText
                                                  sx={{ alignItems: 'flex-start' }}
                                                  secondary={(
                                                    <Box>
                                                      <Box>
                                                        Future Start Date indicates the day on which this membership will be activated.

                                                        The membership will remain inactive until the given future date has been reached.
                                                      </Box>
                                                      <Box sx={{ marginTop: '16px' }}>
                                                        If Future Start Date is left blank, this membership will be active and available for use immediately.
                                                      </Box>
                                                    </Box>
                                                  )}
                                                />
                                              </ListItem>
                                            </List>
                                          </HelpDialog>
                                        </Box>
                                        <Box sx={{ marginLeft: '32px', width: '100%' }}>
                                          <CustomDateInput
                                            label="Future Start Date"
                                            name="startDate"
                                            minDate={getCentralNowDate()}
                                          />
                                        </Box>
                                      </Box>

                                    </Box>

                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.nestedGridItemAdjust}
                                  >
                                    <CustomTextInput
                                      fullWidth
                                      label="DL Number"
                                      name="dlNumber"
                                    />
                                  </Grid>

                                  {userProfile?.isEmployee && (
                                    <Grid
                                      item
                                      xs={12}
                                      sx={styles.nestedGridItemAdjust}
                                    >
                                      <CustomTextInput
                                        fullWidth
                                        label="Alternate ID"
                                        name="alternateId"
                                      />
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.nestedGridItemAdjust}
                                  >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <Box sx={{ position: 'relative' }}>
                                        <CrossRefereceHelp style={{ left: '-1px', top: '-14px' }} />
                                      </Box>
                                      <Box sx={{ marginLeft: '32px', width: '100%' }}>
                                        <CustomTextInput
                                          fullWidth
                                          label="Cross Reference ID"
                                          name="crossRefId"
                                        />
                                      </Box>
                                    </Box>

                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      ...styles.nestedGridItemAdjust,
                                      display: forceCustomContactValue ? 'none' : 'flex',
                                    }}
                                  >

                                    <FormControl component="fieldset" disabled={forceCustomContactValue}>
                                      <FormLabel component="legend">Contact Info</FormLabel>
                                      <RadioGroup row aria-label="contact info" name="contact-info" value={useDefaultContact} onChange={handleContactTypeChange}>
                                        <FormControlLabel value="default" control={<Radio color="primary" />} label="Use fleet default contact info" />
                                        <FormControlLabel value="custom" control={<Radio color="primary" />} label="Use custom contact info" />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>

                                  {isFetchingDefaultContact ? (
                                    <>
                                      <TvcSkeleton entityType="default" />
                                      <TvcSkeleton entityType="default" />
                                      <TvcSkeleton entityType="default" />
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={styles.nestedGridItemAdjust}
                                      >
                                        <CustomTextInput
                                          fullWidth
                                          label="Cell Phone"
                                          name="phoneCell"
                                          disabled={isDefaultContact}
                                          rules={{
                                            ...requiredIfTrue(
                                              'Cell Phone required',
                                              enforceValidation,
                                            ),
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <CustomAddressForm
                                          isReadOnly={isDefaultContact}
                                          useAddress2={false}
                                          // COUNTRY
                                          countryFieldOptions={
                                            {
                                              disabled: isDefaultContact,
                                              rules: {
                                                ...requiredIfTrue(
                                                  'Country is required',
                                                  enforceValidation,
                                                ),
                                              },
                                            }
                                          }
                                          // ADDRESS 1
                                          address1FieldOptions={{
                                            name: 'address',
                                            disabled: isDefaultContact,
                                            rules: {
                                              ...requiredIfTrue(
                                                'Address required',
                                                enforceValidation,
                                              ),
                                            },
                                          }}
                                          // CITY
                                          cityFieldOptions={
                                            {
                                              disabled: isDefaultContact,
                                              rules: {
                                                ...requiredIfTrue(
                                                  'City required',
                                                  enforceValidation,
                                                ),
                                              },
                                            }
                                          }
                                          // STATE
                                          stateFieldOptions={
                                            {
                                              disabled: isDefaultContact,
                                              rules: {
                                                ...requiredIfTrue(
                                                  'State/Province required',
                                                  enforceValidation,
                                                ),
                                                validate: enforceValidation // NOTE: We're doing this because this is the only spot in the code base we need this logic
                                                  ? undefined // use the default isValidSelection function defined in CustomAddressForm for the state field
                                                  : {
                                                    isValidSelection: () => (true), // override the default isValidSelection function to always return true
                                                  },
                                              },
                                            }
                                          }
                                          // ZIP
                                          postalCodeFieldOptions={
                                            {
                                              name: 'zipCode',
                                              disabled: isDefaultContact,
                                              rules: {
                                                ...requiredIfTrue(
                                                  'Zip Code required',
                                                  enforceValidation,
                                                ),
                                              },
                                            }
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}

                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>
                        </Fade>
                      )}

                      {userOptions.loginNeeded
                        && (
                          <Grid item xs={12}>
                            <ProfilePermissions
                              hideExpandIcon
                              lockExpanded
                              detailsStyle={{
                                border: 'thick solid whitesmoke',
                              }}
                              apiValidationErrors={[]} // TODO: Deal with this
                              permissionsChildren={(
                                <UserPermissions
                                  hideSpecialPermissionsCheckbox={hideSpecialPermissionsCheckbox}
                                  userRequiresSpecialPermissions={lockPermissions}
                                  lockPermissionsReason={lockPermissionsReason}
                                  lockPermissions={lockPermissions}
                                  allPermissionsSelector={allPermissionsSelector}
                                  userPermissions={permissions}
                                  permissionFilter={permissionFilter}
                                  onPermissionsChanged={(changedPermissions) => {
                                    const selectedPermissions = changedPermissions.filter(
                                      (p) => p.isSelected,
                                    );
                                    setPermissions(selectedPermissions);
                                  }}
                                  onNeedSpecialPermissionsChanged={(needPermissions: boolean) => {
                                    setRequiresSpecialPermissions(needPermissions);
                                  }}
                                  resetToUserPermissions={Boolean(
                                    // apiValidationErrors.length || error,
                                    [].length,
                                  )}
                                  useSubmitButton={false}
                                  userCanEditPermissions={userCanEditPermissions}
                                />

                              )}
                            />
                          </Grid>
                        )}

                      <Grid
                        item
                        xs={12}
                        sx={styles.submitButtonGridItemAdjust}
                      >
                        <Grid container xs={12}>
                          <Grid item xs={12} md={4} />
                          <Grid item xs={12} md={8}>
                            <Box display="flex" flexDirection="column">
                              {userOptions.loginNeeded && form.formState.dirtyFields.email && !form.formState.errors.email && (
                                <Alert severity="info" sx={{ marginBottom: '10px' }}>
                                  An email will be sent to
                                  {' '}
                                  <span style={{ fontWeight: 'bold' }}>{watchEmail}</span>
                                  {' '}
                                  containing a temporary password and login instructions.

                                </Alert>
                              )}

                              {userOptions.loginNeeded && requiresSpecialPermissions && !permissions.some((p) => p.isSelected)

                                && (
                                  <Alert severity="error" sx={{ marginBottom: '10px' }}>
                                    At least one permission must be selected
                                  </Alert>
                                )}
                              <BusySubmitButton
                                fullWidth
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={
                                  !formState.isValid
                                  || formState.isSubmitting
                                  || isCheckingEmail
                                  || isFetchingDefaultContact
                                  || (userOptions.loginNeeded && requiresSpecialPermissions && !permissions.some((p) => p.isSelected))
                                }
                                actionPending={formState.isSubmitting}
                              >
                                Submit
                              </BusySubmitButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </FormWrapper>
            </div>
          </Fade>
        )}
      </>

    </>
  );
};

export default AddAUser;
