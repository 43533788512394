import React from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoneyIcon from '@mui/icons-material/Money';
import { Tooltip } from '@mui/material';
import { PaymentMethod } from '../../../shared/models/payment-method/PaymentMethod';
import { PaymentMethodType } from '../../../shared/enums/payment-method/PaymentMethodType';
import { paymentMethodText } from './PaymentMethodCard';

export const getPaymentMethodIcon = (pmType: PaymentMethodType, isDisabled?: boolean) => {
  let ret: JSX.Element;

  switch (pmType) {
    case PaymentMethodType.BankAccount:
      ret = <AccountBalanceIcon color={isDisabled ? 'disabled' : 'inherit'} />;
      break;
    case PaymentMethodType.CreditCard:
      ret = <CreditCardIcon color={isDisabled ? 'disabled' : 'inherit'} />;
      break;
    case PaymentMethodType.Check:
      ret = <MoneyIcon color={isDisabled ? 'disabled' : 'inherit'} />;
      break;
    default:
      ret = <></>;
  }

  return (
    <>
      <Tooltip title={`Payment Method Type: ${paymentMethodText(pmType)}`} aria-label="payment method type">
        {ret}
      </Tooltip>
    </>
  );
};
interface PaymentMethodIconProps {
  isDisabled?: boolean;
  paymentMethod: PaymentMethod;
}
const PaymentMethodIcon = (props: PaymentMethodIconProps) => {
  const { paymentMethod, isDisabled = false } = props;

  return getPaymentMethodIcon(paymentMethod.paymentMethodType, isDisabled);
};

export default PaymentMethodIcon;
