import {
  Box,
  Checkbox,
  Fab, Fade, FormControlLabel, SelectChangeEvent, Tooltip, Zoom,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import {
  flow, includes, join, map, pick, toLower, values,
} from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import EntityCollectionHeader from '../../../shared/components/EntityCollectionHeader';
import EntityCollectionPage from '../../../shared/components/EntityCollectionPage';
import { useFleetContractModal } from '../hooks/useFleetContractModal';
import { useSearchStyles } from '../../../shared/styles/searchStyles';
import { fabZoomTimeout } from '../../../shared/transitions/timeouts';
import { isListViewSelected, selectIncludeInActiveFleets, setIncludeInactiveFleets } from '../../application/applicationSlice';
import AddAFleetModal from '../AddAFleetModal';
import FleetSearchGridItem from './FleetSearchGridItem';
import FleetSearchListItem from './FleetSearchListItem';
import LoadFleetContractModal from '../LoadFleetContractModal';
import { fabTransitionDelay } from '../../../shared/transitions/transitionDelays';
import { useSearchText } from '../../../shared/hooks/useSearchText';
import ClearableTextField from '../../../shared/components/ClearableTextField';
import { FleetUserPermissions } from '../../auth/permissionNames';
import { useProfile } from '../../auth/hooks/useProfile';
import ShowHide from '../../../shared/components/ShowHide';
import FilterSearchWrapper from '../../../shared/components/FilterSearchWrapper';
import FleetInvalidConditionsFilter, { fleetInvalidConditions } from './FleetInvalidConditionsFilter';
import { filterOptions } from '../../../shared/components/search-filter/filterOptions';
import { useFilterStyles } from '../../user/search/useFilterStyles';
import FleetSearchHelp from './FleetSearchHelp';
import { useBreakPoints } from '../../../shared/hooks/useBreakPoints';
import { RangeSlider, OnRangeChangedProps } from '../../../shared/components/RangeSlider';
import { fetchFleets } from '../fleetSlice';
import FleetActiveFilter, { fleetActiveStatuses } from './FleetActiveFilter';
import FleetUsageFilter, { fleetUsages } from './FleetUsageFiltersx';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import HelmetTitle from '../../../shared/components/HelmetTitle';

const defaultRangeSelection: OnRangeChangedProps = {
  isActive: false,
  range: [0, 50000],
};

const FleetSearch = () => {
  const commonSearchStyles = useSearchStyles();
  const isListView = useSelector(isListViewSelected);
  const includeInactiveFleets = useSelector(selectIncludeInActiveFleets);
  const { userProfile } = useProfile();
  const [selectedFleetInvalidConditions, setSelectedFleetInvalidConditions] = useState<string[]>([]);
  const [selectedFleetUsage, setSelectedFleetUsage] = useState<string[]>([]);
  const [selectedFleetActiveStatuses, setSelectedFleetActiveStatuses] = useState<string[]>([]);
  const [filterOptionsAreVisible, setFilterOptionsAreVisible] = useState(false);
  const [hasToggledIncludeInactive, setHasToggledIncludeInactive] = useState(false);
  const { styles } = useFilterStyles({ filterOptionsAreVisible });
  const { isXsDown } = useBreakPoints();
  const dispatch = useAppDispatch();

  const [rangeSelection, setRangeSelection] = useState<OnRangeChangedProps>(defaultRangeSelection);

  const handleFleetUsageSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setSelectedFleetUsage(value as string[]);
  };

  const handleFleetInvalidConditionSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setSelectedFleetInvalidConditions(value as string[]);
  };

  const handleFleetActiveStatusSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setSelectedFleetActiveStatuses(value as string[]);
  };

  const {
    loadFleetContractModalOpen,
    selectedFleet,
    closeLoadFleetContractModal,
    openLoadFleetContractModal,
  } = useFleetContractModal();

  const { searchText, deferedSearchText, setSearchText, searchTextHasValue } = useSearchText();
  const [importAFleetModalOpen, setImportAFleetModalOpen] = useState(false);

  const fleetActiveStatusFilter = (fleet: Fleet) => filterOptions<Fleet>(fleet, fleetActiveStatuses, selectedFleetActiveStatuses);
  const fleetStatusFilter = (fleet: Fleet) => filterOptions<Fleet>(fleet, fleetInvalidConditions, selectedFleetInvalidConditions);
  const fleetUsageFilter = (fleet: Fleet) => filterOptions<Fleet>(fleet, fleetUsages, selectedFleetUsage);
  const fleetLowContractBalanceFilter = (fleet: Fleet) => (rangeSelection.isActive ? fleet.carrierId ? fleet.contractBalance >= rangeSelection.range[0] && fleet.contractBalance <= rangeSelection.range[1] : false : true);

  const fleets = useSelector((state: RootState) => state.fleets.fleets.filter(
    flow(
      pick(['name', 'carrierId', 'organizationId', 'rootMemberId']),
      values,
      map(toLower),
      join(' '),
      includes(deferedSearchText.toLowerCase()),
    ),
  ))
    .filter(fleetStatusFilter)
    .filter(fleetActiveStatusFilter)
    .filter(fleetUsageFilter)
    .filter(fleetLowContractBalanceFilter);

  const canViewContractBalanceFilter = fleets.some((f) => f.cachePermissions?.permissions[FleetUserPermissions.canViewFleetFuelContractBalance]);

  const toggleJsxItems = (fleetList: Fleet[]) => (isListView
    ? fleetList.map((fleet) => (
      <FleetSearchListItem
        key={`fleet-${fleet.id}`}
        item={fleet}
        onPrimaryActionClick={openLoadFleetContractModal(fleet)}
        hideLastDivider={
          fleets.length > 0 && fleets[fleets.length - 1].id === fleet.id
        }
      />
    ))
    : fleetList.map((fleet) => (
      <FleetSearchGridItem
        key={`fleet-${fleet.id}`}
        item={fleet}
        onPrimaryActionClick={openLoadFleetContractModal(fleet)}
      />
    )));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasToggledIncludeInactive(true);
    dispatch(setIncludeInactiveFleets(event.target.checked));
    // setIncludeInactiveFleets(event.target.checked);
  };

  const reFetchFleets = async (includeInactive: boolean = false) => {
    await dispatch(fetchFleets(includeInactive));
  };

  const clearFilterSelections = () => {
    setSelectedFleetInvalidConditions([]);
    setRangeSelection(defaultRangeSelection);
    setSelectedFleetActiveStatuses([]);
    setSelectedFleetUsage([]);
  };

  useEffect(() => {
    if (hasToggledIncludeInactive) {
      clearFilterSelections();

      if (!includeInactiveFleets) {
        setSelectedFleetActiveStatuses([]);
      }

      reFetchFleets(includeInactiveFleets);
    }
  }, [hasToggledIncludeInactive, includeInactiveFleets]);

  return (
    <>
      <HelmetTitle subTitle="Fleet Search" />
      <Fade in>
        <Box sx={{ width: '100%' }}>
          <EntityCollectionPage
            entityName="fleet"
            entityCollecitonHeader={(
              <EntityCollectionHeader
                centerBlock={(
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box display="flex" alignItems="center" flex={1} position="relative">
                      {userProfile?.isEmployee && (
                        <FleetSearchHelp
                          canViewContractFilter={canViewContractBalanceFilter}
                          style={{
                            top: isXsDown ? '-24px' : '-10px',
                            left: isXsDown ? '0px' : '-4px',
                          }}
                        />
                      )}
                      <form style={{ ...styles.searchForm, marginTop: '10px' }}>
                        <Box sx={styles.textFieldBox} flexWrap="wrap">
                          <ClearableTextField
                            sx={{ ...commonSearchStyles.searchTextField, alignSelf: 'center' }}
                            showAdornment={searchTextHasValue}
                            onAdornmentClick={() => setSearchText('')}
                            value={searchText}
                            onChange={(e) => setSearchText(e.currentTarget.value)}
                            label="Filter Fleets"
                            variant="standard"
                          />
                        </Box>
                        <Box sx={styles.filterContainer}>
                          <FilterSearchWrapper
                            optionsAreSelected={
                              selectedFleetInvalidConditions.length > 0 || rangeSelection.isActive || selectedFleetActiveStatuses.length > 0 || selectedFleetUsage.length > 0
                            }
                            visibilityCallback={(isvisible: boolean) => {
                              setFilterOptionsAreVisible(isvisible);
                            }}
                            clearFiltersFunc={() => {
                              clearFilterSelections();
                            }}
                          >
                            <FleetInvalidConditionsFilter
                              handleSelection={handleFleetInvalidConditionSelected}
                              value={selectedFleetInvalidConditions}
                            />

                            <FleetUsageFilter
                              handleSelection={handleFleetUsageSelected}
                              value={selectedFleetUsage}
                            />

                            <FleetActiveFilter
                              handleSelection={handleFleetActiveStatusSelected}
                              value={selectedFleetActiveStatuses}
                              style={{ display: includeInactiveFleets ? 'flex' : 'none' }}
                            />

                            {/* TODO: Address responsive issues with RangeSlider on smaller device widths */}
                            {canViewContractBalanceFilter && (
                              <div style={{
                                flexGrow: 1,
                              }}
                              >
                                <RangeSlider
                                  defaultValue={defaultRangeSelection}
                                  reset={!rangeSelection.isActive}
                                  onRangeChanged={(rangeSelected: OnRangeChangedProps) => {
                                    setRangeSelection(rangeSelected);
                                  }}
                                />
                              </div>
                            )}

                          </FilterSearchWrapper>
                        </Box>
                      </form>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={includeInactiveFleets}
                            onChange={handleChange}
                            name="includeInactiveFleets"
                            color="primary"
                          />
                        )}
                        label="Include Inactive Fleets"
                      />
                    </Box>
                  </Box>
                )}
                rightBlock={(
                  <>
                    <ShowHide show={userProfile?.userPermissions?.canAddFleet}>
                      <Zoom
                        in
                        timeout={fabZoomTimeout}
                        unmountOnExit
                        style={{ transitionDelay: fabTransitionDelay }}
                      >
                        <Box>
                          <Tooltip title="Add a Fleet" aria-label="add a fleet">
                            <Fab
                              aria-label="Add fleet"
                              variant="extended"
                              size="small"
                              color="secondary"
                              onClick={() => setImportAFleetModalOpen(true)}
                            >
                              <Add />
                              {' '}
                              Add Fleet
                            </Fab>
                          </Tooltip>
                        </Box>
                      </Zoom>
                    </ShowHide>
                  </>
                )}
              />
            )}
            resetPagingDependencies={[deferedSearchText, selectedFleetInvalidConditions, rangeSelection, selectedFleetActiveStatuses, selectedFleetUsage]}
            jsxItems={toggleJsxItems(fleets)}
            activePageColor="secondary"
          >
            <>
              {selectedFleet && selectedFleet.carrierId && selectedFleet.cachePermissions?.permissions[FleetUserPermissions.canLoadFleetFuelContract] && (
                <div>
                  <LoadFleetContractModal
                    open={loadFleetContractModalOpen}
                    fleet={selectedFleet}
                    onClose={closeLoadFleetContractModal}
                    canViewPendingLoads={selectedFleet?.cachePermissions?.permissions[
                      FleetUserPermissions.canViewFleetFuelActivity
                    ]}
                  />
                </div>
              )}
              {userProfile?.userPermissions?.canAddFleet && (
                <AddAFleetModal
                  open={importAFleetModalOpen}
                  onClose={() => setImportAFleetModalOpen(false)}
                  userFleetPermissions={
                    fleets[0] ? fleets[0].cachePermissions : undefined
                  }
                />
              )}
            </>
          </EntityCollectionPage>
        </Box>
      </Fade>
    </>
  );
};

export default FleetSearch;
