import React from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import IconText from '../../shared/components/icon-components/IconText';
import EmailTextInput from '../../shared/components/formFields/EmailTextInput';

interface EmailAddressFormData {
  email: string;
}

interface EmailAddressFormProps {
  onFormSubmit: (data: EmailAddressFormData) => void;
  isBusy?: boolean;
}
const EmailAddressForm = (props: EmailAddressFormProps) => {
  const theme = useTheme();
  const { onFormSubmit, isBusy } = props;
  const emailForm = useForm<EmailAddressFormData>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const {
    handleSubmit, watch, formState: { isValid },
  } = emailForm;

  const watchEmail = watch('email');

  return (
    <FormProvider {...emailForm}>
      <form
        style={{ flexDirection: 'column', display: 'flex' }}
        onSubmit={handleSubmit(async (submittedForm) => {
          onFormSubmit(submittedForm);
        })}
      >

        <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
          <Grid item xs={12}>
            <EmailTextInput authenticatedCheck={false} label="New Email Address" requiredText="New Email Address required" />
          </Grid>
          {isValid && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconText
                icon={<CheckIcon htmlColor={theme.palette.success.main} />}
                text={`${watchEmail} is available for use in the portal`}
                textStyle={{ color: theme.palette.success.main }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <BusySubmitButton
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              disabled={!isValid}
              actionPending={Boolean(isBusy)}
              containerStyles={{ display: 'flex', justifyContent: 'center' }}
            >
              Send Verification Code
            </BusySubmitButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default EmailAddressForm;
