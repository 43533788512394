import { Message } from '../../../shared/models/message/Message';
import { initAxiosInstance } from './utils';

export const useMessageApi = () => {
  const portalClient = initAxiosInstance();

  const getFleetMessages = async (fleetId: string): Promise<Message[]> => {
    const { data } = await portalClient.get<Message[]>(`/message/fleet/${fleetId}`);
    return data;
  };

  const getIndividualMessages = async (userId: string): Promise<Message[]> => {
    const { data } = await portalClient.get<Message[]>(`/message/user/${userId}`);

    return data;
  };

  return {
    getFleetMessages,
    getIndividualMessages,
  };
};
