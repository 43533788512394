import { useHistory } from 'react-router-dom';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import { FleetUserPermissions } from '../../auth/permissionNames';
import { useProfile } from '../../auth/hooks/useProfile';

interface PortalRouteTemplate {
  fallback: string;
  account: string;
  dashboard: string;
  users: string;
  fleetFuel: string;
  fleetLegal: string;
}

interface UseFleetNavigationProps {
  fleet: Fleet | undefined;
}

export const fleetClickedRouteTemplates: PortalRouteTemplate = {
  fallback: '/fleets',
  account: '/fleets/{0}/account',
  dashboard: '/fleets/{0}/dashboard',
  users: '/fleets/{0}/users',
  fleetFuel: '/fleets/{0}/fleet-fuel',
  fleetLegal: '/fleets/{0}/legal',
};

export const useFleetNavigation = (props: UseFleetNavigationProps) => {
  const { fleet } = props;
  const history = useHistory();
  const { userProfile } = useProfile();
  const getAppropriateFleetClickedRoute = (
    fleetClicked: Fleet | undefined,
  ): string => {
    // order of precendence and available permissions

    // Don't ever use dashboard route as appropriate fleet clicked route for employees
    if (!userProfile?.isEmployee && fleetClicked?.cachePermissions?.permissions[FleetUserPermissions.canAccessFleetDashboard]
    ) {
      return fleetClickedRouteTemplates.dashboard.replace(
        '{0}',
        fleetClicked.id,
      );
    }

    if (
      fleetClicked?.cachePermissions?.permissions[FleetUserPermissions.canViewFleetAccount]
    ) {
      return fleetClickedRouteTemplates.account.replace(
        '{0}',
        fleetClicked.id,
      );
    }

    if (
      fleetClicked?.cachePermissions?.permissions[FleetUserPermissions.canViewFleetUsers]
    ) {
      return fleetClickedRouteTemplates.users.replace(
        '{0}',
        fleetClicked.id,
      );
    }

    if (
      fleetClicked?.cachePermissions?.permissions[FleetUserPermissions.canViewFleetFuelRoute]
    ) {
      return fleetClickedRouteTemplates.fleetFuel.replace(
        '{0}',
        fleetClicked.id,
      );
    }

    if (
      fleetClicked?.cachePermissions?.permissions[FleetUserPermissions.canViewFleetLegalCases]
    ) {
      return fleetClickedRouteTemplates.fleetLegal.replace(
        '{0}',
        fleetClicked.id,
      );
    }

    return fleetClickedRouteTemplates.fallback;
  };

  const navigateToAppropriateFleetClickedRoute = () => {
    history.push(getAppropriateFleetClickedRoute(fleet));
  };

  return { navigateToAppropriateFleetClickedRoute };
};
