import { useTheme } from '@mui/material/styles';
import React from 'react';

interface UseFilterStylesProps {
  filterOptionsAreVisible: boolean;
}

export const useFilterStyles = (props: UseFilterStylesProps) => {
  const { filterOptionsAreVisible } = props;
  const theme = useTheme();
  const searchFormStyles:React.CSSProperties = {
    display: 'flex',
    flex: '1',
    flexWrap: 'wrap',
    alignSelf: 'stretch',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  };
  const styles = {
    searchForm: searchFormStyles,
    textFieldBox: {
      display: 'flex',
      marginRight: '20px',
      [theme.breakpoints.down('md')]: {
        marginRight: '0px',
        flexBasis: '100%',
      },
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      flex: '1',
      [theme.breakpoints.down('xl')]: {
        flexBasis: filterOptionsAreVisible ? '100%' : 'unset',
        marginTop: filterOptionsAreVisible ? '10px' : 'initial',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '20px',
      },
    },
  };

  return { styles };
};
