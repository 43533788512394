import React from 'react';
import LabelValue from '../LabelValue';
import LimitDefaultLabel, { LimitLabelFormat } from './LimitDefaultLabel';

interface LimitInputDisplayProps {
  limitValue: number | undefined | null;
  defaultLimitValue: number | undefined | null;
  label?: React.ReactNode;
  value: React.ReactNode;
  isInEditMode: boolean;
  format?: LimitLabelFormat;
  customLabel?: React.ReactNode;
}

const LimitInputDisplay = (props: LimitInputDisplayProps) => {
  const {
    limitValue,
    defaultLimitValue,
    label = 'No label defined',
    value,
    isInEditMode,
    format = 'currency',
    customLabel,
  } = props;

  return (
    <>
      <LabelValue
        applyBoxStyles={false}
        label={
          customLabel || (
            <LimitDefaultLabel
              label={label}
              limitValue={limitValue}
              defaultLimitValue={defaultLimitValue}
              format={format}
              isInEditMode={isInEditMode}
            />
          )
        }
        value={value}
      />
    </>
  );
};

export default LimitInputDisplay;
