import React from 'react';
import { LabelValueProps } from '../LabelValue';
import ConfirmLabelValue from './ConfirmLabelValue';

const SubConfirmmLabelValue = (props: LabelValueProps) => {
  const { label, value, ...rest } = props;
  return (
    <ConfirmLabelValue
      {...rest}
      label={label}
      value={value}
      valueStyle={{ fontSize: '0.95rem' }}
    />
  );
};

export default SubConfirmmLabelValue;
