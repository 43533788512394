import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import IconText, { IconTextProps } from './IconText';
import { theme } from '../../../appTheme';

export interface InfoIconTextProps extends Omit<IconTextProps, 'icon'> {
}

const CheckJunkBoxIconText = (props: InfoIconTextProps) => {
  const { ...rest } = props;
  return (
    <IconText
      {...rest}
      text="Please be sure to check your spam/junk box if you do not receive an email within a few minutes"
      icon={<InfoIcon htmlColor={theme.palette.info.main} />}
    />
  );
};

export default CheckJunkBoxIconText;
