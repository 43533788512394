import React from 'react';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import { FilterOption } from '../../../shared/components/search-filter/FilterOption';
import MultiSelectFilter from '../../../shared/components/search-filter/MultiSelectFilter';
import { StatusFilterProps } from '../../../shared/components/search-filter/StatusFilterProps';
import { UserFuelCardIcon } from '../../../shared/components/UserIcons';

export const userFuelCardStatuses: FilterOption<PortalApiUser>[] = [

  {
    displayName: 'Active',
    key: 'active-fuelcard',
    icon: <UserFuelCardIcon
      hasFuelCard
      hasActiveFuelCard
    />,
    condition: (user: PortalApiUser) => user.hasFuelCard && user.hasActiveFuelCard,
  },
  {
    displayName: 'Inactive',
    key: 'inactive-fuelcard',
    icon: <UserFuelCardIcon
      hasFuelCard
      hasActiveFuelCard={false}
    />,
    condition: (user: PortalApiUser) => user.hasFuelCard && !user.hasActiveFuelCard,
  },
  {
    displayName: 'No Fuel Card',
    key: 'no-membership',
    icon: <UserFuelCardIcon
      hasFuelCard={false}
      hasActiveFuelCard={false}
    />,
    condition: (user: PortalApiUser) => !user.hasFuelCard && !user.hasActiveFuelCard,
  },

];

const FuelCardStatusFilter = (props: StatusFilterProps) => {
  const { value, handleSelection, disabled } = props;
  return (
    <MultiSelectFilter<PortalApiUser>
      disabled={disabled}
      inputLabel="Fuel Card Status"
      labelId="filter-fuelcard-label"
      selectId="filter-fuelcard-select"
      value={value}
      handleSelection={handleSelection}
      options={userFuelCardStatuses}
    />
  );
};

export default FuelCardStatusFilter;
