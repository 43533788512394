export enum DiscountStatus {
  Valid,
  Inactive,
  NotFound,
  UnStarted,
  Expired,
  Exhausted,
  InvalidProduct,
  MinimumNotMet,
  AlreadyUsed,
}
