import React, { Suspense, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Grid, Hidden } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useAppDispatch } from './app/store';
import {
  fetchStatesAndProvinces,
  retrievePendingNameChangeRequestsThunk,
  retrieveProMilesThunk,
  setConfiguration,
  showOverlay,
} from './features/application/applicationSlice';
import { useProfile } from './features/auth/hooks/useProfile';
import { fetchFleets, setFleets } from './features/fleet/fleetSlice';
import FleetSearch from './features/fleet/search/FleetSearch';
import MenuBar from './shared/components/nav/MenuBar';
import Nav from './shared/components/nav/Nav';
import { useAsyncErrorHandler } from './shared/hooks/useTvcAppInsights';
import LoadingScreen from './shared/components/LoadingScreen';
import ProtectedRoute from './shared/components/routing/ProtectedRoute';
import { useBreakPoints } from './shared/hooks/useBreakPoints';
import StandardError from './shared/components/StandardError';
import TvcSessionExpiringPrompt from './features/auth/components/TvcSessionExpiringPrompt';
import SystemNotifications from './shared/components/SystemNotifications';

import { Profile } from './shared/models/user/Profile';
import IndividualMembershipSearch from './features/individual-memberships/search/IndividualMembershipSearch';
import { fetchFuelCardMarketingData, setLoggedInUserIsSelectedProfile, setSelectedProfile } from './features/user/userSlice';
import IndividualContactUs from './features/individual-memberships/landing/IndividualContactUs';
import { signInAuthProvider } from './services/authService';
import { useApplicationApi } from './services/api/hooks/useApplicationApi';
import { FleetUserProfile } from './shared/models/fleet/FleetUserProfile';
import { fetchFleetMessagesThunk, fetchIndividualMessagesThunk } from './features/messages/messageSlice';
import { FleetUserPermissions } from './features/auth/permissionNames';
import { fetchLegalDocumentCategories } from './features/legal/legalSlice';
import { useFleetApi } from './services/api/hooks/useFleetApi';
import { useUserApi } from './services/api/hooks/useUserApi';
import { isFleetSuperAdministrator } from './shared/utilities/userHelpers';

const AdminDashboard = React.lazy(
  () => import('./features/admin/AdminDashboard'),
);

const FleetLanding = React.lazy(
  () => import('./features/fleet/landing/FleetLanding'),
);

// EmployeeIndividualMembershipLanding
const EmployeeIndividualMembershipLanding = React.lazy(
  () => import('./features/individual-memberships/landing/EmployeeIndividualMembershipLanding'),
);

const IndividualMembershipLanding = React.lazy(
  () => import('./features/individual-memberships/landing/IndividualMembershipLanding'),
);

const Root = () => {
  const styles = {
    root: {
      background:
        'linear-gradient(270deg, rgba(0,92,157,1) 0%, rgba(23,51,94,1) 100%)',
    },
    container: {
      display: 'flex',
      flexGrow: 1,
      minHeight: '95vh !important',
    },
  };
  const { handleFatalError, handleError } = useAsyncErrorHandler();
  const { userProfile, setUserProfile } = useProfile();
  const dispatch = useAppDispatch();
  const isLoading = useSelector(showOverlay);
  const [allowAccess, setAllowAccess] = useState(true);
  const [isAppDataInitialized, setIsAppDataInitialized] = useState(false);
  const { isMdDown } = useBreakPoints();
  const [defaultRoute, setDefaultRoute] = useState<string>('/fleets');
  const [authorizationErrorReason, setAuthorizationErrorReason] = useState<string>();
  const { getConfiguration } = useApplicationApi();
  const { getFleetProMilesSsoToken } = useFleetApi();
  const { getUserProMilesSsoToken } = useUserApi();
  const setDefaultRouteToAuthorizationError = (reason: string) => {
    setDefaultRoute('/authorization-error');
    setAuthorizationErrorReason(reason);
  };

  const fetchInitializationData = async () => {
    try {
      const configuration = await getConfiguration();
      dispatch(setConfiguration(configuration));
      await dispatch(fetchStatesAndProvinces());
      await dispatch(fetchLegalDocumentCategories());
    } catch (err) {
      handleError(err);
    }
  };

  const disallowAccess = () => {
    setUserProfile(undefined);
    setAllowAccess(false);
  };

  const handleEmployeeLogin = async (up: Profile, fullName: string | undefined) => {
    // pre-fetch pending name change requests
    if (up.userPermissions?.canApproveNameChangeRequests) {
      dispatch(retrievePendingNameChangeRequestsThunk());
    }

    if (up.userPermissions?.canViewAllFleets) {
      await dispatch(fetchFleets(false));
    } else if (up.userPermissions?.canViewPortalAdministratorRoute) {
      setDefaultRoute('/admin');
    } else if (up.userPermissions?.canViewAllIndividualMemberships) {
      setDefaultRoute('/individual-memberships');
    } else {
      // if we get here... then, we have nowhere to redirect them... so, error
      setDefaultRouteToAuthorizationError(`${fullName} does not have any sufficient permissions to access the portal`);
    }
  };

  const handleIndividualMembershipLogin = (up: Profile, fullName: string | undefined) => {
    if (up.portalUser) {
      dispatch(setSelectedProfile(up));
      try {
        dispatch(fetchIndividualMessagesThunk(up.id));
        dispatch(fetchFuelCardMarketingData(up.portalUser));
        dispatch(retrieveProMilesThunk(() => getUserProMilesSsoToken(up.id)));
      } catch (err) {
        handleError(err);
      } finally {
        setDefaultRoute(`/individual-memberships/${up.id}/dashboard`);
      }
    } else {
      setDefaultRouteToAuthorizationError(`Unable to resolve membership for ${fullName} ${up?.memberId !== undefined ? ` (${up.memberId})` : ''}`);
    }
  };

  const handleFleetUserLogin = (up: Profile, userFleets: FleetUserProfile[], fullName: string | undefined) => {
    // at this point in time, fleet logins should only be associated with one fleet
    if (up.isMultiFleetUser) {
      setDefaultRouteToAuthorizationError(`${fullName} is associated with more than one fleet`);
    } else {
      const userFleet = userFleets[0];

      if (!userFleet.isActive) {
        disallowAccess();
      } else {
        const fleetId = userFleet.id;
        dispatch(setFleets(userFleets));
        dispatch(fetchFleetMessagesThunk(fleetId));

        if (isFleetSuperAdministrator(userFleet.permissions)) {
          dispatch(retrieveProMilesThunk(() => getFleetProMilesSsoToken(fleetId)));
        }

        if (userFleet?.cachePermissions?.permissions[FleetUserPermissions.canAccessFleetDashboard]) {
          setDefaultRoute(`/fleets/${fleetId}/dashboard`);
        } else {
          setDefaultRoute(`/fleets/${fleetId}/profile`);
        }
      }
    }
  };

  useEffect(() => {
    if (userProfile) {
      fetchInitializationData();

      const initialize = async () => {
        const userFleets = userProfile.fleets?.length ? userProfile.fleets : [];
        const userFullName = userProfile?.fullName;

        try {
          if (userProfile.isEmployee) {
            await handleEmployeeLogin(userProfile, userFullName);
            return;
          }

          if (!userFleets.length) {
            handleIndividualMembershipLogin(userProfile, userFullName);
          } else {
            handleFleetUserLogin(userProfile, userFleets, userFullName);
            return;
          }
        } catch (err: any) {
          handleFatalError(err);
        }
      };

      initialize().then(() => setIsAppDataInitialized(true));
    }
  }, [userProfile]);

  const logoutUser = async () => {
    await signInAuthProvider.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/forbidden.html`,
    });
  };

  useEffect(() => {
    if (isAppDataInitialized && !allowAccess) {
      logoutUser();
    }
  }, [isAppDataInitialized, allowAccess]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <LoadingScreen isLoading={isLoading} />
        {userProfile && isAppDataInitialized && (
          <div style={{
            position: 'fixed', zIndex: 5000, width: '100%', display: 'flex', justifyContent: 'center',
          }}
          >
            <SystemNotifications />
          </div>
        )}

        {userProfile && isAppDataInitialized && (
          <>
            <MenuBar />
            <Grid container sx={styles.container}>
              <Hidden lgDown>
                <Grid item xs={2} sx={styles.root}>
                  <Nav />
                </Grid>
              </Hidden>
              <Grid
                item
                container
                xs={isMdDown ? 12 : 10}
                sx={{ display: 'flex', overflowX: 'hidden' }}
              >
                <Suspense fallback={<LoadingScreen isLoading />}>
                  <Switch>
                    <Route path="/fleets/:fleetId" component={FleetLanding} />
                    <ProtectedRoute
                      isAuthorized={Boolean(
                        userProfile.userPermissions?.canViewAllFleets,
                      )}
                      path="/fleets"
                      exact
                      component={FleetSearch}
                    />
                    <Route
                      path="/individual-memberships/:userId"
                      render={(props: RouteComponentProps<{ userId: string }>) => {
                        const { userId } = props.match.params;

                        if (userProfile.isEmployee) {
                          return <EmployeeIndividualMembershipLanding />;
                        }

                        const userProfileIsSelectedProfile = (userProfile.id === userId);
                        dispatch(setLoggedInUserIsSelectedProfile(userProfileIsSelectedProfile));

                        if (!userProfileIsSelectedProfile) {
                          window.location.href = '/';
                        }

                        return <IndividualMembershipLanding />;
                      }}
                    />
                    <ProtectedRoute
                      isAuthorized={userProfile?.userPermissions?.canViewAllIndividualMemberships}
                      path="/individual-memberships"
                      exact
                      component={IndividualMembershipSearch}
                    />

                    <ProtectedRoute
                      path="/admin"
                      isAuthorized={
                        userProfile.userPermissions
                          ?.canViewPortalAdministratorRoute
                      }
                      component={AdminDashboard}
                    />
                    <Route
                      path="/authorization-error"
                      render={() => (
                        <StandardError
                          style={{ width: '100%', height: '50px' }}
                          errorTitle={authorizationErrorReason}
                          errorDescription=""
                        />
                      )}
                    />
                    <Route path="/contact-us" component={IndividualContactUs} />
                    <Redirect from="/" to={defaultRoute} />
                  </Switch>
                </Suspense>
              </Grid>
            </Grid>
            <TvcSessionExpiringPrompt
              sessionMinutes={userProfile.isEmployee ? 120 : 60}
            />
          </>
        )}

      </Router>
    </LocalizationProvider>
  );
};

export default Root;
