import React from 'react';
import { Box } from '@mui/material';
import StandardHeader from './StandardHeader';
import { useBreakPoints } from '../hooks/useBreakPoints';

interface StandardContentProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  headerText?: React.ReactNode;
  additionalRightAlignedHeaderContent?: React.ReactNode;
  alignItems?: string
}

const StandardContent = (props: StandardContentProps) => {
  const {
    children, icon, headerText, additionalRightAlignedHeaderContent, alignItems = 'center',
  } = props;
  const { isSmDown } = useBreakPoints();
  return (
    <Box sx={{ marginBottom: '16px' }}>
      <Box display="flex" alignItems={alignItems} justifyContent="space-between" flexDirection={isSmDown ? 'column' : 'row'}>
        <Box>
          <StandardHeader icon={icon} headerText={headerText} />
        </Box>
        {additionalRightAlignedHeaderContent && (
          <Box>{additionalRightAlignedHeaderContent}</Box>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default StandardContent;
