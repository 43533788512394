import React from 'react';
import { useTheme } from '@mui/material/styles';
import EntityActionButton from '../../../shared/components/EntityActionButton';
import { useFleetSearchItem } from '../hooks/useFleetSearchItem';
import FleetCardItemDisplay from '../FleetCardItemDisplay';
import InvalidCarrier from '../../../shared/components/InvalidCarrier';
import FleetSearchBalance from './FleetSearchBalance';
import SearchGridItem from '../../../shared/components/search/SearchGridItem';
import { FleetUserPermissions } from '../../auth/permissionNames';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import SearchItemProps from '../../../shared/models/other/SearchItemProps';

const FleetSearchGridItem = (props: SearchItemProps<Fleet>) => {
  const theme = useTheme();
  const {
    fleet,
    fleetClicked,
    onLoadFundsClick,
    userCanLoadContract,
    userCanViewContractBalance,
    isLoadFundsDisabled,
  } = useFleetSearchItem(props);

  return (
    <SearchGridItem
      itemKey={fleet.id}
      userCanClickItem={Boolean(fleet.cachePermissions?.permissions[FleetUserPermissions.canViewFleetRoute]
        || fleet.cachePermissions?.permissions[FleetUserPermissions.canViewFleetFuelActivity])}
      title={<span style={{ color: !fleet.isActive ? theme.palette.grey[600] : undefined }}>{fleet.name}</span>}
      cardClicked={fleetClicked}
      cardStyles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      userCanViewCardActions={userCanLoadContract || userCanViewContractBalance}
      borderLeftColor={fleet.isActive ? theme.palette.secondary.main : theme.palette.action.disabled}
      minHeight={190.1}
      cardHeaderSubHeader={<FleetCardItemDisplay fleet={fleet} />}
      cardActionChildren={(
        <>
          {userCanLoadContract && (
            <InvalidCarrier isValidCarrier={fleet.isValidCarrier && fleet.hasActiveFuelCards}>
              <EntityActionButton
                onClick={onLoadFundsClick}
                aria-label={`load fuel funds to ${fleet.name}`}
                disabled={isLoadFundsDisabled}
              >
                Load Funds
              </EntityActionButton>
            </InvalidCarrier>
          )}
          {userCanViewContractBalance
            && <FleetSearchBalance balance={fleet.contractBalance} />}
        </>
      )}
    />
  );
};

export default FleetSearchGridItem;
