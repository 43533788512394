import React from 'react';
import { theme } from '../../../appTheme';
import { PaymentMethod } from '../../../shared/models/payment-method/PaymentMethod';

export const CCStatus = ({
  paymentMethod,
}: {
  paymentMethod: PaymentMethod;
}) => {
  const {
    isAvailable, expireYear, expireMonth, validationError,
  } = paymentMethod;
  const displayText = isAvailable ? `Expires ${(`0${expireMonth}`).slice(-2)}/${expireYear}` : validationError;

  return isAvailable ? (
    <>{displayText}</>
  ) : (
    <span style={{ color: theme.palette.error.main }}>
      {displayText}
    </span>

  );
};
