import { ListItem } from '@mui/material';
import React from 'react';

interface SubListItemProps {
  children?: React.ReactNode;
}

const SubListItem = (props: SubListItemProps) => {
  const { children } = props;

  return (
    <ListItem alignItems="flex-start" sx={{ paddingLeft: '50px' }}>
      {children}
    </ListItem>
  );
};

export default SubListItem;
