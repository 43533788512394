import { Box } from '@mui/material';
import React from 'react';
import { theme } from '../../../appTheme';

export interface IconTextProps {
  icon: React.ReactNode;
  text?: React.ReactNode;
  style?: React.CSSProperties;
  hideIcon?: boolean;
  textStyle?: React.CSSProperties;
}

const IconText = (props: IconTextProps) => {
  const {
    icon,
    text,
    style,
    hideIcon = false,
    textStyle,
  } = props;

  return (
    <Box sx={{
      marginBottom: '12px', display: 'flex', alignItems: 'center', ...style,
    }}
    >
      {!hideIcon && icon}
      {' '}
      <Box sx={{ marginLeft: '10px', color: theme.palette.text.secondary, ...textStyle }}>
        {text}
      </Box>
    </Box>
  );
};

export default IconText;
