import * as yup from 'yup';
import {
  cond,
  constant,
  eq,
  find,
  flow,
  includes,
  isEmpty,
  map,
  parseInt as _parseInt,
  toString as _toString,
} from 'lodash/fp';
import { getYear, getMonth } from 'date-fns/fp';
import { getCentralNowDate } from './dateHelpers';

const usOrCandianZipRegEx = new RegExp('(^\\d{5}?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\\d{1}[A-Za-z]{1}\\s?\\d{1}[A-Za-z]{1}\\d{1}$)');
export type Validator = (value: string) => string | undefined;

const returnMsgIfTrue = (msg: string) => cond<boolean, string | undefined>([
  [eq(true), constant(msg)],
  [eq(false), constant(undefined)],
]);

export const combineValidators = (...validators: Validator[]) => (value: string) => flow(
  map((validator: Validator) => validator(value)),
  find<string | undefined>(Boolean),
)(validators);

export const required = (msg: string): Validator => flow(_toString, isEmpty, returnMsgIfTrue(msg));

export const isInvalidEmailString = (value: string) => {
  try {
    yup.string().email().validateSync(value);

    return false;
  } catch (err) {
    return true;
  }
};

const getShortYear = flow(_toString, (str) => str.substr(2), _parseInt(10));

// NOTE: validExpDate only handles 2 digit years.
// TOD: <Y> add try catch for expected errors and return error message
export const validExpDate = (msg: string): Validator => (value: string) => {
  const returnHelper = returnMsgIfTrue(msg);
  const today = getCentralNowDate();
  const parsedDate = value.split('/').map(_parseInt(10));

  const monthValue = parsedDate[0];
  const yearValue = parsedDate[1];

  if (monthValue === 0 || yearValue === 0) {
    return returnHelper(true);
  }

  if (monthValue > 12 || yearValue > 99) {
    return returnHelper(true);
  }

  const currentYear = getShortYear(getYear(today));
  const currentMonth = getMonth(today) + 1;
  const validDate = currentYear === yearValue
    ? monthValue >= currentMonth
    : yearValue > currentYear;

  return returnHelper(!validDate);
};

export const doesNotContain = (
  msg: string,
  search: string | RegExp,
): Validator => flow(includes(search), returnMsgIfTrue(msg));

export const isValidEmail = (msg?: string): Validator => flow(isInvalidEmailString, returnMsgIfTrue(msg || 'Invalid email format'));

const getJustNumbers = (value: string) => {
  const justNumbers = value.replace(/-/g, '').replace(/_/g, '').replace(/ /g, '');

  return justNumbers;
};
export const maskedPhoneRequire10Digits = (value: string) => {
  const justNumbers = getJustNumbers(value);
  return (justNumbers.toString().length === 10 ? true : `Phone number must be exactly 10 digits. Current length: ${justNumbers.length}`);
};

export const validateIncomingPhoneNumberLength = (value: string) => {
  const justNumbers = getJustNumbers(value);
  return (justNumbers.toString().length > 10 ? `Displayed value is truncated. Incoming phone number (${value}) has more than 10 digits.  ` : true);
};

export const usOrCanadianZipCodeFormat = (value: string) => (!value ? true : (value && usOrCandianZipRegEx.test(value)) ? true : 'Invalid U.S. or Canadian Zip Code format');
