import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import IconText, { IconTextProps } from './IconText';
import { theme } from '../../../appTheme';

export interface InfoIconTextProps extends Omit<IconTextProps, 'icon'> {
  iconSize?: 'inherit' | 'large' | 'medium' | 'small';
  iconColor?: string;
}

const InfoIconText = (props: InfoIconTextProps) => {
  const { iconSize = 'medium', iconColor, ...rest } = props;
  return (
    <IconText
      {...rest}
      icon={<InfoIcon htmlColor={iconColor || theme.palette.info.main} fontSize={iconSize} />}
    />
  );
};

export default InfoIconText;
