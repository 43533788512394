import React from 'react';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import { FilterOption } from '../../../shared/components/search-filter/FilterOption';
import MultiSelectFilter from '../../../shared/components/search-filter/MultiSelectFilter';
import { StatusFilterProps } from '../../../shared/components/search-filter/StatusFilterProps';
import { UserLoginAccountIcon } from '../../../shared/components/UserIcons';

export const userLoginStatuses: FilterOption<PortalApiUser>[] = [

  {
    displayName: 'Active',
    key: 'active-login',
    icon: <UserLoginAccountIcon
      hasLoginAccount
      loginAccountIsActive
    />,
    condition: (user: PortalApiUser) => user.hasLogin && !user.isDisabled,
  },
  {
    displayName: 'Disabled',
    key: 'disabled-login',
    icon: <UserLoginAccountIcon
      hasLoginAccount
      loginAccountIsActive={false}
    />,
    condition: (user: PortalApiUser) => user.hasLogin && user.isDisabled,
  },
  {
    displayName: 'No Login',
    key: 'no-login',
    icon: <UserLoginAccountIcon
      hasLoginAccount={false}
      loginAccountIsActive={false}
    />,
    condition: (user: PortalApiUser) => !user.hasLogin,
  },

];

const LoginStatusFilter = (props: StatusFilterProps) => {
  const { value, handleSelection, disabled } = props;
  return (
    <MultiSelectFilter<PortalApiUser>
      disabled={disabled}
      inputLabel="Login Status"
      labelId="filter-login-label"
      selectId="filter-login-select"
      value={value}
      handleSelection={handleSelection}
      options={userLoginStatuses}
    />
  );
};

export default LoginStatusFilter;
