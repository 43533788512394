import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import IconText, { IconTextProps } from './IconText';
import { theme } from '../../../appTheme';

export interface WaningIconTextProps extends Omit<IconTextProps, 'icon'> {
  iconSize?: 'inherit' | 'large' | 'medium' | 'small';
  icon?: React.ReactNode;
}

const WarningIconText = (props: WaningIconTextProps) => {
  const { iconSize = 'medium', icon, ...rest } = props;
  return (
    <IconText
      {...rest}
      icon={icon || <WarningIcon fontSize={iconSize} htmlColor={theme.palette.warning.main} />}
    />
  );
};

export default WarningIconText;
