import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EntityActionButton from '../../../shared/components/EntityActionButton';
import InvalidCarrier from '../../../shared/components/InvalidCarrier';
import SearchListItem from '../../../shared/components/search/SearchListItem';
import ListSearchItemProps from '../../../shared/models/other/ListSearchItemProps';
import { FleetUserPermissions } from '../../auth/permissionNames';
import FleetCardItemDisplay from '../FleetCardItemDisplay';
import FleetSearchBalance from './FleetSearchBalance';
import { useFleetSearchItem } from '../hooks/useFleetSearchItem';
import { Fleet } from '../../../shared/models/fleet/Fleet';

const FleetSearchListItem = (props: ListSearchItemProps<Fleet>) => {
  const { hideLastDivider } = props;
  const theme = useTheme();
  const {
    fleet,
    fleetClicked,
    onLoadFundsClick,
    userCanLoadContract,
    userCanViewContractBalance,
    isLoadFundsDisabled,
  } = useFleetSearchItem(props);

  return (
    <SearchListItem
      itemKey={fleet.id}
      userCanClickItem={Boolean(
        fleet.cachePermissions?.permissions[FleetUserPermissions.canViewFleetRoute]
        || fleet.cachePermissions?.permissions[FleetUserPermissions.canViewFleetFuelActivity
        ],
      )}
      listItemStyles={{
        minHeight: 112.047,
      }}
      itemClicked={fleetClicked}
      borderColor={fleet.isActive ? theme.palette.secondary.main : theme.palette.action.disabled}
      primaryTitle={<div style={{ color: !fleet.isActive ? theme.palette.grey[600] : undefined, fontWeight: 'bold', opacity: 0.85 }}>{fleet.name}</div>}
      secondaryTitle={(
        <Box sx={{ marginTop: '2px', fontSize: '0.95em' }}>
          <FleetCardItemDisplay fleet={fleet} />
        </Box>
      )}
      userCanViewCardActions={userCanLoadContract || userCanViewContractBalance}
      listItemActionChildren={(
        <>
          {userCanLoadContract && (
            <InvalidCarrier isValidCarrier={fleet.isValidCarrier && fleet.hasActiveFuelCards}>
              <EntityActionButton
                onClick={onLoadFundsClick}
                aria-label={`load fuel funds to ${fleet.name}`}
                disabled={isLoadFundsDisabled}
              >
                Load Funds
              </EntityActionButton>
            </InvalidCarrier>
          )}
          {userCanViewContractBalance && <FleetSearchBalance balance={fleet.contractBalance} />}
        </>
      )}
      hideLastDivider={hideLastDivider}
    />
  );
};

export default FleetSearchListItem;
