import React from 'react';
import FormLayout from './FormLayout';
import StandardError from './StandardError';

interface FormWrapperProps {
  error?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const FormWrapper = (props: FormWrapperProps) => {
  const { error = '', children, style } = props;

  return (
    <FormLayout style={style}>
      {error ? <StandardError /> : children}
    </FormLayout>
  );
};

export default FormWrapper;
