import {
  List, ListItem, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import HelpDialog from '../../../shared/components/HelpDialog';
import AdditionalFilteringHelpListItem from '../../../shared/components/help/AdditionalFilteringHelpListItem';
import FilteringHelpListItem from '../../../shared/components/help/FilteringHelpListItem';
import InitialResultsHelpListItem from '../../../shared/components/help/InitialResultsHelpListItem';

interface FleetSearchHelpProps {
  style?: React.CSSProperties;
  canViewContractFilter?: boolean;
}
const FleetSearchHelp = (props: FleetSearchHelpProps) => {
  const { style, canViewContractFilter = false } = props;
  return (
    <HelpDialog tooltipTitle="Click for help about how to use fleet filtering" style={style}>
      <Typography variant="h5" gutterBottom>
        Filter Fleets
      </Typography>

      <InitialResultsHelpListItem
        initialResultSecondaryText={(
          <>
            <div>
              By default, the initial result set that is returned from the server will only include fleets that are active. If you want to also include inactive fleets, check the "Include Inactive Fleets" checkbox.
            </div>
          </>
        )}
      >
        <List>
          <ListItem sx={{ alignItems: 'flex-start' }}>
            <ListItemText
              sx={{ alignItems: 'flex-start' }}
              primary='"Include Inactive Fleets"'
              secondary="Checking this box will re-fetch fleets from the server and include inactive fleets."
            />
          </ListItem>
        </List>
      </InitialResultsHelpListItem>

      <FilteringHelpListItem>
        <List>
          <ListItem sx={{ alignItems: 'flex-start' }}>
            <ListItemText
              sx={{ alignItems: 'flex-start' }}
              primary='"Filter Fleets" input'
              secondary="Text entered into this input will filter the fleets via fleet name, carrier id, org id, and root member id."
            />
          </ListItem>
        </List>
      </FilteringHelpListItem>

      <AdditionalFilteringHelpListItem>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="INVALID CONDITIONS"
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Invalid Carrier"
              secondary="Select this filtering option to display fleets that have an invalid EFS carrier ID."
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="No Active Fuel Cards"
              secondary="Select this filtering option to display fleets that have an EFS carrier ID but do not have any 'Active' fuel cards."
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Missing Root Member"
              secondary="Select this filtering option to display fleets that do not have a root member associated with their organization."
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Missing Org"
              secondary="Select this filtering option to display fleets that are not associated with a TVC organization."
            />
          </ListItem>
        </List>

        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="USAGE"
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Fuel"
              secondary="Select this filtering option to display fleets that are using fuel."
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Legal"
              secondary="Select this filtering option to display fleets that are using legal."
            />
          </ListItem>
        </List>

        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="ACTIVE STATUS"
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Active"
              secondary="Select this filtering option to display fleets that are 'Active'."
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Inactive"
              secondary="Select this filtering option to display fleets that are 'Inactive."
            />
          </ListItem>
        </List>

        { canViewContractFilter && (
          <List>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="CONTRACT BALANCE"
                secondary="Check the checkbox input to enable this filter. When it's checked, it will limit the result set to only those fleets that have an EFS carrier ID and those contract balances which fall within the default range. You can increase/decrease the range by moving the range selection as desired."
              />
            </ListItem>
          </List>
        )}
      </AdditionalFilteringHelpListItem>

    </HelpDialog>
  );
};

export default FleetSearchHelp;
