import { useSelector } from 'react-redux';
import { selectCountries } from '../../features/application/applicationSlice';

const useSelectCountry = () => {
  const countries = useSelector(selectCountries);

  const getDisplayNameForCountry = (country: string) => {
    if (country === '') {
      return 'None';
    }
    return country;
  };

  return {
    // countries: ['', ...countries],
    countries: [...countries],
    getDisplayNameForCountry,
  };
};

export default useSelectCountry;
