import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import { selectFleetById } from '../fleetSlice';

export const useFleetContractModal = () => {
  const [loadFleetContractModalOpen, setLoadFleetContractModalOpen] = useState(false);

  const [fleetId, setFleetId] = useState<string>();

  const selectedFleet = useSelector(selectFleetById(fleetId));

  const closeLoadFleetContractModal = () => setLoadFleetContractModalOpen(false);

  const openLoadFleetContractModal = (fleet: Fleet) => () => {
    setFleetId(fleet.id);
    setLoadFleetContractModalOpen(true);
  };

  return {
    loadFleetContractModalOpen,
    setLoadFleetContractModalOpen,
    selectedFleet,
    closeLoadFleetContractModal,
    openLoadFleetContractModal,
  };
};
