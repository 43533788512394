import React from 'react';
import {
  Box, Typography, TypographyTypeMap,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { theme as appTheme } from '../../appTheme';

interface ContentHeaderProps {
  headerText: string;
  isError?: boolean;
  isSuccess?: boolean;
  showIcon?: boolean;
  typographyVariant?: TypographyTypeMap['props']['variant'];
}
const MigrationContentHeader = (props: ContentHeaderProps) => {
  const {
    headerText,
    isError = false,
    isSuccess = false,
    showIcon = true,
    typographyVariant = 'h6',
  } = props;

  return (
    <Box display="flex" sx={{ alignItems: 'center', marginTop: '18px' }}>
      { showIcon && (
        <>
          { isError && <Box display="flex"><ErrorOutlineIcon htmlColor={appTheme.palette.error.main} /></Box> }
          { isSuccess && <Box display="flex"><CheckCircleOutlineIcon htmlColor={appTheme.palette.success.main} /></Box> }
        </>
      )}
      <Box sx={{ paddingTop: '3px', marginLeft: (showIcon && (isError || isSuccess)) ? '8px' : {} }}>
        <Typography
          variant={typographyVariant}
          sx={{
            color: (isError || isSuccess)
              ? isError ? appTheme.palette.error.main : appTheme.palette.success.main
              : undefined,
          }}
        >
          { headerText }
        </Typography>
      </Box>
    </Box>
  );
};

export default MigrationContentHeader;
