export const formatNumber = (num?: number | null,
  fractionDigits: number = 0) => {
  if (!num) {
    return '-';
  }

  return Math.abs(num)
    .toFixed(fractionDigits)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export function currencyFormat(
  num?: number | null,
  fractionDigits: number = 0,
) {
  if (!num) {
    return num === 0 ? '$0.00' : '';
  }

  const displayNum = formatNumber(num, fractionDigits);

  return `${num < 0 ? '-' : ''}$${displayNum}`;
}
