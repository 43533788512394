import { omit } from 'lodash/fp';
import { ExportType } from '../enums/fuel/ExportType';
import { FuelHistoryType } from '../enums/fuel/FuelHistoryType';
import { PortalApiLimitType } from '../enums/fuel/PortalApiLimitType';
import { FuelTransaction } from '../models/fuel/FuelTransaction';
import { PendingLoad } from '../models/fuel/PendingLoad';
import { PortalApiTransaction } from '../models/fuel/PortalApiTransaction';
import { ScheduleLoadSubmit } from '../models/fuel/ScheduleLoadSubmit';
import { TriggeredFundBaseModel } from '../models/fuel/TriggeredFundBaseModel';
import { TriggeredFundModel } from '../models/fuel/TriggeredFundModel';
import { VoidedLoad } from '../models/fuel/VoidedLoad';
import { LoadLimits } from '../types/fuel/LoadLimits';
import { getValueOrReplacementValue } from './miscHelpers';

export const parsePendingLoad = (load: PendingLoad): PendingLoad => ({
  ...load,
  amount: load.amount,
  estimatedAvailabilityDate: load.estimatedAvailabilityDate,
  createdDate: load.createdDate,
});

export const transactionTypeToString = (type: FuelHistoryType) => (type === FuelHistoryType.Fee ? 'Account Inactivity Fee' : getValueOrReplacementValue(FuelHistoryType[type], 'Unknown'));

export const transactionLocationToString = (transaction: FuelTransaction) => {
  if (!transaction.location) {
    return 'N/A';
  }
  let locationStr = transaction.location;
  if (transaction.city && transaction.state) {
    locationStr = `${locationStr} ${transaction.city}, ${transaction.state}`;
  }
  return locationStr;
};

export const parseVoidedLoad = (load: VoidedLoad): VoidedLoad => ({
  ...load,
  amount: load.amount,
  createdDate: load.createdDate,
});

export const parseScheduledLoad = (load: TriggeredFundModel) => ({
  ...load,
  estimatedFundsAvailability: load.estimatedFundsAvailability,
  nextOccurrence: load.nextOccurrence,
  modifiedDate: load.modifiedDate
    ? new Date(load.modifiedDate)
    : undefined,
});

export const portalApiTransactionToTransaction = (
  portalApiTransaction: PortalApiTransaction,
): FuelTransaction => ({
  historyId: portalApiTransaction.id,
  amount: portalApiTransaction.amount,
  availabilityDateTime: portalApiTransaction.availabilityDateTime
    ? new Date(portalApiTransaction.availabilityDateTime)
    : null,
  dateTime: new Date(portalApiTransaction.dateTime),
  timeZone: portalApiTransaction.timeZone,
  type: portalApiTransaction.fuelHistoryType,
  discountAmount: portalApiTransaction.totalDiscountAmount,
  status: portalApiTransaction.status,
  userName: portalApiTransaction.userName || '',
  location: portalApiTransaction.locationName,
  fuelCardId: portalApiTransaction.fuelCardId,
  usedCoupon: portalApiTransaction.usedCoupon,
  city: portalApiTransaction.city,
  state: portalApiTransaction.state,
  fleetCardNumber: portalApiTransaction.fleetCardNumber,
  lastFour: portalApiTransaction.lastFour,
});

export const getTriggeredFundBaseModel = (loadFeePercentage: number, loadFeeMinimum: number, scheduledLoad: ScheduleLoadSubmit): TriggeredFundBaseModel => {
  const processingFee = scheduledLoad.processingPeriod;

  const loadData: TriggeredFundBaseModel = {
    accountId: scheduledLoad.paymentMethodId,
    feeId: processingFee.id,
    loadFeePercentage,
    loadFeeMinimum,
    expediteFeePercentage: processingFee.percentage,
    expediteFeeDays: processingFee.days,
    loadAmount: scheduledLoad.fundsToAdd,
    /* Triggers uses cron syntax:
        1. Minute when the process will be started [0-60]
        2. Hour when the process will be started [0-23]
        3. Day of the month when the process will be started [1-28/29/30/31]
        4. Month of the year when the process will be started [1-12]
        5. Weekday when the process will be started [0-6] [0 is Sunday]
    */
    trigger: {
      type: 1,
      criteria: scheduledLoad.isFirstFifteenth ? `0 0 0 ${scheduledLoad.firstFifteenth.join(',')} * *` : `0 0 0 * * ${scheduledLoad.daysOfWeek.join(',')}`,
    },
    feeAdjustmentAmount: scheduledLoad.feeAdjustmentAmount,
    feeAdjustmentReason: scheduledLoad.feeAdjustmentReason,
  };

  return loadData;
};

export const findNodeByLimitType = (limits: LoadLimits[] = []) => (limitType: PortalApiLimitType) => omit(
  'multiplier',
  limits.find((limit) => limit.limitType === limitType),
);

export const getIftaExportType = (exportType: ExportType) => (exportType === ExportType.Excel ? 'xlsx' : 'pdf');

export const getLastFourText = (lastFour: string | undefined, replacementValue: string = '<missing last four>') => (lastFour ? `Ending in ${lastFour}` : replacementValue);

export const defaultFleetTransactionHistoryDaysBack = 30;
export const defaultFleetTransactionHistoryMonthsBack = 6;
export const defaultIftaMonthsBack = 12;
export const maxIftaMonthsBack = 24;

export const defaultFuelStatsDaysBack = 90;
export const maxFuelStatsMonthsBack = 6;

export const securityHoldText = 'Your account is undergoing verification. We apologize for any inconvenience, please check back in 24 hours.';
