import { theme } from '../../appTheme';

const baseGridCardStyle: any = {
  cardHeader: {
    cursor: 'pointer',
  },
  cardHeaderNoClick: {
    cursor: 'default',
  },
  cardTitle: {
    fontSize: '18.66px',
  },
  cardSubTitle: {
    fontSize: '0.8rem',
  },
  unassignedTextColor: {
    color: theme.palette.grey[500],
  },
};

export default baseGridCardStyle;
