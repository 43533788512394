import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { currencyFormat } from '../../../shared/utilities/currencyFormat';

interface FleetSearchBalanceProps {
  balance: number;
}

const FleetSearchBalance = (props: FleetSearchBalanceProps) => {
  const { balance } = props;
  const theme = useTheme();
  const balanceFormatted = balance !== undefined ? currencyFormat(balance, 2) : '-';

  return (
    <Typography
      variant="subtitle1"
      color="textSecondary"
      align="right"
      sx={{
        marginRight: '8px', minWidth: '98px', color: balance <= 0 ? theme.palette.error.main : theme.palette.success.main, fontSize: '0.9rem',
      }}
    >
      { balanceFormatted }
    </Typography>
  );
};

export default FleetSearchBalance;
