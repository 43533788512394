import { Grid } from '@mui/material';
import React from 'react';

interface GridCollectionWrapperProps {
  children?: React.ReactNode;
}

const GridCollectionWrapper = (props: GridCollectionWrapperProps) => {
  const { children } = props;
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
};

export default GridCollectionWrapper;
