import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { LoadLimits } from '../../types/fuel/LoadLimits';
import { Multiplier } from '../../models/fuel/Multiplier';
import { currencyFormat } from '../../utilities/currencyFormat';
import CustomCheckboxInput from '../formFields/CustomCheckboxInput';
import LimitDisplayLabel from './LimitDisplayLabel';
import LimitInputDisplay from './LimitInputDisplay';
import LimitPeriodAmountLimitInput from './LimitPeriodAmountLimitInput';

interface DefaultMultiplierInfoState {
  multiplier: number;
  multiplicand: number;
  calculatedDefault: number;
  calculatedDefaultFormatted: string;
  multiplicandFormatted: string;
}

interface LimitPeriodAmountLimitInputEditProps {
  limits?: LoadLimits;
  defaultLimits?: LoadLimits;
  defaultMultiplier?: Multiplier;
  canEditLoadLimits?: boolean;
  entityName?: string;
  labelStyle?: React.CSSProperties;
}

const LimitPeriodAmountLimitInputEdit = (
  props: LimitPeriodAmountLimitInputEditProps,
) => {
  const theme = useTheme();
  const activeColor = theme.palette.grey[800];
  const styles = {
    allowanceAmount: {
      fontWeight: 'bold',
      color: activeColor,
    },
    isDefaultLimitPeriodAmount: {
      fontWeight: 'bold',
      color: activeColor,
    },
    isNotDefaultLimitPeriodAmount: {
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  };

  const {
    limits,
    defaultLimits,
    defaultMultiplier,
    entityName,
    canEditLoadLimits,
    labelStyle,
  } = props;
  const form = useFormContext();
  const [isDefaultLimitPeriodAmount, setIsDefaultLimitPeriodAmount] = useState<boolean>(false);

  // const [defaultMultInfoIsValid, setDefaultMultInfoIsValid] = useState<boolean>(false);
  const [defaultMultInfo, setDefaultMultInfo] = useState<DefaultMultiplierInfoState>();
  const [calculatedFormatted, setCalculatedFormatted] = useState<string>();
  const watchFlatLimitAmount = form.watch('flatLimitAmount');
  const watchUseMultiplier = form.watch('useMultiplier');

  useEffect(() => {
    if (defaultMultiplier?.multiplicand && defaultMultiplier?.multiplier) {
      const calculatedDefault = defaultMultiplier.multiplicand * defaultMultiplier.multiplier;
      // setDefaultMultInfoIsValid(true);
      setDefaultMultInfo({
        multiplier: defaultMultiplier.multiplier,
        multiplicand: defaultMultiplier.multiplicand,
        multiplicandFormatted: currencyFormat(defaultMultiplier.multiplicand),
        calculatedDefault,
        calculatedDefaultFormatted: currencyFormat(calculatedDefault),
      });
    }
  }, [defaultMultiplier?.multiplicand, defaultMultiplier?.multiplier]);

  useEffect(() => {
    if (defaultMultInfo) {
      setCalculatedFormatted(
        currencyFormat(
          defaultMultInfo.multiplier
          * (watchFlatLimitAmount || defaultMultInfo?.multiplicand),
        ),
      );
    }
  }, [watchFlatLimitAmount, watchUseMultiplier, defaultMultInfo]);

  const getSharedLimitNumberProps = () => ({
    displayOnly: false,
    label: '',
    showAdornment: true,
    adornmentIsDisabled: form.formState.isSubmitting,
  });
  return (
    <LimitInputDisplay
      limitValue={form.getValues('flatLimitAmount')}
      defaultLimitValue={
        form.getValues('useMultiplier')
          ? defaultLimits?.flatLimitAmount
          : defaultMultiplier?.multiplicand
      }
      customLabel={(
        <LimitDisplayLabel
          label={(
            <div>
              <span style={{ ...labelStyle }}>Limit Period Amount Allowed</span>
              <br />
              <div
                style={{
                  fontStyle: 'italic',
                  fontSize: '12px',
                  marginLeft: '32px',
                  marginTop: '8px',
                }}
              >
                <div
                  style={{
                    backgroundColor: theme.palette.grey[100],
                    color: 'rgba(0, 0, 0, 0.54)',
                    paddingLeft: '10px',
                    marginLeft: '-10px',
                    paddingBottom: '4px',
                    borderRadius: '5px',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      fontStyle: 'normal',
                      padding: '8px',
                      marginBottom: '6px',
                      marginTop: '0px',
                      marginLeft: '-10px',
                      borderRadius: '5px',
                      backgroundColor: isDefaultLimitPeriodAmount
                        ? activeColor
                        : theme.palette.grey[100],
                      color: isDefaultLimitPeriodAmount
                        ? theme.palette.common.white
                        : 'rgba(0, 0, 0, 0.54)',
                    }}
                  >
                    DEFAULT
                  </span>

                  <div style={{ marginLeft: '-2px' }}>
                    Number of active fuel cards multiplied by
                    {' '}
                    {currencyFormat(defaultMultiplier?.multiplicand)}
                    <br />
                    {entityName}
                    {' '}
                    currently has
                    {' '}
                    {defaultMultInfo?.multiplier}
                    {' '}
                    active fuel cards
                    <br />
                    <span>
                      {defaultMultInfo?.multiplier}
                      {' '}
                      x
                      {' '}
                      {defaultMultInfo?.multiplicandFormatted}
                      {' '}
                      =
                      {' '}
                      <span
                        style={isDefaultLimitPeriodAmount
                          ? styles.isDefaultLimitPeriodAmount
                          : styles.isNotDefaultLimitPeriodAmount}
                      >
                        {defaultMultInfo?.calculatedDefaultFormatted}
                      </span>
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: '10px',
                    fontSize: '14px',
                    fontStyle: 'normal',
                  }}
                >
                  {watchUseMultiplier ? (
                    <>
                      Calculated allowance:
                      {' '}
                      <span>
                        {defaultMultInfo?.multiplier}
                        {' '}
                        x
                        {' '}
                        {watchFlatLimitAmount
                          ? currencyFormat(watchFlatLimitAmount)
                          : defaultMultInfo?.multiplicandFormatted}
                        {' '}
                        =
                        {' '}
                        <span style={styles.allowanceAmount}>
                          {calculatedFormatted}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Flat limit amount allowance:
                      {' '}
                      {watchFlatLimitAmount ? (
                        <span style={styles.allowanceAmount}>
                          {currencyFormat(watchFlatLimitAmount)}
                        </span>
                      ) : (
                        <span style={{ color: theme.palette.error.main }}>
                          &lt;unable to calculate&gt;
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          limitValue={limits?.flatLimitAmount}
          isInEditMode
          canEditLoadLimits={canEditLoadLimits}
        />
      )}
      value={(
        <>
          <CustomCheckboxInput
            name="useMultiplier"
            label="Use fuel card multiplier"
            defaultValue={limits?.useMultiplier}
            isVisible
          />

          <LimitPeriodAmountLimitInput
            {...getSharedLimitNumberProps()}
            defaultLimits={defaultLimits}
            onAfterAdornmentClicked={() => {
              form.setValue('useMultiplier', defaultLimits?.useMultiplier);
              form.trigger();
            }}
            isDefaultValue={(fieldValues: any[]) => {
              const [flatLimitAmount, useMultiplier] = fieldValues;
              const ret = !flatLimitAmount?.toString().length
                && useMultiplier === Boolean(defaultLimits?.useMultiplier);
              setIsDefaultLimitPeriodAmount(ret);
              return ret;
            }}
            otherFields={['useMultiplier']}
            defaultMultiplier={defaultMultiplier}
            placeholder={
              form.getValues('useMultiplier')
                ? 'Enter custom multiplicand amount'
                : 'Enter custom flat limit amount'
            }
          />
        </>
      )}
      isInEditMode
    />
  );
};

export default LimitPeriodAmountLimitInputEdit;
