import { useSelector } from 'react-redux';
import { selectPaymentMethods } from '../paymentMethodSlice';

export const usePaymentMethods = () => {
  const { paymentMethods, paymentMethodsLoading } = useSelector(selectPaymentMethods);

  const getPaymentMethodIsCheck = (paymentMethodId?: number) => {
    if (paymentMethodId === undefined) {
      return undefined;
    }
    const paymentMethod = paymentMethods.byId[paymentMethodId];
    return paymentMethod?.isCheck;
  };

  return {
    paymentMethodsLoading,
    paymentMethods,
    getPaymentMethodIsCheck,
  };
};
