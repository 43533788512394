import React from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

interface CustomCheckboxInputProps {
  name: string;
  label?: string;
  defaultValue?: boolean;
  disabled?: boolean;
  style?: Object;
  id?: string;
  fullWidth?: boolean;
  isVisible?: boolean;
  onChangeCallback?: (isChecked: boolean) => void;
  color?: 'default' | 'primary' | 'secondary' | undefined;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

const CustomCheckboxInput = (props: CustomCheckboxInputProps) => {
  const {
    style,
    label,
    id,
    disabled,
    name,
    defaultValue,
    fullWidth = false,
    isVisible = true,
    onChangeCallback,
    color,
    labelPlacement,
  } = props;
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
    defaultValue: defaultValue || false,
  });

  const defaultInputId = id || `${name}-checkbox`;

  const checkbox = () => (
    <FormControl
      fullWidth={fullWidth}
      sx={{ display: isVisible ? 'inline-flex' : 'none' }}
    >
      <FormControlLabel
        control={(
          <Checkbox
            {...field}
            color={color}
            id={defaultInputId}
            checked={field.value}
            onChange={(e) => {
              const { checked } = e.target;
              if (onChangeCallback) {
                onChangeCallback(checked);
              }
              field.onChange(checked);
            }}
            sx={style}
            disabled={disabled}
            inputRef={field.ref}
          />
        )}
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormControl>
  );

  return checkbox();
};

export default CustomCheckboxInput;
