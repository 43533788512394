import React, { useEffect } from 'react';
import { Grid, SelectProps } from '@mui/material';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { get } from 'lodash/fp';
import CustomTextInput from '../formFields/CustomTextInput';
import CustomSelectInput from '../formFields/CustomSelectInput';
import { StateProvince } from '../../models/other/StateProvince';
import useSelectCountry from '../../hooks/useSelectCountry';
import useStateProvinceList from '../../hooks/useStateProvinceList';

interface CustomAddressFieldConfigurationProp {
  name?: string;
  label?: string;
  disabled?: boolean;
  maxLength?: number;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

interface CustomAddressFieldConfiguration {
  name: string;
  label: string;
  disabled: boolean;
  maxLength?: number;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

const countryFieldOptionsDefaults: CustomAddressFieldConfiguration = {
  name: 'country',
  label: 'Country',
  disabled: false,
};

const address1FieldOptionsDefaults: CustomAddressFieldConfiguration = {
  name: 'address1',
  label: 'Street Address',
  disabled: false,
  maxLength: 10,
};

const address2FieldOptionsDefaults: CustomAddressFieldConfiguration = {
  name: 'address2',
  label: 'Apt/Suite',
  disabled: false,
  maxLength: 100,
};

const cityFieldOptionsDefaults: CustomAddressFieldConfiguration = {
  name: 'city',
  label: 'City',
  disabled: false,
  maxLength: 100,
};

const stateFieldOptionsDefaults: CustomAddressFieldConfiguration = {
  name: 'state',
  label: 'State/Province',
  disabled: false,
  rules: {
    validate: {
      isValidSelection: (v: string) => ((!v) ? 'Select State/Province' : true),
    },
  },
};

const zipFieldOptionsDefaults: CustomAddressFieldConfiguration = {
  name: 'postalCode',
  label: 'Zip',
  disabled: false,
  maxLength: 100,
};

interface CustomAddressFormProps {
  isReadOnly?: boolean;
  useAddress2?: boolean;
  useCountry?: boolean;

  countryFieldOptions?: CustomAddressFieldConfigurationProp;
  countryDefaultValue?: 'Canada' | 'USA'
  address1FieldOptions?: CustomAddressFieldConfigurationProp;
  address2FieldOptions?: CustomAddressFieldConfigurationProp;
  cityFieldOptions?: CustomAddressFieldConfigurationProp;
  stateFieldOptions?: CustomAddressFieldConfigurationProp;
  postalCodeFieldOptions?: CustomAddressFieldConfigurationProp;
  stateSelectRenderValueFunc?: (value: SelectProps['value']) => React.ReactNode;
}

const getFieldConfiguration = (prop: CustomAddressFieldConfigurationProp | undefined, propDefaults: CustomAddressFieldConfiguration): CustomAddressFieldConfiguration => ({
  name: propDefaults.name,
  label: propDefaults.label,
  disabled: propDefaults.disabled,
  ...prop,
  rules: {
    ...propDefaults?.rules,
    ...prop?.rules,
    validate: {
      ...propDefaults?.rules?.validate,
      ...prop?.rules?.validate,
    },
  },
});
export const CustomAddressForm = (props: CustomAddressFormProps) => {
  const {
    useAddress2 = true,
    useCountry = true,
    isReadOnly = false,

    countryFieldOptions,
    address1FieldOptions,
    address2FieldOptions,
    cityFieldOptions,
    stateFieldOptions,
    postalCodeFieldOptions,

    countryDefaultValue = 'USA',
  } = props;

  const countryOptions = getFieldConfiguration(countryFieldOptions, countryFieldOptionsDefaults);
  const address1Options = getFieldConfiguration(address1FieldOptions, address1FieldOptionsDefaults);
  const address2Options = getFieldConfiguration(address2FieldOptions, address2FieldOptionsDefaults);
  const cityOptions = getFieldConfiguration(cityFieldOptions, cityFieldOptionsDefaults);
  const stateOptions = getFieldConfiguration(stateFieldOptions, stateFieldOptionsDefaults);
  const zipOptions = getFieldConfiguration(postalCodeFieldOptions, zipFieldOptionsDefaults);

  const { countries, getDisplayNameForCountry } = useSelectCountry();
  const {
    stateProvinceList, setIsCanada,
  } = useStateProvinceList();

  const {
    watch, setValue, trigger, formState: { isDirty },
  } = useFormContext();

  const watchCountry = watch(countryOptions.name);
  const watchState = watch(stateOptions.name);

  useEffect(() => {
    if (isDirty) {
      trigger(stateOptions.name);
    }
  }, [watchState, isDirty]);

  useEffect(() => {
    // this logic only applies if they parent component wants to use country
    if (useCountry) {
      setIsCanada(watchCountry === 'Canada');
    }
  }, [watchCountry, useCountry]);

  useEffect(() => {
    // this logic only applies if they parent component wants to use country
    if (useCountry && !isReadOnly) { // only do this logic if the form is not read only
      if (stateProvinceList.length > 0) {
        if (!stateProvinceList.find((sp) => sp.abbreviation === watchState)) {
          setValue(stateOptions.name, undefined);
        }
      }
    }
  }, [watchState, stateProvinceList, useCountry, isReadOnly]);

  return (
    <Grid container spacing={2}>
      {useCountry && (
        <Grid item xs={12}>
          <CustomSelectInput<string>
            name={countryOptions.name}
            rules={countryOptions.rules}
            items={countries}
            itemValue={(v) => v}
            itemDisplay={getDisplayNameForCountry}
            itemKey={(v) => `country-${v}`}
            label={countryOptions.label}
            defaultValue={countryDefaultValue}
            disabled={countryOptions.disabled}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={useAddress2 ? 6 : 12}>
        <CustomTextInput
          name={address1Options.name}
          rules={address1Options.rules}
          fullWidth
          label={address1Options.label}
          maxLength={address1Options.maxLength}
          disabled={address1Options.disabled}
        />
      </Grid>
      {useAddress2 && (
        <Grid item xs={12} sm={6}>
          <CustomTextInput
            name={address2Options.name}
            rules={address2Options.rules}
            fullWidth
            label={address2Options.label}
            maxLength={address2Options.maxLength}
            disabled={address2Options.disabled}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomTextInput
          name={cityOptions.name}
          rules={cityOptions.rules}
          fullWidth
          label={cityOptions.label}
          maxLength={cityOptions.maxLength}
          disabled={cityOptions.disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomSelectInput<StateProvince>
          name={stateOptions.name}
          rules={stateOptions.rules}
          label={stateOptions.label}
          itemDisplay={get('name')}
          fullWidth
          itemKey={get('abbreviation')}
          itemValue={get('abbreviation')}
          items={stateProvinceList}
          disabled={stateOptions.disabled}
          data-testid="custom-address-state-select"
          // renderValue={(v: any) => {
          renderValue={() => {
            const ret = watchState ? stateProvinceList.find((sp) => sp.abbreviation === watchState)?.name : '';
            return ret;
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomTextInput
          name={zipOptions.name}
          rules={zipOptions.rules}
          fullWidth
          label={zipOptions.label}
          maxLength={zipOptions.maxLength}
          disabled={zipOptions.disabled}
        />
      </Grid>
    </Grid>
  );
};
