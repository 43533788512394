import { StepLabel, styled } from '@mui/material';
import { theme as appTheme } from '../../../appTheme';

export const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepLabel-active': {
    fontWeight: 500,
    color: `${appTheme.palette.secondary.main} !important`,
    fontSize: '1.3em',
    fontStyle: 'normal !important',
  },
  '& .MuiStepLabel-completed': {
    color: `${appTheme.palette.primary.main} !important`,
    fontStyle: 'normal !important',
  },
  '& .MuiStepLabel-alternativeLabel': {
    fontStyle: 'italic',
  },
});
