import {
  Box,
  List, ListItem, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import SubListItem from '../../../shared/components/help/SubListItem';
import HelpDialog from '../../../shared/components/HelpDialog';

const FilterPortalUserStatusHelp = () => (
  <HelpDialog tooltipTitle="Click for help about filter portal user status" style={{ position: 'relative', top: '0px', left: '0px' }}>
    <Typography variant="h5" gutterBottom>
      Non-Fleet Membership Search
    </Typography>
    <List>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary="FILTER PORTAL USER STATUS"
        />
      </ListItem>
      <SubListItem>
        <ListItemText
          primary={(
            <span>
              Members
              {' '}
              <b>without</b>
              {' '}
              a portal user
            </span>
          )}
          secondary={(
            <Box>
              <Box>
                Selecting this option will filter the result set to only display memberships that do not have a portal user account associated with their membership.
              </Box>
              <Box sx={{ marginTop: '8px', fontStyle: 'italic' }}>
                Additional filtering options are not available when this option is selected.
              </Box>
            </Box>
            )}
        />
      </SubListItem>
      <SubListItem>
        <ListItemText
          primary={(
            <span>
              Members
              {' '}
              <b>with</b>
              {' '}
              a portal user
            </span>
          )}
          secondary="Selecting this option will filter the result set to only display memberships that already have a portal user account associated with their membership."
        />
      </SubListItem>
      <SubListItem>
        <ListItemText
          primary="Both (default)"
          secondary={(
            <Box>
              <Box>
                Selecting this option will include memberships with and without a portal user account.
              </Box>
              <Box sx={{ marginTop: '8px', fontStyle: 'italic' }}>
                Additional filtering options are not available when this option is selected.
              </Box>
            </Box>
            )}
        />
      </SubListItem>
    </List>

  </HelpDialog>
);

export default FilterPortalUserStatusHelp;
