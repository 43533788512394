import { Snackbar, Alert } from '@mui/material';
import React from 'react';

export type AppAlertSeverity = 'error' | 'info' | 'warning' | 'success';

interface AppAlertProps {
  message: React.ReactNode;
  severity: AppAlertSeverity;
  autoHideDuration: number;
  open: boolean;
  onClose: () => void;
}

const AppAlert = (props: AppAlertProps) => {
  const {
    autoHideDuration, message, open, onClose, severity,
  } = props;
  const styles = {
    snackBarRoot: {
      marginRight: '18px',
    },
  };
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      message={message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      open={open}
      ContentProps={{
        sx: styles.snackBarRoot,
      }}
    >
      <Alert onClose={onClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppAlert;
