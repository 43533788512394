import {
  Box, Checkbox, FormControlLabel, Slider,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { theme } from '../../appTheme';
import { currencyFormat } from '../utilities/currencyFormat';
import SectionRow from './confirm-summary/SectionRow';
import LabelValue from './LabelValue';

const AirbnbSlider = withStyles({
  root: {
    color: theme.palette.primary.light,
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);

const marks = [
  {
    value: -10000,
    label: '-$10,000',
  },
  {
    value: 0,
    label: '$0',
  },
  {
    value: 50000,
    label: '$50,000',
  },
  {
    value: 100000,
    label: '$100,000',
  },
];

export interface OnRangeChangedProps {
  range: number[],
  isActive: boolean,
}
export interface RangeSliderProps {
  onRangeChanged: (value: OnRangeChangedProps) => void;
  defaultValue: OnRangeChangedProps;
  reset: boolean;
}

// TODO: Componentize this guys when we need to use it somewhere other than fleet search
export const RangeSlider = (props: RangeSliderProps) => {
  const { onRangeChanged, reset, defaultValue } = props;
  const [value, setValue] = useState<number[]>([defaultValue.range[0], defaultValue.range[1]]);
  const [isChecked, setIsChecked] = useState<boolean>(defaultValue.isActive);

  const handleChange = (event: any, newValue: number | number[]) => {
    // TODO: see if there is a solution to avoid the min and max sliders to overlap (don't let max goe less than min... vice versa)
    setValue(newValue as number[]);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (reset) {
      setValue([defaultValue.range[0], defaultValue.range[1]]);
      setIsChecked(defaultValue.isActive);
    }
  }, [reset]);

  useEffect(() => {
    onRangeChanged({ range: value, isActive: isChecked });
  }, [value, isChecked]);

  const getRangeValueColor = (checked: boolean, rangeValue: number) => (!checked ? theme.palette.action.disabled : rangeValue < 1 ? theme.palette.error.main : theme.palette.success.main);

  return (
    <div style={{
      display: 'flex', marginLeft: '48px', marginRight: '18px', marginTop: '15px', marginBottom: '30px', flexDirection: 'column',
    }}
    >
      <Box>
        <FormControlLabel
          control={(
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              name="useContractBalanceRange"
              color="primary"
            />
          )}
          label="Contract Balance"
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <SectionRow>
          <LabelValue label="Min" value={currencyFormat(value[0])} valueStyle={{ fontSize: '13px', color: getRangeValueColor(isChecked, value[0]) }} />
          <LabelValue label="Max" value={currencyFormat(value[1])} valueStyle={{ fontSize: '13px', color: getRangeValueColor(isChecked, value[1]) }} />
        </SectionRow>

        <AirbnbSlider
          disabled={!isChecked}
          value={value}
          onChange={handleChange}
          aria-labelledby="range-slider"
          marks={marks}
          min={-10000}
          max={100000}
          step={100}
        />
      </Box>
    </div>
  );
};

export default RangeSlider;
