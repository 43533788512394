import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import {
  flow,
  groupBy,
  head,
  filter,
  values,
} from 'lodash/fp';
import { AppThunk, RootState } from '../../app/store';
import { FuelHistoryType } from '../../shared/enums/fuel/FuelHistoryType';
import {
  useFuelApi,
} from '../../services/api/hooks/useFuelApi';
import { VoidedLoad } from '../../shared/models/fuel/VoidedLoad';
import { PendingLoad } from '../../shared/models/fuel/PendingLoad';
import { PortalApiFuelCardAssignmentHistory } from '../../shared/models/fuel/PortalApiFuelCardAssignmentHistory';
import { createBlockingThunk, createNonBlockingThunk } from '../../services/thunk';
import { EfsCarrier } from '../../shared/models/fuel/EfsCarrier';
import { TriggeredFundModel } from '../../shared/models/fuel/TriggeredFundModel';
import { FuelHistory } from '../../shared/models/fuel/FuelHistory';
import { useFleetApi } from '../../services/api/hooks/useFleetApi';
import { useUserApi } from '../../services/api/hooks/useUserApi';
import { addAlert, decrementBlockingRequests, incrementBlockingRequests } from '../application/applicationSlice';
import { TriggeredFundPauseModel } from '../../shared/models/fuel/TriggeredFundPauseModel';
import { NextAvailableFuelLoadModel } from '../../shared/models/fuel/NextAvailableFuelLoadModel';

const map = require('lodash/fp/map').convert({ cap: false });

export interface GroupedScheduledLoads {
  name: string,
  fleetId?: string,
  carrierId?: number,
  scheduledLoads: TriggeredFundModel[],
}

export const defaultTransactionHistory: FuelHistory = {
  transactions: [],
  startDate: '',
  endDate: '',
  isDefaultDateRange: false,
};
export interface EfsState {
  carriers: EfsCarrier[];
  carriersAreLoading: boolean;

  transactionCarrierHistory: FuelHistory;
  transactionCarrierHistoryLoading: boolean;
  transactionHistoryCard: FuelHistory;
  transactionHistoryCardLoading: boolean;

  pendingLoads: PendingLoad[];
  pendingLoadsCard: PendingLoad[];
  pendingLoadsLoading: boolean;
  pendingLoadsCardLoading: boolean;

  scheduledLoads: TriggeredFundModel[];
  scheduledLoadsLoading: boolean;
  scheduledLoadsCard: TriggeredFundModel[];
  scheduledLoadsCardLoading: boolean;

  voidedLoads: VoidedLoad[];
  voidedLoadsLoading: boolean;
  voidedLoadsCard: VoidedLoad[];
  voidedLoadsCardLoading: boolean;

  erroredScheduledLoads: TriggeredFundModel[];
  fuelCardAssignmentHistory: PortalApiFuelCardAssignmentHistory[],
  fuelCardAssignmentHistoryLoading: boolean;
  erroredScheduledLoadsLoading: boolean;
  individualCarrierNextAvailFundingInfo?: NextAvailableFuelLoadModel;
}

const initialState: EfsState = {
  carriers: [],
  carriersAreLoading: false,
  transactionCarrierHistory: defaultTransactionHistory,
  pendingLoads: [],
  pendingLoadsLoading: false,
  pendingLoadsCard: [],
  pendingLoadsCardLoading: false,

  scheduledLoads: [],
  scheduledLoadsLoading: false,
  scheduledLoadsCard: [],
  scheduledLoadsCardLoading: false,

  voidedLoads: [],
  voidedLoadsLoading: false,
  voidedLoadsCard: [],
  voidedLoadsCardLoading: false,

  transactionCarrierHistoryLoading: false,
  transactionHistoryCard: defaultTransactionHistory,
  transactionHistoryCardLoading: false,
  fuelCardAssignmentHistory: [],
  fuelCardAssignmentHistoryLoading: false,

  erroredScheduledLoads: [],
  erroredScheduledLoadsLoading: false,
};

export const efsSlice = createSlice({
  name: 'efs',
  initialState,
  reducers: {
    setCarriers(state, action: PayloadAction<EfsCarrier[]>) {
      state.carriers = action.payload;
    },
    setCarriersLoading(state, action: PayloadAction<boolean>) {
      state.carriersAreLoading = action.payload;
    },
    removeCarrier(state, action: PayloadAction<number>) {
      state.carriers = state.carriers.filter((c) => c.id !== action.payload);
    },
    setTransactionCarrierHistory(state, action: PayloadAction<FuelHistory>) {
      state.transactionCarrierHistory = action.payload;
    },
    setTransactionCarrierHistoryLoading(state, action: PayloadAction<boolean>) {
      state.transactionCarrierHistoryLoading = action.payload; // todo don't forget
    },
    setTransactionCardHistory(state, action: PayloadAction<FuelHistory>) {
      state.transactionHistoryCard = action.payload;
    },
    setTransactionCardHistoryLoading(state, action: PayloadAction<boolean>) {
      state.transactionHistoryCardLoading = action.payload;
    },
    setFuelCardAssignmentHistory(state, action: PayloadAction<PortalApiFuelCardAssignmentHistory[]>) {
      state.fuelCardAssignmentHistory = action.payload;
    },
    setFuelCardAssignmentHistoryLoading(state, action: PayloadAction<boolean>) {
      state.fuelCardAssignmentHistoryLoading = action.payload;
    },


    setPendingLoads(state, action: PayloadAction<PendingLoad[]>) {
      state.pendingLoads = action.payload;
    },
    setPendingLoadsLoading(state, action: PayloadAction<boolean>) {
      state.pendingLoadsLoading = action.payload;
    },

    setCardPendingLoads(state, action: PayloadAction<PendingLoad[]>) {
      state.pendingLoadsCard = action.payload;
    },
    setCardPendingLoadsLoading(state, action: PayloadAction<boolean>) {
      state.pendingLoadsCardLoading = action.payload;
    },

    setScheduledLoads(state, action: PayloadAction<TriggeredFundModel[]>) {
      state.scheduledLoads = action.payload;
    },
    setScheduledLoadsLoading(state, action: PayloadAction<boolean>) {
      state.scheduledLoadsLoading = action.payload;
    },
    setCardScheduledLoads(state, action: PayloadAction<TriggeredFundModel[]>) {
      state.scheduledLoadsCard = action.payload;
    },
    setCardScheduledLoadsLoading(state, action: PayloadAction<boolean>) {
      state.scheduledLoadsCardLoading = action.payload;
    },


    setVoidedLoads(state, action: PayloadAction<VoidedLoad[]>) {
      state.voidedLoads = action.payload;
    },
    setVoidedLoadsLoading(state, action: PayloadAction<boolean>) {
      state.voidedLoadsLoading = action.payload;
    },
    setVoidedLoadsCard(state, action: PayloadAction<VoidedLoad[]>) {
      state.voidedLoadsCard = action.payload;
    },
    setVoidedCardLoadsLoading(state, action: PayloadAction<boolean>) {
      state.voidedLoadsCardLoading = action.payload;
    },

    setErroredScheduledLoads(state, action: PayloadAction<TriggeredFundModel[]>) {
      state.erroredScheduledLoads = action.payload;
    },

    setErroredScheduledLoadsLoading(state, action: PayloadAction<boolean>) {
      state.erroredScheduledLoadsLoading = action.payload;
    },

    setIndividualCarrierNextAvailFundingInfo(state, action: PayloadAction<NextAvailableFuelLoadModel | undefined>) {
      state.individualCarrierNextAvailFundingInfo = action.payload;
    },
  },
});

export const {
  setCarriers,
  setCarriersLoading,
  removeCarrier,
  setTransactionCarrierHistory,
  setTransactionCarrierHistoryLoading,
  setTransactionCardHistory,
  setTransactionCardHistoryLoading,
  setFuelCardAssignmentHistory,
  setFuelCardAssignmentHistoryLoading,

  setPendingLoads,
  setPendingLoadsLoading,
  setCardPendingLoads,
  setCardPendingLoadsLoading,

  setScheduledLoads,
  setScheduledLoadsLoading,
  setCardScheduledLoads,
  setCardScheduledLoadsLoading,

  setVoidedLoads,
  setVoidedLoadsLoading,
  setVoidedLoadsCard,
  setVoidedCardLoadsLoading,

  setErroredScheduledLoads,
  setErroredScheduledLoadsLoading,

  setIndividualCarrierNextAvailFundingInfo,
} = efsSlice.actions;

// CARRIERS
export const fetchCarriers = createNonBlockingThunk<EfsCarrier[]>(
  async (dispatch, getState) => {
    const stateCarriers = getState().efs.carriers;
    const { getFleetEfsCarriers } = useFleetApi();
    if (stateCarriers.length) {
      return stateCarriers;
    }
    dispatch(setCarriersLoading(true));
    const carriers = await getFleetEfsCarriers();
    dispatch(setCarriers(carriers));
    dispatch(setCarriersLoading(false));
    return carriers;
  },
);

export const selectCarriers = (state: RootState) => state.efs.carriers;
export const selectCarriersLoading = (state: RootState) => state.efs.carriers;

// TRANSACTION HISTORY
const retrieveTransactionHistoryForFleetThunk = (
  fleetId: string,
  startDate?: Date,
  endDate?: Date,
  type: FuelHistoryType = FuelHistoryType.All,
  fuelCardId?: number,
  userId?: string,
  isBlocking: boolean = true,
): AppThunk<Promise<FuelHistory>> => async (dispatch) => {
  const { postFleetTransactionHistory } = useFuelApi();
  const isCardOnly: boolean = Boolean(fuelCardId);

  dispatch(isCardOnly ? setTransactionCardHistory(defaultTransactionHistory) : setTransactionCarrierHistory(defaultTransactionHistory));

  if (!isBlocking) {
    dispatch(isCardOnly ? setTransactionCardHistoryLoading(true) : setTransactionCarrierHistoryLoading(true));
  }

  try {
    const fleetTransactions: FuelHistory = await postFleetTransactionHistory(
      fleetId,
      startDate,
      endDate,
      type,
      fuelCardId,
      userId,
    );

    fleetTransactions.transactions = orderBy(fleetTransactions.transactions, 'dateTime', 'desc');

    dispatch(isCardOnly ? setTransactionCardHistory(fleetTransactions) : setTransactionCarrierHistory(fleetTransactions));

    if (!isBlocking) {
      dispatch(isCardOnly ? setTransactionCardHistoryLoading(false) : setTransactionCarrierHistoryLoading(false));
    }

    return fleetTransactions;
  } catch (err) {
    if (!isBlocking) {
      dispatch(isCardOnly ? setTransactionCardHistoryLoading(false) : setTransactionCarrierHistoryLoading(false));
    }
    throw err;
  }
};

export const retrieveFleetTransactionHistory = (fleetId: string, startDate?: Date, endDate?: Date, type: FuelHistoryType = FuelHistoryType.All, fuelCardId?: number, userId?: string, block = true) => (block
  ? createBlockingThunk(retrieveTransactionHistoryForFleetThunk(fleetId, startDate, endDate, type, fuelCardId, userId))
  : createNonBlockingThunk(retrieveTransactionHistoryForFleetThunk(fleetId, startDate, endDate, type, fuelCardId, userId, false))
);

const retrieveIndividualFuelTransactionHistoryThunk = (
  userId: string,
  startDate?: Date,
  endDate?: Date,
  type: FuelHistoryType = FuelHistoryType.All,
  fuelCardId?: number,
  isBlocking: boolean = true,
): AppThunk<Promise<FuelHistory>> => async (dispatch) => {
  const { postIndividualFuelTransactionHistory } = useFuelApi();

  dispatch(fuelCardId ? setTransactionCardHistory(defaultTransactionHistory) : setTransactionCarrierHistory(defaultTransactionHistory));

  if (!isBlocking) {
    dispatch(fuelCardId ? setTransactionCardHistoryLoading(true) : setTransactionCarrierHistoryLoading(true));
  }

  try {
    const individualTransactions = await postIndividualFuelTransactionHistory(
      userId,
      startDate,
      endDate,
      type,
      fuelCardId,
    );

    individualTransactions.transactions = orderBy(individualTransactions.transactions, 'dateTime', 'desc');

    dispatch(fuelCardId ? setTransactionCardHistory(individualTransactions) : setTransactionCarrierHistory(individualTransactions));

    if (!isBlocking) {
      dispatch(fuelCardId ? setTransactionCardHistoryLoading(false) : setTransactionCarrierHistoryLoading(false));
    }

    return individualTransactions;
  } catch (err) {
    if (!isBlocking) {
      dispatch(fuelCardId ? setTransactionCardHistoryLoading(false) : setTransactionCarrierHistoryLoading(false));
    }
    throw err;
  }
};

export const retrieveIndividualFuelTransactionHistory = (userId: string, startDate?: Date, endDate?: Date, type: FuelHistoryType = FuelHistoryType.All, fuelCardId?: number, isBlocking: boolean = false) => (isBlocking
  ? createBlockingThunk(retrieveIndividualFuelTransactionHistoryThunk(userId, startDate, endDate, type, fuelCardId))
  : createNonBlockingThunk(retrieveIndividualFuelTransactionHistoryThunk(userId, startDate, endDate, type, fuelCardId, false))
);

export const selectTransactionCarrierHistory = () => (state: RootState) => state.efs.transactionCarrierHistory;
export const selectTransactionCarrierHistoryLoading = () => (state: RootState) => state.efs.transactionCarrierHistoryLoading;
export const selectTransactionCardHistory = () => (state: RootState) => state.efs.transactionHistoryCard;
export const selectTransactionCardHistoryLoading = () => (state: RootState) => state.efs.transactionHistoryCardLoading;

// FUEL CARD ASSIGNMENT HISTORY
const retrieveFleetFuelCardAssigmentHistoryThunk = (
  fleetId: string,
  fuelCardId: number,
  isBlocking: boolean = true,
): AppThunk<Promise<PortalApiFuelCardAssignmentHistory[]>> => async (dispatch) => {
  setFuelCardAssignmentHistory([]);

  const { getFleetFuelCardAssignmentHistory } = useFleetApi();

  if (!isBlocking) {
    dispatch(setTransactionCarrierHistoryLoading(true));
  }

  try {
    const fleetTransactions = await getFleetFuelCardAssignmentHistory(
      fleetId,
      fuelCardId,
    );

    dispatch(setFuelCardAssignmentHistory(orderBy<PortalApiFuelCardAssignmentHistory>(fleetTransactions, 'sysStartTime', 'desc')));
    if (!isBlocking) {
      dispatch(setFuelCardAssignmentHistoryLoading(false));
    }

    return fleetTransactions;
  } catch (err) {
    if (!isBlocking) {
      dispatch(setFuelCardAssignmentHistoryLoading(true));
    }
    throw err;
  }
};

export const retrieveFleetFuelCardAssigmentHistory = (fleetId: string, fuelCardId: number, block = true) => (block
  ? createBlockingThunk(retrieveFleetFuelCardAssigmentHistoryThunk(fleetId,
    fuelCardId))
  : createNonBlockingThunk(retrieveFleetFuelCardAssigmentHistoryThunk(fleetId,
    fuelCardId,
    false)));

// const registerIndividualSmartCardThunk = (registrationData: RegisterSmartCard): AppThunk<Promise<FuelCardFuelLoadResponse>> => async (dispatch) => {
//   const { registerSmartCard } = usePortalApi();

//   const response = await registerSmartCard(registrationData);
//   return response;
// };

// export const registerIndividualSmartCard = (registrationData: RegisterSmartCard, block = true) => (block
//   ? createBlockingThunk(registerIndividualSmartCardThunk(registrationData))
//   : createNonBlockingThunk(registerIndividualSmartCardThunk(registrationData)));

export const selectFuelCardAssigmentHistory = () => (state: RootState) => state.efs.fuelCardAssignmentHistory;
export const selectFuelCardAssigmentHistoryLoading = () => (state: RootState) => state.efs.fuelCardAssignmentHistoryLoading;

// PENDING, SCHEDULED, VOIDED LOADS

export const retrievePendingLoadsForIndividualCarrierThunk = (userId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const { getPendingLoadsForIndividualCarrier } = useUserApi();
  dispatch(setPendingLoads([]));
  dispatch(setPendingLoadsLoading(true));
  const pendingLoads = await getPendingLoadsForIndividualCarrier(userId);
  dispatch(setPendingLoadsLoading(false));
  dispatch(setPendingLoads(pendingLoads));
};

export const retrievePendingLoadsForIndividualThunk = (fuelCardId: number): AppThunk<Promise<void>> => async (dispatch) => {
  const { getPendingLoadsForIndividualFuelCard } = useUserApi();
  dispatch(setCardPendingLoads([]));
  dispatch(setCardPendingLoadsLoading(true));
  const pendingLoads = await getPendingLoadsForIndividualFuelCard(fuelCardId);
  dispatch(setCardPendingLoadsLoading(false));
  dispatch(setCardPendingLoads(pendingLoads));
};

export const retrievePendingLoadsForFleetThunk = (fleetId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const { getPendingLoadsForFleet } = useFleetApi();
  dispatch(setPendingLoads([]));
  dispatch(setPendingLoadsLoading(true));
  const pendingLoads = await getPendingLoadsForFleet(fleetId);
  dispatch(setPendingLoadsLoading(false));
  dispatch(setPendingLoads(pendingLoads));
};

export const retrieveScheduledLoadsForFleetThunk = (fleetId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const { getScheduledLoadsForFleet } = useFuelApi();
  dispatch(setScheduledLoads([]));
  dispatch(setScheduledLoadsLoading(true));
  const scheduledLoads = await getScheduledLoadsForFleet(fleetId);
  dispatch(setScheduledLoadsLoading(false));
  dispatch(setScheduledLoads(scheduledLoads));
};

const fetchScheduledLoadsForSmartCardThunk = (cardId: number): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { getScheduledLoadsForSmartCard } = useFuelApi();
    dispatch(setCardScheduledLoads([]));
    dispatch(setCardScheduledLoadsLoading(true));
    const scheduledLoads = await getScheduledLoadsForSmartCard(cardId);
    dispatch(setCardScheduledLoadsLoading(false));
    dispatch(setCardScheduledLoads(scheduledLoads));
  } catch (err) {
    dispatch(setCardScheduledLoadsLoading(false));
    throw err;
  }
};

const fetchScheduledLoadsForIndividualCarrierThunk = (userId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { getScheduledLoadsForIndividualCarrier } = useFuelApi();
    dispatch(setScheduledLoads([]));
    dispatch(setScheduledLoadsLoading(true));
    const scheduledLoads = await getScheduledLoadsForIndividualCarrier(userId);
    dispatch(setScheduledLoadsLoading(false));
    dispatch(setScheduledLoads(scheduledLoads));
  } catch (err) {
    dispatch(setScheduledLoadsLoading(false));
    throw err;
  }
};

export const retrieveScheduledLoadsForSmartCardThunk = (cardId: number) => createBlockingThunk(fetchScheduledLoadsForSmartCardThunk(cardId));

export const retrieveScheduledLoadsForIndividualCarrierThunk = (userId: string) => createBlockingThunk(fetchScheduledLoadsForIndividualCarrierThunk(userId));

export const retrieveVoidedLoadsForFleetThunk = (fleetId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const { getVoidedLoadsForFleet } = useFleetApi();
  dispatch(setVoidedLoads([]));
  dispatch(setVoidedLoadsLoading(true));
  const voidedLoads = await getVoidedLoadsForFleet(fleetId);
  dispatch(setVoidedLoadsLoading(false));

  dispatch(setVoidedLoads(voidedLoads));
};

export const retrieveVoidedLoadsForUserThunk = (cardId: number): AppThunk<Promise<void>> => async (dispatch) => {
  const { getVoidedLoadsForUser } = useUserApi();
  dispatch(setVoidedLoadsCard([]));
  dispatch(setVoidedCardLoadsLoading(true));
  const voidedLoads = await getVoidedLoadsForUser(cardId);
  dispatch(setVoidedCardLoadsLoading(false));

  dispatch(setVoidedLoadsCard(voidedLoads));
};

export const updateSmartCardScheduledLoadPauseInfo = (cardId: number, pauseInfo: TriggeredFundPauseModel): AppThunk<Promise<void>> => async (dispatch) => {
  const { postPauseTriggeredLoadSmartCard } = useFuelApi();
  try {
    dispatch(incrementBlockingRequests());
    await postPauseTriggeredLoadSmartCard(pauseInfo);
    await dispatch(retrieveScheduledLoadsForSmartCardThunk(cardId));
    dispatch(
      addAlert({
        message: 'Pause information updated successfully',
        severity: 'success',
      }),
    );
    dispatch(decrementBlockingRequests());
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const removeSmartCardScheduledLoadPauseInfo = (cardId: number, scheduledLoadId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const { deletePauseTriggeredLoadSmartCard } = useFuelApi();
  try {
    dispatch(incrementBlockingRequests());
    await deletePauseTriggeredLoadSmartCard(scheduledLoadId);
    await dispatch(retrieveScheduledLoadsForSmartCardThunk(cardId));
    dispatch(
      addAlert({
        message: 'Pause information removed successfully',
        severity: 'success',
      }),
    );
    dispatch(decrementBlockingRequests());
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const fetchIndividualCarrierNextAvailFundingInfo = (userId: string) => createBlockingThunk<NextAvailableFuelLoadModel>(
  async (dispatch) => {
    const { getIndividualCarrierNextAvailableFundingDate } = useUserApi();
    dispatch(setIndividualCarrierNextAvailFundingInfo(undefined));
    const nextAvailInfo = await getIndividualCarrierNextAvailableFundingDate(userId);
    dispatch(setIndividualCarrierNextAvailFundingInfo(nextAvailInfo));

    return nextAvailInfo;
  },
);

const commonUpdateScheduledLoadPauseInfo = (pauseInfo: TriggeredFundPauseModel, reFetchScheduledLoads: () => AppThunk<Promise<void>, unknown>): AppThunk<Promise<void>> => async (dispatch) => {
  const { postPauseTriggeredLoad } = useFuelApi();
  try {
    dispatch(incrementBlockingRequests());
    await postPauseTriggeredLoad(pauseInfo);
    await dispatch(reFetchScheduledLoads());
    dispatch(
      addAlert({
        message: 'Pause information updated successfully',
        severity: 'success',
      }),
    );
    dispatch(decrementBlockingRequests());
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const updateIndividualCarrierScheduledLoadPauseInfo = (userId: string, pauseInfo: TriggeredFundPauseModel): AppThunk<Promise<void>> => async (dispatch) => {
  const reFetchScheduledLoads = () => retrieveScheduledLoadsForIndividualCarrierThunk(userId);
  dispatch(commonUpdateScheduledLoadPauseInfo(pauseInfo, reFetchScheduledLoads));
};

export const updateScheduledLoadPauseInfo = (fleetId: string, pauseInfo: TriggeredFundPauseModel): AppThunk<Promise<void>> => async (dispatch) => {
  const reFetchScheduledLoads = () => retrieveScheduledLoadsForFleetThunk(fleetId);
  dispatch(commonUpdateScheduledLoadPauseInfo(pauseInfo, reFetchScheduledLoads));
};

const commonRemoveScheduledLoadPauseInfo = (scheduledLoadId: string, reFetchScheduledLoads: () => AppThunk<Promise<void>, unknown>): AppThunk<Promise<void>> => async (dispatch) => {
  const { deletePauseTriggeredLoad } = useFuelApi();
  try {
    dispatch(incrementBlockingRequests());
    await deletePauseTriggeredLoad(scheduledLoadId);
    await dispatch(reFetchScheduledLoads());
    dispatch(
      addAlert({
        message: 'Pause information removed successfully',
        severity: 'success',
      }),
    );
    dispatch(decrementBlockingRequests());
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const removeIndividualCarrierScheduledLoadPauseInfo = (userId: string, scheduledLoadId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const reFetchScheduledLoads = () => retrieveScheduledLoadsForIndividualCarrierThunk(userId);
  dispatch(commonRemoveScheduledLoadPauseInfo(scheduledLoadId, reFetchScheduledLoads));
};

export const removeScheduledLoadPauseInfo = (fleetId: string, scheduledLoadId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const reFetchScheduledLoads = () => retrieveScheduledLoadsForFleetThunk(fleetId);
  dispatch(commonRemoveScheduledLoadPauseInfo(scheduledLoadId, reFetchScheduledLoads));
};

export const retrieveVoidedLoadsForUserCarrierThunk = (userId: string): AppThunk<Promise<void>> => async (dispatch) => {
  const { getVoidedLoadsIndividualCarrier } = useUserApi();
  dispatch(setVoidedLoads([]));
  dispatch(setVoidedLoadsLoading(true));
  const voidedLoads = await getVoidedLoadsIndividualCarrier(userId);
  dispatch(setVoidedLoadsLoading(false));

  dispatch(setVoidedLoads(voidedLoads));
};

export const selectPendingLoads = (state: RootState) => state.efs.pendingLoads;
export const selectCardPendingLoads = (state: RootState) => state.efs.pendingLoadsCard;
export const selectScheduledLoads = (state: RootState) => state.efs.scheduledLoads;
export const selectCardScheduledLoads = (state: RootState) => state.efs.scheduledLoadsCard;
export const selectErroredScheduledLoads = (state: RootState) => state.efs.erroredScheduledLoads;
export const selectErroredScheduledLoadsGrouped = (state: RootState) => flow(
  groupBy('ownerName'),
  map((sloads: TriggeredFundModel[], key: string): GroupedScheduledLoads => ({
    name: key, fleetId: head(sloads)?.fleetId, carrierId: head(sloads)?.carrierId, scheduledLoads: sloads.filter((sl) => sl.hasError),
  })),
  filter('scheduledLoads.length'),
  values,
)(state.efs.erroredScheduledLoads);

export const selectVoidedLoads = (state: RootState) => state.efs.voidedLoads;
export const selectVoidedLoadsCard = (state: RootState) => state.efs.voidedLoadsCard;

export const selectPendingLoadsLoading = (state: RootState) => state.efs.pendingLoadsLoading;
export const selectCardPendingLoadsLoading = (state: RootState) => state.efs.pendingLoadsCardLoading;
export const selectScheduledLoadsLoading = (state: RootState) => state.efs.scheduledLoadsLoading;
export const selectCardScheduledLoadsLoading = (state: RootState) => state.efs.scheduledLoadsCardLoading;
export const selectErroedScheduledLoadsLoading = (state: RootState) => state.efs.erroredScheduledLoadsLoading;
export const selectVoidedLoadsLoading = (state: RootState) => state.efs.voidedLoadsLoading;
export const selectVoidedLoadsCardLoading = (state: RootState) => state.efs.voidedLoadsCardLoading;
export const selectIndividualCarrierNextAvailFundingInfo = (state: RootState) => state.efs.individualCarrierNextAvailFundingInfo;

export default efsSlice.reducer;
