import {
  Backdrop, CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const MsalLoginRedirect = () => {
  const theme = useTheme();
  const loginSplashWrapperStyles: React.CSSProperties = {
    position: 'absolute',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
  };
  const loginSplashLogoWrapperStyles: React.CSSProperties = {
    display: 'block',
    margin: '0px',
    top: '40%',
    position: 'absolute',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
  };
  const styles = {
    loginSplashWrapper: loginSplashWrapperStyles,
    loginSplashLogoWrapper: loginSplashLogoWrapperStyles,
    loadingBackdrop: {
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.common.white,
    },
  };

  return (
    <div
      style={{
        ...styles.loginSplashWrapper,
        backgroundImage: "url('/tvc-prodriver-background.jpg')",
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Backdrop open sx={styles.loadingBackdrop}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <div style={styles.loginSplashLogoWrapper} />
    </div>
  );
};

export default MsalLoginRedirect;
