import React from 'react';
import { ListItemIcon, ListItemText, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';

interface ListItemContentProps {
  linkText: React.ReactNode;
  isSubLink: boolean;
  isExternal: boolean;
}

export const ListItemContent = (props: ListItemContentProps) => {
  const {
    linkText, isSubLink = false, isExternal = false,
  } = props;

  const theme = useTheme<Theme>();

  const styles = {
    subItem: {
      fontSize: '0.85em',
    },
    iconRoot: {
      color: theme.palette.common.white,
      minWidth: '28px',
      marginLeft: '8px',
    },
    listItemTextRoot: {
      flexGrow: 0,
    },
  };
  const subItemStyles = isSubLink ? styles.subItem : {};
  const rootStyles = isExternal ? styles.listItemTextRoot : {};
  return (
    <>
      <ListItemText
        color="secondary"
        primary={linkText}
        sx={{
          ...rootStyles,
          '& .MuiListItemText-primary': subItemStyles,
        }}
      />
      {isExternal && (
        <ListItemIcon
          sx={styles.iconRoot}
        >
          <LaunchIcon fontSize="small" />
        </ListItemIcon>
      )}
    </>
  );
};
