import React from 'react';
import {
  List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import SearchGridItem from '../../../shared/components/search/SearchGridItem';
import EntityActionButton from '../../../shared/components/EntityActionButton';
import { useProfile } from '../../auth/hooks/useProfile';
import { MemberDisplayProperties } from '../../../shared/models/matrix/MemberDisplayProperties';

interface MatrixMembershipGridItemProps {
  member: MemberDisplayProperties;
  onAddPortalLogin: (member: MemberDisplayProperties) => void;
}
const MatrixMembershipGridItem = (props: MatrixMembershipGridItemProps) => {
  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
      backgroundColor: theme.palette.grey[100],
    },
    listItem: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    listItemIcon: {
      minWidth: '24px',
      fontSize: '14px',
    },
  };
  const { member, onAddPortalLogin } = props;
  const { userProfile } = useProfile();
  const canManageUser = userProfile?.userPermissions?.canManageIndividualPortalUser;

  return (
    <SearchGridItem
      itemKey={`matrix-member=${member.id}`}
      minHeight={212.938}
      userCanClickItem={false}
      title={<span style={{ color: theme.palette.grey[600] }}>{`${member.firstName || ''} ${member.lastName || ''}`}</span>}
      borderLeftColor={theme.palette.grey[400]}
      cardStyles={{ backgroundColor: theme.palette.grey[100], display: 'flex', flexDirection: 'column' }}
      cardHeaderSubHeader={(
        <List
          component="div"
          sx={styles.root}
          aria-label="user contact information"
        >
          <ListItem disableGutters dense sx={styles.listItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <EmailIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText aria-label="email" primary={member.email ? member.email : '-'} />
          </ListItem>
          <ListItem disableGutters dense sx={styles.listItem}>
            <ListItemText
              aria-label="matrix info"
              primary={member.id}
            />
          </ListItem>
        </List>
      )}
      userCanViewCardActions={canManageUser}
      cardActionChildren={(
        <>
          <EntityActionButton
            onClick={canManageUser
              ? () => {
                onAddPortalLogin(member);
              }
              : undefined}
            aria-label={`create login portal account for ${member.firstName} ${member.lastName}`}
          >
            Add Portal Login
          </EntityActionButton>
        </>
      )}
      cardActionStyles={{ justifyContent: 'flex-start', flex: 1, alignItems: 'flex-end' }}
    />
  );
};

export default MatrixMembershipGridItem;
