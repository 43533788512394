import {
  Collapse, IconButton,
  Alert, AlertTitle,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAsyncErrorHandler } from '../hooks/useTvcAppInsights';
import { useBreakPoints } from '../hooks/useBreakPoints';

import { useApplicationApi } from '../../services/api/hooks/useApplicationApi';
import { SystemNotificationModel } from '../models/application/SystemNotificationModel';

interface SystemNotificationProps {
  id: number,
  title: string,
  content: string
}

const SystemNotification = (props: SystemNotificationProps) => {
  const { title, content } = props;
  const [open, setOpen] = useState<boolean>(true);
  const { isSmDown } = useBreakPoints();
  const theme = useTheme();
  return (
    <Collapse
      in={open}
      sx={{
        marginTop: '4px', width: isSmDown ? '100%' : '90vw',
      }}
    >
      <Alert
        severity="info"
        icon={false}
        sx={{
          border: `${theme.palette.info.main} solid 1px`, boxShadow: theme.shadows[2],
        }}
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={async () => {
              // const { acknowledgeSystemNotifications } = usePortalApi();
              // await acknowledgeSystemNotifications(id);
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
        {HTMLReactParser(content)}
      </Alert>
    </Collapse>
  );
};

const SystemNotifications = () => {
  const [systemNotifications, setSystemNotifications] = useState<SystemNotificationModel[]>([]);
  const { getSystemNotifications } = useApplicationApi();
  const { handleError } = useAsyncErrorHandler();

  useEffect(() => {
    const loadSystemNotifications = async () => {
      try {
        const messages = await getSystemNotifications();
        setSystemNotifications(messages);
      } catch (error) {
        handleError(error, 'Error checking for new system notifications');
      }
    };
    loadSystemNotifications();
  }, []);

  return (
    <>
      {systemNotifications.map((sn) => (
        <SystemNotification key={`sys-notif-${sn.id}`} id={sn.id} title={sn.title} content={sn.contentHtml} />
      ))}
    </>
  );
};

export default SystemNotifications;
