import { useFleetNavigation } from './useFleetNavigation';
import { FleetUserPermissions } from '../../auth/permissionNames';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import SearchItemProps from '../../../shared/models/other/SearchItemProps';

export const useFleetSearchItem = (props: SearchItemProps<Fleet>) => {
  const { item, onPrimaryActionClick, userCanClickItem } = props;
  const { navigateToAppropriateFleetClickedRoute } = useFleetNavigation({
    fleet: item,
  });

  const fleetClicked = () => {
    navigateToAppropriateFleetClickedRoute();
  };

  const isLoadFundsDisabled = !(item.isValidCarrier && item.hasActiveFuelCards && item.isActive);

  const userCanLoadContract = Boolean(item.carrierId && item.cachePermissions?.permissions[FleetUserPermissions.canLoadFleetFuelContract]);

  const userCanViewContractBalance = item.cachePermissions?.permissions[FleetUserPermissions.canViewFleetFuelContractBalance];

  return {
    fleet: item,
    fleetClicked,
    onLoadFundsClick: onPrimaryActionClick,
    userCanLoadContract,
    userCanClickFleet: userCanClickItem,
    userCanViewContractBalance,
    isLoadFundsDisabled,
  };
};
