import React from 'react';
import {
  ListItemButton, Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ListItemContent } from './ListItemContent';

interface NavItemOnClickProps {
  linkText?: React.ReactNode;
  isSub?: boolean;
  isExternal?: boolean;
  onClick?: () => void;
}
export const NavItemOnClick = (props: NavItemOnClickProps) => {
  const {
    linkText, isExternal = false, isSub = false, onClick,
  } = props;

  const theme = useTheme<Theme>();

  const styles = {
    root: {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
      },
      color: '#e0e0e0',
      cursor: 'pointer',
    },
    subItem: {
      fontSize: '0.85em',
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    selectedHover: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  };

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        ...styles.root,
        '&.Mui-selected': styles.selected,
        '&.Mui-selected:hover': styles.selectedHover,
      }}
    >
      <ListItemContent linkText={linkText} isSubLink={isSub} isExternal={isExternal} />
    </ListItemButton>
  );
};
