import {
  Box, Fade, FormControl, Grid, MenuItem, Select,
  Pagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isListViewSelected } from '../../features/application/applicationSlice';
import { useBreakPoints } from '../hooks/useBreakPoints';
import { usePagination } from '../hooks/usePagination';
import { PagingProps } from '../models/other/PagingProps';
import { fadeTimeout } from '../transitions/timeouts';
import ContentPadding from './ContentPadding';
import GridCollectionWrapper from './GridCollectionWrapper';
import ListCollectionWrapper from './ListCollectionWrapper';
import NoResults from './NoResults';
import ToggleResultsViewButtons from './ToggleResultsViewButtons';

interface EntityCollectionPageProps extends PagingProps {
  entityCollecitonHeader?: React.ReactNode;
  children?: React.ReactNode;
  showViewToggle?: boolean;
  additionalRow?: React.ReactNode;
}

const EntityCollectionPage = React.forwardRef(
  (props: EntityCollectionPageProps, ref: any) => {
    const theme = useTheme();
    const styles = {
      container: {
        alignContent: 'start',
      },
      collectionControls: {
        display: 'flex',
        flexBasis: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft: '8px',
        [theme.breakpoints.down('sm')]: {
          marginTop: '15px',
          marginLeft: '0px',
        },
      },
      pagingControls: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flex: '1',
          flexBasis: '100%',
          justifyContent: 'space-around',
        },
      },
      toggleViewButtons: {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flex: '1',
          justifyContent: 'center',
          marginTop: '20px',
        },
      },
      pageSizes: {
        marginRight: '20px',
        [theme.breakpoints.down('sm')]: {
          marginRight: '0px',
        },
      },
    };
    const isListView = useSelector(isListViewSelected);
    const {
      entityName,
      activePageColor,
      resetPagingDependencies,
      jsxItems,
      pageSizes,
      pagingControlsVisibility,
      isEmptyResults,
      changePage,
      handlePageSizeChange,
      getPagedEntities,
      setPage,
      pageSize,
      page,
    } = usePagination(props);
    const { isSmDown } = useBreakPoints();
    const {
      entityCollecitonHeader, children, showViewToggle = true, additionalRow,
    } = props;

    useEffect(() => {
      setPage(1);
    }, resetPagingDependencies);

    return (
      <Grid container ref={ref} sx={styles.container}>
        <Grid item xs={12}>
          <ContentPadding containerStyle={{ padding: isSmDown ? '0px' : '' }}>
            <>
              {entityCollecitonHeader}
              <Box sx={styles.collectionControls}>
                <Box sx={styles.pagingControls}>
                  <FormControl sx={styles.pageSizes}>
                    <Select
                      labelId="rows-per-page"
                      id="rows-selection"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                    >
                      {pageSizes.map((size) => (
                        <MenuItem value={size} key={`page-size-${size}`}>
                          {size}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Pagination
                    sx={{
                      display: 'inline-block',
                      visibility: pagingControlsVisibility,
                    }}
                    count={Math.ceil(jsxItems.length / pageSize)}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    showFirstButton={!isSmDown}
                    showLastButton={!isSmDown}
                    shape="rounded"
                    color={activePageColor}
                    onChange={changePage}
                    size={isSmDown ? 'small' : 'medium'}
                    disabled={jsxItems.length <= pageSize}
                  />
                </Box>
                {showViewToggle && (
                  <Box sx={styles.toggleViewButtons}>
                    <ToggleResultsViewButtons isDisabled={jsxItems.length < 1} />
                  </Box>
                )}
              </Box>
            </>
          </ContentPadding>
        </Grid>
        {additionalRow
          && (
            <Grid item xs={12}>

              {additionalRow}
            </Grid>
          )}
        <Grid item xs={12}>
          <ContentPadding containerStyle={{ padding: isSmDown ? '0px' : '' }}>
            <>
              {!isEmptyResults && (
                <>
                  {isListView ? (
                    <ListCollectionWrapper>
                      {getPagedEntities(jsxItems).map(
                        (entity: JSX.Element) => entity,
                      )}
                    </ListCollectionWrapper>
                  ) : (
                    <GridCollectionWrapper>
                      {getPagedEntities(jsxItems).map((entity: any) => entity)}
                    </GridCollectionWrapper>
                  )}
                </>
              )}

              {isEmptyResults && (
                <Fade in timeout={fadeTimeout}>
                  <div>
                    <NoResults>
                      No
                      {' '}
                      {entityName}
                      s found
                    </NoResults>
                  </div>
                </Fade>
              )}
            </>
          </ContentPadding>
          <Grid item xs={12}>
            <div>{children}</div>
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

export default EntityCollectionPage;
