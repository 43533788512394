import React from 'react';
import {
  List, ListItem, ListItemIcon, ListItemText, Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import { PortalApiUser } from '../../../shared/models/user/PortalApiUser';
import SearchGridItem from '../../../shared/components/search/SearchGridItem';
import { useIndividualMembershipSearchItem } from '../hooks/useIndividualMembershipSearchItem';
import { UserLoginAccountIcon, UserMembershipIcon } from '../../../shared/components/UserIcons';
import { MemberDisplayProperties } from '../../../shared/models/matrix/MemberDisplayProperties';
import SearchItemProps from '../../../shared/models/other/SearchItemProps';

interface IndividualMembershipUserSearchGridItemProps extends SearchItemProps<PortalApiUser> {
  member: MemberDisplayProperties;
}

const IndividualMembershipUserSearchGridItem = (props: IndividualMembershipUserSearchGridItemProps) => {
  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    listItem: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    listItemIcon: {
      minWidth: '24px',
      fontSize: '16px',
    },
  };
  const { member } = props;
  const {
    navigateToAppropriateIndividualMembershipClickedRoute,
    individualUser,
    userName,
  } = useIndividualMembershipSearchItem(props);

  return (
    <SearchGridItem
      itemKey={individualUser.userId}
      userCanClickItem
      minHeight={212.938}
      title={userName}
      gridClicked={() => (navigateToAppropriateIndividualMembershipClickedRoute())}
      userCanViewCardActions
      borderLeftColor={theme.palette.secondary.dark}
      cardStyles={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      paperHoverElevation={14}
      paperRestingElevation={4}
      cardHeaderSubHeader={(
        <List
          component="div"
          sx={styles.root}
          aria-label="user contact information"
        >
          <ListItem disableGutters dense sx={styles.listItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <EmailIcon fontSize="inherit" htmlColor={theme.palette.secondary.dark} />
            </ListItemIcon>
            <ListItemText aria-label="email" primary={individualUser.email ? individualUser.email : '-'} />
          </ListItem>
          <ListItem disableGutters dense sx={styles.listItem}>
            <ListItemText
              aria-label="matrix info"
              primary={<span style={{ fontWeight: 'bold', marginLeft: '24px' }}>Matrix Member Info</span>}
              secondary={(
                <Box display="flex" sx={{ flexDirection: 'column', marginLeft: '24px' }}>
                  <Box>
                    {member.firstName}
                    {' '}
                    {member.lastName}
                    {' '}
                    (
                    {member.id}
                    )
                  </Box>
                  <Box>{member.email}</Box>
                </Box>
              )}
            />
          </ListItem>
        </List>
      )}
      cardActionChildren={(
        <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
          <UserLoginAccountIcon
            applySpacing
            hasLoginAccount={individualUser.hasLogin}
            loginAccountIsActive={!individualUser.isDisabled}
          />
          <UserMembershipIcon
            applySpacing
            hasMembership={individualUser.hasMembership}
            membershipIsValid={individualUser.hasValidMembership}
          />
        </div>
      )}
    />
  );
};

export default IndividualMembershipUserSearchGridItem;
