import { useDeferredValue, useEffect, useState } from 'react';

export const useSearchText = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [searchTextHasValue, setSearchTextHasValue] = useState<boolean>(false);
  const [searchTextAsNumber, setSearchTextAsNumber] = useState<number>(0);
  const deferedSearchText = useDeferredValue(searchText);

  useEffect(() => {
    setSearchTextHasValue(Boolean(searchText?.trim()));
  }, [searchText]);

  useEffect(() => {
    setSearchTextAsNumber(parseFloat(searchText));
  }, [searchText]);

  return {
    searchText,
    deferedSearchText,
    setSearchText,
    searchTextHasValue,
    searchTextAsNumber,
  };
};
