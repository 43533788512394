import React, { useEffect, useState } from 'react';
import {
  Box, Button, Divider, Grid, IconButton, Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { isEqual } from 'lodash';

import CustomSelectInput from '../../shared/components/formFields/CustomSelectInput';
import { MatrixProduct } from '../../shared/types/matrix/MatrixProduct';
import { useMatrixProducts } from './hooks/useMatrixProducts';
import { MatrixPromotion } from '../../shared/models/matrix/MatrixPromotion';
import { ProductPromotions } from '../../shared/models/fleet/ProductPromotions';
import { FleetProductTemplate } from '../../shared/models/fleet/FleetProductTemplate';

interface FleetTemplatesProps {
  initializeWithOne?: boolean;
  existingProductPromos?: FleetProductTemplate[];
}
const FleetTemplates = (props: FleetTemplatesProps) => {
  const { initializeWithOne = false, existingProductPromos = [] } = props;
  const theme = useTheme();
  const {
    products,
    getProductDisplay,
    getPromotionDisplay,
    getProductPromotions,
  } = useMatrixProducts(true);

  const {
    control, trigger, watch, setValue,
  } = useFormContext<ProductPromotions>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'productPromotions',
  });

  const [prodPromoCompare, setProdPromoCompare] = useState(fields);
  const prodPromoWatch = watch('productPromotions');

  useEffect(() => {
    if (prodPromoWatch && !isEqual(prodPromoWatch, prodPromoCompare)) {
      setProdPromoCompare(prodPromoWatch);
      setValue('productPromotions', prodPromoWatch);
      setTimeout(() => {
        trigger();
      }, 100);
    }
  }, [prodPromoWatch]);

  useEffect(() => {
    if (initializeWithOne) {
      append({});
      setTimeout(() => {
        trigger();
      }, 100);
    }
  }, []);
  return (
    <Grid container spacing={2}>
      {fields.length > 0 && fields.map((fieldItem, index: number) => {
        const combinedProdPromos = fields.concat(existingProductPromos);
        const alreadyDefined = combinedProdPromos.some((ppc) => ppc.id !== fieldItem.id && ppc.productId === fieldItem.productId && ppc.promotionId === fieldItem.promotionId);

        return (
          <React.Fragment key={`field-item-${fieldItem.id}`}>
            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box>
                <Tooltip
                  title="Remove Member Product Configuration"
                  aria-label="remove member product configuration"
                >
                  <IconButton
                    onClick={() => { remove(index); }}
                    size="medium"
                  >
                    <RemoveCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomSelectInput<MatrixProduct>
                    label="Product"
                    name={`productPromotions.${index}.productId`}
                    defaultValue={fieldItem.productId}
                    style={{ flex: '1', display: 'flex' }}
                    rules={{
                      required: 'Product required',
                      validate: {
                        checkProdPromoCombo: () => (alreadyDefined ? 'Product/promotion configuration already exists' : true),
                      },
                    }}
                    items={products}
                    itemValue={(p) => p.id}
                    itemDisabled={(item) => !item.promotions.length}
                    itemKey={(p) => `product-item-${p.id}`}
                    itemDisplay={(item: MatrixProduct) => {
                      const hasPromotions = Boolean(item.promotions.length);
                      return (
                        <span>
                          {getProductDisplay(item.id)}
                          {!hasPromotions ? (
                            <span style={{
                              fontStyle: 'italic',
                              color: theme.palette.error.main,
                            }}
                            >
                              {' '}
                              (no promotions)
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      );
                    }}
                    key={`template-${fieldItem.id}-${index}-product-${fieldItem.productId}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelectInput<MatrixPromotion>
                    label={
                      !fieldItem.productId
                        ? 'Promotion (select product)'
                        : 'Promotion'
                    }
                    name={`productPromotions.${index}.promotionId`}
                    defaultValue={fieldItem.promotionId}
                    disabled={!fieldItem.productId}
                    style={{ flex: '1', display: 'flex' }}
                    rules={{
                      required: 'Promotion required',
                      validate: {
                        checkValidPromoId: (promoId: number) => {
                          const promos = getProductPromotions(fieldItem.productId) || [];
                          const promoExists = promos.some((pr) => pr.id === promoId);

                          return promoExists ? true : 'Please select a valid promotion id';
                        },
                        checkProdPromoCombo: () => (alreadyDefined ? 'Product/promotion configuration already exists' : true),
                      },
                    }}
                    items={getProductPromotions(fieldItem.productId) || []}
                    itemKey={(promo) => `template-${fieldItem.id}-promotion-${promo.id}`}
                    itemValue={(p) => p.id}
                    itemDisplay={(item: MatrixPromotion) => getPromotionDisplay(item)}
                    key={`template-${fieldItem.id}-${index}-promo-${fieldItem.promotionId}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ marginBottom: '8px' }}>
                    Display Name:
                    {' '}
                    <span style={{ fontWeight: 'bold', textDecoration: fieldItem.friendlyName ? 'line-through' : undefined }}>{getProductDisplay(fieldItem.productId)}</span>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {fields.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        );
      })}

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start' }}>
        <Tooltip
          title="Add Member Product Configuration"
          aria-label="add member product configuration"
        >
          <Button
            size="small"
            color="primary"
            variant="text"
            onClick={() => {
              append({});
              setTimeout(() => {
                trigger();
              }, 1);
            }}
            startIcon={<AddIcon fontSize="small" />}
            disabled={fields.length >= 20}
          >
            <Box sx={{ paddingTop: '1px' }}>{`Add${fields.length > 0 ? ' Another' : ''} Member Product Configuration`}</Box>
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default FleetTemplates;
