import React from 'react';
import UsingDefault from './UsingDefault';

interface LimitDisplayLabelProps {
  label: React.ReactNode;
  isInEditMode?: boolean;
  limitValue: number | undefined | null;
  canEditLoadLimits?: boolean;
  style?: React.CSSProperties;
  isLoading?: boolean;
}

const LimitDisplayLabel = (props: LimitDisplayLabelProps) => {
  const {
    label,
    isInEditMode = false,
    limitValue,
    canEditLoadLimits = false,
    style,
    isLoading = false,
  } = props;

  return (
    <span style={{ ...style }}>
      {label}
      {!limitValue
        && !isInEditMode
        && !isLoading
        && (canEditLoadLimits ? <UsingDefault /> : '')}
    </span>
  );
};

export default LimitDisplayLabel;
