import {
  Box, CircularProgress, Fade, Paper, Tab, Tabs,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PublishIcon from '@mui/icons-material/Publish';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { CalendarToday } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectPendingLoads, selectScheduledLoads } from '../../../features/fuel/efsSlice';
import { useBreakPoints } from '../../hooks/useBreakPoints';
import { RootState } from '../../../app/store';
import { PendingLoad } from '../../models/fuel/PendingLoad';

const LoadingContent = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px',
    }}
    data-testid="loading-content"
  >
    <CircularProgress />
  </Box>
);

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  style?: React.CSSProperties;
}

const TabPanel = (props: TabPanelProps) => {
  const {
    children, value, index, style, ...other
  } = props;
  const { isXsDown } = useBreakPoints();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-tab-${index}`}
      style={style}
      {...other}
    >
      <Paper>
        <Box p={isXsDown ? 1 : 3}>{children}</Box>
      </Paper>
    </div>
  );
};

interface LoadFuelFundsTabsProps {
  loadFundsTabContent?: React.ReactNode;
  scheduleLoadsTabContent?: React.ReactNode;
  pendingLoadsTabContent?: React.ReactNode;
  activeTab?: number;
  loadFundsTabContentLoading: boolean;
  disableTabs?: boolean;
  headerContent?: React.ReactNode;
  userCanViewPendingLoads?: boolean;
  userCanViewScheduledLoads?: boolean;
  pendingLoadsSelector?: (state: RootState) => PendingLoad[];
}

const LoadFuelFundsTabs = (props: LoadFuelFundsTabsProps) => {
  const {
    loadFundsTabContent,
    scheduleLoadsTabContent,
    pendingLoadsTabContent,
    activeTab,
    loadFundsTabContentLoading = false,
    disableTabs = false,
    headerContent,
    userCanViewPendingLoads = false,
    userCanViewScheduledLoads = false,
    pendingLoadsSelector = selectPendingLoads,
  } = props;
  const theme = useTheme();
  const styles = {
    topOverview: {
      display: 'flex',
      margin: '0.5em 0',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },
    },
  };
  const [tab, setTab] = useState(0);
  const { isSmDown } = useBreakPoints();

  useEffect(() => {
    setTab(activeTab || 0);
  }, [activeTab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setTab(tabIndex);
  };

  const handleChangeIndex = (index: number) => {
    setTab(index);
  };

  const pendingLoads = useSelector(pendingLoadsSelector);

  const scheduledLoads = useSelector(selectScheduledLoads);

  return (
    <Fade in style={{ transitionDelay: '200ms' }} timeout={600}>
      <Box display="flex" flex={1} flexDirection="column">
        <Box sx={styles.topOverview}>
          {headerContent}
        </Box>

        {/* NOTE: The way these tabs work... you cannot conditionally render.. we have to set the display property accordingly  */}
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="load fuel funds tabs"
        >
          <Tab
            disabled={disableTabs}
            label={isSmDown ? 'Load' : 'Load Funds'}
            icon={<PublishIcon />}
            data-testid="load-funds-tab"
          />
          <Tab
            sx={{
              display: userCanViewScheduledLoads && Boolean(scheduleLoadsTabContent) ? undefined : 'none',
            }}
            disabled={disableTabs}
            label={`${isSmDown ? 'Sched' : 'Scheduled Loads'} (${scheduledLoads?.length ? scheduledLoads.length : 0})`}
            icon={<CalendarToday />}
            data-testid="scheduled-loads-tab"
          />
          <Tab
            sx={{
              display: userCanViewPendingLoads ? undefined : 'none',
            }}
            disabled={disableTabs}
            label={`${isSmDown ? 'Pending' : 'Pending Loads'} (${pendingLoads?.length ? pendingLoads.length : 0})`}
            icon={<QueryBuilderIcon />}
            data-testid="pending-loads-tab"
          />
        </Tabs>

        <SwipeableViews
          axis="x-reverse"
          index={tab}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={tab} index={0} dir={theme.direction}>
            {loadFundsTabContentLoading ? (
              <LoadingContent />
            ) : (
              loadFundsTabContent
            )}
          </TabPanel>

          <TabPanel
            value={tab}
            index={1}
            dir={theme.direction}
            style={{
              display: userCanViewScheduledLoads && tab === 1 ? 'block' : 'none',
            }}
          >
            {scheduleLoadsTabContent}
          </TabPanel>

          <TabPanel
            value={tab}
            index={2}
            dir={theme.direction}
            style={{
              display: userCanViewPendingLoads && tab === 2 ? 'block' : 'none',
            }}
          >
            {pendingLoadsTabContent}
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Fade>
  );
};

export default LoadFuelFundsTabs;
