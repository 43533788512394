import React from 'react';
import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
} from '@azure/msal-react';
import { HelmetProvider } from 'react-helmet-async';
import {
  Alert, AlertTitle, CssBaseline, StyledEngineProvider, Theme,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './appTheme';
import MsalLoginRedirect from './features/auth/login/MsalLoginRedirect';
import Root from './Root';
import { signInAuthProvider } from './services/authService';
import TvcAppInsightsErrorBoundary from './shared/components/error-boundary/TvcAppInsightsErrorBoundary';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface MsalErrorProps {
  msalAuthResult: MsalAuthenticationResult;
}

const MsalError = (props: MsalErrorProps) => {
  const { msalAuthResult } = props;
  const { error } = msalAuthResult;
  // This code represents a user-cancellation action, so
  // if the user cancelled, just send them to the login page
  if (error?.errorMessage.includes('AADB2C90091')) {
    window.location.pathname = '/';
    return null;
  }
  return (
    <Alert severity="error">
      <AlertTitle>
        Error -
        {' '}
        {error ? error.name : 'Unknown Error'}
      </AlertTitle>
      {error ? error.errorMessage : 'An unknown error occurred, please try again.'}
    </Alert>
  );
};

const AppDefault = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <TvcAppInsightsErrorBoundary>
        <MsalProvider instance={signInAuthProvider}>
          <CssBaseline />
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={MsalLoginRedirect}
            errorComponent={(errorResult: MsalAuthenticationResult) => (
              <MsalError msalAuthResult={errorResult} />
            )}
          >
            <HelmetProvider>
              <Root />
            </HelmetProvider>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </TvcAppInsightsErrorBoundary>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default AppDefault;
