import React from 'react';
import { Tooltip } from '@mui/material';
import TvcBadge from './TvcBadge';

interface InvalidCarrierProps {
  isValidCarrier?: boolean;
  children: React.ReactNode;
}

const InvalidCarrier = (props: InvalidCarrierProps) => {
  const {
    isValidCarrier,
    children,
  } = props;

  return (
    <Tooltip title={!isValidCarrier ? 'Invalid Carrier or No Active Fuel Cards' : ''} aria-label="invalid carrier or no active fuel card badge">
      <TvcBadge badgeColor="error" variant="dot" invisible={isValidCarrier}>
        { children }
      </TvcBadge>
    </Tooltip>
  );
};

export default InvalidCarrier;
