import axios, { AxiosResponseHeaders } from 'axios';
import { parseEnv } from '../../../config';
import { getAccessToken } from '../../authService';

export const initAxiosInstance = () => {
  const config = parseEnv();

  const portalClient = axios.create({
    baseURL: config.REACT_APP_PORTAL_API_BASE_URL,
    responseType: 'json',
  });

  portalClient.interceptors.request.use(async (conf) => {
    const nextConfig = { ...conf };
    nextConfig.headers = {
      ...conf.headers,
      Authorization: `Bearer ${await getAccessToken()}`,
      Accept: 'application/json',
    };

    return nextConfig;
  });

  return portalClient;
};

export const createFile = (blob: Blob, headers: AxiosResponseHeaders): File => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const disposition = headers['content-disposition'];
  const matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) {
    const filename = matches[1].replace(/['"]/g, '');
    return new File([blob], filename);
  }
  return new File([blob], '');
};
