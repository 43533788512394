import {
  FormControl,
  Input,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { FilterOption } from './FilterOption';

// const MenuProps = {
//   getContentAnchorEl: null,
// };

interface MultiSelectFilterProps<T> {
  labelId: string;
  selectId: string;
  inputLabel: string;
  value: string[];
  handleSelection: (event: SelectChangeEvent<string[]>) => void;
  options: FilterOption<T>[],
  isMultiple?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const MultiSelectFilter = <T,>(props: MultiSelectFilterProps<T>) => {
  const {
    labelId, selectId, inputLabel, value, handleSelection, options, isMultiple = true, disabled = false, style,
  } = props;

  const theme = useTheme();
  const styles = {
    formControl: {
      marginLeft: '24px',
      minWidth: '200px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '20px',
      },
    },
    helperTextRoot: {
      marginLeft: '0px !important',
    },
    inputLabelRoot: {
      left: '-14px',
    },
  };

  return (
    <FormControl
      sx={{
        ...styles.formControl,
        flex: '1',
        marginY: '14px',
        ...style,
      }}
    >
      <InputLabel
        id={labelId}
        sx={styles.inputLabelRoot}
      >
        {inputLabel}
      </InputLabel>
      <Select
        disabled={disabled}
        labelId={labelId}
        id={selectId}
        multiple={isMultiple}
        value={value}
        onChange={handleSelection}
        input={<Input />}
        // MenuProps={MenuProps}
        renderValue={(renderValues) => (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {(renderValues as string[]).map((key) => {
              const statusItem = options.find((s) => s.key === key);
              return statusItem ? statusItem.icon ? statusItem.icon : <span style={{ display: 'inline-block', marginRight: '8px' }}>{statusItem.displayName}</span> : '<no selection>';
            })}
          </div>
        )}
      >
        {options.map((uls) => (
          <MenuItem
            key={uls.key}
            value={uls.key}
          >
            {uls.icon && (
              <ListItemIcon>
                {uls.icon}
              </ListItemIcon>
            )}
            <ListItemText primary={uls.displayName} />
          </MenuItem>

        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectFilter;
