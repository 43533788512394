import {
  Box, Chip, Fade, Paper, Zoom,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface PinnedSubHeaderProps {
  selectionExists: boolean;
  chipLabel: string;
  selectedLabel?: string;
  onDelete?: () => void;
  disabled?: boolean;
  show?: boolean;
}
const PinnedSubHeader = (props: PinnedSubHeaderProps) => {
  const theme = useTheme();
  const styles = {
    selectedLabel: {
      paddingRight: '20px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
  };

  const {
    selectionExists,
    chipLabel,
    onDelete,
    selectedLabel = 'Currently selected',
    disabled,
    show = true,
  } = props;

  return (
    <Fade in={show} timeout={200}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: '0px',
          padding: '1rem 0rem',
        }}
      >
        <Box display="flex" flexWrap="wrap">
          <Box
            sx={{
              ...styles.selectedLabel,
              color: selectionExists ? 'initial' : theme.palette.action.disabled,
            }}
          >
            {selectedLabel}
            :
          </Box>
          <Box>
            <Zoom in={selectionExists} mountOnEnter unmountOnExit timeout={200}>
              <Chip
                label={chipLabel}
                onDelete={onDelete}
                color="secondary"
                disabled={disabled}
              />
            </Zoom>
          </Box>
        </Box>
      </Paper>
    </Fade>
  );
};

export default PinnedSubHeader;
