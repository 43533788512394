import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { signInAuthProvider } from '../../../services/authService';

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props}><div>{props.children}</div></Slide>,
);

function formattedMilliseconds(msDuration: number): string {
  const h = Math.floor(msDuration / 1000 / 60 / 60);
  const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
  const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

  const seconds: string = s < 10 ? `0${s}` : `${s}`;
  const minutes: string = m < 10 ? `0${m}` : `${m}`;

  return `${minutes}m ${seconds}s`;
}

const minute = 60000;

interface TvcSessionExpiringPromptProps {
  sessionMinutes?: number;
  promptMinutesRemaining?: number;
}

const TvcSessionExpiringPrompt = (props: TvcSessionExpiringPromptProps) => {
  const { sessionMinutes = 60, promptMinutesRemaining = 5 } = props;
  const timeout = sessionMinutes * minute;
  const promptTime = promptMinutesRemaining * minute;

  const [remaining, setRemaining] = useState(timeout);
  const [, setLastEvent] = useState('Events Emitted on Leader');

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const handleOnActive = () => {
    setLastEvent('active');
  };
  const handleOnIdle = () => {
    setLastEvent('idle');
  };

  const handleClose = () => {};

  const { reset, getRemainingTime, isLeader } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: true,
    leaderElection: true,
  });

  useEffect(() => {
    setRemaining(getRemainingTime());

    const timer = setInterval(() => {
      setRemaining(getRemainingTime());
      isLeader();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleContinue = () => {
    setModalIsOpen(false);
    reset();
  };

  const logout = async () => {
    await signInAuthProvider.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/session-timeout.html`,
    });
  };

  useEffect(() => {
    if (remaining < promptTime) {
      setModalIsOpen(true);
    }

    if (remaining <= 0) {
      logout();
    }
  }, [remaining]);

  return (
    <Dialog
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">Session Timeout</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>Your session is about to end.</div>
          {' '}
          <div
            style={{
              visibility: remaining < promptTime ? 'visible' : 'hidden',
            }}
          >
            You will be automatically logged out in
            {' '}
            {formattedMilliseconds(remaining)}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleContinue} color="primary">
          Resume Session
        </Button>
        <Button onClick={logout} color="primary">
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TvcSessionExpiringPrompt;
