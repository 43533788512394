import React, { useEffect, useState } from 'react';
import {
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/store';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import CustomNumberInput from '../../shared/components/formFields/CustomNumberInput';
import CustomTextInput from '../../shared/components/formFields/CustomTextInput';
import JoinOperatorSelect from '../../shared/components/JoinOperatorSelect';
import { selectIndividualSearchCriteria, setIndividualSearchCriteria, IndividualSearchCriteria } from '../application/applicationSlice';
import ContentPadding from '../../shared/components/ContentPadding';
import { SearchIndividualMemberships } from '../../shared/models/matrix/SearchIndividualMemberships';
import { JoinOperator } from '../../shared/enums/other/JoinOperator';
import { isValidEmail } from '../../shared/utilities/formValidators';

interface UserDetailSearch {
  memberId?: number;
  firstName?: string;
  lastName?: string;
  firstNameOperator?: JoinOperator;
  lastNameOperator?: JoinOperator;
  email?: string;
}

export interface MembershipSearchTypeOptions {
  isMemberId: boolean;
  isOtherSearchCriteria: boolean;
  isEmail: boolean;
}

interface SearchTypeProps {
  onChange: (value: MembershipSearchTypeOptions) => void;
  sx?: React.CSSProperties;
  initialSelection?: 'isMemberId' | 'isOther' | 'isEmail';
  showHelp?: boolean;
}

const SearchType = (props: SearchTypeProps) => {
  const {
    onChange,
    sx,
    initialSelection = 'isMemberId',
    // showHelp = false,
  } = props;

  const [value, setValue] = useState<string>(initialSelection);

  useEffect(() => {
    onChange({ isMemberId: (value.includes('isMemberId')), isOtherSearchCriteria: (value.includes('isOther')), isEmail: (value.includes('isEmail')) });
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* { showHelp && <AddAUserHelp /> } */}
      <FormControl component="fieldset" sx={sx}>
        <FormLabel component="legend">Search By</FormLabel>
        <RadioGroup row aria-label="search type" name="search-type" value={value} onChange={handleChange}>
          <FormControlLabel value="isMemberId" control={<Radio color="primary" />} label="Member ID" />
          <FormControlLabel value="isEmail" control={<Radio color="primary" />} label="E-mail Address" />
          <FormControlLabel value="isOther" control={<Radio color="primary" />} label="Other Criteria" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

const defaultSearchTypeOptions: MembershipSearchTypeOptions = {
  isMemberId: true,
  isOtherSearchCriteria: false,
  isEmail: false,
};

interface DefineMembershipsSearchCriteriaProps {
  onSearchCriteriaDefined: (data: SearchIndividualMemberships) => Promise<void>;
  useStore?: boolean;
  postSubmitFunc?: () => void;
}

const DefineMembershipsSearchCriteria = (props: DefineMembershipsSearchCriteriaProps) => {
  const {
    onSearchCriteriaDefined, useStore = true, postSubmitFunc,
  } = props;

  const formContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };
  const formValueDefaults = useSelector(selectIndividualSearchCriteria);

  const [searchTypeOption, setSearchTypeOption] = useState<MembershipSearchTypeOptions>(
    formValueDefaults?.searchType || defaultSearchTypeOptions,
  );

  const dispatch = useAppDispatch();

  const handleUserOptionSelection = (
    searchType: MembershipSearchTypeOptions,
  ) => {
    setSearchTypeOption(searchType);
  };

  const form = useForm<UserDetailSearch>({
    mode: 'all',
    defaultValues: useStore ? {
      memberId: formValueDefaults?.criteria?.id?.value,
      firstName: formValueDefaults?.criteria?.firstName?.value,
      firstNameOperator: formValueDefaults?.criteria?.firstName?.operator ?? JoinOperator.Contains,
      lastName: formValueDefaults?.criteria?.lastName?.value,
      lastNameOperator: formValueDefaults?.criteria?.lastName?.operator ?? JoinOperator.Contains,
      email: formValueDefaults?.criteria?.emailAddress?.value,
    } : {},
  });

  const {
    formState: { isValid, isDirty }, trigger, handleSubmit, watch,
  } = form;

  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchEmail = watch('email');
  const watchFirstNameOperator = watch('firstNameOperator');

  const resolveFilterStringValue = (
    value: string | undefined,
    valuOperator: JoinOperator | undefined,
    defaultOperator: number = JoinOperator.Contains,
  ) => (value && value.length > 0 ? { value, operator: valuOperator !== undefined ? valuOperator : defaultOperator } : undefined);

  const handleDefineMembershipsSearchCriteria = async (formData: UserDetailSearch) => {
    let criteriaDefinedData: SearchIndividualMemberships;
    const firstName = formData.firstName?.trim();
    const lastName = formData.lastName?.trim();
    const email = formData.email?.trim();
    // MemberUserReturnType
    if (searchTypeOption.isMemberId) {
      criteriaDefinedData = {
        id: formData.memberId ? { value: formData.memberId } : undefined,
      };
    } else if (searchTypeOption.isEmail) {
      criteriaDefinedData = {
        emailAddress: resolveFilterStringValue(email, JoinOperator.Equals),
      };
    } else {
      criteriaDefinedData = {
        firstName: resolveFilterStringValue(firstName, formData.firstNameOperator),
        lastName: resolveFilterStringValue(lastName, formData.lastNameOperator),
      };
    }

    criteriaDefinedData.sorts = [{ columnName: 'Member.LastName', isDescending: false }];

    if (useStore) {
      dispatch(setIndividualSearchCriteria({ criteria: criteriaDefinedData, searchType: searchTypeOption }));
    }

    await onSearchCriteriaDefined(criteriaDefinedData);

    if (postSubmitFunc) {
      postSubmitFunc();
    }
  };

  useEffect(() => {
    if (isDirty) {
      trigger();
    }
  }, [searchTypeOption, watchLastName, watchFirstName, watchEmail, watchFirstNameOperator, isDirty]);

  const requireNumberOfChars = (value: string, numberRequired: number = 4) => (value && value.trim().length < numberRequired ? `Must be at least ${numberRequired} characters` : true);

  const getInitialSelectionValue = (value: IndividualSearchCriteria | undefined) => {
    if (value?.searchType?.isMemberId) {
      return 'isMemberId';
    }
    if (value?.searchType?.isEmail) {
      return 'isEmail';
    }
    return 'isOther';
  };

  return (
    <ContentPadding>
      <FormProvider {...form}>
        <form
          style={formContainerStyles}
          onSubmit={handleSubmit(handleDefineMembershipsSearchCriteria)}
        >
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12}>
              <SearchType
                onChange={handleUserOptionSelection}
                sx={{
                  marginBottom: '18px',
                }}
                showHelp={false}
                initialSelection={getInitialSelectionValue(formValueDefaults)}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomNumberInput
                name="memberId"
                fullWidth
                label="Member ID"
                style={{ flex: '1' }}
                decimalScale={0}
                allowNegative={false}
                validateOnChange
                disabled={!searchTypeOption.isMemberId}
                rules={{
                  required: searchTypeOption.isMemberId ? 'Member ID is required' : false,
                  validate: {
                    lengthLimit: (v: number) => (v && v.toString().length > 9 ? 'Member ID cannot be greater than 9 digits' : true),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextInput
                name="email"
                fullWidth
                label="E-mail Address"
                disabled={!searchTypeOption.isEmail}
                rules={{
                  validate: {
                    format: isValidEmail(),
                    required: (v: string) => (searchTypeOption.isEmail && v.trim().length === 0 ? 'E-mail Address is required' : true),
                  },
                }}
              />
            </Grid>

            <Grid item xs={4} md={2}>
              <JoinOperatorSelect name="firstNameOperator" disabled={!searchTypeOption.isOtherSearchCriteria || !watchFirstName} label="First Name" />
            </Grid>
            <Grid item xs={8} md={10}>
              <CustomTextInput
                name="firstName"
                disabled={!searchTypeOption.isOtherSearchCriteria}
                rules={{
                  validate: {
                    required: (v: string) => (searchTypeOption.isOtherSearchCriteria && !watchLastName && v.trim().length === 0 ? 'First Name or Last Name is required' : true),
                    requireNumChars: (v: string) => requireNumberOfChars(v),
                  },
                }}
                fullWidth
                maxLength={100}
              />
            </Grid>

            <Grid item xs={4} md={2}>
              <JoinOperatorSelect name="lastNameOperator" disabled={!searchTypeOption.isOtherSearchCriteria || !watchLastName} label="Last Name" />
            </Grid>
            <Grid item xs={8} md={10}>
              <CustomTextInput
                name="lastName"
                disabled={!searchTypeOption.isOtherSearchCriteria}
                fullWidth
                rules={{
                  validate: {
                    required: (v: string) => (searchTypeOption.isOtherSearchCriteria && !watchFirstName && v.trim().length === 0 ? 'Last Name or First Name is required' : true),
                    requireNumChars: (v: string) => requireNumberOfChars(v),
                  },
                }}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={12}>
              <BusySubmitButton
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                disabled={!isValid}
                actionPending={false}
                style={{ marginTop: '14px' }}
              >
                Submit
              </BusySubmitButton>
            </Grid>
          </Grid>

        </form>
      </FormProvider>
    </ContentPadding>
  );
};

export default DefineMembershipsSearchCriteria;
