import React from 'react';

interface UsingDefaultProps {
  style?: React.CSSProperties;
}
const UsingDefault = (props: UsingDefaultProps) => {
  const { style } = props;

  const commonStyles: React.CSSProperties = {
    marginBottom: '6px',
    marginTop: '6px',
    padding: '1px 8px',
    minWidth: '120px',
    fontSize: '10px',
    textAlign: 'center',
  };

  const usingDefaultStyles: React.CSSProperties = {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingRight: '0px',
    paddingLeft: '0px',
    fontStyle: 'italic',
    color: '#8ed1a0',
    fontSize: '13px',
  };

  return (
    <span
      style={{
        ...commonStyles,
        ...usingDefaultStyles,
        ...style,
      }}
    >
      &lt;Using Default&gt;
    </span>
  );
};

export default UsingDefault;
