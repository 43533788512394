import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import MuiTableCell from '@mui/material/TableCell';
import { groupBy, remove, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';

import BusySubmitButton from './BusySubmitButton';
import { RootState } from '../../app/store';

import UserPermissionsHelp from './UserPermissionsHelp';
import InfoIconText from './icon-components/InfoIconText';
import { PortalApiPermissionFull } from '../models/permissions/PortalApiPermissionFull';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

export interface SelectedPermission extends PortalApiPermissionFull {
  isSelected: boolean;
  isDependee: boolean;
  isPrerequisiteBlock: boolean;
}

export interface UserPermissionsProps {
  userPermissions: PortalApiPermissionFull[];
  onPermissionsChanged: (selectedPermissions: SelectedPermission[]) => void;
  onNeedSpecialPermissionsChanged?: (needSpecialPermissions: boolean) => void;
  permissionFilter?: (p: PortalApiPermissionFull) => boolean;
  resetToUserPermissions?: boolean;
  isBusy?: boolean;
  onSubmitClicked?: () => void;
  stickyTopOffset?: number;
  useSubmitButton?: boolean;
  userCanEditPermissions?: boolean;
  allPermissionsSelector?: (state: RootState) => PortalApiPermissionFull[];
  lockPermissions?: boolean;
  lockPermissionsReason?: string;
  userRequiresSpecialPermissions?: boolean;
  hideSpecialPermissionsCheckbox?: boolean;
  useInternalRequireAtLeastOneSelectionMessage?: boolean;
  callOnPermissionsChangedInitially?: boolean;
}

const UserPermissions = (props: UserPermissionsProps) => {
  const theme = useTheme();
  const {
    permissionFilter = (p) => p.categoryName === 'Employee',
    userPermissions,
    onPermissionsChanged,
    resetToUserPermissions = false,
    isBusy = false,
    onSubmitClicked,
    stickyTopOffset = 90,
    useSubmitButton = true,
    userCanEditPermissions = false,
    allPermissionsSelector,
    lockPermissions = false,
    lockPermissionsReason,
    userRequiresSpecialPermissions = false,
    onNeedSpecialPermissionsChanged,
    hideSpecialPermissionsCheckbox = false,
    useInternalRequireAtLeastOneSelectionMessage = false,
    callOnPermissionsChangedInitially = false,
  } = props;
  const [permissions, setPermissions] = useState<SelectedPermission[]>([]);
  const [needSpecialPermissions, setNeedSpecialPermissions] = useState<boolean>(userRequiresSpecialPermissions);
  const allPermissions = allPermissionsSelector ? useSelector(allPermissionsSelector) : [];

  const disableSubmitButton = needSpecialPermissions ? !permissions.some((p) => p.isSelected) : false;

  useEffect(() => {
    setNeedSpecialPermissions(userRequiresSpecialPermissions);
  }, [userRequiresSpecialPermissions]);
  useEffect(() => {
    if (onNeedSpecialPermissionsChanged) {
      onNeedSpecialPermissionsChanged(needSpecialPermissions);
    }
  }, [needSpecialPermissions, onNeedSpecialPermissionsChanged]);

  const checkDependentPermissions = (permission: SelectedPermission, newPermissions: SelectedPermission[]) => {
    if (!permission.dependentPermissionId) {
      return;
    }
    const dependentPermission = newPermissions.find(
      (dp) => dp.id === permission.dependentPermissionId,
    );
    if (dependentPermission) {
      const dependentIndex = newPermissions.findIndex(
        (p) => p.id === dependentPermission.id,
      );
      if (permission.isSelected) {
        newPermissions[dependentIndex] = {
          ...dependentPermission,
          isSelected: true,
          isDependee: true,
        };
      } else {
        const needsSelected = newPermissions.some(
          (np) => np.dependentPermissionId
            && np.id !== permission.id
            && np.dependentPermissionId === dependentPermission.id
            && np.isSelected,
        );
        if (
          (!needsSelected && dependentPermission.isSelected)
          || (needsSelected && !dependentPermission.isSelected)
        ) {
          newPermissions[dependentIndex] = {
            ...dependentPermission,
            isSelected: needsSelected,
            isDependee: true,
          };
        }
      }
    }
  };

  const updatePermission = (permission: SelectedPermission, oldPermissions: SelectedPermission[], updateFunc: (p: SelectedPermission) => void, createNew: boolean = true) => {
    const newPermissions = createNew ? [...oldPermissions] : oldPermissions;
    const index = newPermissions.findIndex((p) => p.id === permission.id);
    const newPermission = { ...permission };
    updateFunc(newPermission);
    newPermissions[index] = newPermission;
    checkDependentPermissions(newPermission, newPermissions);
    return { newPermissions, newPermission };
  };

  const checkPermissionPrerequisites = (permission: SelectedPermission, newPermissions: SelectedPermission[]) => {
    const permissionId = permission.id;
    const prerequisites = newPermissions.filter((p) => p.id !== permissionId && p.permissionPrerequisiteIds && p.permissionPrerequisiteIds.some((p1) => p1 === permissionId));
    const { isSelected } = permission;
    prerequisites.forEach((p) => {
      if (isSelected) {
        updatePermission(p, newPermissions, (np) => { np.isPrerequisiteBlock = false; }, false);
      } else if (p.permissionPrerequisiteIds) {
        const otherPrerequisiteIds = p.permissionPrerequisiteIds.filter((id) => id !== permissionId);
        if (otherPrerequisiteIds.length === 0) {
          updatePermission(p, newPermissions, (np) => { np.isPrerequisiteBlock = true; np.isSelected = false; }, false);
        } else {
          const isPrerequisiteBlock = !(newPermissions.find((p1) => otherPrerequisiteIds.includes(p1.id) && p1.isSelected) !== undefined);
          if (p.isPrerequisiteBlock !== isPrerequisiteBlock) {
            updatePermission(p, newPermissions, (np) => {
              np.isPrerequisiteBlock = isPrerequisiteBlock;
              if (np.isPrerequisiteBlock) { np.isSelected = false; }
            }, false);
          }
        }
      } else {
        updatePermission(p, newPermissions, (np) => { np.isPrerequisiteBlock = false; }, false);
      }
    });
    return newPermissions;
  };

  const resetPermissions = () => {
    const perms: SelectedPermission[] = allPermissions
      .filter(permissionFilter)
      .map((p) => ({
        ...p,
        isSelected: userPermissions.map((u) => u.id).includes(p.id),
        isDependee: userPermissions.some((dp) => dp.dependentPermissionId === p.id),
        isPrerequisiteBlock: false,
      }));
    perms
      .forEach((p) => checkPermissionPrerequisites(p, perms));

    if (callOnPermissionsChangedInitially) {
      onPermissionsChanged(perms);
    }
    setPermissions(sortBy(perms, ['scope', 'entity', 'action']));
  };

  useEffect(() => {
    resetPermissions();
  }, [userPermissions]);

  useEffect(() => {
    if (resetToUserPermissions) {
      resetPermissions();
    }
  }, [resetToUserPermissions]);

  const getPrerequisiteInfo = (permissionIds: string[]): React.ReactChild => {
    const prereqPermissions = permissionIds.map((pid) => allPermissions.find((ap) => ap.id === pid));

    if (prereqPermissions?.length > 0) {
      return (
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
            Prerequisite Permission
          </div>
          {prereqPermissions.length > 1 && (
            <div style={{ marginBottom: '10px' }}>
              At least
              {' '}
              <b>one</b>
              {' '}
              of the permissions below must be selected before this permission will be enabled
            </div>
          )}
          {prereqPermissions.map((pre, i) => {
            if (pre) {
              return (
                <React.Fragment key={`pre-req-${pre.id}`}>
                  <div style={{ marginLeft: '8px' }}>
                    &middot;
                    {pre.description}
                  </div>
                </React.Fragment>
              );
            }

            return <React.Fragment key={`pre-req-${i}`}>- Prerequisite permission not found -</React.Fragment>;
          })}

        </div>
      );
    }
    return <></>;
  };

  const blendPermissions = (sourcePermissions: SelectedPermission[]) => {
    const blendedPermissions = [...sourcePermissions];
    blendedPermissions.filter((p) => p.action === '*' && p.isSelected).forEach((gp) => {
      remove(blendedPermissions, (p) => (gp.entity === '*' || p.entity === gp.entity) && p.scope === gp.scope);
      blendedPermissions.push(gp);
    });
    onPermissionsChanged(blendedPermissions);
  };

  const updatePermissionSelection = (
    permission: SelectedPermission,
    isSelected: boolean,
  ) => {
    const { newPermissions, newPermission } = updatePermission(permission, permissions, (p) => { p.isSelected = isSelected; });
    checkPermissionPrerequisites(newPermission, newPermissions);
    setPermissions(sortBy(newPermissions, ['scope', 'entity', 'action']));
    blendPermissions(newPermissions);
  };

  const findGroupPermission = (entityName: string, scopeName: string) => permissions.find((p) => p.action === '*' && p.entity === entityName && p.scope === scopeName);

  const isGroupPermissionSelected = (entityName: string, scopeName: string): boolean => findGroupPermission(entityName, scopeName)?.isSelected ?? false;

  const getScopeDescription = (scopeName: string) => {
    if (scopeName.toLocaleLowerCase() === 'portal') {
      return '"Portal" permissions apply to actions or access related to configuration of the portal ';
    }

    if (scopeName.toLocaleLowerCase() === 'fleet*') {
      return '"Fleet*" permissions apply to actions or access related to configuration of ALL fleets';
    }

    if (scopeName.toLocaleLowerCase() === 'fleet') {
      return '"Fleet" permissions apply to actions or access related to configuration of this fleet';
    }

    if (scopeName.toLocaleLowerCase() === 'member*') {
      return '"Member*" permissions apply to actions or access related to configuration of all non-fleet memberships';
    }
    return '';
  };

  interface ScopeGroupPermission {
    scopeName: string;
    displayName: string;
  }
  const renderScopeGroupPermission = (scope: ScopeGroupPermission, index: number) => {
    const permission = findGroupPermission('*', scope.scopeName);
    if (permission) {
      return (
        <>
          <Checkbox sx={{ paddingLeft: '0px' }} color="primary" checked={permission.isSelected} onChange={(e) => updatePermissionSelection(permission, e.target.checked)} disabled={!userCanEditPermissions || permission.isPrerequisiteBlock || lockPermissions} />
          <span style={{ display: 'inline-flex', color: permission.isPrerequisiteBlock || !userCanEditPermissions || lockPermissions ? theme.palette.action.disabled : undefined }}>
            {scope.displayName}
          </span>

          {permission.permissionPrerequisiteIds && permission.permissionPrerequisiteIds.length > 0 && (
            !lockPermissions && (
              <Tooltip
                title={getPrerequisiteInfo(permission.permissionPrerequisiteIds)}
              >
                <InfoIcon
                  color="action"
                  fontSize="small"
                  sx={{
                    marginLeft: '10px',
                    cursor: 'default',
                  }}
                  onClick={(e) => {
                    // if user clicks info icon... don't allow checkbox state to change
                    e.preventDefault();
                  }}
                />
              </Tooltip>
            )
          )}
        </>
      );
    }
    return (
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="h5"
          sx={{ marginTop: index > 0 ? '50px' : 'unset' }}
        >
          {scope.scopeName}
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ alignSelf: 'flex-end', paddingBottom: '3px', marginLeft: '12px' }}>
          {getScopeDescription(scope.scopeName)}
        </Typography>
      </Box>
    );
  };

  const renderEntityGroupPermission = (entityName: string, scopeName: string) => {
    const permission = findGroupPermission(entityName, scopeName);
    if (permission) {
      return (
        <>
          {isGroupPermissionSelected('*', scopeName)
            ? (
              <CheckIcon
                color="action"
                fontSize="small"
                sx={{
                  marginRight: '10px',
                  cursor: 'default',
                  color: lockPermissions ? theme.palette.action.disabled : undefined,
                }}
              />
            )
            : <Checkbox color="primary" checked={permission.isSelected} onChange={(e) => updatePermissionSelection(permission, e.target.checked)} disabled={!userCanEditPermissions || permission.isPrerequisiteBlock || lockPermissions} />}
          <span style={{ display: 'inline-flex', color: permission.isPrerequisiteBlock || !userCanEditPermissions || lockPermissions ? theme.palette.action.disabled : undefined }}>{permission.description}</span>

          {permission.permissionPrerequisiteIds && permission.permissionPrerequisiteIds.length > 0 && (
            !lockPermissions && (
              <Tooltip
                title={getPrerequisiteInfo(permission.permissionPrerequisiteIds)}
              >
                <InfoIcon
                  color="action"
                  fontSize="small"
                  sx={{
                    marginLeft: '10px',
                    cursor: 'default',
                  }}
                  onClick={(e) => {
                    // if user clicks info icon... don't allow checkbox state to change
                    e.preventDefault();
                  }}
                />
              </Tooltip>
            )
          )}
        </>
      );
    }
    return (
      <>
        {entityName}
      </>
    );
  };

  const renderChildPermission = (entityName: string, scopeName: string, p: SelectedPermission, i: number) => {
    if (isGroupPermissionSelected(entityName, scopeName) || isGroupPermissionSelected('*', scopeName)) {
      return (
        <CheckIcon
          color="action"
          fontSize="small"
          sx={{
            marginRight: '10px',
            cursor: 'default',
            color: lockPermissions ? theme.palette.action.disabled : undefined,
          }}
        />
      );
    }
    return (
      <Checkbox
        sx={{ marginLeft: '26px' }}
        checked={p.isSelected}
        onChange={(e) => updatePermissionSelection(
          p,
          e.target.checked,
        )}
        disabled={
          !userCanEditPermissions || lockPermissions
          || (p.isDependee
            && p.isSelected)
          || p.isPrerequisiteBlock
        }
        name={`permission-${i}`}
        color="primary"
      />
    );
  };

  const getDependeeInfo = (permissionId: string): React.ReactChild => {
    const dependentPermission = allPermissions.find(
      (p) => p.id === permissionId,
    );

    if (dependentPermission) {
      return (
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
            Dependent Permission
          </div>

          <div style={{ marginBottom: '10px' }}>
            The permission listed below is required and will be automatically selected.
          </div>

          <div style={{ marginLeft: '8px' }}>
            &middot;
            {dependentPermission.description}
          </div>
        </div>
      );
    }

    return `Unable to find dependent permission with id ${permissionId}`;
  };

  const handleNeedSpecialPermissionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNeedSpecialPermissions(event.target.checked);
  };

  return (
    <Grid container xs={12} spacing={2} sx={{ margin: '0px' }}>
      <Grid item xs={9}>
        {lockPermissionsReason && (
          <InfoIconText text={lockPermissionsReason} />
        )}

        {!hideSpecialPermissionsCheckbox && (
          <div style={{ position: 'relative' }}>
            <UserPermissionsHelp />
            <FormControlLabel
              control={(
                <Checkbox
                  disabled={lockPermissions || !userCanEditPermissions}
                  color="secondary"
                  name="need-special-permission"
                  checked={needSpecialPermissions}
                  onChange={handleNeedSpecialPermissionsChange}
                />
              )}
              label="This user needs special permissions"
            />
          </div>

        )}

        {needSpecialPermissions && (
          <Table sx={{ marginTop: '30px' }}>
            <TableBody>
              {Object.entries(groupBy(permissions, 'scope')).map(
                ([scopeKey, scopeValues], index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: '0px' }}>
                      <Typography
                        variant="h5"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {renderScopeGroupPermission({ scopeName: scopeValues[index].scope || '', displayName: scopeValues[index].name }, index)}
                      </Typography>

                      <Table>
                        <TableBody>
                          {Object.entries(groupBy(scopeValues.filter((p) => p.entity !== '*'), 'entity')).map(
                            ([groupKey, groupValues]) => (
                              <TableRow key={groupKey}>
                                <TableCell>
                                  <Typography
                                    variant="h6"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    {renderEntityGroupPermission(groupKey, scopeKey)}
                                  </Typography>
                                  <Table>
                                    <TableBody>
                                      {groupValues.filter((g) => g.action !== '*').map(
                                        (p: SelectedPermission, i: number) => (
                                          <TableRow key={i}>
                                            <TableCell>
                                              <FormControlLabel
                                                sx={{ marginLeft: isGroupPermissionSelected('*', scopeKey) ? '52px' : (isGroupPermissionSelected(groupKey, scopeKey) ? '26px' : {}), marginTop: isGroupPermissionSelected(groupKey, scopeKey) ? '12px' : {} }}
                                                control={renderChildPermission(groupKey, scopeKey, p, i)}
                                                label={(
                                                  <span
                                                    style={{
                                                      display: 'inline-flex',
                                                      alignItems: 'center',
                                                      cursor: isGroupPermissionSelected(groupKey, scopeKey) ? 'default' : 'pointer',
                                                      color: lockPermissions ? theme.palette.action.disabled : undefined,
                                                    }}
                                                  >
                                                    {p.description || p.name}
                                                    {p.dependentPermissionId && (
                                                      !lockPermissions && (
                                                        <Tooltip
                                                          title={getDependeeInfo(
                                                            p.dependentPermissionId,
                                                          )}
                                                          onClick={(e) => {
                                                            // if user clicks info icon... don't allow checkbox state to change
                                                            e.preventDefault();
                                                          }}
                                                        >
                                                          <InfoIcon
                                                            color="action"
                                                            fontSize="small"
                                                            sx={{
                                                              marginLeft: '10px',
                                                              cursor: 'pointer',
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      )
                                                    )}

                                                    {p.permissionPrerequisiteIds && p.permissionPrerequisiteIds?.length > 0
                                                      && (
                                                        <Tooltip
                                                          title={getPrerequisiteInfo(p.permissionPrerequisiteIds)}
                                                        >
                                                          <InfoIcon
                                                            color="action"
                                                            fontSize="small"
                                                            sx={{
                                                              marginLeft: '10px',
                                                              cursor: 'default',
                                                            }}
                                                            onClick={(e) => {
                                                              // if user clicks info icon... don't allow checkbox state to change
                                                              e.preventDefault();
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      )}
                                                  </span>
                                                )}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        )}
      </Grid>
      <Grid item xs={3}>
        {userCanEditPermissions && useSubmitButton && (
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ position: 'sticky', top: stickyTopOffset }}
          >
            <Box display="flex" sx={{ flexDirection: 'column' }}>
              <BusySubmitButton
                fullWidth
                variant="contained"
                color="primary"
                disabled={disableSubmitButton}
                actionPending={isBusy}
                onClick={() => {
                  if (onSubmitClicked) {
                    onSubmitClicked();
                  }
                }}
              >
                Submit
              </BusySubmitButton>

              {useInternalRequireAtLeastOneSelectionMessage && needSpecialPermissions && !permissions.some((p) => p.isSelected) && (
                <Box sx={{ marginTop: '8px' }}>
                  <Alert severity="error" sx={{ marginBottom: '10px' }}>
                    Select at least one permission
                  </Alert>
                </Box>
              )}

            </Box>

          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default UserPermissions;
