import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { PortalApiFleetCard } from '../../shared/models/fuel/PortalApiFleetCard';
import { PortalApiFuelCard } from '../../shared/models/fuel/PortalApiFuelCard';
import {
  createBlockingThunk,
  createNonBlockingThunk,
} from '../../services/thunk';
import { useFleetApi } from '../../services/api/hooks/useFleetApi';

export interface FuelCardState {
  fuelCards: PortalApiFleetCard[];
  fuelCardsLoading: boolean;
  userFuelCards: PortalApiFuelCard[];
  userFuelCardsLoading: boolean;
  // smartCards: PortalSmartCard[];
  // smartCardsLoading: boolean;
}

const initialState: FuelCardState = {
  fuelCards: [],
  fuelCardsLoading: false,
  userFuelCards: [],
  userFuelCardsLoading: false,
  // smartCards: [],
  // smartCardsLoading: false,
};

export const fuelCardSlice = createSlice({
  name: 'fuelCards',
  initialState,
  reducers: {
    fuelCardsRetrieved: (
      state,
      action: PayloadAction<PortalApiFleetCard[]>,
    ) => {
      state.fuelCards = action.payload;
    },
    setFuelCardsLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.fuelCardsLoading = action.payload;
    },
    userFuelCardsRetrieved: (
      state,
      action: PayloadAction<PortalApiFuelCard[]>,
    ) => {
      state.userFuelCards = action.payload;
    },
    setUserFuelCardsLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.userFuelCardsLoading = action.payload;
    },
    // smartCardsRetrieved: (
    //   state,
    //   action: PayloadAction<PortalSmartCard[]>,
    // ) => {
    //   state.smartCards = action.payload;
    // },
    // setSmartCardsLoadingStatus: (state, action: PayloadAction<boolean>) => {
    //   state.smartCardsLoading = action.payload;
    // },
  },
});

const {
  fuelCardsRetrieved, setFuelCardsLoadingStatus, userFuelCardsRetrieved, setUserFuelCardsLoadingStatus,
} = fuelCardSlice.actions;

const retrieveFuelCardsForFleetThunk = (
  fleetId: string,
): AppThunk<Promise<PortalApiFleetCard[]>> => async (dispatch) => {
  const { getFleetFuelCards } = useFleetApi();

  dispatch(setFuelCardsLoadingStatus(true));
  const fuelCards = await getFleetFuelCards(fleetId);
  dispatch(fuelCardsRetrieved(fuelCards));
  dispatch(setFuelCardsLoadingStatus(false));

  return fuelCards;
};

const retrieveFuelCardsForFleetUserThunk = (
  fleetId: string,
  userId: string,
): AppThunk<Promise<PortalApiFleetCard[]>> => async (dispatch) => {
  const { getFleetUserFuelCards } = useFleetApi();

  dispatch(setUserFuelCardsLoadingStatus(true));

  try {
    const fuelCards = await getFleetUserFuelCards(fleetId, userId);
    dispatch(userFuelCardsRetrieved(fuelCards));
    dispatch(setUserFuelCardsLoadingStatus(false));
    return fuelCards;
  } catch (err) {
    dispatch(setUserFuelCardsLoadingStatus(false));
    throw err;
  }
};

export const retrieveFuelCards = (fleetId: string, block = true) => (block
  ? createBlockingThunk(retrieveFuelCardsForFleetThunk(fleetId))
  : createNonBlockingThunk(retrieveFuelCardsForFleetThunk(fleetId)));

export const retrieveFuelCardsForFleetUser = (fleetId: string, userId: string, block = true) => (block
  ? createBlockingThunk(retrieveFuelCardsForFleetUserThunk(fleetId, userId))
  : createNonBlockingThunk(retrieveFuelCardsForFleetUserThunk(fleetId, userId)));

export const selectFuelCards = (state: RootState) => state.fuelCards.fuelCards || [];
export const selectFuelCardById = (fuelCardId: number) => (state: RootState) => state.fuelCards.fuelCards?.find((card) => card.cardId === fuelCardId);
export const selectFuelCardsLoading = (state: RootState) => state.fuelCards.fuelCardsLoading;

export const selectUserFuelCards = (state: RootState) => state.fuelCards.userFuelCards || [];
export const selectUserFuelCardById = (fuelCardId: number) => (state: RootState) => state.fuelCards.userFuelCards?.find((card) => card.cardId === fuelCardId);
export const selectUserFuelCardsLoading = (state: RootState) => state.fuelCards.userFuelCardsLoading;

export default fuelCardSlice.reducer;
