import {
  Drawer, IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Menu } from '@mui/icons-material';
import * as React from 'react';
import { useState } from 'react';
import { useBreakPoints } from '../../hooks/useBreakPoints';
import Nav from './Nav';
import ProfileAvatar from './ProfileAvatar';

interface MobileDrawerProps {
  renderNav?: boolean;
}

const MobileDrawer = (props: MobileDrawerProps) => {
  const { renderNav = true } = props;
  const theme = useTheme();
  const styles = {
    paper: {
      backgroundColor: theme.palette.primary.main,
      background:
        'linear-gradient(270deg, rgba(23,51,94,1) 0%, rgba(0,92,157,1) 100%)',
      minWidth: '300px',
    },
    profileAvatar: {
      padding: '10px;',
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const { isLgUp } = useBreakPoints();
  const toggleDrawer = (open: boolean) => () => {
    setIsOpen(open);
  };

  return (
    <>
      <IconButton
        edge="start"
        size="medium"
        aria-label="Mobile menu"
        color="default"
        onClick={toggleDrawer(true)}
      >
        <Menu fontSize="large" />
      </IconButton>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': styles.paper,
        }}
        keepMounted
      >
        <div
          style={
            isLgUp ? styles.profileAvatar : { ...styles.profileAvatar, backgroundColor: theme.palette.common.white }
          }
        >
          <ProfileAvatar />
        </div>
        {renderNav && (
          <Nav
            onNavListClicked={() => {
              setIsOpen(false);
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default MobileDrawer;
