import { useEffect, useState } from 'react';

export const useFuelFundsTabs = () => {
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState<number>();
  const [formIsProcessing, setFormIsProcessing] = useState(false);
  const [error, setError] = useState<string>('');
  const handleFeedbackSubmissionNavigation = (tab: number = 0) => {
    setFormIsSubmitted(false);
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(undefined);
  }, []);

  return {
    formIsSubmitted,
    setFormIsSubmitted,
    activeTab,
    setActiveTab,
    handleFeedbackSubmissionNavigation,
    formIsProcessing,
    setFormIsProcessing,
    error,
    setError,
  };
};
