import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
  isAuthorized: boolean | undefined;
  redirectIfNotAuthorized?: boolean;
  redirectPath?: string;
}

const ProtectedRoute = ({
  isAuthorized = false,
  children,
  component,
  redirectIfNotAuthorized = true,
  redirectPath = '/',
  ...additionalProps
}: ProtectedRouteProps) => {
  const history = useHistory();
  if (!isAuthorized) {
    if (redirectIfNotAuthorized) {
      history.push(redirectPath);
    }

    return null;
  }

  return (
    <Route {...additionalProps} component={component}>
      {children}
    </Route>
  );
};

export default ProtectedRoute;
