import {
  ListItem, Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { LinkProps, NavLink, useLocation } from 'react-router-dom';
import { ListItemContent } from './ListItemContent';

interface NavItemProps extends LinkProps {
  linkText?: React.ReactNode;
  isSub?: boolean;
  baseCompare?: string;
  isExternal?: boolean;
}
export const NavItem = (props: NavItemProps) => {
  const {
    to, linkText, isSub = false, baseCompare, isExternal = false,
  } = props;
  const location = useLocation();
  const theme = useTheme<Theme>();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (baseCompare) {
      setIsSelected(location.pathname.startsWith(baseCompare));
    } else {
      setIsSelected(location.pathname.startsWith(to.toString()));
    }
  }, [location, to]);

  const styles = {
    root: {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
      },
      color: '#e0e0e0',
      cursor: 'pointer',
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    selectedHover: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  };

  return (
    <ListItem
      button
      component={NavLink}
      to={to}
      target={isExternal ? '_blank' : undefined}
      selected={isSelected}
      sx={{
        ...styles.root,
        '&.Mui-selected': styles.selected,
        '&.Mui-selected:hover': styles.selectedHover,
      }}
    >
      <ListItemContent linkText={linkText} isSubLink={isSub} isExternal={isExternal} />
    </ListItem>
  );
};
