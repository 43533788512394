import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { CheckCircle } from '@mui/icons-material';
import NoResults from '../NoResults';
import StandardContent from '../StandardContent';
import TvcSkeleton from '../TvcSkeleton';
import { getCreditDebitFormatting } from '../../../features/fuel/FuelActivityDetails';
import ScrollableContent from '../ScrollableContent';
import { useBreakPoints } from '../../hooks/useBreakPoints';
import PendingLoadLastLoadErrorIcon from '../pending-loads/PendingLoadLastLoadErrorIcon';
import { PendingLoad } from '../../models/fuel/PendingLoad';
import { HistoryMatrixId } from '../../models/fuel/HistoryMatrixId';
import { SubRow } from '../SubRow';

interface PendingLoadsProps {
  pendingLoads?: PendingLoad[];
  isLoading: boolean;
  usePaperTableContainer?: boolean;
  onPendingLoadClick?: (id: HistoryMatrixId) => Promise<void>;
  maxScrollContainerHeight?: number | 'unset';
  showBottomOverlay?: boolean;
  hideHeader?: boolean;
  testId?: string;
  showCouponUsed?: boolean;
}

const PendingLoads = (props: PendingLoadsProps) => {
  const {
    pendingLoads,
    isLoading,
    usePaperTableContainer = false,
    onPendingLoadClick,
    maxScrollContainerHeight = 'unset',
    showBottomOverlay = false,
    hideHeader = false,
    testId = 'pending-loads-table',
    showCouponUsed = true,
  } = props;
  const theme = useTheme();
  const styles = {
    header: {
      color: 'rgba(0, 0, 0, 0.70)',
    },
    row: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  };

  const { isSmDown } = useBreakPoints();

  const getCouponUsedDisplay = (load: PendingLoad) => (load.usedCoupon ? <CheckCircle sx={{ color: theme.palette.success.main }} /> : <></>);

  const getTypeDisplay = (load: PendingLoad) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ display: 'inline-block' }}>{load.type}</span>
      {load.hasErrorInLastStep
        && <PendingLoadLastLoadErrorIcon />}
    </div>
  );

  const content = () => (
    <>
      {isSmDown
        ? (
          <List>
            {pendingLoads?.map((load, index) => (
              <React.Fragment key={`pending-load-${index}`}>
                <ListItem button key={`pendingloadlist-${index}`}>
                  <ListItemText
                    onClick={() => {
                      if (onPendingLoadClick) {
                        onPendingLoadClick(load.id);
                      }
                    }}
                    secondary={(
                      <Box sx={{ fontSize: '1.4em', marginTop: '8px' }}>
                        <SubRow
                          title="Type"
                          value={getTypeDisplay(load)}
                          titleMinWidth={130}
                        />
                        <SubRow title="Est. Avail" value={load.estimatedAvailabilityDate} titleMinWidth={130} />
                        {showCouponUsed && <SubRow title="Promo Used" value={getCouponUsedDisplay(load)} titleMinWidth={130} />}
                        <SubRow title="Amount" value={getCreditDebitFormatting(load.amount > 0, load.amount)} titleMinWidth={130} />
                      </Box>
                    )}
                  />
                </ListItem>
                {pendingLoads.length !== index + 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        )
        : (
          <ScrollableContent
            maxHeight={maxScrollContainerHeight}
            showBottomOverlay={showBottomOverlay}
          >
            <Table data-testid={testId} sx={{ tableLayout: 'fixed' }}>
              <TableHead sx={{
                position: 'sticky', top: '0px', zIndex: 1000, backgroundColor: theme.palette.common.white,
              }}
              >
                <TableRow>
                  <TableCell sx={styles.header}>Type</TableCell>
                  <TableCell sx={styles.header}>Submitted Date</TableCell>
                  <TableCell sx={styles.header}>
                    {isSmDown ? 'Est. Avail' : 'Estimated Availability Date'}
                  </TableCell>
                  {showCouponUsed && (
                    <TableCell sx={styles.header} align="right">
                      Promo Used
                    </TableCell>
                  )}
                  <TableCell sx={styles.header} align="right">
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingLoads?.map((load, index) => (
                  <TableRow
                    sx={{
                      ...styles.row,
                      cursor: onPendingLoadClick ? 'pointer' : 'unset',
                    }}
                    key={index}
                    onClick={() => {
                      if (onPendingLoadClick) {
                        onPendingLoadClick(load.id);
                      }
                    }}
                  >
                    <TableCell>
                      {getTypeDisplay(load)}
                    </TableCell>
                    <TableCell>
                      {load.createdDate}
                    </TableCell>
                    <TableCell>
                      {load.estimatedAvailabilityDate}
                    </TableCell>
                    {showCouponUsed && (
                      <TableCell align="right">
                        {getCouponUsedDisplay(load)}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      {getCreditDebitFormatting(load.amount > 0, load.amount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollableContent>
        )}
    </>

  );

  const contentWithLoading = isLoading ? (
    <TvcSkeleton entityType="pending loads" />
  ) : pendingLoads && pendingLoads?.length > 0 ? (
    usePaperTableContainer ? (
      <TableContainer component={Paper}>{content()}</TableContainer>
    ) : (
      <TableContainer>{content()}</TableContainer>
    )
  ) : (
    <NoResults>No Pending Loads</NoResults>
  );

  if (hideHeader) {
    return contentWithLoading;
  }

  return (
    <StandardContent icon={<QueryBuilderIcon />} headerText="Pending Loads">
      {contentWithLoading}
    </StandardContent>
  );
};

export default PendingLoads;
