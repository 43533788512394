import { Avatar, AvatarProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/store';
import { Profile } from '../../shared/models/user/Profile';
import { retrieveEmployeeAvatar } from './userSlice';

interface EmployeeAvatarProps extends AvatarProps {
  employee?: Profile;
  block?: boolean;
  isLarge?: boolean;
  style?: React.CSSProperties;
}

const EmployeeAvatar = (props: EmployeeAvatarProps) => {
  const dispatch = useAppDispatch();
  const {
    employee, isLarge, style, block = true, ...rest
  } = props;
  const theme = useTheme();
  const styles = {
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  };

  const [photoSrc, setPhotoSrc] = useState<string>();

  useEffect(() => {
    const fetchData = async (id: string, email: string) => {
      const avatar: string = await dispatch(
        retrieveEmployeeAvatar({ id, email }, block),
      );
      setPhotoSrc(avatar);
    };

    if (employee?.azureUser?.email) {
      fetchData(employee.id, employee.azureUser?.email);
    }
  }, [employee?.azureUser?.email]);
  const largeStyles = isLarge ? styles.large : {};
  return (
    <Avatar
      {...rest}
      alt={employee?.fullName}
      src={photoSrc}
      sx={{
        ...largeStyles,
        ...style,
      }}
    >
      {employee?.initials}
    </Avatar>
  );
};

export default EmployeeAvatar;
