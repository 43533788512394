import {
  Box,
  Divider,
  Fade,
  FormControl,
  Grid,
  List,
  ListSubheader,
  MenuItem,
  Select,
  Pagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { usePagination } from '../hooks/usePagination';
import { PagingProps } from '../models/other/PagingProps';
import { fadeTimeout } from '../transitions/timeouts';
import ContentPadding from './ContentPadding';
import NoResults from './NoResults';
import TvcSkeleton, { SkeletonEntityTypes } from './TvcSkeleton';

interface ModalCollectionPageProps extends PagingProps {
  searchBox?: React.ReactNode;
  pinnedSubheader?: React.ReactNode;
  listStyle?: React.CSSProperties;
  useContentPadding?: boolean;
  searchContainerStyle?: React.CSSProperties;
  skeletonEntityType?: SkeletonEntityTypes;
  usePaging?: boolean;
}

const ModalCollectionSearch = (props: ModalCollectionPageProps) => {
  const theme = useTheme();
  const styles = {
    container: {
      alignContent: 'start',
    },
    collectionControls: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginTop: '16px',
      marginLeft: '8px',
      alignSelf: 'baseline',
      [theme.breakpoints.down('md')]: {
        marginTop: '25px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
      },
    },
    pagingControls: {
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flex: '1',
        flexBasis: '100%',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
      },
    },
    pageSizes: {
      marginRight: '20px',
      [theme.breakpoints.down('sm')]: {
        margin: '15px 0px',
      },
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    searchBox: {
      display: 'contents',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flex: '1',
      },
    },
    paginationControl: {
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flex: '1',
        flexBasis: '100%',
        justifyContent: 'center',
      },
    },
  };
  const { searchContainerStyle, skeletonEntityType = 'modal collection', usePaging = true } = props;
  const {
    entityName,
    activePageColor,
    resetPagingDependencies,
    jsxItems,
    isLoading,
    pageSizes,
    isEmptyResults,
    changePage,
    handlePageSizeChange,
    getPagedEntities,
    setPage,
    pageSize,
    page,
    pagingNeeded,
  } = usePagination(props);

  const {
    searchBox, pinnedSubheader, listStyle, useContentPadding = true,
  } = props;

  useEffect(() => {
    setPage(1);
  }, resetPagingDependencies);

  const collection = usePaging ? getPagedEntities(jsxItems) : jsxItems;

  const searchAndPaging = () => (
    <Box sx={{
      ...styles.searchContainer,
      ...searchContainerStyle,
    }}
    >
      <Box sx={styles.searchBox}>{searchBox}</Box>
      <Box
        sx={styles.collectionControls}
        display="flex"
      >
        {usePaging && (
          <Box sx={styles.pagingControls}>
            <FormControl sx={styles.pageSizes}>
              <Select
                labelId="rows-per-page"
                id="rows-selection"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                {pageSizes.map((size) => (
                  <MenuItem value={size} key={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Pagination
              sx={{
                ...styles.paginationControl,
                display: pagingNeeded ? 'inline-block' : 'none',
              }}
              count={Math.ceil(jsxItems.length / pageSize)}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              showFirstButton
              showLastButton
              shape="rounded"
              color={activePageColor}
              onChange={changePage}
              disabled={jsxItems.length <= pageSize}
            />
          </Box>
        )}
      </Box>
    </Box>
  );

  const listItems = () => (
    <Box>
      {isLoading ? (
        <TvcSkeleton entityType={skeletonEntityType} />
      ) : (
        <>
          {!isEmptyResults && (
            <List
              sx={{
                backgroundColor: 'whitesmoke',
                margin: '1em 0 0',
                padding: '0px',
                borderRadius: '5px',
                ...listStyle,
              }}
            >
              <ListSubheader
                disableGutters
                sx={{ backgroundColor: theme.palette.common.white }}
              >
                {pinnedSubheader}
              </ListSubheader>
              <Fade in={!isLoading} timeout={1000}>
                <div>
                  {collection.map(
                    (jsxElement: JSX.Element, index: number) => (
                      <div key={index}>
                        {jsxItems.length > 0 && index !== 0 && (
                          <Divider light />
                        )}
                        {jsxElement}
                      </div>
                    ),
                  )}
                </div>
              </Fade>
            </List>
          )}

          {isEmptyResults && (
            <Fade in timeout={fadeTimeout}>
              <Box
                justifyContent="center"
                display="flex"
                sx={{ marginTop: '20px' }}
              >
                <NoResults>
                  No
                  {' '}
                  {entityName}
                  s
                  {' '}
                  found
                </NoResults>
              </Box>
            </Fade>
          )}
        </>
      )}
    </Box>
  );
  return (
    <Grid container sx={styles.container}>
      <Grid item xs={12}>
        {useContentPadding ? (
          <ContentPadding>
            {searchAndPaging()}
          </ContentPadding>
        ) : searchAndPaging()}

      </Grid>
      <Grid item xs={12}>
        {useContentPadding ? (
          <ContentPadding>
            {listItems()}
          </ContentPadding>
        ) : listItems()}

      </Grid>
    </Grid>
  );
};

export default ModalCollectionSearch;
