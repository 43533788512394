import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { PortalApiLoadLimit } from '../../models/fuel/PortalApiLoadLimit';
import ContentPadding from '../ContentPadding';
import DetailsWrapper from '../DetailsWrapper';
import LoadFuelFundsForm, { Coupon, LoadFuelFundsData } from '../forms/LoadFuelFunds';
import LoadFuelFundsTabs from '../load-funds/LoadFundsTabs';
import { useAsyncErrorHandler } from '../../hooks/useTvcAppInsights';
import { useTvcPortalApiValidationErrors } from '../../hooks/useTvcPortalApiValidationErrors';
import LoadFundsFormSubmissionFeedback from '../load-funds/LoadFundsFormSubmissionFeedback';
import { useFuelFundsTabs } from '../load-funds/hooks/useFuelFundsTabs';
import { useTvcPortalApiStepsResponse } from '../../hooks/useTvcPortalApiStepsResponse';
import Balance from '../Balance';
import StandardError from '../StandardError';
import { useProfile } from '../../../features/auth/hooks/useProfile';
import FuelLoadError from './FuelLoadError';
import { FuelFees } from '../../models/fuel/FuelFees';
import { PortalApiBalance } from '../../models/fuel/PortalApiBalance';
import { NextAvailableFuelLoadModel } from '../../models/fuel/NextAvailableFuelLoadModel';
import { usePaymentMethods } from '../../../features/paymentMethod/hooks/usePaymentMethods';
import { RootState } from '../../../app/store';
import { PendingLoad } from '../../models/fuel/PendingLoad';

interface LoadFundsModalProps<T extends PortalApiBalance> {
  canViewPendingLoads?: boolean;
  canViewBalance?: boolean;
  balance?: number;
  balanceAsOf?: Date;
  loadFees: FuelFees;
  loadLimits: PortalApiLoadLimit;
  coupon?: Coupon;
  allowCoupon?: boolean;
  pendingLoadsTabContent?: React.ReactNode;
  nextAvailFundingInfo?: NextAvailableFuelLoadModel;
  balanceHelpComponent?: React.ReactNode;
  pendingLoadsBalanceHelpComponent?: React.ReactNode;
  dialogProps: {
    title?: string;
    onClose: () => void;
    open: boolean;
    dataTestId?: string;
    titleBackGroundColor?: string;
  };
  loadContractFunc: (loadData: LoadFuelFundsData) => Promise<AxiosResponse<T, any>>;
  postLoadFundsFunc?: (data: T, loadData: LoadFuelFundsData) => Promise<void>;
  balanceLabel?: string;
  isLoadingLoadFees?: boolean;

  pendingLoadsBalance?: number;
  pendingLoadsLength?: number;
  isLoadingPendingLoads: boolean;
  pendingLoadsSelector?: (state: RootState) => PendingLoad[];
}
export const LoadFundsModal = <LoadFundsResponse extends PortalApiBalance>(props: LoadFundsModalProps<LoadFundsResponse>) => {
  const {
    canViewPendingLoads = false,
    canViewBalance = false,
    balance,
    balanceAsOf,
    loadFees,
    loadLimits,
    coupon,
    allowCoupon = true,
    pendingLoadsTabContent,
    loadContractFunc,
    postLoadFundsFunc,
    nextAvailFundingInfo,
    dialogProps,
    balanceHelpComponent,
    pendingLoadsBalanceHelpComponent,
    balanceLabel,
    isLoadingLoadFees = false,

    pendingLoadsBalance = 0,
    pendingLoadsLength = 0,
    isLoadingPendingLoads,
    pendingLoadsSelector,
  } = props;
  const { userProfile } = useProfile();
  const theme = useTheme();
  const {
    onClose, open, title = 'Load Funds', dataTestId = 'load-funds-modal-test-id', titleBackGroundColor = theme.palette.primary.light,
  } = dialogProps;

  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [loadRepsonse, setLoadResponse] = useState<PortalApiBalance>();
  const {
    formIsSubmitted, setFormIsSubmitted, activeTab, setActiveTab, handleFeedbackSubmissionNavigation, formIsProcessing, setFormIsProcessing, error, setError,
  } = useFuelFundsTabs();
  const { handleError } = useAsyncErrorHandler();
  const {
    apiValidationErrors, hasApiValidationErrors, setApiValidationErrors,
  } = useTvcPortalApiValidationErrors();

  const {
    apiStepsResponse, setApiStepsResponse, hasApiStepsResponse,
  } = useTvcPortalApiStepsResponse();

  const { paymentMethodsLoading } = usePaymentMethods();

  const handleClose = () => {
    setFormIsSubmitted(false);
    setApiValidationErrors([]);
    setActiveTab(undefined);
    setLoadResponse(undefined);
    setApiStepsResponse([]);
    onClose();
  };

  const onFormSubmissionFeedbackButtonClicked = (tabNumber: number) => {
    setLoadResponse(undefined);
    setApiStepsResponse([]);
    handleFeedbackSubmissionNavigation(tabNumber);
  };

  const handleLoadSubmit = async (loadData: LoadFuelFundsData) => {
    // including formIsProcessing check as a sanity check
    if (formIsProcessing) {
      throw new Error('Unable to load funds. Form submission is already processing');
    }
    setFormIsProcessing(true);
    setApiValidationErrors([]);
    setApiStepsResponse([]);
    setError('');
    try {
      const { data } = await loadContractFunc(loadData);

      setLoadResponse(data);

      postLoadFundsFunc?.(data, loadData);
      setFormIsSubmitted(true);
    } catch (e) {
      const err = e as Error;
      if (!hasApiValidationErrors({ errorResponse: err })) {
        if (!hasApiStepsResponse(err)) {
          setError(err.toString());
        }
      }
      handleError(err);
    } finally {
      setFormIsProcessing(false);
    }
  };

  useEffect(() => () => {
    setFormIsSubmitted(false);
    setError('');
    setApiStepsResponse([]);
  }, [open]);

  return (
    <DetailsWrapper
      title={title}
      data-testid={dataTestId}
      preventKeyboardNavigation={formIsProcessing}
      onClose={handleClose}
      open={open}
      titlebackgroundcolor={titleBackGroundColor}
      maxLargeScreenDialogWidth={1300}
      confirmCloseProps={{
        showConfirmCloseModal: !formIsSubmitted ? formIsDirty : false,
        confirmTitle: "Close 'Load Funds' Dialog?",
        confirmContent: "You may have unsaved changes. Are you sure you want to close the 'Load Funds' dialog? ",
      }}
      isAppBarSticky
      closeButtonTestID="load-funds-modal-close-button"
    >
      <ContentPadding>
        {error.length > 0
          ? <FuelLoadError />
          : (
            <LoadFuelFundsTabs
              activeTab={activeTab}
              headerContent={(
                <>
                  <Balance
                    label={balanceLabel}
                    balance={balance}
                    asOfDate={balanceAsOf}
                    userCanViewBalance={canViewBalance}
                    helpComponent={balanceHelpComponent}
                    balanceValueTestID="load-funds-balance"
                  />
                  <Balance
                    isLoading={isLoadingPendingLoads}
                    label={`Pending Loads (${pendingLoadsLength})`}
                    balance={pendingLoadsBalance}
                    userCanViewBalance={canViewPendingLoads}
                    helpComponent={pendingLoadsBalanceHelpComponent}
                    balanceValueTestID="load-funds-pending-loads-balance"
                  />
                </>
              )}
              loadFundsTabContentLoading={paymentMethodsLoading || isLoadingLoadFees}
              disableTabs={formIsProcessing}
              loadFundsTabContent={(
                <>
                  {loadFees && (
                    <LoadFuelFundsForm
                      loadFees={loadFees}
                      loadLimits={loadLimits || ({} as PortalApiLoadLimit)}
                      apiValidationErrors={apiValidationErrors}
                      steps={apiStepsResponse}
                      coupon={coupon}
                      onLoadFundsSubmit={handleLoadSubmit}
                      postSubmitContent={(
                        <LoadFundsFormSubmissionFeedback
                          error={error}
                          onLoadMoreFundsClick={() => {
                            onFormSubmissionFeedbackButtonClicked(0);
                          }}
                          onViewPendingLoadsClick={() => {
                            onFormSubmissionFeedbackButtonClicked(2);
                          }}
                          response={loadRepsonse}
                          showViewPendingLoads={canViewPendingLoads}
                        />
                      )}
                      formIsSubmitted={formIsSubmitted}
                      formDataIsProcessing={formIsProcessing}
                      onCancel={() => {
                        handleClose();
                      }}
                      onDirtyStateChanged={(isDirty) => {
                        setFormIsDirty(isDirty);
                      }}
                      allowCoupon={allowCoupon}
                      nextAvailableFundingInfo={nextAvailFundingInfo}
                    />
                  )}

                  {!isLoadingLoadFees && !loadFees && (
                    <StandardError
                      style={{ width: '100%' }}
                      errorTitle="Load fees are unavailable"
                      errorDescription={userProfile?.isEmployee ? 'Load fees are required to render the load funds form. Please check to make sure expedite fees are correctly configured.' : ''}
                    />
                  )}
                </>
              )}
              pendingLoadsTabContent={pendingLoadsTabContent}
              userCanViewPendingLoads={canViewPendingLoads}
              pendingLoadsSelector={pendingLoadsSelector}
            />
          )}
      </ContentPadding>
    </DetailsWrapper>
  );
};
