import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCanadianProvinces, selectUsStates } from '../../features/application/applicationSlice';
import { StateProvince } from '../models/other/StateProvince';

const useStateProvinceList = () => {
  const [stateProvinceList, setStateProvinceList] = useState<StateProvince[]>([]);
  const [isCanada, setIsCanada] = useState<boolean>(false);

  const usStates = useSelector(selectUsStates);
  const canadianProvinces = useSelector(selectCanadianProvinces);

  const getUsStates = (sortBy?: ((a: StateProvince, b: StateProvince) => number)) => {
    if (sortBy) {
      return usStates.sort(sortBy);
    }
    return usStates;
  };

  const getCanadianProvinces = (sortBy?: ((a: StateProvince, b: StateProvince) => number)) => {
    if (sortBy) {
      return canadianProvinces.sort(sortBy);
    }
    return canadianProvinces;
  };

  const getAllStatesAndProvinces = (sortBy?: ((a: StateProvince, b: StateProvince) => number)) => {
    const fullList = [...usStates, ...canadianProvinces];
    if (sortBy) {
      return fullList.sort(sortBy);
    }
    return fullList;
  };

  useEffect(() => {
    if (isCanada) {
      setStateProvinceList(canadianProvinces);
    } else {
      setStateProvinceList(usStates);
    }
  }, [isCanada]);

  return {
    stateProvinceList,
    isCanada,
    setIsCanada,
    getUsStates,
    getCanadianProvinces,
    getAllStatesAndProvinces,
  };
};

export default useStateProvinceList;
