import { CaseInformation } from '../../../shared/models/legal/CaseInformation';
import { FleetCaseInformation } from '../../../shared/models/legal/FleetCaseInformation';
import { LawType } from '../../../shared/models/legal/LawType';
import { VehicleType } from '../../../shared/models/legal/VehicleType';
import { ViolationType } from '../../../shared/models/legal/ViolationType';
import { initAxiosInstance } from './utils';

export const useCaseApi = () => {
  const portalClient = initAxiosInstance();

  // API: SubmitCase
  const postSubmitIndividualMembershipCase = async (caseInfo: CaseInformation) => {
    const { data } = await portalClient.post<Number>('/legal/case', caseInfo);
    return data;
  };

  // API: SubmitFleetCase
  const postSubmitFleetMembershipCase = async (fleetCaseInfo: FleetCaseInformation) => {
    const { data } = await portalClient.post<Number>('/legal/case/fleet', fleetCaseInfo);
    return data;
  };

  // API: GetVehicleTypes
  const getVehicleTypes = async () => {
    const { data } = await portalClient.get<VehicleType[]>('/legal/case/vehicleTypes');
    return data;
  };

  // API: GetViolationTypes
  const getViolationTypes = async () => {
    const { data } = await portalClient.get<ViolationType[]>('/legal/case/violationTypes');
    return data;
  };

  // API: GetLawTypes
  const getLawTypes = async () => {
    const { data } = await portalClient.get<LawType[]>('/legal/case/lawTypes');
    return data;
  };

  return {
    postSubmitIndividualMembershipCase,
    postSubmitFleetMembershipCase,
    getVehicleTypes,
    getViolationTypes,
    getLawTypes,
  };
};
