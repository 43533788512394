import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import ContentPadding from './ContentPadding';

interface FormLayoutProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const FormLayout = (props: FormLayoutProps) => {
  const { children, style } = props;
  const theme = useTheme();
  const styles = {
    paperRoot: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px',
        marginLeft: '8px',
        marginRight: '8px',
      },
    },
  };

  return (
    <Paper sx={{
      ...styles.paperRoot,
      ...style,
    }}
    >
      <ContentPadding includeTopBottomPadding>{children}</ContentPadding>
    </Paper>
  );
};

export default FormLayout;
