import React from 'react';
import { Tooltip } from '@mui/material';
import TvcBadge from './TvcBadge';
import { RestrictAccess } from '../models/other/RestrictAccess';

interface FreezeBadgeProps {
  restrictAccess?: RestrictAccess;
  children: React.ReactNode;
}

const FreezeBadge = (props: FreezeBadgeProps) => {
  const {
    restrictAccess,
    children,
  } = props;

  return (
    <Tooltip title={restrictAccess?.freezeActivity ? restrictAccess.freezeActivityReason : ''} aria-label="account is being reviewed for eligibility">
      <TvcBadge badgeColor="info" variant="dot" invisible={!restrictAccess?.freezeActivity}>
        {children}
      </TvcBadge>
    </Tooltip>
  );
};

export default FreezeBadge;
