import React from 'react';
import { Button } from '@mui/material';
import { PortalApiBalance } from '../../models/fuel/PortalApiBalance';
import FormSubmissionFeedback from '../FormSubmissionFeedback';
import FormSubmissionFeedbackButtons from '../FormSubmissionFeedbackButtons';
import StandardError from '../StandardError';
import { FuelLoadStatus } from '../../enums/fuel/FuelLoadStatus';

interface LoadFundsFormSubmissionFeedbackProps {
  onLoadMoreFundsClick: () => void;
  onViewPendingLoadsClick: () => void;
  error?: string;
  id?: string;
  viewLoadsText?: string;
  response?: PortalApiBalance;
  showViewPendingLoads?: boolean;
}

const getAlertText = (response: PortalApiBalance | undefined) => {
  if (response) {
    switch (response.status) {
      case FuelLoadStatus.Loaded:
        return 'Funds were successfully loaded and are now available.';
      case FuelLoadStatus.Deferred:
        return 'Funds were successfully submitted for processing and will be available on the estimated delivery date.';
      case FuelLoadStatus.Drafted:
        return 'Funds were successfully submitted to be drafted. The availability of funds are pending bank account authorization. Estimated delivery date of funds cannot be guaranteed.';
      default:
        return 'Unknown funding status';
    }
  }
  return '-';
};

const getAlertSeverity = (response: PortalApiBalance | undefined) => {
  if (response) {
    switch (response.status) {
      case FuelLoadStatus.Loaded:
      case FuelLoadStatus.Deferred:
        return 'success';
      case FuelLoadStatus.Drafted:
        return 'warning';
      default:
        return 'info';
    }
  }
  return undefined;
};

const LoadFundsFormSubmissionFeedback = (
  props: LoadFundsFormSubmissionFeedbackProps,
) => {
  const {
    onLoadMoreFundsClick,
    onViewPendingLoadsClick,
    error,
    response,
    id,
    viewLoadsText = 'View Pending Loads',
    showViewPendingLoads = false,
  } = props;
  return error ? (
    <StandardError errorDescription={error} />
  ) : (
    <FormSubmissionFeedback show alertText={getAlertText(response)} alertSeverity={getAlertSeverity(response)} id={id} steps={response?.steps}>
      <FormSubmissionFeedbackButtons>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onLoadMoreFundsClick()}
        >
          Load More Funds
        </Button>
        {showViewPendingLoads && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onViewPendingLoadsClick()}
          >
            {viewLoadsText}
          </Button>
        )}
      </FormSubmissionFeedbackButtons>
    </FormSubmissionFeedback>
  );
};

export default LoadFundsFormSubmissionFeedback;
