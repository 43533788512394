import { find } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/store';
import { MatrixPromotion } from '../../../shared/models/matrix/MatrixPromotion';
import { fetchFleetMatrixProducts, selectFleetMatrixProducts, selectIsLoadingFleetMatrixProducts } from '../fleetSlice';

export const useMatrixProducts = (isFleet: boolean = false) => {
  const dispatch = useAppDispatch();
  const fleetProducts = useSelector(selectFleetMatrixProducts); // TODO: individual
  const isLoadingFleetProducts = useSelector(selectIsLoadingFleetMatrixProducts);

  const products = isFleet ? fleetProducts : [];
  const isLoadingProducts = isFleet ? isLoadingFleetProducts : false; // TODO: individual
  const fetchFleetProducts = async (orgId: number) => {
    await dispatch(fetchFleetMatrixProducts(orgId));
  };

  const getProductDisplay = (productId: number | undefined) => {
    if (!productId) return '-';

    return `${productId} - ${find((product) => product.id === productId, products)?.name}`;
  };

  const getPromotionDisplay = (promotion: MatrixPromotion | undefined) => {
    if (!promotion) return '-';

    return `${promotion.id} - ${promotion.name}`;
  };

  const getProductPromotions = (productId: number | undefined) => {
    if (!productId) return [];

    return find((product) => product.id === productId, products)?.promotions;
  };

  const getProductPromotionDisplay = (
    productId: number | undefined,
    promotionId: number | undefined,
  ) => {
    const productPromotion = getProductPromotions(productId)?.find(
      (p) => p.id === promotionId,
    );

    return getPromotionDisplay(productPromotion);
  };

  return {
    products,
    isLoadingProducts,
    getProductDisplay,
    getProductPromotionDisplay,
    getProductPromotions,
    getPromotionDisplay,
    fetchFleetProducts,
  };
};
