import React, { useState } from 'react';
import { TreeItem, TreeView } from '@mui/lab';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import LabelValue from '../LabelValue';
import { formatTimezone, getShortDay } from '../../utilities/dateHelpers';
import ProcessStepsArray from '../ProcessStepsArray';
import NoResults from '../NoResults';
import { currencyFormat } from '../../utilities/currencyFormat';
import { LoadStepDetailModel } from '../../models/fuel/LoadStepDetailModel';

interface ScheduledLoadHistoryProps {
  history?: LoadStepDetailModel[];
}

const ScheduledLoadHistoryDisplay = (props: ScheduledLoadHistoryProps) => {
  const {
    history = [],
  } = props;

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  return (
    <TreeView
      defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
      defaultExpandIcon={<AddBoxOutlinedIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      sx={{
        width: '100%',
      }}
    >
      {history.length > 0 ? history.map((h) => {
        const triggerDateDisplay = h.createdDate ? `${formatTimezone(new Date(h.createdDate), "MM/dd/yyyy h:mm aaaaa'm' zzz")} (${getShortDay(new Date(h.createdDate))})` : '<invalid trigger date>';
        return (
          <TreeItem key={`deferred-load-${h.deferredLoadId}`} nodeId={h.deferredLoadId} sx={{ marginTop: '12px' }} label={triggerDateDisplay}>
            <div style={{ paddingTop: '12px', paddingBottom: '12px' }}>
              <LabelValue label="Deferred Load ID" value={h.deferredLoadId} containerStyle={{ marginBottom: '12px' }} />
              <LabelValue label="Load Amount" value={h.loadAmount ? currencyFormat(h.loadAmount, 2) : '-'} containerStyle={{ marginBottom: '12px' }} />
              <ProcessStepsArray stepArray={h.stepsWithDateTimes} />
            </div>
          </TreeItem>
        );
      })
        : <NoResults>No History</NoResults>}
    </TreeView>
  );
};

export default ScheduledLoadHistoryDisplay;
