import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { ExtendedFuelCardMarketingData } from '../models/fuel/ExtendedFuelCardMarketingData';
import { PortalApiUser } from '../models/user/PortalApiUser';
import HowToFindFuelingDiscounts from '../components/fuel/HowToFindFuelingDiscounts';
import { securityHoldText } from './fuelHelpers';

interface CallLinkProps {
  phoneNumber?: string;
}
const CallLink = (props: CallLinkProps) => {
  const { phoneNumber = '833-882-3835' } = props;

  return <Link href={`tel:${phoneNumber}`} sx={{ color: 'inherit', fontWeight: 600 }}>{phoneNumber}</Link>;
};

const FindDiscounts = () => <HowToFindFuelingDiscounts style={{ color: 'inherit', fontWeight: 600 }} buttonText="View Fuel Discount Map" />;

export const getTargtedFuelMessage = (fm: ExtendedFuelCardMarketingData, portalUser: PortalApiUser): React.ReactNode => {
  if (portalUser.showFuelMessaging && fm) {
    if (fm.productHasFuel) { // do they have an active membership with a product that has fuel (smart card or carrier card)
      if (fm.hasFuelCard || portalUser.hasCarrierFuelCard) {
        if (fm.hasActiveFuelCard || portalUser.hasActiveCarrierFuelCard) {
          if (fm.hadAnyTransactionsInTheLast2Weeks) {
            if (fm.hadAnyDiscountedTransactionsInTheLast7Days) {
              return '';
            }
            return (
              <Box>
                Not seeing the discounts you expected? The TVC fuel card offers you incredible discounts across the country at participating locations.
                Make sure you are using your TVC Fuel Card at locations with discounts:
                {' '}
                <FindDiscounts />
              </Box>
            );
          }

          return (
            <Box>
              Did you fill up this month? Your TVC membership includes access to our TVC Fuel Card which helps you save on fuel! Start using your TVC Fuel Card at locations with discounts today:
              {' '}
              <FindDiscounts />
            </Box>
          );
        }

        if (fm.hasRegisteredCardWithoutActivation) {
          if (fm.hasRegisteredCardWithoutActivationOver30DaysOld) {
            return (
              <Box>
                Have you received your fuel card in the mail? Activate it now to start saving at the pump! Once you activate your card, you can use our Fuel Discount Map to find discounts at fueling locations on your route.
                {' '}
                <FindDiscounts />
                <Box sx={{
                  marginTop: '16px',
                }}
                >
                  Didn’t receive your fuel card yet? Let us know! Call
                  {' '}
                  <CallLink phoneNumber="800-288-2889" />
                  {' '}
                  or email
                  {' '}
                  <Link href="mailto:fuel@prodriver.com" style={{ color: 'inherit', fontWeight: 600 }}>fuel@prodriver.com</Link>
                  . Every day you spend driving without using your TVC fuel card is a day you’re throwing money away.
                </Box>
              </Box>
            );
          }
          return (
            <Box>
              Have you received your fuel card in the mail? Activate it now to start saving at the pump! Once you activate your card, you can use our Fuel Discount Map to find discounts at fueling locations on your route.
              {' '}
              <FindDiscounts />
            </Box>
          );
        }
      }

      if (fm.hasSecurityHold) {
        return securityHoldText;
      }

      if (!portalUser.hasCarrierFuelCard) {
        return (
          <>
            Want to save thousands of dollars on fuel? Sign up for our TVC Fuel Card which is included for free with your membership.
            With average savings of 60 cents or more per gallon, TVC members keep more money in their pocket each month!
            <br />
            <br />
            <Link
              component={RouterLink}
              to={`/individual-memberships/${portalUser.userId}/fuel`}
              style={{ fontWeight: 'bold', color: 'white' }}
            >
              Sign up for our TVC Fuel Card
            </Link>
          </>
        );
      }

      return (
        <Box>
          There seems to be an issue in determining the status of your fuel card. Please call
          {' '}
          <CallLink />
          {' '}
          for more details.
        </Box>
      );
    }

    return (
      <Box>
        Interested in saving 60 cents or more per gallon on fuel? Call
        {' '}
        <CallLink phoneNumber="866 - 875 - 9276" />
        {' '}
        today to upgrade to Pro Plus and gain access to the TVC Fuel Card!
      </Box>
    );
  }

  return '';
};
