import { useState } from 'react';

export const useEntitySelectedModals = <T>() => {
  const [selectedEntity, setSelectedEntity] = useState<T | null>(null);
  const [selectedAlternativeEntity, setSelectedAlternativeEntity] = useState<T | undefined>(undefined);
  const [entityDetailsModalOpen, setEntityDetailsModalOpen] = useState(false);
  const [entityActionModalOpen, setEntityActionModalOpen] = useState(false);
  const [entitySecondaryActionModalOpen, setEntitySecondaryActionModalOpen] = useState(false);

  const clearSelectedEntity = () => {
    setTimeout(() => {
      setSelectedEntity(null);
      setSelectedAlternativeEntity(undefined);
    }, 300);
  };

  return {
    selectedEntity,
    setSelectedEntity,
    selectedAlternativeEntity,
    setSelectedAlternativeEntity,
    entityDetailsModalOpen,
    setEntityDetailsModalOpen,
    entityActionModalOpen,
    setEntityActionModalOpen,
    entitySecondaryActionModalOpen,
    setEntitySecondaryActionModalOpen,
    clearSelectedEntity,
  };
};
