import { useState } from 'react';
import { parseEnv } from '../../config';
import { TvcSlideDirection } from '../types/other/animation';

export interface TvcTabRoute {
  routeSuffix: string;
  tabIndex: number;
  label: React.ReactNode;
}

const config = parseEnv();

export const useCommon = () => {
  // SLIDE
  const [slideDirection, setSlideDirection] = useState<TvcSlideDirection>('left');

  // TABS
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [hideTabs, setHideTabs] = useState(false);
  const [tabRoutes, setTabRoutes] = useState<TvcTabRoute[]>([]);
  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    const previousTab: number = currentTab;
    setCurrentTab(tabIndex);
    setSlideDirection(previousTab > tabIndex ? 'left' : 'right');
  };
  const tabsStyle = {
    root: {
      justifyContent: 'center',
    },
    scroller: {
      flexGrow: 0,
    },
  };

  // OTHER
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return {
    // SLIDE
    slideDirection,
    // TABS
    currentTab,
    setCurrentTab,
    handleTabChange,
    hideTabs,
    setHideTabs,
    tabRoutes,
    setTabRoutes,
    tabsStyle,
    // OTHER
    config,
    isLoading,
    setIsLoading,
    isOpen,
    setIsOpen,
  };
};
