import {
  get, cond, flow, eq, constant, getOr,
} from 'lodash/fp';
import { PaymentMethodType } from '../enums/payment-method/PaymentMethodType';
import { AddBankAccountPaymentMethod } from '../models/payment-method/AddBankAccountPaymentMethod';
import { AddCreditCardPaymentMethod } from '../models/payment-method/AddCreditCardPaymentMethod';
import { PaymentMethod } from '../models/payment-method/PaymentMethod';
import { PortalApiPaymentMethod } from '../models/payment-method/PortalApiPaymentMethod';
import { AddPaymentMethod } from '../types/payment-method/AddPaymentMethod';
import { getCentralNowDate } from './dateHelpers';

export const getPaymentMethodType = get('paymentMethodType');

export const getLast4 = get('last4');

export const getMaskedAccountNumber = cond<PortalApiPaymentMethod, string>([
  [
    flow(getPaymentMethodType, eq(PaymentMethodType.CreditCard)),
    flow(getLast4, (last4) => `xxxx-xxxx-xxxx-${last4}`),
  ],
  [
    flow(getPaymentMethodType, eq(PaymentMethodType.BankAccount)),
    flow(getLast4, (last4) => `xxxxx${last4}`),
  ],
  [
    flow(getPaymentMethodType, eq(PaymentMethodType.Check)),
    flow(getOr('', 'id'), toString),
  ],
  [constant(true), constant('Unknown Account')],
]);

export const getDefaultName = (paymentMethod: PortalApiPaymentMethod) => {
  const { friendlyName, last4, paymentMethodType } = paymentMethod;
  if (friendlyName) {
    return friendlyName;
  }

  switch (paymentMethodType) {
    case PaymentMethodType.BankAccount:
      return `Bank Account...${last4}`;
    case PaymentMethodType.CreditCard:
      return `Credit Card...${last4}`;
    case PaymentMethodType.Check:
      // TODO: <Y> add more context about check in name, ie add the checkNumber
      return 'Check';
    default:
      return 'unknown';
  }
};

export const creditCardIsExpired = (month: number, year: number) => {
  const expireDate = getCentralNowDate();
  expireDate.setFullYear(year, month);

  return expireDate < getCentralNowDate();
};

const isCreditCard = (
  paymentMethod: AddPaymentMethod,
): paymentMethod is AddCreditCardPaymentMethod => Boolean((paymentMethod as AddCreditCardPaymentMethod).token);

const isBankAccount = (
  paymentMethod: AddPaymentMethod,
): paymentMethod is AddBankAccountPaymentMethod => Boolean((paymentMethod as AddBankAccountPaymentMethod).routingNumber);

export const getAddPaymentMethodBody = (paymentMethod: AddPaymentMethod) => {
  if (isCreditCard(paymentMethod)) {
    return {
      creditCardAccount: paymentMethod,
    };
  }

  if (isBankAccount(paymentMethod)) {
    return {
      bankAccountModel: paymentMethod,
    };
  }

  throw new Error('unrecognized payment method attempted to add');
};

export const getPaymentMethodDisabledState = (pm: PaymentMethod, userIsEmployee: boolean | undefined, isBilling: boolean): boolean => {
  const isAvailable = isBilling ? pm.isAvailableForBilling : pm.isAvailable;
  if (isAvailable && !pm.isAuthorized) {
    return !userIsEmployee;
  }

  return !isAvailable;
};

export const findPaymentMethod = (pms: PaymentMethod[], pmId: number | undefined) => pms.find((pm) => pm.id === pmId);

export const apiPaymentMethodToFrontendPaymentMethod = (pm: PortalApiPaymentMethod) => ({
  ...pm,
  name: getDefaultName(pm),
  maskedAccountNumber: getMaskedAccountNumber(pm),
  isCheck: pm.paymentMethodType === PaymentMethodType.Check,
});
