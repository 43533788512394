import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../app/store';
import { PortalApiPermission } from '../../../shared/models/permissions/PortalApiPermission';
import { Profile } from '../../../shared/models/user/Profile';
import { AzureUser } from '../../../shared/models/user/AzureUser';
import {
  useAsyncErrorHandler,
  useTvcAppInsights,
} from '../../../shared/hooks/useTvcAppInsights';
import { getUserProfileThunk } from '../authReducer';
import { computeAllow } from '../authUtil';

export const getInitials = (azureUser?: AzureUser): string => `${azureUser?.firstName.substr(0, 1)}${azureUser?.lastName.substr(0, 1)}`;

export const getFullName = (azureUser?: AzureUser) => `${azureUser?.firstName} ${azureUser?.lastName}`;

export const useProfile = () => {
  const dispatch = useAppDispatch();
  const [profileLoading, setProfileLoading] = useState(false);
  const { handleError } = useAsyncErrorHandler();
  const [userProfile, setUserProfile] = useState<Profile>();
  const { setLoggedInUser } = useTvcAppInsights();

  const getUserProfile = async () => {
    setProfileLoading(true);
    const profile = await dispatch(getUserProfileThunk());
    setProfileLoading(false);
    setLoggedInUser(profile);
    return profile;
  };

  useEffect(() => {
    let ignore = false;
    getUserProfile()
      .then((profile) => {
        if (!ignore) {
          setUserProfile(profile);
        }
      })
      .catch(handleError);
    return () => { ignore = true; };
  }, []);

  const allowWithScope = (
    action: string,
    entity: string,
    scopeName: string,
    scopePermissions: PortalApiPermission[] | undefined,
  ) => {
    if (!userProfile || !userProfile.permissions) return false;
    return computeAllow(
      action,
      entity,
      scopeName,
      userProfile.permissions,
      scopePermissions,
    );
  };

  const profileFleet = userProfile?.fleets && userProfile?.fleets.length > 0 ? userProfile.fleets[0] : undefined;
  const profileFleetUpcomingEvents = profileFleet && profileFleet.upcomingEvents ? profileFleet.upcomingEvents : undefined;

  const allow = (action: string, entity: string, scope: string) => allowWithScope(action, entity, scope, undefined);

  const allowFleet = (action: string, entity: string, fleetId: string) => {
    const fleet = userProfile?.fleets?.find((f) => f.id === fleetId);
    return allowWithScope(action, entity, 'Fleet', fleet?.permissions);
  };

  return {
    profileLoading,
    userProfile,
    allow,
    allowFleet,
    profileFleet,
    profileFleetUpcomingEvents,
    setUserProfile,
  };
};
