import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  concat, differenceWith, isEqual,
} from 'lodash/fp';
import { AppThunk, RootState } from '../../app/store';
import { useFuelApi } from '../../services/api/hooks/useFuelApi';
import {
  useMatrixApi,
} from '../../services/api/hooks/useMatrixApi';
import { SearchIndividualMemberships } from '../../shared/models/matrix/SearchIndividualMemberships';
import { PortalApiMembers } from '../../shared/models/matrix/PortalApiMembers';
import { PortalApiMembershipSearchUser } from '../../shared/models/matrix/PortalApiMembershipSearchUser';
import { usePermissionsApi } from '../../services/api/hooks/usePermissionsApi';
import { PortalApiPermissionFull } from '../../shared/models/permissions/PortalApiPermissionFull';
import { EmployeeDetails } from '../../shared/models/user/EmployeeDetails';
import { UpdateLoginInfo } from '../../shared/models/user/UpdateLoginInfo';
import { Profile } from '../../shared/models/user/Profile';
import { PortalApiProfile } from '../../shared/models/user/PortalApiProfile';
import { PortalUserBody } from '../../shared/models/user/PortalUserBody';
import { AddFleetUser } from '../../shared/models/user/AddFleetUser';
import { ResetFleetUserPassword } from '../../shared/models/user/ResetFleetUserPassword';
import { ResetUserPassword } from '../../shared/models/user/ResetUserPassword';
import { SetFleetUserStatus } from '../../shared/models/user/SetFleetUserStatus';
import { SetUserStatus } from '../../shared/models/user/SetUserStatus';
import { FleetUserDetailModel } from '../../shared/models/user/FleetUserDetailModel';
import { PortalApiUser } from '../../shared/models/user/PortalApiUser';
import {
  createBlockingThunk,
  createNonBlockingThunk,
} from '../../services/thunk';
import { toTimeZone } from '../../shared/utilities/dateHelpers';
import { addAlert, decrementBlockingRequests, incrementBlockingRequests } from '../application/applicationSlice';
import { getFullName, getInitials } from '../auth/hooks/useProfile';
import { FuelCardStatusTypes } from '../../shared/enums/fuel/FuelCardStatusTypes';
import { UserPortalFuelFees } from '../../shared/models/fuel/UserPortalFuelFees';
import { PortalSmartCard } from '../../shared/models/fuel/PortalSmartCard';
import { NameChangeRequestBase } from '../../shared/models/name-change/NameChangeRequestBase';
import { mapPortalApiFleetUser } from '../../shared/utilities/userHelpers';
import { useFleetApi } from '../../services/api/hooks/useFleetApi';
import { useUserApi } from '../../services/api/hooks/useUserApi';
import { getValueOrReplacementValue, syncFileRead } from '../../shared/utilities/miscHelpers';
import { ExtendedFuelCardMarketingData } from '../../shared/models/fuel/ExtendedFuelCardMarketingData';
import { getTargtedFuelMessage } from '../../shared/utilities/tsxHelpers';
import { Balance } from '../../shared/models/fuel/Balance';
import { MemberCarrierModel } from '../../shared/models/matrix/MemberCarrierModel';
import {
  defaultTransactionHistory, setPendingLoads, setScheduledLoads, setTransactionCarrierHistory,
  setVoidedLoads,
} from '../fuel/efsSlice';
import { FuelSponsorInfo } from '../../shared/models/user/FuelSponsorInfo';

export interface SelectedUser {
  user: PortalApiUser,
  profile: Profile,
}

export interface SmartCardActivated {
  smartCardId: number;
  userId: string;
}
export interface UserState {
  individualUsers: PortalApiMembers;
  fleetUsers: FleetUserDetailModel[];
  fleetUsersLoading: boolean;
  employees: Profile[];
  employeesLoading: boolean;
  permissions: PortalApiPermissionFull[];
  permissionsLoading: boolean;
  fleetPermissions: PortalApiPermissionFull[];
  fleetPermissionsLoading: boolean;
  loadFees: UserPortalFuelFees[];
  isLoadingLoadFees: boolean;
  selectedProfile?: Profile;
  loggedInUserIsSelectedProfile: boolean;
  isSettingSmartCardPin: boolean;
  isUpdatingIndividualUser: boolean;
  individualFuelMarketing?: ExtendedFuelCardMarketingData;
  targetedFuelMessage?: React.ReactNode;
}

const initialState: UserState = {
  individualUsers: { count: 0, members: [] },
  fleetUsers: [],
  fleetUsersLoading: false,
  employees: [],
  employeesLoading: false,
  permissions: [],
  permissionsLoading: false,
  fleetPermissionsLoading: false,
  fleetPermissions: [],
  loadFees: [],
  isLoadingLoadFees: false,
  loggedInUserIsSelectedProfile: false,
  isSettingSmartCardPin: false,
  isUpdatingIndividualUser: false,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    permissionsRetrieved: (
      state,
      action: PayloadAction<PortalApiPermissionFull[]>,
    ) => {
      state.permissions = action.payload;
    },
    setPermissionsLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.permissionsLoading = action.payload;
    },
    fleetPermissionsRetrieved: (
      state,
      action: PayloadAction<PortalApiPermissionFull[]>,
    ) => {
      state.fleetPermissions = action.payload;
    },
    setFleetPermissionsLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.fleetPermissionsLoading = action.payload;
    },
    fleetUsersRetrieved: (state, action: PayloadAction<FleetUserDetailModel[]>) => {
      // TODO: <Y> make this a little more fault tolerant for potential duplicates
      const newUsers = differenceWith(
        isEqual,
        action.payload,
        state.fleetUsers || [],
      );
      state.fleetUsers = concat(state.fleetUsers || [], newUsers);
    },
    fleetUsersRefreshed: (
      state,
      action: PayloadAction<{ fleetId: string; users: FleetUserDetailModel[] }>,
    ) => {
      const otherFleetUsers = (state.fleetUsers || []).filter(
        (u) => u.fleetId !== action.payload.fleetId,
      );
      state.fleetUsers = concat(otherFleetUsers, action.payload.users);
    },
    sortFleetUsers: (state) => {
      state.fleetUsers = state.fleetUsers
        ? state.fleetUsers?.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        })
        : [];
    },
    putFleetUser: (state, action: PayloadAction<FleetUserDetailModel>) => {
      const userIndex = state.fleetUsers?.findIndex((u) => u.userId === action.payload.userId);

      if (userIndex === -1) {
        state.fleetUsers?.push(action.payload);
      } else {
        state.fleetUsers[userIndex] = action.payload;
      }
    },
    toggleFleetUserLoginStatus: (state, action: PayloadAction<{ fleetId: string, userId: string, isActive: boolean }>) => {
      const userIndex = state.fleetUsers.findIndex((u) => u.userId === action.payload.userId && u.fleetId === action.payload.fleetId);

      if (userIndex !== -1) {
        state.fleetUsers[userIndex].isDisabled = !action.payload.isActive;
      }
    },
    toggleFleetUserLoginInfo: (state, action: PayloadAction<{ fleetId: string, userId: string, newLoginInfo: UpdateLoginInfo }>) => {
      const userIndex = state.fleetUsers.findIndex((u) => u.userId === action.payload.userId && u.fleetId === action.payload.fleetId);

      if (userIndex !== -1) {
        const { email } = action.payload.newLoginInfo;
        state.fleetUsers[userIndex].email = email;
      }
    },
    togglUserLoginStatus: (state, action: PayloadAction<{ userId: string, isActive: boolean }>) => {
      const userIndex = state.individualUsers.members.findIndex((u) => u.user && u.user.userId === action.payload.userId);

      if (userIndex !== -1) {
        const foundUser = state.individualUsers.members[userIndex].user;
        if (foundUser) {
          foundUser.isDisabled = !action.payload.isActive;
        }
      }

      // note.. moved this outside the userIndex check because there is a scenario where the selectedProfile will be set in the store when
      // they refresh the browser (but the inidividualUsers collection will be empty) because the profile is fetched bia the userId URL param
      if (state.selectedProfile?.portalUser) {
        state.selectedProfile.portalUser.isDisabled = !action.payload.isActive;
      }
    },
    toggleSelectedProfileAzureUserInfo: (state, action: PayloadAction<{ userId: string, loginInfo: UpdateLoginInfo }>) => {
      const { userId, loginInfo } = action.payload;
      const { email } = loginInfo;

      const userIndex = state.individualUsers.members.findIndex((u) => u.user && u.user.userId === userId);

      if (userIndex !== -1) {
        const foundUser = state.individualUsers.members[userIndex].user;
        if (foundUser) {
          foundUser.email = email;
        }
      }

      if (state.selectedProfile?.azureUser) {
        state.selectedProfile.azureUser = {
          ...state.selectedProfile.azureUser,
          ...action.payload.loginInfo,
        };
      }
    },
    setFleetUsersLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.fleetUsersLoading = action.payload;
    },
    updateEmployee: (state, action: PayloadAction<Profile>) => {
      const employeeIndex = state.employees.findIndex(
        (emp) => emp.id === action.payload.id,
      );

      if (employeeIndex !== -1) state.employees[employeeIndex] = action.payload;
    },
    employeesRetrieved: (state, action: PayloadAction<Profile[]>) => {
      // const newEmployees = differenceWith(
      //   isEqual,
      //   action.payload,
      //   state.employees || []
      // );
      // state.employees = concat(state.employees || [], newEmployees);
      state.employees = action.payload;
    },
    setEmployeesLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.employeesLoading = action.payload;
    },
    individualMembershipUsersRetrieved: (state, action: PayloadAction<PortalApiMembers>) => {
      state.individualUsers = action.payload;
    },
    individualLoadFeesRetrieved(state, action: PayloadAction<UserPortalFuelFees>) {
      const fleetLoadFeesIndex = state.loadFees.findIndex(
        (fpl) => fpl.userId === action.payload.userId,
      );

      if (fleetLoadFeesIndex === -1) {
        state.loadFees.push(action.payload);
      } else {
        state.loadFees[fleetLoadFeesIndex] = action.payload;
      }
    },
    setIsLoadingLoadFees(state, action: PayloadAction<boolean>) {
      state.isLoadingLoadFees = action.payload;
    },
    setSelectedProfile(state, action: PayloadAction<Profile | undefined>) {
      state.selectedProfile = action.payload;
    },
    updateSelectedProfileSmartCard(state, action: PayloadAction<PortalSmartCard>) {
      const smartcards = state.selectedProfile ? state.selectedProfile.smartcards.map((sc) => (sc.cardId === action.payload.cardId ? {
        ...action.payload,
      } : sc)) : [];

      state.selectedProfile = state.selectedProfile ? {
        ...state.selectedProfile,
        smartcards,
      } : undefined;
    },
    updateSelectedProfileCarrierBalance(state, action: PayloadAction<Balance>) {
      const portalUser = state.selectedProfile?.portalUser
        ? {
          ...state.selectedProfile.portalUser,
          contractBalance: action.payload.efsBalance,
          contractBalanceAsOf: action.payload.efsBalanceAsOf,
        } : undefined;

      state.selectedProfile = state.selectedProfile ? {
        ...state.selectedProfile,
        portalUser,
      } : undefined;
    },
    smartCardRegistered(state, action: PayloadAction<PortalSmartCard>) {
      const smartCard = action.payload;
      if (smartCard.cardBalance) {
        smartCard.cardBalance.efsBalanceAsOf = toTimeZone(smartCard.cardBalance.efsBalanceAsOf);
      }

      state.selectedProfile = state.selectedProfile ? {
        ...state.selectedProfile,
        portalUser: state.selectedProfile.portalUser ? {
          ...state.selectedProfile.portalUser,
          // allowRegisterSmartCard: false,
          hasFuelCard: true,
          fuelCardCount: 1,
          hasActiveFuelCard: false,
        } : undefined,
        smartcards: [{
          ...action.payload,
          status: FuelCardStatusTypes.INACTIVE,
          lastFour: smartCard.lastFour,
          needsActivation: true,
        }],
      } : undefined;
    },
    smartCardActivated(state, action: PayloadAction<SmartCardActivated>) {
      const individualUserIndex = state.individualUsers.members.findIndex((u) => u.user && u.user.userId === action.payload.userId);

      if (individualUserIndex !== -1) {
        const foundUser = state.individualUsers.members[individualUserIndex].user;
        if (foundUser) {
          state.individualUsers.members[individualUserIndex].user = {
            ...foundUser,
            hasActiveFuelCard: false,
            fuelCardCount: 1,
          };
        }
      }

      if (state.selectedProfile) {
        const smartCardIndex = state.selectedProfile.smartcards.findIndex(
          (sc) => sc.cardId === action.payload.smartCardId,
        );

        if (smartCardIndex !== -1) {
          state.selectedProfile.smartcards[smartCardIndex] = {
            ...state.selectedProfile.smartcards[smartCardIndex],
            needsActivation: false,
            status: FuelCardStatusTypes.INACTIVE,
          };
        }
      }
    },
    updateIndividualUserFuelCardState(state, action: PayloadAction<string>) {
      const individualUserIndex = state.individualUsers.members.findIndex((u) => u.user && u.user.userId === action.payload);

      if (individualUserIndex !== -1) {
        const foundUser = state.individualUsers.members[individualUserIndex].user;

        if (foundUser) {
          state.individualUsers.members[individualUserIndex].user = {
            ...foundUser,
            // allowRegisterSmartCard: false,
            fuelCardCount: 1,
            hasFuelCard: true,
            hasActiveFuelCard: false,
          };
        }
      }
    },
    setLoggedInUserIsSelectedProfile(state, action: PayloadAction<boolean>) {
      state.loggedInUserIsSelectedProfile = action.payload;
    },
    setIsSettingSmartCardPin(state, action: PayloadAction<boolean>) {
      state.isSettingSmartCardPin = action.payload;
    },
    setIsUpdatingIndividualUser(state, action: PayloadAction<boolean>) {
      state.isUpdatingIndividualUser = action.payload;
    },
    addedLoginAccountToIndividualUser(state, action: PayloadAction<PortalApiProfile>) { // TODO: MORN - update any with appropriate interface
      const individualUserIndex = state.individualUsers.members.findIndex((u) => u.user && u.user.userId === action.payload.id);

      if (individualUserIndex !== -1) {
        let foundUser = state.individualUsers.members[individualUserIndex].user;
        if (foundUser) {
          foundUser = {
            ...foundUser,
            hasLogin: true,
          };
        }
      }

      if (state.selectedProfile) {
        state.selectedProfile.azureUser = action.payload.azureUser;
      }
    },
    addedLoginAccountToIndividuaMembershpNewlUser(state, action: PayloadAction<PortalApiMembershipSearchUser>) {
      const individualUserIndex = state.individualUsers.members.findIndex((u) => u.member && u.member.id === action.payload.member.id);

      if (individualUserIndex !== -1) {
        state.individualUsers.members[individualUserIndex] = action.payload;
      }
    },
    updateFleetUserName(state, action: PayloadAction<{ userId: string, fleetId: string, nameChangeInfo: NameChangeRequestBase }>) {
      const userIndex = state.fleetUsers.findIndex((u) => u.userId === action.payload.userId && u.fleetId === action.payload.fleetId);

      if (userIndex !== -1) {
        const { firstName, lastName } = action.payload.nameChangeInfo;
        state.fleetUsers[userIndex].firstName = firstName;
        state.fleetUsers[userIndex].lastName = lastName;
      }
    },
    updateFleetUserCrossRefId: (state, action: PayloadAction<{ fleetId: string, userId: string, crossRefId: string | undefined }>) => {
      const userIndex = state.fleetUsers.findIndex((u) => u.userId === action.payload.userId && u.fleetId === action.payload.fleetId);

      if (userIndex !== -1) {
        state.fleetUsers[userIndex].crossRefId = action.payload.crossRefId;
      }
    },
    setIndividualFuelMarketing: (state, action: PayloadAction<ExtendedFuelCardMarketingData>) => {
      state.individualFuelMarketing = action.payload;
    },
    setTargtedFuelMessage: (state, action: PayloadAction<React.ReactNode>) => {
      state.targetedFuelMessage = action.payload;
    },
    updateSelectedProfileCarrierId(state, action: PayloadAction<MemberCarrierModel>) {
      const {
        balance, carrierId, isValidCarrier, hasCarrierFuelCard, hasActiveCarrierFuelCard, carrierCards, fuelSponsorInfo,
      } = action.payload;

      const portalUser: PortalApiUser | undefined = state.selectedProfile?.portalUser
        ? {
          ...state.selectedProfile.portalUser,
          contractBalance: balance?.efsBalance ? balance.efsBalance : 0,
          contractBalanceAsOf: balance?.efsBalanceAsOf && balance.efsBalanceAsOf ? toTimeZone(balance.efsBalanceAsOf) : null,
          carrierId,
          isValidCarrier,
          hasCarrierFuelCard,
          hasActiveCarrierFuelCard,
          fuelSponsorInfo,
        } : undefined;

      state.selectedProfile = state.selectedProfile ? {
        ...state.selectedProfile,
        carrierCards,
        portalUser,
      } : undefined;
    },
    updateSelectedProfileFuelSponsorInfo(state, action: PayloadAction<FuelSponsorInfo>) {
      const fuelSponsorInfo = action.payload;

      const portalUser: PortalApiUser | undefined = state.selectedProfile?.portalUser
        ? {
          ...state.selectedProfile.portalUser,
          fuelSponsorInfo,
        } : undefined;

      state.selectedProfile = state.selectedProfile ? {
        ...state.selectedProfile,
        portalUser,
      } : undefined;
    },
  },
});

export const {
  fleetUsersRetrieved,
  setFleetUsersLoadingStatus,
  fleetUsersRefreshed,
  employeesRetrieved,
  setEmployeesLoadingStatus,
  permissionsRetrieved,
  setPermissionsLoadingStatus,
  fleetPermissionsRetrieved,
  setFleetPermissionsLoadingStatus,
  sortFleetUsers,
  putFleetUser,
  updateEmployee,
  toggleFleetUserLoginStatus,
  individualMembershipUsersRetrieved,
  individualLoadFeesRetrieved,
  setIsLoadingLoadFees,
  setSelectedProfile,
  updateSelectedProfileSmartCard,
  togglUserLoginStatus,
  setLoggedInUserIsSelectedProfile,
  smartCardRegistered,
  updateIndividualUserFuelCardState,
  smartCardActivated,
  setIsSettingSmartCardPin,
  setIsUpdatingIndividualUser,
  addedLoginAccountToIndividualUser,
  addedLoginAccountToIndividuaMembershpNewlUser,
  toggleSelectedProfileAzureUserInfo,
  toggleFleetUserLoginInfo,
  updateFleetUserName,
  updateFleetUserCrossRefId,
  setIndividualFuelMarketing,
  setTargtedFuelMessage,
  updateSelectedProfileCarrierBalance,
  updateSelectedProfileCarrierId,
  updateSelectedProfileFuelSponsorInfo,
} = userSlice.actions;

// FLEET USERS
const retrieveFleetUsersThunk = (
  fleetId: string,
  refresh: boolean,
): AppThunk<Promise<FleetUserDetailModel[]>> => async (dispatch, getState) => {
  const stateUsers = getState().users.fleetUsers?.filter(
    (user) => user.fleetId === fleetId,
  );
  const { getFleetUsers } = useUserApi();

  if (stateUsers?.length && !refresh) {
    return stateUsers;
  }
  dispatch(setFleetUsersLoadingStatus(true));
  const users = await getFleetUsers(fleetId);
  dispatch(setFleetUsersLoadingStatus(false));
  dispatch(
    refresh
      ? fleetUsersRefreshed({
        fleetId,
        users,
      })
      : fleetUsersRetrieved(users),
  );
  return users;
};

export const retrieveUsers = (fleetId: string, block = true, refresh = false) => (block
  ? createBlockingThunk(retrieveFleetUsersThunk(fleetId, refresh))
  : createNonBlockingThunk(retrieveFleetUsersThunk(fleetId, refresh)));

const addFleetUserThunk = (userData: AddFleetUser, fleetName?: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(setFleetUsersLoadingStatus(true));
  const { postFleetUser } = useFleetApi();
  const addedFleetUser = await postFleetUser(userData);
  dispatch(putFleetUser(mapPortalApiFleetUser(addedFleetUser)));
  dispatch(sortFleetUsers());
  // await dispatch(retrieveUsers(addedUser.fleetId));
  dispatch(setFleetUsersLoadingStatus(false));
  dispatch(
    addAlert({
      message: `"${addedFleetUser.firstName} ${addedFleetUser.lastName}" successfully added to ${getValueOrReplacementValue(fleetName, '< unknown fleet >')}`,
      severity: 'success',
    }),
  );
};

export const addFleetUser = (userData: AddFleetUser, fleetName?: string) => createNonBlockingThunk(addFleetUserThunk(userData, fleetName));

const addNonBlockingFleetUserNameChangeRequestThunk = (userId: string, fleetId: string, nameChangeInfo: NameChangeRequestBase): AppThunk<Promise<void>> => async (dispatch) => {
  const { postAddFleetUserNameChangeRequest } = useFleetApi();
  await postAddFleetUserNameChangeRequest(userId, fleetId, nameChangeInfo);
  dispatch(updateFleetUserName({
    userId,
    fleetId,
    nameChangeInfo,
  }));
};

export const addFleetUserNameChangeRequestThunk = (userId: string, fleetId: string, nameChangeInfo: NameChangeRequestBase) => createNonBlockingThunk(addNonBlockingFleetUserNameChangeRequestThunk(userId, fleetId, nameChangeInfo));

export const updateFleetUserLoginStatusThunk = (fleetUserInfo: SetFleetUserStatus): AppThunk<Promise<void>> => async (dispatch) => {
  const { postFleetUserActiveStatus } = useFleetApi();
  try {
    dispatch(incrementBlockingRequests());
    await postFleetUserActiveStatus(fleetUserInfo);
    dispatch(toggleFleetUserLoginStatus(fleetUserInfo));
    dispatch(decrementBlockingRequests());
    dispatch(
      addAlert({
        message: `Login account has successfully been ${fleetUserInfo.isActive ? 'enabled' : 'disabled'}`,
        severity: 'success',
      }),
    );
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const resetFleetUserLoginPasswordThunk = (fleetUserInfo: ResetFleetUserPassword): AppThunk<Promise<void>> => async (dispatch) => {
  const { postResetFleetUserPassword } = useFleetApi();
  try {
    dispatch(incrementBlockingRequests());
    await postResetFleetUserPassword(fleetUserInfo);
    dispatch(decrementBlockingRequests());
    dispatch(
      addAlert({
        message: 'Password was successfully reset',
        severity: 'success',
      }),
    );
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const updateUserLoginStatusThunk = (userInfo: SetUserStatus): AppThunk<Promise<void>> => async (dispatch) => {
  const { postSetUserActiveStatus } = useUserApi();
  try {
    dispatch(incrementBlockingRequests());
    await postSetUserActiveStatus(userInfo);
    dispatch(togglUserLoginStatus(userInfo));
    dispatch(decrementBlockingRequests());
    dispatch(
      addAlert({
        message: `Login account has successfully been ${userInfo.isActive ? 'enabled' : 'disabled'}`,
        severity: 'success',
      }),
    );
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

export const resetUserLoginPasswordThunk = (userInfo: ResetUserPassword): AppThunk<Promise<void>> => async (dispatch) => {
  const { postResetUserPassword } = useUserApi();
  try {
    dispatch(incrementBlockingRequests());
    await postResetUserPassword(userInfo);
    dispatch(decrementBlockingRequests());
    dispatch(
      addAlert({
        message: 'Password was successfully reset',
        severity: 'success',
      }),
    );
  } catch (err) {
    dispatch(decrementBlockingRequests());
    throw err;
  }
};

// EMPLOYEES
const retrieveEmployeesThunk = (refresh: boolean = false): AppThunk<Promise<Profile[]>> => async (dispatch, getState) => {
  const stateEmployees = getState().users.employees;
  const { getEmployees } = useUserApi();
  if (stateEmployees?.length && !refresh) {
    return stateEmployees;
  }
  dispatch(setEmployeesLoadingStatus(true));
  const employees = await getEmployees();
  dispatch(setEmployeesLoadingStatus(false));
  dispatch(
    employeesRetrieved(
      employees.map((e) => ({
        ...e,
        initials: getInitials(e.azureUser),
        fullName: getFullName(e.azureUser),
      })),
    ),
  );
  return employees;
};

export const retrieveEmployees = (block = true) => (block
  ? createBlockingThunk(retrieveEmployeesThunk())
  : createNonBlockingThunk(retrieveEmployeesThunk()));

export const refreshEmployees = (block = true) => (block
  ? createBlockingThunk(retrieveEmployeesThunk(true))
  : createNonBlockingThunk(retrieveEmployeesThunk(true)));

const retrieveEmployeeDetailsThunk = (props: GetAvatarProps): AppThunk<Promise<EmployeeDetails>> => async (dispatch, getState) => {
  const { id, email } = props;
  const employee: Profile | undefined = getState().users.employees.find(
    (e) => e.id === id,
  );
  const updateStoreEmployee = (phone: string, employ?: Profile) => {
    if (employ) {
      dispatch(
        updateEmployee({
          ...employ,
          phone,
        }),
      );
    }
  };
  if (email) {
    const { getEmployeeDetails } = useUserApi();

    try {
      // TODO: get job title and use it where you need to.
      const details: EmployeeDetails = await getEmployeeDetails(email);
      updateStoreEmployee(details.phone, employee);
      return { phone: details.phone, jobTitle: details.jobTitle };
    } catch (ex) {
      updateStoreEmployee('', employee);
    }
  }
  return { phone: '', jobTitle: '' };
};

const retrieveEmployeeAvatarThunk = (props: GetAvatarProps): AppThunk<Promise<string>> => async (dispatch, getState) => {
  const { id, email } = props;

  const employee: Profile | undefined = getState().users.employees.find(
    (e) => e.id === id,
  );

  const updateStoreEmployee = (avatar: string, employ?: Profile) => {
    if (employ) {
      dispatch(
        updateEmployee({
          ...employ,
          avatar,
        }),
      );
    }
  };

  if (employee && employee.avatar) {
    return employee.avatar;
  }

  if (email) {
    const { getEmployeePhoto } = useUserApi();

    try {
      const employeeAvatar: Blob = await getEmployeePhoto(email);
      const avatar = await syncFileRead(employeeAvatar);
      updateStoreEmployee(avatar, employee);
      return avatar;
    } catch (ex) {
      updateStoreEmployee('', employee);
    }
  }

  return '';
};

export interface GetAvatarProps {
  id?: string;
  email?: string;
}

export const retrieveEmployeeAvatar = (props: GetAvatarProps, block = true) => (block
  ? createBlockingThunk(retrieveEmployeeAvatarThunk(props))
  : createNonBlockingThunk(retrieveEmployeeAvatarThunk(props)));

export const retrieveEmployeeDetails = (props: GetAvatarProps, block = true) => (block
  ? createBlockingThunk(retrieveEmployeeDetailsThunk(props))
  : createNonBlockingThunk(retrieveEmployeeDetailsThunk(props)));

// INDIVIDUAL MEMBERSHIP USERS
const retrieveIndividualMembershipUsersThunk = (
  paramaters: SearchIndividualMemberships,
): AppThunk<Promise<PortalApiMembers>> => async (dispatch) => {
  const { postSearchIndividualMembershipUsers } = useMatrixApi();
  const users = await postSearchIndividualMembershipUsers(paramaters);
  dispatch(individualMembershipUsersRetrieved(users));

  return users;
};

const retrieveIndividualMembershipProfileThunk = (
  userId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  const { getUserProfile } = useUserApi();
  dispatch(setSelectedProfile(undefined));
  const profile = await getUserProfile(userId);
  dispatch(setSelectedProfile(profile));
};

export const smartCardRegisteredThunk = (
  userId: string,
  smartCard: PortalSmartCard,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(smartCardRegistered(smartCard));
  dispatch(updateIndividualUserFuelCardState(userId));
};

const addLoginToIndividualPortalUserThunk = (requestBody: PortalUserBody, isNewPortalUser: boolean): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch(setIsUpdatingIndividualUser(true));
    const { postAddIndividualMembershipLogin } = useUserApi();
    const response = await postAddIndividualMembershipLogin(requestBody);
    const fullUserName = `${requestBody.firstName} ${requestBody.lastName}`;
    if (!isNewPortalUser) {
      // this login is being created for a membership that already has a portal user account... update state accordingly
      dispatch(addedLoginAccountToIndividualUser(response));
    } else {
      // make a background call to the individual membership search via member id... update state accordingly
      /* eslint-disable no-lonely-if */
      if (requestBody.memberId) {
        const filter: SearchIndividualMemberships = {
          id: { value: requestBody.memberId },
          sorts: [{ columnName: 'Member.LastName', isDescending: false }],
        };

        const { postSearchIndividualMembershipUsers } = useMatrixApi();
        const newPortalUser = await postSearchIndividualMembershipUsers(filter);

        if (newPortalUser && newPortalUser.members.length === 1) {
          const newUserToAdd = newPortalUser.members[0];
          dispatch(addedLoginAccountToIndividuaMembershpNewlUser(newUserToAdd));
        } else {
          throw new Error('User response payload unavailable');
        }
      } else {
        throw new Error('Request body memberId unavailable');
      }
    }

    dispatch(setIsUpdatingIndividualUser(false));

    dispatch(
      addAlert({
        message: response.notificationSent ? `Successfully added portal login to "${fullUserName}"` : 'Portal account created. However, there was a problem sending the notification email.',
        severity: response.notificationSent ? 'success' : 'warning',
      }),
    );
  } catch (err) {
    dispatch(setIsUpdatingIndividualUser(false));
    throw err;
  }
};

const setIndividualCarrierIdThunk = (carrierInfo: { memberId: number, carrierId: number }): AppThunk<Promise<void>> => async (dispatch) => {
  const { postSetIndividualCarrierId } = useMatrixApi();

  const memberCarrierInfo = await postSetIndividualCarrierId(carrierInfo);

  dispatch(updateSelectedProfileCarrierId(memberCarrierInfo));
};

export const updateIndividualCarrierId = (carrierInfo: { memberId: number, carrierId: number }) => createBlockingThunk(setIndividualCarrierIdThunk(carrierInfo));

const deleteIndividualCarrierIdThunk = (memberId: number): AppThunk<Promise<void>> => async (dispatch) => {
  const { deleteIndividualCarrierId } = useMatrixApi();

  const memberCarrierInfo = await deleteIndividualCarrierId(memberId);

  dispatch(setTransactionCarrierHistory(defaultTransactionHistory));
  dispatch(setPendingLoads([]));
  dispatch(setScheduledLoads([]));
  dispatch(setVoidedLoads([]));

  // cleanup selected profile state after carrier id is removed
  dispatch(updateSelectedProfileCarrierId(memberCarrierInfo));
};

export const removeIndividualCarrierId = (memberId: number) => createBlockingThunk(deleteIndividualCarrierIdThunk(memberId));

export const updateIndividualUserLoginInfoThunk = (userId: string, newLoginInfo: UpdateLoginInfo): AppThunk<Promise<void>> => async (dispatch) => {
  const { postUpdateIndividualLoginInfo } = useUserApi();
  await postUpdateIndividualLoginInfo(userId, newLoginInfo);
  dispatch(toggleSelectedProfileAzureUserInfo({ userId, loginInfo: newLoginInfo }));
  dispatch(
    addAlert({
      message: 'Login information successfully updated',
      severity: 'success',
    }),
  );
};

export const addLoginToIndividualPortalUser = (requestBody: PortalUserBody, isNewPortalUser = false) => createNonBlockingThunk(addLoginToIndividualPortalUserThunk(requestBody, isNewPortalUser));

export const retrieveIndividualMembershipUsers = (paramaters: SearchIndividualMemberships) => createBlockingThunk(retrieveIndividualMembershipUsersThunk(paramaters));

export const retrieveIndividualMembershipProfile = (userId: string) => createBlockingThunk(retrieveIndividualMembershipProfileThunk(userId));

export const retrieveLoadFeesForIndividualThunk = (userId: string, forceRefresh: boolean = false): AppThunk<Promise<UserPortalFuelFees>> => async (dispatch, getState) => {
  const stateIndividualLoadFees = getState().users.loadFees.find(
    (u) => u.userId === userId,
  );

  const { getIndividualLoadFees } = useFuelApi();

  if (stateIndividualLoadFees && !forceRefresh) {
    return stateIndividualLoadFees;
  }

  dispatch(setIsLoadingLoadFees(true));
  const userLoadFees: UserPortalFuelFees = await getIndividualLoadFees(userId);
  dispatch(setIsLoadingLoadFees(false));

  dispatch(individualLoadFeesRetrieved(userLoadFees));

  return userLoadFees;
};

const activateSmartCardAndSetPinThunk = (
  userId: string,
  smartCardId: number,
  pin: number,
): AppThunk<Promise<void>> => async (dispatch) => {
  const { postActivateAndSetSmartCardPin } = useFuelApi();

  try {
    dispatch(setIsSettingSmartCardPin(true));
    await postActivateAndSetSmartCardPin(smartCardId, pin);

    dispatch(smartCardActivated({ smartCardId, userId }));
    dispatch(
      addAlert({
        message: 'Fuel card has been successfully flagged for activation',
        severity: 'success',
      }),
    );
    dispatch(setIsSettingSmartCardPin(false));
  } catch (err) {
    dispatch(setIsSettingSmartCardPin(false));
    throw err;
  }
};

export const activateSmartCardAndSetPin = (userId: string, smartCardId: number, pin: number) => createNonBlockingThunk(activateSmartCardAndSetPinThunk(userId, smartCardId, pin));

const setSmartCardPinThunk = (
  userId: string,
  smartCardId: number,
  pin: number,
): AppThunk<Promise<void>> => async (dispatch) => {
  const { postSetSmartCardPin } = useFuelApi();

  try {
    dispatch(setIsSettingSmartCardPin(true));
    await postSetSmartCardPin(smartCardId, pin);
    dispatch(
      addAlert({
        message: 'Fuel card pin has been reset successfully',
        severity: 'success',
      }),
    );
    dispatch(setIsSettingSmartCardPin(false));
  } catch (err) {
    dispatch(setIsSettingSmartCardPin(false));
    throw err;
  }
};

export const setSmartCardPin = (userId: string, smartCardId: number, pin: number) => createNonBlockingThunk(setSmartCardPinThunk(userId, smartCardId, pin));

// PERMISSIONS
const retrievePermissionsThunk = (): AppThunk<Promise<PortalApiPermissionFull[]>> => async (dispatch, getState) => {
  const statePermissions = getState().users.permissions;
  const { getPermissions } = usePermissionsApi();
  if (statePermissions?.length) {
    return statePermissions;
  }
  dispatch(setPermissionsLoadingStatus(true));
  const permissions = await getPermissions();
  dispatch(setPermissionsLoadingStatus(false));
  dispatch(permissionsRetrieved(permissions));
  return permissions;
};

export const retrievePermissions = (block = true) => (block
  ? createBlockingThunk(retrievePermissionsThunk())
  : createNonBlockingThunk(retrievePermissionsThunk()));

const retrieveFleetPermissionsThunk = (): AppThunk<Promise<PortalApiPermissionFull[]>> => async (dispatch, getState) => {
  const stateFleetPermissions = getState().users.fleetPermissions;
  const { getFleetPermissions } = usePermissionsApi();

  if (stateFleetPermissions?.length) {
    return stateFleetPermissions;
  }

  dispatch(setFleetPermissionsLoadingStatus(true));
  const fleetPermissions = await getFleetPermissions();
  dispatch(setFleetPermissionsLoadingStatus(false));
  dispatch(fleetPermissionsRetrieved(fleetPermissions));

  return fleetPermissions;
};

export const retrieveFleetPermissions = () => createNonBlockingThunk(retrieveFleetPermissionsThunk());

const fetchFuelIndividualMarketingDataThunk = (portalUser: PortalApiUser): AppThunk<Promise<void>> => async (dispatch) => {
  const { getFuelCardMarketingData } = useFuelApi();
  const data = await getFuelCardMarketingData(portalUser.userId);
  const extendedData = {
    ...data,
    // allowRegisterSmartCard: portalUser.allowRegisterSmartCard,
    productHasFuel: portalUser.productHasFuel,
    hasFuelCard: portalUser.hasFuelCard,
    hasActiveFuelCard: portalUser.hasActiveFuelCard,
    hasSecurityHold: portalUser.hasSecurityHold,
  };
  dispatch(setIndividualFuelMarketing(extendedData));
  dispatch(setTargtedFuelMessage(getTargtedFuelMessage(extendedData, portalUser)));
};

export const fetchFuelCardMarketingData = (portalUser: PortalApiUser) => createBlockingThunk(fetchFuelIndividualMarketingDataThunk(portalUser));

// FLEET USER SELECTORS
export const selectFleetUsersByFleetId = (fleetId: string) => (state: RootState) => state.users.fleetUsers?.filter((user) => user.fleetId === fleetId);
export const selectFleetUserById = (userId: string) => (state: RootState) => state.users.fleetUsers?.find((user) => user.userId === userId);
export const selectFleetUsersLoading = (state: RootState) => state.users.fleetUsersLoading;

// EMPLOYEE SELECTORS
export const selectEmployees = (state: RootState) => state.users.employees;

export const selectEmployeeById = (id: string) => (state: RootState) => state.users.employees?.find((employee) => employee.id === id);
export const selectEmployeesLoading = (state: RootState) => state.users.employeesLoading;

// INDIVIDUAL USER SELECTORS
export const selectIndividualUsers = () => (state: RootState) => state.users.individualUsers.members;
export const selectIndividualUsersSearchCount = (state: RootState) => state.users.individualUsers.count;
export const selectIndividualLoadFees = (userId: string) => (state: RootState) => state.users.loadFees.find((fpl) => fpl.userId === userId);
export const selectIsLoadingUserLoadFees = (state: RootState) => state.users.isLoadingLoadFees;
export const selectSelectedProfile = () => (state: RootState) => state.users.selectedProfile;
export const selectIsSettingSmartCardPin = (state: RootState) => state.users.isSettingSmartCardPin;
export const selectLoggedInUserIsSelectedProfile = () => (state: RootState) => state.users.loggedInUserIsSelectedProfile;
export const selectIsUpdatingIndividualUser = (state: RootState) => state.users.isUpdatingIndividualUser;
export const selectIndividualFuelCardMarketingData = (state: RootState) => state.users.individualFuelMarketing;
export const selectTargetedFuelMarketingMessage = (state: RootState) => state.users.targetedFuelMessage;
export const selectHasTargetedFuelMarketingMessage = (state: RootState): boolean => Boolean(state.users.targetedFuelMessage);

// PERMISSIONS SELECTORS
export const selectPermissions = (state: RootState) => state.users.permissions;
export const selectPermissionsLoading = (state: RootState) => state.users.permissionsLoading;
export const selectFleetPermissions = (state: RootState) => state.users.fleetPermissions;
export const selectFleetPermissionsLoading = (state: RootState) => state.users.fleetPermissionsLoading;

export default userSlice.reducer;
