import React, { useEffect, useState } from 'react';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseEnv } from './config';
import AppDefault from './AppDefault';
import MigrateMemberToPortal from './features/member-migration/MigrateMemberToPortal';
import { selectAppAlert } from './features/application/applicationSlice';
import AppAlert from './shared/components/AppAlert';
import AzureAppInsights from './AzureAppInsights';

const appConfig = parseEnv();

LicenseInfo.setLicenseKey(appConfig.REACT_APP_MATERIAL_UI_X_PRO_LICENSE_KEY);

const App = () => {
  const alert = useSelector(selectAppAlert);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    setAlertOpen(true);
  }, [alert]);

  return (
    <AzureAppInsights>
      <Router>
        <Switch>
          <Route path="/member-signup" component={MigrateMemberToPortal} />
          <Route path="*" component={AppDefault} />
        </Switch>
      </Router>

      {alert && (
        <AppAlert
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          severity={alert.severity || 'info'}
          autoHideDuration={alert.autoHideDuration || 8000}
          message={alert.message}
        />
      )}
    </AzureAppInsights>
  );
};

export default App;
