import React from 'react';
import {
  List, ListItem, ListItemText, Typography,
} from '@mui/material';

import HelpDialog from '../../shared/components/HelpDialog';

interface ContractBalanceHelpProps {
  top?: number;
  left?: number;
  isFleet?: boolean;
  balanceTitle?: string;
}
const ContractBalanceHelp = (props: ContractBalanceHelpProps) => {
  const {
    top = 2, left = -26, isFleet = false, balanceTitle = 'Contract Balance',
  } = props;

  const lowerCaseTitle = balanceTitle.toLowerCase();
  return (
    <HelpDialog
      tooltipTitle={`Click for help about the ${lowerCaseTitle}`}
      style={{
        top: `${top}px`,
        left: `${left}px`,
      }}
    >
      <Typography variant="h5" gutterBottom>
        {balanceTitle}
      </Typography>

      <List>
        <ListItem sx={{ alignItems: 'flex-start' }}>
          <ListItemText
            sx={{ alignItems: 'flex-start' }}
            secondary={`The ${lowerCaseTitle} represents the current amount available${!isFleet ? '.' : ' to distribute across a company\'s active fuel cards.'} This amount will vary as funds are withdrawn or added. The ${lowerCaseTitle} updates in near-real-time 
              as transactions are recorded. The "as of" date and time reflect when this available balance was last updated (central time).`}
          />
        </ListItem>
      </List>
    </HelpDialog>
  );
};

export default ContractBalanceHelp;
