import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useLoadLimits } from '../../../features/fleet/hooks/useLoadLimits';
import CustomNumberInput, {
  CustomNumberInputProps,
} from '../formFields/CustomNumberInput';
import LimitAdornment from './LimitAdornment';

interface LimitInputProps extends CustomNumberInputProps {
  showAdornment?: boolean;
  adornmentIsDisabled?: boolean;
  onAdornmentClicked?: () => void;
  onAfterAdornmentClicked?: () => void;
  isDefaultValue?: (fieldValues: any[]) => boolean;
  otherFields?: string[];
}

const LimitInput = (props: LimitInputProps) => {
  const {
    placeholder = 'Enter custom amount',
    decimalScale = 2,
    prefix = '$',
    thousandSeparator = true,
    validateOnChange = true,
    showAdornment = true,
    adornmentIsDisabled = false,
    name,
    onAdornmentClicked,
    onAfterAdornmentClicked,
    isDefaultValue,
    otherFields = [],
    ...rest
  } = props;
  const { limitAdornmentProps } = useLoadLimits();
  const form = useFormContext();

  const fieldValues = form.watch([name, ...otherFields]);
  const isDefault = isDefaultValue
    ? isDefaultValue(fieldValues)
    : !fieldValues[0]?.toString().length;

  const adornmentOnClickHandler = onAdornmentClicked
    || (() => {
      form.setValue(name, '');
      if (onAfterAdornmentClicked) onAfterAdornmentClicked();
    });

  return (
    <CustomNumberInput
      {...rest}
      name={name}
      {...limitAdornmentProps(
        showAdornment,
        <LimitAdornment
          isDefault={isDefault}
          onAdornmentClick={adornmentOnClickHandler}
          disabled={adornmentIsDisabled}
        />,
        'end',
      )}
      style={{ flex: 1, display: 'flex' }}
      placeholder={placeholder}
      decimalScale={decimalScale}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      validateOnChange={validateOnChange}
    />
  );
};

export default LimitInput;
