import React from 'react';
import { currencyFormat } from '../../utilities/currencyFormat';

export type LimitLabelFormat = 'currency' | 'days';

const formatLimitLabel = (limit: number, format: LimitLabelFormat) => {
  switch (format) {
    case 'days':
      return `${limit} calendar days`;
    case 'currency':
      return currencyFormat(limit);
    default:
      return `${limit} ${format}`;
  }
};

interface LimitDefaultLabelProps {
  label: React.ReactNode;
  limitValue: number | undefined | null;
  defaultLimitValue: number | undefined | null;
  format?: LimitLabelFormat;
  isInEditMode: boolean;
}

const LimitDefaultLabel = (props: LimitDefaultLabelProps) => {
  const {
    label,
    format = 'currency',
    isInEditMode,
    limitValue,
    defaultLimitValue,
  } = props;

  if (!isInEditMode) {
    return <>{label}</>;
  }

  const isDefault = !limitValue;

  const limit = defaultLimitValue;

  const displayLimit = limit ? formatLimitLabel(limit, format) : 'unlimited';

  return (
    <span>
      {label}
      {' '}
      -
      {' '}
      <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
        Default is
        {' '}
        <span style={{ fontWeight: isDefault ? 'bold' : 'unset' }}>
          {displayLimit}
        </span>
      </span>
    </span>
  );
};

export default LimitDefaultLabel;
