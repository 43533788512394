import { omit, getOr } from 'lodash/fp';
import { AxiosResponse } from 'axios';
import { FleetNotificationEmailContactModel } from '../../../shared/models/fleet/FleetNotificationEmailContactModel';
import { VoidedLoad } from '../../../shared/models/fuel/VoidedLoad';
import { parsePendingLoad, parseVoidedLoad } from '../../../shared/utilities/fuelHelpers';
import { PortalApiPermission } from '../../../shared/models/permissions/PortalApiPermission';
import { Fleet } from '../../../shared/models/fleet/Fleet';
import { PortalApiFleet } from '../../../shared/models/fleet/PortalApiFleet';
import { portalApiFleetToFleet } from '../../../shared/utilities/fleetHelpers';
import { AddFleetUser } from '../../../shared/models/user/AddFleetUser';
import { FleetUserDetailModel } from '../../../shared/models/user/FleetUserDetailModel';
import { SetFleetUserStatus } from '../../../shared/models/user/SetFleetUserStatus';
import { ResetFleetUserPassword } from '../../../shared/models/user/ResetFleetUserPassword';
import { PortalApiAddFleetAndLimits } from '../../../shared/models/fleet/PortalApiAddFleetAndLimits';
import { PortalApiUpdateFleetAndLimits } from '../../../shared/models/fleet/PortalApiUpdateFleetAndLimits';
import { PortalApiLoadLimits } from '../../../shared/models/fuel/PortalApiLoadLimits';
import { FleetLoadLimits } from '../../../shared/models/fuel/FleetLoadLimits';
import { EfsCarrier } from '../../../shared/models/fuel/EfsCarrier';
import { PortalApiEfsCarrier } from '../../../shared/models/fuel/PortalApiEfsCarrier';
import { FleetPortalApiUpdatePermissions } from '../../../shared/models/permissions/FleetPortalApiUpdatePermissions';
import { PortalApiFleetCard } from '../../../shared/models/fuel/PortalApiFleetCard';
import { PortalApiMatrixUser } from '../../../shared/models/matrix/PortalApiMatrixUser';
import { PortalApiFuelCardAssignmentHistory } from '../../../shared/models/fuel/PortalApiFuelCardAssignmentHistory';
import { PortalApiFuelCard } from '../../../shared/models/fuel/PortalApiFuelCard';
import { CsaInfoResponse } from '../../../shared/components/legal/legalTypes';
import { BillingHistoryModel } from '../../../shared/models/other/BillingHistoryModel';
import { UpdateLoginInfo } from '../../../shared/models/user/UpdateLoginInfo';
import { UserNameChangeRequests } from '../../../shared/models/name-change/UserNameChangeRequests';
import { NameChangeRequestBase } from '../../../shared/models/name-change/NameChangeRequestBase';
import { LoadFleetContract } from '../../../shared/models/fuel/LoadFleetContract';
import { ContractFuelLoadResponse } from '../../../shared/models/fuel/ContractFuelLoadResponse';
import { PendingLoad } from '../../../shared/models/fuel/PendingLoad';
import { PortalApiPaymentMethod } from '../../../shared/models/payment-method/PortalApiPaymentMethod';
import { AddPaymentMethod } from '../../../shared/types/payment-method/AddPaymentMethod';
import { PaymentMethod } from '../../../shared/models/payment-method/PaymentMethod';
import {
  apiPaymentMethodToFrontendPaymentMethod, getAddPaymentMethodBody,
} from '../../../shared/utilities/paymentMethodHelpers';
import { initAxiosInstance } from './utils';
import { FleetProductTemplate } from '../../../shared/models/fleet/FleetProductTemplate';
import { SignIn } from '../../../shared/models/user/SignIn';
import { LegalDocument } from '../../../shared/models/legal/LegalDocument';
import { NextAvailableFuelLoadModel } from '../../../shared/models/fuel/NextAvailableFuelLoadModel';

export const useFleetApi = () => {
  const portalClient = initAxiosInstance();

  const loadAFleetContract = (
    props: LoadFleetContract,
  ) => portalClient.post<ContractFuelLoadResponse>('fleet/contract/load', {
    accountId: props.paymentMethodId,
    feeId: props.processingFee.id,
    loadFeePercentage: props.loadFeePercentage,
    loadFeeMinimum: props.loadFeeMinimum,
    expediteFeePercentage: props.processingFee.percentage,
    expediteFeeDays: props.processingFee.days,
    estimatedFundsAvailability: props.processingFee.availibilityDate,
    fleetId: props.fleetId,
    loadAmount: props.fundsToAdd,
    feeAdjustmentAmount: props.feeAdjustmentAmount,
    feeAdjustmentReason: props.feeAdjustmentReason,
    couponUsageId: props.couponUsageId,
  });

  const getPendingLoadsForFleet = async (fleetId: string) => {
    const { data: pendingLoads } = await portalClient.get<PendingLoad[]>(
      'fleet/contract/pendingLoads',
      {
        params: {
          fleetId,
        },
      },
    );

    const parsedPendingLoads: PendingLoad[] = pendingLoads.map(parsePendingLoad);

    return parsedPendingLoads;
  };

  const addPaymentMethodToFleet = async (
    fleetId: string,
    payment: AddPaymentMethod,
  ) => {
    const { data } = await portalClient.post<any, AxiosResponse<PortalApiPaymentMethod>>('/fleet/paymentMethod', { fleetId, isActive: true, ...getAddPaymentMethodBody(payment) });

    return data;
  };

  const getFleetPaymentMethods = async (
    fleetId: string,
  ): Promise<PaymentMethod[]> => {
    const { data: paymentMethods } = await portalClient.get<PortalApiPaymentMethod[]>('fleet/paymentmethod/all', {
      params: {
        fleetId,
        includeUnauthorized: true,
      },
    });

    return paymentMethods.map<PaymentMethod>(apiPaymentMethodToFrontendPaymentMethod);
  };

  // API: GetNotificationEmails
  const getFleetFuelNotifications = async (fleetId: string) => {
    const { data } = await portalClient
      .get<FleetNotificationEmailContactModel[]>(`/fleet/${fleetId}/notification/email/fuel`);

    return data;
  };

  // API: AddNotificationEmails
  const postFleetFuelNotifications = async (fleetId: string, emails: FleetNotificationEmailContactModel[]) => {
    const { data } = await portalClient
      .post<FleetNotificationEmailContactModel[]>(`/fleet/${fleetId}/notification/email/fuel`, { contacts: emails });

    return data;
  };

  // API: DeleteNotificationEmail
  const deleteFleetFuelNotifications = async (
    fleetId: string,
    emails: FleetNotificationEmailContactModel[],
  ): Promise<void> => {
    await portalClient.delete(`/fleet/${fleetId}/notification/email/fuel`, {
      data: {
        contacts: emails,
      },
    });
  };

  // API:
  const getFleetLegalNotifications = async (fleetId: string) => {
    const { data } = await portalClient
      .get<FleetNotificationEmailContactModel[]>(`/fleet/${fleetId}/notification/email/legal`);

    return data;
  };

  // API:
  const postFleetLegalNotifications = async (fleetId: string, emails: FleetNotificationEmailContactModel[]) => {
    const { data } = await portalClient
      .post<FleetNotificationEmailContactModel[]>(`/fleet/${fleetId}/notification/email/legal`, { contacts: emails });

    return data;
  };

  // API:
  const deleteFleetLegalNotifications = async (
    fleetId: string,
    emails: FleetNotificationEmailContactModel[],
  ): Promise<void> => {
    await portalClient.delete(`/fleet/${fleetId}/notification/email/legal`, {
      data: {
        contacts: emails,
      },
    });
  };

  // API: GetContractVoidedLoads
  const getVoidedLoadsForFleet = async (fleetId: string) => {
    const { data: voidedLoads } = await portalClient.get<VoidedLoad[]>(
      'fleet/contract/voidedLoads',
      {
        params: {
          fleetId,
        },
      },
    );

    const parsedVoidedLoads: VoidedLoad[] = voidedLoads.map(parseVoidedLoad);

    return parsedVoidedLoads;
  };

  // API: GetAll
  const getFleets = async (
    permissions: PortalApiPermission[] | null,
    includeInactive: boolean = false,
  ): Promise<Fleet[]> => {
    const { data: fleets } = await portalClient.get<PortalApiFleet[]>(
      '/fleet/all',
      {
        params: {
          includeInactive,
        },
      },
    );

    return fleets.map((f) => portalApiFleetToFleet(f, permissions));
  };

  // API: AddUpdateUser
  const postFleetUser = (fleetUser: AddFleetUser) => portalClient
    .post<FleetUserDetailModel>('/fleet/user', fleetUser)
    .then((resp) => resp.data);

  // API: SetActiveUser
  const postFleetUserActiveStatus = (fleetUserInfo: SetFleetUserStatus) => portalClient.post<void>('/fleet/user/setActive', null, {
    params: {
      ...fleetUserInfo,
    },
  }).then((resp) => resp.data);

  // API: ResetPassword
  const postResetFleetUserPassword = ({ fleetId, userId }: ResetFleetUserPassword) => portalClient.post('/fleet/user/resetPassword', {
    forceChangePasswordNextSignIn: true,
    fleetId,
    userId,
  }).then((resp) => resp.data);

  // TODO: Genericize postAddNewFleet and postUpdateFleet
  // API: AddUpdateFleet
  const postAddNewFleet = async (fleetInfo: PortalApiAddFleetAndLimits) => {
    const { data: fleet } = await portalClient.post<PortalApiFleet>('/fleet', {
      ...fleetInfo,
    });

    return fleet;
  };

  // API: AddUpdateFleet
  const postUpdateFleet = async (fleetInfo: PortalApiUpdateFleetAndLimits) => {
    const { data: fleet } = await portalClient.post<PortalApiFleet>('/fleet', {
      ...fleetInfo,
    });

    return fleet;
  };

  // API: GetDefaultLimit
  const getFleetSystemDefaultLimits = async (
    fleetId: string,
  ): Promise<PortalApiLoadLimits> => portalClient.get<PortalApiLoadLimits>('/fleet/defaultLimit', {
    params: { fleetId },
  })
    .then((resp) => resp.data);

  // API: GetNewFleetDefaultLimit
  const getNewFleetDefaultLimit = async (
    parentId: string,
  ): Promise<PortalApiLoadLimits> => portalClient.get<PortalApiLoadLimits>('/fleet/newFleetDefaultLimit', {
    params: { parentId },
  })
    .then((resp) => resp.data);

  // API: UpdateFleetLimits
  const postEditFleetLoadLimits = async (fleetLimits: Partial<FleetLoadLimits>) => portalClient.post('/fleet/limits', fleetLimits).then((res) => res.data);

  // API: GetCarriers
  const getFleetEfsCarriers = async (): Promise<EfsCarrier[]> => {
    const { data: carriers } = await portalClient.get<PortalApiEfsCarrier[]>(
      '/fleet/carrier/all',
    );

    return carriers.map(omit('createDate'));
  };

  // API: UpdateUserPermissions
  const postUpdateFleetUserPermissions = (info: FleetPortalApiUpdatePermissions) => portalClient.post('/fleet/user/permissions', info).then((resp) => resp.data);

  // API: GetCards
  const getFleetFuelCards = async (fleetId: string) => {
    const { data: fuelCards } = await portalClient.get<PortalApiFleetCard[]>(
      '/fleet/card/all',
      {
        params: {
          fleetId,
        },
      },
    );

    return fuelCards;
  };

  // API: GetUserCards
  const getFleetUserFuelCards = async (fleetId: string, userId: string): Promise<PortalApiFleetCard[]> => portalClient
    .get<PortalApiFleetCard[]>(`/fleet/${fleetId}/card/user/${userId}`)
    .then(getOr([], 'data'));

  // API: DeletePaymentMethod
  const deleteFleetPaymentMethod = async (
    paymentMethodId: number,
    fleetId: string,
  ): Promise<void> => {
    await portalClient.delete('fleet/paymentmethod', {
      params: {
        fleetId,
        accountId: paymentMethodId,
      },
    });
  };

  const getFleetMatrixUsers = async (
    fleetId: string,
    excludeRootMember: boolean = true,
    filterUnassigned: boolean = false,
  ): Promise<PortalApiMatrixUser[]> => portalClient.get<PortalApiMatrixUser[]>('/fleet/user/matrixUsers', {
    params: {
      fleetId,
      excludeRootMember,
      filterUnassigned,
    },
  })
    .then(getOr([], 'data'));

  // API: AddFuelCardAssignment
  const postAddFleetFuelCardAssignment = async (
    cardId: number,
    fleetUserId: string,
  ) => {
    await portalClient.post('/fleet/card/addassignment', {
      cardId,
      fleetUserId,
    });
  };

  // API: GetFuelCardAssignmentHistory
  const getFleetFuelCardAssignmentHistory = async (fleetId: string, fuelCardId: number): Promise<PortalApiFuelCardAssignmentHistory[]> => {
    const { data } = await portalClient
      .get<PortalApiFuelCardAssignmentHistory[]>(`/fleet/${fleetId}/card/${fuelCardId}/assignmentHistory`);

    return data.map((d) => ({
      ...d,
      sysStartTime: d.sysStartTime
        ? new Date(d.sysStartTime)
        : undefined,
      createddate: d.createddate
        ? new Date(d.createddate)
        : undefined,
    }));
  };

  // API: UpdateFuelCardAssignment
  const postUnassignFleetDriverFromFuelCard = async (card: PortalApiFuelCard) => {
    await portalClient.post('/fleet/card/updateassignment', {
      fleetFuelCardAssignmentId: card.fuelCardAssignmentId,
      fleetUserId: null,
    });
  };

  // API: UpdateFuelCardAssignment
  const postReassignFleetDriverToFuelCard = async (fleetFuelCardAssignmentId: string, fleetUserId: string) => {
    await portalClient.post('/fleet/card/updateassignment', {
      fleetFuelCardAssignmentId,
      fleetUserId,
    });
  };

  // API: GetCsaInformation
  const getFleetCsaInfo = async (fleetId: string): Promise<CsaInfoResponse> => {
    const { data } = await portalClient.get<CsaInfoResponse>(`/fleet/csa/${fleetId}`);
    return data;
  };

  // API: GetBillingHistory
  const getFleetBillingHistory = async (fleetId: string) => {
    const { data } = await portalClient.get<BillingHistoryModel[]>(`/fleet/billingHistory/${fleetId}`);

    return data;
  };

  // API: UpdateUserEmail
  const postUpdateFleetLoginInfo = async (fleetId: string, userId: string, loginInfo: UpdateLoginInfo) => {
    const { data } = await portalClient.post<any>('/fleet/user/email', {
      fleetId,
      userId,
      ...loginInfo,
    });

    return data;
  };

  // API: DeleteNameChangeRequest
  const deleteFleetUserNameChangeRequest = (userId: string, fleetId: string) => portalClient.delete(`/fleet/user/namechangerequest/${userId}?fleetId=${fleetId}`);

  // API: GetNameChangeRequest
  const getFleetUserNameChangeRequest = async (userId: string, fleetId: string) => {
    const { data } = await portalClient.get<UserNameChangeRequests>(`/fleet/user/namechangerequest/${userId}?fleetId=${fleetId}`);

    return data;
  };

  // API: UpdateFleetUserFirstLastName
  const postAddFleetUserNameChangeRequest = async (userId: string, fleetId: string, nameChangeRequest: NameChangeRequestBase) => {
    await portalClient.post<any>('/fleet/user/nameChangeRequest', {
      userId,
      fleetId,
      ...nameChangeRequest,
    });
  };

  // API: GetFleetLegalDraftDocument
  const getFleetDraftCaseDocument = async (fleetId: string, caseId: number) => {
    const { data } = await portalClient.get<LegalDocument[]>(`/legal/fleet/${fleetId}/legalDraftDocument/${caseId}`);
    return data;
  };

  // API: AddUpdateFleetProducts
  const postAddMemberSubscriptionProduct = async (fleetId: string, productPromotions: FleetProductTemplate[]) => {
    const { data } = await portalClient.post<FleetProductTemplate[]>('/fleet/products', {
      fleetId,
      productPromotions,
    });

    return data;
  };

  const getFleetUserSignIns = async (azureId: string, fleetId: string): Promise<SignIn[]> => {
    const { data } = await portalClient.get<SignIn[]>(`/fleet/user/signIns/${azureId}?fleetId=${fleetId}`);

    return data;
  };

  // GetNextAvailableFundingDate
  const getFleetNextAvailableFundingDate = async (fleetId: string): Promise<NextAvailableFuelLoadModel> => {
    const { data } = await portalClient.get<NextAvailableFuelLoadModel>(`/fleet/contract/${fleetId}/nextAvailableFundingDate`);

    return data;
  };

  const getFleetProMilesSsoToken = async (fleetId: string) => {
    const { data } = await portalClient.get<any, AxiosResponse<string>>(
      `/fleet/ProMiles/${fleetId}`,
    );

    return data;
  };

  return {
    loadAFleetContract,
    getPendingLoadsForFleet,
    addPaymentMethodToFleet,
    getFleetPaymentMethods,
    getFleetFuelNotifications,
    postFleetFuelNotifications,
    deleteFleetFuelNotifications,
    getVoidedLoadsForFleet,
    getFleets,
    postFleetUser,
    postFleetUserActiveStatus,
    postResetFleetUserPassword,
    postAddNewFleet,
    postUpdateFleet,
    getFleetSystemDefaultLimits,
    getNewFleetDefaultLimit,
    postEditFleetLoadLimits,
    getFleetEfsCarriers,
    postUpdateFleetUserPermissions,
    getFleetFuelCards,
    getFleetUserFuelCards,
    deleteFleetPaymentMethod,
    getFleetMatrixUsers,
    postAddFleetFuelCardAssignment,
    getFleetFuelCardAssignmentHistory,
    postUnassignFleetDriverFromFuelCard,
    postReassignFleetDriverToFuelCard,
    getFleetCsaInfo,
    getFleetBillingHistory,
    postUpdateFleetLoginInfo,
    deleteFleetUserNameChangeRequest,
    getFleetUserNameChangeRequest,
    postAddFleetUserNameChangeRequest,
    getFleetLegalNotifications,
    postFleetLegalNotifications,
    deleteFleetLegalNotifications,
    getFleetDraftCaseDocument,
    postAddMemberSubscriptionProduct,
    getFleetUserSignIns,
    getFleetNextAvailableFundingDate,
    getFleetProMilesSsoToken,
  };
};
