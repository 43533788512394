import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';
import ApiValidationErrors from '../../shared/components/ApiValidationErrors';
import BusySubmitButton from '../../shared/components/BusySubmitButton';
import DetailsWrapper from '../../shared/components/DetailsWrapper';
import CustomNumberInput from '../../shared/components/formFields/CustomNumberInput';
import CustomTextInput from '../../shared/components/formFields/CustomTextInput';
import FormWrapper from '../../shared/components/FormWrapper';
import IconText from '../../shared/components/icon-components/IconText';
import InfoIconText from '../../shared/components/icon-components/InfoIconText';
import { useBreakPoints } from '../../shared/hooks/useBreakPoints';
import EmailTextInput from '../../shared/components/formFields/EmailTextInput';
import StandardError from '../../shared/components/StandardError';

interface AddIndividualPortalLoginModalProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (
    formData: UserWithLoginFormData
  ) => Promise<void>;
  defaultFormData: UserWithLoginFormData;
  apiValidationErrors?: string[];
  isBusy?: boolean;
  apiError?: string;
}

interface UserWithLoginFormData {
  email: string;
  firstName: string;
  lastName: string;
  memberId: number;
}

const AddIndividualPortalLoginModal = (props: AddIndividualPortalLoginModalProps) => {
  const theme = useTheme();
  const {
    onClose,
    open,
    onFormSubmit,
    defaultFormData,
    apiValidationErrors = [],
    isBusy = false,
    apiError = '',
  } = props;

  const {
    firstName,
    lastName,
    memberId,
  } = defaultFormData;

  const [showConfirm, setShowConfirm] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const { isMdDown } = useBreakPoints();

  const form = useForm<UserWithLoginFormData>({
    mode: 'onChange',
    defaultValues: {
      ...defaultFormData,
    },
  });

  const {
    reset, formState: { isDirty, isValid }, handleSubmit, trigger, watch,
  } = form;

  const watchEmail = watch('email');

  useEffect(() => {
    setTimeout(() => {
      trigger();
    }, 1);
  }, [defaultFormData]);

  const handleClose = () => {
    setShowConfirm(false);
    reset();
    onClose();
  };

  const showEmailNote = isBusy || (watchEmail && isValid);

  return (
    <DetailsWrapper
      title={`Add Portal Login to: ${firstName} ${lastName} (${memberId})`}
      onClose={handleClose}
      open={open}
      titlebackgroundcolor={theme.palette.primary.dark}
      maxLargeScreenDialogWidth={1300}
      confirmCloseProps={{
        showConfirmCloseModal: isDirty,
        forceConfirmModal: showConfirm,
        onConfirmCancel: () => setShowConfirm(false),
        confirmTitle: "Close 'Add Portal Login' Dialog?",
        confirmContent:
          "You may have unsaved changes. Are you sure you want to close the 'Add Portal Login' dialog? ",
      }}
      isAppBarSticky
    >
      <FormWrapper>
        <FormProvider {...form}>
          <form
            style={{ flexDirection: 'column', display: 'flex' }}
            onSubmit={handleSubmit(async (submittedForm) => {
              await onFormSubmit(submittedForm);
            })}
          >
            <Grid container spacing={2} xs={12}>
              <Grid item xs={12}>
                <CustomNumberInput
                  disabled
                  name="memberId"
                  fullWidth
                  rules={{
                    required: 'Member ID required',
                  }}
                  label="Member ID"
                  style={{ flex: '1' }}
                  decimalScale={0}
                  allowNegative={false}
                  validateOnChange
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextInput
                  name="firstName"
                  fullWidth
                  rules={{
                    required: 'First Name required',
                  }}
                  label="First Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextInput
                  name="lastName"
                  fullWidth
                  rules={{
                    required: 'Last Name required',
                  }}
                  label="Last Name"
                />
              </Grid>
              <Grid item xs={12}>
                <EmailTextInput
                  isCheckingEmailCallback={(v: boolean) => {
                    setCheckingEmail(v);
                  }}
                  isBusy={isBusy}
                />
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {showEmailNote && (
                  <IconText
                    icon={<CheckIcon htmlColor={theme.palette.success.main} />}
                    text={`${watchEmail} is available for use in the portal`}
                    textStyle={{ color: theme.palette.success.main }}
                  />
                )}
              </Grid>

              {apiValidationErrors.length > 0 && (
                <Grid item xs={12}>
                  <ApiValidationErrors
                    apiValidationErrors={apiValidationErrors}
                  />
                </Grid>
              )}

              <Grid
                item
                container
                xs={12}
                sx={{
                  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                }}
              >
                {apiError && (
                  <StandardError
                    style={{
                      width: '100%', height: '50px', marginTop: '8px', marginBottom: '8px',
                    }}
                    errorTitle={apiError}
                    errorDescription=""
                  />
                )}
                <BusySubmitButton
                  disabled={!isValid || Boolean(apiError)}
                  variant="contained"
                  type="submit"
                  color="primary"
                  actionPending={isBusy || checkingEmail}
                >
                  Add Portal Login
                </BusySubmitButton>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {showEmailNote && (
                  <InfoIconText
                    style={{ width: isMdDown ? '100%' : '50%', margin: 'auto', marginTop: '18px' }}
                    text={(
                      <span>
                        An email with a temporary password and instructions on how to access the portal will be sent to
                        {' '}
                        <span style={{ color: theme.palette.success.main }}>{watchEmail}</span>
                      </span>
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </FormWrapper>
    </DetailsWrapper>
  );
};

export default AddIndividualPortalLoginModal;
